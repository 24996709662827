import React from 'react';
import UploadVectorstore from '../components/UploadVectorstoreFromUserNests';
import GetVectorstores from '../components/GetVectorstores';

function Vectorstores() {
  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-start justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-1/2 px-4">
        <UploadVectorstore />
      </div>
      <div className="w-1/2 px-4">
        <GetVectorstores />
      </div>
    </div>
  );
}

export default Vectorstores;
