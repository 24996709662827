import React, { useState } from 'react';
import { nip04 } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { SimplePool } from 'nostr-tools';


// Function to fetch the user metadata
const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();

  const filters = [{
    pubkey: publicKey,
    kinds: [0]
  }];

  try {
    const events = await pool.list(relayUrls, filters);

    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    // Sort the events by the 'created_at' timestamp in descending order
    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);

    // Find the first event with non-empty content
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      // Store user metadata in variables
      const userName = metadata.name;
      const userPictureUrl = metadata.picture;
      const userAbout = metadata.about;

      return { userName, userPictureUrl, userAbout };
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', // Replace with a default picture URL
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};



export const fetchDecryptedContent = async (userPublicKey, encryptedProperties) => {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/decrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedProperties: encryptedProperties,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchDecryptedContent:', error);
    throw error;
  }
};



function LoginWithEmail(props) {

  // Access props using props.propertyName
  const { appName, imageUrl, onLoginSuccess, onClose} = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
  
    try {
      const response = await fetch('https://api.paywithflash.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ email, password })
      });
  
      const data = await response.json();
      
  
      if (response.ok) {
        // Successful loginf
        // Extracting data elements
        const encryptedUserPrivateKey = data.private_key;
        const userPublicKey = data.public_key;

        const userPrivateKey = await fetchDecryptedContent(userPublicKey, encryptedUserPrivateKey);

        // Fetch the metadata
        const { userName, userPictureUrl, userAbout } = await fetchUserMetadata(userPublicKey, ["wss://relay.paywithflash.com", "wss://relay.damus.io"]);
        
        // Call the callback function with the login data
        onLoginSuccess({ email, password, userPrivateKey, userPublicKey, userName, userPictureUrl, userAbout });

      } else {
        // Login failed
        setErrorMessage(data.error || 'Login failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred during login');
    } finally {
      setLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
      <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-full h-24 mb-4 object-cover rounded-t-sm rounded-b-none" // Tailwind CSS for width and margin bottom
            src={imageUrl}
            alt="Image"
          />
          {/* Overlay label positioned absolutely within the parent div */}
          <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
            {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
          </div>
        </div>

        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-20 h-20 mb-3 object-cover rounded-full border-4 border-white -mt-12 z-20" // Tailwind CSS for width, margin bottom, and z-index
            src={imageUrl}
            alt="Image"
          />
        </div>


        {/* <div className=" flex text-center justify-center items-center">
        <div className="text-center w-fit bg-orange-100  rounded-md px-2 py-1 text-xs font-medium mb-3 text-orange-600">Subscribe in Sats ₿</div>
        </div> */}
        <h2 className="text-center text-2xl font-bold mb-0 text-black">
          {appName}
        </h2>
        <div className="text-center text-md text-gray-600 mb-6 px-10">
          Login with your Flash account to continue.
        </div>
        <form className="space-y-3 px-10  align-middle justify-center" onSubmit={handleLogin}>
          <div>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-md  font-medium text-gray-600  custom-placeholder"
              placeholder='Email'
            />
          </div>
          <div className="relative">
            <input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-md  font-medium text-gray-600  custom-placeholder"
              placeholder='Password'
            />
            <button
              onClick={togglePasswordVisibility}
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-purple-500 hover:text-purple-300"
            >
              {/* Assuming you have an icon like FontAwesome's FaEye/FaEyeSlash for showing/hiding password */}
            </button>
          </div>
          {errorMessage && (
            <div className="text-red-500 mt-2 text-sm">
              {errorMessage}
            </div>
          )}
          <div>
            <div className="flex w-full mt-3 text-slate-400 text-center justify-center items-center">

              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-purple-800 px-3 py-3 text-md font-semibold leading-6 text-white  hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
              >
                Log in
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
  
  
}

export default LoginWithEmail;
