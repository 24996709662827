import React from "react";
import ReactApexChart from "react-apexcharts";

const StatsCharts = ({ transactionsPerDay, transactionDays }) => {

  const series = [{
    name: "Transactions",
    data: transactionsPerDay
  }];
  
  const options = {
    chart: {
      height: 400,
      type: 'line',
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 8,
        left: 4,
        blur: 4,
        opacity: 0.15
      },
      toolbar: {
        show: false
      }
    },
    colors: ['#6466f1', '#6466f1'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Transactions per day',
      align: 'left'
    },
    grid: {
      borderColor: '#ffffff',
      row: {
        colors: ['#6466f1', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.04
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: transactionDays,
      title: {
      }
    },
      yaxis: {
      color: ['#6466f1'],
      title: {
      },
      min: 0,
      max: Math.max(...transactionsPerDay) * 2,
      tickAmount: Math.max(...transactionsPerDay) * 2,
      labels: {
        formatter: function (val) {
          return val.toFixed(0); // Rounds the value to the nearest whole number
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }

      

    return (
      <div id="chart">
          <ReactApexChart options={options} series={series} type="line" height={350} />
      </div>
    );
}

export default StatsCharts;
