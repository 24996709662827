import React from 'react';
import PropTypes from 'prop-types';

const SaleDetails = ({ sale, onClose }) => {
    console.log(sale)
    const stats = [
        { name: 'Total Items', value: sale.Products.reduce((acc, product) => acc + product.quantity, 0) },
        { name: 'Total Spent (USD)', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(sale.totalCostDollar) },
        { name: 'Total Spent (Sats)', value: new Intl.NumberFormat('en-US').format(Math.round(sale.totalCostSats)) },
        { name: 'Purchase Date', value: new Date(sale.saleDate).toLocaleDateString('en-UK') },
    ];

    const customerDetails = [
        { name: 'Email', value: sale.customerEmail },
        { name: 'Address 1', value: sale.customerAddress1 },
        { name: 'Address 2', value: sale.customerAddress2 },
        { name: 'Phone Number', value: sale.customerPhoneNumber },
        { name: 'Postal Code', value: sale.customerPostalCode },
        { name: 'City', value: sale.customerCity },
        { name: 'Country', value: sale.customerCountry },
    ];

    return (
        <div>
            <div className="">
                <button onClick={onClose} className="m-4 text-gray-700 bg-white shadow hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-3 ml-auto inline-flex items-center mb-4" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Back to Sales
                </button>
            </div>
            <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-white px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
                <div>
                    <div className="flex items-center gap-x-3">
                        <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                            <div className="h-2 w-2 rounded-full bg-current" />
                        </div>
                        <h1 className="flex gap-x-3 text-base leading-7">
                            <span className="font-semibold text-gray-700">{sale.firstName || 'Unknown'} {sale.lastName || 'Customer'}</span>
                        </h1>
                    </div>
                    <p className="mt-2 text-xs leading-6 text-gray-500">Sale Origin</p>
                    <div className="overflow-x-auto max-w-xs">
                        <span className="inline-block whitespace-nowrap text-gray-500">{sale.saleOrigin}</span>
                    </div>
                </div>
                <div className="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                    Sale Details
                </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat, statIdx) => (
                    <div
                        key={stat.name}
                        className={`border-t border-gray-100 py-6 px-4 sm:px-6 lg:px-8 ${statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : ''}`}
                    >
                        <p className="text-sm font-medium leading-6 text-gray-700">{stat.name}</p>
                        <p className="mt-2 flex items-baseline gap-x-2">
                            <span className="text-3xl font-semibold tracking-tight text-gray-800">{stat.value}</span>
                        </p>
                    </div>
                ))}
            </div>

            {/* Customer Details */}
            <div className="bg-white border-t border-white/10 pt-11 p-4">
                <h2 className="px-4 text-base font-semibold leading-7 text-gray-700 sm:px-6 lg:px-8">Customer Details</h2>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="px-4 py-2 bg-gray-50 rounded-lg shadow">
                        <p className="text-sm font-medium leading-6 text-gray-700">First Name</p>
                        <p className="mt-1 text-sm text-gray-900 bg-white border-t border-white/10 p-2">{sale.firstName}</p>
                    </div>
                    <div className="px-4 py-2 bg-gray-50 rounded-lg shadow">
                        <p className="text-sm font-medium leading-6 text-gray-700">Last Name</p>
                        <p className="mt-1 text-sm text-gray-900 bg-white border-t border-white/10 p-2">{sale.lastName}</p>
                    </div>
                    {customerDetails.map((detail, index) => (
                        <div key={index} className="px-4 py-2 bg-gray-50 rounded-lg shadow">
                            <p className="text-sm font-medium leading-6 text-gray-700">{detail.name}</p>
                            <p className="mt-1 text-sm text-gray-900 bg-white border-t border-white/10 p-2">{detail.value}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Products list */}
            <div className="bg-white border-t border-white/10 pt-11">
                <h2 className="px-4 text-base font-semibold leading-7 text-gray-700 sm:px-6 lg:px-8">Products</h2>
                <table className="mt-4 w-full whitespace-nowrap text-left">
                    <thead>
                        <tr>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Product ID</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Product Name</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Category</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Price (USD)</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Price (sats)</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Tax Rate</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Quantity</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-white/5">
                        {sale.Products.map((product, index) => (
                            <tr key={index}>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.productId}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.productName}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.categoryName}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(product.price)}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{new Intl.NumberFormat('en-US').format(Math.round(product.price * 1410))}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.taxRate}%</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

SaleDetails.propTypes = {
    sale: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SaleDetails;
