import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();
  const footerLinks = ["About", "Privacy Policy", "Licensing", "Contact"];

  return (
    <footer className="bg-gray-800 text-gray-400 px-8 py-4">
      <div className="flex justify-between items-center container mx-auto">
        <div className="text-sm">
          ©{currentYear} NostrEggs. All rights reserved.
        </div>
        <div className="space-x-4">
          {footerLinks.map((link, index) => (
            <Link 
              key={index} 
              to={`/${link.toLowerCase().replace(' ', '-')}`} 
              className="text-sm hover:text-white"
            >
              {link}
            </Link>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
