import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function PieChartSalesPerProductCategory({ data, currency }) {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: 'donut',
      width: 380,
      toolbar: {
        show: true,
        tools: {
          download: true
        }
      }
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      position: 'bottom'
    },
    title: {
      text: 'Revenue by Product Category'
    },
    labels: [], // Initialize labels here
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    tooltip: {
      y: {
        formatter: function (value) {
          // Displaying values as percentages for better visibility
          return currency === 'Dollar' ? `$${(value / 100).toFixed(2)}` : `${(value / 100).toFixed(2)} zł`;
        }
      }
    }
  });

  const aggregateDataByCategory = (data, currency) => {
    return data.reduce((acc, curr) => {
      const key = curr.categoryName;  // the product category as the key
      const price = currency == 'Dollar' ? curr.totalCostDollar * 100 : curr.totalCostZloty * 100;  // the total cost to be summed

      if (!acc[key]) {
        acc[key] = 0;  // Initialize if not already present
      }

      acc[key] += price;  // Accumulate the price under the category
      return acc;
    }, {});
};


  useEffect(() => {
    if (data && data.length > 0) {
      const aggregatedData = aggregateDataByCategory(data, currency);
      setSeries(Object.values(aggregatedData));
      setLabels(Object.keys(aggregatedData));

      setOptions(prevOptions => ({
        ...prevOptions,
        labels: Object.keys(aggregatedData)
      }));
    }
  }, [data, currency]);

  return (
    <div>
      <div id="chart" className='mt-10'>
        <ReactApexChart options={options} series={series} type="donut" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}
