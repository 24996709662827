import { ClockIcon, CheckBadgeIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import Form1 from '../FlashForms/forms/0-ExampleForm';
import { BiLink, BiSolidLockAlt } from 'react-icons/bi';
import { HiCursorClick } from 'react-icons/hi';
import { BsFillFileLock2Fill, BsFillCameraVideoOffFill, BsFillPersonVcardFill } from 'react-icons/bs';
import { LuMousePointerClick } from 'react-icons/lu';
import { AiOutlineShop } from 'react-icons/ai';
import { MdWidgets } from 'react-icons/md';

import ButtonForm from '../FlashForms/forms/ButtonForm';
import PaymentLinkForm from '../FlashForms/forms/PaymentLinkForm';

import PaywallForm from '../FlashForms/forms/PaywallForm';
import VideoPaywallForm from '../FlashForms/forms/VideoPaywallForm';
import SubscriptionPlanForm from '../FlashForms/forms/SubscriptionPlanForm';
import SubscriptionPaywallForm from '../FlashForms/forms/SubscriptionPaywallForm';
import SubscriptionVideoPaywallForm from '../FlashForms/forms/SubscriptionVideoPaywallForm';
import WidgetForm from '../FlashForms/forms/WidgetForm';

// Integrations
import VimeoOTTSubscriptionPlanForm from '../FlashForms/forms/VimeoOTTSubscriptionPlanForm';


import ConnectAPIForm from '../FlashForms/forms/ConnectAPIForm';
// Import other form components as needed


export const cardConfigs = {
  flash1Actions: [
      {
      id: 'createWidget',
      title: 'Payment Widget',
      icon: MdWidgets,
      iconForeground: 'text-slate-50',
      iconBackground: 'bg-slate-700',
      formComponent: WidgetForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'Payment Widget'
    },
    {
      id: 'createPaymentLink',
      title: 'Payment Link',
      icon: BiLink,
      iconForeground: 'text-slate-50',
      iconBackground: 'bg-slate-700',
      formComponent: PaymentLinkForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/Payment_Link_gif.gif',
      gifText: 'Payment Link'
    },
    {
      id: 'createPaymentButton',
      title: 'Payment Button',
      icon: LuMousePointerClick,
      iconForeground: 'text-slate-50',
      iconBackground: 'bg-slate-700',
      formComponent: ButtonForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/payment_button_gif.gif',
      gifText: 'Payment Button'
    },
    {
      id: 'createPaywall',
      title: 'Page Paywall',
      icon: BiSolidLockAlt,
      iconForeground: 'text-slate-50',
      iconBackground: 'bg-slate-700',
      formComponent: PaywallForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/paywall_gif.gif',
      gifText: 'Paywall'
    },
    {
      id: 'createVideoPaywall',
      title: 'Video Paywall',
      icon: BsFillCameraVideoOffFill,
      iconForeground: 'text-slate-50',
      iconBackground: 'bg-slate-700',
      formComponent: VideoPaywallForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/Video_paywall_gif.gif',
      gifText: 'Video Paywall'
    },

    // Add more professional actions here...
  ],
  flash2Actions: [
    // Add more personal actions here...
  ],
  flash3Actions: [
    
    // Add more personal actions here...
  ],
  flash4Actions: [
    {
      id: 'createSubscriptionPlan',
      title: 'Create a Subscription Plan',
      icon: BsFillPersonVcardFill,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: SubscriptionPlanForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/subscription_gif.gif',
      gifText: 'Subscription Plan'
    },
    // Add more subscription actions here...
  ],
  flash5Actions: [
    {
      id: 'createSubscriptionPaywall',
      title: 'Create a Subscription Page Paywall',
      icon: BiSolidLockAlt,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: SubscriptionPaywallForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'Subscription Paywall'
    },
    {
      id: 'createSubscriptionVideoPaywall',
      title: 'Create a Subscription Video Paywall',
      icon: BsFillCameraVideoOffFill,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: SubscriptionVideoPaywallForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'Subscription Video Paywall'
    },
    // Add more subscription actions here...
  ],
  flash6Actions: [
    {
      id: 'createVimeoOTTSubscriptionPlan',
      title: 'Vimeo OTT',
      image_url: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Vimeo_OTT_logo.png',
      formComponent: VimeoOTTSubscriptionPlanForm,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'Vimeo OTT Subscription Plan'
    },
    // Add more subscription actions here...
  ],
  eggActions: [
    {
      id: 'createVectorstore',
      title: 'Distribute an API',
      icon: BiSolidLockAlt,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: Form1,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'This is how you distribute an API.'
    },
    {
      id: 'monetizeDatabase',
      title: 'Monetize an existing API',
      icon: BsFillFileLock2Fill,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: Form1,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'This is how you monetize an existing API.'
    },
    // Add more personal actions here...
  ],
  nestActions: [
    {
      id: 'createVectorstore',
      title: 'Create a Vectorstore',
      icon: BiSolidLockAlt,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: Form1,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'This is how you create a vectorstore.'
    },
    {
      id: 'monetizeDatabase',
      title: 'Create a paywall for a database',
      icon: BsFillFileLock2Fill,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      formComponent: Form1,
      gifUrl: 'https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_onboarding/create_widget_gif.gif',
      gifText: 'This is how you create a paywall for a database.'
    },
    // Add more personal actions here...
  ],
  // You can add more categories as needed
};

