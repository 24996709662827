import React from 'react';

const ProductOrderFormDisplay = ({ onModify, details, isDigitalProduct }) => {
    return (
        <div className="flex justify-center items-center mt-1">
            <div className="p-8 flex w-full flex-col items-center max-w-md space-y-4 rounded-2xl bg-white">
                <h2 className="text-center text-3xl font-bold font-semibold mb-2 text-slate-900">Order Details</h2>
                <div className="grid grid-cols-2 gap-4">
                    <p className="col-span-2"><strong>Name:</strong> {details.customerName} {details.customerSurname}</p>
                    {!isDigitalProduct && (
                        <>
                            <p className="col-span-2"><strong>Address:</strong> {details.addressLine1}, {details.addressLine2 ? details.addressLine2 + ', ' : ''}{details.city}, {details.postalCode}, {details.country}</p>
                        </>
                    )}
                    <p className="col-span-2"><strong>Email:</strong> {details.email}</p>
                    {details.npub && (
                        <p className="col-span-2"><strong>Nostr npub:</strong> {details.npub}</p>
                    )}
                </div>
                <a
                    href="#modify"
                    onClick={(e) => {
                        e.preventDefault();
                        onModify();
                    }}
                    className="text-sm text-indigo-600 hover:text-indigo-800 cursor-pointer"
                >
                    Change
                </a>
            </div>
        </div>
    );
};

export default ProductOrderFormDisplay;
