import React, { useState } from 'react';
import { ArrowLongLeftIcon, PlusIcon } from '@heroicons/react/24/outline';

import SidebarLayout from '../components/SidebarLayout';
import CreateEgg from '../components/CreateEgg';
import GetPublicEggs from '../components/GetPublicEggs';
import Example from '../components/cards'; // make sure the path is correct
import FlashCards from '../components/FlashCards'

import { CardsPage } from '../components/FlashCards/CardsTemplate';
import { cardConfigs } from '../components/FlashCards/CardsList';
import Header from '../components/FlashStatic/Header';




function Eggs() {
  const [activeSection, setActiveSection] = useState('view'); // Default to 'view'


 const handleUploadCancel = (newStatus) => {
    setActiveSection(newStatus)
  }

  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const openModal = (actionId) => {
    setSelectedAction(actionId);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  const getFormComponent = () => {
    switch (selectedAction) {
      case 'createFlashAPI':
        return <Form1 close={closeModal} />;
      case 'connectAPI':
        return <ConnectAPIForm close={closeModal} />;
      // Add other cases as needed...
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen">
      <SidebarLayout>
          <Header 
          title="Create an Egg" 
          subtitle="Distribute directly your APIs and datasets. For a better web."
          showWalletsAvatar={true} // Set this to true or false as needed
          // ... other header props ...
        />
            <div className="container mx-auto">
    

    </div>
      <div className='p-8'>

     <CardsPage
      actions={cardConfigs.eggActions}
      title="Monetize your APIs"
        />
        
             <CardsPage
      actions={cardConfigs.nestActions}
      title="Monetize your datasources"
          />
          </div>

        
      </SidebarLayout>
    </div>
  );
}

export default Eggs;
