import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { PlusCircleIcon } from '@heroicons/react/24/outline'


const WalletConnectButton2 = ({ onWalletConnected }) => {
  const [appName, setAppName] = useState("");
  const [nwcString, setNwcString] = useState("");
  const [providerName, setProviderName] = useState("");
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [isBitcoinConnectLoaded, setIsBitcoinConnectLoaded] = useState(false); // new state

  useEffect(() => {
    if (!window.albyBitcoinConnectInitialized) {
      import('@getalby/bitcoin-connect-react')
        .then(({ init, launchModal, requestProvider, closeModal }) => {
          // Assign directly to window if suitable for your application
          window.bitcoinConnect = { init, launchModal, requestProvider, closeModal };
          window.albyBitcoinConnectInitialized = true;
          setIsBitcoinConnectLoaded(true); // set the state to true
        })
        .catch(error => {
          console.error("Error importing bitcoin connect:", error);
          setIsBitcoinConnectLoaded(false);
        });
    } else {
      setIsBitcoinConnectLoaded(true); // set the state to true if already initialized
    }
  }, []);



  const openCustomModal = () => {
    setIsCustomModalOpen(true);
  };

  const closeCustomModal = () => {
    setIsCustomModalOpen(false);
  };


  const clearBitcoinConnectState = () => {
    localStorage.removeItem('bc:config');
    setNwcString("");
    setProviderName("");
  };


  const handleAppNameSubmit = async (e) => {
    e.preventDefault();
    closeCustomModal();

    if (isBitcoinConnectLoaded && window.bitcoinConnect) {
      window.bitcoinConnect.init({
        appName: appName,
        filters: ['nwc']
      });
      handleConnect();
    } else {
      console.error("Bitcoin connect functions are not defined");
    }
  };

  const handleConnect = async () => {
    if (isBitcoinConnectLoaded && window.bitcoinConnect) {
      try {
        clearBitcoinConnectState();

        // Call the functions using window.bitcoinConnect
        await window.bitcoinConnect.launchModal();
  
        const provider = await window.bitcoinConnect.requestProvider();
  
        const nwcUrl = provider.options.nostrWalletConnectUrl;
        const relayUrl = provider.relayUrl;
  
        window.bitcoinConnect.closeModal();

        clearBitcoinConnectState();

        setNwcString("");
        setProviderName("");
  
        // Call the callback function passed from parent
        if (onWalletConnected) {
          onWalletConnected(appName, nwcUrl);
        }
  
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    } else {
      console.error("Bitcoin connect functions are not defined");
    }
  };
  

  return (
    <div>
      <div className='flex mt-4 px-1 pb-1'>
      <button
            className="flex flex-row w-full gap-1 items-center justify-center rounded-lg bg-white border border-indigo-700 text-indigo-700 px-2 py-1.5 text-sm font-medium leading-6   hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 "
            onClick={openCustomModal}
        >
                  <PlusCircleIcon className="h-5 w-5 -ml-2  text-indigo-700" />

            Connect Wallet
        </button>
        </div>
      <Modal
        isOpen={isCustomModalOpen}
        onRequestClose={closeCustomModal}
        contentLabel="Enter Wallet Name"
        overlayClassName="fixed inset-0 bg-white bg-opacity-70 flex justify-center items-center"
        className="bg-[#181818] outline-none rounded-lg p-6 max-w-sm w-full mx-4 text-white"
        style={{ overlay: { zIndex: 1000 } }}
        >
        <form onSubmit={handleAppNameSubmit} className="flex flex-col items-center">
            <label className="font-medium text-sm text-gray-100 mb-10">Give your new wallet connection a name:</label>
            <input
            type="text"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
            placeholder="Enter Wallet Name"
            className="w-full px-4 py-2 text-black text-sm rounded"
            required
            />
            <button type="submit" className="w-full mt-4 text-blue-500 hover:text-blue-300 border border-transparent hover:border-blue-500 text-sm text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
              Continue
            </button>
        </form>
    </Modal>
    </div>
  );
};

export default WalletConnectButton2;