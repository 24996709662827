import React, { useState } from 'react';
import RelaysComponent from './RelaysComponent';

import { InformationCircleIcon } from '@heroicons/react/24/outline';

function DisplayVectorstoreDetail({
    vectorstore_ID,
    vectorstore_name,
    vectorstore_description,
    last_modified_date,
    created_by,
    is_owner,
    on_vectorstore_name_change,
    on_vectorstore_description_change,
    relays,
    setRelays
}) {

    const [tooltipVisible, setTooltipVisible] = useState(false);

    function formatDate(dateString) {
        const date = new Date(dateString);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }


    return (
        <>
            <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Vector Database Details</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Here you can see all the vector db's details.</p>
            </div>

            <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">ID</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{vectorstore_ID}</dd>
                    </div>
                    
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">Name</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <input
                                type="text"
                                disabled={!is_owner}
                                name="vectorstoreName"
                                id="vectorstoreName"
                                autoComplete="vectorstoreName"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
                                placeholder="Vectorstore Name"
                                value={vectorstore_name}
                                onChange={(e) => on_vectorstore_name_change(e.target.value)}
                            />
                        </dd>
                    </div>


                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">Description</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <textarea
                                disabled={!is_owner}
                                type="text"
                                name="vectorstoreDescription"
                                id="vectorstoreDescription"
                                autoComplete="vectorstoreDescription"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
                                // placeholder="Vectorstore Description"
                                value={vectorstore_description}
                                onChange={(e) => on_vectorstore_description_change(e.target.value)}
                                rows={3}
                            />
                        </dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <div className="flex items-start"> {/* <-- Use items-center here */}
                            <dt className="text-sm font-medium text-gray-900">Relays</dt>
                            <span className="ml-2 cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                                <InformationCircleIcon className="h-6 w-6 mt-[-3px]" /> {/* <-- Adjust using mt (margin-top) */}
                                {tooltipVisible && (
                                    <div className="absolute -top-2 left-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-64">
                                        IMPORTANT!
                                        <br />
                                        <br />
                                        Relays are where your data is stored for you to retrieve at any time (other than with NostrEggs integrations).
                                        <br />
                                        Adding new relays will not send existing vectors to these relays. Only future updates.
                                        <br />
                                        <br />
                                        Make sure the relays you input can:
                                        <br />
                                        <span className="ml-4">1. Accept multiple events per second</span>
                                        <br />
                                        <span className="ml-4">2. Don't have event size restrictions</span>
                                        <br />
                                        <br />
                                        If you're not sure, just leave the default NostrEggs relay!
                                    </div>
                                )}
                            </span>
                        </div>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <RelaysComponent
                                relays={relays}
                                setRelays={setRelays}
                                is_owner={is_owner}
                            />
                        </dd>
                    </div>




                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">Last Modified Date</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{formatDate(last_modified_date)}</dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900">Owner</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 max-w-xl p-1 overflow-auto">
                            {created_by}
                        </dd>
                    </div>

                </dl>
            </div>
        </>
    );
}

export default DisplayVectorstoreDetail;
