import React, { useState, useEffect } from 'react';

async function sendEmailVerification(recipientEmail, code) {
    const apiUrl = 'https://api.paywithflash.com/api/send_subscription_email_verification_code';
    const requestData = { code, recipient_email: recipientEmail };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(requestData),
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
        }
        const responseData = await response.json();
        // console.log('Email sent successfully:', responseData);
    } catch (error) {
        console.error('Error sending purchase confirmation email:', error.message);
        throw error;
    }
}

const EmailCodeVerification = ({
    email,
    emailVerified,
    setEmailVerified,
    errorMessageEmail,
    setErrorMessageEmail,
    onCodeConfirmed
  }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [storedCode, setStoredCode] = useState('');
    const [resendCountDown, setResendCountDown] = useState(30);
    const [allowResend, setAllowResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    const generateAndSendCode = () => {
      const newCode = Math.floor(1000 + Math.random() * 9000).toString();
      setStoredCode(newCode);
      sendEmailVerification(email, newCode)
        .then(() => {
          setResendCountDown(30);
          setAllowResend(false);
        })
        .catch((error) => {
          setErrorMessageEmail(error.message);
        });
    };
  
    useEffect(() => {
      const timer = setTimeout(() => {
        if (resendCountDown > 0) {
          setResendCountDown(resendCountDown - 1);
        } else {
          setAllowResend(true);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }, [resendCountDown]);
  
    useEffect(() => {
      generateAndSendCode();
    }, []);
  
    const handleVerificationCodeChange = (event) => {
      setVerificationCode(event.target.value);
    };
  
    const confirmCode = () => {
      if (verificationCode === storedCode) {
        setEmailVerified(true);
        onCodeConfirmed();
      } else {
        setErrorMessage("Incorrect verification code");
      }
    };
  
    const handleResendClick = () => {
      if (allowResend) generateAndSendCode();
    };
  
    return (
      <>
        {errorMessageEmail && (
          <div className="text-red-500 text-xs mb-2">{errorMessageEmail}</div>
        )}
        {errorMessage && (
          <div className="text-red-500 text-xs mb-2">{errorMessage}</div>
        )}
        {!emailVerified && !errorMessageEmail && (
          <>
            <div className="flex items-center space-x-2">
              <p className='text-gray-600 text-xs'>We've sent an email with a 4-digit code to the email address above, valid for 10 min. Input the code below to confirm ownership</p>
            </div>
            <div className="flex items-center space-x-4 mt-1">
              <input
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={handleVerificationCodeChange}
                className="text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 flex-grow"
              />
              <button
                type="button"
                onClick={confirmCode}
                className="bg-gray-800 hover:bg-gray-700 text-white text-sm font-bold py-2 px-2 rounded"
                disabled={!verificationCode}
              >
                Confirm Code
              </button>
              {allowResend ? (
                <button
                  type="button"
                  onClick={handleResendClick}
                  className="bg-gray-500 hover:bg-gray-700 text-white text-sm font-bold py-2 px-2 rounded"
                >
                  Resend
                </button>
              ) : (
                <span className="text-gray-600 text-xs">Resend in {resendCountDown} s</span>
              )}
            </div>
          </>
        )}
      </>
    );
  };
  
  export default EmailCodeVerification;
  