import React, { useState } from 'react';

const ProductImages = ({ product }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const imageCount = product.ImageURLs.length;

    const goToPrevious = () => {
        setActiveIndex((prevIndex) =>
            prevIndex - 1 < 0 ? imageCount - 1 : prevIndex - 1
        );
    };

    const goToNext = () => {
        setActiveIndex((prevIndex) =>
            prevIndex + 1 >= imageCount ? 0 : prevIndex + 1
        );
    };

    const goToIndex = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className="relative w-full h-full">
            <img
                className="absolute inset-0 h-full w-full object-cover rounded-xl"
                src={product.ImageURLs[activeIndex]}
                alt={`${product.Name} - ${activeIndex + 1}`}
            />
            <div className="absolute inset-0 flex justify-between items-center">
                <button
                    className="bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full"
                    onClick={goToPrevious}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                </button>
                <button
                    className="bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white p-2 rounded-full"
                    onClick={goToNext}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </div>
            <div className="absolute bottom-0 w-full flex justify-center p-4">
                {product.ImageURLs.map((_, index) => (
                    <button
                        key={index}
                        className={`h-3 w-3 rounded-full mx-1 ${index === activeIndex ? 'bg-slate-800' : 'bg-white border-2 border-gray-300'}`}
                        onClick={() => goToIndex(index)}
                        aria-label={`Go to image ${index + 1}`}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default ProductImages;
