// PaywallTestPage.js

import React, { useEffect, useState } from 'react';

const SubscribeTestPage = () => {
  const [content, setContent] = useState({ html: '', css: '' });

  useEffect(() => {
    const fetchHtmlAndCss = async () => {
      try {
        // Fetch the HTML content
        const response = await fetch('/movies-demo.html');
        const html = await response.text();

        // Parse the HTML to find CSS links
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const links = doc.querySelectorAll('link[rel="stylesheet"]');

        // Fetch each CSS file and concatenate the content
        const cssPromises = Array.from(links).map(link => 
          fetch(link.href).then(res => res.text())
        );
        const cssStyles = await Promise.all(cssPromises);

        // Set the HTML and CSS content in state
        setContent({ html, css: cssStyles.join('\n') });
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    fetchHtmlAndCss();
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'external/subscription-plan-script.js';
    script.dataset.config = 'eyJ1c2VyUHVibGljS2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiI3ZU1VdTAvWXlja0psVWtyNDZCUjNacWx1SzhtOU9rZ2NaZC9PbHFZMXVFOWNNNlBSMVo3N0tlN0JTK2l1Yi9kMmxZVmF2OVJLcUYyZzJINWl3aDlrYTNlYUh2S2kvTGhHcFlIRURoVGYxST0/aXY9Tm1ieFNYSm1MT2N0R0ZnaUVOa01sdz09In0='; // your encoded config here
    // Test with NWC eyJ1c2VyUHVibGlja2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiJNMnYweFdkT3owUmZET3o1ckpmYkpRL3hVQk1LakxsTEJLZlhZb2FRTjBRQjVVY1dHUVFURU5oeS9QbldkVEErMURMQU5Pc0NJOGFpd09TYXJBZWJBUzJyVUZxdUJQc2g2SEpXMFo0ZGRVcmlqQUFuZHBMbVVGMnBuU0hkU3BzeEZ4UkU1N1AwWEVSM214NjBsWmhVNzluSDJkaDlGNXJEeG1YWEdBbFRVdnA2YVdiNmxKNGk1cE5FZG9uOUQ0SSttcGR0blQ2cjVuekxlcXJpL1F2OXJ0U08rRlZJclAyNWJ5SkpYUU42YUo3SDJkVzRySUpVZk1aOGdXMXBDSENqR0dFZlY3Uk9KRHp4ZWpUd293K0Q0Nll4SkpIOUxycEZTK0F6M3VuOEVhNG40TXppcWtXaTFZeC9RZlhPUTQ1dXYxR2kyZUphbUVvblRlU1RkMmdLbkxvUmJHak83d0VaWUpmaG94Um1CWmNNVTkrZU5tczA5SUtua2E3Q0lmUEUzTlhKcGpmQ3lOdjFvWlZ6aVFYSmtsQThyN1NTMW5VSHNZYm83NXNweDFSekNtdUI0ZWVvWjUzK3luaHJLNFFvVEZOTWdnbENNeFN5Q3BTZzg0RDNXb3NvcGpxNXROTWRFWTVTMkoxUXI1UktuNVpKeVNMRkVpSXdNbTIxK1RHU2Z2SXNtazN2ZHhtRGZzVVdsb3drMUE9PT9pdj0zYW5QaVVKcmJNS0NOMU9WRXhKVENRPT0ifQ==
    // Test without NWC eyJ1c2VyUHVibGlja2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiJHQ051NHU1K2tEQmYyVmpucFZlZ3ZCSldOdVhzbVp5NUtvNUJrZVFXbmcvUkQwZzl3elRoaG0vNS9yL1hpaityR1lRemlDRVp5N1dVZDZoVkU0blM4dzYvNjRlM0EvTFdJNEtXUEFzNWxMOXNGVkFxV0VOQjBHMXdLbVlvaXRrMVF4UmJBc2hKeldjYUdoc3pDUm5yMWFmbDJ3MkNaMGpBeURIK3BTM3hJdzJ0WGZIaWNUdVcxQ28yZWFlZjRCR0p4VmwySXY0eVdmckZ4VGpBZXNuZDQ2aUxCSmFuQjJySXlEQlZTZ2N6WFlGaXFBNXgzTUFpVVk1cHJKUEg0VVBJcjR1UHVqNGNaeGZXN1B1bEhVTkRhMnVsYWVKTTZRUkdaaWtiSG9CR3M5RWoxM0w2eldEUEVpMVR5dlVueENXS1JjNVBYZXQyQzVpNUN2dktYU2M4Tnc9PT9pdj1lRWtrczVEMHh0MnRya3lVMEJocVhnPT0ifQ==
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  

  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: content.css }} />
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
    </div>
  );
};

export default SubscribeTestPage;
