import React, { useEffect, useState } from 'react';
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { BiWallet } from 'react-icons/bi';


const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}




export default function Summary({ wallet, flashDetails, userData, summaryError, onConfirm, onBack }) {
    const [formattedDate, setFormattedDate] = useState('');

    // Function to handle plan selection
    const handleConfirm = () => {
        onConfirm();
    };


    // Effect hook to get the current date in right format for display
    function formatDate(date) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
            }
        }

        return `${month} ${day}${suffix(day)} ${year}`;
    }

    // useEffect to get and format the current date
    useEffect(() => {
        const currentDate = new Date();
        setFormattedDate(formatDate(currentDate));
    }, []);

    return (
      <div className="flex flex-col max-h-screen overflow-y-auto bg-white rounded-lg p-4 sm:p-12">
      <div className="text-left mb-4">
        <div className="flex items-center">
          <img
            className="h-12 w-12 rounded-full bg-gray-800 mr-2"
            src={userData.userPictureUrl}
            alt={userData.userName}
          />
          <h3 className="text-md sm:text-lg font-semibold leading-tight sm:leading-6 text-gray-900">{userData.userName}</h3>
        </div>
        <p className="mt-1 text-sm text-gray-500">
          Summary
        </p>
      </div>
      <div className="rounded-lg" style={{border: '1px solid #f0f0f0', boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
        
        {/* Container for Amount and Flash Name */}
        <div className="flex-grow overflow-y-auto px-4">
        <h2 className="sr-only">Payment Summary</h2>
        <div className="flex flex-col sm:flex-row justify-between mb-4">
          <div className="pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">Amount</dt>
            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(flashDetails.priceInDollars)}
              {' '}(<span>{flashDetails.priceInSats.toLocaleString()}</span> sats)
            </dd>
          </div>
          <div className="px-6 pt-4">
            <dt className="sr-only">Status</dt>
            <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              {flashDetails.name}
            </dd>
          </div>
        </div>
        <dl className="mb-4">
              <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                <dt className="flex-none">
                  <span className="sr-only">Client</span>
                  <UserCircleIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd className="text-sm font-medium leading-6 text-gray-900">{userData.userName}</dd>
              </div>
              <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt className="flex-none">
                  <span className="sr-only">Due date</span>
                  <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd className="text-sm leading-6 text-gray-500">
                  <time dateTime="2023-01-31">{formattedDate}</time>
                </dd>
              </div>
              <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                <dt className="flex-none">
                  <span className="sr-only">Status</span>
                  <BiWallet className="h-6 w-5 text-gray-400" aria-hidden="true" />
                </dt>
                <dd className="text-sm leading-6 text-gray-500">Paid with <strong>{wallet.user_wallet_name}</strong></dd>
              </div>
              </dl>
              {summaryError && (
                <p className='mt-4 text-sm text-red-500'>Error processing payment. Choose another wallet or try again later.</p>
              )}
            <div className="mt-4 px-4 py-4">
              <div className="flex flex-col sm:flex-row justify-between gap-4">
                <button 
                  className="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-400 hover:bg-purple-700"
                  onClick={onBack}
                >
                  Back to Wallet Selection
                </button>
                <button 
                  className="inline-flex w-full justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700"
                  onClick={onConfirm}
                >
                  Confirm & Pay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
}
