import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ProductList = ({ products, isLoading, error, onProductSelect }) => {

    // Helper function to determine the className based on product status
    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    if (isLoading) return <div className="text-center mt-5">Loading...</div>;
    if (error) return <div className="text-red-500 text-center mt-5">Error: {error}</div>;

    return (
        <div className="mt-6 rounded-lg overflow-hidden ring-1 ring-indigo-200 shadow-sm">
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-white">
                        <tr>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Image</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Name</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Price</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Status</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Inventory</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Category</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Product Page</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Checkout Page</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-indigo-100 bg-white">
                        {products.map((product, index) => (
                            <tr key={index} className="hover:bg-indigo-50 cursor-pointer" onClick={() => onProductSelect(product)}>
                                <td className="px-3 py-2">
                                    <img src={product.ImageURLs[0]} alt={product.Name} className="h-10 w-10 object-cover rounded-full"/>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.Name}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.Price}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm">
                                    <span className={classNames(
                                        product.ActiveStatus ? 'text-green-400 bg-green-400/10' : 'text-red-400 bg-red-400/10',
                                        'inline-flex rounded-full px-2 text-xs font-semibold leading-5'
                                    )}>
                                        {product.ActiveStatus ? 'Active' : 'Inactive'}
                                    </span>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.inventory ? `${product.inventory.StockCount} pcs` : 'No Inventory'}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{product.category ? `${product.category.CategoryName}` : 'No Category'}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">
                                    <a
                                        href={`/product-page/${product.ProductID}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="View Product"
                                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                        </svg>
                                    </a>
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">
                                    <a
                                        href={`/checkout-page/${product.ProductID}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="View Checkout"
                                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductList;
