import React, { useState, useEffect, Fragment } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function generateMockData() {
  const data = [];
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30); // Start from 30 days ago

  for (let i = 0; i < 30; i++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    // Generate random values for amount_in_usd and amount_in_sats
    const amountInUSD = Math.random() * 1000;
    const amountInSats = Math.random() * 100000;

    data.push({
      transaction_date: date.toISOString(),
      amount_in_usd: amountInUSD.toFixed(2),
      amount_in_sats: amountInSats.toFixed(0)
    });
  }

  return data;
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function aggregateData(data, interval) {
  const formatOptions = { Day: 'yyyy-MM-dd', Week: 'yyyy-IW', Month: 'yyyy-MM' };
  
  let groupedData = {}, f = {};
  for (const acc of data) {
    const date = new Date(acc.saleDate);
    let key = getKeyByInterval(date, interval);
    if (!f[key]) {
      if (!groupedData[key]) {
        groupedData[key] = { totalUSD: 0, totalZloty : 0, totalSats: 0 };
      }
  
      groupedData[key].totalUSD += Number(acc.totalCostDollar);
      groupedData[key].totalSats += Number(acc.totalCostSats);
      groupedData[key].totalZloty += Number(acc.totalCostZloty);
      f[key] = 1;
    }
  }

  return Object.keys(groupedData).map(key => {
    const {totalUSD, totalSats, totalZloty } = groupedData[key];
    return {
      date: key,
      totalUSD: totalUSD,
      totalZloty: totalZloty,
      totalSats: totalSats
    }});
}

// Helper function to get the week number
Date.prototype.getWeek = function() {
  const firstDayOfYear = new Date(this.getFullYear(), 0, 1);
  const pastDaysOfYear = (this - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

// Helper function to get key by interval
function getKeyByInterval(date, interval) {
  if (interval === 'Day') {
    return date.toLocaleDateString('en-CA');
  } else if (interval === 'Week') {
    const firstDayOfWeek = date.getDate() - date.getDay() + 1;
    const weekStartDate = new Date(date.setDate(firstDayOfWeek));
    return `${weekStartDate.getFullYear()}-W${weekStartDate.getWeek()}`;
  } else if (interval === 'Month') {
    return `${date.getFullYear()}-${date.getMonth() + 1}`.padEnd(7, '0');
  }
}

export default function AreaChartSaleAmount({ data, currency }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [interval, setInterval] = useState('Day');
  const mockData = generateMockData();
  // const [data, setData] = useState(mockData);


  useEffect(() => {
    const aggregatedData = aggregateData(data, interval);
    const dates = aggregatedData.map(item => item.date);
    const totalUSD = aggregatedData.map(item => item.totalUSD);
    const totalSats = aggregatedData.map(item => item.totalSats);
    const totalZloty = aggregatedData.map(item => item.totalZloty);

    setSeries([
      {
        name: currency == 'Dollar' ? "Sum of Sales (USD)" : "Sum of Sales (PLN)",
        data: currency == 'Dollar' ? totalUSD : totalZloty
      },
      {
        name: "Sum of Sales (Sats)",
        data: totalSats
      }
    ]);

    setOptions({
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: `Sale Revenue Over Time`,
        align: 'left'
      },
      xaxis: {
        type: 'datetime',
        categories: dates,
        labels: {
          show: false // Hides x-axis labels
        },
        axisBorder: {
          show: false // Hides x-axis line
        },
        axisTicks: {
          show: false // Hides x-axis ticks
        }
      },
      yaxis: [
        {
          labels: {
            show: false // Hides y-axis labels for the first axis
          },
          axisBorder: {
            show: false // Hides y-axis line for the first axis
          },
          axisTicks: {
            show: false // Hides y-axis ticks for the first axis
          }
        },
        {
          opposite: true,
          labels: {
            show: false // Hides y-axis labels for the second axis
          },
          axisBorder: {
            show: false // Hides y-axis line for the second axis
          },
          axisTicks: {
            show: false // Hides y-axis ticks for the second axis
          }
        }
      ],
      grid: {
        show: false // Hides the grid lines
      },
      legend: {
        horizontalAlign: 'right',
        verticalAlign: 'top'
      },
      stroke: {
        curve: 'smooth'
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
        y: {
          formatter: function (value) {
            return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
          }
        }
      }
    });
  }, [data, interval, currency]);

  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval);
  };

  const DropdownMenu = () => (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {interval}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {['Day', 'Week', 'Month'].map((i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={() => handleIntervalChange(i)}
                  >
                    {i.charAt(0).toUpperCase() + i.slice(1)}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  return (
    <div>
      <DropdownMenu />
      <div id="chart" className='mt-1'>
        <ReactApexChart options={options} series={series} type="area" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}