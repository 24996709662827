import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { KeyIcon, LockClosedIcon, PhotographIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import MyKeys from './FlashKeys';
import { UserProfile } from './UserProfile'
import { UserWallets } from './UserWallets'
import { ChangePassword } from './ChangePassword'
import { WalletDropdown, ReferralLinkDisplayAndCopy } from '../FlashForms/comp/InputsLib';




function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


export default function AccountManagement() {

  const navigate = useNavigate(); // Initialize useNavigate

  let userEmail = localStorage.getItem('userEmail') || "";
  const userPublicKey = localStorage.getItem('userHexPublicKey') || "";
  
  const referralLink = `https://app.paywithflash.com/login?ref=${userPublicKey}`;

  // Get wallet list from Redux
  const wallets = useSelector ((state) => state.walletReducer.wallets);

  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search); // Parse query parameters
  const initialTab = queryParams.get('tab') || 'Profile'; // Get 'tab' parameter or default to 'Profile'

  const [selectedTab, setSelectedTab] = useState(initialTab); // Use initialTab as the initial state
  const [isVisible, setIsVisible] = useState (false);
  const [selectedWalletId, setSelectedWalletId] = useState();

  const [isCreatedReferralLinkSuccess, setIsCreatedReferralLinkSuccess] = useState (false);
  const [isAlreadyExists, setIsAlreadyExists] = useState ();

  function switchToWalletsTab() {
    setSelectedTab('Wallets');
  }

  const checkReferralLink = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ user_public_key: userPublicKey })
    };
    
    try {
      const response = await fetch('https://api.paywithflash.com/api/check_referral_link', requestOptions);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      return response.json ();

    } catch (error) {
      throw new Error (error);
    }
  }

  const confirm = async () => {
    const formData = new FormData ();
    formData.append('user_public_key', userPublicKey);
    formData.append('referral_link', referralLink);
    formData.append('wallet_id', selectedWalletId);

    const apiUrl = isAlreadyExists ? 'https://api.paywithflash.com/api/update_referral' : 'https://api.paywithflash.com/api/create_referral';
    
    try {
      const response = await fetch (apiUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error ('Network response was not ok');
      }
      
      setIsCreatedReferralLinkSuccess (true);
      setTimeout (() => {
        setIsCreatedReferralLinkSuccess (false);
      }, 3000);
      const data = response.json ();
      return data;
    } catch (error) {
      throw new Error ('An error in generating Referral Link');
    }
  }

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    navigate(`/my-account?tab=${tab}`);
  };

  const handleWalletChange = (e) => {
    setSelectedWalletId (e.target.value);
  }

    const renderStatus = (status) => {
    let bgColor, textColor, text;
    switch (status) {
      case 'active':
        bgColor = 'bg-emerald-500';
        textColor = 'text-gray-500';
        text = 'Active';
        break;
      case 'paused':
        bgColor = 'bg-yellow-500';
        textColor = 'text-gray-500';
        text = 'Paused';
        break;
      case 'archived':
        bgColor = 'bg-red-500';
        textColor = 'text-gray-500';
        text = 'Archived';
        break;
      default:
        bgColor = 'bg-gray-500';
        textColor = 'text-gray-500';
        text = 'Unknown';
    }

    return (
      <div className="mt-1 flex items-center gap-x-1.5">
        <div className={`flex-none rounded-full ${bgColor}/20 p-1`}>
          <div className={`h-1.5 w-1.5 rounded-full ${bgColor}`} />
        </div>
        <p className={`text-xs leading-5 ${textColor}`}>{text}</p>
      </div>
    );
  }

  useEffect (() => {
    if (wallets.length) {
      setSelectedWalletId (wallets[0].id);
    }
  }, [wallets])

  useEffect (() => {
    if (userPublicKey) {
      checkReferralLink ()
        .then (referralLinkExists => {
          if (referralLinkExists.isExists) {
            setIsAlreadyExists (referralLinkExists.isExists);
            setSelectedWalletId (referralLinkExists.wallet_id);
            setIsVisible (true);
          }
        });
      }
  }, [userPublicKey]);

  let tabs;
  if (userEmail !== "null") {
    tabs = ['Profile', 'Wallets', 'Referral link', 'Keys', 'Change Password']
  } else {
    tabs = ['Profile', 'Wallets', 'Referral link', 'Keys']
  }
  


  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full">
        <nav className="flex space-x-8 border-b border-t pt-3 pb-0 bg-white pl-9">
          {tabs.map((tab) => (
            <a
              key={tab}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleTabChange(tab);
              }}
              className={classNames(
                tab === selectedTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-indigo-300 hover:text-indigo-400 hover:border-indigo-400',
                'inline-flex items-center border-b-2 px-0 pt-1 pb-3 text-sm font-medium'
              )}
            >
              {tab}
            </a>
          ))}
        </nav>

        <div className="mt-6 pl-9">
          {selectedTab === 'Profile' && (
            <UserProfile />
          )}

          {selectedTab === 'Wallets' && (
            <UserWallets switchToWalletsTab={switchToWalletsTab} />
          )}

          {selectedTab == 'Referral link' && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
              <div className='md:col-span-1 pt-1'>
                <h2 className="text-xl font-semibold leading-7 text-indigo-900">Create a referral link</h2>
                <p className="text-sm leading-6 text-indigo-900 opacity-70">Earn 30% of all revenue from any user who creates an account using your link.</p>
              </div>
              <div className="md:col-span-2 overflow-hidden rounded-lg bg-white p-5 shadow mr-12">
                { !isVisible && (
                  <button
                  type="button"
                  className="bg-blue-600 text-white font-semibold mt-4 py-2 px-4 border border-blue-700 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center gap-2"
                  onClick={() => setIsVisible (true)}
                  >
                  Generate Referral Link
                  </button>
                )}
                { isVisible && (
                  <>
                    <div className="inputform m-2">
                      <WalletDropdown label="Wallet" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
                    </div>
                    <ReferralLinkDisplayAndCopy value={referralLink} />
                    { isCreatedReferralLinkSuccess && (
                      <span className = "text-sm text-green-600"> Saved the Referral Link Successfully! </span>
                    )}
                    <button
                    type="button"
                    className="bg-blue-600 text-white font-semibold mt-4 py-2 px-4 border border-blue-700 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 flex items-center justify-center gap-2"
                    onClick={() => confirm ()}
                    >
                    { isAlreadyExists ? 'Update' : 'Confirm' }
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          {selectedTab === 'Keys' && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
              <div className='md:col-span-1 pt-1'>
                <h2 className="text-xl font-semibold leading-7 text-indigo-900">Keys</h2>
                <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage your access keys.</p>
              </div>
              <div className="md:col-span-3 overflow-hidden rounded-lg bg-white shadow mr-12">
                {/* Keys content */}
                <MyKeys />
                {/* Placeholder or specific content for Keys tab */}
              </div>
            </div>
          )}

          {selectedTab === 'Change Password' && (
            <ChangePassword />
          )}
        </div>
      </div>
    </div>
  );
}
