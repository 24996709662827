import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAndUpdateWalletList } from '../../Redux/Actions/walletAction';

import { nip04 } from 'nostr-tools';
import { webln } from "@getalby/sdk";


import WalletConnectButton from './WalletConnectButton'

export function UserWallets() {
    // Get session variables
    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";

    // Get env var
    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY


    const navigate = useNavigate();

    // State for new wallet details
    const [newSelectedWalletName, setNewSelectedWalletName] = useState('Alby');
    const [newWalletName, setNewWalletName] = useState('');
    const [newWalletNwcUrl, setNewWalletNwcUrl] = useState('');
    const [addNewWallet, setAddNewWallet] = useState(false);
    // State to track editing status and values for each wallet
    const [editingWallet, setEditingWallet] = useState(false);
    const [editedWalletName, setEditedWalletName] = useState('');
    const [editedUserWalletName, setEditedUserWalletName] = useState('');
    const [editedWalletNwcUrl, setEditedWalletNwcUrl] = useState('');

    // getting the wallets from redux
    const wallets = useSelector ((state) => state.walletReducer.wallets);

    // Function to be able to add new wallets
    async function saveWalletToDB(selectedWalletName, userWalletName, walletNwcUrl) {
        // Encrypt the nwcUrl
        let encrypted_nwc_url;
        if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
            encrypted_nwc_url = await nip04.encrypt(privateKey, userHexPublicKey, walletNwcUrl);
        } else {
            encrypted_nwc_url = "";
        }

        const formData = new FormData();
        formData.append('user_public_key', userHexPublicKey);
        formData.append('wallet_name', selectedWalletName);
        formData.append('ln_address', "");
        formData.append('user_wallet_name', userWalletName);
        formData.append('nwc_url', encrypted_nwc_url);
    
        try {
            const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();

            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }
    
    
    // Function to be able to delete existing ones
    async function deleteWalletFromDB(id) {
        try {
            const response = await fetch('https://api.paywithflash.com/api/delete_wallet_from_db', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }

    // Function to handle deletion of an wallet
    const handleDeleteWallet = async (id) => {
        try {
            await deleteWalletFromDB(id);

            await getAndUpdateWalletList (userHexPrivateKey, publicKey);
        } catch (error) {
            console.error('Error deleting wallet:', error);
        }
    };

    const getWalletNameFromNWCString = async (nwcString) => {
        const walletsAvailable = ["Alby", "Mutiny"];
        // Convert nwcString to lowercase for case-insensitive comparison
        const lowerCaseNWCString = nwcString.toLowerCase();

        // Iterating over each wallet name to see if it's present in the nwcString
        for (const walletName of walletsAvailable) {
            // Also convert walletName to lowercase
            if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
                return walletName;
            }
        }

        // Return "Custom" if no match is found
        return "Custom";
    };
    
    // Callback function to be called from child
    const handleWalletConnected = async (appName, nwcString) => {
        // Here you can handle the received values

        try {
            // Call function to get the wallet name based on the NWCUrl
            const providerName = await getWalletNameFromNWCString(nwcString);

            await saveWalletToDB(providerName, appName, nwcString)

            await getAndUpdateWalletList (userHexPrivateKey, publicKey);
        } catch (error) {
            console.error('Error saving new wallet:', error);
        }
    };
    
    useEffect(() => {
        getAndUpdateWalletList (userHexPrivateKey, publicKey);
    }, []);

    return (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
            <div className='md:col-span-1 pt-1'>
                <h2 className="text-xl font-semibold leading-7 text-indigo-900">My Wallets</h2>
                <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage and add new wallets.</p>
            </div>
            <div className="md:col-span-3">
                <ul className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white shadow mr-12">
                    {wallets.map((wallet) => (
                        <li key={wallet.id} className="relative flex items-center gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                            <div className="flex-shrink-0 flex flex-col items-center">
                                {/* Wallet Image */}
                                <img 
                                    src={`${wallet.wallet_logo_url}`}
                                    alt={`${wallet.wallet_name} image`}
                                    className="h-10 w-10 rounded-full object-cover"
                                />
                                <p className="text-sm font-semibold leading-6 text-gray-900 truncate text-center">
                                    {wallet.wallet_name}
                                </p>
                            </div>
                            <div className="flex-grow flex flex-col justify-between pl-4">
                                <div className="flex items-center">
                                    <p className="text-sm text-gray-900 truncate w-24">
                                        <span className="font-normal">Wallet Name:</span>
                                    </p>
                                    <div className="border-r border-gray-300 h-6 mr-2"></div>
                                    <p className="text-sm text-gray-900 truncate font-semibold flex-grow">
                                        {wallet.user_wallet_name}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-sm text-gray-900 truncate w-24">
                                        <span className="font-normal">Balance:</span>
                                    </p>
                                    <div className="border-r border-gray-300 h-6 mr-2"></div>
                                    <p className="text-sm text-gray-900 truncate font-semibold flex-grow">
                                        {Math.floor(wallet.balance / 1000).toLocaleString()} sats
                                    </p>
                                </div>
                            </div>
                            <div className="flex-shrink-0 ml-4">
                                <button 
                                    onClick={() => handleDeleteWallet(wallet.id)}
                                    className="rounded-md px-3 py-2 text-xs font-semibold text-red-600 shadow-sm hover:text-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                >
                                    Remove from Flash
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
                <WalletConnectButton onWalletConnected={handleWalletConnected} />
                
                <p className='mt-1 text-sm text-indigo-900'>Don't have a wallet? Setup in 2 min with{' '}
                    <a href="https://getalby.com/invited-by/pierrecorbin" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:text-blue-800 visited:text-purple-600 underline">
                        our partner
                    </a>
                </p>
            </div>
        </div>
    );
    
    
    
    

}
