import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const CustomerDetails = ({ customer, onClose }) => {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCustomerOrders = async () => {
            try {
                const { data } = await axios.get(`https://api.paywithflash.com/api/get_customer_orders`, {
                    params: { email: customer.Email }
                });
                setOrders(data);
            } catch (error) {
                console.error('Failed to fetch customer orders', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchCustomerOrders();
    }, [customer.Email]);

    const lastPurchaseDate = orders.length > 0 ? orders[orders.length - 1].PurchaseDateTime : null;

    const stats = [
        { name: 'Total Orders', value: customer.TotalOrders },
        { name: 'Total Spent (USD)', value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(customer.TotalCostUSD) },
        { name: 'Total Spent (Sats)', value: new Intl.NumberFormat('en-US').format(Math.round(customer.TotalCostSats)) },
        { name: 'Last Purchase Date', value: lastPurchaseDate ? new Date(lastPurchaseDate).toLocaleDateString('en-UK') : 'N/A' },
    ];

    return (
        <div>
            <div className="">
                <button onClick={onClose} className="m-4 text-gray-700 bg-white shadow hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-3 ml-auto inline-flex items-center mb-4" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Back to Customers
                </button>
            </div>
            <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-white px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
                <div>
                    <div className="flex items-center gap-x-3">
                        <div className="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
                            <div className="h-2 w-2 rounded-full bg-current" />
                        </div>
                        <h1 className="flex gap-x-3 text-base leading-7">
                            <span className="font-semibold text-gray-700">{customer.FirstName} {customer.LastName}</span>
                        </h1>
                    </div>
                    <p className="mt-2 text-xs leading-6 text-gray-500">Email</p>
                    <div className="overflow-x-auto max-w-xs">
                        <span className="inline-block whitespace-nowrap text-gray-500">{customer.Email}</span>
                    </div>
                    <p className="mt-2 text-xs leading-6 text-gray-500">Public Key</p>
                    <div className="overflow-x-auto max-w-xs">
                        <span className="inline-block whitespace-nowrap text-gray-500">{customer.UserPublicKey}</span>
                    </div>
                </div>
                <div className="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">
                    Customer Details
                </div>
            </div>

            {/* Stats */}
            <div className="grid grid-cols-1 bg-white sm:grid-cols-2 lg:grid-cols-4">
                {stats.map((stat, statIdx) => (
                    <div
                        key={stat.name}
                        className={`border-t border-gray-100 py-6 px-4 sm:px-6 lg:px-8 ${statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : ''}`}
                    >
                        <p className="text-sm font-medium leading-6 text-gray-700">{stat.name}</p>
                        <p className="mt-2 flex items-baseline gap-x-2">
                            <span className="text-3xl font-semibold tracking-tight text-gray-800">{stat.value}</span>
                        </p>
                    </div>
                ))}
            </div>

            {/* Orders list */}
            <div className="bg-white border-t border-white/10 pt-11">
                <h2 className="px-4 text-base font-semibold leading-7 text-gray-700 sm:px-6 lg:px-8">Orders</h2>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <table className="mt-4 w-full whitespace-nowrap text-left">
                        <thead>
                            <tr>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Order ID</th>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700"># of items</th>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Product</th>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Category</th>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Cost (USD)</th>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Cost (sats)</th>
                                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Purchase Date</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-white/5">
                            {orders.map((order) => (
                                <tr key={order.SaleID}>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{order.SaleID}</td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{order.Products.length}</td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{order.Products.map(product => product.ProductName).join(', ')}</td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{order.Products.map(product => product.ProductCategoryName).join(', ')}</td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(order.TotalCostUSD)}</td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{new Intl.NumberFormat('en-US').format(Math.round(order.TotalCostSats))}</td>
                                    <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{new Date(order.PurchaseDateTime).toLocaleString('en-UK')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

CustomerDetails.propTypes = {
    customer: PropTypes.shape({
        CustomerID: PropTypes.number.isRequired,
        FirstName: PropTypes.string.isRequired,
        LastName: PropTypes.string.isRequired,
        UserPublicKey: PropTypes.string.isRequired,
        Email: PropTypes.string.isRequired,
        TotalOrders: PropTypes.number.isRequired,
        TotalCostUSD: PropTypes.number.isRequired,
        TotalCostSats: PropTypes.number.isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CustomerDetails;
