import React, { useEffect, useState } from 'react';

import NpubCodeVerification from './NpubCodeVerification';
import EmailCodeVerification from './EmailCodeVerification';
import WalletConnectButton from './WalletConnectButton';
import WalletConnectButtonSuccess from './WalletConnectButtonSuccess';
import Memberships from '../SubscriptionPage/Memberships';




async function getWalletNameFromNWCString(nwcString) {
    const walletsAvailable = ["Alby", "Mutiny"];
    // Convert nwcString to lowercase for case-insensitive comparison
    const lowerCaseNWCString = nwcString.toLowerCase();

    // Iterating over each wallet name to see if it's present in the nwcString
    for (const walletName of walletsAvailable) {
        // Also convert walletName to lowercase
        if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
            return walletName;
        }
    }

    // Return "Custom" if no match is found
    return "Custom";
};


const ProductOrderForm = ({ onOrderSubmitted, walletNwcUrl, setWalletNwcUrl, memberships, onSelectedMembershipChange, eMail, nPub, isVerified, loginMethod, appName }) => {
    const [customerName, setCustomerName] = useState('');
    const [customerSurname, setCustomerSurname] = useState('');
    const [contactMethod, setContactMethod] = useState(loginMethod == 'nostr' ? 'npub' : 'email');
    const [email, setEmail] = useState(eMail);
    const [npub, setNpub] = useState(nPub);
    const [errorMessageNostrDM, setErrorMessageNostrDM] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');

    // States for communications
    const [nostrVerificationCodeSent, setNostrVerificationCodeSent] = useState(false);
    const [emailVerificationCodeSent, setEmailVerificationCodeSent] = useState(false);
    const [npubVerified, setNpubVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    const handleSelectEmail = () => {
        setContactMethod('email');
        setNostrVerificationCodeSent(false);
    };


    const handleNpubSubmit = () => {
        setNostrVerificationCodeSent(true);
        setErrorMessageNostrDM(null);
    };

    const handleEmailSubmit = () => {
        setEmailVerificationCodeSent(true);
        setErrorMessageEmail(null);
    };


    // Callback function to be called from WalletConnectButton
    const handleWalletConnected = async (appName, nwcString) => {
        // Here you can handle the received values
        try {
            // Call function to get the wallet name based on the NWCUrl
            const providerName = await getWalletNameFromNWCString(nwcString);
            setWalletNwcUrl(nwcString)
        } catch (error) {
            console.error('Error saving new wallet:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (contactMethod === 'email' && !email) {
            alert('Please enter an email address.');
            return;
        } else if (contactMethod === 'npub' && !npub) {
            alert('Please enter a Nostr public key (npub).');
            return;
        }

        let formValues;
        if (contactMethod === 'email' && email) {
            formValues = {
                // customerName,
                // customerSurname,
                walletNwcUrl,
                email
            };
        } else if (contactMethod === 'npub' && npub && npubVerified) {
            formValues = {
                // customerName,
                // customerSurname,
                walletNwcUrl,
                npub
            };
        }

        onOrderSubmitted(formValues);
    };
    
    return (
        <form className="w-full max-w-md mx-auto py-8 grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
            <div className="col-span-2">
                <h2 className="text-lg font-semibold text-gray-900">Select Your Plan</h2>
            </div>
            <div className="col-span-2">
                {Array.isArray(memberships) &&
                    <Memberships memberships={memberships} onSelectedMembershipChange={onSelectedMembershipChange} />
                }
            </div>
            { (!isVerified || (email && npub)) && (
                <>
                    <div className="col-span-2">
                        <h2 className="text-lg font-semibold text-gray-900">Contact Information</h2>
                    </div>
                    <div className="col-span-2 flex items-center">
                        { loginMethod == 'email & nostr' && (
                            <>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text mr-2">Email</span>
                                        <input
                                            type="radio"
                                            name="contactMethod"
                                            value="email"
                                            checked={contactMethod === 'email'}
                                            onChange={() => setContactMethod('email')}
                                            className="mr-2"
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text mr-2">Nostr npub</span>
                                        <input
                                            type="radio"
                                            name="contactMethod"
                                            value="npub"
                                            checked={contactMethod === 'npub'}
                                            onChange={() => setContactMethod('npub')}
                                            className="mr-2"
                                        />
                                    </label>
                                </div>
                            </>
                        )}
                    </div>
                    {contactMethod === 'email' && (
                        <div className="col-span-2 relative">
                            <input
                                className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email*"
                                required={contactMethod === 'email'}
                            />
                            {!emailVerified && (
                                <button
                                    type="button"
                                    onClick={() => handleEmailSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-blue-500 rounded-r-md hover:bg-blue-700"
                                >
                                    Verify email
                                </button>
                            )}
                            {emailVerified && (
                                <button
                                    type="button"
                                    disabled={true}
                                    onClick={() => handleEmailSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
                                >
                                    Verified
                                </button>
                            )}
                        </div>
                    )}
                    {emailVerificationCodeSent && (
                        <div className="col-span-2">
                            <EmailCodeVerification
                                email={email}
                                emailVerified={emailVerified}
                                setEmailVerified={setEmailVerified}
                                errorMessageEmail={errorMessageEmail}
                                setErrorMessageEmail={setErrorMessageEmail}
                            />
                        </div>
                    )}
                    {contactMethod === 'npub' && (
                        <div className="col-span-2 relative">
                            <input
                                className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
                                type="text"
                                value={npub}
                                onChange={(e) => setNpub(e.target.value)}
                                placeholder="Nostr npub*"
                                required={contactMethod === 'npub'}
                            />
                            {!npubVerified && (
                                <button
                                    type="button"
                                    onClick={() => handleNpubSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-blue-500 rounded-r-md hover:bg-blue-700"
                                >
                                    Verify npub
                                </button>
                            )}
                            {npubVerified && (
                                <button
                                    type="button"
                                    disabled={true}
                                    onClick={() => handleNpubSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
                                >
                                    Verified
                                </button>
                            )}
                        </div>
                    )}
                    {nostrVerificationCodeSent && (
                        <div className="col-span-2">
                            <NpubCodeVerification
                                npub={npub}
                                npubVerified={npubVerified}
                                setNpubVerified={setNpubVerified}
                                errorMessageNostrDM={errorMessageNostrDM}
                                setErrorMessageNostrDM={setErrorMessageNostrDM}
                            />
                        </div>
                    )}
                </>
            )}
            {!walletNwcUrl && (isVerified || npubVerified || emailVerified) && (
                <>
                    <div className="col-span-2 mt-2">
                        <h2 className="text-lg font-semibold text-gray-900">Payment</h2>
                    </div>
                    <WalletConnectButton onWalletConnected={handleWalletConnected} appName={appName} />
                </>
            )}
            {walletNwcUrl && (
                <>
                    <div className="col-span-2 flex justify-between">
                        <WalletConnectButtonSuccess />
                    </div>
                    <div className="col-span-2 flex justify-between">
                        <button
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Purchase Subscription
                        </button>
                    </div>
                </>
            )}
        </form>
    );
};

export default ProductOrderForm;
