import React, { useState, useEffect } from 'react';

const SubscribersTable = ({ flash }) => {
  const [subscribers, setSubscribers] = useState([])


  useEffect(() => {
    const fetchSubscribers = async () => {
      const apiEndpoint = flash.flash_type.includes("Vimeo")
        ? `https://api.paywithflash.com/vimeo_ott/api/get_vimeo_ott_user_memberships_for_flash_id?user_public_key=${flash.user_public_key}&vimeo_ott_flash_id=${flash.id}`
        : `https://api.paywithflash.com/api/get_subscribed_user_memberships_with_details?flash_id=${flash.id}`;

        try {
          const response = await fetch(apiEndpoint);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          console.log(data)
          const fetchedSubscribers = data.vimeo_ott_user_memberships || data.user_memberships;
    
    
          setSubscribers(fetchedSubscribers);
        } catch (error) {
          console.error('Error fetching subscriptions:', error);
        }
      };
    
      if (flash && flash.id) fetchSubscribers();
    }, [flash]);

  return (
    <div className="mt-6 mb-6 rounded-lg overflow-hidden ring-1 ring-indigo-200 shadow-sm">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white ">
                    <tr>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">ID</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Plan</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Email Address</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">User Public Key</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Status</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Subscription Start Date</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Next Payment Date</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-indigo-100 bg-white ">
                    {subscribers.map((subscriber, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-500">{index + 1}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{subscriber.plan_name}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{subscriber.email}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                          <div className="overflow-x-auto" style={{maxWidth: '150px'}}>
                            {subscriber.user_public_key}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                          {subscriber.status.charAt(0).toUpperCase() + subscriber.status.slice(1)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                          {new Date(subscriber.membership_start_date).toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                          {new Date(subscriber.next_payment_date).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
            </table>
          </div>
        </div>
  );
}

export default SubscribersTable;
