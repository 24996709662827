import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    ClipboardIcon,
    EyeIcon,
    InformationCircleIcon
} from '@heroicons/react/24/outline';

import * as Utils from '../components/FlashForms/utils.js';
import SidebarLayout from '../components/SidebarLayout.js';
import Header from '../components/FlashStatic/Header.js';
import StatsCharts from '../components/FlashServices/StatsChart.js';
import SubscribersTable from '../components/FlashServices/SubscribersTable.js';
import CountdownTimer from '../components/FlashServices/CountdownTimer.js'

import SubscriptionPlans from '../components/FlashServices/SubscriptionsPlan.js'

import { PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import { LuMousePointerClick } from 'react-icons/lu';
import { BiLink, BiSolidLockAlt, BiCopy, BiCheck } from 'react-icons/bi';
import { ArrowLongLeftIcon } from '@heroicons/react/24/outline';
import { BsFillFileLock2Fill, BsFillCameraVideoOffFill } from 'react-icons/bs';

import { EditSubscription } from '../components/FlashCards/EditFlashTemplate.js';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const renderFlashIcon = (flashType) => {
    const iconClasses = "h-5 w-5";
    const spanClasses = "inline-flex rounded-xl p-3 ring-4 ring-white";

    switch (flashType) {
        case "Paywall":
            return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BiSolidLockAlt className={iconClasses} aria-hidden="true" /></span>;
        case "Payment button":
            return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><LuMousePointerClick className={iconClasses} aria-hidden="true" /></span>;
        case "Payment link":
            return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BiLink className={iconClasses} aria-hidden="true" /></span>;
        case "Video Paywall":
            return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BsFillCameraVideoOffFill className={iconClasses} aria-hidden="true" /></span>;
        default:
            return null;  // Or handle default case
    }
};

export const SummaryCopyButton = ({ text, onClick }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleClick = () => {
        setIsCopied(true);
        onClick(); // Call the passed onClick function
    };

    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => setIsCopied(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    return (
        <div className="mt-4">
            <button
                onClick={handleClick}
                className={`rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm flex items-center ${isCopied ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-600 hover:bg-blue-700'
                    }`}
            >
                {isCopied ? 'Copied!' : text}
                {isCopied ? <BiCheck className="ml-2" aria-hidden="true" /> : <BiCopy className="ml-2" aria-hidden="true" />}
            </button>
        </div>
    );
};

export const SummarySnippet = ({ snippet, label }) => (
    <div className="mt-2">
        <label className="block text-sm font-medium text-indigo-300 mb-2 mr-2">{label}</label>
        <textarea
            disabled={true}
            rows={2}
            name="snippet"
            id="snippet"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={snippet}
        />
    </div>
);

export const SummaryKey = ({ keyForWebhook, label }) => {
    const [visible, setVisible] = useState(false);

    const handleVisibleChange = () => {
        setVisible(!visible);
    }

    return (
        <div className="mt-2">
            <label className="block text-sm font-medium text-indigo-300 mb-2 mr-2">{label}</label>
            <div className='flex'>
                <input
                    type="text"
                    rows={1}
                    name="key"
                    id="key"
                    disabled={true}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-2"
                    value={keyForWebhook ? (visible ? keyForWebhook : '********************************************************************************') : ''}
                />
                <EyeIcon className="h-7 w-7 text-gray-500 eye-icon m-auto" onClick={handleVisibleChange} />
            </div>
        </div>
    )
};

export const SummaryFooter = () => (
    <div className="text-xs text-gray-400 text-medium mt-3 mb-8">
        <p className="credit-text">Powered by </p>
        <img className="h-18 w-auto credit-logo" src="/flash-logo-dark.png" alt="Your Company" />
    </div>
);

const ServiceDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const flash = location.state.flash;

    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    let userNsec = localStorage.getItem('userNsec') || "";
    let userNpub = localStorage.getItem('userNpub') || "";
    let userName = localStorage.getItem('userName') || "";
    let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
    let userAbout = localStorage.getItem('userAbout') || "";

    const [activeTab, setActiveTab] = useState('details');
    const [isActive, setIsActive] = useState(false);
    const [walletDetails, setWalletDetails] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [totalRevenueUSD, setTotalRevenueUSD] = useState(0);
    const [totalRevenueSats, setTotalRevenueSats] = useState(0);
    const [transactionsPerDay, setTransactionsPerDay] = useState([]);
    const [transactionDays, setTransactionDays] = useState([]);
    const [plans, setPlans] = useState([]);
    const [snippet, setSnippet] = useState(flash.script);
    const [key, setKey] = useState(flash.key);
    const [flashPriceInDollars, setFlashPriceInDollars] = useState([]);

    const handleTabChange = (tab) => setActiveTab(tab);
    const toggleActive = () => setIsActive(!isActive);

    const handleBackClick = () => {
        navigate('/subscriptions');
    };

    const handleCopy = async (snippetText) => {
        try {
            await navigator.clipboard.writeText(snippetText);
        } catch (error) {
            console.error('Failed to copy snippet:', error);
        }
    };

    const handleDeleteFlash = async () => {
        try {
            const response = await fetch('https://api.paywithflash.com/api/delete_flash', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `flash_id=${encodeURIComponent(flash.id)}`,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Delete result:', result);

            navigate('/my-services');
        } catch (error) {
            console.error('Error deleting flash:', error);
        }
    };

    const determineFlashStatus = (flash) => {
        const expiryDate = new Date(flash.expiry_date);
        const now = new Date();
        return expiryDate > now ? 'active' : 'expired';
    };

    const renderStatus = (status) => {
        let bgColor, textColor, text;
        switch (status) {
            case 'active':
                bgColor = 'bg-emerald-500';
                textColor = 'text-gray-500';
                text = 'Active';
                break;
            case 'paused':
                bgColor = 'bg-yellow-500';
                textColor = 'text-gray-500';
                text = 'Paused';
                break;
            case 'expired':
                bgColor = 'bg-red-500';
                textColor = 'text-gray-500';
                text = 'Expired';
                break;
            default:
                bgColor = 'bg-gray-500';
                textColor = 'text-gray-500';
                text = 'Unknown';
        }

        return (
            <div className="mt-1 flex items-center gap-x-1.5">
                <div className={`flex-none rounded-full ${bgColor}/20 p-1`}>
                    <div className={`h-1.5 w-1.5 rounded-full ${bgColor}`} />
                </div>
                <p className={`text-xs leading-5 ${textColor}`}>{text}</p>
            </div>
        );
    };

    useEffect(() => {
        if (flash) {
            const fetchConvertedValue = async () => {
                const value = await Utils.convertCurrencyForFlashDetails(flash.price, flash.currency);
                setFlashPriceInDollars(value);
            };
            fetchConvertedValue();
        }
    }, [flash]);

    async function fetchWalletDetails(walletId) {
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_wallet_from_id?wallet_id=${encodeURIComponent(walletId)}`, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching wallet details:', error);
            return null;
        }
    }

    async function fetchSubscriptionPlans(flash_id) {
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_memberships_by_flash_id?flash_id=${encodeURIComponent(flash_id)}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data.memberships;
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    }

    async function fetchTransactionsByFlashId(flashId) {
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_transactions_by_flash_id?flash_id=${encodeURIComponent(flashId)}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            return data.transactions;
        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    }

    useEffect(() => {
        if (flash && flash.wallet_id) {
            fetchWalletDetails(flash.wallet_id)
                .then(details => {
                    setWalletDetails(details);
                })
                .catch(error => console.error('Error fetching wallet details:', error));
        }
    }, [flash]);

    useEffect(() => {
        if (flash && flash.id) {
            fetchSubscriptionPlans(flash.id)
                .then(plans => {
                    setPlans(plans);
                })
                .catch(error => console.error('Error fetching plans:', error));
        }
    }, [flash]);

    useEffect(() => {
        if (flash && flash.id) {
            fetchTransactionsByFlashId(flash.id)
                .then(transactions => {
                    setTransactions(transactions);
                })
                .catch(error => console.error('Error fetching transactions:', error));
        }
    }, [flash]);

    useEffect(() => {
        if (transactions && transactions.length > 0) {
            const totalUSD = transactions.reduce((acc, transaction) => {
                return acc + (Number(transaction.value_in_usd) || 0);
            }, 0);
            const totalSats = transactions.reduce((acc, transaction) => {
                return acc + (Number(transaction.amount_in_sats) || 0);
            }, 0);

            setTotalTransactions(transactions.length);
            setTotalRevenueUSD(totalUSD);
            setTotalRevenueSats(totalSats);
        } else {
            setTotalTransactions(0);
            setTotalRevenueUSD(0);
            setTotalRevenueSats(0);
        }
    }, [transactions]);

    useEffect(() => {
        if (transactions && transactions.length > 0) {
            const transactionCounts = {};

            transactions.forEach(transaction => {
                const date = new Date(transaction.transaction_date).toLocaleDateString('en-US');
                if (transactionCounts[date]) {
                    transactionCounts[date] += 1;
                } else {
                    transactionCounts[date] = 1;
                }
            });

            const days = Object.keys(transactionCounts);
            const counts = days.map(day => transactionCounts[day]);

            setTransactionDays(days);
            setTransactionsPerDay(counts);
        }
    }, [transactions]);

    return (
        <SidebarLayout>
            <Header
                title="Subscription details"
                subtitle="See all the details of your Subscription"
                showWalletsAvatar={false}
            />
            <article className="project-details rounded bg-white shadow-sm ring-1 ring-gray-900/5">
                <div className='py-0'>
                    <div className="mt-0 mx-auto px-8 sm:px-8 lg:px-8">
                        <button
                            type="button"
                            onClick={() => handleBackClick()}
                            className="mt-6 mb-4 inline-flex items-center gap-x-2  rounded bg-white px-4 py-2 text-sm font-semibold text-gray-900 ring-1  ring-gray-200 hover:bg-gray-50 mb-8"
                        >
                            <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Back
                        </button>
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="flex items-center gap-x-4">
                                {renderFlashIcon(flash.flash_type)}
                                <h2 className="truncate text-xl font-bold text-gray-900">{flash.name}</h2>
                                <div className="ml-6">
                                    {renderStatus(determineFlashStatus(flash))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-2 sm:mt-2 2xl:mt-5">
                    <div className="border-b border-gray-200">
                        <div className="mx-auto px-4 sm:px-6 lg:px-10">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                <button
                                    className={`whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${activeTab === 'details' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                                    onClick={() => handleTabChange('details')}
                                >
                                    Details
                                </button>
                                <button
                                    className={`whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${activeTab === 'stats' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                                    onClick={() => handleTabChange('stats')}
                                >
                                    Stats
                                </button>
                                <button
                                    className={`whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${activeTab === 'subscribers' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                                    onClick={() => handleTabChange('subscribers')}
                                >
                                    Subscribers
                                </button>
                                <button
                                    className={`whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ${activeTab === 'settings' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                                    onClick={() => handleTabChange('settings')}
                                >
                                    Settings
                                </button>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className="mx-auto mt-8 px-8 sm:px-8 lg:px-10 mb-18">
                    {activeTab === 'details' && (
                        <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Flash Name</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{flash.name}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Flash Image Url</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{flash.flash_image_url}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Created Date</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {new Date(flash.creation_date).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Flash Type</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{flash.flash_type}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Login Method</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{flash.flash_login_method}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Expiry Date</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {
                                            (() => {
                                                const oneYearFromNow = new Date();
                                                oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                                                return new Date(flash.expiry_date) > oneYearFromNow
                                                    ? "Lifetime"
                                                    : new Date(flash.expiry_date).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    });
                                            })()
                                        }
                                    </dd>
                                </div>
                                {flash.flash_type !== "Subscription Plan" && (
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Flash Price (sats)</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {new Intl.NumberFormat('en-US', {
                                                maximumFractionDigits: 0
                                            }).format(flash.price)} sats
                                        </dd>
                                    </div>
                                )}
                                {flashPriceInDollars && flash.flash_type !== "Subscription Plan" && (
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Flash Price (USD)</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {flashPriceInDollars}
                                        </dd>
                                    </div>
                                )}
                                {walletDetails && (
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Wallet Connected</dt>
                                        <dd className="mt-1 text-sm text-gray-900">{walletDetails.user_wallet_name}</dd>
                                    </div>
                                )}
                                {flash.flash_type === "Subscription Plan" && (
                                    <div className="sm:col-span-2 mr-6 mb-6">
                                        <dt className="text-sm font-medium text-gray-500">Plans</dt>
                                        <SubscriptionPlans plans={plans} />
                                    </div>
                                )}
                                {(flash.flash_type === "Subscription Plan" || flash.flash_type === "Payment button") ? (
                                    <div className="sm:col-span-2 mr-6 mb-6">
                                        <SummarySnippet label="Subscription Url" snippet={flash.script} />
                                        <SummaryCopyButton text="Copy Url" onClick={() => handleCopy(snippet)} />
                                        <SummaryKey label="Subscription Secret" keyForWebhook={flash.key} />
                                        <SummaryCopyButton text="Copy Secret" onClick={() => handleCopy(key)} />
                                        <EditSubscription flash={flash} />
                                        <SummaryFooter />
                                    </div>
                                ) : (
                                    <div className="sm:col-span-2 mr-6 mb-6">
                                        <SummarySnippet label="Subscription Url" snippet={flash.script} />
                                        <SummaryCopyButton text="Copy Url" onClick={() => handleCopy(snippet)} />
                                        <SummaryFooter />
                                    </div>
                                )}
                            </dl>
                        </div>
                    )}
                    {activeTab === 'stats' && (
                        <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Total Transactions</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{totalTransactions}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Total Revenue</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalRevenueUSD)}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Total Revenue (in sats)</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {new Intl.NumberFormat('en-US').format(totalRevenueSats)} sats
                                    </dd>
                                </div>
                            </dl>
                            <div className='dsaf -ml-2 mt-6 mr-4'>
                                <StatsCharts
                                    transactionsPerDay={transactionsPerDay}
                                    transactionDays={transactionDays}
                                />
                            </div>
                        </div>
                    )}
                    {activeTab === 'subscribers' && (
                        <SubscribersTable flash={flash} className="mb-6" />
                    )}
                    {activeTab === 'settings' && (
                        <div>
                            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Delete Flash</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <button
                                        onClick={handleDeleteFlash}
                                        className="rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                    >
                                        Delete Flash
                                    </button>
                                </dd>
                            </div>
                        </div>
                    )}
                </div>
            </article>
        </SidebarLayout>
    );
};

export default ServiceDetails;
