import React, { useState, useEffect } from 'react';
import SidebarLayout from '../components/SidebarLayout';
import TransactionsTableWithFilters from '../components/MyIncome/IncomeTables/TransactionsTableWithFilters';
import Header from '../components/FlashStatic/Header';
import TransactionsTable from '../components/MyIncome/IncomeTables/TransactionTable';



function Transactions() {
  // Get env var
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY

  // Get the values from session storage
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  let userAbout = localStorage.getItem('userAbout') || "";

  const [activeSection, setActiveSection] = useState('view');
  const [transactionsData, setTransactionsData] = useState([]);


  useEffect(() => {
    fetchTransactionsWithDetails(userHexPublicKey)
      .then(data => {
        if (data && data.transactions) {
          setTransactionsData(data.transactions);
        }
      });
  }, [userHexPublicKey]);

  const handleUploadCancel = (newStatus) => {
    setActiveSection(newStatus);
  };

  async function fetchTransactionsWithDetails(userPublicKey) {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_incoming_new_transactions_by_user?user_public_key=${encodeURIComponent(userPublicKey)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }


  return (
    <div className="min-h-screen">
      <SidebarLayout>
        <Header 
          title="Transactions" 
          subtitle="View all the income from your flashes here."
          showWalletsAvatar={false} // Set this to true or false as needed
          // ... other header props ...
        />

        <div className='p-6'>
          <TransactionsTable
            initialData={transactionsData}
            columnsToShow={['id', 'lnurl', 'amount_in_sats', 'value_in_usd', 'flash_name', 'transaction_date']}
          />
        </div>
        
        {/* You can place the Example component wherever it fits within your layout */}
        {/* Other components or content can go here */}
      </SidebarLayout>
    </div>
  );
}

export default Transactions;
