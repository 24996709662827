import React, { useState } from 'react';
import { BiLogoBitcoin, BiDollarCircle, BiWallet, BiSolidLockAlt, BiCopy, BiTimeFive, BiUser } from 'react-icons/bi';

const ConnectAPIForm = ({ close }) => {
  const [currency, setCurrency] = useState('Satoshis');
  const [wallet, setWallet] = useState('Alby');
  const [price, setPrice] = useState('');
  const [flashTitle, setFlashTitle] = useState('');
  const [duration, setDuration] = useState('1 day');
  const user = 'Cierre Porbin'; // This could also be managed by a state if it needs to be dynamic

  const SATOSHI_TO_DOLLAR_RATE = 0.0002616; // Assuming 1 USD = 100000 Satoshis

  // Function to render the correct icon based on the currency state
  const renderCurrencyIcon = () => {
    switch (currency) {
      case 'Satoshis':
        return <BiLogoBitcoin className="h-5 w-5" aria-hidden="true" />;
      case 'Dollar':
        return <BiDollarCircle className="h-5 w-5" aria-hidden="true" />;
      default:
        return null;
    }
  };

  // Function to render the correct icon based on the wallet state
  const renderWalletIcon = () => {
    switch (wallet) {
      case 'Alby':
        return <BiWallet className="h-5 w-5" aria-hidden="true" />;
      case 'Nuum':
        return <BiWallet className="h-5 w-5" aria-hidden="true" />;
      default:
        return null;
    }
  };

    const handleFlashTitleChange = (e) => {
    setFlashTitle(e.target.value);
  };

   const formatPriceWithSeparator = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Function to format price based on currency
  const formatPrice = (price, currency) => {
    return currency === 'Satoshis' ? `${formatPriceWithSeparator(price)} sats` : `$${formatPriceWithSeparator(price)}`;
  };

  // Function to convert and display the price in the other currency
  const convertCurrency = () => {
    let convertedValue = '';
    if (price) {
      const numericPrice = parseFloat(price.replace(/,/g, '')); // Remove commas for calculation
      if (currency === 'Satoshis') {
        const dollars = (numericPrice * SATOSHI_TO_DOLLAR_RATE).toFixed(2);
        convertedValue = `~${formatPriceWithSeparator(dollars)} dollars`;
      } else {
        const sats = (numericPrice / SATOSHI_TO_DOLLAR_RATE).toFixed(0);
        convertedValue = `~${formatPriceWithSeparator(sats)} sats`;
      }
    } else {
      convertedValue = currency === 'Satoshis' ? '~0 dollars' : '~0 sats';
    }
    return convertedValue;
  };

  const PaymentLinkSummary = () => (
    <div className="lg:col-start-3 lg:row-end-1">
      <h2 className="sr-only">Summary</h2>
<div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-6">
        <dl className="space-y-2">
          <div className="flex justify-between items-center">
            <dt className="text-xl font-semibold leading-6 text-gray-900 ml-2">{flashTitle || 'Flash Title'}</dt>

            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
              
              <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                Active
              </span>
            </dd>
            
          </div>
          <p className="max-w-4xl text-xs text-gray-400 ml-2 mt-0 date-flash-recap">Created on March 31st 2024</p>

          <div className="flex-auto pl-2 mb-8 recap-list">
            <dt className="sr-only ">Price</dt>
            <dd className="text-base font-semibold leading-6 text-gray-900">
              {formatPrice(price, currency)}
            </dd>
                          <p className="text-sm text-gray-400 font-regular mt-1 mb-6">
                {convertCurrency()}
              </p>
          </div>
          {/* Other information such as Duration, Connected Wallet, and User */}
          <div className="mt-4 flex w-full flex-none gap-x-4 px-2">
            <BiWallet className="h-6 w-5 text-gray-400" aria-hidden="true" />
            <dd className="text-sm leading-6 text-gray-500">{wallet}</dd>
          </div>
                    <div className="mt-6 flex w-full flex-none gap-x-4 px-2">
            <BiTimeFive className="h-6 w-5 text-gray-400" aria-hidden="true" />
            <dd className="text-sm leading-6 text-gray-500">{duration}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-2">
            <BiUser className="h-6 w-5 text-gray-400" aria-hidden="true" />
            <dd className="text-sm leading-6 text-gray-500">by {user}</dd>
          </div>
        </dl>
        <div className="mt-6">
          <button className="rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 flex items-center">
            Copy link <BiCopy className="ml-2" aria-hidden="true" />
          </button>
        </div>
        <div className="text-xs text-gray-400 text-medium mt-6 mb-8"><p className="credit-text">Powered by </p>            <img
              className="h-18 w-auto credit-logo"
              src="/FLASH.png"
              alt="Your Company"
        /></div>

      </div>
      
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row md:space-x-4 modal-base">
      {/* Form Column */}
      <div className="flex-1 p-14">
        {/* Heading */}
              <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20 mb-4">
        New Egg
      </span>
        <div className="md:flex md:items-center md:justify-between">
          
          <div className="min-w-0 flex-1">
            <h3 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
              Connect an API
            </h3>
                  <p className="mt-3 max-w-4xl text-sm text-gray-500 mb-3">
              <p>Accept Bitcoin payments through Lightning with a simple link. </p>
             <p>Share it to anyone, pause or delete anytime.</p> 
      </p>
      
          </div>
        </div>
        

      {/* Form */}
      <form className="mt-6">
        <div className="grid grid-cols-1 gap-y-6">
                  {/* Flash Title Input */}
        <div>
          <label htmlFor="flash-title" className="block text-sm font-medium text-gray-700">
            Flash Title
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="flash-title"
              id="flash-title"
              autoComplete="off"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter the flash title"
              value={flashTitle} // Bind input to the flashTitle state
              onChange={handleFlashTitleChange} // Update the state when the input changes
            />
          </div>
        </div>

               {/* Flash Price and Currency aligned on the same line */}
      <div className="md:flex md:items-end">
        {/* Flash Price */}
        <div className="md:flex-1">
          <label htmlFor="flash-price" className="block text-sm font-medium text-gray-700">
            Flash Price
          </label>
          <div className="mt-1 relative">
            <input
              type="number"
              name="flash-price"
              id="flash-price"
              autoComplete="off"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter the flash price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            {/* Converter output positioned absolutely to not affect the layout */}
            <p className="text-sm text-gray-400 absolute bottom-0 right-0 mb-2 mr-3">
              {convertCurrency()}
            </p>
          </div>
        </div>

        {/* Currency */}
        <div className="md:flex-1 md:ml-6">
          <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
            Currency
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
              {renderCurrencyIcon()}
            </span>
            <select
              id="currency"
              name="currency"
              autoComplete="off"
              className="block flex-1 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value="Satoshis">Satoshis</option>
              <option value="Dollar">Dollars</option>
            </select>
          </div>
        </div>
      </div>

          {/* Wallet Dropdown */}
          <div>
            <label htmlFor="wallet" className="block text-sm font-medium text-gray-700">
              Wallet
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
                {renderWalletIcon()}
              </span>
              <select
                id="wallet"
                name="wallet"
                autoComplete="off"
                className="block flex-1 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
              >
                <option value="Alby">Alby</option>
                <option value="Nuum">Nuum</option>
              </select>
            </div>
          </div>

          {/* Flash Duration */}
<div>
          <label htmlFor="flash-duration" className="block text-sm font-medium text-gray-700">
            Flash Duration
          </label>
          <select
            id="flash-duration"
            name="flash-duration"
            autoComplete="off"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={duration} // Bind the select to the duration state
            onChange={(e) => setDuration(e.target.value)} // Update the state when the selection changes
          >
            <option value="1 day">1 day</option>
            <option value="7 days">7 days</option>
            <option value="lifetime">Lifetime</option>
          </select>
            </div>
            </div>
        {/* Close Button */}
          <div className="mt-10 flex justify-start">
            <button
              type="button"
              onClick={close}
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700"
            >
              Create payment link
            </button>
                        <button
              type="button"
              onClick={close}
              className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-300 ml-2"
            >
              Cancel
            </button>
          </div>

          <p className="mt-2 max-w-4xl text-sm text-gray-300">
            <BiSolidLockAlt className='icon-lock'/>
            
        Payments will go directly to your connected wallet, through Flash. 
      </p>
        </form>
      </div>

      {/* Grey Background Column */}
      <div className="flex-1 bg-gray-100 p-16">
        {/* You can add content here if needed */}
        
    <PaymentLinkSummary 
          price={price}
          currency={currency}
          flashTitle={flashTitle}
          wallet={wallet}
          duration={duration} // Pass the duration state as a prop
          user={user}
        />

        
      </div>
    </div>
  );
};

export default ConnectAPIForm;