import React, { useState } from 'react';


import LoginWithNostr from './LoginWithNostr';
import LoginWithEmail from './LoginWithEmail';
import SignUpWithEmail from './SignUpWithEmail';
import SignUpWithNostr from './SignUpWithNostr';


function LoginPage(props) {
  // Access props using props.propertyName
  const { appName, onLoginSuccess } = props;
  const [useEmailLogin, setUseEmailLogin] = useState(true);
  const [viewingSignup, setViewingSignup] = useState(false); // New state to control login or signup view

    const handleResetPasswordClick = () => {
        window.open('/password-reset-request', '_blank'); // Use the path you defined for the password reset page
    };

    const toggleLoginMethod = () => {
        setUseEmailLogin(!useEmailLogin);
    };

    const toggleSignupView = () => {
        // Open the login page in a new tab
        window.open('/login', '_blank');
    };


          

  return (
    <div className="">
    <div className="w-full flex flex-col rounded-2xl shadow-2xl justify-center px-4 sm:px-12 md:px-24 py-8 sm:py-12 text-white" style={{ backgroundImage: 'radial-gradient(circle, rgb(37 13 79), rgb(20 6 50))' }}>
      <div className="text-left"> {/* This div will align all enclosed text to the left */}
        {!viewingSignup && (
          <>
            {useEmailLogin ? (
              <>
                <LoginWithEmail onLoginSuccess={onLoginSuccess} />

                <p className="mt-10 text-xs sm:text-sm">
                  Don't have an account? It'll only take a minute,{' '}
                  <button onClick={toggleSignupView} className="font-semibold text-xs sm:text-sm leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                    create one!
                  </button>
                </p>
                <p className="text-xs sm:text-sm">
                  Forgot your password?{' '}
                  <button onClick={handleResetPasswordClick} className="font-semibold text-xs sm:text-sm leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                      Reset it.
                  </button>
                </p>
                <button
                onClick={toggleLoginMethod}
                className="mt-2 text-xs sm:text-sm ml-4 bg-transparent border-none p-0 hover:text-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                style={{ textDecoration: 'underline' }}
                >
                  Log in with Nostr →
                </button>
              </>
            ) : (
              <>
                <LoginWithNostr appName={appName} onLoginSuccess={onLoginSuccess} />
                <p className="mt-10 text-xs sm:text-sm">
                    Don't have an account? It'll only take a minute,{' '}
                  <button onClick={toggleSignupView} className="font-semibold text-xs sm:text-sm leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                    create one!
                  </button>
                </p>
                <button
                onClick={toggleLoginMethod}
                className="mt-2 mr-4 text-xs sm:text-sm bg-transparent border-none p-0 hover:text-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                style={{ textDecoration: 'underline' }}
                >
                  Don't feel confident handling your own keys? Login with Email!
                </button>
              </>
            )}
          </>
        )}

        {viewingSignup && (
          <>
            {useEmailLogin ? (
              <>
                <SignUpWithEmail appName={appName} onLoginSuccess={onLoginSuccess} />
                <p className="ml-4 mt-10 text-xs sm:text-sm">
                  Already have an account?{' '}
                  <button onClick={toggleSignupView} className="font-semibold leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                    Log in!
                  </button>
                </p>
                <button
                  onClick={toggleLoginMethod}
                  className="mt-2 ml-4 mt-0 text-xs sm:text-sm bg-transparent border-none p-0 hover:text-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                  style={{ textDecoration: 'underline' }}
                >
                  Want more privacy? Sign up with Nostr!
                </button>
              </>
            ) : (
              <>
                <SignUpWithNostr appName={appName} onLoginSuccess={onLoginSuccess} />
                <p className="ml-4 mt-10 text-xs sm:text-sm">
                  Already have an account?{' '}
                  <button onClick={toggleSignupView} className="font-semibold leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                    Log in!
                  </button>
                </p>
                <button
                  onClick={toggleLoginMethod}
                  className="mt-2 ml-4 mr-4 text-xs sm:text-sm bg-transparent border-none p-0 hover:text-purple-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
                  style={{ textDecoration: 'underline' }}
                >
                  Don't feel confident handling your own keys? Sign up with email!
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  </div>

  );
}

export default LoginPage;
