import React, { useState, useEffect } from 'react';
import { getPublicKey, generatePrivateKey } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19, SimplePool, matchFilter, Kind } from 'nostr-tools';

const { npubEncode, nsecEncode, decode } = nip19;

const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};


const convertNostrToHex = (nostr) => {
  const { type, data } = decode(nostr);
  
  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid nostr. Nostr must be "npub" or "nsec".');
  }
};


const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();
  const filters = [{ pubkey: publicKey, kinds: [0] }];

  try {
    const events = await pool.list(relayUrls, filters);
    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);

    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");

    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      const userName = metadata.name || 'Unknown User';
      const userPictureUrl = metadata.picture || 'default_picture_url'; // Replace with a default picture URL
      const userAbout = metadata.about || 'No description available';

      return { userName, userPictureUrl, userAbout };
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', // Replace with a default picture URL
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};



function LoginWithNostr(props) {

  // Access props using props.propertyName
  const { appName, onLoginSuccess } = props;

  const [privateKey, setPrivateKey] = useState('');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for handling error messages


  const handleLogin = async () => {
    setErrorMessage('');
  
    if (!privateKey.trim()) {
      setErrorMessage('Please enter a Nostr private key.');
      return;
    }
  
    setLoading(true);

    let hexPrivateKey;
  
    try {
      // Logic to process privateKey...
      if (privateKey.startsWith('nsec')) {
        hexPrivateKey = convertNostrToHex(privateKey);
      } else {
        hexPrivateKey = privateKey;
      }

      const userPublicKey = getPublicKey(hexPrivateKey);
      const userPrivateKey = hexPrivateKey;

      // Fetch the metadata
      const { userName, userPictureUrl, userAbout } = await fetchUserMetadata(userPublicKey, ["wss://relay.paywithflash.com", "wss://relay.damus.io"]);

      // Call the callback function with the login data
      onLoginSuccess({ userPrivateKey, userPublicKey, userName, userPictureUrl, userAbout });
    } catch (error) {
      setErrorMessage("This Nostr private key doesn't exist or an error occurred.");
      return false; // Indicate failure
    } finally {
      setLoading(false);
    }
  
  };
  

  const togglePrivateKeyVisibility = (e) => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };


  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="px-4 sm:px-6 lg:px-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-32 sm:w-48"
            src="https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/flash-logo-light.png"
            alt="Flash logo"
          />
        </div>
        <h2 className="mt-6 text-center text-xl sm:text-2xl font-bold leading-9 tracking-tight">
          Log in to Flash
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <form className="space-y-6">
          <div>
            <label htmlFor="privateKey" className="block text-sm font-medium leading-6">
              Private Key
            </label>
            <div className="mt-1 relative">
              <input
                id="privateKey"
                name="privateKey"
                type={showPrivateKey ? 'text' : 'password'}
                required
                value={privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm"
              />
              <button 
                onClick={togglePrivateKeyVisibility} 
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300"
              >
                <FaEye />
              </button>
            </div>
            {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
          </div>
          <div>
            <button
              type="button"
              onClick={handleLogin}
              className="flex w-full justify-center rounded-md bg-purple-800 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </>
);

  
}

export default LoginWithNostr;
