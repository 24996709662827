import React, { useState } from 'react';

function SaveChangesComponent({ is_owner, on_save, handleBackButton, on_delete_vectorstore, vectorstore_ID }) {
    const [showDangerZone, setShowDangerZone] = useState(false);

    return (
        <React.Fragment>
            <div className="flex flex-col items-center mb-6 mt-6">
                <div className="flex items-center justify-end gap-x-6">
                    <button
                        type="button"
                        disabled={!is_owner}
                        onClick={handleBackButton}
                        className="text-sm font-semibold leading-6 text-gray-900"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        disabled={!is_owner}
                        className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${is_owner ? 'bg-purple-800 hover:bg-purple-900 text-white' : 'bg-purple-800 opacity-50 text-white cursor-not-allowed'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                        onClick={on_save}
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        disabled={!is_owner}
                        className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${is_owner ? 'bg-red-800 hover:bg-red-900 text-white' : 'bg-red-800 opacity-50 text-white cursor-not-allowed'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600`}
                        onClick={() => setShowDangerZone(!showDangerZone)}
                    >
                        Delete
                    </button>
                </div>

                {showDangerZone && (
                    <div className="mt-4 p-4 border-2 border-red-700 bg-red-100 rounded w-full max-w-lg">
                        <p className="mb-4 text-sm"><strong>Danger zone:</strong> You are about to delete this vector database. Remember, the data is yours and always available in the nostr relays you saved it in. Do not lose the vector database's ID: {vectorstore_ID}</p>
                        <div className="flex justify-end gap-x-4">
                            <button
                                type="button"
                                onClick={() => setShowDangerZone(false)}
                                className="bg-gray-300 hover:bg-gray-400 text-black rounded-md px-3 py-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={on_delete_vectorstore}
                                className="bg-red-800 hover:bg-red-900 text-white rounded-md px-3 py-2 text-xs font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default SaveChangesComponent;
