import React, { useEffect, useState } from 'react';

const OrderPrice = ({subTotal, subTax, totalInSatoshis}) => {
    return (
        <div className="px-5 mt-5">
            <div className="py-4 rounded-md shadow-lg">
                <div className="px-4 flex justify-between">
                    <span className="font-semibold text-sm">Subtotal</span>
                    <span className="font-bold">${subTotal}</span>
                </div>
                <div className="px-4 flex justify-between">
                    <span className="font-semibold text-sm">Sales Tax</span>
                    <span className="font-bold">${subTax}</span>
                </div>
                <div className="border-t-2 mt-3 py-2 px-4">
                    <div className="flex justify-between">
                        <span className="font-semibold text-2xl">Total</span>
                        <div>
                            <span className="font-bold text-2xl">${(parseFloat(subTotal) + parseFloat(subTax)).toFixed(2)}</span>
                            <div className="text-gray-400 text-sm">{totalInSatoshis} sats</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    
}

export default OrderPrice;