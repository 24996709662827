import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import CountdownTimer from './CountdownTimer'

import { BiLink, BiSolidLockAlt } from 'react-icons/bi';
import { BsFillCameraVideoOffFill, BsFillPersonVcardFill, BsPlayFill, BsLockFill, BsCreditCard } from 'react-icons/bs';
import { LuMousePointerClick } from 'react-icons/lu';


const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return 'bg-green-500';
    case 'paused':
      return 'bg-yellow-500';
    case 'archived':
      return 'bg-red-500';
    default:
      return 'bg-gray-500';
  }
    
}


const renderStatus = (status) => {
  let bgColor, textColor, text;
  switch (status) {
    case 'active':
      bgColor = 'bg-emerald-500';
      textColor = 'text-gray-500';
      text = 'Active';
      break;
    case 'paused':
      bgColor = 'bg-yellow-500';
      textColor = 'text-gray-500';
      text = 'Paused';
      break;
    case 'expired':
      bgColor = 'bg-red-500';
      textColor = 'text-gray-500';
      text = 'Expired';
      break;
    default:
      bgColor = 'bg-gray-500';
      textColor = 'text-gray-500';
      text = 'Unknown';
  }

  return (
    <div className="mt-1 flex items-center gap-x-1.5">
      <div className={`flex-none rounded-full ${bgColor}/20 p-1`}>
        <div className={`h-1.5 w-1.5 rounded-full ${bgColor}`} />
      </div>
      <p className={`text-xs leading-5 ${textColor}`}>{text}</p>
    </div>
  );
}


const renderFlashIcon = (flash) => {
  
  // Check if flash_image_url exists and is not empty
  if (flash.flash_image_url) {
    return (
      <span>
        <img src={flash.flash_image_url} 
            style={{ objectFit: 'contain' }} 
            className="p-2 h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
            alt="Flash" />
      </span>
    );
  } else {
    return (
      <span className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 bg-indigo-50 text-indigo-700 flex items-center justify-center">
        <BsFillPersonVcardFill className="h-5 w-5" aria-hidden="true" />
      </span>
    );
  }
};


export default function SubscriptionsList({ flashes }) {
  const [selectedFlash, setSelectedFlash] = useState(null);

  const navigate = useNavigate();

  const handleFlashSelect = (flash) => {
    navigate(`/subscription-details/${flash.id}`, { state: { flash } });
  };
  

  function handleDetailsClick(event) {
    event.stopPropagation();
  }

  function determineFlashStatus(flash) {
    const expiryDate = new Date(flash.expiry_date);
    const now = new Date();
    return expiryDate > now ? 'active' : 'expired';
  }


  // Sort flashes by status
  const sortedFlashes = flashes.sort((a, b) => {
    const statusA = determineFlashStatus(a);
    const statusB = determineFlashStatus(b);

    if (statusA === 'active' && statusB !== 'active') return -1;
    if (statusB === 'active' && statusA !== 'active') return 1;
    // Optionally, add more conditions here if you want to further sort by other statuses
    return 0;
  });

  
  return (
    <div className='p-6 border border-gray-200 bg-white rounded-xl'>
      <ul
        role="list"
        className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8"
      >
        {sortedFlashes.map((flash) => (
          <li
            key={flash.id}
            className="overflow-hidden rounded-xl border border-gray-200"
            onClick={(event) => handleFlashSelect(flash)}
          >
            <div className="border-b border-gray-900/5 bg-white p-6 flex justify-between items-center">
              {renderFlashIcon(flash)}
              <div className="min-w-0 flex-auto">
                <p className="ml-4 text-sm font-semibold leading-6 text-gray-900">
                  {flash.name}
                </p>
              </div>
              <div className="flex items-center gap-x-4">
                <div className="flex flex-col items-end">
                  {renderStatus(determineFlashStatus(flash))}
                </div>
                <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </div>
            </div>
            
          </li>
        ))}
      </ul>
    </div>
  )
}

