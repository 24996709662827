import React, { useState } from 'react';
import { nip04 } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { SimplePool } from 'nostr-tools';


// Function to fetch the user metadata
const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();

  const filters = [{
    pubkey: publicKey,
    kinds: [0]
  }];

  try {
    const events = await pool.list(relayUrls, filters);

    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    // Sort the events by the 'created_at' timestamp in descending order
    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);

    // Find the first event with non-empty content
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      // Store user metadata in variables
      const userName = metadata.name;
      const userPictureUrl = metadata.picture;
      const userAbout = metadata.about;

      return { userName, userPictureUrl, userAbout };
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', // Replace with a default picture URL
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};



export const fetchDecryptedContent = async (userPublicKey, encryptedProperties) => {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/decrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedProperties: encryptedProperties,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchDecryptedContent:', error);
    throw error;
  }
};



function LoginWithEmail(props) {

  // Access props using props.propertyName
  const { onLoginSuccess } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
  
    try {
      const response = await fetch('https://api.paywithflash.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ email, password })
      });
  
      const data = await response.json();
      
  
      if (response.ok) {
        // Successful login
        // Extracting data elements
        const encryptedUserPrivateKey = data.private_key;
        const userPublicKey = data.public_key;

        const userPrivateKey = await fetchDecryptedContent(userPublicKey, encryptedUserPrivateKey);

        // Fetch the metadata
        const { userName, userPictureUrl, userAbout } = await fetchUserMetadata(userPublicKey, ["wss://relay.paywithflash.com", "wss://relay.damus.io"]);
        
        // Call the callback function with the login data
        onLoginSuccess({ userPrivateKey, userPublicKey, userName, userPictureUrl, userAbout });

      } else {
        // Login failed
        setErrorMessage(data.error || 'Login failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred during login');
    } finally {
      setLoading(false);
    }
  };
  

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-32 sm:w-48"
            src="https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/flash-logo-light.png"
            alt="Flash logo"
          />
        </div>
        <h2 className="mt-6 text-center text-xl sm:text-2xl font-bold leading-9 tracking-tight">
          Log in to Flash
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-0 bg-white/5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6">
              Password
            </label>
            <div className="mt-1 relative">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm"
              />
              <button 
                onClick={togglePasswordVisibility} 
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300"
              >
                {/* Assuming <FaEye /> is an imported component */}
                <FaEye />
              </button>
            </div>
            {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-purple-800 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-500 focus-visible:ring-offset-2"
            >
              Log in
            </button>
          </div>
        </form>
      </div>
    </>
);

}

export default LoginWithEmail;
