import React, { useEffect, useState } from 'react';
import cn from 'classnames';

const Categories = ({ categories, selectedCategory, setCategory }) => {
    return (
        <div className="mt-5 flex flex-row px-5">
          <span className={cn ({'bg-indigo-500' : Object.keys(selectedCategory).length == 0, 'text-white' : Object.keys(selectedCategory).length == 0}, "px-5 py-1 rounded-2xl text-sm mr-4 cursor-pointer")} onClick = {() => { setCategory ({})}}>
            All items
          </span>
          { categories.map ((category, index) => {
            return (
              <span key = {index} className={cn ({"bg-indigo-500" : selectedCategory.CategoryID == category.CategoryID, "text-white" : selectedCategory.CategoryID == category.CategoryID}, "px-5 py-1 rounded-2xl text-sm mr-4 cursor-pointer")} onClick = {() => { setCategory (category)}}>
                {category.CategoryName}
              </span>
              )
            })}
      </div>
    )
}

export default Categories; 