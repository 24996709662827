// StatsComponent.js
import React from 'react';

const StatsComponent = ({ filteredData, currency }) => {
    let totalOrder = 0, totalQuantity = 0, totalUSD = 0.0, totalSats = 0, totalZloty = 0.0, maxTotalCostUSD = 0, maxTotalCostSats = 0, maxTotalCostZloty = 0;
    let f = {};
    for (const saleData of filteredData) {
        if (!f[saleData.saleId]) {
            totalOrder ++;
            totalQuantity += saleData.quantity;
            totalUSD += parseFloat(saleData.totalCostDollar);
            totalUSD = parseFloat(totalUSD.toFixed(2));
            totalSats += parseInt(saleData.totalCostSats, 10);
            totalZloty = parseFloat (totalZloty + saleData.totalCostZloty).toFixed (2);
            maxTotalCostUSD = Math.max(maxTotalCostUSD, parseFloat(saleData.totalCostDollar));
            maxTotalCostSats = Math.max(maxTotalCostSats, parseInt(saleData.totalCostSats, 10));
            maxTotalCostZloty = Math.max(maxTotalCostZloty, parseFloat(saleData.totalCostZloty));
            f[saleData.saleId] = 1; 
        }
    }
    const avgSaleAmountSats = totalSats ? `${parseFloat (totalSats / totalOrder).toLocaleString()} sats` : '0 sats';
    const avgSaleAmountZloty = totalZloty ? `${parseFloat (totalZloty / totalOrder).toFixed (2).toLocaleString()} PLN` : '0 PLN';
    const avgSaleAmountUSD = totalUSD ? `US$${parseFloat (totalUSD / totalOrder).toFixed (2).toLocaleString()}` : 'US$0';

    const stats = [
        { name: 'Total Orders', stat: [`${totalOrder} sales`, `${totalQuantity} items`] },

        { name: 'Total Revenue', stat: [currency == 'Dollar' ? `US$${totalUSD.toLocaleString()} ` : `${totalZloty.toLocaleString()} PLN`, `${totalSats.toLocaleString()} sats`] }, // Optionally format as string with commas
        { name: 'Average Sale Amount', stat: [currency == 'Dollar' ? avgSaleAmountUSD : avgSaleAmountZloty, avgSaleAmountSats] }, // Format with two decimal places
        { name: 'Biggest Sale', stat: [currency == 'Dollar' ? `US$${maxTotalCostUSD.toLocaleString()}` : `${maxTotalCostZloty.toLocaleString()} PLN`, `${maxTotalCostSats.toLocaleString()} sats`] }, // Format with two decimal places
    ];

    return (
        <div>
        <dl className="mb-6 grid grid-cols-1 gap-5 grid-cols-2 md:grid-cols-2 mt-0">
            {stats.map((item) => (
            <div key={item.name} className="overflow-hidden rounded-lg bg-white p-4 border-slate-50 shadow-sm  ">
                <div className="text-sm font-regular  text-slate-800 opacity-80 truncate">{item.name}</div>
                <div className = "flex flex-row flex-wrap items-end">
                    <div className="mt-0.5 text-xl font-semibold tracking-tight text-slate-800 mr-1">{item.stat[0]}</div>
                    <div className="mt-0.5 text-md font-semibold tracking-tight text-slate-500">{item.stat[1]}</div>
                </div>
            </div>
            ))}
        </dl>
        </div>
    );
};

export default StatsComponent;
