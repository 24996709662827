// PaywallTestPage.js

import React, { useEffect, useState} from 'react';

// import html "../public/article.html"

const PaywallTestPage = () => {
  const [articleContent, setArticleContent] = useState('');

  useEffect(() => {
    fetch('/article.html')
      .then(response => response.text())
      .then(html => setArticleContent(html));
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'external/paywall-script.js';
    script.dataset.config = 'eyJ1c2VyUHVibGljS2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFVzZXJQcml2YXRla2V5IjoiR3FIVkNGYzR0Vzh0ZjJ1VlFmaDlIa1VvRk9vRjFzODZKd3ZZZ20yTTVkQVpGUWpKMUprT1huUTBNaXVMekQ2eERVVXZJMGRwV0VxbjB1RXBINVRtdXEwRWpCZGliMmpoQ2JnOG1rVVBOWEU9P2l2PURVam04NjREUFVUUEd6UFRzTG1SZUE9PSIsImVuY3J5cHRlZFVzZXJOb3N0cldhbGxldENvbm5lY3RVcmwiOiJwSThjb053YmdjTGs2cVlDbFFOdUV3K3NSTUxuUkFQODVKdFU3NWpZcUlpbXp5eEdHMUNYOElJOWREYU9RTFovV3dDdXVRSy91eVJCeEN2SGFWYXQxMUR5Uzh0Z0plMklKeXNqcVZ0d0FKN2lXK1pjSUdDUWtZNnRBaVlpRnR0ZGEwMGpiVGlwVWE3ZVNFbCtnL0lqZmFNNjJsaENsTms1RnBtcHEzOFpzRkU5YlpxcXlKWFEydUNZQXErdjY4cEpBa1pZYWpKWTBqSC9lTVF2NWRqbUxFcHg5ejUvMkNWVDY3U21Na3VMdmVQUEFHd1ZoRjVwTzd0TEFyQytBbVVNMnU3OTd3QkZWRmlER014dmVaUVF6a2h2TGUrbWJZMlJ4RXY2MkNwdWJqcz0/aXY9dlhOajFIWFVwc3FjWWdlb2dEeW1tQT09IiwiZW5jcnlwdGVkUHJvcGVydGllcyI6InBDc1JIUEg3V0tPSjFnWjVVMmUwZWlmSXpOSEtpc29KTURvQ1BJR1lkV0taUnZYbVk3SEhRcGMxdHVoMUVSUld4K0s2cDN0RW01d25qOXhnZXhOTURXYk52WFlIakx3cHpMcDdJbitPUm1SR1VPbmRGMDJPbCtkSHc0TmFhdUV3T0RobDZ2eWpuYWJPOWZYelBTcTVmRllsSlNVWTZ3SEc4aUYyNmZtVzNIYz0/aXY9bVpTOVF4Z3REcUZRTkRrdnJLMmFqQT09In0='; // your encoded config here
    // Test with NWC eyJ1c2VyUHVibGlja2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiJNMnYweFdkT3owUmZET3o1ckpmYkpRL3hVQk1LakxsTEJLZlhZb2FRTjBRQjVVY1dHUVFURU5oeS9QbldkVEErMURMQU5Pc0NJOGFpd09TYXJBZWJBUzJyVUZxdUJQc2g2SEpXMFo0ZGRVcmlqQUFuZHBMbVVGMnBuU0hkU3BzeEZ4UkU1N1AwWEVSM214NjBsWmhVNzluSDJkaDlGNXJEeG1YWEdBbFRVdnA2YVdiNmxKNGk1cE5FZG9uOUQ0SSttcGR0blQ2cjVuekxlcXJpL1F2OXJ0U08rRlZJclAyNWJ5SkpYUU42YUo3SDJkVzRySUpVZk1aOGdXMXBDSENqR0dFZlY3Uk9KRHp4ZWpUd293K0Q0Nll4SkpIOUxycEZTK0F6M3VuOEVhNG40TXppcWtXaTFZeC9RZlhPUTQ1dXYxR2kyZUphbUVvblRlU1RkMmdLbkxvUmJHak83d0VaWUpmaG94Um1CWmNNVTkrZU5tczA5SUtua2E3Q0lmUEUzTlhKcGpmQ3lOdjFvWlZ6aVFYSmtsQThyN1NTMW5VSHNZYm83NXNweDFSekNtdUI0ZWVvWjUzK3luaHJLNFFvVEZOTWdnbENNeFN5Q3BTZzg0RDNXb3NvcGpxNXROTWRFWTVTMkoxUXI1UktuNVpKeVNMRkVpSXdNbTIxK1RHU2Z2SXNtazN2ZHhtRGZzVVdsb3drMUE9PT9pdj0zYW5QaVVKcmJNS0NOMU9WRXhKVENRPT0ifQ==
    // Test without NWC eyJ1c2VyUHVibGlja2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiJHQ051NHU1K2tEQmYyVmpucFZlZ3ZCSldOdVhzbVp5NUtvNUJrZVFXbmcvUkQwZzl3elRoaG0vNS9yL1hpaityR1lRemlDRVp5N1dVZDZoVkU0blM4dzYvNjRlM0EvTFdJNEtXUEFzNWxMOXNGVkFxV0VOQjBHMXdLbVlvaXRrMVF4UmJBc2hKeldjYUdoc3pDUm5yMWFmbDJ3MkNaMGpBeURIK3BTM3hJdzJ0WGZIaWNUdVcxQ28yZWFlZjRCR0p4VmwySXY0eVdmckZ4VGpBZXNuZDQ2aUxCSmFuQjJySXlEQlZTZ2N6WFlGaXFBNXgzTUFpVVk1cHJKUEg0VVBJcjR1UHVqNGNaeGZXN1B1bEhVTkRhMnVsYWVKTTZRUkdaaWtiSG9CR3M5RWoxM0w2eldEUEVpMVR5dlVueENXS1JjNVBYZXQyQzVpNUN2dktYU2M4Tnc9PT9pdj1lRWtrczVEMHh0MnRya3lVMEJocVhnPT0ifQ==
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // <script src="/paywall-script.js" data-config="eyJ1c2VyUHVibGlja2V5IjoiOTFlOWVlYmY3N2M2ZWU0ZTVjNGYxMTljNDE5MGRhN2U0NDRkMzA1NDViY2I0MTVmMjRhN2QzMmM5NjA4ZDc4ZSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOnt9fQ=="></script>
  

  return (
    <div dangerouslySetInnerHTML={{ __html: articleContent }} />
  );
};

export default PaywallTestPage;
