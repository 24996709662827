import React from 'react';
import { Link } from 'react-router-dom';
import SidebarLayout from '../components/SidebarLayout';

const activeProjects = [
  { name: 'Vector Databases', initials: 'VS', href: '/vectornests', desc: "Store any document to retrieve informations in seconds.", bgColor: 'bg-purple-800' },
]

// Dummy data for upcoming projects
const upcomingProjects = [
  { name: 'Datasets', initials: 'DB', href: '#', desc: "Store any document to retrieve informations in seconds.", bgColor: 'bg-gray-300' },
  // { name: 'Micro-transactions', initials: 'MT', href: '#', desc: "Store any document to retrieve informations in seconds.", bgColor: 'bg-gray-300' },
  // add more upcoming projects here...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function NostrNests() {
  return (
    <div>
      <SidebarLayout title="Nests" subtitle="Where your data comes to life">
        <div>
          <h2 className="text-sm font-medium text-gray-500">Active nests</h2>
          <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
            {activeProjects.map((project) => (
              <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
                <div
                  className={classNames(
                    project.bgColor,
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  {project.initials}
                </div>
                <div className="flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 px-4 py-2 text-sm">
                  <Link to={project.href} className="font-medium text-gray-900 hover:text-gray-600">
                    {project.name}
                  </Link>
                    <p className="text-gray-500">{project.desc}</p>
                  </div>
                  {/* <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>
          
          <h2 className="text-sm font-medium text-gray-500 mt-8">Upcoming nests</h2>
          <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
            {upcomingProjects.map((project) => (
              <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
                <div
                  className={classNames(
                    project.bgColor,
                    'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white'
                  )}
                >
                  {project.initials}
                </div>
                <div className="flex flex-1 items-center justify-between rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                  <div className="flex-1 px-4 py-2 text-sm">
                    <Link to={project.href} className="font-medium text-gray-900 hover:text-gray-600">
                      {project.name}
                    </Link>
                    <p className="text-gray-400">{project.desc}</p>
                  </div>
                  {/* <div className="flex-shrink-0 pr-2">
                    <button
                      type="button"
                      className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Open options</span>
                      <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div> */}
                </div>
              </li>
            ))}
          </ul>

        </div>   
      </SidebarLayout>
    </div>
  );
}

export default NostrNests;
