// SnippetDisplay.js

import React from 'react';

const SnippetDisplay = ({ snippet }) => {
  return (
    <div>
      <p>Copy and paste this snippet into your website to add the paywall:</p>
      <textarea value={snippet} readOnly style={{ width: '100%', height: '100px' }} />
      <p>Instructions on how to embed the snippet...</p>
    </div>
  );
};

export default SnippetDisplay;
