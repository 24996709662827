import React, { useRef, useEffect } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function DisplayEggData({ 
  egg_payload,
  egg_example_usage,
  is_owner,
  on_egg_payload_change,
  on_egg_example_usage_change
 }) {

  // Create references
  const payloadSyntaxRef = useRef(null);
  const payloadTextAreaRef = useRef(null);

  const exampleSyntaxRef = useRef(null);
  const exampleTextAreaRef = useRef(null);


  // Synchronize scrolling
  const syncScrolling = (syntax, textArea) => {
    if (syntax.current && textArea.current) {
        syntax.current.scrollLeft = textArea.current.scrollLeft;
    }
  };


    return (
        <div className="bg-[#3a404d] p-4 rounded-md overflow-x-auto max-h-screen overflow-y-auto border-2 border-purple-900">
        <div className="px-2 pb-6 pt-2 sm:px-2">
            <h3 className="text-base font-semibold leading-7 text-white">Egg Usage</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-white">Learn how to use this egg in your code.</p>
        </div>
        <div className="col-span-full">
            <label htmlFor="egg_example_usage" className="block text-sm font-medium leading-6 text-white">
                Payload
            </label>
            <div className="mt-1" style={{ 
                    position: 'relative', 
                    overflowX: 'auto', 
                    overflowY: 'hidden', 
                    resize: 'none', 
                    minHeight: '100px', 
                    borderRadius: '10px', 
                    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', 
                }}>
                
                <div 
                    ref={payloadSyntaxRef}
                    style={{ overflow: 'hidden' }}
                >
                    <SyntaxHighlighter
                        disabled={!is_owner}
                        language="javascript"
                        style={atomOneDark}
                        customStyle={{
                            padding: '15px',
                            height: '100%',
                            minWidth: 'max-content',
                            borderRadius: '10px',
                            resize: 'none',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            whiteSpace: 'pre',
                        }}
                    >
                        {egg_payload}
                    </SyntaxHighlighter>
                </div>
                
                <textarea
                    disabled={!is_owner}
                    type="text"
                    ref={payloadTextAreaRef}
                    name="eggPayload"
                    id="eggPayload"
                    autoComplete="eggPayload"
                    spellCheck="false"
                    onScroll={() => syncScrolling(payloadSyntaxRef, payloadTextAreaRef)}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'transparent',
                        color: 'transparent',
                        caretColor: '#f8f8f2',
                        fontFamily: '"Source Code Pro", monospace',
                        fontSize: '12px',
                        lineHeight: '1.5',
                        padding: '15px',
                        border: 'none',
                        outline: 'none',
                        overflowY: 'hidden',
                        resize: 'none',
                        borderRadius: '10px',
                        whiteSpace: 'pre'
                    }}
                    placeholder="Your JSON payload..."
                    value={egg_payload}
                    onChange={e => {
                      on_egg_payload_change(e.target.value);
                    }}
                    rows={3}
                />
            </div>
        </div>




        <div className="col-span-full">
            <label htmlFor="egg_example_usage" className="mt-2 block text-sm font-medium leading-6 text-white">
                Example Usage
            </label>
            <div className="mt-1" style={{ 
                    position: 'relative', 
                    overflowX: 'auto', 
                    overflowY: 'hidden', 
                    resize: 'none', 
                    minHeight: '100px', 
                    borderRadius: '10px', 
                    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', 
                }}>
                
                <div 
                    ref={exampleSyntaxRef}
                    style={{ overflow: 'hidden' }}
                >
                    <SyntaxHighlighter
                        disabled={!is_owner}
                        language="javascript"
                        style={atomOneDark}
                        customStyle={{
                            padding: '15px',
                            height: '100%',
                            minWidth: 'max-content',
                            borderRadius: '10px',
                            resize: 'none',
                            fontSize: '12px',
                            lineHeight: '1.5',
                            whiteSpace: 'pre',
                        }}
                    >
                        {egg_example_usage}
                    </SyntaxHighlighter>
                </div>
                
                <textarea
                    disabled={!is_owner}
                    type="text"
                    ref={exampleTextAreaRef}
                    name="eggExampleUsage"
                    id="eggExampleUsage"
                    autoComplete="eggExampleUsage"
                    spellCheck="false"
                    onScroll={() => syncScrolling(exampleSyntaxRef, exampleTextAreaRef)}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'transparent',
                        color: 'transparent',
                        caretColor: '#f8f8f2',
                        fontFamily: '"Source Code Pro", monospace',
                        fontSize: '12px',
                        lineHeight: '1.5',
                        padding: '15px',
                        border: 'none',
                        outline: 'none',
                        overflowY: 'hidden',
                        resize: 'none',
                        borderRadius: '10px',
                        whiteSpace: 'pre'
                    }}
                    placeholder="Your JSON payload..."
                    value={egg_example_usage}
                    onChange={e => {
                      on_egg_example_usage_change(e.target.value);
                    }}
                    rows={4}
                />
            </div>
        </div>
    </div>
    );
    
}

export default DisplayEggData;
