import React, { useState } from 'react';
import axios from 'axios'; // for calling API
import { createZapInvoice } from '../NostrEggs'


function ModalUploadMore({
    onClose,
    onSave,
    vectorstore_ID,
    vectorstore_name,
    vectorstore_description,
    created_by,
    privacy_status,
    vectorstore_owner_ln_address,
    vectorstore_price
}) {

    // Set State values
    const [files, setFiles] = useState([]);
    const [file_metadata, setFile_metadata] = useState('');


    // Set handler functions
    const onFilesChange = (e) => {
        setFiles(Array.from(e.target.files));
    };
    const onFileMetadataChange = (e) => {
        setFile_metadata(e.target.value);
    };


    // Function to create invoice and wait for payment
    const payInvoice = async (invoice) => {
    if (typeof window.webln === "undefined") {
        return alert("No WebLN Wallet available. Need one? We recommend you go to www.getalby.com");
    }

    try {
        await window.webln.enable();
        const result = await window.webln.sendPayment(invoice);
        // const { preimage } = result; // Extract the 'preimage' property from 'result'

        return result;
    } catch (error) {
        alert("An error occurred during the payment.");
    }
    };

    // Main upload function. First create zap invoice and wait for payment, then call API
    const onFilesUpload = async () => {

        const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

        if (!files || !file_metadata) {
            alert('For update, File and File Metadata are mandatory!');
            return;
        }
        const requestCost = files.length * 2000000; // 2k sats per file
        const normalizedRelays = ['wss://relay.paywithflash.com']
        // Create the invoice
        const zapInvoice = await createZapInvoice(publicKey, requestCost, "NostrEgg invoice. VectorstoreID: " + vectorstore_ID, normalizedRelays);
        // Make the user pay for his request
        const paid = await payInvoice(zapInvoice)
        if (paid) {
            // If the user paid, then run the following code
            for (const file of files) {
                try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('vectorstore_name', vectorstore_name);
                formData.append('vectorstore_description', vectorstore_description);
                formData.append('vectorstore_ID', vectorstore_ID);
                formData.append('file_metadata', file_metadata);
                formData.append('created_by', created_by);
                formData.append('privacy_status', privacy_status);
                formData.append('vectorstore_owner_ln_address', vectorstore_owner_ln_address);
                formData.append('vectorstore_price', vectorstore_price);

                const response = await axios.post('https://api.paywithflash.com/api/vectorizeFile', formData);

                // if (response.success) {
                //     onSave();
                // }

                } catch (error) {
                    console.error('API call failed:', error);
                }
                onClose();
                onSave();
            }
        }
    }





  return (
    <div className="relative">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <form>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Upload To Vector Database</h2>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                  <div className="col-span-full">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                      File
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        {/* <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" /> */}
                        <div className="mt-4 flex text-sm leading-6 text-purple-950">
                          <label
                            htmlFor="files"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-950 focus-within:outline-none focus-within:ring-2 focus-within:ring-purple-600 focus-within:ring-offset-2 hover:text-purple-500"
                          >
                            <span>Upload a file</span>
                            <input 
                              type="file"
                              multiple
                              name="files"
                              id="files"
                              // className="sr-only"
                              onChange={onFilesChange}
                          />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PDF & JSON only</p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="file_metadata" className="block text-sm font-medium leading-6 text-gray-900">
                      File Metadata
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 sm:max-w-md">
                        <input
                          type="text"
                          name="file_metadata"
                          id="file_metadata"
                          autoComplete="file_metadata"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="File Metadata"
                          value={file_metadata} 
                          onChange={onFileMetadataChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={onClose}>
                Cancel
              </button>
              <button
                type="button"
                className="rounded-md bg-purple-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onFilesUpload}
              >
                Save
              </button>
            </div>
          </form>
      </div>
    </div>
  );
}

export default ModalUploadMore;
