import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { SimplePool, nip04 } from 'nostr-tools';
import { cn } from 'classnames';


import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';

import {  PencilSquareIcon } from '@heroicons/react/24/outline';

import PayWithFlashButton from '../components/payWithFlash/payWithFlashButton';
import LnInvoiceHashDisplayAndCopy from '../components/paymentComponents/LnInvoiceHashDisplayAndCopy';
import { CurrencyDropdown, WalletDropdown } from '../components/FlashForms/comp/InputsLib';
import ProductOrdering from '../components/PointOfSale/ProductOrdering';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Function to get the referral data from the db using user public key
async function fetchReferralDataWithPublicKey(userPublicKey) {
  const formData = new FormData ();
  formData.append('userPublicKey', userPublicKey);
  try {

      const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
      method: 'POST',
      body: formData,
      });

      if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
  }
}

const PointOfSalePage = () => {

    const padList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'CLR', '.', 'DSC', '0'];

    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY
    const nwcUrl = process.env.REACT_APP_NOSTR_WALLET_CONNECT_URL

    const userEmail = localStorage.getItem('userEmail');
    const userHexPublickey = localStorage.getItem('userHexPublicKey');
    const userHexPrivateKey = localStorage.getItem('userHexPrivateKey');
    const userNsec = localStorage.getItem('userNsec');
    const userNPub = localStorage.getItem('userNpub');

    const [selectedTab, setSelectedTab] = useState('Point Of Sale');

    const [wallets, setWallets] = useState ([]);
    const [selectedWalletId, setSelectedWalletId] = useState ('');
    const [selectedWalletNwcUrl, setSelectedWalletNwcUrl] = useState ();
    const [selectedWalletName, setSelectedWalletName] = useState ();

    // sale information
    const [productList, setProductList] = useState ([]);
    const [productQuantity, setProductQuantity] = useState ([]);
    const [customerId, setCustomerId] = useState (-1);

    const [isQRCode, setIsQRCode] = useState (false);
    const [isLoading, setIsLoading] = useState (false);
    const [isPaid, setIsPaid] = useState (false);
    const [currency, setCurrency] = useState ('Satoshis');
    const [priceInZloty, setPriceInZloty] = useState ();   
    const [priceInDollars, setPriceInDollars] = useState ();
    const [priceInSats, setPriceInSats] = useState ();
    const [invoice, setInvoice] = useState ('');
    const [tempPrice, setTempPrice] = useState ([]);
    const [displayPrice, setDisplayPrice] = useState (0);
    const [bitcoinPrice, setBitcoinPrice] = useState ();

    // Referral Data
    const [isReferralExists, setIsReferralExists] = useState();
    const [referralData, setReferralData] = useState();
    const [referralWalletData, setReferralWalletData] = useState();

    const [error, setError] = useState ({});

    // Function to get the nwc url from the db using the nwc_id
    async function fetchWalletUrlById(wallet_id) {
      try {
          const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
          url.searchParams.append('wallet_id', wallet_id);

          const response = await fetch(url);

          if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          return data;
      } catch (error) {
          console.error('Error:', error);
          throw error; // Rethrowing the error for handling it further up in the call stack
      }
    }

    // Function to save the sales product info to table salesproduct of the db
    async function saveSaleInfoToDB (userHexPublicKey, customerId, productList, productQuantity, saleOrigin, priceInDollars, priceInSats, priceInZloty, saleDate) {
      const formData = new FormData ();

      formData.append ('user_public_key', userHexPublicKey);
      formData.append ('customer_id', customerId);
      for (let product_id of productList)
        formData.append ('product_list[]', product_id);
      for (let product_quantity of productQuantity)
        formData.append ('product_quantity[]', product_quantity);
      formData.append ('sale_origin', saleOrigin);
      formData.append ('price_in_dollars', priceInDollars);
      formData.append ('price_in_sats', priceInSats);
      formData.append ('price_in_zloty', priceInZloty);
      formData.append ('sale_date', saleDate);

      try {
        const response = await fetch ('https://api.paywithflash.com/api/save_sale_info_to_db', {
          method : 'POST',
          body : formData,
        });

        if (!response.ok) {
          const errorData = await response.json ();
          throw new Error (errorData.message | 'Failed to save sale information to database');
        }

        const successData = await response.json ();
        // console.log ('Sale Information successfully saved to database:', successData);

        return successData;

      } catch (error) {
        console.log ('Error saving Sales information to database:', error);
        throw error;
      }
    }

    // Function to save the transaction to table transactions of the db(don't add zloty payment)
    async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
      const formData = new FormData();
      formData.append('from_public_key', from_public_key);
      formData.append('to_public_key', to_public_key);
      formData.append('transaction_date', transactionDate);
      formData.append('amount_in_sats', amountInSats);
      formData.append('value_in_usd', valueInUsd);
      formData.append('flash_id', flashId);
      formData.append('flash_name', flashName);
      formData.append('flash_type', flashType); // Assuming this is also a new requirement
      formData.append('to_wallet_id', toWalletId);
      formData.append('to_user_wallet_name', toUserWalletName);
      formData.append('from_wallet_id', fromWalletId);
      formData.append('from_user_wallet_name', fromUserWalletName);
      formData.append('lnurl', lnurl);

      try {
        const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to save transaction to database');
        }

        const successData = await response.json();
        // console.log('Transaction successfully saved to database:', successData);

        return successData;
      } catch (error) {
        console.error('Error saving transaction to database:', error);
        throw error;
      }
    }

    // Function used to create the Zap Invoice
    async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
      // Call your server-side API to decrypt the config with nostr nip04
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userPublicKey: userPublicKey,
          encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
          amount: amount,
          memo: memo
        }),
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
      }
      return data;
    }

    // The function used to for the user to pay the fee owed to referred him
    async function payFeeToReferral(encryptedReferralUserNostrWalletConnectUrl, encryptedUserNostrWalletConnectUrl, amount, memo) {

      const transactionDate = new Date().toISOString();

      // Call your server-side API to decrypt the config with nostr nip04
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
              referralUserPublicKey: referralData.user_public_key,
              encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
              userPublicKey: userHexPublickey,
              encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
              amount: amount,
              memo: memo,
              amount_in_sats: priceInSats,
              value_in_usd: priceInDollars,
              usd_btc_exchange_rate: bitcoinPrice,
              flash_id: -1,
              transaction_date: transactionDate,
          }),
      });
      const data = await response.json();

      if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
      }

      return data;
    }

    // The function used to for the user to pay the fee owed to Flash
    async function payFeeToFlash(encryptedUserNostrWalletConnectUrl, amount, memo) {

      const transactionDate = new Date().toISOString();
      // Call your server-side API to decrypt the config with nostr nip04
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userPublicKey: userHexPublickey,
          encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
          amount: amount,
          memo: memo,
          amount_in_sats: priceInSats,
          value_in_usd: priceInDollars,
          usd_btc_exchange_rate: bitcoinPrice,
          flash_id: -1,
          transaction_date: transactionDate,
          user_public_key: userHexPublickey
        }),
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
      }

      return data;
    }

    // Checks the payment status of the invoice
    const checkPaymentStatus = async (nwcUrl, invoice) => {
      const paymentStatus = await checkInvoicePayment(nwcUrl, invoice);
      if (paymentStatus) {
        // Payment success: set isPaid to true
        handlePaymentSuccess(nwcUrl, invoice)
      }
    };

    // Function to check if the invoice was paid
    async function checkInvoicePayment(encryptedUserNostrWalletConnectUrl, invoice) {
      const startTime = Date.now();
      let isPaid = false;

      while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
        try {
          const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              userPublicKey: userHexPublickey,
              encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
              invoice: invoice
            }), // Assuming nostrWalletConnectUrl is handled server-side
          });
          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.message || 'Failed to check payment');
          }

          if (data.is_paid) {
            isPaid = true;
            break; // Exit the loop if the invoice is paid
          }

          // If not paid, wait 1 second before trying again
          await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
          console.error("Error checking invoice payment:", error);
          // You might want to decide if you should throw an error or perhaps continue trying
          // For now, we'll wait 1 second before trying again
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }

      return isPaid;
    }

    // Function to handle successful payment
    const handlePaymentSuccess = async () => {
      setIsPaid(true);

      setTimeout (() => {
        setIsPaid (false);
        setIsQRCode (false);
        setDisplayPrice (0);
        setPriceInDollars (0);
        setPriceInSats (0);
        setPriceInZloty (0);
        setInvoice ('');
        setTempPrice ([]);
        setBitcoinPrice (0);
      }, 3000);
      
      const saleDate = new Date().toISOString();
      await saveSaleInfoToDB (userHexPublickey, customerId, productList, productQuantity, 'pos', priceInDollars, priceInSats, priceInZloty, saleDate);
  
      // Calculate the fee and price minus fee
      const feePercentage = 1.5; // 1%
  
      // Assuming priceInSats is already defined and is the total price in Satoshis
      let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
      satsFee = Math.round(satsFee); // Rounding to the nearest whole number
  
      let satsFeeToFlash, satsFeeToReferral;
      if (isReferralExists) {
        satsFeeToFlash = satsFee * 70 / 100;
        satsFeeToReferral = satsFee * 30 / 100;
        await payFeeToReferral(referralWalletData.nwc_url, selectedWalletNwcUrl, satsFeeToReferral, `Referral Fee for POS`);
      }
      else satsFeeToFlash = satsFee;
      
      // // Pay fee to flash
      const response = await payFeeToFlash(selectedWalletNwcUrl, satsFeeToFlash, `Fee for POS`);
      // const fee_invoice = response.invoice;
      // const isPaid = response.is_paid;
    };

    // Function to get the price of Bitcoin
    async function getBitcoinPrice() {
      try {
        const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
          method: 'POST', // if the API requires POST method
          headers: {
            'Content-Type': 'application/json',
          },
          // If the API requires a body payload, uncomment and edit the following line
          // body: JSON.stringify({ key: 'value' }), 
        });
    
        if (!response.ok) {
          // If the response is not OK, throw an error with the response status
          throw new Error(`API call failed with status: ${response.status}`);
        }
    
        const data = await response.json();
    
        // Assuming the API returns an object with a property that contains the Bitcoin price
        return data
      } catch (error) {
        console.error('API call failed:', error);
        throw error; // Rethrow the error to be handled by the caller
      }
    }
    
    async function getExchangeRateFromDollars (to_currency) {
      try {
        const formData = new FormData ();
        formData.append ('to_currency', to_currency);

        const response = await fetch ('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
          method : 'POST',
          body : formData,
        });

        if (!response.ok) {
          throw new Error (`API call failed with status:${response.status}`);
        }

        const data = await response.json ();
        return data;
      } catch (error) {
        console.error ('API call failed:', error);
        throw error;
      }
    }
    
    // Function to convert the price from currency in order to have both satoshis and usd values
    async function convertPrice(price, currency) {
      const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin
      let priceInSats, priceInDollars, priceInZloty;
    
      try {
        const bitcoinPrice = await getBitcoinPrice();
        const exchangeRate = await getExchangeRateFromDollars ('PLN');
        setBitcoinPrice(bitcoinPrice)
    
        if (!bitcoinPrice) {
          throw new Error('Failed to fetch Bitcoin price');
        }

        if (!exchangeRate) {
          return new Error ('Failed to fetch exchange rate');
        }
    
        if (currency === "Dollar") {
          const amountInUSD = parseFloat(price);
          priceInSats = Math.round((amountInUSD / bitcoinPrice) * oneBitcoinInSats);
          priceInDollars = amountInUSD;
          priceInZloty = priceInDollars * exchangeRate;
          setPriceInSats(priceInSats)
          setPriceInDollars(priceInDollars)
          setPriceInZloty(priceInZloty);
        } else if (currency === "Satoshis") {
          const amountInSats = parseFloat(price);
          priceInSats = amountInSats;
          priceInDollars = ((amountInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
          priceInZloty = priceInDollars * exchangeRate;
          setPriceInSats(priceInSats)
          setPriceInDollars(priceInDollars)
          setPriceInZloty(priceInZloty);
        } else if (currency === 'Zloty') {
          const amount = parseFloat (price);
          priceInZloty = amount;
          priceInDollars = priceInZloty / exchangeRate;
          priceInSats = Math.round((priceInDollars / bitcoinPrice) * oneBitcoinInSats);
          setPriceInSats (priceInSats);
          setPriceInDollars (priceInDollars);
          setPriceInZloty (priceInZloty);
        } else {
          throw new Error('Unknown currency type: ' + currency);
        }
    
        return { priceInDollars, priceInSats, priceInZloty };
      } catch (error) {
        console.error('Error in conversion:', error);
        throw error;
      }
    }
  
    const handleClickPad = (value) => {

        setError ({});

        if (value == 'CLR') setTempPrice ([]);
        else if (value == 'DSC') {
            if (tempPrice.length == 0) return ;
            let cloneArray = Object.assign ([], tempPrice);
            cloneArray.pop ();
            setTempPrice (cloneArray);
        }
        else {
            if (currency == 'Satoshis' && value == '.') return ;
            let cloneArray = Object.assign ([], tempPrice);
            cloneArray.push (value);
            setTempPrice (cloneArray);
        }
    }

    const handleGenerateQRCode = async () => {

      setError ({});

      if (displayPrice == 0 || isNaN(displayPrice)) return ; 

      const { priceInDollars, priceInSats, priceInZloty } = await convertPrice(displayPrice, currency);

      if (priceInSats > 500000) {
        setError ({'isError' : true, 'msg' : 'You have exceeded the maximum'});
        return ;
      }

      setIsQRCode (true);
      setIsLoading (true);

      if (selectedWalletNwcUrl) {

          if (!priceInDollars || !priceInSats) {
            console.error('Failed to convert price');
            return;
          }
          setPriceInDollars(priceInDollars);
          setPriceInSats(priceInSats);
          setPriceInZloty(priceInZloty);

          const createdInvoice = await createZapInvoice(userHexPublickey, selectedWalletNwcUrl, priceInSats, 'Payment for ' + 'Product');
          if (!createdInvoice) {
            console.error('Failed to create invoice');
            return;
          }
          setInvoice (createdInvoice.invoice);
          setIsLoading (false);
      }
    }

    const handleWalletChange = (e) => {
      const selectedWalletId = e.target.value;
      const selectedWallet = wallets.find(wallet => wallet.id.toString() === selectedWalletId);
    
      if (selectedWallet) {
        setSelectedWalletId (selectedWallet.id);
        setSelectedWalletNwcUrl (selectedWallet.nwc_url);
        setSelectedWalletName (selectedWallet.name);
      }
    };

    const handleBack = () => {
      setIsQRCode (false);
    }

    // Effect hook to fetch wallets when the component mounts
    useEffect(() => {
        const fetchWallets = async () => {
          try {
            const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userHexPublickey)}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const data = await response.json();
            const results = [];
      
            for (const item of data) {
              // let decrypted_nwc_url;
              // if (item.nwc_url !== "") {
              //   decrypted_nwc_url = await nip04.decrypt(privateKey, userHexPublickey, item.nwc_url);
              // } else {
              //   decrypted_nwc_url = ""
              // }
              
              results.push({
                id: item.id,
                user_publicKey: item.user_public_key,
                nwc_url: item.nwc_url,
                wallet_name: item.wallet_name,
                user_wallet_name: item.user_wallet_name,
                ln_address: item.ln_address
              });
            }
            setWallets(results);
      
            // Set the first wallet as the default wallet and its ID
            if (results.length > 0) {
              // setWallet(results[0].user_wallet_name);
              setSelectedWalletId(results[0].id); // Set the ID of the first NWC
              setSelectedWalletNwcUrl(results[0].nwc_url);
              setSelectedWalletName (results[0].name);
            }
          } catch (error) {
            console.error('Error fetching wallets:', error);
        }
      };
  
      fetchWallets();
    }, []),

    useEffect (() => {
      if (userHexPublickey) {
        const fetchReferralData = async () => {
          const referralData = await fetchReferralDataWithPublicKey(userHexPublickey);
          setIsReferralExists (referralData.isReferralExists);
          setReferralData(referralData.referralData);
  
          if (referralData.isReferralExists) {
            const referralWalletData = await fetchWalletUrlById(referralData.referralData.wallet_id);
            setReferralWalletData (referralWalletData);
          }
        }

        fetchReferralData();
      }
    }, [userHexPublickey])

    useEffect (() => {
        const stringPrice = tempPrice.join ('');
        const numberPrice = parseFloat (stringPrice);
        setDisplayPrice (numberPrice);
    }, [tempPrice])

    useEffect (() => {
      if (invoice) {
        checkPaymentStatus(selectedWalletNwcUrl, invoice);
      }
    }, [invoice]);

    return (
        <>
            {isLoading && (
              <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style = {{ zIndex : '100'}}>
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
              </div>
            )}
        <SidebarLayout>
          <div className='hidden md:block'>
              <Header
                title="Point Of Sale"
                subtitle="Generate QR Code & Pay via QR Code"
                showWalletsAvatar={false}
            />
          </div>
          <div className='md:hidden flex w-full text-center align-middle justify-center py-3'>
            <img src='./images/logo-dark.png' className='w-44 md:hidden flex item-center'></img>
            </div>

              <div className = "w-full">
                <nav className="md:flex space-x-8 border-b border-t pt-3 pb-0 bg-white pl-9">
                  {['Point Of Sale', 'Products'].map((tab) => (
                    <a
                      key={tab}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTab(tab);
                      }}
                      className={classNames(
                        tab === selectedTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-indigo-300 hover:text-indigo-400 hover:border-indigo-400',
                        'inline-flex items-center border-b-2 px-0 pt-1 pb-3 text-sm font-medium'
                      )}
                    >
                      {tab}
                    </a>
                  ))}
                </nav>
                <div className = "md:mt-6 md:ml-9">
                  { selectedTab === 'Point Of Sale' && (
                    <div className = "grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
                      <div className='hidden md:flex md:flex-col md:col-span-1 pt-1'>
                        <h2 className="text-xl font-semibold leadding-7 text-indigo-900">Point Of Sale</h2>
                        <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage point of sale.</p>
                      </div>
                      <div className = "md:col-span-3">
                          <div className= "md:h-screen flex flex-col items-center bg-gray">
                    
                            <div className="w-full sm:w-full md:w-3/3 lg:w-2/3 p-0 md:p-8 pt-0 md:pt-8  rounded-none md:rounded-2xl md:bg-white ">
                              {!isPaid ? (
                                <>
                                  { isQRCode && (
                              <>
                                <div className='bg-white p-4  shadow-md mx-4 mt-1'>
                                      <div className = "text-center text-3xl font-bold mb-2 text-slate-900 mt-3"> Pay with Lightning </div>
                                      <div className="text-center mb-2 mt-2">
                                        
                                            <div className="inline-block" style={{ width: '250px', height: '250px' }}>
                                                <QRCode
                                                    value={invoice}
                                                    size={250}
                                                    bgColor={"#ffffff"}
                                                    fgColor={"#000000"}
                                                    level={"L"}
                                                    includeMargin={false}
                                                    renderAs={"svg"}
                                                    imageSettings={{
                                                        src: "/favico.png",
                                                        excavate: true,
                                                        height: 40,
                                                        width: 40
                                                    }}
                                                />
                                            </div>
                                        <div className = " flex w-full flex-col  text-gray-800 mt-1 mb-2 py-3">
                                       <span className="priceInSats text-3xl font-bold text-slate-900">{parseInt(priceInSats).toLocaleString()} sats</span>
                                      <span className="priceInDollars font-medium text-lg text-slate-600">${parseFloat(priceInDollars).toLocaleString()} / {parseFloat(priceInZloty).toLocaleString()} PLN</span>{' '}
                                    </div>
                                    <LnInvoiceHashDisplayAndCopy value={invoice} />
                                        <div className="mt-4 mb-0 text-sm text-center ">
                                          <a href="#" onClick = { handleBack } className="btn shadow-none w-full bg-slate-100 border border-slate-200  py-3 min-h-0 h-auto text-slate-800 hover:text-slate-800">← Cancel payment</a>
                                        </div>
                                  </div>
                                  </div>
                                    </>
                                  )}
                                  { !isQRCode && (
                                    <>
                                      {/* Payment Information and Buttons Centered */}
                                      <div className="text-center mb-0">
                                        <div className="flex justify-center space-x-2 mt-0">
                                          <div className="w-1/2">
                                          </div>
                                        </div>
                                      </div>
                                      <div className='p-2 pb-0 pt-0 w-full justify-between px-3 flex flex-row gap-0 md:flex md:flex-col mb:p-0'>
                                        <WalletDropdown  id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
                                        <CurrencyDropdown  id="currency" name="currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
                                      </div>
                                      <div className='text-center mb-0 mt-4 text-black opacity-100'>
                                          <input type = 'number' placeholder="Enter amount..." className = ' placeholder:text-slate-300 sm:text-black w-full opacity-100 placeholder:text-2xl   h-20 bg-white border-slate-300 text-left pl-4 text-4xl font-semibold ' value = {displayPrice} disabled></input>
                                      </div>

                                      {/* QR Code Centered */}
                                      <div className="  text-center rounded-md grid grid-cols-3 gap-0 mb-0">
                                          { padList.map ((item, i) => {
                                              return (
                                                  <span key = {i} className = {classNames (item == '0' ? 'col-span-3' : '', 'transition-colors duration-100 h-16 text-white ease-in-ou bg-slate-800 border border-slate-700 align-middle items-center content-center text-2xl shadow-sm border-1 px-3 py-3 border-black hover:border-indigo-700 cursor-pointer hover:bg-indigo-700 hover:text-white')} onClick = {() => { handleClickPad (item) }}> {item} </span>
                                              )
                                          })}
                                      </div>

                                      <button className = ' bg-indigo-800 w-full px-4 py-2 text-md h-16 font-semibold text-white text-xl hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' onClick = {handleGenerateQRCode} > Continue to payment </button>

                                      { error.isError && (
                                        <div className = 'text-center text-md font-semibold text-red-600 mt-1'> {error.msg} </div>
                                      )}

                                      <div className="mt-6 mb-6 text-sm text-center">
                                        <span className="max-w-4xl text-sm text-gray-400 mb-6 pb-6">You can receive up to <b className = "font-bold text-gray-700">500,000</b> Satoshi(<b className = "font-bold text-gray-700">$332.34</b>).</span>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                            
                            <div className='flex bg-white p-4 shadow-sm rounded-lg mx-4 mt-1 items-center align-middle justify-center text-center' style={{ height: '85vh' }}>
                            <p>
                        
                              <img src='/nimages/success.gif' className='w-8 mx-auto pb-4'></img>
                               
                               
                               <p className='text-xl text-slate-900 font-bold'> Payment successful! <br /> Thank you.</p>
                              
                              
                              
                              </p>
                              </div>
                              )}
                            </div>
                          </div>
                      </div>
                    </div>
                  )}
                  { selectedTab === 'Products' && (
                    <div className = "grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
                        {/* <div className='hidden md:flex md:flex-col md:col-span-1 pt-1'>
                          <h2 className="text-xl font-semibold leading-7 text-indigo-900">Product Ordering</h2>
                          <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage Product Ordering.</p>
                        </div> */}
                        <div className = "md:col-span-4 mx-2">
                          {/* <div className= "md:h-screen flex flex-col items-center bg-gray">
                          </div> */}
                          <ProductOrdering />
                        </div>
                    </div>
                  )}
                </div>
              </div>
            </SidebarLayout>
        </>
      );
      
}

export default PointOfSalePage; 
