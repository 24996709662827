import React, { useEffect, useState } from 'react';
import { nip04, SimplePool } from 'nostr-tools';
import { relayConnections } from './NostrEggs';

function GetVectorstores() {
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const nostrEggsVectorstorePublicKey = process.env.REACT_APP_NOSTR_EGGS_VECTORSTORE_PUBLIC_KEY;
  const nostrEggsVectorstorePrivateKey = process.env.REACT_APP_NOSTR_EGGS_VECTORSTORE_PRIVATE_KEY;

  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";

  const pool = new SimplePool();
  
  const [vectorstores, setVectorstores] = useState([]);

  useEffect( () => {
    async function get_vectorstores() {
        let vectorstores = [];

        if (relayConnections) {
            const relays = relayConnections.map(connection => connection.url);
            let events = await pool.list(relays, [{
                kinds: [4],
                ['#p']: [nostrEggsVectorstorePublicKey],
            }]);

            let uniqueVectorstoreIDs = new Set();

            events.forEach((event) => {
                let vectorstoreIDTag = event.tags.find(tag => tag[0] === 'vectorstoreID');
                let vectorstoreNameTag = event.tags.find(tag => tag[0] === 'vectorstoreName');

                if (vectorstoreIDTag && vectorstoreNameTag && !uniqueVectorstoreIDs.has(vectorstoreIDTag[1])) {
                    uniqueVectorstoreIDs.add(vectorstoreIDTag[1]);

                    vectorstores.push({
                        vectorstoreName: vectorstoreNameTag[1],
                        vectorstoreID: vectorstoreIDTag[1]
                    });
                }
            });
        }

        return vectorstores;
    }

    get_vectorstores().then(vectorstores => {
        setVectorstores(vectorstores);
    });
  }, [relayConnections]);

  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-white'>Vectorstores</h2>
          <p className='text-center text-white-50 mb-5'>Here is the list of all vectorstores:</p>
        </div>
        <ul role="list" className="divide-y divide-gray-700 space-y-6 mt-8">
          {vectorstores.map((vectorstore) => (
            <li key={vectorstore.vectorstoreID} className="flex justify-between px-4 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm sm:text-sm">
              <div className="flex gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-white">{vectorstore.vectorstoreName}</p>
                  <p className="mt-1 truncate text-xs leading-5 text-gray-500">{vectorstore.vectorstoreID}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default GetVectorstores;
