// ProjectStatsComponent.js
import React from 'react';

const ProjectStatsComponent = ({ flashes }) => {
  // Total number of projects
  const totalProjects = flashes.length;

  // Total active projects
  const totalActiveProjects = flashes.filter(flash => {
    const expiryDate = new Date(flash.expiry_date);
    const now = new Date();
    return expiryDate > now; // Checks if the expiry date is later than the current date
  }).length;

  const stats = [
    { name: 'Total Flashes', stat: totalProjects },
    { name: 'Total Active Flashes', stat: totalActiveProjects },
  ];

  return (
    <div>
      <dl className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 shadow">
            <dt className="text-sm font-medium text-slate-900  truncate">{item.name}</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-slate-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default ProjectStatsComponent;
