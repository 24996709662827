const PaymentSuccessScreen = ({ product }) => {
    const productURL = `/product-page/${product.ProductID}`;
    const hasDiscount = product.Discount && parseFloat(product.Discount) > 0;
    const hasTax = product.TaxRate && parseFloat(product.TaxRate) > 0;
  
    return (
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow">
        <h2 className="text-3xl font-bold text-center mb-6">Congratulations on Your Purchase!</h2>
  
        <div className="md:flex md:items-start md:justify-between">
          {/* Product Image & Details */}
          <div className="md:flex md:items-start">
            <img src={product.ImageURLs[0]} alt="Product" className="w-full md:w-48 h-auto rounded-md" />
            <div className="mt-4 md:mt-0 md:ml-6">
              <h3 className="text-xl font-semibold">{product.Name}</h3>
              <p className="text-md mt-2 text-gray-600">{product.Description.substring(0, 100)}...</p>
              <a href={productURL} target="_blank" rel="noopener noreferrer" className="mt-4 inline-block bg-indigo-600 text-white font-bold py-2 px-4 rounded hover:bg-indigo-700 transition-colors">View Product</a>
            </div>
          </div>
        </div>
  
        {/* Price Details & Email Message */}
        <div className="mt-6">
          <div className="mt-2">
            <span className="text-lg font-bold">Price: ${parseFloat(product.PriceInDollars).toFixed(2)}</span>
            {hasDiscount && (
              <>
                {' '}
                <span className="line-through text-gray-500">${(parseFloat(product.PriceInDollars) / (1 - parseFloat(product.Discount) / 100)).toFixed(2)}</span>
                <span className="text-sm ml-2 text-red-500">-{product.Discount}%</span>
              </>
            )}
          </div>
          <p className="text-sm text-gray-500">{parseInt(product.PriceInSatoshis).toLocaleString()} satoshis</p>
          <div className="text-sm text-gray-500">
            {hasTax && `Tax Rate: ${product.TaxRate}%`}
          </div>
        </div>
  
        <p className="text-md text-center text-gray-600 mt-4">You will have received an email with the product details.</p>
      </div>
    );
  };
  
  export default PaymentSuccessScreen;
  