import React, { useState, useEffect } from 'react';
import { getPublicKey, generatePrivateKey } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19, SimplePool, matchFilter, Kind } from 'nostr-tools';

const { npubEncode, nsecEncode, decode } = nip19;

const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

const convertNostrToHex = (nostr) => {
  const { type, data } = decode(nostr);
  
  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid nostr. Nostr must be "npub" or "nsec".');
  }
};

const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();

  const filters = [{
    pubkey: publicKey,
    kinds: [0]
  }];

  try {
    const events = await pool.list(relayUrls, filters);

    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    // Sort the events by the 'created_at' timestamp in descending order
    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);

    // Find the first event with non-empty content
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      // Store user metadata in variables
      const userName = metadata.name;
      const userPictureUrl = metadata.picture;
      const userAbout = metadata.about;

      return { userName, userPictureUrl, userAbout };
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', // Replace with a default picture URL
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};




//nsec1fntm3dcma2mjymywu8jar3t0629n5ukkrhslf4xnqw3r44dd6raqv0nfz5
function SignUpWithNostr(props) {

  const { appName, imageUrl, onLoginSuccess, onClose } = props;

  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userPrivateKey, setUserPrivateKey] = useState('');
  const [userPublicKey, setUserPublicKey] = useState('');
  const [npub, setNpub] = useState('');
  const [nsec, setNsec] = useState('');
  const [userName, setUserName] = useState('');
  const [userPictureUrl, setUserPictureUrl] = useState('');
  const [userAbout, setUserAbout] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // New state for handling error messages

  // const navigate = useNavigate();


  const handleCreatePrivateKey = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      const newPrivateKey = generatePrivateKey();
      const publicKey = getPublicKey(newPrivateKey);
      const nsec = convertHexToNostr(newPrivateKey, 'nsec');
      const npub = convertHexToNostr(newPrivateKey, 'npub');

      setUserPrivateKey(newPrivateKey);
      setUserPublicKey(publicKey);
      setNsec(nsec);
      setNpub(npub);

      // Store keys in session storage
      localStorage.setItem('userHexPrivateKey', newPrivateKey);
      localStorage.setItem('userHexPublicKey', publicKey);
      localStorage.setItem('userNsec', nsec);
      localStorage.setItem('userNpub', npub);

      // Redirect to the dashboard page
      // navigate('/dashboard');
      const userPrivateKey = newPrivateKey;
      const userPublicKey = publicKey;
      const { userName, userPictureUrl, userAbout } = await fetchUserMetadata(publicKey, ["wss://relay.paywithflash.com", "wss://relay.damus.io"]);
      
      setUserName(userName);
      setUserPictureUrl(userPictureUrl);
      setUserAbout(userAbout);

      setLoading(false);
    }, 1000);
  };



  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };


  const togglePrivateKeyVisibility = (e) => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };


  return (
    <>
      <div className=" lg:w-1/1 md:w-1/1">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
          </div>
        )}
        <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
          <div className="flex shrink-0 items-center justify-center">
            <img
              className="w-full h-24 mb-4 object-cover rounded-t-sm rounded-b-none" // Tailwind CSS for width and margin bottom
              src={imageUrl}
              alt="Image"
            />
            {/* Overlay label positioned absolutely within the parent div */}
            <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
              {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
            </div>
          </div>

          <div className="flex shrink-0 items-center justify-center">
            <img
              className="w-20 h-20 mb-3 object-cover rounded-full border-4 border-white -mt-12 z-20" // Tailwind CSS for width, margin bottom, and z-index
              src={imageUrl}
              alt="Image"
            />
          </div>
          <h2 className="text-center text-2xl font-bold mb-0 text-black">
            {appName}
          </h2>
          <div className="text-center text-md text-gray-600 mb-6 px-10">
            Create an account or login to subscribe.
          </div>
          <div className="pt-0">
            {!npub && !nsec && (
              <div className="flex flex-col shrink-0 text-slate-400 items-center text-center justify-center text-sm space-y-4">
                <p className='text-lg p-6 bg-gray-100 mx-12 rounded-lg text-gray-700 font-medium '>Nostr gives more privacy, but comes with responsibility: <b>we cannot recover your account.</b> Save your keys in a secure location.</p>
                <div className="flex w-full px-10 mt-3 text-slate-400 text-center justify-center items-center">
                  <button className='flex w-full justify-center rounded-md bg-purple-800 px-3 py-3 text-md font-semibold leading-6 text-white  hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2'>
                    <a href="#" onClick={handleCreatePrivateKey} className="text-md">
                      Create new Nostr keys
                    </a>
                  </button>
                </div>
              </div>
            )}
            {npub && nsec && (
              <>
                <div className="flex flex-col shrink-0 text-slate-400 items-center text-center justify-center  space-y-4">
                  <div className='bg-slate-200 text-slate-500 mx-12 rounded-lg py-3 px-4 text-sm '>
                    <p className='text-center justify-center text-md space-y-4 '><b>We do not store your keys and will not be able to retrieve them for you.</b> Make sure to save them securely. You can always remove the app connections from your wallet to cancel subscriptions, even if you lose access to Flash.</p>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div className='px-12 my-2'>
                      <div className='mb-4'>
                        <label className="block text-sm  text-slate-500 font-semibold mb-2">Public Key (npub)</label>
                        <div className='flex'>
                          <input className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-sm  font-regular text-slate-500 " type="text" value={npub} readOnly />
                          <button onClick={() => copyToClipboard(npub)} className='ml-2 text-sm bg-slate-200 px-4 rounded-lg hover:bg-slate-300 text-slate-500'>Copy</button>
                        </div>
                      </div>
                      <div>
                        <label className="block text-sm  text-slate-500 font-semibold mb-2">Secret Key (nsec):</label>
                        <div className='flex'>
                          <input className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-sm  font-regular text-slate-500" type="text" value={nsec} readOnly />
                          <button onClick={() => copyToClipboard(nsec)} className='ml-2 text-sm bg-slate-200 px-4 rounded-lg hover:bg-slate-300 text-slate-500'>Copy</button>
                        </div>
                      </div>
                    </div>
                    <div className='px-10 mt-6'>
                      <button
                        className="flex w-full justify-center rounded-md bg-purple-800 px-3 py-3 text-md font-semibold leading-6 text-white  hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
                        onClick={() => onLoginSuccess({ userPrivateKey, userPublicKey, userName, userPictureUrl, userAbout })}>
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

    </>
  );
  
}

export default SignUpWithNostr;
