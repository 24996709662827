import React, { useState, useEffect, useRef } from 'react';
import projects from '../geyser_projects_with_ids.json';

const Card = ({ flashId, card, handlePay, handleClick }) => {
  
  const [visible, setVisible] = useState (false);
  const [subscribers, setSubscribers] = useState (0);

  useEffect (() => {

    const url = 'https://api.paywithflash.com/geyser/api/external/get_geyser_user_memberships_count?geyser_flash_id=' + flashId;

    const fetchData = async () => {
      try {
        const response = await fetch (url, {
          method : 'GET',
          headers : {
            'Content-type' : 'application/json',
          }
        })

        if (response.ok) {
          const data = await response.json ();
          setSubscribers (data.membership_count);
        }
        else {
          throw new Error ('Failed to fetch data');
        }
      } catch (error) {
        console.error ('Error fetching data');
      }
    }
    
    fetchData ();

  }, [flashId]);

  // Function to handle redirect
  const handleRedirect = () => {
    window.open(`https://geyser.fund/project/${card.name}`, '_blank');
  };



  return (
  <div>
      <div onClick={handleRedirect} className="cursor-pointer rounded-lg overflow-hidden bg-white border-2 border-gray-200 hover:border-gray-300 text-black">
        <img className="h-[150px] w-full object-cover" src={card.image} alt="image" />
        <div className="p-2">
          <div className="font-bold truncate text-ellipsis overflow-hidden text-md text-left">{card.title}</div>
          <p className="flex text-black-300 text-base items-center">
            <span className="mx-0"><img className="max-w-[20px] max-h-[20px] rounded-full" src="/geyser-pp.png" alt="Profile"></img></span>
            <span className="truncate overflow-hidden text-left text-sm text-gray-600"> {card.owners[0].user.username} </span>
          </p>
        
        <div className="flex justify-start">
          <div className="rounded-full text-sm font-semibold mr-2 py-2">
            <div className="text-left"> {subscribers} Subscribers </div>
          </div>
        </div>
        <div
          className="rounded overflow-hidden px-4 py-2 border-2 hover:cursor-pointer text-black text-center flex justify-center items-center gap-2 geyser-sub"
          onClick={(event) => handlePay(card.geyser_flash_id, event)}
        >
          Subscribe
        </div>
      </div>
      </div>

    </div>
  );
};

const Geyser = () => {
  const componentRef = useRef(null);
  const [visible, setVisible] = useState(false); // Tracks modal visibility
  const [shouldRender, setShouldRender] = useState(false); // Manages rendering of the iframe
  const [selectedFlashId, setSelectedFlashId] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false); // Tracks if the iframe should be displayed
  const [iframeHasLoaded, setIframeHasLoaded] = useState(false); // Actual load status of the iframe
    const [modalWidth, setModalWidth] = useState('35%');


  const directURL = `https://app.paywithflash.com/external-login/geyser?flashId=${selectedFlashId}`;

  useEffect(() => {
    if (visible) {
      setShouldRender(true);
      setIframeLoaded(false); // Reset iframe load state when modal opens
      setIframeHasLoaded(false); // Reset actual iframe load status when modal opens
    }
  }, [visible]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (componentRef.current && !componentRef.current.contains(e.target)) {
        setVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!visible && shouldRender) {
      const timeoutId = window.setTimeout(() => {
        setShouldRender(false);
      }, 200);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [visible, shouldRender]);

  useEffect(() => {
    let timeout;
    if (iframeHasLoaded && !iframeLoaded) {
      // Ensure the skeleton is displayed for at least 1 second
      const elapsedTime = Date.now() - (componentRef.current?.startTime || Date.now());
      const remainingTime = Math.max(500 - elapsedTime, 0);

      timeout = setTimeout(() => {
        setIframeLoaded(true);
      }, remainingTime);
    }

    return () => clearTimeout(timeout);
  }, [iframeHasLoaded]);

  const handlePay = (flash_id, e) => {
    e.stopPropagation();
    setVisible(true);
    setSelectedFlashId(flash_id);
    if (componentRef.current) {
      componentRef.current.startTime = Date.now(); // Record the start time for loading
    }
  };

  const handleIframeLoad = () => {
    setIframeHasLoaded(true); // Set the flag indicating the iframe has loaded
  };

    useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setModalWidth('80%'); // Mobile devices
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        setModalWidth('50%'); // Tablets and small desktops
      } else {
        setModalWidth('35%'); // Larger desktops
      }
    }

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Call the function to set the initial modal width based on the current window size
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Dynamic style for the modal container
 const modalContainerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: visible ? 'translate(-50%, -50%)' : 'translate(-50%, -48%)',
    opacity: visible ? 1 : 0,
    visibility: shouldRender ? 'visible' : 'hidden',
    transition: 'opacity 0.2s ease, transform 0.2s ease',
    zIndex: 30, // Ensure this is higher than the overlay's z-index
    width: modalWidth,
    height: '100vh',
    backgroundColor: '#fff',
    // boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    overflow: 'hidden',
  };


  const gridContainerStyle = {
    display: 'grid',
    gap: '1rem', // Consider reducing if the gap contributes to overflow
    margin: '0 auto',
    padding: '0rem', // Ensure padding is not causing overflow, adjust if necessary
    boxSizing: 'border-box',
  };




  const SkeletonLoader = () => {
    return (

      <div>
        <div className="flex flex-col  items-center space-y-2 p-0 bg-white">
          <div className="animate-pulse bg-gray-200  h-24 w-full -mb-12"></div>
          <div className="animate-pulse bg-gray-200 rounded-full h-20 w-20 mb-0  border-4 border-white"></div>

        </div>
        <div className="flex flex-col items-center space-y-2 px-10 py-4 h-screen bg-white">



          <div className="animate-pulse bg-gray-200 rounded-lg h-8 w-full"></div>
          <div className="animate-pulse bg-gray-200 rounded-lg h-6 w-full"></div>
          <div className="space-y-2 w-full flex flex-col items-center pt-6">
            <div className="animate-pulse bg-gray-200 rounded-md h-7 w-full"></div>
            <div className="animate-pulse bg-gray-200 rounded-md h-7 w-full"></div>
            <div className="animate-pulse bg-gray-200 rounded-md h-7 w-full"></div>
          </div>
          <div className="space-y-2 w-full flex flex-col items-left pt-6">
            <div className="animate-pulse bg-gray-200 rounded-md h-5 w-3/4"></div>
            <div className="animate-pulse bg-gray-200 rounded-md h-5 w-full"></div>
          </div>
          <div className="space-y-4 w-full flex flex-col items-center pt-6">
            <div className="animate-pulse bg-gray-200 rounded-lg h-12 w-full"></div>
            <div className="animate-pulse bg-gray-200 rounded-lg h-12 w-full"></div>

            <div className="animate-pulse bg-gray-200 rounded-lg h-6 w-1/2"></div>
          </div>
        </div>
      </div>
    );
  };





  


  return (
       <>
      {/* Overlay with lower z-index */}
      {visible && (
        <div className="fixed inset-0 bg-black bg-opacity-10 z-20"></div> // z-index lower than the modal
      )}
<style>
        {`
          .grid-container {
            /* Flexible grid layout that adjusts the number of columns based on the container width */
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

          /* Intermediate step for tablets and smaller desktops: 2 cards per row */
          @media (min-width: 768px) and (max-width: 1023px) {
            .grid-container {
              grid-template-columns: repeat(2, minmax(200px, 1fr)); // Ensure 2 cards per row in this range
            }
          }

          /* Desktops: 3 cards per row */
          @media (min-width: 1024px) {
            .grid-container {
              grid-template-columns: repeat(3, minmax(200px, 1fr)); // Keep 3 cards per row for larger screens
            }
          }
        `}
      </style>
      <div className={`${visible ? 'blur-background' : ''}`}>
        {/* Header, main content, etc. */}
        <div className="pt-14 bg-gray-50">
          {/* Top bar */}
          <div className='flex top-0 fixed z-10 bg-white w-full border-b-2 border-gray-100 justify-center py-2.5 align-middle text-center'>
            <img className="max-h-[30px] justify-center align-middle text-center" src="https://storage.googleapis.com/geyser-projects-media/app/logo-name-brand.svg" alt="Brand Logo" />
          </div>
          
          {/* Main content */}
          <div className="relative bg-gray-50">
            <div className='flex justify-center align-middle mt-6'>
            <div class='flex flex-col text-black w-screen md:w-screen md:mx-12 mx-6 lg:w-1/2 align-middle rounded-lg border-2 bg-white py-5 px-4 mb-4'>
              
              <div class='flex justify-between items-center mb-0'> 
                <div class='text-gray-700 font-medium'>Want to integrate subscriptions on Geyser?</div>
                <button class='px-4 py-1 rounded-lg bg-green-800 w-56 geyser-button' onClick={() => window.open('https://airtable.com/appyM7XlNIWVypuP5/shrEbNwIAI39vcsnx', '_blank')}>
                  Join Beta
                </button>
              </div>

              <div class='text-gray-600 ss-titre'>Subscriptions in Sats are powered by Flash. <a href='https://paywithflash.com/subscriptions/' class='text-purple-600 hover:text-purple-800' target='_blank'>Learn more</a></div>
                          </div>
              
            </div>
          </div>
        </div>

        
        {/* Grid container */}
        <div className="flex py-0 pb-12 bg-gray-50 items-center justify-center align-middle">
          <div className='bg-white p-6 rounded-lg border-2 border-gray-200 w-screen md:w-screen md:mx-12 mx-6 lg:w-1/2'>
            <div className='text-xl font-bold pb-6'>Subscribe to Geyser Projects <span className='text-sm bg-black beta-label'>Beta</span></div>
            <div style={gridContainerStyle} className="grid-container">
              {projects.data.projectsGet.projects.map((card) => (
                <Card key={card.id} flashId={card.geyser_flash_id} card={card} handlePay={handlePay} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div style={modalContainerStyle}>
        {shouldRender && (
          <>
            {!iframeLoaded && <SkeletonLoader />}
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              style={{ width: '100%', height: '100%', borderRadius: '12px', display: iframeLoaded ? 'block' : 'none' }}
              src={visible ? directURL : 'about:blank'}
              onLoad={handleIframeLoad}
              ref={componentRef}
            ></iframe>
          </>
        )}
      </div>
    </>
  );
};

export default Geyser;