import React from 'react';

const HeadingSection = ({ title, subtitle, links }) => {
  return (
    <div className="relative overflow-hidden bg-gray-900 py-16 sm:py-24"> {/* Reduced height */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">{title}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">{subtitle}</p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-orange-500 sm:grid-cols-2 md:flex lg:gap-x-10"> {/* Orange link text */}
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                <span className="text-orange-500">{link.name}</span> <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingSection;
