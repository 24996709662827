import React, { useEffect, useState } from 'react';

import NpubCodeVerification from './NpubCodeVerification';
import EmailCodeVerification from './EmailCodeVerification';
import Memberships from '../SubscriptionPage/Memberships';

const ProductOrderFormManage = ({ email, setEmail, npub, setNpub, isVerified, setIsVerified, loginMethod }) => {
    const [contactMethod, setContactMethod] = useState(loginMethod == 'nostr' ? 'npub' : 'email');
    const [errorMessageNostrDM, setErrorMessageNostrDM] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');

    // States for communications
    const [nostrVerificationCodeSent, setNostrVerificationCodeSent] = useState(false);
    const [emailVerificationCodeSent, setEmailVerificationCodeSent] = useState(false);
    const [npubVerified, setNpubVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);


    const handleNpubSubmit = () => {
        setNostrVerificationCodeSent(true);
        setErrorMessageNostrDM(null);
    };

    const handleEmailSubmit = () => {
        setEmailVerificationCodeSent(true);
        setErrorMessageEmail(null);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsVerified(true);
        setEmailVerified(false);
        setNpubVerified(false);
        setEmailVerificationCodeSent(false);
        setNostrVerificationCodeSent(false);
        setErrorMessageEmail('');
        setErrorMessageNostrDM('');
    };

    return (
        <form className="w-full max-w-md mx-auto mx-8 p-8 grid grid-cols-2 gap-4 rounded-lg shadow" onSubmit={handleSubmit}>
            {/* <div className="col-span-2">
                <h2 className="text-lg font-semibold text-gray-900">Select Your Plan</h2>
            </div>
            <div className="col-span-2">
                {Array.isArray(memberships) &&
                    <Memberships memberships={memberships} onSelectedMembershipChange={onSelectedMembershipChange} />
                }
            </div> */}
            { (!isVerified || (email && npub)) && (
                <>
                    <div className="col-span-2">
                        <h2 className="text-lg font-semibold text-gray-900">Contact Information</h2>
                    </div>
                    <div className="col-span-2 flex items-center">
                        { loginMethod == 'email & nostr' && (
                            <>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text mr-2">Email</span>
                                        <input
                                            type="radio"
                                            name="contactMethod"
                                            value="email"
                                            checked={contactMethod === 'email'}
                                            onChange={() => setContactMethod('email')}
                                            className="mr-2"
                                        />
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text mr-2">Nostr npub</span>
                                        <input
                                            type="radio"
                                            name="contactMethod"
                                            value="npub"
                                            checked={contactMethod === 'npub'}
                                            onChange={() => setContactMethod('npub')}
                                            className="mr-2"
                                        />
                                    </label>
                                </div>
                            </>
                        )}
                    </div>
                    {contactMethod === 'email' && (
                        <div className="col-span-2 relative">
                            <input
                                className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email*"
                                required={contactMethod === 'email'}
                            />
                            {!emailVerified && (
                                <button
                                    type="button"
                                    onClick={() => handleEmailSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-blue-500 rounded-r-md hover:bg-blue-700"
                                >
                                    Verify email
                                </button>
                            )}
                            {emailVerified && (
                                <button
                                    type="button"
                                    disabled={true}
                                    onClick={() => handleEmailSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
                                >
                                    Verified
                                </button>
                            )}
                        </div>
                    )}
                    {emailVerificationCodeSent && (
                        <div className="col-span-2">
                            <EmailCodeVerification
                                email={email}
                                emailVerified={emailVerified}
                                setEmailVerified={setEmailVerified}
                                errorMessageEmail={errorMessageEmail}
                                setErrorMessageEmail={setErrorMessageEmail}
                            />
                        </div>
                    )}
                    {contactMethod === 'npub' && (
                        <div className="col-span-2 relative">
                            <input
                                className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
                                type="text"
                                value={npub}
                                onChange={(e) => setNpub(e.target.value)}
                                placeholder="Nostr npub*"
                                required={contactMethod === 'npub'}
                            />
                            {!npubVerified && (
                                <button
                                    type="button"
                                    onClick={() => handleNpubSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-blue-500 rounded-r-md hover:bg-blue-700"
                                >
                                    Verify npub
                                </button>
                            )}
                            {npubVerified && (
                                <button
                                    type="button"
                                    disabled={true}
                                    onClick={() => handleNpubSubmit(npub)}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
                                >
                                    Verified
                                </button>
                            )}
                        </div>
                    )}
                    {nostrVerificationCodeSent && (
                        <div className="col-span-2">
                            <NpubCodeVerification
                                npub={npub}
                                npubVerified={npubVerified}
                                setNpubVerified={setNpubVerified}
                                errorMessageNostrDM={errorMessageNostrDM}
                                setErrorMessageNostrDM={setErrorMessageNostrDM}
                            />
                        </div>
                    )}
                </>
            )}

            { (emailVerified || npubVerified) && (
                <div className="col-span-2 flex justify-between">
                    <button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Confirm
                    </button>
            </div>
            )}
        </form>
    );
};

export default ProductOrderFormManage;
