let SATOSHI_TO_DOLLAR_RATE = null;

const fetchSatoshiPrice = async () => {
  try {
    const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice/BTC.json');
    const data = await response.json();
    const bitcoinRate = data.bpi.USD.rate_float;
    // Calculate the price of one Satoshi in USD
    return bitcoinRate / 100000000;
  } catch (error) {
    console.error('Failed to fetch Bitcoin price:', error);
    return null;
  }
};



export const formatPriceWithSeparator = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPrice = (price, currency) => {
  return currency === 'Satoshis' ? `${formatPriceWithSeparator(price)} sats` : `$${formatPriceWithSeparator(price)}`;
};

export const convertCurrency = async (price, currency) => {
  if (SATOSHI_TO_DOLLAR_RATE === null) {
    SATOSHI_TO_DOLLAR_RATE = await fetchSatoshiPrice();
  }
  
  let convertedValue = '';
  if (price && SATOSHI_TO_DOLLAR_RATE) {
    const numericPrice = parseFloat(price.replace(/,/g, ''));
    if (currency === 'Satoshis') {
      const dollars = (numericPrice * SATOSHI_TO_DOLLAR_RATE).toFixed(2);
      convertedValue = `~${formatPriceWithSeparator(dollars)} dollars`;
    } else {
      const sats = (numericPrice / SATOSHI_TO_DOLLAR_RATE).toFixed(0);
      convertedValue = `~${formatPriceWithSeparator(sats)} sats`;
    }
  } else {
    convertedValue = currency === 'Satoshis' ? '~0 dollars' : '~0 sats';
  }

  return convertedValue;
};


export const convertCurrencyForFlashDetails = async (price, currency) => {
  if (SATOSHI_TO_DOLLAR_RATE === null) {
    SATOSHI_TO_DOLLAR_RATE = await fetchSatoshiPrice();
  }
  
  let convertedValue = '';
  if (price && SATOSHI_TO_DOLLAR_RATE) {
    const numericPrice = parseFloat(price.replace(/,/g, ''));
    if (currency === 'Satoshis') {
      const dollars = (numericPrice * SATOSHI_TO_DOLLAR_RATE).toFixed(2);
      convertedValue = `~ $${formatPriceWithSeparator(dollars)}`;
    } else {
      const sats = (numericPrice / SATOSHI_TO_DOLLAR_RATE).toFixed(0);
      convertedValue = `~ $${formatPriceWithSeparator(sats)}`;
    }
  } else {
    convertedValue = currency === 'Satoshis' ? '0' : '0';
  }

  return convertedValue;
};
