// generateSnippet.js

import { nip04 } from "nostr-tools";

export const generateSnippet = async (url, price, userPublickey, userPrivateKey, memo, nostrWalletConnectUrl, currency) => {

  const ourPublicKey = "f981da5e5ca0a37af365877988b49f6011ac1d16f1defdb968e9ffc83316fe8c"

  // nip04 encrypt the content
  const jsonObjectString = JSON.stringify({ url: url, memo: memo, price: price, nostrWalletConnectUrl: nostrWalletConnectUrl, currency: currency });

  try {
    const encryptedProperties = await nip04.encrypt(userPrivateKey, ourPublicKey, jsonObjectString);
    console.log(encryptedProperties);
    const encodedData = window.btoa(JSON.stringify({ userPublickey: userPublickey, encryptedProperties: encryptedProperties }));
    const snippet = `<script src="/paywall-script.js" data-config="${encodedData}"></script>`;
    console.log(snippet);

    return snippet;
  } catch (error) {
    console.error("An error occurred during encryption:", error);
    // Handle the error as needed
  }
};

export default generateSnippet;
