import React, { useState, useEffect } from 'react';
import axios from 'axios';

import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import ProductList from '../components/Products/Products/ProductList';
import ProductForm from '../components/Products/Products/ProductForm';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const ProductPage = () => {

    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    let userNpub = localStorage.getItem('userNpub') || "";
    let userName = localStorage.getItem('userName') || "";
    let userAbout = localStorage.getItem('userAbout') || "";
    let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

    const [selectedTab, setSelectedTab] = useState('My Products');

    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showFormModal, setShowFormModal] = useState(false);


    useEffect(() => {
        const fetchProductsAndInventoryAndCategory = async () => {
            try {
                setIsLoading(true);
                const { data: productsData } = await axios.get(`https://api.paywithflash.com/api/list_products_for_user`, {
                    params: { user_public_key: userHexPublicKey }
                });

                // Fetch inventory and category for each product
                const productDetailsPromises = productsData.map(async (product) => {
                    try {
                        const inventoryResponse = await axios.get(`https://api.paywithflash.com/api/get_inventory_for_product/${product.ProductID}`);
                        product.inventory = inventoryResponse.data;
                    } catch {
                        product.inventory = null; // Handle individual product inventory fetch errors
                    }

                    try {
                        const categoryResponse = await axios.get(`https://api.paywithflash.com/api/get_category_by_id/${product.CategoryID}`);
                        product.category = categoryResponse.data;
                    } catch {
                        product.category = { error: 'Category not found' }; // Handle missing category information
                    }

                    return product;
                });

                const productsWithDetails = await Promise.all(productDetailsPromises);
                setProducts(productsWithDetails);
            } catch (error) {
                console.error('Failed to fetch products, inventory, and category', error);
                setError('Failed to fetch products and inventory');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductsAndInventoryAndCategory();
    }, [userHexPublicKey]);



    const handleProductSelect = (product) => {
        setSelectedProduct(product);
        setShowFormModal(true);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
        setShowFormModal(false);
    };

    return (
        <div className="min-h-screen">
            <SidebarLayout>
                <Header
                    title="My Products"
                    subtitle="Create and manage your products."
                    showWalletsAvatar={false}
                    badgeText=""
                />
                <div className="container mx-auto">
                </div>
                <div className="container mx-auto px-4">
                    <>
                        {!showFormModal && (
                            <div>
                                <ProductList products={products} isLoading={isLoading} error={error} onProductSelect={handleProductSelect} />
                                <button
                                    className="mt-4 bg-slate-800 hover:bg-slate-900 text-white font-bold py-2 px-4 rounded mb-4"
                                    onClick={() => setShowFormModal(true)}
                                >
                                    Add New Product
                                </button>
                            </div>
                        )}
                        {showFormModal && (
                            <div>
                                <ProductForm
                                    product={selectedProduct}
                                    onClose={handleCloseModal}
                                    userPublicKey={userHexPublicKey}
                                    userPrivateKey={userHexPrivateKey}
                                />
                            </div>
                        )}
                    </>
                </div>
            </SidebarLayout>
        </div>
    );
};

export default ProductPage;
