import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

import ProductImages from '../components/Products/Products/ProductImages';
import Cart from '../components/Products/ProductGallery/Cart';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';

import { Dialog, Disclosure, Menu, Popover } from '@headlessui/react';
import { XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
    const exchangeRate = await getExchangeRateFromDollars('PLN');

    if (currency === "usd") {
        const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
        const priceInZloty = parseFloat(value) * exchangeRate;
        return { priceInDollars: parseFloat(value), priceInSatoshis, priceInZloty };
    } else if (currency === "satoshis") {
        const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
        const priceInZloty = priceInDollars * exchangeRate;
        return { priceInDollars: parseFloat(priceInDollars), priceInSatoshis: value, priceInZloty };
    } else if (currency === 'zloty') {
        const priceInDollars = parseFloat(value) / exchangeRate;
        const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
        return { priceInDollars, priceInSatoshis, priceInZloty: parseFloat(value) };
    }
};

async function getBitcoinPrice() {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    return data;
}

async function getExchangeRateFromDollars(to_currency) {
    const formData = new FormData();
    formData.append('to_currency', to_currency);
    const response = await fetch('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method: 'POST',
        body: formData,
    });
    const data = await response.json();
    return data;
}

export const decodeCart = (encodedCart) => {
    if (!encodedCart) return [];
    try {
        return JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(encodedCart)))));
    } catch (e) {
        console.error('Failed to decode cart:', e);
        return [];
    }
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
};

const encodeData = (data) => {
    const jsonString = JSON.stringify(data);
    return encodeURIComponent(btoa(unescape(encodeURIComponent(jsonString))));
};

const Card = ({ product, addToCart, user }) => {
    const navigate = useNavigate();
    const [convertedPrice, setConvertedPrice] = useState({});
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        const fetchConvertedPrice = async () => {
            const prices = await convertPrices(product.Currency, parseFloat(product.Price));
            setConvertedPrice(prices);
        };

        fetchConvertedPrice();
    }, [product]);

    const handleViewProduct = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        const encodedCart = encodeData(cart);
        const encodedUser = encodeData(user);
        const encodedProduct = encodeData(product);
        navigate(`/product-gallery-product?product=${encodedProduct}&user=${encodedUser}`);
    };

    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
    };

    return (
        <div
            key={product.ProductID}
            className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
        >
            <div className="relative h-72 bg-gray-200">
                <ProductImages product={product} />
            </div>
            <div className="flex flex-1 flex-col space-y-2 p-4">
                <div className='flex justify-between inline-block'>
                    <h3 className="text-sm font-medium text-gray-900">
                        <a href={product.href}>
                            {product.Name}
                        </a>
                    </h3>
                    {product.is_digital_product && (
                        <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                            Digital Product
                        </span>
                    )}
                    {!product.is_digital_product && (
                        <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                            Physical Product
                        </span>
                    )}
                </div>
                <p className="text-sm text-gray-500">
                    {product.description && product.description.length > 200 ? product.description.substring(0, 200) + '...' : product.description}
                </p>
                <div className="flex flex-1 flex-col justify-end">
                    <p className="text-sm italic text-gray-500">{product.options}</p>
                    <p className="text-base font-medium text-gray-900">${convertedPrice.priceInDollars?.toFixed(2)} USD</p>
                    <p className="text-sm text-gray-500">{convertedPrice.priceInSatoshis?.toLocaleString()} sats</p>
                </div>
                <div className="flex space-x-2 items-center">
                    <label className="text-sm text-gray-500">Qty:</label>
                    <input
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        min="1"
                        className="w-16 px-2 py-1 border rounded-md text-sm"
                    />
                </div>
                <div className="card-actions justify-end space-x-1">
                    <button
                        className="mt-4 text-sm bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleViewProduct();
                        }}
                    >
                        View Product
                    </button>
                    <button
                        className="mt-4 text-sm bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        onClick={(e) => {
                            e.stopPropagation();
                            addToCart({
                                ...product,
                                PriceInDollars: convertedPrice.priceInDollars,
                                PriceInSatoshis: convertedPrice.priceInSatoshis,
                                quantity // Ensure the correct quantity is passed
                            });
                        }}
                    >
                        Add to Cart
                    </button>
                </div>
            </div>
        </div>
    );
};


const SkeletonCard = () => (
    <div className="card bg-base-100 w-96 shadow-xl m-4 flex flex-col gap-4 p-4">
        <div className="skeleton h-48 w-full"></div>
        <div className="skeleton h-4 w-28"></div>
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-full"></div>
    </div>
);

const ProductGallery = () => {
    const query = useQuery();
    const user_public_key = query.get('user');
    const cartQuery = query.get('cart');
    const decodedCart = decodeCart(cartQuery);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isDigitals, setIsDigitals] = useState([]);
    const [selectedIsDigitals, setSelectedIsDigitals] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showFormModal, setShowFormModal] = useState(false);
    const [cart, setCart] = useState(decodedCart || []);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCart(storedCart);
    }, []);

    useEffect(() => {
        const fetchProductsAndInventoryAndCategory = async () => {
            try {
                if (!user_public_key) {
                    throw new Error('user_public_key is missing in the URL query parameters.');
                }

                setIsLoading(true);
                const { data: productsData } = await axios.get(`https://api.paywithflash.com/api/list_products_for_user`, {
                    params: { user_public_key }
                });

                const productDetailsPromises = productsData.map(async (product) => {
                    try {
                        const inventoryResponse = await axios.get(`https://api.paywithflash.com/api/get_inventory_for_product/${product.ProductID}`);
                        product.inventory = inventoryResponse.data;
                    } catch {
                        product.inventory = null;
                    }

                    try {
                        const categoryResponse = await axios.get(`https://api.paywithflash.com/api/get_category_by_id/${product.CategoryID}`);
                        product.category = categoryResponse.data;
                    } catch {
                        product.category = { error: 'Category not found' };
                    }

                    product.isDigital = product.is_digital_product === true ? 'Digital Product' : 'Physical Product';
                    return product;
                });

                const productsWithDetails = await Promise.all(productDetailsPromises);
                setProducts(productsWithDetails);
                setFilteredProducts(productsWithDetails);
                setCategories([...new Set(productsWithDetails.map(product => product.category))]);
                setIsDigitals([...new Set(productsWithDetails.map(product => product.isDigital))]);
            } catch (error) {
                console.error('Failed to fetch products, inventory, and category:', error);
                setError('Failed to fetch products and inventory');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductsAndInventoryAndCategory();
    }, [user_public_key]);

    useEffect(() => {
        const filtered = products.filter(product =>
            (selectedCategories.length === 0 || selectedCategories.includes(product.CategoryID)) &&
            (selectedIsDigitals.length === 0 || selectedIsDigitals.includes(product.isDigital)) &&
            (searchTerm ? product.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.category.CategoryName.toLowerCase().includes(searchTerm.toLowerCase()) : true)
        );
        setFilteredProducts(filtered);
    }, [searchTerm, selectedCategories, selectedIsDigitals, products]);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const handleProductSelect = (product) => {
        setSelectedProduct(product);
        setShowFormModal(true);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
        setShowFormModal(false);
    };

    const handleCategoryChange = (categoryID) => {
        setSelectedCategories(prevSelected =>
            prevSelected.includes(categoryID)
                ? prevSelected.filter(id => id !== categoryID)
                : [...prevSelected, categoryID]
        );
    };

    const handleIsDigitalChange = (isDigitalID) => {
        setSelectedIsDigitals(prevSelected =>
            prevSelected.includes(isDigitalID)
                ? prevSelected.filter(id => id !== isDigitalID)
                : [...prevSelected, isDigitalID]
        );
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const addToCart = (product) => {
        const existingProductIndex = cart.findIndex((item) => item.ProductID === product.ProductID);
        if (existingProductIndex !== -1) {
            const updatedCart = [...cart];
            updatedCart[existingProductIndex].quantity += product.quantity; // Increment by the quantity passed
            setCart(updatedCart);
        } else {
            setCart([...cart, { ...product, quantity: product.quantity }]);
        }
    };

    const updateCart = (updatedCart) => {
        setCart(updatedCart);
    };

    const removeFromCart = (index) => {
        const newCart = [...cart];
        newCart.splice(index, 1);
        setCart(newCart);
    };

    const clearCart = () => {
        setCart([]);
    };

    return (
        <div className="min-h-screen bg-white">
            <div className="container mx-auto px-4 lg:px-16">
                <div className="flex items-center justify-between my-4">
                    <div className="flex w-1/2">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Search products..."
                            className="w-full px-4 py-2 border rounded-md text-sm"
                        />
                        <div className="ml-4">
                            <Popover as="div" className="relative inline-block text-left">
                                <div>
                                    <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        Categories
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>
                                </div>
                                <Popover.Panel className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-2 px-4">
                                        {categories.map((category, index) => (
                                            <div key={index} className="flex items-center py-1">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedCategories.includes(category.CategoryID)}
                                                    onChange={() => handleCategoryChange(category.CategoryID)}
                                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />
                                                <label className="ml-3 text-sm text-gray-700">
                                                    {category.CategoryName}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </div>

                        <div className="ml-4">
                            <Popover as="div" className="relative inline-block text-left">
                                <div>
                                    <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                                        Digital
                                        <ChevronDownIcon
                                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>
                                </div>
                                <Popover.Panel className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-2 px-4">
                                        {isDigitals.map((isDigital, index) => (
                                            <div key={index} className="flex items-center py-1">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedIsDigitals.includes(isDigital)}
                                                    onChange={() => handleIsDigitalChange(isDigital)}
                                                    className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                />
                                                <label className="ml-3 text-sm text-gray-700">
                                                    {isDigital}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </Popover.Panel>
                            </Popover>
                        </div>
                    </div>
                    <Cart cart={cart} user={user_public_key} removeFromCart={removeFromCart} clearCart={clearCart} updateCart={updateCart} />
                </div>
                {isLoading && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {[...Array(6)].map((_, index) => (
                            <SkeletonCard key={index} />
                        ))}
                    </div>
                )}
                {error && <p>{error}</p>}
                {!isLoading && !error && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {filteredProducts.map((product, index) => (
                            <Card
                                key={index}
                                product={product}
                                addToCart={addToCart}
                                user={user_public_key}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className="mb-4 mt-10 text-xs text-center text-slate-500 py-2">Powered by <a href="https://paywithflash.com" className="font-bold text-slate-500">Flash</a></div>
        </div>
    );
};

export default ProductGallery;
