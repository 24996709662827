import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import NpubCodeVerification from './NpubCodeVerification';
import EmailCodeVerification from './EmailCodeVerification';
import WalletConnectButton from './WalletConnectButton';
import WalletConnectButtonSuccess from './WalletConnectButtonSuccess';
import Memberships from './Memberships';

async function getWalletNameFromNWCString(nwcString) {
    const walletsAvailable = ["Alby", "Mutiny"];
    const lowerCaseNWCString = nwcString.toLowerCase();

    for (const walletName of walletsAvailable) {
        if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
            return walletName;
        }
    }

    return "Custom";
};

const ProductOrderFormVimeoOTT = ({ 
    onOrderSubmitted,
    walletNwcUrl,
    setWalletNwcUrl,
    memberships,
    onSelectedMembershipChange,
    eMail,
    userPassword,
    setUserPassword,
    loginMethod,
    appName,
    errorMessage,
    setErrorMessage
}) => {
    const [customerName, setCustomerName] = useState('');
    const [customerSurname, setCustomerSurname] = useState('');
    const [contactMethod, setContactMethod] = useState('email');
    const [email, setEmail] = useState(eMail);
    const [npub, setNpub] = useState('');
    const [errorMessageNostrDM, setErrorMessageNostrDM] = useState('');
    const [errorMessageEmail, setErrorMessageEmail] = useState('');

    const [nostrVerificationCodeSent, setNostrVerificationCodeSent] = useState(false);
    const [emailVerificationCodeSent, setEmailVerificationCodeSent] = useState(false);
    const [npubVerified, setNpubVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSelectEmail = () => {
        setContactMethod('email');
        setNostrVerificationCodeSent(false);
    };

    const handleNpubSubmit = () => {
        setNostrVerificationCodeSent(true);
        setErrorMessageNostrDM(null);
    };

    const handleEmailSubmit = () => {
        setEmailVerificationCodeSent(true);
        setErrorMessageEmail(null);
    };

    const handleWalletConnected = async (appName, nwcString) => {
        try {
            const providerName = await getWalletNameFromNWCString(nwcString);
            setWalletNwcUrl(nwcString)
        } catch (error) {
            console.error('Error saving new wallet:', error);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (contactMethod === 'email' && (!email || !userPassword)) {
            setErrorMessage("Please provide both an email address and a password.");
            return;
        } else if (contactMethod === 'npub' && !npub) {
            setErrorMessage("Please provide a Nostr public key (npub).");
            return;
        }

        let formValues;
        if (contactMethod === 'email') {
            formValues = {
                walletNwcUrl,
                email,
                userPassword
            };
        } else if (contactMethod === 'npub' && npub && npubVerified) {
            formValues = {
                walletNwcUrl,
                npub
            };
        }

        onOrderSubmitted(formValues);
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <form className="w-full max-w-md mx-auto py-8 grid grid-cols-2 gap-4 p-6" onSubmit={handleSubmit}>
                <div className="col-span-2">
                    <h2 className="text-lg font-semibold text-gray-900">Select Your Plan</h2>
                </div>
                <div className="col-span-2">
                    {Array.isArray(memberships) &&
                        <Memberships memberships={memberships} onSelectedMembershipChange={onSelectedMembershipChange} />
                    }
                </div>
                <div className="col-span-2">
                    <h2 className="text-lg font-semibold text-gray-900">Contact Information</h2>
                    <p className="text-xs text-gray-900">The email and password you input here will be your login credentials to {appName}</p>
                </div>
                <div className="col-span-2 flex items-center">
                    {loginMethod === 'email & nostr' && (
                        <>
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text mr-2">Email</span>
                                    <input
                                        type="radio"
                                        name="contactMethod"
                                        value="email"
                                        checked={contactMethod === 'email'}
                                        onChange={() => setContactMethod('email')}
                                        className="mr-2"
                                    />
                                </label>
                            </div>
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text mr-2">Nostr npub</span>
                                    <input
                                        type="radio"
                                        name="contactMethod"
                                        value="npub"
                                        checked={contactMethod === 'npub'}
                                        onChange={() => setContactMethod('npub')}
                                        className="mr-2"
                                    />
                                </label>
                            </div>
                        </>
                    )}
                </div>
                {contactMethod === 'email' && (
                    <>
                        <div className="col-span-2 relative">
                            <input
                                className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 pl-3 pr-20"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email*"
                                required
                            />
                            {!emailVerified && (
                                <button
                                    type="button"
                                    onClick={handleEmailSubmit}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-gray-800 rounded-r-md hover:bg-gray-700"
                                >
                                    Verify email
                                </button>
                            )}
                            {emailVerified && (
                                <button
                                    type="button"
                                    disabled
                                    onClick={handleEmailSubmit}
                                    className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
                                >
                                    Verified
                                </button>
                            )}
                        </div>
                        <div className="col-span-2 relative">
                            <input
                                className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 pl-3 pr-20"
                                type={showPassword ? "text" : "password"}
                                value={userPassword}
                                onChange={(e) => setUserPassword(e.target.value)}
                                placeholder="Password*"
                                required
                            />
                            <button
                                type="button"
                                onClick={toggleShowPassword}
                                className="absolute inset-y-0 right-0 px-3 text-gray-500"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </>
                )}
                {emailVerificationCodeSent && email && (
                    <div className="col-span-2">
                        <EmailCodeVerification
                            email={email}
                            emailVerified={emailVerified}
                            setEmailVerified={setEmailVerified}
                            errorMessageEmail={errorMessageEmail}
                            setErrorMessageEmail={setErrorMessageEmail}
                        />
                    </div>
                )}
                {contactMethod === 'npub' && (
                    <div className="col-span-2 relative">
                        <input
                            className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
                            type="text"
                            value={npub}
                            onChange={(e) => setNpub(e.target.value)}
                            placeholder="Nostr npub*"
                            required
                        />
                        {!npubVerified && (
                            <button
                                type="button"
                                onClick={() => handleNpubSubmit(npub)}
                                className="absolute inset-y-0 right-0 px-4 text-white bg-blue-500 rounded-r-md hover:bg-blue-700"
                            >
                                Verify npub
                            </button>
                        )}
                        {npubVerified && (
                            <button
                                type="button"
                                disabled
                                onClick={() => handleNpubSubmit(npub)}
                                className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
                            >
                                Verified
                            </button>
                        )}
                    </div>
                )}
                {nostrVerificationCodeSent && (
                    <div className="col-span-2">
                        <NpubCodeVerification
                            npub={npub}
                            npubVerified={npubVerified}
                            setNpubVerified={setNpubVerified}
                            errorMessageNostrDM={errorMessageNostrDM}
                            setErrorMessageNostrDM={setErrorMessageNostrDM}
                        />
                    </div>
                )}
                <div className="col-span-2 mt-2">
                    <h2 className="text-lg font-semibold text-gray-900">Payment</h2>
                </div>
                {!walletNwcUrl && (
                    <>
                        <div className="col-span-2">
                            <WalletConnectButton onWalletConnected={handleWalletConnected} appName={appName} />
                            <div className="flex justify-center space-x-1 mt-2">
                                <p className="text-gray-500 text-xs">
                                    Don't have a compatible wallet?
                                </p>
                                <p className="text-gray-500 font-bold text-xs underline cursor-pointer">
                                    <a href="https://getalby.com/invited-by/pierrecorbin"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-gray-500 hover:text-gray-800">
                                        Setup in 2 min with our partner
                                    </a>
                                </p>
                            </div>
                            <div className="flex justify-center space-x-1 mt-1">
                                <span className="text-gray-500 text-xs cursor-pointer" onClick={toggleModal}>
                                    Don't have Bitcoin?
                                </span>
                                <span className="text-gray-500 font-bold text-xs cursor-pointer underline hover:text-gray-800" onClick={toggleModal}>
                                    Get some!
                                </span>
                            </div>
                        </div>
                    </>
                )}
                {walletNwcUrl && (
                    <>
                        <div className="col-span-2 flex justify-between">
                            <WalletConnectButtonSuccess />
                        </div>
                        <div className="col-span-2 flex justify-between">
                            <button
                                type="submit"
                                className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Purchase Subscription
                            </button>
                        </div>
                        {errorMessage && (
                            <div className="col-span-2 text-red-500 text-sm mb-2">
                                {errorMessage}
                            </div>
                        )}
                    </>
                )}
                {errorMessage && !walletNwcUrl && (
                    <div className="col-span-2 text-red-500 text-center mb-2">
                        {errorMessage}
                    </div>
                )}
            </form>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={toggleModal}>
                    <div className="bg-white rounded-lg p-4" style={{ width: '90%', maxWidth: '600px', height: '80%', overflowY: 'auto' }} onClick={e => e.stopPropagation()}>
                        <iframe
                            allow="usb; ethereum; clipboard-write; payment; microphone; camera"
                            loading="lazy"
                            src="https://widget.mtpelerin.com/?_ctkn=4e7165a8-2f93-4db0-8895-b5ca05330e65&type=web&lang=en&tab=buy&tabs=buy,sell,swap&net=lightning_mainnet&nets=lightning_mainnet&primary=%23222D3A&success=%23222D3A&mylogo=https%3A%2F%2Fflash-images.fra1.cdn.digitaloceanspaces.com%2Fflash_images%2Fflash%2520logo.png&bsc=USD&bdc=sat"
                            title="Mt Pelerin exchange widget"
                            style={{ width: '100%', height: '100%', border: 'none' }}
                        ></iframe>
                    </div>
                </div>
            )}
        </>
    );
};

export default ProductOrderFormVimeoOTT;
