// PaywallCreator.js

import React, { useState } from 'react';
import generateSnippet from './generateSnippet';
// import { X } from 'lucide-react';

const PaywallCreator = () => {
  const [url, setUrl] = useState('');
  const [price, setPrice] = useState('');
  const [memo, setMemo] = useState('');
  const [currency, setCurrency] = useState('USD'); // Added currency state
  const [nostrWalletConnectUrl, setNostrWalletConnectUrl] = useState('');
  const [snippetHtml, setSnippetHtml] = useState('');


  let userPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userPublickey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const generatedSnippet = await generateSnippet(url, price, userPublickey, userPrivateKey, memo, nostrWalletConnectUrl, currency);
      setSnippetHtml(generatedSnippet);
    } catch (error) {
      console.error("Failed to generate the snippet:", error);
    }
  };
  
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-gray-900h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold ">
          Create a Paywall for Your Content
        </h2>
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div>
            <label htmlFor="articleUrl" className="block text-sm font-medium">
              Article URL
            </label>
            <div className="mt-1">
              <input
                id="articleUrl"
                name="articleUrl"
                type="url"
                required
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter article URL"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            
          </div>

          <div>
            <label htmlFor="Memo" className="block text-sm font-medium">
              Memo
            </label>
            <div className="mt-1">
              <input
                id="memo"
                name="memo"
                type="text"
                required
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                placeholder="Enter Memo"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            
          </div>

                    <div>
            <label htmlFor="NostrWallet" className="block text-sm font-medium">
              nostrWalletConnectUrl
            </label>
            <div className="mt-1">
              <input
                id="nostrWalletConnectUrl"
                name="nostrWalletConnectUrl"
                type="text"
                required
                value={nostrWalletConnectUrl}
                onChange={(e) => setNostrWalletConnectUrl(e.target.value)}
                placeholder="Enter nostrWalletConnectUrl"
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            
          </div>
          
          <div>
            <label htmlFor="currency" className="block text-sm font-medium">
              Currency
            </label>
            <div className="mt-1">
              <select
                id="currency"
                name="currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="USD">USD</option>
                <option value="SATS">Satoshis (BTC)</option>
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="price" className="block text-sm font-medium">
              Price ({currency})
            </label>
            <div className="mt-1">
              <input
                id="price"
                name="price"
                type="number"
                required
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={`Set a price in ${currency}`}
                min={currency === "USD" ? "0.01" : "1"} // Different min value for satoshis
                step={currency === "USD" ? "0.01" : "1"} // Different step value for satoshis
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Create Paywall
            </button>
          </div>
        </form>
      </div>

<div>
        {snippetHtml && (
          <div className="mt-4">
            <h3 className="text-lg font-medium">Generated Snippet:</h3>
            <p>{ snippetHtml }</p>
            {/* Render the raw HTML of the snippet */}
            <div
              className="whitespace-pre-wrap text-sm"
              dangerouslySetInnerHTML={{ __html: snippetHtml }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PaywallCreator;
