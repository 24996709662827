import React from 'react';

const WalletsAvatars = () => {
  // Embedded array of images within the component
  const images = [
    { url: './wos.png', name: 'Image 1' },
    { url: './zap.png', name: 'Image 1' },
    { url: './alby.png', name: 'Image 1' },
    { url: './muun.png', name: 'Image 1' },
    { url: './breeze.webp', name: 'Image 1' },
  ];

  return (
    <div className="isolate flex -space-x-2 overflow-hidden mt-4 mb-4">
      {images.map((image, index) => (
        <img
          key={index}
          className={`relative z-30 inline-block h-6 w-6  rounded-full ring-2 ring-white`}
          src={image.url}
          alt={image.name || `Image ${index + 1}`}
        />
      ))} 
          <div className='pl-5 py-1 text-xs text-indigo-900 opacity-80'>Receive payments via 9+ wallets</div>
      </div>
      
  );
};

export default WalletsAvatars;
