import {
    nip19,
    nip57,
    relayInit,
    generatePrivateKey,
    finishEvent,
    SimplePool,
  } from "nostr-tools";
  
  export const decodeNpub = (npub) => nip19.decode(npub).data;
  
  const decodeNoteId = (noteId) => nip19.decode(noteId).data;

  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
  
  let cachedProfileMetadata = {};
  
  export const getProfileMetadata = async (authorId) => {
    if (cachedProfileMetadata[authorId]) {
      return cachedProfileMetadata[authorId];
    }
  
    const metadata = await new Promise((resolve, reject) => {
      const relay = relayInit("wss://relay.damus.io");
  
      relay.on("connect", async () => {
  
        const metadata = await relay.get({
          authors: [authorId],
          kinds: [0],
        });
  
        cachedProfileMetadata[authorId] = metadata;
        resolve(metadata);
        relay.close();
      });
  
      relay.on("error", () => {
        reject(`failed to connect to ${relay.url}`);
        relay.close();
      });
  
      relay.connect();
    });
  
    if (!metadata) {
      throw new Error("failed to fetch user profile :(");
    }
  
    return metadata;
  };
  
  export const extractProfileMetadataContent = (profileMetadata) =>
    JSON.parse(profileMetadata.content);
  
  export const getZapEndpoint = async (profileMetadata) => {
    const zapEndpoint = await nip57.getZapEndpoint(profileMetadata);
  
    if (!zapEndpoint) {
      throw new Error("failed to retrieve zap endpoint :(");
    }
  
    return zapEndpoint;
  };
  
  const signEvent = async (zapEvent) => {
    if (isNipO7ExtAvailable()) {
      try {
        return await window.nostr.signEvent(zapEvent);
      } catch (e) {
        // fail silently and sign event as an anonymous user
      }
    }
  
    return finishEvent(zapEvent, privateKey);
  };
  
  const makeZapEvent = async ({ profile, event, amount, relays, comment }) => {
    const zapEvent = nip57.makeZapRequest({
      profile,
      event,
      amount,
      relays,
      comment,
    });
  
    return signEvent(zapEvent);
  };
  
  export const fetchInvoice = async ({
    zapEndpoint,
    amount,
    comment,
    authorId,
    noteId,
    normalizedRelays,
  }) => {
    const zapEvent = await makeZapEvent({
      profile: authorId,
      event: noteId ? decodeNoteId(noteId) : undefined,
      amount,
      relays: normalizedRelays,
      comment,
    });
    let url = `${zapEndpoint}?amount=${amount}&nostr=${encodeURIComponent(
      JSON.stringify(zapEvent)
    )}`;
  
    if (comment) {
      url = `${url}&comment=${encodeURIComponent(comment)}`;
    }
  
    const res = await fetch(url);
    if (!res.ok) {
        throw new Error(`Server responded with status code ${res.status}`);
    }
    // const { pr: invoice } = await res.json();
    const jsonResponse = await res.json();
    const { pr: invoice } = jsonResponse;
    
    return invoice;
  };
  
  export const isNipO7ExtAvailable = () => {
    return window !== undefined && window.nostr !== undefined;
  };
  
  export const listenForZapReceipt = async ( sub, EggID ) => {

    return new Promise((resolve, reject) => {
      const handler = async (event) => {
        try {
          if (
            event.kind === 4 &&
            event.tags.some(([tag, value]) => tag === 'p' && value === publicKey) &&
            event.tags.some(([tag, value]) => tag === 'EggID' && value === EggID) &&
            event.tags.some(([tag]) => tag === 'preimage')
          ) {
            // Once we got the response, remove this event listener
            sub.off('event', handler);
            resolve(event)
          }
        } catch (error) {
          console.error('Error handling event:', error.message, error);
          reject(error);
        }
      };
      // Register the event handler
      sub.on('event', handler);
    });
  };
  