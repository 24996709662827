import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { nip04 } from 'nostr-tools';
import { SketchPicker } from 'react-color';

import { TextInput, CheckBox, NumberInput, FormHeader, Modal, PaymentInfo, CancelButton, ConfirmButton, SimpleDropdown, WalletDropdown, CurrencyDropdown, LifetimeDurationDropdown, LoginMethodDropdown, IconDropdown, NumberInputWithConverter, SubscriptionPlanList } from '../comp/InputsLib.js';
import * as Utils from '../utils.js';
import {
  SummaryTitle, SummaryPrice, SummaryWallet, SummaryDuration, SummaryUser, SummarySnippet, SummaryKey, SummaryCreationDate, SummaryCopyButton, SummaryFooter, SummarySubscriptionPlanView
} from '../comp/SummaryFields.js';
import generateSubscriptionPlanSnippet from '../generateSnippets/generateSubscriptionPlanSnippet.js';

import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'

const SubscriptionPlanForm = ({ close }) => {
  // Get env var
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY
  const ourNostrWalletConnectUrl = process.env.REACT_APP_NOSTR_WALLET_CONNECT_URL

  // Get the values from session storage
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  let userAbout = localStorage.getItem('userAbout') || "";

  const [title, setTitle] = useState('');
  const [buttonColor, setButtonColor] = useState ('#000000');
  const [buttonText, setButtonText] = useState ();
  const [isUseWebhook, setIsUseWebhook] = useState (false);
  const [webhookUrl, setWebhookUrl] = useState ('');
  const [selectedLoginMethod, setSelectedLoginMethod] = useState ('email');
  const [appUrl, setAppUrl] = useState('');
  const [successUrl, setSuccessUrl] = useState('');
  const [failUrl, setFailUrl] = useState('');
  const [price, setPrice] = useState(0);
  const [currency, setCurrency] = useState('Satoshis');
  const [duration, setDuration] = useState('lifetime');
  const [expiryDate, setExpiryDate] = useState();
  const [wallet, setWallet] = useState('Alby');
  const user = userName;
  const [selector, setSelector] = useState('');
  const [convertedValue, setConvertedValue] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  // Add state for wallets
  const [wallets, setWallets] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const [selectedWalletLnAddress, setSelectedWalletLnAddress] = useState('');
  const [selectedWalletNwc, setSelectedWalletNwc] = useState('');
  const [flashMemo, setFlashMemo] = useState('');
  const [snippet, setSnippet] = useState('');
  const [key, setKey] = useState ();
  // States for Subscription Plans
  const [plans, setPlans] = useState([]);

  // the part to upload the images
  const [previewImage, setPreviewImage] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [isImageUploaded, setIsImageUploaded] = useState (false);

  // Error handle
  const [errors, setErrors] = useState ({});

  const [isModalOpen, setModalOpen] = useState(false);


  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handlePlansChange = (updatedPlans) => {
    setPlans(updatedPlans);
  };


  // Effect hook to get the current date in right format for display
  function formatDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
    }

    return `${month} ${day}${suffix(day)} ${year}`;
  }
  useEffect(() => {
    const currentDate = new Date();
    setFormattedDate(formatDate(currentDate));
  }, []);


  // Effect hook to directly set the exchange rate of sats to usd
  useEffect(() => {
    let isMounted = true;

    const fetchConvertedValue = async () => {
      const value = await Utils.convertCurrency(price, currency);
      if (isMounted) {
        setConvertedValue(value);
      }
    };

    fetchConvertedValue();

    return () => {
      isMounted = false;
    };
  },);

  // Effect hook to set the expiryDate of the Flash when the user changes the duration
  useEffect(() => {
    if (duration === 'lifetime') {
      // Option 1: Set a far future date for 'lifetime'
      const farFuture = new Date();
      farFuture.setFullYear(farFuture.getFullYear() + 100); // 100 years in the future
      setExpiryDate(farFuture);
  
      // Option 2: Handle 'lifetime' differently
      // setExpiryDate(null); // If your application logic can handle null as 'lifetime'
    } else {
      // Calculate the expiry date based on the selected duration
      const durationInDays = parseInt(duration.split(' ')[0]); // This will parse the numeric part from the duration string
      const newExpiryDate = new Date();
      newExpiryDate.setDate(newExpiryDate.getDate() + durationInDays); // Add the duration to the current date
      setExpiryDate(newExpiryDate); // Set the new date as the expiry date
    }
  }, [duration]); // This effect runs whenever the duration state changes

  // Effect hook to fetch wallets when the component mounts
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userHexPublicKey)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        const results = [];
  
        for (const item of data) {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
            decrypted_nwc_url = await nip04.decrypt(userHexPrivateKey, publicKey, item.nwc_url);
          } else {
            decrypted_nwc_url = ""
          }
          
          results.push({
            id: item.id,
            user_publicKey: item.user_public_key,
            nwc_url: decrypted_nwc_url,
            wallet_name: item.wallet_name,
            user_wallet_name: item.user_wallet_name,
            ln_address: item.ln_address
          });
        }
        setWallets(results);
  
        // Set the first wallet as the default wallet and its ID
        if (results.length > 0) {
          setWallet(results[0].user_wallet_name);
          setSelectedWalletId(results[0].id); // Set the ID of the first NWC
          setSelectedWalletNwc(results[0].nwc_url);
        }
      } catch (error) {
        console.error('Error fetching wallets:', error);
      }
    };
  
    fetchWallets();
  }, [userHexPrivateKey, userHexPublicKey, publicKey]); // Add dependencies if needed


  const handleWalletChange = (e) => {
    const selectedWalletId = e.target.value;
    const selectedWallet = wallets.find(wallet => wallet.id.toString() === selectedWalletId);
  
    if (selectedWallet) {
      setWallet(selectedWallet.user_wallet_name); // Sets the wallet name
      setSelectedWalletId(selectedWallet.id); // Sets the wallet ID
      setSelectedWalletLnAddress(selectedWallet.ln_address); // Sets the LN address
      setSelectedWalletNwc(selectedWallet.nwc_url);
    }
  };

  // the part  to upload the images
  const handleImageChange = async (e) => {
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }

    // Display preview of images
    const preview = {
        url: URL.createObjectURL(file),
        type: file.type,
    };

    setPreviewImage(preview);
    setCurrentImage(file);
    setIsImageUploaded(true);
  };

  const saveImageToDb = async (flashId) => {
    const formData = new FormData();
    formData.append('image', currentImage);
    formData.append('flash_id', flashId);

    try {
        const response = await axios.post('https://api.paywithflash.com/api/upload_subscription_picture', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.data.success) {
            console.log ("save the image successfully!");
            // newImageUrls.push(response.data.url);
        } else {
            console.error('Image upload failed:', response.data.error);
        }
    } catch (error) {
        console.error('Error uploading image:', error);
    }
  }
  
  const saveMembershipToDb = (flashId) => {
    const savePromises = plans.map(plan => {
      return fetch('https://api.paywithflash.com/api/save_membership', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              'name': plan.name,  
              'description': plan.description,
              'flash_id': flashId,
              'price': plan.price,
              'currency': plan.currency,
              'renewal_intervals': plan.renewal_intervals,
              'creation_date': new Date().toISOString(),
              'owner_public_key': userHexPublicKey
          })
      })
      .then(response => {
          // Check if the response is ok (status in the range 200-299)
          if (!response.ok) {
              // Throw an error with response status
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
      })
      .then(data => {
          // Log success message
          console.log('Membership created successfully:', data);
      })
      .catch(error => {
          // Log any errors, including HTTP errors
          console.error('Error creating membership:', error);
      });
    });

    return Promise.all(savePromises);
  };



  const handleConfirm = async () => {
    // Input Validation
    const displayingErrors = {};
    if (title == '') displayingErrors['title'] = "This field is required!";
    if (isUseWebhook && webhookUrl == '') displayingErrors['webhookUrl'] = 'This field is required';
    
    if (Object.keys (displayingErrors).length != 0) {
      setErrors (displayingErrors);
      setTimeout (() => {
        setErrors ({});
      }, 4000);
      return ;
    }
    
    // Logic for what happens when 'Confirm' is clicked
    event.preventDefault();
    try {
        // Prepare the data to be sent to the server for creating a new flash
        const formData = new FormData();
        formData.append('flash_name', title);
        formData.append('wallet_id', selectedWalletId);
        formData.append('user_public_key', userHexPublicKey);
        formData.append('flash_script', ''); // Initially empty
        formData.append('flash_creation_date', new Date().toISOString());
        formData.append('flash_expiry_date', expiryDate.toISOString()); // Directly using toISOString
        formData.append('flash_price', price);
        formData.append('flash_currency', currency);
        formData.append('flash_type', 'Subscription Plan');
        formData.append('flash_image_url', '');
        formData.append('flash_webhook_url', webhookUrl);
        formData.append('flash_login_method', selectedLoginMethod);
    
        // Make an API call to save the flash to the database
        let response = await fetch('https://api.paywithflash.com/api/save_flash_to_db', {
          method: 'POST',
          body: formData,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const saveResult = await response.json();
        console.log('Flash saved response:', saveResult); // Check the API response
        const flashId = saveResult.flash_id;
        setKey (saveResult.key);
        console.log('Flash saved:', saveResult);

        // Save the memberships to the db
        await saveMembershipToDb(flashId);

        const generatedSnippet = `https://app.paywithflash.com/subscription-page?flashId=${flashId}`;
        setSnippet(generatedSnippet)
    
        // Prepare data to update the flash with the generated snippet
        const updateData = new FormData();
        updateData.append('flash_id', flashId);
        updateData.append('flash_name', title);
        updateData.append('wallet_id', selectedWalletId);
        updateData.append('user_public_key', userHexPublicKey);
        updateData.append('flash_script', generatedSnippet);
        updateData.append('flash_creation_date', new Date().toISOString());
        updateData.append('flash_expiry_date', expiryDate.toISOString()); // Again using toISOString
        updateData.append('flash_price', price);
        updateData.append('flash_currency', currency);
        updateData.append('flash_type', 'Subscription Plan');
        updateData.append('flash_image_url', '');
        updateData.append('flash_webhook_url', webhookUrl);
        updateData.append('flash_login_method', selectedLoginMethod);
    
        // Make an API call to update the flash in the database
        response = await fetch('https://api.paywithflash.com/api/update_flash_in_db', {
          method: 'POST',
          body: updateData,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok during update');
        }
    
        const updateResult = await response.json();
        console.log('Flash updated with snippet:', updateResult);
        // Save the subscription image to the db
        await saveImageToDb(flashId);
  
    } catch (error) {
      console.error("Error during flash creation, update, and snippet generation:", error);
    }
  };
  
  const handleCopy = async (snippetText) => {
    // Logic for what happens when 'Copy' is clicked
    try {
      await navigator.clipboard.writeText(snippetText);
    } catch (error) {
      console.error('Failed to copy snippet:', error);
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSelectorChange = (e) => {
    setSelector(e.target.value);
  };

  const handleAppUrlChange = (e) => {
    setAppUrl(e.target.value);
  };

  const handleSuccessUrlChange = (e) => {
    setSuccessUrl(e.target.value);
  };

  const handleFailUrlChange = (e) => {
    setFailUrl(e.target.value);
  };

  const handleColorChange = (newColor) => {
    setButtonColor (newColor.hex);
  };

  const handleImageUrlChange = (e) => {
    setImageUrl (e.target.value);
  }

  const handleUseWebhookChange = () => {
    setIsUseWebhook (!isUseWebhook);
  }
  const handleWebhookUrlChange = (e) => {
    setWebhookUrl (e.target.value);
  }

  const handleLoginMethodChange = (e) => {
    setSelectedLoginMethod (e.target.value);
  }

  const handleButtonTextChange = (e) => {
    setButtonText (e.target.value);
  }

  // the part to upload the images
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
  };

  const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
  };

  const handleImagesDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      handleImageChange(e);
  };

return (
  <div className="flex flex-col modal-base">
    <div className="flex-1 p-14 overflow-auto h-screen">
      <FormHeader
        title="Create a Subscription Plan"
        subtitle="Add a subscription plan to any app and receive payments in Bitcoin through Lightning, with a simple snippet. Add it on any page, pause or delete anytime."
        infoBadge={{
          text: "Learn more about Subscription plans",
          color: "bg-gray-100 text-gray-600",
          onClick: openModal
          }}
        newEggBadge={{
          text: "New Flash",
          color: "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-600/20 mb-3"
          }}
      />

      {/* Form Inputs */}
      <div className="inputform">
        <TextInput label="Title*" placeholder="Enter the title" value={title} onChange={handleTitleChange} />
        { 'title' in errors && 
          <small className="text-md font-medium text-red-500">{errors.title}</small>
        }
      </div>
      {/* <div className = "inputform">
        <label className = "block text-sm font-medium text-indigo-300 mb-2"> Button Color </label>
        <SketchPicker color={buttonColor} onChange={handleColorChange} />
        <div style = {{marginTop : '20px'}} className = "flex flex-row space-x-3">
          <label className = "block text-sm font-medium text-indigo-300 mb-2"> Selected Color </label>
          <button style={{ backgroundColor : buttonColor, width : '40px', height : '20px'}}></button>
        </div>
      </div> */}
      {/* <div className="inputform">
          <TextInput label="HTML Selector (optional)" placeholder="Enter the id of the html element that your button should follow" value={selector} onChange={handleSelectorChange} />
      </div> */}
      {/* <div className="inputform">
        <TextInput label="Button Text" placeholder="Enter the button text" value={buttonText} onChange={handleButtonTextChange} />
      </div> */}
      {/* Images upload and display logic */}
      <div className="inputform sm:col-span-4">
        <label htmlFor="images" className="block text-sm font-medium leading-6 text-gray-900">
            Subscription Image*
        </label>
        { !isImageUploaded ? (
          <div
              className={`mt-1 flex justify-center rounded-lg border border-dashed px-6 py-10 ${isDragging ? 'border-indigo-600 bg-indigo-50' : 'border-gray-900/25'
                  }`}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleImagesDrop}
          >
            <div className="text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                        htmlFor="images"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                        <span>Upload a file</span>
                        <input
                            id="images"
                            name="images"
                            type="file"
                            onChange={handleImageChange}
                            className="sr-only"
                        />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        ) : (
          <div className="mt-4 grid grid-cols-4 gap-4">
              { previewImage && (
                <div className="relative aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                    { previewImage.type.includes('image') ? (
                        <img src={previewImage.url} alt='Preview Image' className="h-full w-full object-cover object-center" />
                    ): (
                      <div className="flex justify-center items-center h-full bg-gray-300">
                          <svg className="w-20 h-20 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18v4m0-22v4m0 0h4m-4 0H8m8 4a2 2 0 00-2-2H8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V10z" />
                          </svg>
                      </div>
                    )}
                    
                    <button
                        onClick={() => {
                            setPreviewImage(null);
                            setCurrentImage(null);
                            setIsImageUploaded(false);
                        }}
                        className="absolute right-0 top-0 w-6 h-6 m-2 p-1 rounded-full text-white hover:bg-opacity-90 bg-black bg-opacity-80 flex items-center justify-center"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
              )}
          </div>
        )}
        { 'image' in errors &&
          <small className="text-md font-medium text-red-500">{errors.image}</small>
        }
      </div>

      {/* <div className="inputform">
        <TextInput label="Image Url*" placeholder="Enter the image url" value={imageUrl} onChange={handleImageUrlChange} />
        { 'imageUrl' in errors && 
          <small className="text-md font-medium text-red-500">{errors.imageUrl}</small>
        }
      </div> */}
      <div className="inputform">
        <CheckBox label="Use Advanced Webhook Features" isChecked={isUseWebhook} onChange={handleUseWebhookChange}></CheckBox>
      </div>
      { isUseWebhook && 
        <div className="inputform">
          <TextInput label="Webhook Url*" placeholder="Enter the webhook url" value={webhookUrl} onChange={handleWebhookUrlChange} />
          <small className = "block text-sm font-medium text-gray-400 mt-1"> * We will send a POST request to this URL. * </small>
          { 'webhookUrl' in errors && 
          <small className="text-md font-medium text-red-500">{errors.webhookUrl}</small>
        }
        </div>
      }
      <div className = "inputform">
        <LoginMethodDropdown label = "User Login Method" id = "loginMethod" name = "loginMethod" value = {selectedLoginMethod} onChange = {handleLoginMethodChange}></LoginMethodDropdown>
      </div>
      {/* <div className="inputform">
        <TextInput label="App Url" placeholder="Enter the app url" value={appUrl} onChange=f{handleAppUrlChange} />
      </div>
      <div className="inputform">
        <TextInput label="Success Url" placeholder="Url to redirect user if login is successful" value={successUrl} onChange={handleSuccessUrlChange} />
      </div>
      <div className="inputform">
        <TextInput label="Fail Url" placeholder="Url to redirect user if login fails" value={failUrl} onChange={handleFailUrlChange} />
      </div> */}
      <div className="inputform">
        <WalletDropdown label="Wallet" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
      </div>
      <div className="inputform">
        <SubscriptionPlanList label="Subscription Plans" id="plans" name="plans" plans={plans} onPlansChange={handlePlansChange} />
      </div>
      <div className="inputform">
        <ConfirmButton onClick={handleConfirm}> Create subscription plan </ConfirmButton>
        <CancelButton onClose={close} />
      </div>
      <div className="inputform">
        <PaymentInfo text="Payments will go directly to your connected wallet." />
      </div>

      {/* Summary Column Content Now Here */}
      <div className="p-0">
        <div className="">
          {/* <SummaryTitle title={title || 'Flash Title'} />
          <SummaryCreationDate creationDate={formattedDate} />
          <SummaryWallet wallet={wallet} />
          <SummaryDuration duration={duration} />
          <SummaryUser user={user} /> */}
          {/* <SummarySubscriptionPlanView plans={plans} /> */}
          <SummarySnippet snippet={snippet} label="Subscription Url" />
          <SummaryCopyButton text="Copy Url" onClick={() => handleCopy(snippet)} />
          <SummaryKey keyForWebhook={key} label="Subscription Key"/>
          <SummaryCopyButton text="Copy Key" onClick={() => handleCopy(key)} />
          <SummaryFooter />
        </div>
      </div>
    </div>
    {isModalOpen && (
        <Modal onClose={closeModal}>
          <iframe 
            src="https://scribehow.com/embed/Create_a_Subscription_Plan__UqqbUBOBTYKBmkdMOVmZtw?removeLogo=true" 
            width="100%" 
            height="640" 
            allowFullScreen 
            frameBorder="0"
          ></iframe>
        </Modal>
      )}
  </div>
);
};

export default SubscriptionPlanForm;
