import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

const VerifySuccess = () => {

  const navigate = useNavigate ();
  const { token } = useParams ();

  const [isVerifySuccess, setIsVerifySuccess] = useState ();
  const [data, setData] = useState ({});

  useEffect (() => {
    const verifyLink = async () => {
        try {
          const response = await fetch (`https://api.paywithflash.com/api/verify_email/${token}`);
    
          if (!response.ok) {
            console.error ('An Error in verifying the link');
            throw new Error (`An Error in verifying the link: ${response.status}`);
          }
          const data = await response.json ();
          return data;
        } catch (error) {
          console.error (`An Error in verifying the link: ${error}`);
          throw new Error (`An Error in verifying the link: ${error}`);
        }
      }

      verifyLink ()
        .then (async(data) => {
          setIsVerifySuccess (data.success);
          setData (data);
        })
        .catch (error => {
          console.error (error);
        })

  }, [token])

  useEffect (() => {
    if (isVerifySuccess) {
      setTimeout (() => {
        navigate ('/dashboard');
      }, 3000)
    }
  }, [isVerifySuccess]);

  if ('success' in data && data.success == false) return (<div>{data.message}</div>);

  return (
    <div className="flex items-center justify-center flex-col mt-[150px]">
      { isVerifySuccess && (
        <>
          <section className="lg:max-w-2xl w-3/4 mx-auto bg-white border border-blue-800 rounded-lg">
            <header className="py-8 flex justify-center w-full">
              <a href="#">
                <img
                  src="/flash-logo-dark.png"
                  alt="Flash Logo"
                />
              </a>
            </header>
            <div className="w-full h-[2px] bg-indigo-600"></div>
            <div className="text-center mt-10 flex flex-col gap-3">
              <h1 className="text-3xl font-semibold mx-2">
                Thanks for {""}
                <span className="relative">
                  Verifying your email address!
                </span>
              </h1>
            </div>
            <main className="mt-8 px-5 sm:px-10">
              <h3>
                Hey , Thanks for completing the Signing up!
              </h3>
              <br />
              <p className="mt-8 text-gray-600">
                Thank you, <br />
                Flash Support Team
              </p>
            </main>
            <footer className="mt-8">
              <div className="bg-indigo-600 py-5 text-white text-center">
                <p className="mt-3 ">
                  © {new Date().getFullYear()} Paywithflash.
                </p>
              </div>
            </footer>
          </section>
        </>
      )}
    </div>
  );
};

export default VerifySuccess;