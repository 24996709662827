import React from 'react';
import { LockClosedIcon, GlobeAltIcon } from '@heroicons/react/24/outline';


function PrivacyStatusComponent({ privacy_status, is_owner, on_privacy_change }) {


    return (

        <React.Fragment>
            <div className='w-full p-6 pr-20 pl-20 border-t border-gray-100'>
                {/* Private Button */}
                <button
                    type="button"
                    disabled={!is_owner}
                    className={`inline-flex items-center gap-x-1.5 rounded-md ${privacy_status === 'Private' ? 'bg-purple-800 hover:bg-purple-900' : 'bg-gray-200 hover:bg-gray-300'} px-2.5 py-1.5 text-sm font-semibold ${privacy_status === 'Private' ? 'text-white' : 'text-gray-600'} shadow-sm hover:purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 w-1/2 ${!is_owner ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => on_privacy_change('Private')}
                >
                    <LockClosedIcon className="h-5 w-5 inline mr-2" />
                    Private
                </button>

                {/* Public Button */}
                <button
                    type="button"
                    disabled={!is_owner}
                    className={`inline-flex items-center gap-x-1.5 rounded-md ${privacy_status === 'Public' ? 'bg-purple-800 hover:bg-purple-900' : 'bg-gray-200 hover:bg-gray-300'} px-2.5 py-1.5 text-sm font-semibold ${privacy_status === 'Public' ? 'text-white' : 'text-gray-600'} shadow-sm hover:purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 w-1/2 ${!is_owner ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => on_privacy_change('Public')}
                >
                    <GlobeAltIcon className="h-5 w-5 inline mr-2" />
                    Public
                </button>
            </div>
        </React.Fragment>

    );
    
    
}

export default PrivacyStatusComponent;
