import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import EditFlashForm from '../FlashForms/forms/EditFlashForm';
import { AiOutlineConsoleSql } from 'react-icons/ai';

// Helper function for classNames
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Card Component
const Card = ({ onClick }) => (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <button 
            onClick = {() => onClick ()}
            className="rounded bg-blue-600 px-4 py-2 text-md font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
            Edit Subscription
        </button>
        </dd>
    </div>
);

// Cards Component
export const Cards = ({ flash }) => {
  const [open, setOpen] = useState(false);
 
  const openModal = () => {
      setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <div className="mb-8">
        <Card onClick={openModal} />
      </div>
          
      {/* Modal Logic */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 modal-base modal-pop">
                    <EditFlashForm flash = {flash} close = {closeModal} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

// The page where you use the Cards component
export function EditSubscription(flash) {
  return (
    <div>
      <Cards
        flash = {flash}
      />
    </div>
  );
}
