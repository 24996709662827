import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { SimplePool } from 'nostr-tools';
import { useLocation } from 'react-router-dom';



import PayWithFlashButton from '../../payWithFlash/payWithFlashButton';
import LnInvoiceHashDisplayAndCopy from '../../paymentComponents/LnInvoiceHashDisplayAndCopy';



const SubsciptionVideoPaywallModal = ({ flashId, decryptedConfig, onPaymentSuccess, updateHasPaidWithExtension }) => {

  const [flashDetails, setFlashDetails] = useState({});
  const [walletData, setWalletData] = useState({});
  const [nwcUrl, setNwcUrl] = useState('');
  const [priceInDollars, setPriceInDollars] = useState('');
  const [priceInSats, setPriceInSats] = useState('');
  const [bitcoinPrice, setBitcoinPrice] = useState('');
  const [isPaid, setIsPaid] = useState(false);
  const [isLoadingColumn1, setIsLoadingColumn1] = useState(true);
  const [isLoadingColumn2, setIsLoadingColumn2] = useState(true);
  const [invoice, setInvoice] = useState('');
  const [userPrivateKey, setUserPrivateKey] = useState('');
  const [userPublicKey, setUserPublicKey] = useState('');
  const [userMetadata, setUserMetadata] = useState('');

  const [payerUserPublicKey, setPayerUserPublicKey] = useState('') || "";
  const [payerSelectedWallet, setPayerSelectedWallet] = useState({});



  // Function to fetch the user metadata
  const fetchUserMetadata = async (publicKey) => {
    const relayUrls = ["wss://relay.paywithflash.com", "wss://relay.damus.io", "wss://relay.primal.net", "wss://relay.snort.social", "wss://relay.nostr.band"];
    const pool = new SimplePool();

    const filters = [{
      pubkey: publicKey,
      kinds: [0]
    }];

    try {
      const events = await pool.list(relayUrls, filters);

      const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
      if (userMatchingEvents.length > 0) {
        // Process the array of matching events
      } else {
        console.log('No events found with the matching public key.');
      }

      // Sort the events by the 'created_at' timestamp in descending order
      const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);

      // Find the first event with non-empty content
      const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
      if (metadataEvent) {
        const metadata = JSON.parse(metadataEvent.content);
        // Store user metadata in variables
        const userName = metadata.name;
        const userPictureUrl = metadata.picture;
        const userAbout = metadata.about;

        return { userName, userPictureUrl, userAbout };
      } else {
        console.log('No metadata found for the given public key.');
        return { 
          userName: 'Unknown User', 
          userPictureUrl: 'default_picture_url', // Replace with a default picture URL
          userAbout: 'No description available' 
        };
      }
    } catch (error) {
      console.error('An error occurred while fetching user metadata:', error);
      throw error;
    }
  };

  // Function to get the Flash details from db using the flashID from requested url
  async function fetchFlashById(flashId) {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_flash_from_id?flash_id=${encodeURIComponent(flashId)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }
  
  

  // Function to get the nwc url from the db using the nwc_id
  async function fetchWalletUrlById(wallet_id) {
    try {
      const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
      url.searchParams.append('wallet_id', wallet_id);
  
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
    }
  }


  // Function to decrypt contents using the user public key
  async function decryptContents(userPublicKey, encryptedProperties) {
    try {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/decrypt_content', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          userPublicKey: userPublicKey,
          encryptedProperties: encryptedProperties
        }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
      }
  
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
    }
  }


  // Function to get the price of Bitcoin
  async function getBitcoinPrice() {
    try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST', // if the API requires POST method
        headers: {
          'Content-Type': 'application/json',
        },
        // If the API requires a body payload, uncomment and edit the following line
        // body: JSON.stringify({ key: 'value' }), 
      });
  
      if (!response.ok) {
        // If the response is not OK, throw an error with the response status
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Assuming the API returns an object with a property that contains the Bitcoin price
      return data
    } catch (error) {
      console.error('API call failed:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }


  // Function to convert the price from currency in order to have both satoshis and usd values
  async function convertPrice(price, currency) {
    const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin
    let priceInSats, priceInDollars;
  
    try {
      const bitcoinPrice = await getBitcoinPrice();
      setBitcoinPrice(bitcoinPrice)
  
      if (!bitcoinPrice) {
        throw new Error('Failed to fetch Bitcoin price');
      }
  
      if (currency === "Dollar") {
        const amountInUSD = parseFloat(price);
        priceInSats = Math.round((amountInUSD / bitcoinPrice) * oneBitcoinInSats);
        priceInDollars = amountInUSD;
        setPriceInSats(priceInSats)
        setPriceInDollars(priceInDollars)
      } else if (currency === "Satoshis") {
        const amountInSats = parseFloat(price);
        priceInSats = amountInSats;
        priceInDollars = ((amountInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
        setPriceInSats(priceInSats)
        setPriceInDollars(priceInDollars)
      } else {
        throw new Error('Unknown currency type: ' + currency);
      }
  
      return { priceInDollars, priceInSats };
    } catch (error) {
      console.error('Error in conversion:', error);
      throw error;
    }
  }


  // Function used to create the Zap Invoice
  async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }
    // const data = "lnbc10u1pj6j4gzpp5tzam0ynwcfv3prxmlj59lg40ssw3qfdyceeq0kzsmdg7vrg9kejqdpv2pshjmt9de6zqen0wgsy67fq2pshjmt9de6zqnrfde4scqzzsxqyz5vqsp5nmgtcgn3lnw2amw7rugnmtrdu9mfcw7kend7n3z5mk8rnh2symps9qyyssqvg8604jgpkc8eyknyekr5l8gyu8glclq09xdk8fcjep2jlj5cgrr53uc5lsmlsepe50hgvtgpdjd8v7w3xwdc7qwv02yq2rph9v4ulqqt69u8p"
    return data;
  }


  // Function used to send the Zap Invoice to the user and request payment via webln
  async function requestWebLnPayment(invoice) {
    if (typeof window.webln === "undefined") {
      throw new Error("No WebLN available. Get a bitcoin lightning wallet. We recommend www.getalby.com");
    }

    try {
      await window.webln.enable();
      const result = await window.webln.sendPayment(invoice);
      return result;
    } catch (error) {
      console.error("Failed to pay invoice:", error);
    }
  }


  // Function to display the QR code of the invoice
  async function showQRCode(invoice) {
    // Load the QR code library
    try {
      await new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://cdnjs.cloudflare.com/ajax/libs/qrcode-generator/1.4.4/qrcode.min.js";
        script.onload = resolve;
        script.onerror = () => reject(new Error('Error loading QRCode library'));
        document.head.appendChild(script);
      });
    } catch (error) {
      console.error(error.message);
      return; // Stop the execution of the function if the import fails
    }
  
    // Ensure qrcode function is available globally
    if (typeof qrcode !== 'function') {
      console.error('qrcode function is not available.');
      return;
    }
  
    // Create QR Code instance
    let qr = qrcode(0, 'H'); // Error correction level: 'H'
    qr.addData(invoice);
    qr.make();
  
    // Create an image tag for the QR code
    let imgTag = qr.createImgTag(4); // Cell size: 4
  
    // Append the image tag to the element with ID 'qrcode'
    document.getElementById('qrcode').innerHTML = imgTag;
  }
  
  


  // Function to check if the invoice was paid
  async function checkInvoicePayment(encryptedUserNostrWalletConnectUrl, invoice) {
    const startTime = Date.now();
    let isPaid = false;

    while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
      try {
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userPublicKey: userPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            invoice: invoice
          }), // Assuming nostrWalletConnectUrl is handled server-side
        });
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to check payment');
        }

        if (data.is_paid) {
          isPaid = true;
          break; // Exit the loop if the invoice is paid
        }

        // If not paid, wait 1 second before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));

      } catch (error) {
        console.error("Error checking invoice payment:", error);
        // You might want to decide if you should throw an error or perhaps continue trying
        // For now, we'll wait 1 second before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }

    return isPaid;
  }


  // Function to save the transaction to table transactions of the db
  async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
    const formData = new FormData();
    formData.append('from_public_key', from_public_key);
    formData.append('to_public_key', to_public_key);
    formData.append('transaction_date', transactionDate);
    formData.append('amount_in_sats', amountInSats);
    formData.append('value_in_usd', valueInUsd);
    formData.append('flash_id', flashId);
    formData.append('flash_name', flashName);
    formData.append('flash_type', flashType); // Assuming this is also a new requirement
    formData.append('to_wallet_id', toWalletId);
    formData.append('to_user_wallet_name', toUserWalletName);
    formData.append('from_wallet_id', fromWalletId);
    formData.append('from_user_wallet_name', fromUserWalletName);
    formData.append('lnurl', lnurl);

    try {
      const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save transaction to database');
      }

      const successData = await response.json();
      console.log('Transaction successfully saved to database:', successData);

      return successData;
    } catch (error) {
      console.error('Error saving transaction to database:', error);
      throw error;
    }
  }


  // The function used to for the user to pay the fee owed to Flash
  async function payFeeToFlash(encryptedUserNostrWalletConnectUrl, amount, memo) {

    const transactionDate = new Date().toISOString();
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
        user_public_key: flashDetails.user_public_key
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }

    return data;
  }


  // The function used to create the Zap Invoice
  async function payUserMinusFee(encryptedUserNostrWalletConnectUrl, amount, memo) {
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_user_minus_fee', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        lnAddress: lnAddress
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }

    return data.invoice;
  }



  
  // useEffect for fetching flash details and initializing payment process
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingColumn1(true);
      setIsLoadingColumn2(true);
      try {
        const flashData = await fetchFlashById(flashId);
        if (!flashData) {
          console.error('Failed to fetch flash data');
          return;
        }
        setFlashDetails(flashData);
        setUserPublicKey(flashData.user_public_key);
  
        const walletData = await fetchWalletUrlById(flashData.wallet_id);
        if (!walletData) {
          console.error('Failed to fetch Wallet NWC URL');
          return;
        }
        setWalletData(walletData);
        
        // Set nwcUrl based on walletData
        if (walletData.nwc_url !== "") {
          setNwcUrl(walletData.nwc_url);  // Setting nwcUrl here
        } else {
          setNwcUrl("FlashNWC");
        }
        
        // Only proceed if nwcUrl is set
        if (nwcUrl) {
            const { priceInDollars, priceInSats } = await convertPrice(flashData.price, flashData.currency);
            if (!priceInDollars || !priceInSats) {
              console.error('Failed to convert price');
              return;
            }
            setPriceInDollars(priceInDollars);
            setPriceInSats(priceInSats);

            const createdInvoice = await createZapInvoice(userPublicKey, nwcUrl, priceInSats, 'Payment for ' + flashData.name);
            if (!createdInvoice) {
              console.error('Failed to create invoice');
              return;
            }
            setInvoice(createdInvoice.invoice);
        }
      } catch (error) {
        console.error('Error in fetching data:', error);
      }
      // setIsLoading(false);
    };

    if (flashId) {
      fetchData();
    }
  }, [flashId, nwcUrl]); // Dependency array includes flashId

  useEffect(() => {
    if (invoice) {
      showQRCode(invoice).catch(console.error);
      setIsLoadingColumn2(false);
      checkPaymentStatus(nwcUrl, invoice);
    }
  }, [invoice]);


  useEffect(() => {
    const fetchMetadata = async () => {
      if (userPublicKey) {
        try {
          const userMetadata = await fetchUserMetadata(flashDetails.user_public_key);
          setUserMetadata(userMetadata);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        } finally {
          setIsLoadingColumn1(false); // Set loading to false only after fetching metadata
        }
      }
    };
  
    if (userPublicKey) {
      fetchMetadata();
    }
  }, [userPublicKey]);
  
  

  // Checks the payment status of the invoice
  const checkPaymentStatus = async (nwcUrlForInvoice, invoice) => {
    const paymentStatus = await checkInvoicePayment(nwcUrlForInvoice, invoice);
    if (paymentStatus) {
      setIsPaid(true);

      // Call the parent's callback
      onPaymentSuccess();

      // Get current date
      const currentDate = new Date().toISOString();

      await saveTransactionToDB("", flashDetails.user_public_key, currentDate, priceInSats, priceInDollars, flashId, flashDetails.name, "Subscription Video Paywall", walletData.id, walletData.user_wallet_name, 0, "", invoice)

      // Calculate the fee and price minus fee
      const feePercentage = 1; // 1%

      // Assuming priceInSats is already defined and is the total price in Satoshis
      let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
      satsFee = Math.round(satsFee); // Rounding to the nearest whole number

      // Pay fee to flash
      const response = await payFeeToFlash(nwcUrl, satsFee, `Fee for flash id: ${flashId}`);
      const fee_invoice = response.invoice;
      const isPaid = response.is_paid;
    }
  };




  // handlePaymentClick function handles web extension payment and sets hasPaidWithExtension to true
  const handlePaymentClick = async () => {
    try {
        await requestWebLnPayment(invoice);
        // Call the callback function to update state in the parent
        updateHasPaidWithExtension(true);
    } catch (error) {
        console.error('Error in handling payment:', error);
    }
};


// handlePaymentClick function handles web extension payment and sets hasPaidWithExtension to true
const handlePayWithFlashSuccess = async () => {
  try {
     // Get current date
    const currentDate = new Date().toISOString();

    await saveTransactionToDB(payerUserPublicKey, flashDetails.user_public_key, currentDate, priceInSats, priceInDollars, flashId, flashDetails.name, "Subscription Video Paywall", walletData.id, walletData.user_wallet_name, payerSelectedWallet.id, payerSelectedWallet.user_wallet_name, invoice)

    // Calculate the fee and price minus fee
    const feePercentage = 1; // 1%

    // Assuming priceInSats is already defined and is the total price in Satoshis
    let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
    satsFee = Math.round(satsFee); // Rounding to the nearest whole number
    
    // Pay fee to flash
    const response = await payFeeToFlash(nwcUrl, satsFee, `Fee for flash id: ${flashId}`);
    const fee_invoice = response.invoice;
    const isPaid = response.is_paid;
    
    // Update UI state at the end
    setIsPaid(true);
    // Call the parent's callback
    onPaymentSuccess();
  } catch (error) {
      console.error('Error in Pay With Flash', error);
  }
};


  


  const SkeletonLoaderColumn1 = () => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-16 h-16 bg-gray-300 rounded-full mb-4 animate-pulse"></div> {/* Skeleton Image */}
      </div>
    );
  };


  const SkeletonLoaderColumn2 = () => {
    return (
      <div className="flex flex-col items-center w-full">
        <div className="w-16 h-16 bg-gray-300 rounded-full mb-4 animate-pulse"></div> {/* Skeleton Image */}
      </div>
    );
  };
  

return (
  <div className="fixed inset-0 flex items-center justify-center z-50">
        {/* Overlay Background */}
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75"></div>

        {/* Modal Content */}
        <div className="w-full sm:w-full md:w-2/3 lg:w-1/3 p-8 shadow-lg rounded-2xl bg-white relative z-10">
          {/* Small Flash Logo Centered */}
          <img src="https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/flash-logo-dark.png" alt="Flash Logo" className="h-10 w-auto mb-5 self-center" />

          {!isPaid ? (
            <>
              {/* Client Logo and Name Centered */}
              <div className="text-center mb-4">
                {isLoadingColumn1 ? (
                  <SkeletonLoaderColumn1 />
                ) : (
                  <div className="flex flex-col items-center text-black">
                    <img className="rounded-full mb-2 border border-gray-300" src={userMetadata.userPictureUrl} alt="Profile Image" style={{ width: '60px', height: '60px' }} />
                    <h2 className="text-md font-semibold">Video Paywall</h2>
                    <p className="text-sm">This video is protected by a paywall.</p>
                    <p className="text-sm">It will cost you {parseInt(flashDetails.price).toLocaleString()} {flashDetails.currency} every {decryptedConfig.videoPlayingInterval} seconds.</p>
                  </div>
                )}
              </div>
    
              {/* QR Code Centered */}
              <div className="text-center mb-2 mt-2">
                {isLoadingColumn2 ? (
                  <SkeletonLoaderColumn2 />
                ) : (
                  <div className="inline-block" style={{ width: '180px', height: '180px' }}>
                    <QRCode
                        value={invoice}
                        size={180}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        renderAs={"svg"}
                        imageSettings={{
                            src: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png",
                            excavate: true,
                            height: 40,
                            width: 40
                        }}
                    />
                  </div>
                )}
              </div>
    
              {/* Payment Information and Buttons Centered */}
              <div className="text-center mb-0">
                <div className="flex justify-between items-center mb-4 mt-3">
                  <span className="text-md font-medium text-gray-700">Price:</span>
                  <span className="text-md font-bold text-gray-800">{parseInt(flashDetails.price).toLocaleString()} {flashDetails.currency}</span>
                </div>
                <LnInvoiceHashDisplayAndCopy value={invoice} />
                <div className="flex justify-center space-x-2 mt-3">
                  <div className="w-1/2">
                  <PayWithFlashButton flashDetails={flashDetails} onPaymentSuccess={handlePayWithFlashSuccess} payerSelectedWallet={payerSelectedWallet} setPayerSelectedWallet={setPayerSelectedWallet} payerUserPublicKey={payerUserPublicKey} setPayerUserPublicKey={setPayerUserPublicKey} className="w-full text-lg py-3 bg-gradient-to-r from-purple-600 to-purple-500 hover:from-purple-700 hover:to-purple-600"/>
                  </div>
                  <div className="">
                    <button className="text-md text-sm text-white font-bold px-4 py-3 rounded cursor-pointer bg-blue-600 hover:bg-blue-700 w-full" onClick={handlePaymentClick}>Pay with Extension</button>
                  </div>
                </div>
              </div>
    
              {/* Footer section Centered */}
              <div className="mt-6 mb-0 text-sm text-center">
                <span className="text-gray-700 mr-1">Fastest way to pay</span>
                <a href="https://paywithflash.com/" className="text-blue-600 hover:text-blue-800">Find out how</a>
              </div>
            </>
          ) : (
            <p>Payment successful! Thank you.</p>
          )}
        </div>
    </div>
);


};

export default SubsciptionVideoPaywallModal;