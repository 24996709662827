import React, { useEffect, useState } from 'react';
import { nip04, getPublicKey } from 'nostr-tools';
import { webln } from "@getalby/sdk";
import WalletConnectButton2 from './WalletConnectButton2';
import { getAndUpdateWalletList } from '../../Redux/Actions/walletAction';

const mockWallets = [
    {
        id: 1,
        user_publicKey: 'publicKey1',
        wallet_name: 'walletName1',
        user_wallet_name: 'My Alby Wallet',
        nwc_url: 'nwcUrl1',
        balance: '100',
        wallet_logo_url: 'logoUrl1',
    },
    {
        id: 2,
        user_publicKey: 'publicKey2',
        wallet_name: 'walletName2',
        user_wallet_name: 'Mutiny Wallet',
        nwc_url: 'nwcUrl2',
        balance: '200',
        wallet_logo_url: 'logoUrl2',
    },
    // Add more mock wallets as needed
];

function WalletDropdown({ wallets }) {

    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    let userNpub = localStorage.getItem('userNpub') || "";
    let userName = localStorage.getItem('userName') || "";
    let userAbout = localStorage.getItem('userAbout') || "";
    let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

    // States for data received upon page load
    const [flashPublicKey, setFlashPublicKey] = useState(null);

    // States for user wallet
    const [totalBalance, setTotalBalance] = useState(0);
    const [walletCount, setWalletCount] = useState(0);

    const getWalletNameFromNWCString = async (nwcString) => {
        const walletsAvailable = ["Alby", "Mutiny"];
        // Convert nwcString to lowercase for case-insensitive comparison
        const lowerCaseNWCString = nwcString.toLowerCase();

        // Iterating over each wallet name to see if it's present in the nwcString
        for (const walletName of walletsAvailable) {
            // Also convert walletName to lowercase
            if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
                return walletName;
            }
        }

        // Return "Custom" if no match is found
        return "Custom";
    };

    const fetchEncryptedContent = async (userPublicKey, propertiesToDecrypt) => {
        try {
            const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/encrypt_content`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userPublicKey: userPublicKey,
                propertiesToDecrypt: propertiesToDecrypt,
            }),
            });
        
            if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
            }
        
            const data = await response.json();
            return data.properties;
        } catch (error) {
            console.error('Error in fetchEncryptedContent:', error);
            throw error;
        }
    };

    const fetchDecryptedContent = async (userPublicKey, encryptedProperties) => {
        try {
          const response = await fetch(`${API_BASE_URL_NODEJS}/api/decrypt_content`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              userPublicKey: userPublicKey,
              encryptedProperties: encryptedProperties,
            }),
          });
      
          if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
          }
      
          const data = await response.json();
          return data.properties;
        } catch (error) {
          console.error('Error in fetchDecryptedContent:', error);
          throw error;
        }
    };


    // Function to be able to add new wallets
    async function saveWalletToDB(selectedWalletName, userWalletName, walletNwcUrl) {
        // Encrypt the nwcUrl
        let encrypted_nwc_url;
        if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
            encrypted_nwc_url = await fetchEncryptedContent(userHexPublicKey, walletNwcUrl);
        } else {
            encrypted_nwc_url = "";
        }

        const formData = new FormData();
        formData.append('user_public_key', userHexPublicKey);
        formData.append('wallet_name', selectedWalletName);
        formData.append('ln_address', "");
        formData.append('user_wallet_name', userWalletName);
        formData.append('nwc_url', encrypted_nwc_url);

        try {
            const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }

    const handleWalletConnected = async (appName, nwcString) => {
        try {

            const providerName = await getWalletNameFromNWCString(nwcString);
            await saveWalletToDB(providerName, appName, nwcString);

            await getAndUpdateWalletList (userHexPrivateKey, publicKey);
    
            // // Fetch updated list of user wallets
            // const userWallets = await getUserWallets(userHexPrivateKey, publicKey);
        } catch (error) {
            console.error('Error saving new wallet or fetching wallets:', error);
        }
    };
    

    // Function that gets all user wallets
    async function getUserWallets(userPrivateKey, flashPublicKey) {
        const userPublicKey = getPublicKey(userPrivateKey);
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const walletPromises = data.map(async item => {
                let decrypted_nwc_url;
                if (item.nwc_url !== "") {
                    decrypted_nwc_url = await nip04.decrypt(userPrivateKey, flashPublicKey, item.nwc_url);
                } else {
                    decrypted_nwc_url = "";
                }

                // Fetch balance and logo URL concurrently
                const balancePromise = getWalletBalance(decrypted_nwc_url); // Abstracted balance fetching to a function
                const logoUrlPromise = getWalletLogoUrlFromNWCString(decrypted_nwc_url); // Fetch logo URL

                try {
                    const [balanceResponse, walletLogoUrl] = await Promise.all([balancePromise, logoUrlPromise]);

                    return {
                        id: item.id,
                        user_publicKey: item.user_public_key,
                        wallet_name: item.wallet_name,
                        user_wallet_name: item.user_wallet_name,
                        nwc_url: item.nwc_url,
                        balance: balanceResponse.balance, // Adding balance to the result
                        wallet_logo_url: walletLogoUrl // Adding wallet logo URL to the result
                    };
                } catch (error) {
                    console.error('Error processing wallet:', error);
                    return {
                        ...item,
                        nwc_url: item.nwc_url,
                        balance: 'Error',
                        wallet_logo_url: 'default-logo-url.jpg' // Replace with your default logo URL
                    };
                }
            });

            const processedWallets = await Promise.all(walletPromises);

            const totalInMilliSatoshis = processedWallets.reduce((acc, wallet) => acc + parseFloat(wallet.balance), 0);
            const totalInSatoshis = totalInMilliSatoshis / 1000;
            // Correctly convert satoshis to Bitcoin
            const totalInBitcoin = totalInSatoshis / 100000000;
            setTotalBalance(totalInSatoshis);


            // Update wallet count (you might want only wallets with a balance > 0, adjust accordingly)
            setWalletCount(processedWallets.length);

            return processedWallets;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }

    // Helper function to fetch wallet balance
    async function getWalletBalance(nwcUrl) {
        const nwc = new webln.NostrWebLNProvider({
            nostrWalletConnectUrl: nwcUrl,
        });
        await nwc.enable();
        const balanceResponse = await nwc.getBalance();
        nwc.close();
        return balanceResponse;
    }


    const getWalletLogoUrlFromNWCString = async (nwcString) => {
        const walletsAvailable = [
            {
                name: "alby",
                logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/AlbyWalletLogo.png",
            },
            {
                name: "mutiny",
                logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/MutinyWalletLogo.jpeg",
            },
        ];

        const lowerCaseNWCString = nwcString.toLowerCase();

        for (let i = 0; i < walletsAvailable.length; i++) {
            if (lowerCaseNWCString.includes(walletsAvailable[i].name)) {
                return walletsAvailable[i].logoURL;
            }
        }

        // Return "Custom" or a default image if no match is found
        return "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png"; // Replace with your default wallet logo URL
    };

    return (
        <details className="dropdown dropdown-hover">
            <summary className="btn btn-primary hover:bg-slate-100 animate-none hover:border-slate-200 bg-white text-slate-800 py-1.5 border-slate-200 px-3  min-h-0 h-auto text-sm font-medium">
                <svg className="h-1.5 w-1.5 fill-green-500" viewBox="0 0 6 6" aria-hidden="true">
                <circle cx="3" cy="3" r="2.5" />
                </svg> {wallets.length} Wallets Connected</summary>
            <ul className="p-1.5 shadow menu  dropdown-content z-[1] bg-base-100 rounded-box w-52 mt-1">
                {wallets.map((wallet, index) => (
                    <div key={index}>
                        <li>
                            <a>{wallet.user_wallet_name} - {new Intl.NumberFormat('en-US').format(Math.round(wallet.balance) / 1000)} sats</a>
                        </li>
                    </div>
                ))}
                <WalletConnectButton2 onWalletConnected={handleWalletConnected} />
            </ul>
        </details>
    )
}

export default WalletDropdown;