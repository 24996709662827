import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaStar, FaUsers, FaHandsHelping } from 'react-icons/fa';

const BitcoinFilmFestUserTable = ({ sheetData, player }) => {
    const navigate = useNavigate();
    const playerRowRef = useRef(null);
    const tableContainerRef = useRef(null);

    const sortedData = (Array.isArray(sheetData) ? sheetData : []).sort((a, b) => b.total_points - a.total_points).slice(1);

    const getTicketTypeDetails = (ticketType) => {
        switch (ticketType) {
            case 'Pleb': return { color: 'bg-blue-500', icon: <FaUser /> };
            case 'SuperPleb': return { color: 'bg-green-500', icon: <FaStar /> };
            case 'Team': return { color: 'bg-red-500', icon: <FaUsers /> };
            case 'Volunteer': return { color: 'bg-yellow-500', icon: <FaHandsHelping /> };
            default: return { color: 'bg-gray-500', icon: <FaUser /> };
        }
    };

    const getPositionOrMedal = (index) => {
        switch (index) {
            case 0: return '🥇';
            case 1: return '🥈';
            case 2: return '🥉';
            default: return `#${index + 1}`;
        }
    };

    useEffect(() => {
        if (playerRowRef.current && tableContainerRef.current) {
            tableContainerRef.current.scrollTop = playerRowRef.current.offsetTop - tableContainerRef.current.offsetTop - (tableContainerRef.current.clientHeight / 2) + (playerRowRef.current.clientHeight / 2) + 750;
        }
    }, [sortedData, player]);

    return (
        <div className="mt-6 rounded-lg overflow-hidden shadow-sm text-gray-200" ref={tableContainerRef} style={{ height: '400px', overflowY: 'auto' }}>
            <div className="overflow-x-auto">
                <h2 className='text-5xl text-blue-300 ml-6 font-bold'>Player points</h2>
                <table className="table w-full">
                    <thead className='bg-gray-800 text-gray-200' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <tr>
                            <th>Image</th>
                            <th>Position</th>
                            <th>First Name</th>
                            <th>Team</th>
                            <th>Points</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item, index) => {
                            const { color, icon } = getTicketTypeDetails(item.ticketType);
                            const isPlayerRow = item.email === player;
                            return (
                                <tr key={index} ref={isPlayerRow ? playerRowRef : null} className={isPlayerRow ? 'bg-gray-900' : ''}>
                                    <td><img src="https://pbs.twimg.com/profile_images/1725239923903451136/JLLqXuqG_400x400.jpg" alt="Entry" className="w-10 h-10 rounded-full" /></td>
                                    <td className='text-xl'>{getPositionOrMedal(Number(index))}</td>
                                    <td>{item.firstName}</td>
                                    <td>{item.team}</td>
                                    <td>{item.total_points}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BitcoinFilmFestUserTable;
