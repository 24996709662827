import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { cardConfigs } from './CardsList'; // Adjust this path to where your cardConfig file is located

// Helper function for classNames
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Card Component
const Card = ({ action, onClick }) => (
  <div
    className={classNames(
      'group relative bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 rounded-lg',
      'cursor-pointer hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 overflow-hidden flex justify-center items-center',
      'h-full' // Ensure the card has a full height for vertical centering
    )}
    onClick={() => onClick(action.id)}
    style={{ width: 'auto', position: 'relative' }} // Adjusted to fit the image width and position relative for absolute positioning of children
  >
    <span className="inline-flex rounded-xl ring-4 ring-white">
      <img src={action.image_url} style={{ maxWidth: '100%', maxHeight: '100%', display: 'block' }} alt={action.title} />
    </span>

  </div>
);


// Use a custom hook to listen to window resize events and determine the screen size
function useResponsiveGrid() {
  const [gridCols, setGridCols] = useState(() => {
    // Default grid columns based on initial window width
    if (typeof window !== 'undefined') {
      if (window.innerWidth >= 1024) return 8; // lg
      if (window.innerWidth >= 768) return 6; // md
      return 4; // sm
    }
    return 8; // Default to 8 columns if window is not available (SSR)
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) setGridCols(8); // lg
      else if (window.innerWidth >= 768) setGridCols(6); // md
      else setGridCols(4); // sm
    };

    window.addEventListener('resize', handleResize);
    // Set initial value
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return gridCols;
}




// Cards Component
export const Cards = ({ actions, title }) => {
  const gridCols = useResponsiveGrid();
  const [open, setOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
 
    const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${gridCols || 8}, minmax(0, 1fr))`,
    gap: '1rem', // Adjust the gap as needed
  };


  const openModal = (actionId) => {
    const action = actions.find(a => a.id === actionId);
    if (action) {
      setSelectedAction(action);
      setOpen(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  return (
    <Fragment>
      <div className="mb-8">
        <h4 className="text-sm font-medium mb-4 mt-4 text-indigo-900 opacity-80">{title}</h4>
        <div style={gridStyle}>
          {actions.map((action) => (
            <Card key={action.id} action={action} onClick={openModal} />
          ))}
        </div>
      </div>
          
      {/* Modal Logic */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 modal-base modal-pop">
                  {selectedAction?.formComponent ? React.createElement(selectedAction.formComponent, { close: closeModal }) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

// The page where you use the Cards component
export function CardsIntegrationsPage({actions, title}) {
  return (
    <div>
      <Cards
        actions={actions}
        title={title}
      />
    </div>
  );
}
