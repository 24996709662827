import React, { useState, useEffect } from 'react';
import { getPublicKey, generatePrivateKey } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19, SimplePool, matchFilter, Kind } from 'nostr-tools';

const { npubEncode, nsecEncode, decode } = nip19;

const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};


const convertNostrToHex = (nostr) => {
  const { type, data } = decode(nostr);
  
  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid nostr. Nostr must be "npub" or "nsec".');
  }
};



//nsec1fntm3dcma2mjymywu8jar3t0629n5ukkrhslf4xnqw3r44dd6raqv0nfz5
function SignUpWithNostr(props) {
  // Access props using props.propertyName
  const { appName, onLoginSuccess } = props;

  const [privateKey, setPrivateKey] = useState('');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for handling error messages

  const navigate = useNavigate();


  const handleCreatePrivateKey = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      const newPrivateKey = generatePrivateKey();
      const publicKey = getPublicKey(newPrivateKey);
      const nsec = convertHexToNostr(newPrivateKey, 'nsec');
      const npub = convertHexToNostr(newPrivateKey, 'npub');

      // Store keys in session storage
      localStorage.setItem('userHexPrivateKey', newPrivateKey);
      localStorage.setItem('userHexPublicKey', publicKey);
      localStorage.setItem('userNsec', nsec);
      localStorage.setItem('userNpub', npub);

      setPrivateKey(newPrivateKey);

      // Redirect to the dashboard page
      navigate('/dashboard');

      setLoading(false);
    }, 1000);
  };

  const togglePrivateKeyVisibility = (e) => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };


  return (
     <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="flex shrink-0 items-center justify-center logo-wrap">
          <img
            className="w-48"
            src="/FLASH (9).png"
            alt="Flash logo"
          />
        </div>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
          Sign in with Nostr
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <p className='mb-4'>Nostr gives more privacy, but comes with responsibility: we cannot recover your account. Save your keys in a secure location.</p>
        <a href="#" onClick={handleCreatePrivateKey} className="font-semibold leading-6 text-purple-500 hover:text-purple-400">
            Create new Nostr keys!
        </a>
      </div>
    </>
  );
  
}

export default SignUpWithNostr;
