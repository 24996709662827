import React, { useState, useEffect } from 'react';

async function sendEmailVerification(recipientEmail, code) {
    const apiUrl = 'https://api.paywithflash.com/api/send_subscription_email_verification_code';
    const requestData = { code, recipient_email: recipientEmail };

    try {
        if(recipientEmail) {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(requestData),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
            }
            const responseData = await response.json();
            console.log('Email sent successfully:', responseData);
        }
    } catch (error) {
        console.error('Error sending purchase confirmation email:', error.message);
        throw error;
    }
}

const EmailCodeVerification = ({
    email,
    emailVerified,
    setEmailVerified,
    errorMessageEmail,
    setErrorMessageEmail,
    initialResendCountDown = 30, // 30 seconds for resend
    initialExpiryTime = 600 // 600 seconds (10 minutes) for expiry
}) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [storedCode, setStoredCode] = useState('');
    const [expiresAt, setExpiresAt] = useState(null);
    const [resendCountDown, setResendCountDown] = useState(initialResendCountDown);
    const [allowResend, setAllowResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const generateAndSendCode = () => {
        const newCode = Math.floor(1000 + Math.random() * 9000).toString();
        setStoredCode(newCode);
        setExpiresAt(new Date(new Date().getTime() + initialExpiryTime * 1000)); // Set expiry time
        sendEmailVerification(email, newCode).then(() => {
            setResendCountDown(initialResendCountDown);
            setAllowResend(false);
        }).catch((error) => {
            setErrorMessageEmail(error.message);
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (resendCountDown > 0) {
                setResendCountDown(resendCountDown - 1);
            } else {
                setAllowResend(true);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [resendCountDown]);

    useEffect(() => {
        generateAndSendCode(); // Send code on component mount
        return () => {
            // Optional: Cleanup if needed when component unmounts
        };
    }, []);

    const handleVerificationCodeChange = (event) => {
        setVerificationCode(event.target.value);
    };

    const confirmCode = () => {
        if (new Date() > expiresAt) {
            setErrorMessage("Verification code has expired. Please request a new code.");
        } else if (verificationCode === storedCode) {
            setEmailVerified(true);
            console.log("Code confirmed successfully");
        } else {
            setErrorMessage("Incorrect verification code");
        }
    };

    const handleResendClick = () => {
        if (allowResend) generateAndSendCode();
    };

    return (
        <>
            {!emailVerified && !errorMessageEmail && (
                <>
                <div className="flex items-center space-x-4">
                    <p className='text-gray-600 text-xs'>We've sent an email with a 4-digit code to the email address above, valid for 10 min. Input the code below to confirm ownership</p>
                </div>

                <div className="flex items-center space-x-4">
                    <input
                        type="text"
                        placeholder="Enter verification code"
                        value={verificationCode}
                        onChange={handleVerificationCodeChange}
                        className="text-sm rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 flex-grow"
                    />
                    <button
                        type="button"
                        onClick={confirmCode}
                        className="bg-green-500 hover:bg-green-700 text-sm text-white font-bold py-1 px-4 rounded"
                        disabled={!verificationCode}
                    >
                        Confirm Code
                    </button>
                    {allowResend ? (
                        <button
                            type="button"
                            onClick={handleResendClick}
                            className="bg-blue-500 hover:bg-blue-700 text-sm text-white font-bold py-1 px-4 rounded"
                        >
                            Resend
                        </button>
                    ) : (
                        <span className="text-gray-600 text-xs">Resend code in {resendCountDown} s</span>
                    )}
                </div>
                {errorMessageEmail && (
                <div className="text-red-500 text-xs mb-2">{errorMessageEmail}</div>
            )}
            {errorMessage && (
                <div className="text-red-500 text-xs mb-2">{errorMessage}</div>
            )}
            </>
            )}
        </>
    );
};

export default EmailCodeVerification;
