import React, { useState } from 'react';
import { PlusIcon, LockClosedIcon, GlobeAltIcon, UserIcon, ArrowLongLeftIcon } from '@heroicons/react/24/outline';

import SidebarLayout from '../components/SidebarLayout';
import UploadVectorstoreFromUserNests from '../components/UploadVectorstoreFromUserNests';
import GetUserPublicVectorstores from '../components/GetUserPublicVectorstores';
import GetUserPrivateVectorstores from '../components/GetUserPrivateVectorstores';
import GetUserAllVectorstores from '../components/GetUserAllVectorstores';

function MyVectorEggs() {
  const [privacyStatus, setPrivacyStatus] = useState('Public');
  const [activeSection, setActiveSection] = useState('view'); // Default to 'view'

  const handleUploadCancel = (newStatus) => {
    setActiveSection(newStatus)
  }

  function handlePrivacyToggle(status) {
    setPrivacyStatus(status);
  }

  return (
    <div className="min-h-screen">
      <SidebarLayout title="My Vector Databases" subtitle="View your NostrEggs vector database library here">
        <div className="flex h-full items-stretch">
  
          {/* Right Column for Content */}
          <div className="p-4">
            {activeSection === 'create' && (
            <section className="mb-4">
              <div className="mx-auto w-full rounded-md mb-2 flex justify-between"> {/* Add flex container */}
                <button
                  onClick={() => setActiveSection('view')}
                  className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Back
                </button>
                {/* Leave this space empty for symmetry */}
                <div></div>
              </div>
              
              <div className="mx-auto w-full bg-white rounded-md p-4">
                <h1 className="text-base font-medium text-gray-900">Create a Vector Database</h1>
                <UploadVectorstoreFromUserNests 
                  on_upload_cancel={handleUploadCancel}
                />
              </div>
            </section>
          )}
  
          {activeSection === 'view' && (
          <section className="mb-4">
            <div className="mx-auto w-full rounded-md mb-2">
              <div className="flex justify-between items-center mb-4">
                <div className="flex flex-grow">
                  {/* Private Button */}
                    <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Private' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Private' ? 'text-white' : 'text-gray-600'} shadow-sm hover:bg-purple-900 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/3`}
                        onClick={() => handlePrivacyToggle('Private')}
                    >
                        <LockClosedIcon className="h-5 w-5 inline mr-2" />
                        Private
                    </button>

                    {/* Public Button */}
                    <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Public' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Public' ? 'text-white' : 'text-gray-600'} shadow-sm hover:bg-purple-900 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/3`}
                        onClick={() => handlePrivacyToggle('Public')}
                    >
                        <GlobeAltIcon className="h-5 w-5 inline mr-2" />
                        Public
                    </button>

                    {/* Personal Button */}
                    <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Personal' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Personal' ? 'text-white' : 'text-gray-600'} shadow-sm hover:bg-purple-900 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-1/3`}
                        onClick={() => handlePrivacyToggle('Personal')}
                    >
                        <UserIcon className="h-5 w-5 inline mr-2" />
                        All
                    </button>
                    </div>
                  </div>
                  <div className="text-right mb-2">
                    <button
                      onClick={() => setActiveSection('create')}
                      className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Create
                    </button>
                  </div>
                </div>
                
                <div className="mx-auto w-full bg-white rounded-md p-4">
                  {privacyStatus === 'Private' && <GetUserPrivateVectorstores />}
                  {privacyStatus === 'Public' && <GetUserPublicVectorstores />}
                  {privacyStatus === 'Personal' && <GetUserAllVectorstores />}
                </div>
              </section>
            )}
        </div>
      </div>
    </SidebarLayout>
  </div>
);


}


export default MyVectorEggs;
