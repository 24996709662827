import React, { useState, useEffect } from 'react';
import { nip04 } from 'nostr-tools';
import { TextInput, FormHeader, PaymentInfo, CancelButton, ConfirmButton, SimpleDropdown, WalletDropdown, CurrencyDropdown, LifetimeDurationDropdown, IconDropdown, NumberInputWithConverter, SubscriptionSelectionDropdown } from '../comp/InputsLib.js';
import * as Utils from '../utils.js';
import {
  SummaryTitle, SummaryPrice, SummaryWallet, SummaryDuration, SummaryUser, SummarySnippet, SummaryCreationDate, SummaryCopyButton, SummaryFooter
} from '../comp/SummaryFields.js';
import generateSubscriptionPaywallSnippet from '../generateSnippets/generateSubscriptionPaywallSnippet.js';

const PaywallForm = ({ close }) => {
  // Get env var
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY
  const ourNostrWalletConnectUrl = process.env.REACT_APP_NOSTR_WALLET_CONNECT_URL

  // Get the values from session storage
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  let userAbout = localStorage.getItem('userAbout') || "";

  const [title, setTitle] = useState('');
  const [subscription, setSubscription] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('Satoshis');
  const [duration, setDuration] = useState('1 day');
  const [expiryDate, setExpiryDate] = useState();
  const [wallet, setWallet] = useState('Alby');
  const user = userName;
  const [convertedValue, setConvertedValue] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  // Add state for wallets
  const [wallets, setWallets] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const [selectedWalletLnAddress, setSelectedWalletLnAddress] = useState('');
  const [selectedWalletNwc, setSelectedWalletNwc] = useState('');
  const [flashMemo, setFlashMemo] = useState('');
  const [snippet, setSnippet] = useState('');


  // Effect hook to get the current date in right format for display
  function formatDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
    }

    return `${month} ${day}${suffix(day)} ${year}`;
  }
  useEffect(() => {
    const currentDate = new Date();
    setFormattedDate(formatDate(currentDate));
  }, []);

  // Effect hook to directly set the exchange rate of sats to usd
  useEffect(() => {
    let isMounted = true;

    const fetchConvertedValue = async () => {
      const value = await Utils.convertCurrency(price, currency);
      if (isMounted) {
        setConvertedValue(value);
      }
    };

    fetchConvertedValue();

    return () => {
      isMounted = false;
    };
  },);



  // Effect hook to set the expiryDate of the Flash when the user changes the duration
  useEffect(() => {
    if (duration === 'lifetime') {
      // Option 1: Set a far future date for 'lifetime'
      const farFuture = new Date();
      farFuture.setFullYear(farFuture.getFullYear() + 100); // 100 years in the future
      setExpiryDate(farFuture);
  
      // Option 2: Handle 'lifetime' differently
      // setExpiryDate(null); // If your application logic can handle null as 'lifetime'
    } else {
      // Calculate the expiry date based on the selected duration
      const durationInDays = parseInt(duration.split(' ')[0]); // This will parse the numeric part from the duration string
      const newExpiryDate = new Date();
      newExpiryDate.setDate(newExpiryDate.getDate() + durationInDays); // Add the duration to the current date
      setExpiryDate(newExpiryDate); // Set the new date as the expiry date
    }
  }, [duration]); // This effect runs whenever the duration state changes


  // Effect hook to fetch wallets when the component mounts
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userHexPublicKey)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        const results = [];
  
        for (const item of data) {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
            decrypted_nwc_url = await nip04.decrypt(userHexPrivateKey, publicKey, item.nwc_url);
          } else {
            decrypted_nwc_url = ""
          }
          
          results.push({
            id: item.id,
            user_publicKey: item.user_public_key,
            nwc_url: decrypted_nwc_url,
            wallet_name: item.wallet_name,
            user_wallet_name: item.user_wallet_name,
            ln_address: item.ln_address
          });
        }
        setWallets(results);
  
        // Set the first wallet as the default wallet and its ID
        if (results.length > 0) {
          setWallet(results[0].user_wallet_name);
          setSelectedWalletId(results[0].id); // Set the ID of the first NWC
          setSelectedWalletNwc(results[0].nwc_url);
        }
      } catch (error) {
        console.error('Error fetching wallets:', error);
      }
    };
  
    fetchWallets();
  }, [userHexPrivateKey, userHexPublicKey, publicKey]); // Add dependencies if needed


  const handleWalletChange = (e) => {
    const selectedWalletId = e.target.value;
    const selectedWallet = wallets.find(wallet => wallet.id.toString() === selectedWalletId);
  
    if (selectedWallet) {
      setWallet(selectedWallet.user_wallet_name); // Sets the wallet name
      setSelectedWalletId(selectedWallet.id); // Sets the wallet ID
      setSelectedWalletLnAddress(selectedWallet.ln_address); // Sets the LN address
      setSelectedWalletNwc(selectedWallet.nwc_url);
    }
  };

  const handleConfirm = async () => {
    // Logic for what happens when 'Confirm' is clicked
    event.preventDefault();
    try {
      // Prepare the data to be sent to the server for creating a new flash
      const formData = new FormData();
      formData.append('flash_name', title);
      formData.append('wallet_id', selectedWalletId);
      formData.append('user_public_key', userHexPublicKey);
      formData.append('flash_script', ''); // Initially empty
      formData.append('flash_creation_date', new Date().toISOString());
      formData.append('flash_expiry_date', expiryDate.toISOString()); // Directly using toISOString
      formData.append('flash_price', price);
      formData.append('flash_currency', currency);
      formData.append('flash_type', 'Subscription Paywall');
  
      // Make an API call to save the flash to the database
      let response = await fetch('https://api.paywithflash.com/api/save_flash_to_db', {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const saveResult = await response.json();
      const flashId = saveResult.flash_id;
      console.log('Flash saved:', saveResult);
  
      // Generate the paywall snippet with the new flash_id
      setFlashMemo(`Flash payment for ${title}.`);
      const generatedSnippet = await generateSubscriptionPaywallSnippet(price, userHexPublicKey, userHexPrivateKey, flashMemo, selectedWalletNwc, currency, expiryDate, flashId, selectedWalletLnAddress, subscription);
  
      // Prepare data to update the flash with the generated snippet
      const updateData = new FormData();
      updateData.append('flash_id', flashId);
      updateData.append('flash_name', title);
      updateData.append('wallet_id', selectedWalletId);
      updateData.append('user_public_key', userHexPublicKey);
      updateData.append('flash_script', generatedSnippet);
      updateData.append('flash_creation_date', new Date().toISOString());
      updateData.append('flash_expiry_date', expiryDate.toISOString()); // Again using toISOString
      updateData.append('flash_price', price);
      updateData.append('flash_currency', currency);
      updateData.append('flash_type', 'Subscription Paywall');
  
      // Make an API call to update the flash in the database
      response = await fetch('https://api.paywithflash.com/api/update_flash_in_db', {
        method: 'POST',
        body: updateData,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok during update');
      }
  
      const updateResult = await response.json();
      console.log('Flash updated with snippet:', updateResult);
  
      // Set the snippet field
      setSnippet(generatedSnippet);
  
    } catch (error) {
      console.error("Error during flash creation, update, and snippet generation:", error);
    }
  };
  
  const handleCopy = async (snippetText) => {
    // Logic for what happens when 'Copy' is clicked
    try {
      await navigator.clipboard.writeText(snippetText);
    } catch (error) {
      console.error('Failed to copy snippet:', error);
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };


  return (
    <div className="flex flex-col md:flex-row md:space-x-4 modal-base h-screen">
      <div className="flex-1 p-14 overflow-auto">

        <FormHeader
          title="Create a Paywall"
          subtitle="Add a simple paywall to any page to receive payments in Bitcoin through Lightning anywhere, with a simple snippet. Add it on any page, pause or delete anytime."
          infoBadge={{
            text: "Learn more about Paywalls",
            color: "bg-gray-100 text-gray-600"
            }}
          newEggBadge={{
            text: "New Flash",
            color: "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-600/20 mb-3"
            }}
        />

        <div className="inputform">
          <TextInput label="Title" placeholder="Enter the title" value={title} onChange={handleTitleChange} />
        </div>

        <div className="inputform">
          <SubscriptionSelectionDropdown label="Select Subscription" id="flash-subscription" name="flash-subscription" value={subscription.label} userPublicKey={userHexPublicKey} onChange={(event) => setSubscription(event.target.value)} />
        </div>
        
        <div className="inputform">
          <NumberInputWithConverter label="Price" name="price" id="price" placeholder="Enter the price" value={price} onChange={(e) => setPrice(e.target.value)} convertedValue={convertedValue} />
        </div>

        <div className="inputform">
          <CurrencyDropdown label="Currency" id="currency" name="currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
        </div>

        <div className="inputform">
          <WalletDropdown label="Wallet" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
        </div>

        <div className="inputform">
          <LifetimeDurationDropdown label="Flash Duration" id="flash-duration" name="flash-duration" value={duration} onChange={(e) => setDuration(e.target.value)} />
        </div>

        <div className="inputform">
          <ConfirmButton onClick={handleConfirm}> Create paywall </ConfirmButton>
          <CancelButton onClose={close} />
        </div>
        
        <div className="inputform">
          <PaymentInfo text="Payments will go directly to your connected wallet, through Flash." />
        </div>

      </div>
      <div className="flex-1 bg-gray-100">
        {/* You can add content here if needed */}
        
        {/* Summary Column */}
        <div className="flex-1 bg-gray-100 p-14">
            <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-8">
                <SummaryTitle title={title || 'Flash Title'} />
                <SummaryCreationDate creationDate={formattedDate} />
                <SummaryPrice price={price} currency={currency} convertedValue={convertedValue} />
                <SummaryWallet wallet={wallet} />
                <SummaryDuration duration={duration} />
                <SummaryUser user={user} />
                <SummarySnippet snippet={snippet} /> {/* Remove if not needed */}
                <SummaryCopyButton text="Copy snippet" onClick={() => handleCopy(snippet)} />
                <SummaryFooter />
            </div>
        </div>
      </div>
    </div>
  );
};

export default PaywallForm;
