import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [countdown, setCountdown] = useState(null); // Initialize countdown to null

    useEffect(() => {
        let timeoutId;
        if (countdown > 0) {
            timeoutId = setTimeout(() => setCountdown(countdown - 1), 1000);
        }
        return () => clearTimeout(timeoutId);
    }, [countdown]);

    const requestPasswordReset = async () => {
        // Call the API endpoint
        const response = await fetch('https://api.paywithflash.com/api/request-password-reset', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email })
        });

        if (response.ok) {
            setMessage('We\'ve sent you an email with password reset instructions. Please check your inbox.');
            setCountdown(30); // Start the countdown
        } else {
            setMessage('There was an error processing your request. Please try again later.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        await requestPasswordReset();
    };

    const handleResendEmail = async () => {
        await requestPasswordReset();
    };

    return (
       

      <>
        <div className="flex h-screen" style={{backgroundImage: "url('nimages/bg-flash.png')", backgroundPosition: 'top', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
    {/* Left Column for Login Form */}
    <div className="flex flex-col justify-center px-8 md:px-36 text-white w-full align-middle items-center " >
        <div className="w-full md:w-2/3 lg:w-2/3 xl:w-1/3  border-slate-200 rounded-md p-7 pb-7 shadow-lg bg-white">
          <img className="w-40 mb-3 -ml-1 -mt-0.5" src="/nimages/logo-org.png"></img>
        
       
        <h2 className="text-left text-slate-900 text-2xl font-bold leading-9 tracking-tight">
          Reset Your Password
        </h2>
      
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-900">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="satoshi@nakamoto.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full rounded-md  bg-white/5 py-1.5 text-slate-900  border border-slate-200 ring-1 ring-inset ring-white/10  sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-700 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Send Password Reset Email
            </button>
          </div>
        </form>
        <div className="text-center text-sm text-purple-500">
            {message}
            {countdown !== null && countdown > 0 && ` ${countdown} seconds left before you can
            request another email.`}
            {countdown === 0 && (
                <button onClick={handleResendEmail} className="underline cursor-pointer">
                    {' '}Request another email
                </button>
            )}
        </div>
              </div>
              </div>
            </div>
          </div>
           
      </>
    );
};

export default PasswordResetRequest;
