import React, { useState, useEffect } from 'react';

// Example React component that fetches and displays transactions
function RecentTransactions() {
    const [transactions, setTransactions] = useState([]);
    const [displayedTransactions, setDisplayedTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentEndIndex, setCurrentEndIndex] = useState(5);

    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";

const mockTransactions = [
  {
    id: 1,
    transaction_direction: 'incoming',
    flash_type: 'Flash Type 1',
    transaction_date: '2022-01-01T00:00:00Z',
    amount_in_usd: '10',
    amount_in_sats: 1000,
  },
  {
    id: 2,
    transaction_direction: 'outgoing',
    flash_type: 'Flash Type 2',
    transaction_date: '2022-01-02T00:00:00Z',
    amount_in_usd: '20',
    amount_in_sats: 2000,
  },
  {
    id: 3,
    transaction_direction: 'incoming',
    flash_type: 'Flash Type 3',
    transaction_date: '2022-01-03T00:00:00Z',
    amount_in_usd: '30',
    amount_in_sats: 3000,
  },
  // Add more transactions as needed
];

useEffect(() => {
  setTransactions(mockTransactions);
  setDisplayedTransactions(mockTransactions.slice(0, 5)); // Initially display the first 5 transactions
}, []);

    // Function to fetch transactions
    const fetchTransactions = async (userPublicKey) => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_all_new_transactions_by_user?user_public_key=${encodeURIComponent(userPublicKey)}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setTransactions(data.transactions);
            setDisplayedTransactions(data.transactions.slice(0, 5)); // Initially display the first 5 transactions

        } catch (error) {
            console.error("Error fetching transactions:", error);
            setError(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    // useEffect hook to fetch transactions when the component mounts or userPublicKey changes
    useEffect(() => {
        fetchTransactions(userHexPublicKey);
    }, [userHexPublicKey]);



    // Function to handle loading more transactions
    const loadMoreTransactions = () => {
        const nextEndIndex = currentEndIndex + 5;
        const moreTransactions = transactions.slice(currentEndIndex, nextEndIndex);
        setDisplayedTransactions([...displayedTransactions, ...moreTransactions]);
        setCurrentEndIndex(nextEndIndex);
    };


    function formatDate(dateString) {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('default', options).format(date);
    }
    

    return (
    <div className="flex flex-col items-start">
        {isLoading && <p>Loading transactions...</p>}
        {error && <p>Error fetching transactions: {error}</p>}
            <h2 className="text-md font-semibold text-slate-900 mb-0">Recent Transactions</h2>
            <p className='text-slate-500 text-sm font-regular mb-4'>From any of your gateway</p>
        <ul role="list" className="divide-y divide-gray-100 w-full max-w-xl">
            {displayedTransactions.map((transaction) => (
                <li key={transaction.id} className="flex justify-left gap-x-4 py-2 items-center ">
                    {transaction.transaction_direction === 'incoming' ? (
                        <svg className="h-5 w-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    ) : (
                        <svg className="h-5 w-5 text-slate-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                    )}
                    <div className="min-w-0 flex-1">
                        <p className="text-sm font-semibold leading-6 text-slate-900">
                            {transaction.flash_type}
                        </p>
                        <p className="mt-0 truncate text-xs leading-5 text-slate-500">{formatDate(transaction.transaction_date)}</p>
                    </div>
                    <div className="min-w-0 flex-1 text-right">
                        <p className="text-sm font-semibold leading-6 text-slate-900">
                            ${transaction.amount_in_usd} USD
                        </p>
                        <p className="text-sm font-regular leading-6 text-slate-500">
                            {transaction.amount_in_sats} sats
                        </p>
                    </div>
                </li>
            ))}
        </ul>
        {currentEndIndex < transactions.length && (
            <button onClick={loadMoreTransactions} aria-label="Load more" className="mt-4">
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
            </button>
        )}
    </div>
);

}

export default RecentTransactions;
