import React, { useState, useEffect } from 'react';
import { nip04, nip19, SimplePool, finishEvent } from 'nostr-tools';

const { decode } = nip19;


const convertNostrToHex = async (npub) => {
    const { type, data } = decode(npub);
  
    if (type === 'npub' || type === 'nsec') {
      return data;
    } else {
      throw new Error('Invalid Nostr key. Expected an "npub" key.');
    }
  };


async function sendNostrVerificationMessage(recipientNpub, code) {
    const relayUrls = [
      "wss://relay.paywithflash.com",
      "wss://relay.damus.io",
      "wss://relay.primal.net",
      "wss://relay.snort.social",
      "wss://relay.nostr.band"
    ];
    const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

    try {
        const recipientHexPublicKey = await convertNostrToHex(recipientNpub);

        const message = `
          Hi there,
          Enter this code in the payment interface to confirm you own the npub for payment: ${code}
          Please note: We are the payment gateway and do not handle shipping or fulfillment. Should you have any inquiries regarding your order, please reach out to the vendor directly.
        `;
      
        const ciphertext = await nip04.encrypt(flashPrivateKey, recipientHexPublicKey, message);
      
        let event = {
          kind: 4,
          pubkey: flashPublicKey,
          tags: [['p', recipientHexPublicKey]],
          content: ciphertext,
          created_at: Math.floor(Date.now() / 1000)
        };
      
        event = finishEvent(event, flashPrivateKey);
      
        const pool = new SimplePool();
        const publishResults = await Promise.allSettled(pool.publish(relayUrls, event));
      
        publishResults.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            console.log(`DM successfully published to relay at ${relayUrls[index]}.`);
          } else {
            console.error(`An error occurred while publishing to relay at ${relayUrls[index]}:`, result.reason);
          }
        });
    } catch (error) {
        console.error('Failed to send verification message:', error);
        throw new Error("Wrong npub, please check again."); // Custom error message
    }
}






const NpubCodeVerification = ({ npub, npubVerified, setNpubVerified, errorMessageNostrDM, setErrorMessageNostrDM, initialCountDown = 30 }) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [storedCode, setStoredCode] = useState(null);
    const [countDown, setCountDown] = useState(initialCountDown);
    const [allowResend, setAllowResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Generate and send a new code
    const generateAndSendCode = () => { 
        const newCode = Math.floor(1000 + Math.random() * 9000).toString();
        setStoredCode(newCode);
        console.log("new code:", newCode);

        sendNostrVerificationMessage(npub, newCode)
            .then(() => {
                setCountDown(initialCountDown);
                setAllowResend(false);
            })
            .catch((error) => {
                setErrorMessageNostrDM(error.message); // Set the custom error message
            });
    };

    // Effect to handle resend count down
    useEffect(() => {
        if (countDown > 0) {
            const timer = setTimeout(() => {
                setCountDown(countDown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setAllowResend(true);
        }
    }, [countDown]);

    // Automatically send a code on mount
    useEffect(() => {
        generateAndSendCode();
    }, []);

    const handleVerificationCodeChange = (event) => {
        setVerificationCode(event.target.value);
    };

    const confirmCode = () => {
        if (verificationCode === storedCode) {
            console.log("Code confirmed successfully");
            setNpubVerified(true);
        } else {
            console.log("Incorrect verification code");
            setErrorMessage("Incorrect verification code");
        }
    };

    const handleResendClick = () => {
        generateAndSendCode();
    };

    return (
        <>
        {errorMessageNostrDM && (
            <div className="text-red-500 text-xs mb-2">{errorMessageNostrDM}</div>
        )}
        {errorMessage && (
            <div className="text-red-500 text-xs mb-2">{errorMessage}</div>
        )}
        {!npubVerified && !errorMessageNostrDM && (
            <>
                <div className="flex items-center space-x-4">
                    <p className='text-gray-600 text-xs'>We've sent a DM with a 4-digit code to the npub above, valid for 10 min. Input the code below to confirm ownership</p>
                </div>

                <div className="flex items-center space-x-4">
                    <input
                        type="text"
                        placeholder="Enter verification code"
                        value={verificationCode}
                        onChange={handleVerificationCodeChange}
                        className="text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 flex-grow"
                    />
                    <button
                        type="button"
                        onClick={confirmCode}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded"
                        disabled={!verificationCode}
                    >
                        Confirm Code
                    </button>
                    {countDown > 0 ? (
                        <span className="text-gray-600 text-xs">Resend code in {countDown} s</span>
                    ) : (
                        <button
                            onClick={handleResendClick}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
                            disabled={!allowResend}
                        >
                            Resend
                        </button>
                    )}
                </div>
            </>
        )}
        </>
    );
};

export default NpubCodeVerification;