import React, { useEffect, useRef } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef(null);

  const handleOverlayClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };

      // Prevent body from scrolling when the modal is open
      document.body.style.overflow = 'hidden';

      document.addEventListener('keydown', handleEscapeKey);

      return () => {
        document.removeEventListener('keydown', handleEscapeKey);

        // Reset the overflow property after the modal closes
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" onClick={handleOverlayClick}>
      <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
      <div ref={modalRef} className="bg-white rounded-lg z-10 p-4 overflow-y-auto max-h-[80vh]">
        {children}
      </div>
    </div>
  );
};

export default Modal;
