import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const DateRangePicker = ({ onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const generateCalendarDays = (date) => {
    let days = [];
    const currentMonth = date.getMonth();
    const year = date.getFullYear();
  
    // Get the first day of the month
    const firstDay = new Date(year, currentMonth, 1);
  
    // Get the last day of the month
    const lastDay = new Date(year, currentMonth + 1, 0);
  
    // Generate days for the current month
    for (let day = firstDay.getDate(); day <= lastDay.getDate(); day++) {
      days.push({
        date: new Date(year, currentMonth, day).toISOString().split('T')[0] // Format: 'YYYY-MM-DD'
      });
    }
  
    return days;
  };

  // Navigate to the previous month
  const goToPreviousMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setCurrentDate(newDate);
  };

  // Navigate to the next month
  const goToNextMonth = () => {
    const newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(newDate);
  };

  const handleDayClick = (day) => {
    // Simple logic for demonstration: select start date first, then end date
    if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
      setSelectedStartDate(day.date);
      setSelectedEndDate(null);
    } else {
      setSelectedEndDate(day.date);
      const startDate = selectedStartDate < day.date ? selectedStartDate : day.date;
      const endDate = selectedStartDate < day.date ? day.date : selectedStartDate;
      onDateChange(selectedStartDate, day.date); // Notify parent component
    }
  };

  // Function to handle reset
  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    onDateChange(null, null); // Notify parent component that the dates have been reset
  };

  // Get month and year for the header
  const month = currentDate.toLocaleString('default', { month: 'long' });
  const year = currentDate.getFullYear();

  const days = generateCalendarDays(currentDate);

  return (
    <div>
      <div className="flex justify-between items-center p-2">
        <ChevronLeftIcon className="h-5 w-5 cursor-pointer" onClick={goToPreviousMonth} />
        <span>{`${month} ${year}`}</span>
        <ChevronRightIcon className="h-5 w-5 cursor-pointer" onClick={goToNextMonth} />
      </div>
      <div className="grid grid-cols-7 gap-1 p-2">
        {/* Render generated days here */}
        {days.map(day => (
          <button
            key={day.date}
            onClick={() => handleDayClick(day)}
            className={`py-1 px-2 rounded-md ${
              selectedStartDate === day.date || selectedEndDate === day.date
                ? 'bg-blue-500 text-white'
                : 'bg-gray-100'
            }`}
          >
            {new Date(day.date).getDate()}
          </button>
        ))}
      </div>
      {/* Reset Button */}
      <div className="">
        <button 
          onClick={handleReset} 
          className="ml-2 mb-2 bg-gray-100 text-black px-4 py-2 rounded-md hover:bg-gray-200"
        >
          Reset Dates
        </button>
      </div>
    </div>
  );
};

export default DateRangePicker;
