import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ expiryDate }) => {
  const calculateTimeLeft = (expiry) => {
    const difference = +new Date(expiry) - +new Date();
    let timeLeft = {};

    // Check if the expiry date is more than two weeks from now
    if (difference > 14 * 24 * 60 * 60 * 1000) { // Two weeks in milliseconds
      return { lifetime: true };
    }

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      timeLeft = {
        days,
        formatted: `${days > 0 ? `${days} days ` : ''}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expiryDate));

  useEffect(() => {
    if (!timeLeft.lifetime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(expiryDate));
      }, 1000);

      return () => clearTimeout(timer);
    }
  });

  const getColorClass = () => {
    if (timeLeft.lifetime) {
      return "text-green-700 bg-green-50 ring-green-600/20"; // Or any other appropriate style for "Lifetime"
    } else if (timeLeft.days >= 2) {
      return "text-green-700 bg-green-50 ring-green-600/20";
    } else if (timeLeft.days >= 1) {
      return "text-orange-700 bg-orange-50 ring-orange-600/20";
    } else {
      return "text-red-700 bg-red-50 ring-red-600/20";
    }
  };

  return (
    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${getColorClass()}`}>
      {timeLeft.lifetime ? "Lifetime" : (timeLeft.formatted || "Expired")}
    </span>
  );
};

export default CountdownTimer;
