import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function DisplayVectorstoreData({ content }) {
    const formattedData = JSON.stringify(content, null, 2); // 2 spaces for indentation



    return (
        <div className="bg-[#3a404d] p-4 rounded-md overflow-x-auto max-h-screen overflow-y-auto border-2 border-purple-900">
        <div className="px-2 pb-6 pt-2 sm:px-2">
            <h3 className="text-base font-semibold leading-7 text-white">Vector Database Contents:</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-white">Explore the contents of this vectors database.</p>
        </div>
          {content.map((entry, index) => (
            <div key={index} className="mb-4">
              <p className="text-purple-500">Vector {index + 1}</p>
              <SyntaxHighlighter
              language="json"
              style={atomOneDark}
              customStyle={{
                padding: '15px',
                borderRadius: '5px',
                fontSize: '12px',
              }}
              >
                {entry.replace(/\u0000/g, ' ')} {/* Replace control characters with space */}
              </SyntaxHighlighter>
            </div>
          ))}
        </div>
    );
    
}

export default DisplayVectorstoreData;
