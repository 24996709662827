import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { nip04, nip19, SimplePool, Kind, getPublicKey, generatePrivateKey } from 'nostr-tools';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';

const { npubEncode, nsecEncode, decode } = nip19;

const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

const convertNostrToHex = (nostr) => {
  const { type, data } = decode(nostr);
  
  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid nostr. Nostr must be "npub" or "nsec".');
  }
};

const encryptUserPrivateKey = async (flashPrivateKey, userPublicKey, userPrivateKey) => {
  const encryptedUserPrivateKey = await nip04.encrypt(flashPrivateKey, userPublicKey, userPrivateKey);
  return encryptedUserPrivateKey;
};

const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();

  const filters = [{
    authors: [publicKey],
    kinds: [Kind.Metadata]
  }];

  try {
    const events = await pool.list(relayUrls, filters);

    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      return metadata;
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', 
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};

function LoginWithNostr({ referralPublicKey }) {

  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const [privateKey, setPrivateKey] = useState('');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleLogin = async () => {
    setErrorMessage('');
  
    if (!privateKey.trim()) {
      setErrorMessage('Please enter a Nostr private key.');
      return;
    }
  
    setLoading(true);
  
    const delayedLogin = async () => {
      let hexPrivateKey;
      let npub;
      let nsec;
  
      try {
        if (privateKey.startsWith('nsec')) {
          hexPrivateKey = convertNostrToHex(privateKey);
          nsec = privateKey;
          npub = convertHexToNostr(hexPrivateKey, 'npub');
        } else {
          hexPrivateKey = privateKey;
          nsec = convertHexToNostr(hexPrivateKey, 'nsec');
          npub = convertHexToNostr(hexPrivateKey, 'npub');
        }
  
        const publicKey = getPublicKey(hexPrivateKey);
  
        const relayUrls = ["wss://relay.paywithflash.com", "wss://relay.damus.io", "wss://relay.primal.net", "wss://relay.snort.social", "wss://relay.nostr.band"];
        const metadata = await fetchUserMetadata(publicKey, relayUrls);
        if (metadata) {
          localStorage.setItem('userName', metadata.name || '');
          localStorage.setItem('userPictureUrl', metadata.picture || '');
          localStorage.setItem('userAbout', metadata.about || '');

          const loginOrigin = window.location.origin; // Capture the origin of the login
          const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPrivateKey, publicKey, hexPrivateKey);

          const response = await fetch('https://api.paywithflash.com/api/login_with_nostr', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({ 
              user_public_key: publicKey, 
              private_key: encryptedUserPrivateKey,
              referral_public_key: referralPublicKey == null ? '' : referralPublicKey, // Pass the referral public key
              login_origin: loginOrigin
            })
          });

          const data = await response.json();
          if (response.ok) {
            localStorage.setItem('userHexPrivateKey', hexPrivateKey);
            localStorage.setItem('userHexPublicKey', publicKey);
            localStorage.setItem('referralId', data.referral_id);
            localStorage.setItem('userNsec', nsec);
            localStorage.setItem('userNpub', npub);
            return true;
          } else {
            setErrorMessage(data.error || 'Login failed');
            return false;
          }
        } else {
          setErrorMessage('Failed to fetch user metadata.');
          return false;
        }
      } catch (error) {
        setErrorMessage("This Nostr private key doesn't exist or an error occurred.");
        return false;
      }
    };
  
    setTimeout(async () => {
      const loginSuccessful = await delayedLogin();
      if (loginSuccessful) {
        navigate('/dashboard');
      }
      setLoading(false);
    }, 4000);
  };
  
  const handleCreatePrivateKey = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      const newPrivateKey = generatePrivateKey();
      const publicKey = getPublicKey(newPrivateKey);
      const nsec = convertHexToNostr(newPrivateKey, 'nsec');
      const npub = convertHexToNostr(newPrivateKey, 'npub');

      localStorage.setItem('userHexPrivateKey', newPrivateKey);
      localStorage.setItem('userHexPublicKey', publicKey);
      localStorage.setItem('userNsec', nsec);
      localStorage.setItem('userNpub', npub);

      setPrivateKey(newPrivateKey);

      navigate('/dashboard');

      setLoading(false);
    }, 1000);
  };

  const togglePrivateKeyVisibility = (e) => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}

      <div className="flex flex-col">
        <div className="flex items-center mb-0">
          <h2 className="text-left text-slate-900 text-xl font-bold leading-9 tracking-tight login-email">
            Log in with Nostr
          </h2>
        </div>
        <p className="text-sm font-light text-slate-700">
          Enter your private key.
        </p>

        <div className="mt-2">
          <form className="space-y-3">
            <div>
              <label htmlFor="privateKey" className="block text-sm font-medium leading-6 text-slate-900">
                Private Key
              </label>
              <div className="mt-1 relative">
                <input
                  id="privateKey"
                  name="privateKey"
                  placeholder='••••••••••••••••••••••••••'
                  type={showPrivateKey ? 'text' : 'password'}
                  required
                  value={privateKey}
                  onChange={(e) => setPrivateKey(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
                />
                <button
                  onClick={togglePrivateKeyVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300 eye-icon"
                >
                  <FaEye />
                </button>
              </div>
              {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
            </div>
            <div>
              <button
                type="button"
                onClick={handleLogin}
                className="flex w-full justify-center rounded-md bg-slate-900 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginWithNostr;
