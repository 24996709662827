import React, { useState, useRef } from 'react';


function RelaysComponent({
    relays,
    setRelays,
    is_owner
}) {
  const [text, setText] = useState('');
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.endsWith(' ')) {
        const regex = /^wss:\/\/([\w-]+\.)?[\w-]+\.\w+$/;
        if (regex.test(value.trim())) {
            setRelays(prev => [...prev, value.trim()]);
            setText('');
            setError(null); // Clear the error message if input is correct
        } else {
            setError('Please enter a valid relay starting with "wss://" and ending with a domain.');
            setText(value.trim()); // Retain the value for the user to correct it
        }
    } else {
        setText(value);
    }
};


  const removeRelay = (index) => {
    setRelays((prev) => {
      const newRelays = [...prev];
      newRelays.splice(index, 1);
      return newRelays;
    });
  };

  const focusInput = () => {
    inputRef.current.focus();
  };

  // Apply the "disabled" styling when is_owner is false
  const containerStyles = is_owner ? 
    "border rounded flex items-center flex-wrap focus-within:outline-none focus-within:ring-transparentfocus-within:ring-4 focus-within:border-purple-800" :
    "border rounded flex items-center flex-wrap focus-within:outline-none focus-within:ring-transparentfocus-within:ring-4 focus-within:border-purple-800 pointer-events-none opacity-50";


  return (
    <div className="mb-4">
        <div 
            onClick={focusInput} 
            className={containerStyles}
        >
            {relays.map((relay, index) => (
                <div key={index} className="m-1 flex items-center bg-purple-800 hover:bg-purple-900 text-white text-sm rounded px-2 py-1">
                    <span className="mr-2">{relay}</span>
                    <button className="text-red-500" onClick={() => removeRelay(index)}>x</button>
                </div>
            ))}
            <input
                ref={inputRef}
                value={text}
                onChange={handleInputChange}
                placeholder={relays.length === 0 ? 'Type and press space...' : ''}
                className="ring-0 border-transparent focus:ring-0 focus:border-transparent flex-1 bg-transparent text-sm"
            />
        </div>
        {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
}

export default RelaysComponent;
