import React, { useState, useEffect, Fragment } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}



function aggregateData(data, interval) {
  const formatOptions = { Day: 'yyyy-MM-dd', Week: 'yyyy-IW', Month: 'yyyy-MM' };
  const dateFormat = formatOptions[interval];

  // Group data by the selected interval
  let groupedData = {}, f = {};

  for (const curr of data) {
      const date = new Date(curr.saleDate);
      let key;
  
      if (interval === 'Day') {
        key = date.toLocaleDateString('en-CA'); // yyyy-MM-dd format
      } else if (interval === 'Week') {
        const firstDayOfWeek = date.getDate() - date.getDay() + 1;
        const weekStartDate = new Date(date.setDate(firstDayOfWeek));
        key = `${weekStartDate.getFullYear()}-W${weekStartDate.getWeek()}`;
      } else if (interval === 'Month') {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`.padEnd(7, '0'); // yyyy-MM format
      }
  
      if (!groupedData[key]) {
        groupedData[key] = { saleCount: 0, totalQuantity: 0 };
      }
      if (!f[curr.saleId]) groupedData[key].saleCount += 1;
      groupedData[key].totalQuantity += Number(curr.quantity); // Assuming each transaction has a 'value_in_usd' field
      f[curr.saleId] = 1;
  };
  
  // Convert grouped data to an array suitable for the chart
  return Object.keys(groupedData).map(key => ({
    date: key,
    saleCount: groupedData[key].saleCount,
    totalQuantity: groupedData[key].totalQuantity
  }));
}

// Helper function to get the week number
Date.prototype.getWeek = function() {
  const firstDayOfYear = new Date(this.getFullYear(), 0, 1);
  const pastDaysOfYear = (this - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};


export default function AreaChartSaleCountsHistory({ data, currency }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [interval, setInterval] = useState('Day'); // State for interval selection

  useEffect(() => {
    const aggregatedData = aggregateData(data, interval);
    const dates = aggregatedData.map(item => item.date);
    const saleCounts = aggregatedData.map(item => item.saleCount);
    const totalQuantity = aggregatedData.map(item => item.totalQuantity);

    setSeries([
      {
        name: "Sales Count",
        data: saleCounts
      },
      {
        name: "Total items sold per day",
        data: totalQuantity
      }
    ]);

    setOptions({
      chart: {
        type: 'area',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: `Sales Count Over Time`,
        align: 'left'
      },
      xaxis: {
        type: 'datetime',
        categories: dates,
        labels: {
          show: false // Hides x-axis labels
        },
        axisBorder: {
          show: false // Hides x-axis line
        },
        axisTicks: {
          show: false // Hides x-axis ticks
        }
      },
      yaxis: [
        {
          labels: {
            show: false // Hides y-axis labels for the first axis
          },
          axisBorder: {
            show: false // Hides y-axis line for the first axis
          },
          axisTicks: {
            show: false // Hides y-axis ticks for the first axis
          }
        },
        {
          opposite: true,
          labels: {
            show: false // Hides y-axis labels for the second axis
          },
          axisBorder: {
            show: false // Hides y-axis line for the second axis
          },
          axisTicks: {
            show: false // Hides y-axis ticks for the second axis
          }
        }
      ],
      grid: {
        show: false // Hides the grid lines
      },
      legend: {
        horizontalAlign: 'right',
        verticalAlign: 'top'
      },
      stroke: {
        curve: 'smooth'
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
        y: {
          formatter: function (value) {
            return value % 1 === 0 ? value.toFixed(0) : value.toFixed(2);
          }
        }
      }
    });
  }, [data, interval]);

  const handleIntervalChange = (newInterval) => {
    setInterval(newInterval);
  };

  const DropdownMenu = () => (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {interval}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {['Day', 'Week', 'Month'].map((i) => (
              <Menu.Item key={i}>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={() => handleIntervalChange(i)}
                  >
                    {i.charAt(0).toUpperCase() + i.slice(1)}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );

  return (
    <div>
      <DropdownMenu />
      <div id="chart" className='mt-1'>
        <ReactApexChart options={options} series={series} type="area" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}