import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductImages from '../components/Products/Products/ProductImages';
import Cart from '../components/Products/ProductGallery/Cart';

const decodeData = (encodedData) => {
    if (!encodedData) return null;
    try {
        return JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(encodedData)))));
    } catch (e) {
        console.error('Failed to decode data:', e);
        return null;
    }
};


const encodeData = (data) => {
    const jsonString = JSON.stringify(data);
    return encodeURIComponent(btoa(unescape(encodeURIComponent(jsonString))));
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ProductGalleryProductPage = () => {
    const query = useQuery();
    const cart = JSON.parse(localStorage.getItem('cart')) || [];

    const encodedProduct = query.get('product');
    const encodedUser = query.get('user');

    const product = decodeData(encodedProduct);
    const user = encodedUser ? atob(decodeURIComponent(encodedUser)) : '';

    const [cartState, setCartState] = useState(cart);
    const [convertedPrice, setConvertedPrice] = useState({});
    const [quantity, setQuantity] = useState(1);

    const navigate = useNavigate();
    const currentUrl = window.location.href;

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cartState));
    }, [cartState]);

    useEffect(() => {
        if (product) {
            const fetchConvertedPrice = async () => {
                const prices = await convertPrices(product.Currency, parseFloat(product.Price));
                setConvertedPrice(prices);
            };

            fetchConvertedPrice();
        }
    }, [product]);

    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
    };

    const addToCart = () => {
        const existingProductIndex = cartState.findIndex((item) => item.ProductID === product.ProductID);
        const productWithPrices = {
            ...product,
            PriceInDollars: convertedPrice.priceInDollars,
            PriceInSatoshis: convertedPrice.priceInSatoshis,
            quantity: quantity
        };

        if (existingProductIndex !== -1) {
            const updatedCart = [...cartState];
            updatedCart[existingProductIndex].quantity += 1;
            setCartState(updatedCart);
        } else {
            const updatedCart = [...cartState, productWithPrices];
            setCartState(updatedCart);
        }
    };

    const handleBuyNow = () => {
        const productWithPrices = {
            ...product,
            PriceInDollars: convertedPrice.priceInDollars,
            PriceInSatoshis: convertedPrice.priceInSatoshis,
            quantity: 1
        };
        const updatedCart = [...cartState, productWithPrices];
        navigate(`/checkout-page/${product.ProductID}`, { state: { cart: updatedCart, user } });
    };

    const handleBackClick = () => {
        const encodedCart = encodeData(cartState);
        const userPublicKey = user.replace(/"/g, ''); // Remove any quotation marks
        navigate(`/product-gallery?user=${userPublicKey}`);
    };

    if (!product) {
        return <div>No product selected</div>;
    }

    return (
        <div className="bg-white min-h-screen flex container mx-auto px-4 lg:px-16">
            <div className="flex-1 mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={handleBackClick}
                    className="rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                    Back to Store
                </button>
                <div>
                    <Cart cart={cartState} user={user} removeFromCart={(index) => setCartState(cartState.filter((_, i) => i !== index))} clearCart={() => setCartState([])} updateCart={setCartState} />
                </div>
            </div>
                <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                    <div className="lg:col-span-4 lg:row-end-1">
                        <div className="relative h-128 bg-gray-100">
                            <ProductImages product={product} />
                        </div>
                    </div>

                    <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                        <div className="flex flex-col-reverse">
                            <div className="mt-4">
                                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.Name}</h1>
                                <h2 id="information-heading" className="sr-only">Product information</h2>
                            </div>
                        </div>

                        <p className="mt-6 text-gray-500">{product.Description}</p>

                        <div className="mt-6 flex flex-1 flex-col justify-end">
                            <p className="text-base font-medium text-gray-900">${convertedPrice.priceInDollars?.toFixed(2)} USD</p>
                            <p className="text-sm text-gray-500">{convertedPrice.priceInSatoshis?.toLocaleString()} sats</p>
                        </div>
                        <div className="flex space-x-2 items-center">
                            <label className="text-sm text-gray-500">Qty:</label>
                            <input
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                                min="1"
                                className="w-16 px-2 py-1 border rounded-md text-sm"
                            />
                        </div>

                        <div className='mt-2'>
                            { product.is_digital_product && (
                                <>
                                    <span className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">
                                        Digital Product
                                    </span>
                                    <p className="text-xs w-3/4 mt-1 text-gray-500">* A download link for your digital products will be sent to you upon completion of your purchase.</p>
                                </>

                            )}
                            { !product.is_digital_product && (
                                <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                                    Physical Product
                                </span>
                            )}
                        </div>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                            <button
                                type="button"
                                className="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-800 px-8 py-3 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                                onClick={addToCart}
                            >
                                Add to Cart
                            </button>
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h3 className="text-sm font-medium text-gray-900">Share</h3>
                            <ul role="list" className="mt-4 flex items-center space-x-6">
                                <li>
                                    <a 
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">Share on Facebook</span>
                                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path
                                                fillRule="evenodd"
                                                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a 
                                        href={`https://www.instagram.com/?url=${encodeURIComponent(currentUrl)}`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">Share on Instagram</span>
                                        <svg className="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                                            <path
                                                fillRule="evenodd"
                                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a 
                                        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=Check%20out%20this%20product!`} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                                    >
                                        <span className="sr-only">Share on Twitter</span>
                                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mb-4 mt-10 text-xs text-center text-slate-500 py-2">Powered by <a href="https://paywithflash.com" className="font-bold text-slate-500">Flash</a></div>

                
            </div>
            
        </div>
    );
};

export default ProductGalleryProductPage;

const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
    const exchangeRate = await getExchangeRateFromDollars('PLN');

    if (currency === "usd") {
        const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
        const priceInZloty = parseFloat(value) * exchangeRate;
        return { priceInDollars: parseFloat(value), priceInSatoshis, priceInZloty };
    } else if (currency === "satoshis") {
        const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
        const priceInZloty = priceInDollars * exchangeRate;
        return { priceInDollars: parseFloat(priceInDollars), priceInSatoshis: Math.round(value), priceInZloty };
    } else if (currency === 'zloty') {
        const priceInDollars = parseFloat(value) / exchangeRate;
        const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
        return { priceInDollars, priceInSatoshis, priceInZloty: parseFloat(value) };
    }
};

async function getBitcoinPrice() {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    return data;
}

async function getExchangeRateFromDollars(to_currency) {
    const formData = new FormData();
    formData.append('to_currency', to_currency);
    const response = await fetch('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method: 'POST',
        body: formData,
    });
    const data = await response.json();
    return data;
}
