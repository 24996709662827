import React from 'react';
import HeadingSection from '../components/HeadingSection';


const Support = () => {
  const title = 'Support';
  const subtitle =
    'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.';
  const links = [
    { name: 'Get support', href: '#' },
    // { name: 'Contact us', href: '#' },
    // Add other links as needed
  ];

  return (
    <div>
      <HeadingSection title={title} subtitle={subtitle} links={links} />
      {/* Rest of your page content goes here */}
    </div>
  );
};

export default Support;
