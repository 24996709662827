import React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import TransactionsTableWithFilters from '../components/MyIncome/IncomeTables/TransactionsTableWithFilters';
import AccountManagement from '../components/FlashAccount/AccountManagement';


function MyAccount() {
    const location = useLocation();


  return (
    <div>
      <SidebarLayout>

        <Header 
          title="My account" 
          subtitle="Manage your settings and wallet connections."
          showWalletsAvatar={false} // Set this to true or false as needed
          // ... other header props ...
        />
        <div className=''>
      <AccountManagement />

        </div>
      </SidebarLayout>
    </div>
  );
}

export default MyAccount;

