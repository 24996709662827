import React, { useEffect, useState } from 'react';
import { nip04 } from 'nostr-tools';
import cn from 'classnames';

import WalletConnectButton from './WalletConnectButton'

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export const fetchDecryptedContent = async (userPublicKey, encryptedProperties) => {
    try {
      const response = await fetch(`${API_BASE_URL_NODEJS}/api/decrypt_content`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userPublicKey: userPublicKey,
          encryptedProperties: encryptedProperties,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.properties;
    } catch (error) {
      console.error('Error in fetchDecryptedContent:', error);
      throw error;
    }
  };


  export const fetchEncryptedContent = async (userPublicKey, propertiesToDecrypt) => {
    try {
      const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/encrypt_content`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userPublicKey: userPublicKey,
          propertiesToDecrypt: propertiesToDecrypt,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.properties;
    } catch (error) {
      console.error('Error in fetchEncryptedContent:', error);
      throw error;
    }
  };



export default function SelectWallet({ wallets, userData, setWallets, userPrivateKey, publicKey, getUserWallets, onSelectWallet }) {

    const [selectedWallet, setSelectedWallet, onClose] = useState ({});

    // Function to handle plan selection
    const handleSelectWallet = (selectedWallet) => {
        onSelectWallet(selectedWallet);
    };


    // Callback function to be called from WalletConnectButton
    const handleWalletConnected = async (appName, nwcString) => {
        try {
            const providerName = await getWalletNameFromNWCString(nwcString);
            await saveWalletToDB(providerName, appName, nwcString);
    
            // Fetch updated list of user wallets
            const userWallets = await getUserWallets(userPrivateKey, publicKey);
    
            if (userWallets) {
                // Update the wallets state with the new list
                setWallets(userWallets);
            } else {
                // Handle case when no wallets are returned
                console.error('No wallets returned after adding new wallet.');
            }
        } catch (error) {
            console.error('Error saving new wallet or fetching wallets:', error);
        }
    };
    


    const getWalletNameFromNWCString = async (nwcString) => {
        const walletsAvailable = ["Alby", "Mutiny"];
        // Convert nwcString to lowercase for case-insensitive comparison
        const lowerCaseNWCString = nwcString.toLowerCase();

        // Iterating over each wallet name to see if it's present in the nwcString
        for (const walletName of walletsAvailable) {
            // Also convert walletName to lowercase
            if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
                return walletName;
            }
        }

        // Return "Custom" if no match is found
        return "Custom";
    };

    // Function to be able to add new wallets
    async function saveWalletToDB(selectedWalletName, userWalletName, walletNwcUrl) {
        // Encrypt the nwcUrl
        let encrypted_nwc_url;
        if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
            encrypted_nwc_url = await fetchEncryptedContent(userData.userPublicKey, walletNwcUrl);
        } else {
            encrypted_nwc_url = "";
        }

        const formData = new FormData();
        formData.append('user_public_key', userData.userPublicKey);
        formData.append('wallet_name', selectedWalletName);
        formData.append('ln_address', "");
        formData.append('user_wallet_name', userWalletName);
        formData.append('nwc_url', encrypted_nwc_url);

        try {
            const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }

    // Check if plans is truthy and an array before rendering
    if (!Array.isArray(wallets) || !wallets) {
        return <div>No wallets available or wallets data is not valid.</div>;
    }


    return (
            <div className="flex flex-col max-h-screen overflow-y-auto bg-white rounded-lg p-4 sm:p-12">

                <div className="text-left">
                    <div className="flex items-center">
                        <img
                            className="h-12 w-12 rounded-full bg-gray-800 mr-2"
                            src={userData.userPictureUrl}
                            alt={userData.userName}
                        />
                        <h3 className="text-lg font-semibold leading-6 text-gray-900">{userData.userName}</h3>
                    </div>
                    <p className="mt-1 text-sm text-gray-500">
                        Select the wallet you want to pay with:
                    </p>
                </div>
    
                <div className="flex-grow mt-2 px-4 sm:px-7" style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #f0f0f0', boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                    <ul role="list" className="mt-2 mb-2 space-y-4 w-full">
                        {wallets.map((wallet) => (
                            <li key={wallet.id} className={`bg-white/80 text-gray-800 rounded-lg ring ring-slate-400 hover:ring-purple-600 shadow overflow-hidden ${wallet.id === selectedWallet.id ? "ring-purple-600" : ""}`} onClick={() => {setSelectedWallet(wallet)}}>
                                <div className="flex flex-row justify-start items-center p-4">
                                    <img 
                                        src={wallet.wallet_logo_url}
                                        className="h-10 w-10 rounded-full object-cover mr-4"
                                    />
                                    <div className="text-left">
                                        <p className="text-sm font-semibold text-slate-400">{wallet.user_wallet_name}</p>
                                        <p className="text-sm text-gray-900 truncate font-semibold">
                                            Balance: {Math.floor(wallet.balance / 1000).toLocaleString()} sats
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <WalletConnectButton onWalletConnected={handleWalletConnected} />
                
                <p className='mt-1 mb-5 mx-5 text-sm text-indigo-900'>Don't have a wallet? Setup in 2 min with{' '}
                    <a href="https://getalby.com/invited-by/pierrecorbin" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:text-blue-800 visited:text-purple-600 underline">
                        our partner
                    </a>
                </p>

                <div className="mt-2">
                    <button
                        type="button"
                        onClick={() => { handleSelectWallet(selectedWallet) }}
                        className="flex w-full justify-center rounded-md bg-purple-800 px-3 py-2 text-sm font-semibold leading-6 text-white shadow hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2"
                    >
                        Continue
                    </button>
                </div>
            </div>
    );
    
    
}
