import React, { useState } from 'react';
import { nip04, getPublicKey } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19, SimplePool, Kind } from 'nostr-tools';
import { EnvelopeIcon } from '@heroicons/react/24/outline'; // Import the MailIcon

const { npubEncode, nsecEncode } = nip19;

const convertHexToNostr = async (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();
  const filters = [{
    pubkey: publicKey,
    kinds: [0]
  }];

  try {
    const events = await pool.list(relayUrls, filters);
    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      localStorage.setItem('userName', metadata.name || '');
      localStorage.setItem('userPictureUrl', metadata.picture || '');
      localStorage.setItem('userAbout', metadata.about || '');
      return true;
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', 
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};

const verifyKeyPair = async (privateKey, publicKey) => {
  const generatedPublicKey = await getPublicKey(privateKey);
  return generatedPublicKey === publicKey;
};

const decryptUserPrivateKey = async (flashPrivateKey, userPublicKey, userPrivateKey) => {
  try {

    const decryptedUserPrivateKey = await nip04.decrypt(flashPrivateKey, userPublicKey, userPrivateKey);

    // Validate the decrypted private key by regenerating the public key
    const isValidKeyPair = await verifyKeyPair(decryptedUserPrivateKey, userPublicKey);
    if (!isValidKeyPair) {
      throw new Error('The regenerated public key does not match the stored public key');
    }

    return decryptedUserPrivateKey;
  } catch (error) {
    console.error("Error during decryption:", error.message || error);
    throw new Error(`Decryption failed: ${error.message || error}`);
  }
};

function LoginWithEmail() {
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
  
    const loginOrigin = window.location.origin; // Capture the origin of the login
  
    try {
      const response = await fetch('https://api.paywithflash.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ email, password, login_origin: loginOrigin })
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const userEmail = data.email;
        const userPublicKey = data.public_key;
        const encryptedUserPrivateKey = data.private_key;
        const referralId = data.referral_id;
  
        try {
          const decryptedUserPrivateKey = await decryptUserPrivateKey(flashPrivateKey, userPublicKey, encryptedUserPrivateKey);
  
          const nsec = await convertHexToNostr(decryptedUserPrivateKey, 'nsec');
          const npub = await convertHexToNostr(decryptedUserPrivateKey, 'npub');
  
          const relayUrls = ["wss://relay.paywithflash.com", "wss://relay.damus.io", "wss://relay.primal.net", "wss://relay.snort.social", "wss://relay.nostr.band"];
          await fetchUserMetadata(userPublicKey, relayUrls);
  
          localStorage.setItem('userEmail', userEmail);
          localStorage.setItem('userHexPublicKey', userPublicKey);
          localStorage.setItem('userHexPrivateKey', decryptedUserPrivateKey);
          localStorage.setItem('referralId', referralId);
          localStorage.setItem('userNsec', nsec);
          localStorage.setItem('userNpub', npub);
  
          navigate('/dashboard');
        } catch (decryptionError) {
          console.error('Decryption error:', decryptionError.message || decryptionError);
          setErrorMessage('An error occurred during decryption');
        }
      } else {
        setErrorMessage(data.error || 'Login failed');
      }
    } catch (error) {
      setErrorMessage('An error occurred during login');
      console.error('Login error:', error.message || error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex items-center mb-0">
          <h2 className="text-left text-slate-900 text-2xl font-bold leading-9 tracking-tight">
            Log in to your account.
          </h2>
        </div>
        <p className="text-sm font-light text-slate-700">
          Manage your Bitcoin payments.
        </p>
        <div className="mt-2">
          <form className="space-y-3" onSubmit={handleLogin}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-900">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder='satoshi@nakamoto.com'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-slate-900">
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  name="password"
                  placeholder='•••••••••••••'
                  type={showPassword ? 'text' : 'password'}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
                />
                <button 
                  onClick={togglePasswordVisibility} 
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-slate-900 hover:text-slate-700 eye-icon"
                >
                  <FaEye />
                </button>
              </div>
              {errorMessage && <div className="text-red-500 mt-1 text-sm">{errorMessage}</div>}
            </div>
            <div>
              <div className="flex items-center justify-between mt-3 mb-5">
                <div className="flex items-center mt-1.5">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={handleRememberMe}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  />
                  <label htmlFor="remember_me" className="ml-2 block text-sm text-slate-800">
                    Remember me
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-slate-900 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Log in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginWithEmail;
