import { nip04 } from "nostr-tools";

export const generateButtonSnippet = async (
  price, 
  userPublicKey, 
  userPrivateKey, 
  memo, 
  nostrWalletConnectUrl, 
  currency, 
  expiryDate, 
  flashId,
  lnAddress,
  selector = null,
  redirectUrl = null
) => {
  const ourPublicKey = "f981da5e5ca0a37af365877988b49f6011ac1d16f1defdb968e9ffc83316fe8c";

  // nip04 encrypt the content
  const jsonObjectString = JSON.stringify({ 
    price, 
    memo, 
    currency, 
    expiryDate, 
    flashId,
    lnAddress,
    selector,
    redirectUrl
  });

  try {
    const encryptedUserPrivatekey = await nip04.encrypt(userPrivateKey, ourPublicKey, userPrivateKey);
    const encryptedProperties = await nip04.encrypt(userPrivateKey, ourPublicKey, jsonObjectString);

    let encryptedUserNostrWalletConnectUrl = "";
    if (nostrWalletConnectUrl !== "") {
      encryptedUserNostrWalletConnectUrl = await nip04.encrypt(userPrivateKey, ourPublicKey, nostrWalletConnectUrl);
    }
    
    const encodedData = window.btoa(JSON.stringify({
      userPublicKey,
      encryptedUserPrivatekey,
      encryptedUserNostrWalletConnectUrl,
      encryptedProperties
    }));

    const snippet1 = `<script src="https://app.paywithflash.com/external/paymentButton.bundle.js" data-id="buttonScript" data-config="${encodedData}"></script>`;

    const snippet2 = 
    `<script src="https://app.paywithflash.com/external/paymentButton.bundle.js" data-id="buttonScript" data-config="${encodedData}"></script>
      <script>
          window.manualPaymentButtonSelector = '#FlashPaymentButton';
          document.addEventListener('DOMContentLoaded', function() {            
              renderPaymentButton();
          });
      </script>
      <div id="FlashPaymentButton"></div>
    `;

    return { automaticSnippet: snippet1, manualSnippet: snippet2 };
  } catch (error) {
    console.error("An error occurred during encryption:", error);
    return { error: "An error occurred during encryption" };
  }
};

export default generateButtonSnippet;
