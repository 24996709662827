import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';
import LnInvoiceHashDisplayAndCopy from '../../paymentComponents/LnInvoiceHashDisplayAndCopy';


const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
    const exchangeRate = await getExchangeRateFromDollars('PLN');

    if (currency === "usd") {
        const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
        const priceInZloty = parseFloat(value) * exchangeRate;
        return { priceInDollars: parseFloat(value), priceInSatoshis, priceInZloty };
    } else if (currency === "satoshis") {
        const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
        const priceInZloty = priceInDollars * exchangeRate;
        return { priceInDollars: parseFloat(priceInDollars), priceInSatoshis: value, priceInZloty };
    } else if (currency === 'zloty') {
        const priceInDollars = parseFloat(value) / exchangeRate;
        const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
        return { priceInDollars, priceInSatoshis, priceInZloty };
    }
};

async function getBitcoinPrice() {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const data = await response.json();
    return data;
}

async function getExchangeRateFromDollars(to_currency) {
    const formData = new FormData();
    formData.append('to_currency', to_currency);
    const response = await fetch('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method: 'POST',
        body: formData,
    });
    const data = await response.json();
    return data;
}

// Function used to create the Zap Invoice
async function createZapInvoiceWithNWC(nostrWalletConnectUrl, amount, memo) {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice_with_nwc', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nostrWalletConnectUrl: nostrWalletConnectUrl,
            amount: amount,
            memo: memo
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to create zap invoice');
    }

    return data;
}

// Function to check if the invoice was paid
async function checkInvoicePayment(nostrWalletConnectUrl, invoice) {
    const startTime = Date.now();
    let isPaid = false;

    while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
        try {
            const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment_with_nwc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nostrWalletConnectUrl: nostrWalletConnectUrl,
                    invoice: invoice
                }),
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to check payment');
            }

            if (data.is_paid) {
                isPaid = true;
                break;
            }

            await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
            console.error("Error checking invoice payment:", error);
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    return isPaid;
}

// Function used to send the Zap Invoice to the user and request payment via webln
async function requestWebLnPayment(invoice) {
    if (typeof window.webln === "undefined") {
        throw new Error("No WebLN available. Get a bitcoin lightning wallet. We recommend www.getalby.com");
    }

    try {
        await window.webln.enable();
        const result = await window.webln.sendPayment(invoice);
        return result;
    } catch (error) {
        console.error("Failed to pay invoice:", error);
    }
}

// Function to get the referral data from the db using user public key
async function fetchReferralDataWithPublicKey(userPublicKey) {
    const formData = new FormData ();
    formData.append('userPublicKey', userPublicKey);
    try {

        const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
        method: 'POST',
        body: formData,
        });

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrowing the error for handling it further up in the call stack
    }
}



const CartPayment = ({ cart, user, totalInSats, orderDetails, handlePaymentSuccess }) => {
    const [invoice, setInvoice] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);


    // const [totalInSats, setTotalInSats] = useState(0);
    const navigate = useNavigate();
    
    useEffect (() => {
        const fetchBitcoinPrice = async () => {
            const bitcoinPriceUSD = await getBitcoinPrice();
        }
        fetchBitcoinPrice();
    }, [])



    useEffect(() => {
        const calculateTotal = async () => {
            let subtotal = 0;
            let totalTaxes = 0;
            let totalInSats = 0;

            for (let product of cart) {
                const prices = await convertPrices(product.Currency, parseFloat(product.Price));
                product.PriceInDollars = prices.priceInDollars;
                product.PriceInSatoshis = prices.priceInSatoshis;
                product.PriceInZloty = prices.priceInZloty;

                const quantity = product.quantity || 1; // Set quantity to 1 if undefined
                const priceInSats = product.PriceInSatoshis * quantity;
                const priceInUSD = prices.priceInDollars * quantity;
                const taxRate = product.TaxRate ? parseFloat(product.TaxRate) / 100 : 0;
                const productTax = priceInUSD * taxRate;
                const productTaxInSats = priceInSats * taxRate;

                subtotal += priceInUSD;
                totalTaxes += productTax;
                totalInSats += priceInSats + productTaxInSats;

            }

            const totalUSD = subtotal + totalTaxes;
            setTotalPrice(totalUSD);
            // setTotalInSats(totalInSats);
        };

        calculateTotal();
    }, [cart]);

    useEffect(() => {
        if (totalInSats > 0) {
            const nostrWalletConnectUrl = cart[0]?.wallet_nwc;
            const memo = `Purchase: ${cart.map(product => product.Name).join(', ')}`;

            setLoading(true);
            createZapInvoiceWithNWC(nostrWalletConnectUrl, totalInSats, memo)
                .then(data => {
                    setInvoice(data.invoice);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Failed to create invoice:', error);
                    setLoading(false);
                });
        }
    }, [totalInSats, cart]);

    useEffect(() => {
        const processPayment = async () => {
            if (invoice) {
                const nostrWalletConnectUrl = cart[0]?.wallet_nwc;
                const paid = await checkInvoicePayment(nostrWalletConnectUrl, invoice);
                if (paid) {
                    setIsPaid(true);
                    handlePaymentSuccess();
                    navigate('/product-gallery-success', { state: { cart, user, orderDetails, totalPrice, totalInSats } });
                }
            }
        };
    
        processPayment();
    }, [invoice]);
    

    const handlePaymentClick = async (invoice) => {
        if (!invoice) {
            console.error('No invoice to pay');
            return;
        }
        try {
            const result = await requestWebLnPayment(invoice);
            if (result) {
                // Payment status is already being checked in the background
                // Optionally do something with result
            }
        } catch (error) {
            console.error('Error in handling payment:', error);
        }
    };

    return (
        <>
            {loading && (
                <div className="loading-container absolute inset-0 flex items-center justify-center" style={{ zIndex: '100' }}>
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
                </div>
            )}
            <div className="flex justify-center items-center">
                <div className="w-full max-w-md px-8 pt-8 space-y-4 rounded-2xl bg-white">
                    {!isPaid ? (
                        <>
                            <div className="text-center text-2xl font-bold font-semibold mb-2 text-slate-900 mt-3"> Pay with Lightning </div>
                            <div className="flex justify-center">
                                <QRCode
                                    value={invoice}
                                    size={180}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    renderAs={"svg"}
                                    imageSettings={{
                                        src: "/favico.png",
                                        excavate: true,
                                        height: 40,
                                        width: 40,
                                    }}
                                />
                            </div>
                            <div className="text-center">
                                <div className="flex flex-col items-center justify-center mb-2">
                                    <LnInvoiceHashDisplayAndCopy value={invoice} />
                                    <span className="priceInSats text-3xl font-bold text-slate-900">{totalInSats.toLocaleString()} sats</span>
                                    <span className="priceInDollars font-medium text-lg text-slate-600">${totalPrice.toFixed(2)}</span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="text-center">
                            <p className="font-semibold text-green-500">Payment successful! Thank you.</p>
                        </div>
                    )}
                    <div className="flex justify-center space-x-4 mt-4">
                        <button
                            className="text-sm bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={() => handlePaymentClick(invoice)}
                        >
                            Pay with Extension
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartPayment;
