import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon } from '@heroicons/react/20/solid'
import CountdownTimer from './CountdownTimer'

import { BiLink, BiSolidLockAlt } from 'react-icons/bi';
import { BsFillCameraVideoOffFill, BsFillPersonVcardFill, BsPlayFill, BsLockFill, BsCreditCard } from 'react-icons/bs';
import { LuMousePointerClick } from 'react-icons/lu';
import { MdWidgets } from 'react-icons/md';


const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return 'bg-green-500';
    case 'paused':
      return 'bg-yellow-500';
    case 'archived':
      return 'bg-red-500';
    default:
      return 'bg-gray-500';
  }
    
}


const renderStatus = (status) => {
  let bgColor, textColor, text;
  switch (status) {
    case 'active':
      bgColor = 'bg-emerald-500';
      textColor = 'text-gray-500';
      text = 'Active';
      break;
    case 'paused':
      bgColor = 'bg-yellow-500';
      textColor = 'text-gray-500';
      text = 'Paused';
      break;
    case 'expired':
      bgColor = 'bg-red-500';
      textColor = 'text-gray-500';
      text = 'Expired';
      break;
    default:
      bgColor = 'bg-gray-500';
      textColor = 'text-gray-500';
      text = 'Unknown';
  }

  return (
    <div className="mt-1 flex items-center gap-x-1.5">
      <div className={`flex-none rounded-full ${bgColor}/20 p-1`}>
        <div className={`h-1.5 w-1.5 rounded-full ${bgColor}`} />
      </div>
      <p className={`text-xs leading-5 ${textColor}`}>{text}</p>
    </div>
  );
}


const renderFlashIcon = (flashType) => {
  const iconClasses = "h-5 w-5";
  const spanClasses = "inline-flex rounded-xl p-3 ring-4 ring-white";

  switch (flashType) {
    case "Paywall":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BiSolidLockAlt className={iconClasses} aria-hidden="true" /></span>;
    case "Payment button":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><LuMousePointerClick className={iconClasses} aria-hidden="true" /></span>;
    case "Payment link":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BiLink className={iconClasses} aria-hidden="true" /></span>;
    case "Video Paywall":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BsFillCameraVideoOffFill className={iconClasses} aria-hidden="true" /></span>;
    case "Subscription Plan":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BsFillPersonVcardFill className={iconClasses} aria-hidden="true" /></span>;
    case "Subscription Paywall":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BiSolidLockAlt className={iconClasses} aria-hidden="true" /></span>;
    case "Subscription Video Paywall":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><BsFillCameraVideoOffFill className={iconClasses} aria-hidden="true" /></span>;
    case "Widget":
      return <span className={`${spanClasses} bg-indigo-50 text-indigo-700`}><MdWidgets className={iconClasses} aria-hidden="true" /></span>;
    default:
      return null;  // Or handle default case
  }
};


export default function ServicesList({ flashes }) {
  const [selectedFlash, setSelectedFlash] = useState(null);

  const navigate = useNavigate();

  const handleFlashSelect = (flash) => {
    navigate(`/service-details/${flash.id}`, { state: { flash } });
  };
  

  function handleDetailsClick(event) {
    event.stopPropagation();
  }

  function determineFlashStatus(flash) {
    const expiryDate = new Date(flash.expiry_date);
    const now = new Date();
    return expiryDate > now ? 'active' : 'expired';
  }


  // Sort flashes by status
  const sortedFlashes = flashes.sort((a, b) => {
    const statusA = determineFlashStatus(a);
    const statusB = determineFlashStatus(b);

    if (statusA === 'active' && statusB !== 'active') return -1;
    if (statusB === 'active' && statusA !== 'active') return 1;
    // Optionally, add more conditions here if you want to further sort by other statuses
    return 0;
  });

  
  return (
    <div>
      <ul
        role="list"
        className="divide-y divide-white overflow-hidden bg-white shadow-sm  sm:rounded-xl"
      >
        {sortedFlashes.map((flash) => (
          <li
            key={flash.id}
            className="relative flex justify-between items-center px-4 py-5 hover:bg-gray-50 sm:px-6 cursor-pointer"
            onClick={(event) => handleFlashSelect(flash)}
          >
            <div className="flex min-w-0 gap-x-4">
              {renderFlashIcon(flash.flash_type)}
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {flash.name}
                </p>
                <p className="text-sm leading-6 text-gray-500">
                  {flash.flash_type}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-4">
              <div className="flex flex-col items-end">
                {renderStatus(determineFlashStatus(flash))}
                <CountdownTimer expiryDate={flash.expiry_date} />
              </div>
              <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </div>
            {/* Conditionally render ProjectDetails
            {selectedFlash && selectedFlash.id === flash.id && (
              <div className="mt-4">
                <ProjectDetails onClick={handleDetailsClick} flash={selectedFlash} />
              </div>
            )} */}
          </li>
        ))}
      </ul>
    </div>
  )
}

