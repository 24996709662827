import React, { useState, useEffect } from 'react';

import { Switch } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline';


function PaidVectorstoreComponent({
    vectorstore_price,
    vectorstore_owner_ln_address,
    is_paid_vectorstore,
    is_owner,
    on_is_paid_change,
    on_vectorstore_price_change,
    on_vectorstore_owner_ln_address_change,
}) {

    // Get the values from session storage
    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    let userNpub = localStorage.getItem('userNpub') || "";

    const nostrWalletConnectUrl = process.env.REACT_APP_NWC_URL


    const [tooltipVisible1, setTooltipVisible1] = useState(false);
    const [tooltipVisible2, setTooltipVisible2] = useState(false);
    const [tooltipVisible3, setTooltipVisible3] = useState(false);
    const [lnAddressError, setLnAddressError] = useState('');
    const [PriceError, setPriceError] = useState("");

    // Set initial value for states when vectorstore value gets set
    useEffect(() => {
        if (vectorstore_price > 0) {
            setPriceError(''); // clear any existing error message
        } else {
            setPriceError("Price can't be zero or empty");
        }
    
        // Check LN Address
        if (vectorstore_owner_ln_address) {
            checkVectorstoreOwnerLnAddress(vectorstore_owner_ln_address)
                .then(isValid => {
                    if (isValid) {
                        setLnAddressError(''); // clear any existing error message
                    } else {
                        setLnAddressError('Not a proper nip-05 lightning address. Check the hint for more info.');
                    }
                });
        }
    
    }, [vectorstore_price, vectorstore_owner_ln_address]);
    
    

    async function checkVectorstoreOwnerLnAddress(vectorstore_owner_ln_address) {
        try {
            // Fetch the data from the URL using the provided Lightning address
            const response = await fetch(`https://lnaddressproxy.getalby.com/lightning-address-details?ln=${encodeURIComponent(vectorstore_owner_ln_address)}`);
            
            if (!response.ok) {
                // If the server responds with a non-2XX status, throw an error
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            // Parse the response to get the JSON data
            const data = await response.json();
    
            // Check for the existence and validity of nostrPubkey inside the lnurlp object
            if (!data.lnurlp || !data.lnurlp.nostrPubkey || data.lnurlp.nostrPubkey === "") {
                return false;  // Invalid address or missing nostrPubkey, return false
            }
    
            return true;  // Address and nostrPubkey is valid, return true
    
        } catch (error) {
            // Log any errors that occur during the fetch or parsing process
            console.warn("Error fetching or parsing lightning-address details:", error);
            return false;  // Return false in case of any error
        }
    }
    
    
    
    



    return (

        <React.Fragment>

            <div className="px-4 py-6 sm:px-6 sm:col-span-4 flex items-center border-t border-gray-100">
                <label htmlFor="paidStatus" className="block text-sm font-medium leading-6 text-gray-900 mr-4 inline-flex items-center">
                    Paid
                </label>
                <Switch
                    disabled={!is_owner}
                    checked={is_paid_vectorstore}
                    onChange={on_is_paid_change}
                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${is_paid_vectorstore ? 'bg-purple-800 focus:ring-purple-800' : 'bg-gray-200 hover:bg-gray-300'} ${!is_owner ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    <span
                        aria-hidden="true"
                        className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${is_paid_vectorstore ? 'translate-x-5' : 'translate-x-0'}`}
                    />
                </Switch>
                <span className="ml-2 cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible1(true)} onMouseLeave={() => setTooltipVisible1(false)}>
                    <InformationCircleIcon className="h-6 w-6" />
                    {tooltipVisible1 && (
                        <div className="absolute -top-2 left-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56">
                            Earn money for your data! Earn sats each time your vectorstore is used.
                        </div>
                    )}
                </span>
            </div>


            {is_paid_vectorstore && (
                <div className="px-4 py-6 sm:px-6 ml-4 col-span-full">
                <div className="flex items-center">
                    <label htmlFor="vectorstorePrice" className="text-sm font-medium leading-6 text-gray-900 mr-4">
                    Price (in sats)
                    </label>

                    <div className="flex-grow flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 mr-4">
                    <input
                    type="number"
                    disabled={!is_owner}
                    name="vectorstorePrice"
                    id="vectorstorePrice"
                    autoComplete="vectorstorePrice"
                    className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0"
                    placeholder="Vectorstore Price"
                    value={vectorstore_price}
                    onChange={(e) => on_vectorstore_price_change(e.target.value)}
                    />
                    </div>

                    <span className="cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible2(true)} onMouseLeave={() => setTooltipVisible2(false)}>
                    <InformationCircleIcon className="h-6 w-6" />
                    {tooltipVisible2 && (
                        <div className="absolute -top-2 right-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56">
                        This is what you will earn each time your vectorstore is used.
                        </div>
                    )}
                    </span>
                </div>

                {PriceError && (
                    <div className="text-red-500 text-sm">
                    {PriceError}
                    </div>
                )}

                </div>
            )}

            {is_paid_vectorstore && (
                <div className="px-4 py-6 sm:px-6 ml-4 col-span-full">
                <div className="flex items-center">
                    <label htmlFor="vectorstoreOwnerLnAddress" className="text-sm font-medium leading-6 text-gray-900 mr-4">
                    Lightning Address
                    </label>

                    <div className="flex items-center flex-grow rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 mr-4">
                    <input
                    type="text"
                    disabled={!is_owner}
                    name="vectorstoreOwnerLnAddress"
                    id="vectorstoreOwnerLnAddress"
                    autoComplete="vectorstoreOwnerLnAddress"
                    className="w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0"
                    placeholder="example@getalby.com"
                    value={vectorstore_owner_ln_address}
                    onChange={(e) => on_vectorstore_owner_ln_address_change(e.target.value)}
                    />
                    </div>

                    <span className="cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible3(true)} onMouseLeave={() => setTooltipVisible3(false)}>
                    <InformationCircleIcon className="h-6 w-6" />
                    {tooltipVisible3 && (
                    <div className="absolute -top-8 right-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-64">
                    Don't have an address? Not a problem, get an Alby wallet and get an address! 
                    <br />
                    <br />
                    Make sure it's NIP-05 verified. Just follow <a href="https://guides.getalby.com/alby-guides/alby-lightning-account/features/use-your-lightning-address-as-identifier-on-nostr" target="_blank" rel="noopener noreferrer"><strong>this Alby guide</strong></a>
                    </div>
                    )}
                    </span>
                </div>

                {lnAddressError && (
                    <div className="text-red-500 text-sm">
                    {lnAddressError}
                    </div>
                )}

                </div>
            )}

        </React.Fragment>

    );
    
    
}

export default PaidVectorstoreComponent;
