import React, { useState, useMemo, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';

import StatsComponentHome from './StatsComponentHome';
import ChartsComponent from '../IncomeCharts/ChartsComponent';
import DateRangePicker from '../DateRangePicker';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const HomeStats = ({ initialData, filtersToShow = [], columnsToShow = [] }) => {
  const [selectedFlashes, setSelectedFlashes] = useState(new Set());
  const [selectedWallets, setSelectedWallets] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());

  // State for date range
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [showDatePicker, setShowDatePicker] = useState(false);


  const flashNames = ["All Streams", ...new Set(initialData.map(transaction => transaction.flash_name))];
  const flashWallets = ["All Wallets", ...new Set(initialData.map(transaction => transaction.to_user_wallet_name))];
  const flashTypes = ["All Types", ...new Set(initialData.map(transaction => transaction.flash_type))];

  const filteredData = useMemo(() => {
    // Filtering based on selected filters
    const filtered = initialData.filter(transaction => {
      const flashNameFilter = selectedFlashes.size === 0 || selectedFlashes.has(transaction.flash_name);
      const walletFilter = selectedWallets.size === 0 || selectedWallets.has(transaction.to_user_wallet_name);
      const typeFilter = selectedTypes.size === 0 || selectedTypes.has(transaction.flash_type);

      // Filtering based on date range
      const transactionDate = new Date(transaction.transaction_date);
      const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;
      const dateFilter = (!startDate || transactionDate >= startDate) &&
                         (!endDate || transactionDate <= endDate);

      return flashNameFilter && walletFilter && typeFilter && dateFilter;
    });

    // Sorting the filtered data in chronological order
    filtered.sort((a, b) => {
      const dateA = new Date(a.transaction_date);
      const dateB = new Date(b.transaction_date);
      return dateA - dateB; // Ascending order
    });

    return filtered;
  }, [initialData, selectedFlashes, selectedWallets, selectedTypes, dateRange]);


  // Handle date range change
  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  // Function to format the date range for display
  const formatDateRange = () => {
    if (!dateRange.startDate && !dateRange.endDate) {
      return 'All Time';
    }
    const start = dateRange.startDate ? new Date(dateRange.startDate).toLocaleDateString() : '...';
    const end = dateRange.endDate ? new Date(dateRange.endDate).toLocaleDateString() : '...';
    return `From ${start} To ${end}`;
  };

  // Function to toggle the visibility of the date picker
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };


  const shouldShowFilter = (filterName) => filtersToShow.includes(filterName);
  const shouldShowColumn = (columnName) => columnsToShow.includes(columnName);

  // Handle selections
  const handleSelection = (selectedSet, value, allValue) => {
    const newSet = new Set(selectedSet);
    if (value === allValue) {
      newSet.clear();
    } else if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
    return newSet;
  };


  return (
    <div className="lg:px-1">

              <StatsComponentHome filteredData={filteredData} />


      {/* Filters (Listbox) */}
     
      
      <div className='z-50'>
      {/* <ChartsComponent filteredData={filteredData} /> */}
      </div>

      {/* <div className="mt-6 rounded-lg overflow-hidden ring-1 ring-indigo-200 shadow-sm">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white ">
                    <tr>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">ID</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Date & Time</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Flash Name</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Flash Type</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Wallet</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">LNurl</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Amount in Sats</th>
                      <th scope="col" className="whitespace-nowrap  px-3 py-3 bg-indigo-700 text-left text-xs font-semibold text-white w-1/7">Amount in USD</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-indigo-100 bg-white ">
                    {filteredData.map((transaction, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-500">{transaction.id}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                            {new Date(transaction.transaction_date).toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{transaction.flash_name}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{transaction.flash_type}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{transaction.to_user_wallet_name}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{transaction.lnurl.slice(0, 30) + "..."}</td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                            {Number(transaction.amount_in_sats).toLocaleString()} sats
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transaction.amount_in_usd)}
                        </td>

                        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                          <span className={classNames(
                            transaction.activeStatus === 'Active' ? 'text-green-400 bg-green-400/10' : transaction.activeStatus === 'Paused' ? 'text-yellow-400 bg-yellow-400/10' : 'text-red-400 bg-red-400/10',
                            'inline-flex rounded-full px-2 text-xs font-semibold leading-5'
                          )}>
                            {transaction.activeStatus}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
            </table>
          </div>
        </div> */}
    </div>
  
 
  );
};

const FilterListbox = ({ items, selectedSet, label, allValue, handleSelection }) => {
  const isAllSelected = selectedSet.size === 0 || selectedSet.has(allValue);

  return (
    <div className="flex flex-grid col-span-4 w-full ">
      <Listbox className="w-full" as="div" value={Array.from(selectedSet)} onChange={handleSelection}>
        <div className="relative mt-1 ">
          <Listbox.Button className="inline-flex w-full justify-between rounded-md bg-white px-3 py-3 text-sm font-medium text-indigo-900 ring-1 ring-indigo-100 ring-inset hover:bg-gray-50">
            {selectedSet.size === 0 ? allValue : Array.from(selectedSet).join(', ')}
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((item, idx) => (
              <Listbox.Option key={idx} value={item} className={({ active }) => classNames('cursor-pointer select-none relative py-2 pl-3 pr-9', selectedSet.has(item) ? 'bg-indigo-600 text-white' : active ? 'bg-indigo-50' : 'text-gray-900')}>
                <>
                  <span className="block truncate">{item}</span>
                  {selectedSet.has(item) && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};

// Function to update the selection
function handleSelectionChange(selectedSet, value, allValue) {
  const newSet = new Set(selectedSet);
  if (value === allValue) {
    newSet.clear();
  } else {
    if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
  }
  return newSet;
}


export default HomeStats;