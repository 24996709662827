import React, { useState, useEffect } from 'react';
import { nip04 } from 'nostr-tools';
import axios from 'axios';

import { BiLogoBitcoin, BiDollarCircle, BiWallet, BiSolidLockAlt, BiSolidInfoCircle } from 'react-icons/bi';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'




// Function to get the price of Bitcoin
async function getBitcoinPrice() {
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
            method: 'POST', // if the API requires POST method
            headers: {
                'Content-Type': 'application/json',
            },
            // If the API requires a body payload, uncomment and edit the following line
            // body: JSON.stringify({ key: 'value' }), 
        });

        if (!response.ok) {
            // If the response is not OK, throw an error with the response status
            throw new Error(`API call failed with status: ${response.status}`);
        }

        const data = await response.json();

        // Assuming the API returns an object with a property that contains the Bitcoin price
        return data
    } catch (error) {
        console.error('API call failed:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
}


const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars

    if (currency === "usd") {
        const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
        return { priceInDollars: value, priceInSatoshis };
    } else if (currency === "satoshis") {
        const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
        return { priceInDollars, priceInSatoshis: value };
    }
};

// Debounce function so that the user doesn't check for the price conversion every time he inputs a digit
const debounce = (func, wait) => {
    let timeout;

    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
};




const ProductForm = ({ product, userPublicKey, userPrivateKey, onClose }) => {

    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY

    const [formData, setFormData] = useState({
        Name: '',
        Description: '',
        Price: 0,
        Currency: '',
        SKU: '',
        Barcode: '',
        CategoryID: '',
        ActiveStatus: true,
        WalletNWC: '',
        Discount: 0,
        TaxRate: 0,
        StockCount: 0,
        is_digital_product: false,
        include_in_pos: true,
    });
    const [categories, setCategories] = useState([]);
    const [imageFiles, setImageFiles] = useState([]); // Added for handling multiple image files
    const [imagePreviews, setImagePreviews] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [temporaryImageFiles, setTemporaryImageFiles] = useState([]);
    const [uploadedImageUrls, setUploadedImageUrls] = useState(product?.ImageURLs[0] != null ? product.ImageURLs : []);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [temporaryDigitalProductFiles, setTemporaryDigitalProductFiles] = useState([]);
    const [uploadedDigitalProductUrls, setUploadedDigitalProductUrls] = useState(product?.DigitalProductURLs[0] != null ? product.DigitalProductURLs : []);
    const [digitalProductPreviews, setDigitalProductPreviews] = useState([]);
    const [digitalProductsToDelete, setDigitalProductsToDelete] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [wallets, setWallets] = useState([]);
    const [wallet, setWallet] = useState([]);
    const [selectedWalletId, setSelectedWalletId] = useState('');
    const [selectedWalletNwc, setSelectedWalletNwc] = useState('')
    const [conversionResult, setConversionResult] = useState({ dollars: 0, satoshis: 0 });
    const [priceError, setPriceError] = useState('');
    const [conversionDisplay, setConversionDisplay] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);




    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`https://api.paywithflash.com/api/list_categories`);
                setCategories(response.data);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        fetchCategories();
    }, []); // Empty dependency array means this effect runs once on mount

    // Preload product details and inventory if editing
    useEffect(() => {
        if (product) {
            // You may want to refine what exactly is set here based on what is editable
            const editableFields = {
                ...product,
                WalletNWC: product.wallet_nwc,
                Currency: product.Currency,
                StockCount: product.inventory?.StockCount || 0,
                is_digital_product: product.is_digital_product,
                include_in_pos: product.include_in_pos,
            };
            delete editableFields.CreationDate;
            delete editableFields.LastUpdatedDate;
            delete editableFields.Deleted;
            delete editableFields.user_public_key;
            delete editableFields.ImageURLs;
            delete editableFields.inventory;
            delete editableFields.category;
            // Assume ImageURLs should not be directly edited but displayed for reference
            setFormData(editableFields);
        }
    }, [product]);

    // Effect hook to fetch wallets when the component mounts
    useEffect(() => {
        const fetchWallets = async () => {
            try {
                const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const results = [];

                for (const item of data) {
                    let decrypted_nwc_url;
                    if (item.nwc_url !== "") {
                        decrypted_nwc_url = await nip04.decrypt(userPrivateKey, publicKey, item.nwc_url);
                    } else {
                        decrypted_nwc_url = ""
                    }

                    results.push({
                        id: item.id,
                        user_publicKey: item.user_public_key,
                        nwc_url: decrypted_nwc_url,
                        wallet_name: item.wallet_name,
                        user_wallet_name: item.user_wallet_name,
                        ln_address: item.ln_address
                    });
                }
                setWallets(results);

                // Set the first wallet as the default wallet and its ID
                if (results.length > 0) {
                    setWallet(results[0].user_wallet_name);
                    setSelectedWalletId(results[0].id); // Set the ID of the first NWC
                    setSelectedWalletNwc(results[0].nwc_url);
                }
            } catch (error) {
                console.error('Error fetching wallets:', error);
            }
        };

        fetchWallets();
    }, [userPrivateKey, userPublicKey, publicKey]); // Add dependencies if needed

    const validateForm = async () => {
        const errors = {};
        // Mandatory fields example
        if (!formData.Name.trim()) errors.Name = 'Name is required';
        if (!formData.Description.trim()) errors.Description = 'Description is required';
        if (!formData.Price.trim()) errors.Price = 'Price is required';
        if (!formData.Currency) errors.Currency = 'Currency is required';
        if (!formData.CategoryID) errors.CategoryID = 'Category is required';
        if (!formData.WalletNWC) errors.WalletNWC = 'Wallet is required';


        try {
            const { priceInDollars, priceInSatoshis } = await convertPrices(formData.Currency.trim(), Number(formData.Price.trim()));

            if (priceInSatoshis > 500000) {
                if (formData.Currency === "usd") {
                    errors.Price = `The price must be equal to or less than 500,000 satoshis ($${priceInDollars} equals ${priceInSatoshis} satoshis)`;
                } else {
                    errors.Price = `The price must be equal to or less than 500,000 satoshis`;
                }
            }
        } catch (error) {
            console.error('Error during form validation:', error);
            errors.Price = 'Error during price conversion. Please try again.';
        }

        setFormErrors(errors);
        // Form is valid if there are no properties in the errors object
        return Object.keys(errors).length === 0;
    };


    // Create a debounced function
    const debouncedConversion = debounce(async (currency, price) => {
        try {
            const { priceInDollars, priceInSatoshis } = await convertPrices(currency, price);
            if (currency === "usd") {
                setConversionDisplay(`(${priceInSatoshis.toLocaleString()} satoshis)`);
            } else {
                setConversionDisplay(`($${Number(priceInDollars).toFixed(2)})`);
            }

            if (priceInSatoshis > 500000) {
                if (currency === "usd") {
                    setPriceError(`The price must be equal to or less than 500,000 satoshis ($${priceInDollars} equals ${priceInSatoshis} satoshis)`);
                    setIsFormValid(false);
                } else {
                    setPriceError(`The price must be equal to or less than 500,000 satoshis`);
                    setIsFormValid(false);
                }
            } else {
                setPriceError('');
                setIsFormValid(true);
            }
        } catch (error) {
            console.error("Error converting prices:", error);
            setPriceError('Error during price conversion. Please try again.');
        }
    }, 1000); // Debounce for 1 second



    // Modify your handleChange function
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // Update the form data immediately
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        // Debounce price conversion
        if (name === 'Price' || name === 'Currency') {
            // Assuming Currency and Price are always set before converting
            if (value && formData.Currency) {
                debouncedConversion(formData.Currency, value);
            }
        }
    };


    const handleDeleteImage = (urlToDelete) => {
        // Mark the image URL for deletion
        setImagesToDelete(prev => [...prev, urlToDelete]);

        // Remove from the displayed list
        setUploadedImageUrls(currentUrls => currentUrls.filter(url => url !== urlToDelete));
    };


    const handleDeleteDigitalProduct = (urlToDelete) => {
        // Mark the image URL for deletion
        setDigitalProductsToDelete(prev => [...prev, urlToDelete]);

        // Remove from the displayed list
        setUploadedDigitalProductUrls(currentUrls => currentUrls.filter(url => url !== urlToDelete));
    };

    const handleImageChange = async (e) => {
        let files;
        if (e.dataTransfer) {
            files = Array.from(e.dataTransfer.files);
        } else {
            files = Array.from(e.target.files);
        }

        // Display preview of images
        const previews = files.map(file => ({
            url: URL.createObjectURL(file),
            type: file.type
        }));
        setImagePreviews(prevPreviews => [...prevPreviews, ...previews]);

        if (!product) {
            setTemporaryImageFiles([...files]);
        } else {
            const newImageUrls = [];

            for (const file of files) {
                const formData = new FormData();
                formData.append('image', file);
                formData.append('product_id', product?.ProductID);

                try {
                    const response = await axios.post('https://api.paywithflash.com/api/upload_product_picture', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                    if (response.data.success) {
                        newImageUrls.push(response.data.url);
                    } else {
                        console.error('Image upload failed:', response.data.error);
                    }
                } catch (error) {
                    console.error('Error uploading image:', error);
                }
            }

            setUploadedImageUrls(prevUrls => [...prevUrls, ...newImageUrls]);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleImagesDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        handleImageChange(e);
    };

    const handleDigitalProductDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        handleDigitalProductsChange(e);
    };

    const handleDigitalProductsChange = async (e) => {
        let files;
        if (e.dataTransfer) {
            files = Array.from(e.dataTransfer.files);
        } else {
            files = Array.from(e.target.files);
        }

        // Display preview of files
        const previews = files.map(file => ({
            url: URL.createObjectURL(file),
            type: file.type
        }));
        setDigitalProductPreviews(prevPreviews => [...prevPreviews, ...previews]);

        if (!product) {
            setTemporaryDigitalProductFiles([...files]);
        } else {
            const newDigitalProductUrls = [];

            for (const file of files) {
                const formData = new FormData();
                formData.append('digitalProduct', file);
                formData.append('product_id', product?.ProductID);

                try {
                    const response = await axios.post('https://api.paywithflash.com/api/upload_digital_product', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
                    if (response.data.success) {
                        newDigitalProductUrls.push(response.data.url);
                    } else {
                        console.error('Digital product upload failed:', response.data.error);
                    }
                } catch (error) {
                    console.error('Error uploading digital product:', error);
                }
            }
            setUploadedDigitalProductUrls(prevUrls => [...prevUrls, ...newDigitalProductUrls]);
        }
    };


    const handleDeleteProduct = async () => {
        if (!product || !product.ProductID) {
            console.error('No product to delete');
            return;
        }

        const confirmDeletion = window.confirm('Are you sure you want to delete this product?');
        if (!confirmDeletion) {
            return;
        }

        try {
            // Note the change here: 'user_public_key' is now part of the body of the request
            const response = await axios.post(`https://api.paywithflash.com/api/delete_product/${product.ProductID}`, {
                user_public_key: userPublicKey // This is correct for sending in the request body
            }, {
                headers: { 'Content-Type': 'application/json' } // This header is typically necessary for POST requests with a JSON body
            });

            if (response.status === 200) {
                alert('Product deleted successfully');
                window.location.reload();
            } else {
                throw new Error('Failed to delete product');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
            alert('Failed to delete product. Please try again.');
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form first
        const isFormValid = await validateForm();

        if (!isFormValid) {
            // If validation fails, we can stop here
            console.log('Validation failed', formErrors);
            return;
        }

        // Delete marked images first
        await Promise.all(imagesToDelete.map(imageUrl =>
            axios.post('https://api.paywithflash.com/api/delete_product_picture', {
                image_url: imageUrl,
                product_id: product?.ProductID, // Assuming you have the product ID
                user_public_key: userPublicKey,
            })
        ));

        // Delete marked digital products first
        await Promise.all(digitalProductsToDelete.map(digitalProductUrl =>
            axios.post('https://api.paywithflash.com/api/delete_digital_product', {
                digital_product_url: digitalProductUrl,
                product_id: product?.productID,
                user_public_key: userPublicKey,
            })
        ));

        const productData = {
            ...formData,
            user_public_key: userPublicKey,
            wallet_nwc: formData.WalletNWC,
            is_digital_product: formData.is_digital_product,
            include_in_pos: formData.include_in_pos,
        };
        const productUrl = product ? `https://api.paywithflash.com/api/update_product/${product.ProductID}` : `https://api.paywithflash.com/api/create_product`;

        try {
            // Step 1: Create or update the product (without images)
            const productResponse = await axios.post(productUrl, productData);
            const productId = product ? product.ProductID : productResponse.data.ProductID;

            // Step 2: Upload temporarily stored images for new products
            if (!product && temporaryImageFiles.length > 0) {
                await Promise.all(temporaryImageFiles.map(file => {
                    const formData = new FormData();
                    formData.append('image', file);
                    formData.append('product_id', productId);
                    return axios.post(`https://api.paywithflash.com/api/upload_product_picture`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                }));
                // Clear the temporary images after uploading
                setTemporaryImageFiles([]);
            }

            if (!product && temporaryDigitalProductFiles.length > 0) {
                await Promise.all(temporaryDigitalProductFiles.map(file => {
                    const formData = new FormData();
                    formData.append('digitalProduct', file);
                    formData.append('product_id', productId);
                    return axios.post(`https://api.paywithflash.com/api/upload_digital_product`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                }))
                // Clear the temporary digital products after uploading
                setTemporaryDigitalProductFiles([]);

            }

            // Step 3: Add or update inventory for this product
            const inventoryUrl = `https://api.paywithflash.com/api/add_or_update_inventory`;

            await axios.post(inventoryUrl, {
                user_public_key: userPublicKey,
                product_id: productId,
                stock_count: formData.StockCount
            });

            alert(`Product ${product ? 'updated' : 'created'} successfully with inventory.`);
            window.location.reload();
        } catch (error) {
            console.error('Failed to submit product or update inventory:', error.response?.data?.error || error.message);
            alert(`Error: ${error.response?.data?.error || error.message}`);
        }
    };



    const currencies = [
        { key: 1, value: 'satoshis', label: 'Satoshis', icon: <BiLogoBitcoin className="h-5 w-5" aria-hidden="true" /> },
        { key: 2, value: 'usd', label: 'Dollars', icon: <BiDollarCircle className="h-5 w-5" aria-hidden="true" /> },
    ];


    const getFileType = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'].includes(extension)) {
            return 'image';
        } else if (['pdf'].includes(extension)) {
            return 'pdf';
        } else {
            return 'other';  // For other file types, you can expand this logic as needed
        }
    }


    return (
        <>
            <div className="">
                <button onClick={onClose} className="m-4 text-gray-700 bg-white shadow hover:bg-gray-100 hover:text-gray-900 rounded-lg text-sm p-3 ml-auto inline-flex items-center mb-4" aria-label="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                    Back to Product List
                </button>
            </div>
            <div className="p-5 bg-white">
                <div className="flex justify-between items-center mb-10">
                    <h2 className="text-xl font-bold">{product ? 'Update Product' : 'Create Product'}</h2>
                </div>
                {/* Form content */}
                <form onSubmit={handleSubmit}>
                    <div className="space-y-12">

                        {/* Product Details */}
                        <div className="grid grid-cols-1 gap-x-20 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Product Details</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    This section ensures that all products are properly named and categorized, facilitating easier management and discovery within the system.
                                </p>
                            </div>
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                {/* Name */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Name*
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                name="Name"
                                                id="Name"
                                                value={formData.Name}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Description */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Description" className="block text-sm font-medium leading-6 text-gray-900">
                                        Description*
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <textarea
                                                name="Description"
                                                id="Description"
                                                value={formData.Description}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                rows="3"
                                            />
                                            {formErrors.Description && <p className="text-red-500 text-xs mt-1">{formErrors.Description}</p>}
                                        </div>
                                    </div>
                                </div>

                                {/* Category */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Category" className="block text-sm font-medium leading-6 text-gray-900">
                                        Category*
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <select
                                                name="CategoryID"
                                                id="CategoryID"
                                                value={formData.CategoryID}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a category</option>
                                                {categories.map((category) => (
                                                    <option key={category.CategoryID} value={category.CategoryID}>
                                                        {category.CategoryName}
                                                    </option>
                                                ))}
                                            </select>
                                            {formErrors.CategoryID && <p className="text-red-500 text-xs mt-1">{formErrors.CategoryID}</p>}
                                        </div>
                                    </div>
                                </div>

                                {/* Images upload and display logic */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="images" className="block text-sm font-medium leading-6 text-gray-900">
                                        Product Images*
                                    </label>
                                    <div
                                        className={`mt-1 flex justify-center rounded-lg border border-dashed px-6 py-10 ${isDragging ? 'border-indigo-600 bg-indigo-50' : 'border-gray-900/25'
                                            }`}
                                        onDragOver={handleDragOver}
                                        onDragEnter={handleDragEnter}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleImagesDrop}
                                    >
                                        <div className="text-center">
                                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="images"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input
                                                        id="images"
                                                        name="images"
                                                        type="file"
                                                        multiple
                                                        onChange={handleImageChange}
                                                        className="sr-only"
                                                    />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                    <div className="mt-4 grid grid-cols-4 gap-4">
                                        {imagePreviews.map((preview, index) => (
                                            <div key={index} className="relative aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                                                {preview.type.includes('image') ? (
                                                    <img src={preview.url} alt={`Preview ${index + 1}`} className="h-full w-full object-cover object-center" />
                                                ) : (
                                                    <div className="flex justify-center items-center h-full bg-gray-300">
                                                        <svg className="w-20 h-20 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18v4m0-22v4m0 0h4m-4 0H8m8 4a2 2 0 00-2-2H8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V10z" />
                                                        </svg>
                                                    </div>
                                                )}
                                                <button
                                                    onClick={() => {
                                                        setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
                                                        setUploadedImageUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
                                                    }}
                                                    className="absolute right-0 top-0 w-6 h-6 m-2 p-1 rounded-full text-white hover:bg-opacity-90 bg-black bg-opacity-80 flex items-center justify-center"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                        {uploadedImageUrls.map((url, index) => (
                                            <div key={index + imagePreviews.length} className="relative aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                                                {getFileType(url) === 'image' ? (
                                                    <img src={url} alt={`Uploaded file ${index + 1}`} className="h-full w-full object-cover object-center" />
                                                ) : (
                                                    <div className="flex justify-center items-center h-full bg-gray-300">
                                                        {getFileType(url) === 'pdf' && (
                                                            <svg className="w-20 h-20 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18v4m0-22v4m0 0h4m-4 0H8m8 4a2 2 0 00-2-2H8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V10z" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                )}
                                                <button
                                                    onClick={() => handleDeleteImage(url)}
                                                    className="absolute right-0 top-0 w-6 h-6 m-2 p-1 rounded-full text-white hover:bg-opacity-90 bg-black bg-opacity-80 flex items-center justify-center"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>


                        {/* Pricing, Payment and Availability */}
                        <div className="grid grid-cols-1 gap-x-20 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Pricing, Payment and Availability</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    This section focuses on the commercial aspects of the product, helping to set your preferred wallet for payments, competitive prices and manage inventory effectively
                                </p>
                            </div>
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                {/* Price */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Price" className="block text-sm font-medium leading-6 text-gray-900">
                                        Price*
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="number"
                                                name="Price"
                                                id="Price"
                                                value={formData.Price}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                            <div className="pointer-events-none absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                                                {conversionDisplay}
                                            </div>
                                        </div>
                                    </div>
                                    {priceError && (
                                        <div className="mt-1 w-full pl-[27%]">
                                            <p className="text-red-500 text-xs">{priceError}</p>
                                        </div>
                                    )}
                                </div>

                                {/* Currency */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Currency" className="block text-sm font-medium leading-6 text-gray-900">
                                        Currency*
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <select
                                                name="Currency"
                                                id="Currency"
                                                value={formData.Currency}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a currency</option>
                                                {currencies.map((currency) => (
                                                    <option key={currency.key} value={currency.value}>
                                                        {currency.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {formErrors.currency && <p className="text-red-500 text-xs mt-1">{formErrors.currency}</p>}
                                        </div>
                                    </div>
                                </div>

                                {/* Wallets Dropdown */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="WalletNWC" className="block text-sm font-medium leading-6 text-gray-900">
                                        Wallet*
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <select
                                                name="WalletNWC"
                                                id="WalletNWC"
                                                value={formData.WalletNWC}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            >
                                                <option value="">Select a Wallet</option>
                                                {wallets.map((wallet) => (
                                                    <option key={wallet.id} value={wallet.nwc_url}>
                                                        {wallet.user_wallet_name}
                                                    </option>
                                                ))}
                                            </select>
                                            {formErrors.WalletNWC && <p className="text-red-500 text-xs mt-1">{formErrors.WalletNWC}</p>}
                                        </div>
                                    </div>
                                </div>

                                {/* Active */}
                                <div className="sm:col-span-4 flex items-center">
                                    <label htmlFor="ActiveStatus" className="text-sm font-medium leading-6 text-gray-900 mr-2">
                                        Active
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="ActiveStatus"
                                        id="ActiveStatus"
                                        checked={formData.ActiveStatus} // ensure it's checked based on state
                                        onChange={handleChange}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>


                                {/* Include in PoS Checkbox */}
                                <div className="sm:col-span-4 flex items-center">
                                    <label htmlFor="include_in_pos" className="text-sm font-medium leading-6 text-gray-900 mr-2">
                                        Include in PoS
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="include_in_pos"
                                        id="include_in_pos"
                                        checked={formData.include_in_pos} // ensure it's checked based on state
                                        onChange={handleChange}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>


                            </div>
                        </div>


                        {/* Digital Product Settings */}
                        <div className="grid grid-cols-1 gap-x-20 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Digital Product Settings</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    This section is tailored for digital goods, it simplifies the handling of non-physical products, from access to distribution.
                                </p>
                            </div>
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                {/* Is Digital Product */}
                                <div className="sm:col-span-4 flex items-center">
                                    <label htmlFor="is_digital_product" className="text-sm font-medium leading-6 text-gray-900 mr-2">
                                        Is Digital Product
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="is_digital_product"
                                        id="is_digital_product"
                                        checked={formData.is_digital_product} // ensure it's checked based on state
                                        onChange={handleChange}
                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                    />
                                </div>


                                {/* Images upload and display logic */}
                                {formData.is_digital_product && (
                                    <div className="sm:col-span-4">
                                    <label htmlFor="digital-products" className="block text-sm font-medium leading-6 text-gray-900">
                                        Digital Product Files
                                    </label>
                                    <div
                                        className={`mt-1 flex justify-center rounded-lg border border-dashed px-6 py-10 ${isDragging ? 'border-indigo-600 bg-indigo-50' : 'border-gray-900/25'
                                            }`}
                                        onDragOver={handleDragOver}
                                        onDragEnter={handleDragEnter}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDigitalProductDrop}
                                    >
                                        <div className="text-center">
                                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="digital-products"
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input
                                                        id="digital-products"
                                                        name="digital-products"
                                                        type="file"
                                                        multiple
                                                        onChange={handleDigitalProductsChange}
                                                        className="sr-only"
                                                    />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">Up to 10MB</p>
                                        </div>
                                    </div>
                                    <div className="mt-4 grid grid-cols-4 gap-4">
                                        {digitalProductPreviews.map((preview, index) => (
                                            <div key={index} className="relative aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                                                {preview.type.includes('image') ? (
                                                    <img src={preview.url} alt={`Preview ${index + 1}`} className="h-full w-full object-cover object-center" />
                                                ) : (
                                                    <div className="flex justify-center items-center h-full bg-gray-300">
                                                        <svg className="w-20 h-20 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18v4m0-22v4m0 0h4m-4 0H8m8 4a2 2 0 00-2-2H8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V10z" />
                                                        </svg>
                                                    </div>
                                                )}
                                                <button
                                                    onClick={() => handleDeleteDigitalProduct(preview.url)}
                                                    className="absolute right-0 top-0 w-6 h-6 m-2 p-1 rounded-full text-white hover:bg-opacity-90 bg-black bg-opacity-80 flex items-center justify-center"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                        {uploadedDigitalProductUrls.map((url, index) => (
                                            <div key={index + digitalProductPreviews.length} className="relative aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg">
                                                {getFileType(url) === 'image' ? (
                                                    <img src={url} alt={`Uploaded file ${index + 1}`} className="h-full w-full object-cover object-center" />
                                                ) : (
                                                    <div className="flex justify-center items-center h-full bg-gray-300">
                                                        {getFileType(url) === 'pdf' && (
                                                            <svg className="w-20 h-20 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 18v4m0-22v4m0 0h4m-4 0H8m8 4a2 2 0 00-2-2H8a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V10z" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                )}
                                                <button
                                                    onClick={() => handleDeleteDigitalProduct(url)}
                                                    className="absolute right-0 top-0 w-6 h-6 m-2 p-1 rounded-full text-white hover:bg-opacity-90 bg-black bg-opacity-80 flex items-center justify-center"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                )}

                            </div>
                        </div>


                        {/* Inventory Management */}
                        <div className="grid grid-cols-1 gap-x-20 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Inventory Management</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    This section enables finer control over inventory management for you internal business needs.
                                </p>
                            </div>
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                {/* Stock Count */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="StockCount" className="block text-sm font-medium leading-6 text-gray-900">
                                        Stock Count
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="number"
                                                name="StockCount"
                                                id="StockCount"
                                                value={formData.StockCount}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Discount */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Discount" className="block text-sm font-medium leading-6 text-gray-900">
                                        Discount (%)
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="number"
                                                name="Discount"
                                                id="Discount"
                                                value={formData.Discount}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Tax Rate */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="TaxRate" className="block text-sm font-medium leading-6 text-gray-900">
                                        Tax Rate
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="number"
                                                name="TaxRate"
                                                id="TaxRate"
                                                value={formData.TaxRate}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* SKU (Stock Keeping Unit) */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="SKU" className="block text-sm font-medium leading-6 text-gray-900">
                                        SKU (Stock Keeping Unit)
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="number"
                                                name="SKU"
                                                id="SKU"
                                                value={formData.SKU}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>


                                {/* Barcode */}
                                <div className="sm:col-span-4">
                                    <label htmlFor="Barcode" className="block text-sm font-medium leading-6 text-gray-900">
                                        Barcode
                                    </label>
                                    <div className="mt-1">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                            <input
                                                type="text"
                                                name="Barcode"
                                                id="Barcode"
                                                value={formData.Barcode}
                                                onChange={handleChange}
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* Submit button */}
                    <div className="flex justify-center mt-4 mb-10">
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-800 hover:bg-slate-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {product ? 'Update Product' : 'Create Product'}
                        </button>
                        {product && (
                            <button
                                type="button" // Ensure it's not a submit button
                                onClick={handleDeleteProduct}
                                className="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-red-500 hover:text-white bg-white border-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Delete
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default ProductForm;
