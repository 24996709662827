// PaywallTestPage.js

import React, { useEffect } from 'react';

const ButtonTestPage = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'external/button-script.js';
    script.dataset.id="buttonScript"
    script.dataset.config = 'eyJ1c2VyUHVibGljS2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFVzZXJQcml2YXRla2V5IjoiZnVuT0JyNWI2cE9JcjJLNytwZjd4V09vTVR1d3JYb3A3dEU5N2NUY1BQSWRpaXc5ZVhLYWRWcDBHNG9hcSt0MTZBR0JPS0pBTmpqZVVRTkYrNkNYalk1LzFsN0o4QVZ1UkNhd1FwQ1dzWGc9P2l2PXdSekJ4eHdZSDVoU3Z5UVlUWWtZY2c9PSIsImVuY3J5cHRlZFVzZXJOb3N0cldhbGxldENvbm5lY3RVcmwiOiJmK3dLenJ2a0hqbHlvQmdqZFJKRW93Q0YvazNnWkRHenJsWkhzRlRlcGl1WEZ2SEs3WFBBeHhHZWFoZGpnbVpKMExPd1JzVndaVEVZbTNSQXVFUjYybFRWVW0xL0ZYbHcrTUR6ektZcHNmT0tOL3g2WFloUnJ0VGZBWFRDcXpMTStoYWxiN2JLaTQrZk9LUGNXNjVEYkEwbHlONjZJRU5nb0JnR20wMCtpOTl0VVFRMFdwbTRXYWJXamlrcnp3elUzZWFPZzFWbWw2YkYvQTFuaWl2RVB4WWgzcDdraURaZGtsRTI2UExzQ2Z5UStmRWZndDZVNWdIVTdxdFZSMDlsMUFLSGlQeDc3NDUrZkpNbmo0K3NrV3gwUFN1d2ovOHNBN015K3UrNEhPUT0/aXY9VE5rcU9yM1c4ZkptVG5GZ1V2bS9mZz09IiwiZW5jcnlwdGVkUHJvcGVydGllcyI6IlJBekxzS2ZzcjNuUWR0bHVIcEVqby96eExEV0RIYTQrVkI0bGFvdmdva1ZGOWlwWnZ6UTZ0NWVPTTVDWWhYVmV4TWRUQ0tBUHMwR3ExM0NaSmU0K3BMV3RheEtHNkRZeEorSXNJdk9NZGYybGxvQm8xM05qaUxVZUtyMkd3aDZIRnROeGkwYng0VzZ4Wk0zd1lXVDZxenA3dGR4NnBaMGhTSU8xWnZUaXVSRT0/aXY9Sjk5aUc4aVdSQjBScmFlaGYreHRiQT09In0=';
    // Test with NWC eyJ1c2VyUHVibGlja2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiJ3NkUrRlBzZjlRYTNiUTRMNDhXK3Q4RjdHWVFneG93d0VHRE9UV3VrTDgvOTJiYW1EV0taSUt3NkF6cWI3aElOb2xyRHc2WitoN2VYTGpBZzAxUlFvd2NiQnhlZ0VQU1RzRTBkMG8wUDVkcUxtdmIwVGJBcUdLMlJtN0g2YVdrVjNCVzJnLzdMdTI4dlg4WlVuR3poaERPdHUwaDNUSGRtTkRGVnNjYmg5cHhZNzhjWFdJRHZYT1JNQXdCZEovdmV3OU9sZmsrZzhGVjNJV0JqZnIrbTB4QmNUMFZZd0Q0WlQ5RkZqcjFhSUhMQ3dvNDEvT1kwQ2gydnoydytycWZBNm95ejRrbXUvNUs4ZG54K1g1Z3Q4d0lwQkZiWHI0OWdiNGovL1g4VXZFdFJ5czcvUldLeU1mODV1aVV0S1pxQVc2UHd1N1VJVmhDL1Fjb2tLb3dyQ1luN2JVRE96aEVVYkRCQkszZzA2eUhYM1o4QmZPaEc4TTM2T0MxQUo3ZXlYMkQwUDlZVmZQd05paFhsN1BkdktCcmw2U0VNZ0J5TWJsZkdMMWJXRjVsdk9ZckRUZG1WTHUwUFM5a0t1c2ZIQ2VKR0ZzWW1HYTVCRDFFK1NxS1hhOUY5K1dPV1cybmliaWxLeWhsMDVpb3VPbnVLWCtkTk1uTUp3NklKZDd6c1h5L2N5RXlXaXc2SFkyU01WWnB1NndlY3E0TDRzTmU1dFRmeVNXWFptaFNpTC9nOVhUN1NhTklZdXQ3SWcrUE1xdGljUnZEeTFUQUxHZE1PcXBsZTN0Y2N4RVBFd1pId2RrRVJLSHhXR1dGZmtlVndabUV3YTkxUHlxYk9aeEEwRUFqTnlSblc1dzhGQlNjektCYzJKeXR2UDlBc0hpdndEMC9nNHB4VDRCSDVKVXVML3BycUhWd2l1R1FXOTcybz9pdj1JL0NSUUFZYkdHOTRHQko2aFByaUxnPT0ifQ==
    // Test without NWC eyJ1c2VyUHVibGlja2V5IjoiMjZhY2Q2ODBjMjNlZGZmZjRkZWZiODFlYzBiOWQ2ZTJiNjZmMmY1ZmQ1NDFmNTJmODA3YWEwMjgzODg3Njg4OSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOiJGZSs4bU44NCtmS1YzMGpveDB5RUozYUZ5MWVUYTJMeStjdTRvUmU0Z1ZhNlYxN2txOFpGY2xVdjAvcXZ3a1p6S2YxdDRJWkpLWkJCSFkwK1FXT0hlbklVZW4yVzM4SnZYaVlManlaRmNJamtmYnBhYUYyK29QNFo0RmovdCtaeUxaQmZsVkJtbEVRdWRaUWliQW16T1BrRkQ2cnJrc1A4cldUcjBKYmJEbXJHZStGRlF4dG9JZlhaajgrT0NVNnU0T1lkUk1COW5TSzhaRW94cTh0dTE5Q0xheHVObU4rb2pFWXBCUU9meGhZOEdlejZFOXk1UjluNWZ6SkR3WHVBeVNnRDhzVXFKeUliMWgybHdiMndHVXlBL2swSzVSYjhOOVJVU0E0SGJTQjhmbGxjbURDMXl4VnBiaXlzT01WaHp0RUxrM1N0MTA0Zk1kbnllWG9JMlQyTzhzUEFhM1NWWkdEM0ErZktMMlE9P2l2PUtNaHUrR09rQnNocWE5QzVPb1dZWUE9PSJ9
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // <script src="/paywall-script.js" data-config="eyJ1c2VyUHVibGlja2V5IjoiOTFlOWVlYmY3N2M2ZWU0ZTVjNGYxMTljNDE5MGRhN2U0NDRkMzA1NDViY2I0MTVmMjRhN2QzMmM5NjA4ZDc4ZSIsImVuY3J5cHRlZFByb3BlcnRpZXMiOnt9fQ=="></script>
  

  return (
    <div className="max-w-2xl mx-auto my-5 p-5 border border-gray-300 rounded">
      <h1 className="text-2xl font-bold mb-4">Interesting Article Title</h1>
      <p>This is the introduction to the article. It's publicly accessible content that does not sit behind the paywall.</p>

      {/* Here you would dynamically load your paywall script if necessary */}
      {/* <script src="./paywall-script.js" data-config="..."></script> */}

      <div className="mt-5 text-center p-5 bg-gray-100 border border-gray-200 rounded">
        <p>🔒 The rest of this article is protected. Please confirm payment to continue reading.</p>
      </div>

      {/* The rest of your article's content would go here */}
    </div>
  );
};

export default ButtonTestPage;
