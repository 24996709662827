// api.js

const API_BASE_URL_NODEJS = 'https://nostr-eggs-server-skphk.ondigitalocean.app'; // Replace with your API server URL
const API_BASE_URL_FLASK = 'https://api.paywithflash.com'; // Replace with your API server URL

export const fetchDecryptedContent = async (config) => {
  try {
    const response = await fetch(`${API_BASE_URL_NODEJS}/api/decrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: config.userPublicKey,
        encryptedProperties: config.encryptedProperties,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return JSON.parse(data.properties);
  } catch (error) {
    console.error('Error in fetchDecryptedContent:', error);
    throw error;
  }
};

export const checkMembershipStatus = async (subscriptionPlanFlashId) => {
    try {
      // Replace this with the logic to retrieve user memberships from localStorage
      let flashMemberships = JSON.parse(localStorage.getItem('flashMemberships')) || {};
  
      if (flashMemberships[subscriptionPlanFlashId]) {
        const response = await fetch(`${API_BASE_URL_FLASK}/api/get_user_memberships_for_flash_id?user_public_key=${flashMemberships[subscriptionPlanFlashId].user_public_key}&flash_id=${subscriptionPlanFlashId}`);
        const data = await response.json();
  
        if (response.ok && data.user_memberships && data.user_memberships.length > 0) {
          const updatedMembershipData = data.user_memberships[0];
          flashMemberships[subscriptionPlanFlashId].user_has_paid = updatedMembershipData.is_paid;
          localStorage.setItem('flashMemberships', JSON.stringify(flashMemberships));
  
          return flashMemberships[subscriptionPlanFlashId].user_has_paid === 'true';
        }
      }
  
      // Default to false if no membership data is found or an error occurs
      return false;
    } catch (error) {
      console.error('Error in checkMembershipStatus:', error);
      throw error;
    }
  };
  

  export const createZapInvoice = async (userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) => {
    try {
      // Ensure amount is a number
      const numericAmount = Number(amount);
      if (isNaN(numericAmount)) {
        throw new Error("Invalid amount: must be a number");
      }
  
      // Set a default memo if it's empty
      const effectiveMemo = memo || "Payment for Paywall";
  
      const response = await fetch(`${API_BASE_URL_NODEJS}/api/create_zap_invoice`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userPublicKey,
          encryptedUserNostrWalletConnectUrl,
          amount: numericAmount,
          memo: effectiveMemo,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.invoice;
    } catch (error) {
      console.error('Error in createZapInvoice:', error);
      throw error;
    }
  };
  


// Function used to send the Zap Invoice to the user and request payment via webln
export const requestWebLnPayment = async (invoice) => {
    if (typeof window.webln === "undefined") {
      throw new Error("No WebLN available. Get a bitcoin lightning wallet. We recommend www.getalby.com");
    }

    try {
      await window.webln.enable();
      const result = await window.webln.sendPayment(invoice);
      return result;
    } catch (error) {
      console.error("Failed to pay invoice:", error);
      throw error; // Re-throw the error to be handled by the caller
    }
};




// Function to convert the price from currency in order to have both satoshis and usd values
export const convertPrice = async (price, currency) => {
    const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin
    let priceInSats, priceInDollars;
  
    try {
      const bitcoinPrice = await getBitcoinPrice();
  
      if (!bitcoinPrice) {
        throw new Error('Failed to fetch Bitcoin price');
      }
  
      if (currency === "Dollar") {
        const amountInUSD = parseFloat(price);
        priceInSats = Math.round((amountInUSD / bitcoinPrice) * oneBitcoinInSats);
        priceInDollars = amountInUSD;
      } else if (currency === "Satoshis") {
        const amountInSats = parseFloat(price);
        priceInSats = amountInSats;
        priceInDollars = ((amountInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
      } else {
        throw new Error('Unknown currency type: ' + currency);
      }
  
      return { priceInDollars, priceInSats };
    } catch (error) {
      console.error('Error in conversion:', error);
      throw error;
    }
  }



  // Function to check if the invoice was paid
  export const checkInvoicePayment = async (userPublicKey, encryptedUserNostrWalletConnectUrl, invoice) => {
    const startTime = Date.now();
    let isPaid = false;

    while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
      try {
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userPublicKey: userPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            invoice: invoice
          }), // Assuming nostrWalletConnectUrl is handled server-side
        });
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to check payment');
        }

        if (data.is_paid) {
          isPaid = true;
          break; // Exit the loop if the invoice is paid
        }

        // If not paid, wait 1 second before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));

      } catch (error) {
        console.error("Error checking invoice payment:", error);
        // You might want to decide if you should throw an error or perhaps continue trying
        // For now, we'll wait 1 second before trying again
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }

    return isPaid;
  }


  // Function to check if the invoice was paid
  export const checkInvoicePaymentForExtension = async (userPublicKey, encryptedUserNostrWalletConnectUrl, invoice) => {
    try {
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                userPublicKey: userPublicKey,
                encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
                invoice: invoice
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Failed to check payment');
        }

        return data.is_paid;
    } catch (error) {
        console.error("Error checking invoice payment:", error);
        throw error; // Re-throw the error for the caller to handle
    }
};





  // Function to get the Flash details from db using the flashID from requested url
  export const fetchFlashById = async (flashId) => {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/get_flash_from_id?flash_id=${encodeURIComponent(flashId)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }



  // Function to get the nwc url from the db using the nwc_id
  export const fetchWalletUrlById = async (wallet_id) => {
    try {
      const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
      url.searchParams.append('wallet_id', wallet_id);
  
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
      throw error; // Rethrowing the error for handling it further up in the call stack
    }
  }



   // Function to save the transaction to table transactions of the db
   async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
    const formData = new FormData();
    formData.append('from_public_key', from_public_key);
    formData.append('to_public_key', to_public_key);
    formData.append('transaction_date', transactionDate);
    formData.append('amount_in_sats', amountInSats);
    formData.append('value_in_usd', valueInUsd);
    formData.append('flash_id', flashId);
    formData.append('flash_name', flashName);
    formData.append('flash_type', flashType); // Assuming this is also a new requirement
    formData.append('to_wallet_id', toWalletId);
    formData.append('to_user_wallet_name', toUserWalletName);
    formData.append('from_wallet_id', fromWalletId);
    formData.append('from_user_wallet_name', fromUserWalletName);
    formData.append('lnurl', lnurl);

    try {
      const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save transaction to database');
      }

      const successData = await response.json();
      console.log('Transaction successfully saved to database:', successData);
      return successData;
    } catch (error) {
      console.error('Error saving transaction to database:', error);
      throw error;
    }
  }