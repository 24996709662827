import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import UserSubscriptionsList from '../components/User/UserSubscriptionsList';
import UserSubscriptionsStatsComponent from '../components/User/UserSubscriptionsStatsComponent';
import services from '../components/FlashServices/servicesData'; // Adjust the path as needed



function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function MySubscriptions() {
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  const [selectedTab, setSelectedTab] = useState('My Subscriptions');
  const location = useLocation();
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    fetchUserSubscriptions(userHexPublicKey)
      .then(data => {
        if (data) {
          setSubscriptions(data);
        }
      });
  }, [userHexPublicKey]); // The effect depends on userHexPublicKey

  async function fetchUserSubscriptions(userPublicKey) {
    try {
        // URLs for both APIs
        const apiUrl1 = new URL('https://api.paywithflash.com/api/get_user_memberships_with_details');
        apiUrl1.searchParams.append('user_public_key', userPublicKey);

        const apiUrl2 = new URL('https://api.paywithflash.com/vimeo_ott/api/get_vimeo_ott_user_memberships_with_details');
        apiUrl2.searchParams.append('user_public_key', userPublicKey);

        const apiUrl3 = new URL('https://api.paywithflash.com/geyser/api/get_geyser_user_memberships_with_details');
        apiUrl3.searchParams.append('user_public_key', userPublicKey);

        // Fetch data from both APIs concurrently
        const responses = await Promise.all([
            fetch(apiUrl1, { method: 'GET' }),
            fetch(apiUrl2, { method: 'GET' }),
            fetch(apiUrl3, { method: 'GET' })
        ]);

        // Check if both responses are ok
        responses.forEach(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        });

        // Parse JSON concurrently from both responses
        const datas = await Promise.all(responses.map(response => response.json()));

        // Assuming both APIs return a structure with 'user_memberships' key
        const combinedData = datas.reduce((acc, data) => acc.concat(data.user_memberships), []);

        // Sort combinedData by status (active first) and next_payment_date (soonest at the top)
        combinedData.sort((a, b) => {
            // Prioritize active status
            if (a.user_membership_details.status === "active" && b.user_membership_details.status !== "active") {
                return -1;
            } else if (a.status !== "active" && b.user_membership_details.status === "active") {
                return 1;
            }

            // Then sort by next_payment_date
            const dateA = new Date(a.user_membership_details.next_payment_date), dateB = new Date(b.user_membership_details.next_payment_date);
            return dateA - dateB;
        });

        if (combinedData) {
          setSubscriptions(combinedData);
          return combinedData;
        }
    } catch (error) {
        console.error('Error fetching user subscriptions:', error);
        return null;
    }
}





  return (
    <div>
      <SidebarLayout>
        <Header
          title="My Subscriptions"
          subtitle="All your subscriptions, managed in one place."
          showWalletsAvatar={false}
        />

        <div className="w-full">
          <nav className="flex space-x-8 border-b border-t pt-3 pb-0 bg-white pl-9">
            {['My Subscriptions'].map((tab) => (
              <a
                key={tab}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(tab);
                }}
                className={classNames(
                  tab === selectedTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-indigo-300 hover:text-indigo-400 hover:border-indigo-400',
                  'inline-flex items-center border-b-2 px-0 pt-1 pb-3 text-sm font-medium'
                )}
              >
                {tab}
              </a>
            ))}
          </nav>

          <div className="mt-6 pl-9">
            {selectedTab === 'My Subscriptions' && (
              <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
                <div className='md:col-span-1 pt-1'>
                  <h2 className="text-xl font-semibold leading-7 text-indigo-900">Subscriptions</h2>
                  <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage your flash subscriptions.</p>
                </div>
                <div className="md:col-span-3">
                  <div className='p-2 pr-12'>
                    <UserSubscriptionsStatsComponent subscriptions={subscriptions} />
                    <UserSubscriptionsList subscriptions={subscriptions} userHexPublicKey={userHexPublicKey} fetchSubscriptions={() => fetchUserSubscriptions(userHexPublicKey)} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
}

export default MySubscriptions;
