import React from 'react';

const ProductList = ({ products, selectedCategory, addProductToOrder }) => {
    return (
        <div className="grid grid-cols-3 gap-4 px-5 mt-5 overflow-y-auto">
            {products.map((product, index) => {
                return (
                    <>
                        {(Object.keys(selectedCategory).length === 0 || (product.category.CategoryID === selectedCategory.CategoryID)) && (
                            <div key={index} className="px-3 py-3 flex flex-col border border-gray-200 rounded-md h-32 justify-between hover:cursor-pointer" onClick={() => { addProductToOrder(product) }}>
                                <div>
                                    <div className="font-bold text-gray-800">{product.Name}</div>
                                </div>
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex flex-col">
                                        {product.Currency === 'usd' ? (
                                            <>
                                                <span className="self-end font-bold text-lg text-yellow-500">${product.PriceInDollars}</span>
                                                <span className="self-end text-sm text-gray-500">{product.PriceInSatoshis} sats</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="self-end font-bold text-lg text-yellow-500">{product.PriceInSatoshis} sats</span>
                                                <span className="self-end text-sm text-gray-500">${product.PriceInDollars}</span>
                                            </>
                                        )}
                                    </div>
                                    <img src={product.ImageURLs.length > 0 ? product.ImageURLs[0] : ''} className="h-14 w-14 object-cover rounded-md" alt="" />
                                </div>
                            </div>
                        )}
                    </>
                );
            })}
            {products.length === 0 && (
                <div className="text-xl text-center font-semibold">No Product</div>
            )}
        </div>
    );
}

export default ProductList;
