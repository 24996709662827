import React, { useEffect } from 'react';

import SubscriptionVideoPaywall from '../../components/ExternalFlashComponents/SubscriptionVideoPaywall/SubscriptionVideoPaywall'

const VideoTestPage = () => {
    const videoPath = 'https://flash-images.fra1.digitaloceanspaces.com/video_paywall_example/Monetize%20on%20your%20APIs.mp4';
    const encodedConfig = 'eyJ1c2VyUHVibGljS2V5IjoiZjc3NzdkYzAwMjYzMDY0YTU0YTdiZTZkY2I1OTkzMWE0MDRlYjY4NTY0NTMyZTU4MzFkMjMxOTM1MDhlYjMyNSIsImVuY3J5cHRlZFVzZXJQcml2YXRla2V5IjoiYzdxSlZuS1FjS1U4SkdoZXhMUkdaZ3VVa0lwaE85T05LSXRJYk81VndsNjBOT0ZRalRxSUMwVHVhZTVTNUh4MW51NWdWL1NuR3ZhMWRDd1BGT3gvVEl1aldrd3NNYlFFOCtLQWZ0MjJudGM9P2l2PTlVcnZuMzlMa29uUCsvWnZsaXZGK3c9PSIsImVuY3J5cHRlZFVzZXJOb3N0cldhbGxldENvbm5lY3RVcmwiOiI5VTRNUXZBckxuRTAzQzRqTW1ySHJLUmJLcG5pQXorUkIwVTJ6QlpKQ2NtZ0hYVVVhb2RlZTd1dlk2bUZvNnZQU2R2QTh4NnljRktwS3VxcXBJMTM1RVp2enAxbFRtWjcwTWpUc1ZxZkpNaCt5YVE4V2ZpOWpncmlOYm90YmVzQ29MdGJ0NkRqL0lXZTRIZVZRYStaR3M3ckV6TU5VVWNOUHhjTXUzSGxDWWxVS3grR1VVaHJvdnlyU29RWUk0M1ZuaXBjcThjOEF1Q0dPTnU0akFZZUlyeG5JLzRyb2ZFQmVkQ2hsQXg0VUtabXV3TTdDUXErbUkraWJWZFIveGJIVXFXMEs4a2lDOHBmeG1iTUM0blFrRXRGWkxBRVZNUHVSVXBuZjRobGdYcz0/aXY9Z3h3QlU1a0VYN0VQZktHc1YyV25PQT09IiwiZW5jcnlwdGVkUHJvcGVydGllcyI6IlNVZ1phelp5d0RWbVN3OGdDVTRuSWRldExDQk9qVHdZWlVKNmVMWGpKMVl5WjFLN1l3MTZFeWJxajVtUFFxQjVWblpvNldYd1U3TTFBamZyZTQveUdpTlpTZzlBU0VZSFA1R1JmeGo2bXBjdCtmUUR6ZXZqRkp3azBnVlNqb0NSNnRCeDVmUEdyUCtZYnZxbXBXSlNkbUY2QkR3TldvbTdLS3Zway94dmJLWEV1cW9uSmMrbTV5S3d0N084eWl3R2llMU9RTGVsTlZFRlhWaWd6OXI0OHFCZVplcDl3WEZscnA5UnB2Y1JUWGxwaHZBMndDaktFUnY5bFNwVFVlb0FkL0FGWjNHYmxCVU5ycmhDT1dzSTRBPT0/aXY9RGl5YlVnaHN5c0ZIWG9PWUo3NW9RZz09In0=';

    useEffect(() => {
        // Create the script element
        const script = document.createElement('script');
        script.src = 'external/subscription-video-script-test.js';
        script.setAttribute('data-id', 'subscriptionVideoPaywallScriptTest');
        script.setAttribute('data-config', encodedConfig);

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [encodedConfig]); // Dependency array includes encodedConfig

    return (
        <div className="max-w-2xl mx-auto my-5 p-5 border border-gray-300 rounded">
            <video id="myVideo" width="320" height="240" controls>
                <source src={videoPath} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {encodedConfig && (
                <SubscriptionVideoPaywall
                encodedConfig={encodedConfig}
                    videoId="myVideo" // This should match the ID of your video element
                />
            )}
        </div>
    );
};

export default VideoTestPage;
