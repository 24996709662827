import React, { useEffect, useState } from 'react';
import Categories from './Categories';
import ProductList from './ProductList';
import CurrentOrder from './CurrentOrder';
import OrderPrice from './OrderPrice';
import Payment from './Payment';
import PointOfSalePayment from './PointOfSalePayment';
import { WalletDropdown } from '../FlashForms/comp/InputsLib';

import { nip04 } from 'nostr-tools';


import axios from 'axios';


// Function to get the referral data from the db using user public key
async function fetchReferralDataWithPublicKey(userPublicKey) {
    const formData = new FormData ();
    formData.append('userPublicKey', userPublicKey);
    try {

        const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
        method: 'POST',
        body: formData,
        });

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrowing the error for handling it further up in the call stack
    }
}

// Function to get the nwc url from the db using the nwc_id
async function fetchWalletUrlById(wallet_id) {
    try {
        const url = new URL('https://api.paywithflash.com/api/get_wallet_from_id');
        url.searchParams.append('wallet_id', wallet_id);

        const response = await fetch(url);

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error; // Rethrowing the error for handling it further up in the call stack
    }
}

// Function to get the price of Bitcoin
async function getBitcoinPrice() {
    try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST', // if the API requires POST method
        headers: {
          'Content-Type': 'application/json',
        },
        // If the API requires a body payload, uncomment and edit the following line
        // body: JSON.stringify({ key: 'value' }), 
      });
  
      if (!response.ok) {
        // If the response is not OK, throw an error with the response status
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Assuming the API returns an object with a property that contains the Bitcoin price
      return data
    } catch (error) {
      console.error('API call failed:', error);
      throw error; // Rethrow the error to be handled by the caller
    }
  }
  


  async function getExchangeRateFromDollars (to_currency) {
    try {
      const formData = new FormData ();
      formData.append ('to_currency', to_currency);
  
      const response = await fetch ('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method : 'POST',
        body : formData,
      });
  
      if (!response.ok) {
        throw new Error (`API call failed with status:${response.status}`);
      }
  
      const data = await response.json ();
      return data;
    } catch (error) {
      console.error ('API call failed:', error);
      throw error;
    }
  }

  
  const convertPrices = async (currency, value) => {
    const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
    const exchangeRate = await getExchangeRateFromDollars ('PLN');
  
    if (currency === "usd") {
      const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
      const priceInZloty = parseFloat (value) * exchangeRate;
      return { priceInDollars: value, priceInSatoshis, priceInZloty };
    } else if (currency === "satoshis") {
      const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
      const priceInZloty = priceInDollars * exchangeRate;
      return { priceInDollars, priceInSatoshis: value, priceInZloty };
    } else if (currency === 'zloty') {
      const priceInDollars = parseFloat (value) / exchangeRate;
      const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
      return { priceInDollars, priceInSatoshis, priceInZloty: value}
    }
  };



  // Function to save the sales product info to table salesproduct of the db
  async function saveSaleInfoToDB (userPublicKey, customerId, productList, productQuantity, saleOrigin, priceInDollars, priceInSats, priceInZloty, saleDate) {
    const formData = new FormData();
    console.log(productList);
    console.log(productQuantity);
    formData.append('user_public_key', userPublicKey);
    formData.append('customer_id', customerId);
    for (const product of productList) {
        formData.append('product_list[]', product.ProductID);
    }
    for (const quantity of productQuantity) {
        formData.append('product_quantity[]', quantity);
    }
    formData.append('sale_origin', saleOrigin);
    formData.append('price_in_dollars', priceInDollars);
    formData.append('price_in_sats', priceInSats);
    formData.append('price_in_zloty', priceInZloty);
    formData.append('sale_date', saleDate);

    try {
        const response = await fetch('https://api.paywithflash.com/api/save_sale_info_to_db', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to save sale information to database');
        }

        const successData = await response.json();
        console.log('Sale Information successfully saved to database:', successData);

        return successData;
    } catch (error) {
        console.log('Error saving Sales information to database:', error);
        throw error;
    }
}


const ProductOrdering = () => {

    const userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    const userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
    const flashPublickey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;


    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [isInvoice, setIsInvoice] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState (JSON.parse (localStorage.getItem ('selectedProducts')) || []);
    const [productOrderingCount, setProductOrderingCount] = useState (JSON.parse (localStorage.getItem ('productOrderingCount')) || []);
    const [subTotal, setSubTotal] = useState (localStorage.getItem ('subTotal') || 0);
    const [subTax, setSubTax] = useState (localStorage.getItem ('subTax') || 0);

    const [wallets, setWallets] = useState([]);
    const [selectedWalletId, setSelectedWalletId] = useState('');
    const [selectedWalletNwcUrl, setSelectedWalletNwcUrl] = useState();
    const [selectedWalletName, setSelectedWalletName] = useState();

    const [totalInSatoshis, setTotalInSatoshis] = useState(0);
    const [totalInUSD, setTotalInUSD] = useState(0);
    const [totalInZloty, setTotalInZloty] = useState(0);

    const [bitcoinPrice, setBitcoinPrice] = useState (0);

    // Referral Data  
    const [isReferralExists, setIsReferralExists] = useState();
    const [referralData, setReferralData] = useState();
    const [referralWalletData, setReferralWalletData] = useState();




    useEffect(() => {
        const fetchProductsAndInventoryAndCategory = async () => {
            try {
                const { data: productsData } = await axios.get(`https://api.paywithflash.com/api/list_products_for_user`, {
                    params: { user_public_key: userHexPublicKey }
                });
    
                // Fetch inventory and category for each product
                const productDetailsPromises = productsData.map(async (product) => {
                    try {
                        const inventoryResponse = await axios.get(`https://api.paywithflash.com/api/get_inventory_for_product/${product.ProductID}`);
                        product.inventory = inventoryResponse.data;
                    } catch {
                        product.inventory = null; // Handle individual product inventory fetch errors
                    }

                    console.log(product.inventory)
    
                    try {
                        const categoryResponse = await axios.get(`https://api.paywithflash.com/api/get_category_by_id/${product.CategoryID}`);
                        product.category = categoryResponse.data;
                    } catch {
                        product.category = { error: 'Category not found' }; // Handle missing category information
                    }
    
                    // Calculate and include both price representations
                    const prices = await convertPrices(product.Currency, Number(product.Price));
                    product.PriceInDollars = prices.priceInDollars;
                    product.PriceInSatoshis = prices.priceInSatoshis;
                    product.PriceInZloty = prices.priceInZloty;
    
                    return product;
                });
    
                const productsWithDetails = await Promise.all(productDetailsPromises);
    
                // Use a Map to ensure unique categories by CategoryID
                const categoriesMap = new Map();
                productsWithDetails.forEach((product) => {
                    categoriesMap.set(product.category.CategoryID, product.category);
                });
                const categories = Array.from(categoriesMap.values());
    
                setProducts(productsWithDetails);
                setCategories(categories);
    
            } catch (error) {
                console.error('Failed to fetch products, inventory, and category', error);
                throw error;
            }
        }
        const getBitcoinPrices = async () => {
            const bitcoinPriceUSD = await getBitcoinPrice ();
            setBitcoinPrice (bitcoinPriceUSD);
        }
        fetchProductsAndInventoryAndCategory();
        getBitcoinPrices ();
    
    }, [userHexPublicKey]);
    



    // Effect hook to fetch wallets when the component mounts
    useEffect(() => {

        const fetchReferralData = async () => {
            const referralData = await fetchReferralDataWithPublicKey(userHexPublicKey);
            setIsReferralExists (referralData.isReferralExists);
            setReferralData(referralData.referralData);
    
            if (referralData.isReferralExists) {
              const referralWalletData = await fetchWalletUrlById(referralData.referralData.wallet_id);
              setReferralWalletData (referralWalletData);
            }
        }

        const fetchWallets = async () => {
            try {
                const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userHexPublicKey)}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const results = [];

                for (const item of data) {
                    // if (item.nwc_url !== "") {
                    //     decrypted_nwc_url = await nip04.decrypt(flashPrivateKey, userHexPublicKey, item.nwc_url);
                    // } else {
                    //     decrypted_nwc_url = ""
                    // }

                    results.push({
                        id: item.id,
                        user_publicKey: item.user_public_key,
                        nwc_url: item.nwc_url,
                        wallet_name: item.wallet_name,
                        user_wallet_name: item.user_wallet_name,
                        ln_address: item.ln_address
                    });
                }
                setWallets(results);

                // Set the first wallet as the default wallet and its ID
                if (results.length > 0) {
                    // setWallet(results[0].user_wallet_name);
                    setSelectedWalletId(results[0].id); // Set the ID of the first NWC
                    setSelectedWalletNwcUrl(results[0].nwc_url);
                    setSelectedWalletName(results[0].name);
                }
            } catch (error) {
                console.error('Error fetching wallets:', error);
            }
        };
        fetchReferralData();
        fetchWallets();
    }, [userHexPublicKey])


    // Calculate total prices in USD, Satoshis, and Zloty when selected products or counts change
    useEffect(() => {
        let newTotalInSatoshis = 0;
        let newTotalInUSD = 0;
        let newTotalInZloty = 0;
        
        selectedProducts.forEach((product, index) => {
            console.log(product);
            const quantity = productOrderingCount[index];
            const priceInSatoshis = product.PriceInSatoshis * quantity;
            const priceInDollars = parseFloat(product.PriceInDollars) * quantity;
            const priceInZloty = parseFloat(product.PriceInZloty) * quantity;

            const taxRate = parseFloat(product.TaxRate) / 100;
            const taxInSatoshis = priceInSatoshis * taxRate;
            const taxInDollars = priceInDollars * taxRate;
            const taxInZloty = priceInZloty * taxRate;

            newTotalInSatoshis += priceInSatoshis + taxInSatoshis;
            newTotalInUSD += priceInDollars + taxInDollars;
            newTotalInZloty += priceInZloty + taxInZloty;
        });
        
        localStorage.setItem('selectedProducts', JSON.stringify(selectedProducts));
        localStorage.setItem('productOrderingCount', JSON.stringify(productOrderingCount));
        localStorage.setItem('subTotal', subTotal);
        localStorage.setItem('subTax', subTax);
        setTotalInSatoshis(newTotalInSatoshis);
        setTotalInUSD(newTotalInUSD.toFixed(2));
        setTotalInZloty(newTotalInZloty.toFixed(2));
    }, [selectedProducts, productOrderingCount]);




    const handleWalletChange = (e) => {
        const selectedWalletId = e.target.value;
        const selectedWallet = wallets.find(wallet => wallet.id.toString() === selectedWalletId);

        if (selectedWallet) {
            setSelectedWalletId(selectedWallet.id);
            setSelectedWalletNwcUrl(selectedWallet.nwc_url);
            setSelectedWalletName(selectedWallet.name);
        }
    };



    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    }

    const addProductToOrder = (product) => {
        const isExist = selectedProducts.filter((selectedProduct) => selectedProduct.ProductID == product.ProductID);
        if (isExist.length != 0) return;
        let cloneSelectedProducts = Object.assign([], selectedProducts);
        let cloneProductOrderingCount = Object.assign([], productOrderingCount);
        cloneSelectedProducts.push(product);
        cloneProductOrderingCount.push(1);
        setSubTotal((parseFloat(subTotal) + parseFloat(product.Price)).toFixed(2));
        setSubTax((parseFloat(subTax) + parseFloat(product.Price / 100 * parseFloat(product.TaxRate))).toFixed(2));
        setSelectedProducts(cloneSelectedProducts);
        setProductOrderingCount(cloneProductOrderingCount);
    }

    const removeProductFromOrder = (index) => {
        let updatedSelectedProducts = [...selectedProducts];
        let updatedProductOrderingCount = [...productOrderingCount];
        updatedSelectedProducts.splice(index, 1);
        updatedProductOrderingCount.splice(index, 1);

        setSelectedProducts(updatedSelectedProducts);
        setProductOrderingCount(updatedProductOrderingCount);

        // Adjust subtotal and tax when removing the product
        setSubTotal((prevSubTotal) => (parseFloat(prevSubTotal) - parseFloat(selectedProducts[index].Price)).toFixed(2));
        setSubTax((prevSubTax) => (parseFloat(prevSubTax) - parseFloat(selectedProducts[index].Price) / 100 * parseFloat(selectedProducts[index].TaxRate)).toFixed(2));
    };

    const clearOrder = () => {
        setSubTotal(0);
        setSubTax(0);
        setSelectedProducts([]);
        setProductOrderingCount([]);
    }

    const increaseOrderCount = (index) => {
        let cloneArray = [...productOrderingCount];
        cloneArray[index]++;
        setProductOrderingCount(cloneArray);

        setSubTotal((prevSubTotal) => (parseFloat(prevSubTotal) + parseFloat(selectedProducts[index].Price)).toFixed(2));
        setSubTax((prevSubTax) => (parseFloat(prevSubTax) + parseFloat(selectedProducts[index].Price) / 100 * parseFloat(selectedProducts[index].TaxRate)).toFixed(2));

        if (selectedProducts[index].inventory.StockCount < cloneArray[index]) {
            // Optionally, display a message to the user that this item is backordered
            // console.log("Note: This item is on backorder and will be shipped when available.");
        }
    };



    const decreaseOrderCount = (index) => {
        if (productOrderingCount[index] > 1) {
            let cloneArray = [...productOrderingCount];
            cloneArray[index]--;
            setProductOrderingCount(cloneArray);

            setSubTotal((prevSubTotal) => (parseFloat(prevSubTotal) - parseFloat(selectedProducts[index].Price)).toFixed(2));
            setSubTax((prevSubTax) => (parseFloat(prevSubTax) - parseFloat(selectedProducts[index].Price) / 100 * parseFloat(selectedProducts[index].TaxRate)).toFixed(2));
        } else if (productOrderingCount[index] === 1) {
            removeProductFromOrder(index);
        }
    };


    const handleGenerateInvoice = () => {
        setIsInvoice(true); // Toggle to show the PointOfSalePayment component
    };

    const handlePaymentSuccess = async () => {
        // Assuming payment is successful, wait for 3 seconds before switching the view
        setTimeout(() => {
            setIsInvoice(false); // Hide the PointOfSalePayment and show the other components again
            clearOrder(); // Optional: clear the order details if required
        }, 3000); // 3000 milliseconds = 3 seconds

        const saleDate = new Date().toISOString();
        await saveSaleInfoToDB (userHexPublicKey, -1, selectedProducts, productOrderingCount, 'product pos', totalInUSD, totalInSatoshis, totalInZloty, saleDate);
        
        // Calculate the fee and price minus fee
        const feePercentage = 1; // 1%
    
        // Assuming priceInSats is already defined and is the total price in Satoshis
        let satsFee = totalInSatoshis * (feePercentage / 100); // Calculate the fee
        satsFee = Math.round(satsFee); // Rounding to the nearest whole number

        let satsFeeToFlash, satsFeeToReferral;
        if (isReferralExists) {
          satsFeeToFlash = satsFee * 70 / 100;
          satsFeeToReferral = satsFee * 30 / 100;
          await payFeeToReferral(referralWalletData.nwc_url, selectedWalletNwcUrl, satsFeeToReferral, `Referral Fee for Products on PoS`);
        }
        else satsFeeToFlash = satsFee;

        const response = await payFeeToFlash(selectedWalletNwcUrl, satsFeeToFlash, `Fee for Flash POS`);

        localStorage.removeItem ('selectedProducts');
        localStorage.removeItem ('productOrderingCount');
        localStorage.removeItem ('subTotal');
        localStorage.removeItem ('subTax');
    };
    
    // The function used to for the user to pay the fee owed to referred him
    async function payFeeToReferral(encryptedReferralUserNostrWalletConnectUrl, encryptedUserNostrWalletConnectUrl, amount, memo) {

        const transactionDate = new Date().toISOString();
        // Call your server-side API to decrypt the config with nostr nip04
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
            referralUserPublicKey: referralData.user_public_key,
            encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
            userPublicKey: userHexPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: totalInSatoshis,
            value_in_usd: totalInUSD,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
        }),
        });
        const data = await response.json();

        if (!response.ok) {
        throw new Error(data.message || 'Failed to decrypt config');
        }

        return data;
    }

    async function payFeeToFlash(encryptedUserNostrWalletConnectUrl, amount, memo) {

        const transactionDate = new Date().toISOString();
        // Call your server-side API to decrypt the config with nostr nip04
        const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
            userPublicKey: userHexPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            amount: amount,
            memo: memo,
            amount_in_sats: totalInSatoshis,
            value_in_usd: totalInUSD,
            usd_btc_exchange_rate: bitcoinPrice,
            flash_id: -1,
            transaction_date: transactionDate,
            user_public_key: userHexPublicKey
            }),
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Failed to decrypt config');
        }

        return data;
    }

    const handleBack = () => {
        setIsInvoice (false);
      }


    return (
        <>
            {!isInvoice ? (
                <div className="flex lg:flex-row flex-col-reverse shadow-lg">
                    <div className="w-full lg:w-3/5 min-h-screen shadow-lg">
                        <Categories categories={categories} selectedCategory={selectedCategory} setCategory={handleCategoryChange} />
                        <ProductList products={products} selectedCategory={selectedCategory} addProductToOrder={addProductToOrder} />
                    </div>
                    <div className="w-full lg:w-2/5">
                        <CurrentOrder selectedProducts={selectedProducts} productOrderingCount={productOrderingCount} clearOrder={clearOrder} increaseOrderCount={increaseOrderCount} decreaseOrderCount={decreaseOrderCount} />
                        <div className="px-5 mt-5">
                            <div className="py-4 rounded-md shadow-lg">
                                <WalletDropdown label="Wallet" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
                            </div>
                        </div>
                        <OrderPrice subTotal={totalInUSD} subTax={subTax} totalInSatoshis={totalInSatoshis} />
                        <div className="px-5 mt-5">
                            <button onClick={handleGenerateInvoice} className="w-full px-4 py-4 rounded-md shadow-lg text-center bg-indigo-500 text-white font-semibold hover:bg-indigo-700">
                                Generate Invoice
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <PointOfSalePayment
                    amount={totalInSatoshis}
                    totalInUSD={totalInUSD}
                    encryptedUserNostrWalletConnectUrl={selectedWalletNwcUrl}
                    handlePaymentSuccess={handlePaymentSuccess}
                    handleBack={handleBack}
                />
            )}
        </>
    );
}

export default ProductOrdering;