import React from 'react';

const PaymentSuccessScreen = ({ selectedMembership, orderDetails, flashId, encodedParams }) => {

  const manageSubscriptionPageUrl = `/manage-subscription?flashId=${flashId}&params=${encodedParams}`;

  // If you need to handle changes to the input, add a state and an onChange handler
  const [isChecked, setIsChecked] = React.useState(true); // default to true if it should be checked initially

  const handleInputChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow">
      <h2 className="text-2xl font-bold text-center mb-6">You are subscribed!</h2>

      {/* Selected Membership card */}
      <div className="flex flex-col items-center justify-center space-y-4 p-4 gap-2">
        <label className="cursor-pointer block w-full max-w-md mx-auto">
          <input
            type="radio"
            className="peer sr-only"
            name="pricing"
            checked={isChecked} // use a boolean value, not a string
            onChange={handleInputChange} // add onChange handler to make the input interactive
            readOnly={true} // make the input read-only if that's the intended behavior
          />
          <div className="rounded-md border px-5 py-3 text-gray-500 ring-2 ring-transparent transition-all shadow peer-checked:text-gray-800 peer-checked:ring-gray-800 peer-checked:ring-offset-2">
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between">
                <p className="text-sm font-semibold uppercase text-gray-500">{selectedMembership.name}</p>
                <svg className="" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="currentColor" d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z" />
                </svg>
              </div>
              <p className="mt-0.5 text-sm text-gray-500">{selectedMembership.description}</p>
              <div className="flex items-end justify-between">
                <p><span className="text-lg font-bold">${selectedMembership.PriceInDollars}</span> / {selectedMembership.renewal_intervals.slice(0, -2)}</p>
                <p className="text-sm font-bold" style={{ fontVariant: 'small-caps' }}>{selectedMembership.PriceInSatoshis} sats / {selectedMembership.renewal_intervals.slice(0, -2)}</p>
              </div>
            </div>
          </div>
        </label>
      </div>

      {/* Email/DM Message */}
      {orderDetails.email && (
        <p className="text-sm text-center text-gray-600 mt-4">You will have received an email with your payment details.</p>
      )}
      {orderDetails.npub && (
        <p className="text-sm text-center text-gray-600 mt-4">You will have received a nostr DM with your payment details.</p>
      )}

      {/* Get people to go to Flash */}
      <p className="text-sm text-center text-gray-600 mt-4">
        You can manage your subscription
        <a href={manageSubscriptionPageUrl} style={{ fontWeight: 'bold' }}> here </a>
      </p>
    </div>
  );
};

export default PaymentSuccessScreen;
