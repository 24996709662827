import React, { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { nip04 } from 'nostr-tools';
import cn from 'classnames';

import WalletConnectButton from '../payWithFlash/WalletConnectButton'

const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



export const fetchEncryptedContent = async (userPublicKey, propertiesToDecrypt) => {
    try {
      const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/encrypt_content`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userPublicKey: userPublicKey,
          propertiesToDecrypt: propertiesToDecrypt,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.properties;
    } catch (error) {
      console.error('Error in fetchEncryptedContent:', error);
      throw error;
    }
  };




export default function SelectWallet({ appName, publicKey, imageUrl, wallets, userData, setWallets, userPrivateKey, getUserWallets, onSelectWallet, onBack }) {

    const [selectedWallet, setSelectedWallet, onClose] = useState ({});
    const[loading, setLoading] = useState (false);
    const [flashLogo, setFlashLogo] = useState("/flash-credit-1.png"); // Initial logo

    // Function to handle plan selection
    const handleSelectWallet = (wallet) => {
        if (JSON.stringify(wallet) === '{}') return ;
        onSelectWallet(wallet);
    };


    // Callback function to be called from WalletConnectButton
    const handleWalletConnected = async (appName, nwcString) => {
        // Here you can handle the received values

        try {
            // Call function to get the wallet name based on the NWCUrl
            const providerName = await getWalletNameFromNWCString(nwcString);

            await saveWalletToDB(providerName, appName, nwcString)

            // Get the user Wallets and set to state variable
            const userWallets = await getUserWallets(userPrivateKey, publicKey)
            
            if (userWallets) {
                // Update the wallets state with the new list
                setWallets(userWallets);
            } else {
                // Handle case when no wallets are returned
                console.error('No wallets returned after adding new wallet.');
            }
        } catch (error) {
            console.error('Error saving new wallet:', error);
        }
    };


    const getWalletNameFromNWCString = async (nwcString) => {
        const walletsAvailable = ["Alby", "Mutiny"];
        // Convert nwcString to lowercase for case-insensitive comparison
        const lowerCaseNWCString = nwcString.toLowerCase();
    
        // Iterating over each wallet name to see if it's present in the nwcString
        for (const walletName of walletsAvailable) {
            // Also convert walletName to lowercase
            if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
                return walletName;
            }
        }
    
        // Return "Custom" if no match is found
        return "Custom";
    };

    // Function to be able to add new wallets
    async function saveWalletToDB(selectedWalletName, userWalletName, walletNwcUrl) {
        // Encrypt the nwcUrl
        let encrypted_nwc_url;
        if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
            encrypted_nwc_url = await fetchEncryptedContent(userData.userPublicKey, walletNwcUrl);
        } else {
            encrypted_nwc_url = "";
        }

        const formData = new FormData();
        formData.append('user_public_key', userData.userPublicKey);
        formData.append('wallet_name', selectedWalletName);
        formData.append('ln_address', "");
        formData.append('user_wallet_name', userWalletName);
        formData.append('nwc_url', encrypted_nwc_url);
    
        try {
            const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
                method: 'POST',
                body: formData
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();

            return data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }

    // Check if plans is truthy and an array before rendering
    if (!Array.isArray(wallets) || !wallets) {
        return <div>No wallets available or wallets data is not valid.</div>;
    }

    return (
        <>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
                </div>
            )}
            <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
                <div className="flex shrink-0 items-center justify-center">
                    <img
                        className="w-full h-24 mb-4 object-cover rounded-t-sm rounded-b-none" // Tailwind CSS for width and margin bottom
                        src={imageUrl}
                        alt="Image"
                    />
                    {/* Overlay label positioned absolutely within the parent div */}
                    <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
                        {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
                    </div>
                </div>

                <div className="flex shrink-0 items-center justify-center">
                    <img
                        className="w-20 h-20 mb-3 object-cover rounded-full border-4 border-white -mt-12 z-20" // Tailwind CSS for width, margin bottom, and z-index
                        src={imageUrl}
                        alt="Image"
                    />
                </div>


                {/* <div className=" flex text-center justify-center items-center">
        <div className="text-center w-fit bg-orange-100  rounded-md px-2 py-1 text-xs font-medium mb-3 text-orange-600">Subscribe in Sats ₿</div>
        </div> */}
                <h2 className="text-center text-2xl font-bold mb-0 text-black">
                    {appName}
                </h2>
                <div className="px-4 ">
                    {wallets.length != 0 &&
                        <p className="text-center text-md text-gray-600 mb-6 px-10">Select Your Wallet</p>
                    }
                    <div className="mt-5 mb-5">
                        <div className="space-y-6 px-2" >
                            <ul role="list" className="space-y-4 w-full">
                                {wallets.map((wallet) => (
                                    <li key={wallet.id} className={cn({ "ring-purple-700": wallet.id == selectedWallet.id, "ring-slate-200": wallet.id != selectedWallet.id }, "ml-4 mb-4 mt-4 mr-4 cursor-pointer bg-white/80 text-gray-800 rounded-lg ring hover:ring-purple-600 shadow overflow-hidden")}>
                                        <div className="flex flex-row justify-start items-center p-4" onClick={() => { setSelectedWallet(wallet) }}>
                                            <img
                                                src={wallet.wallet_logo_url}
                                                className="h-10 w-10 rounded-full object-cover mr-4"
                                            />
                                            <div className="flex flex-col">
                                                <p className="text-md font-semibold text-slate-700">{wallet.user_wallet_name}</p>
                                                <p className="text-md text-gray-500 truncate font-semibold flex-grow">
                                                    Balance: {Math.floor(wallet.balance / 1000).toLocaleString()} sats
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <WalletConnectButton onWalletConnected={handleWalletConnected} />
                
                    <p className='mt-1 mb-5 mx-5 text-sm text-indigo-900'>Don't have a wallet? Setup in 2 min with{' '}
                        <a href="https://getalby.com/invited-by/pierrecorbin" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-600 hover:text-blue-800 visited:text-purple-600 underline">
                            our partner
                        </a>
                    </p>

                    {wallets.length != 0 &&
                        <div className="mt-3 mx-5 mb-3">
                            <button
                                type="button"
                                onClick={() => { handleSelectWallet(selectedWallet) }}
                                className="flex items-center w-full text-md justify-center rounded-lg bg-purple-700 px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
                            >
                                Continue
                            </button>
                        </div>
                    }

                    <button
                        className="flex items-center w-full text-md justify-center rounded-lg bg-white  px-3 py-3 text-sm font-semibold leading-6 text-purple-600 hover:text-purple-800 focus:outline-none focus:ring-0 focus:ring-purple-800  custom-button2"
                        onClick={onBack}
                    >
                        Back to Plan Selection
                    </button>
                    <p className="fixed w-full bottom-0 mt-4 pb-3 text-sm text-slate-400 font-medium text-center">

                        <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" className="mx-auto">
                            <img
                                src={flashLogo}
                                alt="Flash"
                                className="inline h-5 ml-1 align-middle" // Adjust size as needed
                                onMouseEnter={() => setFlashLogo("/flash-credit-2.png")} // Change logo on hover
                                onMouseLeave={() => setFlashLogo("/flash-credit-1.png")} // Revert logo on mouse leave
                            />
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
    
    
}
