import React, { useState, useEffect } from 'react';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';

const LnInvoiceHashDisplayAndCopy = ({ value }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the icon after 2 seconds
    };

    return (
        <div className="relative flex items-center rounded-md ">
            <input
                type="text"
                value={value}
                readOnly
                className="block w-full   mr-2 border-slate-300 px-3 rounded-lg bg-gray-50  text-gray-700 shadow-sm focus:outline-none sm:text-sm sm:leading-6 flashwidget-input"
            />
            <button 
                onClick={copyToClipboard}
                type="button"
                className="bg-white border border-slate-200 text-gray-600 hover:text-gray-800 p-2.5 rounded-lg focus:outline-none"
                style={{ height: '100%' }} // Ensure the button stretches to the full height of the container
            >
                {copied ? <CheckIcon className="h-5 w-5" aria-hidden="true" /> : <ClipboardIcon className="h-5 w-5" aria-hidden="true" />}
            </button>
        </div>
    );
};

export default LnInvoiceHashDisplayAndCopy;
