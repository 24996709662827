import React, { useState } from 'react';
import { ArrowLongLeftIcon, PlusIcon } from '@heroicons/react/24/outline';

import SidebarLayout from '../components/SidebarLayout';
import UploadVectorstoreFromGlobalNests from '../components/UploadVectorstoreFromGlobalNests';
import GetPublicVectorstores from '../components/GetPublicVectorstores';

function VectorEggs() {
  const [activeSection, setActiveSection] = useState('view'); // Default to 'view'

  const handleUploadCancel = (newStatus) => {
    setActiveSection(newStatus)
  }

  return (
    <div className="min-h-screen">
      <SidebarLayout title="Vector Databases" subtitle="View the NostrEggs vector database library here">
        <div className="flex h-full items-stretch nest-list">

          {/* Right Column for Content */}
          <div className="">
            {activeSection === 'create' && (
              <section className="mb-4">
                <div className="mx-auto w-full rounded-md mb-2 flex justify-between"> {/* Add flex container */}
                  <button
                    onClick={() => setActiveSection('view')}
                    className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Back
                  </button>
                </div>
                
                <div className="mx-auto w-full bg-white rounded-md p-4">
                  <h1 className="text-base font-medium text-gray-900">Create a Vector Database</h1>
                  <UploadVectorstoreFromGlobalNests 
                    on_upload_cancel={handleUploadCancel}
                  />
                </div>
              </section>
            )}

            {activeSection === 'view' && (
              <section className="mb-4">
                <div className="mx-auto w-full rounded-md text-right"> {/* Separate the button from the container below */}
                </div>

                <div className="mx-auto w-full bg-white rounded-md">
                  <GetPublicVectorstores onActivateCreate={() => setActiveSection('create')} />
                </div>
              </section>
            )}
          </div>

        </div>
      </SidebarLayout>
    </div>
  );
}

export default VectorEggs;
