import React from 'react';

const DetailCard = ({ sheetData, player }) => {
    if (!player) {
        return <div>No player data available.</div>;
    }

    // Sort the sheetData based on points and remove the first item if necessary
    const sortedData = (Array.isArray(sheetData) ? sheetData.sort((a, b) => b.points - a.points) : []);

    // Find the player data in the sorted sheetData array
    const playerData = sortedData.find(item => item.email === player);

    // Calculate the position, adding 1 because arrays are zero-indexed
    const position = playerData ? sortedData.indexOf(playerData) + 1 : 'Unknown';

    if (!playerData) {
        return <div>No data found for this player.</div>;
    }

    // Render player details with position
    return (
        <div className="flex flex-col items-left justify-center bg-gray-800">
            <div className="grid grid-cols-1 md:grid-cols-4 p-4">
                <div className="stat flex flex-col p-3 rounded-lg">
                    <div className="stat-title text-sm text-gray-300">Position</div>
                    <div className="stat-value text-2xl text-blue-300 break-words">{position}</div>
                </div>

                <div className="stat flex flex-col p-3 rounded-lg">
                    <div className="stat-title text-sm text-gray-300">Name</div>
                    <div className="stat-value text-2xl text-blue-300 break-words">{playerData.firstName}</div>
                </div>

                <div className="stat flex flex-col p-3 rounded-lg">
                    <div className="stat-title text-sm text-gray-300">Points</div>
                    <div className="stat-value text-2xl text-blue-300 break-words">{playerData.total_points}</div>
                </div>
                <div className="stat flex flex-col p-3 rounded-lg">
                    <div className="stat-title text-sm text-gray-300">Team</div>
                    <div className="stat-value text-2xl text-blue-300 break-words">{playerData.team}</div>
                </div>
            </div>
        </div>
    );
};

export default DetailCard;
