import React, { useState, useMemo, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import DateRangePicker from '../DateRangePicker';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TransactionsTable = ({ initialData, filtersToShow = [], columnsToShow = [] }) => {
  const [selectedFlashes, setSelectedFlashes] = useState(new Set());
  const [selectedWallets, setSelectedWallets] = useState(new Set());
  const [selectedTypes, setSelectedTypes] = useState(new Set());
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  const flashNames = ["All Streams", ...new Set(initialData.map(transaction => transaction.flash_name))];
  const flashWallets = ["All Wallets", ...new Set(initialData.map(transaction => transaction.to_user_wallet_name))];
  const flashTypes = ["All Types", ...new Set(initialData.map(transaction => transaction.flash_type))];

  const filteredData = useMemo(() => {
    const filtered = initialData.filter(transaction => {
      const flashNameFilter = selectedFlashes.size === 0 || selectedFlashes.has(transaction.flash_name);
      const walletFilter = selectedWallets.size === 0 || selectedWallets.has(transaction.to_user_wallet_name);
      const typeFilter = selectedTypes.size === 0 || selectedTypes.has(transaction.flash_type);

      const transactionDate = new Date(transaction.transaction_date);
      const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;
      const dateFilter = (!startDate || transactionDate >= startDate) &&
                         (!endDate || transactionDate <= endDate);

      return flashNameFilter && walletFilter && typeFilter && dateFilter;
    });

    // Sorting the filtered data from most recent to most ancient
    filtered.sort((a, b) => {
      const dateA = new Date(a.transaction_date);
      const dateB = new Date(b.transaction_date);
      return dateB - dateA; // Descending order
    });

    return filtered;
  }, [initialData, selectedFlashes, selectedWallets, selectedTypes, dateRange]);

  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredData, currentPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const formatDateRange = () => {
    if (!dateRange.startDate && !dateRange.endDate) {
      return 'Date range';
    }
    const start = dateRange.startDate ? new Date(dateRange.startDate).toLocaleDateString() : '...';
    const end = dateRange.endDate ? new Date(dateRange.endDate).toLocaleDateString() : '...';
    return `From ${start} To ${end}`;
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const shouldShowFilter = (filterName) => filtersToShow.includes(filterName);
  const shouldShowColumn = (columnName) => columnsToShow.includes(columnName);

  const handleSelection = (selectedSet, value, allValue) => {
    const newSet = new Set(selectedSet);
    if (value === allValue) {
      newSet.clear();
    } else if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
    return newSet;
  };

  return (
    <div className="">
      <div className="flex flex-col   mb-4">
        <h3 className="text-3xl mb-1 font-semibold text-slate-800">All Transactions</h3>
        <p className="mb-2 text-slate-600 opacity-80 -mb-2">The list of transactions to your gateways.</p>
       
      </div>

      <div className="grid grid-cols-4 gap-4 w-full">
        <FilterListbox
          items={flashNames}
          selectedSet={selectedFlashes}
          label="Streams"
          allValue="All Streams"
          handleSelection={(value) => {
            const newSet = handleSelection(selectedFlashes, value, "All Streams");
            setSelectedFlashes(newSet);
          }}
        />
        <FilterListbox
          items={flashTypes}
          selectedSet={selectedTypes}
          label="Types"
          allValue="All Types"
          handleSelection={(value) => {
            const newSet = handleSelection(selectedTypes, value, "All Types");
            setSelectedTypes(newSet);
          }}
        />
        <FilterListbox
          items={flashWallets}
          selectedSet={selectedWallets}
          label="Wallets"
          allValue="All Wallets"
          handleSelection={(value) => {
            const newSet = handleSelection(selectedWallets, value, "All Wallets");
            setSelectedWallets(newSet);
          }}
        />
        <div className="w-full">
          <div className="relative mt-1 w-full">
            <button className="inline-flex w-full justify-between rounded-md bg-white px-3 py-3 text-sm font-medium text-slate-900 rounded-xl border border-slate-200" onClick={toggleDatePicker}>
              {formatDateRange()}
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
            <Transition
              as={Fragment}
              show={showDatePicker}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="absolute z-10 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <DateRangePicker onDateChange={handleDateRangeChange} />
              </div>
            </Transition>
          </div>
        </div>
      </div>

      <div className="mt-2 z-50"></div>

      <div className="mt-6 rounded-md overflow-hidden shadow-sm">
        <div className="overflow-x-auto">
          <table className="w-full table-fixed divide-y divide-gray-300">
            <thead className="bg-slate-700">
              <tr>
                <th scope="col" className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap">ID</th>
                <th scope="col" className="w-2/12 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap">Date & Time</th>
                <th scope="col" className="w-2/12 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap">Flash Name</th>
                <th scope="col" className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap">Flash Type</th>
                <th scope="col" className="w-1/12 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap">Wallet</th>
                <th scope="col" className="w-2/12 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap truncate">LNurl</th>
                <th scope="col" className="w-8 px-2 py-3 text-left text-xs font-semibold text-white whitespace-nowrap">Amount in Sats</th>
                <th scope="col" className="w-8 px-2 py-3 mr-2 text-left text-xs font-semibold text-white whitespace-nowrap">Amount in USD</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {currentData.map((transaction, index) => (
                <tr key={index} className="hover:bg-gray-100" style={{ maxHeight: '40px' }}>
                  <td className="px-2 py-2 text-sm text-slate-700">{transaction.id}</td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-slate-700 opacity-70">
                    {new Date(transaction.transaction_date).toLocaleString()}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-slate-900 opacity-90 truncate">{transaction.flash_name}</td>
                  <td className="px-2 py-2 text-sm text-slate-700 opacity-70 line-clamp-1 truncate">{transaction.flash_type}</td>
                  <td className="px-2 py-2 text-sm text-slate-700 opacity-70">{transaction.to_user_wallet_name}</td>
                  <td className="px-2 py-2 text-sm text-slate-700 opacity-70">{transaction.lnurl.slice(0, 20) + "..."}</td>
                  <td className="px-2 py-2 text-sm text-slate-900 opacity-90">{Number(transaction.amount_in_sats).toLocaleString()} sats</td>
                  <td className="px-2 py-2 text-sm text-slate-900 opacity-90">
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(transaction.amount_in_usd)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          className="px-4 py-2 bg-slate-800 text-white rounded-md"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <span className="text-sm text-slate-700">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="px-4 py-2 bg-slate-800 text-white rounded-md"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const FilterListbox = ({ items, selectedSet, label, allValue, handleSelection }) => {
  return (
    <div className="w-full">
      <Listbox as="div" value={Array.from(selectedSet)} onChange={handleSelection}>
        <div className="relative mt-1">
          <Listbox.Button className="inline-flex w-full justify-between rounded-md bg-white px-3 py-3 text-sm font-medium text-slate-900 rounded-xl border border-slate-200">
            {selectedSet.size === 0 ? allValue : Array.from(selectedSet).join(', ')}
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Listbox.Button>
          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((item, idx) => (
              <Listbox.Option
                key={idx}
                value={item}
                className={({ active }) =>
                  classNames(
                    'cursor-pointer select-none relative py-2 pl-3 pr-9',
                    selectedSet.has(item) ? 'bg-indigo-600 text-white' : active ? 'bg-indigo-50' : 'text-gray-900'
                  )
                }
              >
                <>
                  <span className="block truncate">{item}</span>
                  {selectedSet.has(item) && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};

export default TransactionsTable;
