import React, { useState } from 'react';
import PayWithFlashModal from './PayWithFlashModal';

const PayWithFlashButton = ({ flashDetails, onPaymentSuccess, payerSelectedWallet, setPayerSelectedWallet, payerUserPublicKey, setPayerUserPublicKey }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <button 
        className="text-md text-sm text-white px-4 py-3 font-bold rounded cursor-pointer w-full flex justify-center items-center"
        onClick={openModal}
        style={{
          backgroundImage: 'linear-gradient(to left, #6a0dad, #7b68ee)', // default gradient
          border: 'none'
        }}
        onMouseEnter={e => e.currentTarget.style.backgroundImage = 'linear-gradient(to left, #551a8b, #6a5acd)'} // purple gradient on hover
        onMouseLeave={e => e.currentTarget.style.backgroundImage = 'linear-gradient(to left, #6a0dad, #7b68ee)'} // revert to default gradient
      >
        Pay with Flash
        <img src="https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png" alt="Icon" className="h-4 w-4 ml-2" />
      </button>

      {modalOpen && <PayWithFlashModal flashDetails={flashDetails} onPaymentSuccess={onPaymentSuccess} closeModal={closeModal} selectedWallet={payerSelectedWallet} setSelectedWallet={setPayerSelectedWallet} userPublicKey={payerUserPublicKey} setUserPublicKey={setPayerUserPublicKey} />}
    </>
  );
};

export default PayWithFlashButton;
