import React, { useState, useEffect } from 'react';
import { nip04 } from 'nostr-tools';
import { TextInput, FormHeader, PaymentInfo, CancelButton, ConfirmButton, SimpleDropdown, WalletDropdown, CurrencyDropdown, LifetimeDurationDropdown, IconDropdown, NumberInputWithConverter } from '../comp/InputsLib.js';
import * as Utils from '../utils.js';
import {
  SummaryTitle, SummaryPrice, SummaryWallet, SummaryDuration, SummaryUser, SummarySnippet, SummaryCreationDate, SummaryCopyButton, SummaryFooter
} from '../comp/SummaryFields.js';

const Form1 = ({ close }) => {
  // Get env var
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY

  // Get the values from session storage
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  let userAbout = localStorage.getItem('userAbout') || "";

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('Satoshis');
  const [duration, setDuration] = useState('1 day');
  const [expiryDate, setExpiryDate] = useState();
  const [wallet, setWallet] = useState('Alby');
  const user = userName;
  const [convertedValue, setConvertedValue] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  // Add state for NWCs
  const [nwcs, setNwcs] = useState([]);
  const [selectedNwcId, setSelectedNwcId] = useState('');
  const [flashNwc, setFlashNwc] = useState('');
  const [flashMemo, setFlashMemo] = useState('');
  const [snippet, setSnippet] = useState('');


  // Effect hook to get the current date in right format for display
  function formatDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
    }

    return `${month} ${day}${suffix(day)} ${year}`;
  }
  useEffect(() => {
    const currentDate = new Date();
    setFormattedDate(formatDate(currentDate));
  }, []);

  // Effect hook to directly set the exchange rate of sats to usd
  useEffect(() => {
    let isMounted = true;

    const fetchConvertedValue = async () => {
      const value = await Utils.convertCurrency(price, currency);
      if (isMounted) {
        setConvertedValue(value);
      }
    };

    fetchConvertedValue();

    return () => {
      isMounted = false;
    };
  },);

  // Effect hook to set the expiryDate of the Flash when the user changes the duration
  useEffect(() => {
    if (duration === 'lifetime') {
      // Option 1: Set a far future date for 'lifetime'
      const farFuture = new Date();
      farFuture.setFullYear(farFuture.getFullYear() + 100); // 100 years in the future
      setExpiryDate(farFuture);
  
      // Option 2: Handle 'lifetime' differently
      // setExpiryDate(null); // If your application logic can handle null as 'lifetime'
    } else {
      // Calculate the expiry date based on the selected duration
      const durationInDays = parseInt(duration.split(' ')[0]); // This will parse the numeric part from the duration string
      const newExpiryDate = new Date();
      newExpiryDate.setDate(newExpiryDate.getDate() + durationInDays); // Add the duration to the current date
      setExpiryDate(newExpiryDate); // Set the new date as the expiry date
    }
  }, [duration]); // This effect runs whenever the duration state changes


  // Effect hook to fetch NWCs when the component mounts
  useEffect(() => {
    const fetchNWCs = async () => {
      try {
        const response = await fetch(`https://api.paywithflash.com/api/get_user_nwcs?user_public_key=${encodeURIComponent(userHexPublicKey)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        const results = [];
  
        for (const item of data) {
          const decrypted_nwc_url = await nip04.decrypt(userHexPrivateKey, publicKey, item.nwc_url);
          results.push({
            id: item.id,
            user_publicKey: item.user_public_key,
            nwc_url: decrypted_nwc_url,
            nwc_name: item.nwc_name
          });
        }
  
        setNwcs(results);
  
        // Set the first NWC as the default wallet and its ID
        if (results.length > 0) {
          setWallet(results[0].nwc_name);
          setFlashNwc(results[0].nwc_url);
          setSelectedNwcId(results[0].id); // Set the ID of the first NWC
        }
      } catch (error) {
        console.error('Error fetching NWCs:', error);
      }
    };
  
    fetchNWCs();
  }, [userHexPrivateKey, userHexPublicKey, publicKey]); // Add dependencies if needed

  const handleConfirm = () => {
    // Logic for what happens when 'Confirm' is clicked
  };
  
  const handleCopy = () => {
    // Logic for what happens when 'Copy' is clicked
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-4 modal-base">
      <div className="flex-1 p-14">

        <FormHeader
          title="Create a Payment Link"
          subtitle="Accept Bitcoin payments through Lightning with a simple link. Share it to anyone, pause or delete anytime."
          infoBadge={{
            text: "Learn more about Payment links",
            color: "bg-gray-100 text-gray-600"
            }}
          newEggBadge={{
            text: "New Flash",
            color: "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-600/20 mb-3"
            }}
        />

        <div className="inputform">
          <TextInput label="Title" placeholder="Enter the title" value={title} onChange={handleTitleChange} />
        </div>
        
        <div className="inputform">
          <NumberInputWithConverter label="Price" name="price" id="price" placeholder="Enter the price" value={price} onChange={(e) => setPrice(e.target.value)} convertedValue={convertedValue} />
        </div>

        <div className="inputform">
          <CurrencyDropdown label="Currency" id="currency" name="currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
        </div>

        <div className="inputform">
          <WalletDropdown label="Wallet" id="wallet" name="wallet" nwcs={nwcs} value={flashNwc} onChange={(e) => setWallet(e.target.value)} />
        </div>

        <div className="inputform">
          <LifetimeDurationDropdown label="Flash Duration" id="flash-duration" name="flash-duration" value={duration} onChange={(e) => setDuration(e.target.value)} />
        </div>

        <div className="inputform">
          <ConfirmButton onClick={handleConfirm}> Build payment link </ConfirmButton>
          <CancelButton onClose={close} />
        </div>
        
        <div className="inputform">
          <PaymentInfo text="Payments will go directly to your connected wallet, through Flash." />
        </div>

      </div>
      <div className="flex-1 bg-gray-100">
        {/* You can add content here if needed */}
        
        {/* Summary Column */}
        <div className="flex-1 bg-gray-100 p-14">
            <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-8">
                <SummaryTitle title={title || 'Flash Title'} />
                <SummaryCreationDate creationDate={formattedDate} />
                <SummaryPrice price={price} currency={currency} convertedValue={convertedValue} />
                <SummaryWallet wallet={wallet} />
                <SummaryDuration duration={duration} />
                <SummaryUser user={user} />
                <SummarySnippet snippet={snippet} /> {/* Remove if not needed */}
                <SummaryCopyButton text="Copy snippet" onClick={handleCopy} />
                <SummaryFooter />
            </div>
        </div>
      </div>
    </div>
  );
};

export default Form1;
