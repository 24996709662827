import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';

function GetUserPublicVectorstores() {
    // Get the values from session storage
    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    let userNsec = localStorage.getItem('userNsec') || "";
    let userNpub = localStorage.getItem('userNpub') || "";

    const [eventList, setEventList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEventList, setFilteredEventList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    let navigate = useNavigate(); // Declare the navigate function

    useEffect(() => {
        const createdByValue = userNpub;

        fetch(`https://api.paywithflash.com/api/get_public_vectorstores?created_by=${encodeURIComponent(createdByValue)}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setEventList(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching the data', error);
            });
    }, []);

    useEffect(() => {
        const filtered = eventList.filter(vectorstore =>
            vectorstore.vectorstoreName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            vectorstore.vectorstoreID.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredEventList(filtered);
    }, [eventList, searchTerm]);

    const handleViewVectorstore = (vectorstoreID) => {
        navigate(`/vectorstore-detail-view?vectorstoreID=${vectorstoreID}&origin=user`); // Origin set to user so we know where to redirect to
    };

    return (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mb-4">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <div className="px-4 py-6 sm:px-6">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Public Vector databases</h2>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Here is the list of your public vector databases:</p>
                    <input 
                        type="text" 
                        placeholder="Search by name or ID..." 
                        value={searchTerm} 
                        onChange={e => setSearchTerm(e.target.value)} 
                        className="p-2 border text-sm rounded focus-within:ring-purple-800"
                    />
                </div>
                <div className="border-t border-gray-100">
                    <ul role="list" className="grid grid-cols-1 lg:grid-cols-3">

                        {isLoading ? (
                            Array.from({ length: 2 }).map((_, index) => (
                                <div key={index} className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <div className="animate-pulse">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                    </div>
                                    <div className="mt-6 animate-pulse sm:col-span-2">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                    </div>
                                    <div className="mt-6 animate-pulse">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                    </div>
                                    <div className="mt-6 animate-pulse sm:col-span-2">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                    </div>
                                    <div className="mt-6 animate-pulse">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                    </div>
                                    <div className="mt-6 animate-pulse sm:col-span-2">
                                        <div className="h-4 bg-gray-300 rounded"></div>
                                    </div>
                                </div>
                            ))
                            ) : filteredEventList.length > 0 ? (
                                filteredEventList.map((vectorstore) => (
                                    <li key={vectorstore.vectorstoreID} className="overflow-hidden rounded-xl border m-4"> {/* Adjusted width here */}
                                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                            {/* Assuming vectorstore.imageUrl for the image */}
                                            <img
                                                src={"https://nostreggs.io/wp-content/uploads/2023/09/Design-sans-titre-58.png"} 
                                                alt={vectorstore.vectorstoreName}
                                                className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                                            />
                                            <div className="text-sm font-medium leading-6 text-gray-900">{vectorstore.vectorstoreName}</div>
                                
                                            {/* Replaced Menu with direct View button */}
                                            <button 
                                                onClick={() => handleViewVectorstore(vectorstore.vectorstoreID)}
                                                className="-m-2.5 block p-2.5 text-xs text-gray-400 hover:text-gray-500"
                                            >
                                                View
                                            </button>
                                        </div>
                                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-xs leading-6">
                                            {/* Add details as required */}
                                            <div className="flex justify-left gap-x-2 py-1">
                                                <dt className="text-gray-500"><strong>Desc:</strong></dt>
                                                <dd className="text-gray-700 line-clamp-3">{vectorstore.vectorstore_description}</dd>
                                            </div>
                                            <div className="flex justify-left gap-x-2 py-1">
                                                <dt className="text-gray-500"><strong>Price:</strong></dt>
                                                <dd className="text-gray-700">{vectorstore.vectorstore_price} sats</dd>
                                            </div>
                                            <div className="flex justify-left gap-x-2 py-1">
                                                <dt className="text-gray-500"><strong>Owner:</strong></dt>
                                                <dd className="text-gray-700 line-clamp-1">{vectorstore.createdBy}</dd>
                                            </div>
                                        </dl>
                                    </li>
                                ))
                                
                            ) : (
                                <div className="px-4 py-6 text-center text-gray-500">No vectorstore found.</div>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
                    );
}

export default GetUserPublicVectorstores;
