import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoginWithEmail from './LoginWithEmail';
import LoginWithNostr from './LoginWithNostr';
import SignUpWithEmail from './SignUpWithEmail';
import SignUpWithNostr from './SignUpWithNostr';

function ExternalAppLogin(props) {
  const { appName, imageUrl, onLoginSuccess, onClose } = props;

  const [useEmailLogin, setUseEmailLogin] = useState(true); // true for email, false for Nostr
  const [viewingSignup, setViewingSignup] = useState(true); // false for login, true for signup
  const [flashLogo, setFlashLogo] = useState("/flash-credit-1.png"); // Initial logo

  const navigate = useNavigate();

  const handleResetPasswordClick = () => {
    navigate('/password-reset-request');
  };

  const toggleLoginMethod = () => {
    setUseEmailLogin(!useEmailLogin);
  };

  const toggleSignupView = () => {
    setViewingSignup(!viewingSignup);
  };

  return (
    <div className="">
      <div className="flex-1 flex flex-col justify-center p-0 text-white md:w-1/1 bg-white">
        <div className="text-left">
          {!viewingSignup ? (
            <>
              {useEmailLogin ? (
                <LoginWithEmail appName={appName} imageUrl={imageUrl} onLoginSuccess={onLoginSuccess} onClose={onClose} />
              ) : (
                <LoginWithNostr appName={appName} imageUrl={imageUrl} onLoginSuccess={onLoginSuccess} onClose={onClose} />
              )}
              <div className="flex w-full px-10 mt-3 text-slate-400 text-center justify-center items-center">
                <button onClick={toggleLoginMethod} className="flex items-center w-full text-md justify-center rounded-lg bg-purple-100 px-3 py-3 text-sm font-semibold leading-6 text-purple-600 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2">
                  {useEmailLogin ? 'Log in with Nostr' : 'Log in with Email'}
                </button>
              </div>
              <p className="ml-4 mt-5 text-sm text-slate-400 text-center">
                Don't have an account?{' '}
                <button onClick={toggleSignupView} className="font-semibold leading-6 text-purple-500 hover:text-purple-400 focus:outline-none">
                  Sign up
                </button>
              </p>
              {useEmailLogin && (
                <p className="ml-4 mt-5 text-sm text-slate-400 text-center">
                  <button onClick={handleResetPasswordClick} className="text-sm text-purple-500 hover:text-purple-400 focus:outline-none">
                    Forgot your password? Reset it.
                  </button>
                </p>
              )}

              <p className="fixed w-full bottom-0 mt-4 pb-3 text-sm text-slate-400 font-medium text-center">

                <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" className="mx-auto">
                  <img
                    src={flashLogo}
                    alt="Flash"
                    className="inline h-5 ml-1 align-middle" // Adjust size as needed
                    onMouseEnter={() => setFlashLogo("/flash-credit-2.png")} // Change logo on hover
                    onMouseLeave={() => setFlashLogo("/flash-credit-1.png")} // Revert logo on mouse leave
                  />
                </a>
              </p>
            </>
          ) : (
            <>
              {useEmailLogin ? (
                <SignUpWithEmail appName={appName} imageUrl={imageUrl} onLoginSuccess={onLoginSuccess} onClose={onClose} />
              ) : (
                <SignUpWithNostr appName={appName} imageUrl={imageUrl} onLoginSuccess={onLoginSuccess} onClose={onClose} />
              )}

              <div className="flex w-full px-10 mt-3 text-slate-400 text-center justify-center items-center">
                <button onClick={toggleLoginMethod} className="flex items-center w-full text-md justify-center rounded-lg bg-purple-100 px-3 py-3 text-sm font-semibold leading-6 text-purple-600 hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2">
                  {useEmailLogin ? (
                    <>
                      <img src="/nostr-logo.png" alt="Nostr logo" className="mr-2 h-5 w-5" />
                      Sign up with Nostr
                    </>
                  ) : (
                    'Sign up with email'
                  )}
                </button>
              </div>

              <p className="ml-4 mt-3 text-md text-slate-400 text-center">
                Already have an account?{' '}
                <button onClick={toggleSignupView} className="font-semibold leading-6 text-purple-600 hover:text-purple-400 focus:outline-none">
                  Login
                </button>
              </p>

              <p className="sticky w-full bottom-0 mt-4 pb-3 text-sm text-slate-400 font-medium text-center">

                <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" className="mx-auto">
                  <img
                    src={flashLogo}
                    alt="Flash"
                    className="inline h-5 ml-1 align-middle" // Adjust size as needed
                    onMouseEnter={() => setFlashLogo("/flash-credit-2.png")} // Change logo on hover
                    onMouseLeave={() => setFlashLogo("/flash-credit-1.png")} // Revert logo on mouse leave
                  />
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExternalAppLogin;
