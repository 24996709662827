import { React, useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

const PleaseVerify = () => {

  const location = useLocation ();

  const [email, setEmail] = useState (location.state.email);
  const [userId, setUserId] = useState (location.state.userId);
  const [isSendingSuccess, setIsSendingSuccess] = useState (false);

  const handleResendVerificationLink = async () => {

    const formData = new FormData ();
    formData.append ('user_id', userId);
    formData.append ('email', email);

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    const apiUrl = `https://api.paywithflash.com/api/resend_verification_link`;

    try {
      const response = await fetch (apiUrl, requestOptions);

      if (!response.ok) {
        console.error ('An error in resending the verification link');
        throw new Error (`An error in resending the verification link: ${response.status}`);
      }

      const data = await response.json ();
      setIsSendingSuccess (data.success);

    } catch (error) {
      console.error ('An error in resending the verification link');
      throw new Error (`An error in resending the verification link: ${error}`);
    }
  }

  useEffect (() => {
    if (isSendingSuccess) {
      setTimeout (() => {
        setIsSendingSuccess (false);
      }, 3000);
    }
  }, [isSendingSuccess])

  const handleSubmit = (e) => {
    e.preventDefault ();
    return ;
  }

  return (
    <div className="flex items-center justify-center flex-col mt-5">
      <section className="max-w-2xl mx-auto bg-white">
        <header className="py-8 flex justify-center w-full">
          <a href="#">
            <img
              src="/flash-logo-dark.png"
              alt="Flash Logo"
            />
          </a>
        </header>
        <div className="h-[200px] bg-indigo-600 w-full text-white flex items-center justify-center flex-col gap-5">
          <div className="flex items-center gap-3">
            <div className="w-10 h-[1px] bg-white"></div>
            <EmailIcon />
            <div className="w-10 h-[1px] bg-white"></div>
          </div>
          <div className="flex flex-col gap-3">
            <div className="text-center text-sm sm:text-xl tracking-widest font-normal">
              THANKS FOR SIGNING UP!
            </div>
            <div className="text-xl sm:text-3xl tracking-wider font-bold capitalize">
              Verify your E-mail Address
            </div>
          </div>
        </div>
        <main className="mt-8 px-5 sm:px-10">
          <h2 className="text-gray-700 ">Hey, </h2>
          <p className="mt-2 leading-loose text-gray-600 ">
            Please check your email and select the link provided to verify your address.
          </p>
          <form onSubmit={handleSubmit} className="space-y-4 mt-2">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-900">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                placeholder="satoshi@nakamoto.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full rounded-md  bg-white/5 py-1.5 text-slate-900  border border-slate-200 ring-1 ring-inset ring-white/10  sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <button className=" px-6 py-2 mt-6 text-sm font-bold tracking-wider text-white capitalize transition-colors duration-300 transform bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-900 focus:ring-opacity-80" onClick = {handleResendVerificationLink}>
                resend verification link
              </button>
            </div>
            { isSendingSuccess && (<div className = "text-sm text-green-600"> Just sent again. Please check your email inbox.</div>)}
          </form>
          <p className="mt-8 text-gray-600">
            Thank you, <br />
            Flash Support Team
          </p>
        </main>
        <p className="text-gray-500  px-5 sm:px-10 mt-8">
          This email was sent from{" "}
          <a
            href="mailto:no-reply@paywithflash.com"
            className="text-[#365CCE] hover:underline"
            alt="no-reply@paywithflash.com"
            target="_blank"
          >
            no-reply@paywithflash.com.
          </a>
        </p>
        <footer className="mt-8">
          <div className="bg-indigo-600 py-5 text-white text-center">
            <p className="mt-3 ">
              © {new Date().getFullYear()} Paywithflash
            </p>
          </div>
        </footer>
      </section>
    </div>
  );
};
export default PleaseVerify;

const EmailIcon = () => {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
    </svg>
  );
};