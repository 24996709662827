import React, { useState } from 'react';

const GameInfo = ({ sheetData, player }) => {

    const [expanded, setExpanded] = useState(null); // This state will keep track of which game's rules are shown

    const toggleExpand = (id) => {
        setExpanded(expanded === id ? null : id); // Toggle expand/collapse
    };


    const games = [
        {
            "name": "Bowling",
            "image": "https://duplainchurch.org/wp-content/uploads/2016/04/Big-city-bowling-sandy-utah-background-medium.jpg",
            "time": "Thursday evening",
            "points": ["Min points: 1", "1st place: 100"],
            "rules": "Compete in teams to get the most points!"
        },
        {
            "name": "Pool",
            "image": "https://gameonfamily.com/wp-content/uploads/2015/10/Depositphotos_4734999_original.jpg",
            "time": "Thursday evening",
            "points": ["Min points: 5", "1st place team: 50"],
            "rules": "Try out pool and get the most points!"
        },
        {
            "name": "Darts",
            "image": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Darts_in_a_dartboard.jpg/1600px-Darts_in_a_dartboard.jpg",
            "time": "Thursday evening",
            "points": ["Min points: 1", "1st place team: 100"],
            "rules": "Compete in teams and get the most points!"
        },
        {
            "name": "Rock Paper Scissors",
            "image": "https://bitcoinfilmfest.com/wp-content/uploads/2024/04/Rock-paper-scissors.png",
            "time": "All weekend",
            "points": ["Each token: 1", "1st place: 100"],
            "rules": "Challenge someone else to Rock Paper Scissors and earn his tokens. The person with the most tokens wins!"
        },
        {
            "name": "Zapybird",
            "image": "https://i.pcmag.com/imagery/reviews/06fBcC3YpdFj7i0VvkWspTj-1.fit_lim.size_885x1444.v_1569469985.jpg",
            "time": "All weekend",
            "points": ["Min points: 3", "1st place: 100"],
            "rules": "Get the best score at Zapybird and score the most points!"
        },
        {
            "name": "Satsman",
            "image": "https://images.nintendolife.com/5f38d0c6b4ff2/img6982.large.jpg",
            "time": "All weekend",
            "points": ["Min points: 3", "1st place: 100"],
            "rules": "Get the best score at Satsman and score the most points!"
        },
        {
            "name": "Chain duel",
            "image": "https://bitcoinfilmfest.com/wp-content/uploads/2024/04/ChainDuelHC-1.png",
            "time": "All weekend",
            "points": ["Min points: 5", "1st place: 100"],
            "rules": "Get the best score at Chain Duel and score the most points!"
        },
        {
            "name": "Buy at Shoppingbit Stand",
            "image": "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/shopinbit_bff-image.jpeg",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Buy something at the Shopinbit stand and get points!"
        },
        {
            "name": "Bring Tokens from RPS Game",
            "image": "https://www.bmfgintl.com/wp-content/uploads/2020/09/PCD01_Blue-600x600.jpg",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Bring some of the Rock Paper Scissors tokens and get points!"
        },
        {
            "name": "Order a Song from the Jukebox",
            "image": "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiHTHgmR8dpDALBEKU-c_1_8MnT-dgjUSa4MdQP0hswoezyV9aa1w1Q4zOU3Ghg6olwwxm9f98X9GZjffqefEK_pi1ao8JOpr01AkgHTs26s_jKQwI6rGdu5pk601QPlaJLi7rMxAa4q1yd/s1600/jukebox_1072.jpg",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Order a song from the Jukebox and get points!"
        },
        {
            "name": "Prove a Half Marathon Walk",
            "image": "https://cdn.galleries.smcloud.net/t/galleries/gf-h7Wx-8AUY-Ymp6_szybka-i-szeroka-trasa-orlen-warsaw-marathon-mapa-664x442.jpg",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Prove to us that you walked the equivalent of a half-marathon and get points!"
        },
        {
            "name": "Join the Community Stage",
            "image": "https://stagestudioprojects.co.uk/wp-content/uploads/2020/02/Lancashire-Grammar-School-Stage-Curtain-and-Lighting-Installation-1536x1152.jpg",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Attend presentations at the Community stage and get points!"
        },
        {
            "name": "Watch 3 Films",
            "image": "https://g.foolcdn.com/image/?url=https%3A//g.foolcdn.com/editorial/images/591116/watching-a-movie.jpg&w=2000&op=resize",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Bring us 3 used film tickets and get points!"
        },
        {
            "name": "Participate in Video Contest",
            "image": "https://paxful.com/university/static/1a08a6ff2ab8267f0812e4417efd3788/d5b8e/How-to-accept-Bitcoin-payments-using-the-Lightning-Network.webp",
            "time": "All weekend",
            "points": ["15 points"],
            "rules": "Record a lightning transaction video, post it to socials and tag us. Then come get your points!"
        }
    ]

    if (!player) {
        return <div>No player data available.</div>;
    }

    // Sort the sheetData based on points and remove the first item if necessary (as per your previous slicing)
    const sortedData = (Array.isArray(sheetData) ? sheetData.sort((a, b) => b.points - a.points) : []);

    // Find the player data in the sorted sheetData array
    const playerData = sortedData.find(item => item.email === player);

    // Calculate the position, adding 1 because arrays are zero-indexed
    const position = playerData ? sortedData.indexOf(playerData) + 1 : 'Unknown';

    if (!playerData) {
        return <div>No data found for this player.</div>;
    }

    const overlayImgSrc = "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Done_image_BFF.png";


    return (
        <>
            <h2 className='text-5xl text-blue-300 ml-6 mt-4 mb-4 font-bold'>How you can earn points</h2>
            <div className="carousel w-full">
                {games.map((game, index) => (
                    <div className="carousel-item relative md:w-1/5 w-1/3" key={index}>
                        <div className="absolute inset-0 bg-black opacity-30"></div>
                        {playerData[game.name.toLowerCase().replace(/\s+/g, '_')] && (
                            <img
                                src={overlayImgSrc}
                                alt="Completed"
                                className="absolute inset-0 w-full h-46 object-cover"
                            />
                        )}
                        <img src={game.image} alt={game.name} className="w-full h-56 object-cover" />
                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-xs">
                            <h2 className="text-xl font-bold">{game.name}</h2>
                            <p className="text-lg font-bold mt-1 mb-1">{game.time}</p>
                            <ul>
                                {game.points.map((point, index) => (
                                    <li key={index} className="font-bold">{point}</li>
                                ))}
                            </ul>
                            <button onClick={() => toggleExpand(game.name)} className="mt-2 text-white font-bold rounded">
                                {expanded === game.name ? 'Collapse' : 'Expand'} Rules ▼
                            </button>
                            {expanded === game.name && (
                                    <p>{game.rules}</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default GameInfo;
