import { UPDATE_WALLET } from '../Actions/types';

const initialState = {
  wallets : []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_WALLET:
      return {
        ...state,
        wallets: [...action.payload]
      };
    default:
      return state;
  }
}
