import React, { useState } from 'react';
import { getPublicKey, generatePrivateKey, nip04 } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19 } from 'nostr-tools';

const { npubEncode, nsecEncode } = nip19;

// Function to convert the hex keys to their nsec and npub equivalents
const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};


const convertNostrToHex = (nostr) => {
  const { type, data } = decode(nostr);
  
  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid nostr. Nostr must be "npub" or "nsec".');
  }
};

// Function to create new keys and set session storage
const createPrivateKey = async () => {
  const newPrivateKey = generatePrivateKey();
  const publicKey = getPublicKey(newPrivateKey);
  const nsec = convertHexToNostr(newPrivateKey, 'nsec');
  const npub = convertHexToNostr(newPrivateKey, 'npub');

  // Store keys in session storage
  localStorage.setItem('userHexPrivateKey', newPrivateKey);
  localStorage.setItem('userHexPublicKey', publicKey);
  localStorage.setItem('userNsec', nsec);
  localStorage.setItem('userNpub', npub);

  return { newPrivateKey, publicKey }; // Return the user hex keys
};

// Function to encode the user private key using nip04 before saving it to db
const encryptUserPrivateKey = async (flashPublicKey, userPrivateKey) => {
  const encryptedUserPrivateKey = await nip04.encrypt(userPrivateKey, flashPublicKey, userPrivateKey);

  return encryptedUserPrivateKey;
}


const checkUserEmailAddressInDb = async (emailAddress) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ email: emailAddress })
  };

  return fetch('https://api.paywithflash.com/api/check_email', requestOptions)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          return data.email_exists;
      })
      .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
      });
}



const signupUser = async (email, password, encryptedUserPrivateKey, publicKey) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ 
          email: email,
          password: password,
          privateKey: encryptedUserPrivateKey,
          publicKey: publicKey 
      })
  };

  return fetch('https://api.paywithflash.com/api/signup', requestOptions)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          console.log(data.message);
          return data;
      })
      .catch(error => {
          console.error('There was an error during the signup:', error);
      });
}



async function getFlashPublicKey() {
  try {
    // Make a GET request to the API endpoint
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/getFlashPublicKey', {
      method: 'GET',
      headers: {
        // You can add headers if needed, such as authentication headers
      },
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // Parse the JSON response to get the public key
      const data = await response.json();
      return data.publicKey;
    } else {
      // Handle the error if the response status is not 200
      throw new Error(`API call failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in getFlashPublicKey:', error);
    throw error;
  }
}






async function SignUpWithEmail() {

  const [publicKey, setPublicKey] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  

  const navigate = useNavigate();

  const doPasswordsMatch = () => {
    return password === repeatPassword;
  };
  


  // Hook to get the flash public key
  useEffect(() => {
    async function fetchPublicKey() {
      try {
        const retrievedPublicKey = await getFlashPublicKey();
        console.log(retrievedPublicKey);
        setPublicKey(retrievedPublicKey);
        console.log(retrievedPublicKey);
      } catch (error) {
        console.error('Error fetching flash public key:', error);
      }
    }

    fetchPublicKey();
  }, []);


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
  
    if (!acceptedTerms) {
      setErrorMessage('You must accept the terms and conditions to proceed.');
      setLoading(false);
      return;
    }
  
    if (!doPasswordsMatch()) {
      setErrorMessage('Passwords do not match.');
      setLoading(false);
      return;
    }

    setTimeout(async () => {
      try {
        // Check if email exists in the database
        const emailExists = await checkUserEmailAddressInDb(email);
        if (emailExists) {
          setErrorMessage('An account with this email address already exists, try logging in instead');
          setLoading(false);
          return;
        }
  
        // Create new keys for the user
        const keysResult = await createPrivateKey();
        const userPrivateKey = keysResult.newPrivateKey;
        const userPublicKey = keysResult.publicKey;
  
        // Encrypt the user private key
        const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPublicKey, userPrivateKey);
  
        // Sign up the user
        const signupResult = await signupUser(email, password, encryptedUserPrivateKey, userPublicKey);
        if (signupResult && signupResult.user_id) {
          // Store values in session storage and redirect
          localStorage.setItem('userEmail', email);
          navigate('/dashboard');
        } else {
          // Handle the case where signup did not return a user ID
          setErrorMessage('Signup failed. Please try again.');
        }
      } catch (error) {
        console.error('Signup error:', error);
        setErrorMessage('An error occurred during signup. Please try again.');
      } finally {
        setLoading(false);
      }
    }, 1000); // 1-second delay to ensure the loading animation is shown
  }  

    

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
        <>
        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-48"
            src="/FLASH (9).png"
            alt="Flash logo"
          />
        </div>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
          Sign up to Flash
        </h2>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
<div className="flex flex-col ml-4 mt-10"> {/* This div restricts the form width to 1/3 of its container */}
        <div className="flex items-center mb-0">
            {/* <EnvelopeIcon className="h-5 w-5 text-white mr-2" aria-hidden="true" /> */}
            <h2 className="text-center text-xl font-bold leading-9 tracking-tight login-email">
              Create an account on Flash ⤵️
          </h2>

        </div>
               <p className="text-sm font-light">
            Sign up, connect a wallet and start accepting payments!
        </p>
        
      <div className="mt-2">
          <form className="space-y-4" onSubmit={handleLogin}>
            {/* <div>
              <label htmlFor="username" className="block text-sm font-medium leading-6">
                Name & Surname (or Company)
              </label>
              <input
                id="username"
                name="username"
                type="username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
              />
            </div> */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
            />
          </div>
          <div className="">
            <label htmlFor="password" className="block text-sm font-medium leading-6">
              Password
            </label>
            <div className="mt-1 relative">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
              />
              <button 
                onClick={togglePasswordVisibility} 
                type="button"
                className="absolute  inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300 eye-icon"
              >
                <FaEye />
              </button>
              </div>
            </div>

          <div>
            <label htmlFor="repeatPassword" className="block text-sm font-medium leading-6">
              Repeat Password
            </label>
            <div className="mt-1 relative">
              <input
                id="repeatPassword"
                name="repeatPassword"
                type={showRepeatPassword ? 'text' : 'password'} // Use showRepeatPassword for consistency
                required
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
              />
              <button 
                onClick={toggleRepeatPasswordVisibility} 
                type="button"
                className="absolute  inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300 eye-icon"
              >
                <FaEye />
              </button>
              </div>
            {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
            </div>  

                  <div className="flex items-center">
        <input
          id="terms_and_conditions"
          name="terms_and_conditions"
          type="checkbox"
          checked={acceptedTerms}
          onChange={(e) => setAcceptedTerms(e.target.checked)}
          className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
        />
        <label htmlFor="terms_and_conditions" className="ml-2 block text-sm text-white">
          I accept the <a href="/terms" className="text-purple-500 underline">Terms and Conditions</a>
        </label>
      </div>


          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-purple-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              Sign up
            </button>
          </div>
        </form>
        </div>
        </div>
      </>
  );
}

export default SignUpWithEmail;
