// StatsComponent.js
import React from 'react';

import AreaChartSaleCountsHistory from './AreaChartSaleCountsHistory';
import PieChartSalesPerProductCategory from './PieChartSalesPerProductCategory';
import AreaChartSaleAmount from './AreaChartSaleAmount';



const ChartsComponent = ({ filteredData, currency }) => {

  return (
    <div>
      <dl className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-3">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <AreaChartSaleCountsHistory data={filteredData} currency = {currency} />
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <PieChartSalesPerProductCategory data={filteredData} currency = {currency}/>
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <AreaChartSaleAmount data={filteredData} currency  = {currency} />
        </div>
      </dl>
    </div>
  );
};

export default ChartsComponent;
