// ProjectStatsComponent.js
import React from 'react';

const UserSubscriptionsStatsComponent = ({ subscriptions }) => {
  // Total number of projects
  const totalProjects = subscriptions.length;

  // Total active projects
  const totalActiveProjects = subscriptions.filter(subscription => {
    return subscription.user_membership_details.is_paid === "true"; // Checks if the expiry date is later than the current date
  }).length;

  const stats = [
    { name: 'Total Subscriptions', stat: totalProjects },
    { name: 'Total Active Subscriptions', stat: totalActiveProjects },
  ];

  return (
    <div>
      <dl className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <dt className="text-sm font-medium text-indigo-900 opacity-80 truncate">{item.name}</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-indigo-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default UserSubscriptionsStatsComponent;
