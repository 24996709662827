// StatsComponent.js
import React from 'react';

const StatsComponentHome = ({ filteredData }) => {
  const totalTransactions = filteredData.length;
  const totalAmountSats = filteredData.reduce((sum, transaction) => sum + transaction.amount_in_sats, 0);
  const totalAmountUSD = filteredData.reduce((sum, transaction) => sum + parseFloat(transaction.amount_in_usd), 0);

  const stats = [
    { name: 'Total Transactions', stat: totalTransactions },
    { name: 'Total Amount in Sats', stat: `${totalAmountSats.toLocaleString()} sats` }, // Optionally format as string with commas
    { name: 'Total Amount in USD', stat: `$${totalAmountUSD.toFixed(2)}` }, // Format with two decimal places
  ];

  return (
    <div>
      <dl className="mb-6 grid grid-cols-1 gap-5 grid-cols-2 md:grid-cols-3 mt-5">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden  bg-white  ">
            <dt className="text-sm font-normal  text-slate-500  truncate">{item.name}</dt>
            <dd className="mt-0 text-lg font-semibold tracking-tight text-slate-800">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default StatsComponentHome;
