import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import StatsComponent from './StatsComponent';
import ChartsComponent from './ChartsComponents/ChartsComponent';
import DateRangePicker from './DateRangePicker';
import { BiDollarCircle } from 'react-icons/bi';
import { TbCurrencyZloty } from 'react-icons/tb';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SalesReportingWithFilters = ({ initialData, expandData, onSaleSelect }) => {
  const [currency, setCurrency] = useState('Dollar');
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const CategoryNames = ["All Categories", ...new Set(expandData.map(sale => sale.categoryName))];
  const ProductNames = ["All Products", ...new Set(expandData.map(sale => sale.productName))];

  const filteredData = useMemo(() => {
    const filtered = expandData.filter((saleData) => {
      const categoryFilter = selectedCategories.size === 0 || selectedCategories.has(saleData.categoryName);
      const productFilter = selectedProducts.size === 0 || selectedProducts.has(saleData.productName);
      const saleDate = new Date(saleData.saleDate);
      const startDate = dateRange.startDate ? new Date(dateRange.startDate) : null;
      const endDate = dateRange.endDate ? new Date(dateRange.endDate) : null;
      const dateFilter = (!startDate || saleDate >= startDate) && (!endDate || saleDate <= endDate);
      return categoryFilter && productFilter && dateFilter;
    });

    filtered.sort((a, b) => {
      const dateA = new Date(a.saleDate);
      const dateB = new Date(b.saleDate);
      return dateA - dateB;
    });

    // Consolidate product information for each sale
    const consolidatedData = filtered.reduce((acc, sale) => {
      const existingSale = acc.find(s => s.saleId === sale.saleId);
      if (existingSale) {
        existingSale.Products.push({
          productId: sale.productId,
          productName: sale.productName,
          categoryName: sale.categoryName,
          quantity: sale.quantity,
          price: sale.productPriceInDollars,
          currency: sale.currency,
          isDigital: sale.isDigital,
          taxRate: sale.taxRate
        });
      } else {
        acc.push({
          ...sale,
          Products: [{
            productId: sale.productId,
            productName: sale.productName,
            categoryName: sale.categoryName,
            quantity: sale.quantity,
            price: sale.productPriceInDollars,
            currency: sale.currency,
            isDigital: sale.isDigital,
            taxRate: sale.taxRate
          }]
        });
      }
      return acc;
    }, []);

    return consolidatedData;
  }, [expandData, selectedCategories, selectedProducts, dateRange]);

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };

  const formatDateRange = () => {
    if (!dateRange.startDate && !dateRange.endDate) {
      return 'All Time';
    }
    const start = dateRange.startDate ? new Date(dateRange.startDate).toLocaleDateString() : '...';
    const end = dateRange.endDate ? new Date(dateRange.endDate).toLocaleDateString() : '...';
    return `From ${start} To ${end}`;
  };

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  return (
    <div className="lg:px-1">
      <div className="flex justify-end mb-3 p-2">
        <CurrencyDropdown label="Currency" id="currency" name="currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
      </div>
      <StatsComponent filteredData={filteredData} currency={currency} />

      <div className="flex gap-6">
        <FilterListbox
          items={CategoryNames}
          selectedSet={selectedCategories}
          label="Product Category"
          allValue="All Categories"
          handleSelection={(value) => {
            const newSet = handleSelectionChange(selectedCategories, value, "All Categories");
            setSelectedCategories(newSet);
          }}
        />

        <FilterListbox
          items={ProductNames}
          selectedSet={selectedProducts}
          label="Product Name"
          allValue="All Products"
          handleSelection={(value) => {
            const newSet = handleSelectionChange(selectedProducts, value, "All Products");
            setSelectedProducts(newSet);
          }}
        />

        <div className="flex flex-grid col-span-4 w-full">
          <div className="relative mt-1 w-full">
            <button className="inline-flex w-full justify-between rounded-md bg-white px-3 py-3 text-sm font-medium text-indigo-900 ring-1 ring-indigo-100 ring-inset hover:bg-gray-50" onClick={toggleDatePicker}>
              {formatDateRange()}
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
            <Transition
              as={Fragment}
              show={showDatePicker}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <div className="absolute z-50 mt-1 w-full rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <DateRangePicker onDateChange={handleDateRangeChange} />
              </div>
            </Transition>
          </div>
        </div>
      </div>

      <div className='mt-2 z-40'>
        <ChartsComponent filteredData={filteredData} currency={currency} />
      </div>

      <div className="mt-6 rounded-lg overflow-hidden ring-1 ring-indigo-200 shadow-sm">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Sale Date</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Sale Origin</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Product Names</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Category Names</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Customer Name</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Amount</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Tax Rate</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Tax Amount</th>
                <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700 w-1/7">Total</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-indigo-100 bg-white">
              {filteredData.map((saleData, index) => (
                <tr key={index} className="hover:bg-indigo-50 cursor-pointer" onClick={() => onSaleSelect(saleData)}>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{new Date(saleData.saleDate).toLocaleDateString()}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{saleData.saleOrigin}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{saleData.Products.map(product => product.productName).join(', ')}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{saleData.Products.map(product => product.categoryName).join(', ')}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{saleData.firstName} {saleData.lastName}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                    {currency === 'Dollar' ? `US$${parseFloat(saleData.totalCostDollar).toFixed(2)}` : `${parseFloat(saleData.totalCostZloty).toFixed(2)} PLN`} / {parseInt(saleData.totalCostSats).toLocaleString()} sats
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">{saleData.taxRate}</td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                    {currency === 'Dollar' ? `US$${parseFloat(saleData.totalCostDollar * (saleData.taxRate / 100)).toFixed(2)}` : `${parseFloat(saleData.totalCostZloty * (saleData.taxRate / 100)).toFixed(2)} PLN`} / {parseInt(saleData.totalCostSats * (saleData.taxRate / 100)).toLocaleString()} sats
                  </td>
                  <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900 opacity-70">
                    {currency === 'Dollar' ? `US$${parseFloat(saleData.totalCostDollar * (1 + saleData.taxRate / 100)).toFixed(2)}` : `${parseFloat(saleData.totalCostZloty * (1 + saleData.taxRate / 100)).toFixed(2)} PLN`} / {parseInt(saleData.totalCostSats * (1 + saleData.taxRate / 100)).toLocaleString()} sats
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const FilterListbox = ({ items, selectedSet, label, allValue, handleSelection }) => {
  const isAllSelected = selectedSet.size === 0 || selectedSet.has(allValue);

  return (
    <div className="flex flex-grid col-span-4 w-full">
      <Listbox className="w-full" as="div" value={Array.from(selectedSet)} onChange={handleSelection}>
        <div className="relative mt-1">
          <Listbox.Button className="inline-flex w-full justify-between rounded-md bg-white px-3 py-3 text-sm font-medium text-indigo-900 ring-1 ring-indigo-100 ring-inset hover:bg-gray-50">
            {isAllSelected ? allValue : Array.from(selectedSet).join(', ')}
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Listbox.Button>
          <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {items.map((item, idx) => (
              <Listbox.Option key={idx} value={item} className={({ active }) => classNames('cursor-pointer select-none relative py-2 pl-3 pr-9', selectedSet.has(item) ? 'bg-indigo-600 text-white' : active ? 'bg-indigo-50' : 'text-gray-900')}>
                <>
                  <span className="block truncate">{item}</span>
                  {selectedSet.has(item) && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
};

export const CurrencyDropdown = ({ label, id, name, value, onChange }) => {
  const currencyOptions = [
    { value: 'Dollar', label: 'Dollars', icon: <BiDollarCircle className="h-5 w-5" aria-hidden="true" /> },
    { value: 'Zloty', label: 'Zloty', icon: <TbCurrencyZloty className="h-5 w-5" aria-hidden="true" /> },
  ];

  const renderCurrencyIcon = () => {
    const selectedOption = currencyOptions.find(option => option.value === value);
    return selectedOption ? selectedOption.icon : null;
  };

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-slate-700 mb-2">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center p-2 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
          {renderCurrencyIcon()}
        </span>
        <select
          id={id}
          name={name}
          autoComplete="off"
          className="block flex-1 border border-r-0 border-gray-300 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={value}
          onChange={onChange}
        >
          {currencyOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

function handleSelectionChange(selectedSet, value, allValue) {
  const newSet = new Set(selectedSet);
  if (value === allValue) {
    newSet.clear();
  } else {
    if (newSet.has(value)) {
      newSet.delete(value);
    } else {
      newSet.add(value);
    }
  }
  return newSet;
}

export default SalesReportingWithFilters;
