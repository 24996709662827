import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getWalletList } from '../../Redux/Actions/walletAction.js';
import { BsFillLightningFill, BsFillEggFill, BsEgg, BsLightning } from 'react-icons/bs';
import WalletsAvatars from './WalletsAvatars'; // Adjust the path as necessary
import WalletDropdown from '../Navbar/WalletsDropdown';
import WalletsTotal from '../Navbar/WalletsTotal';

// import redux actions
import { getAndUpdateWalletList } from '../../Redux/Actions/walletAction';

// The ProfileComponent (unchanged)
const ProfileComponent = () => {
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  
  return (
    <a href="/my-account" className="group block flex-shrink-0">
      <div className="btn btn-primary hover:bg-slate-100 animate-none hover:border-slate-200 bg-white text-slate-800  border-slate-200 px-2 pl-3 justify-center align-middle  min-h-0 h-fit py-1">
                  <img
            className="inline-block h-6 w-6 rounded-full object-cover  "
            src={userPictureUrl}
            alt={userName !== "" ? userName : userNpub.slice(0, 10) + "..."}
          />
        <div className=" ">
          
          <p className="text-sm font-semibold text-slate-900 group-hover:text-gray-900">{userName !== "" ? userName : userNpub.slice(0, 10) + "..."}</p>
          {/* <p className="text-xs font-medium text-slate-500 group-hover:text-gray-700">My account</p> */}
        </div>
        <div className="flex-grow">

        </div>
      </div>
    </a>
  );
};

// Modified Header component
export default function Header({
  title,
  subtitle,
  buttonText1,
  buttonLink1,
  buttonText2,
  buttonLink2,
  badgeText,
  showBadgeText = false,
  badgeLink,
  showWalletsAvatar = false // New prop to control the display of WalletsAvatar
}) {

  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  const userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  const userNpub = localStorage.getItem('userNpub') || "";
  const userName = localStorage.getItem('userName') || "";
  const userAbout = localStorage.getItem('userAbout') || "";
  const userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  // Define the wallets state
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalBalanceUSD, setTotalBalanceUSD] = useState(0);

  useEffect (() => {
    getAndUpdateWalletList (userHexPrivateKey, publicKey);
  }, [])

  // getting data from redux
  const wallets = useSelector ((state) => state.walletReducer.wallets);

  useEffect (() => {

    const convert = (async () => {
      // Get USD value
      const totalUSD = await convertPrices("usd", totalInSatoshis);
      setTotalBalanceUSD(parseFloat(totalUSD));
    })
    const totalInMilliSatoshis = wallets.reduce((acc, wallet) => acc + parseFloat(wallet.balance), 0);
    const totalInSatoshis = totalInMilliSatoshis / 1000;

    setTotalBalance(totalInSatoshis);
    convert ();

  }, [wallets])

  // Function to get the price of Bitcoin
  async function getBitcoinPrice() {
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
            method: 'POST', // if the API requires POST method
            headers: {
                'Content-Type': 'application/json',
            },
            // If the API requires a body payload, uncomment and edit the following line
            // body: JSON.stringify({ key: 'value' }), 
        });

        if (!response.ok) {
            // If the response is not OK, throw an error with the response status
            throw new Error(`API call failed with status: ${response.status}`);
        }

        const data = await response.json();

        // Assuming the API returns an object with a property that contains the Bitcoin price
        return data
    } catch (error) {
        console.error('API call failed:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
  }

  // Function to go through the plans and make sure we have two columns: price in sats & price in dollars
  async function convertPrices(currency, value) {
    try {
        const bitcoinPrice = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars

        let convertedPrice;

        if (currency === "usd") {
            const priceInDollars = value / 100000000; // Convert satoshis to bitcoin
            convertedPrice = (priceInDollars * bitcoinPrice).toFixed(2);
        } else if (currency === "satoshis") {
            convertedPrice = value;
        }
        return convertedPrice;

    } catch (error) {
        console.error('Conversion failed:', error);
        return null; // Return null in case of error
    }
  }

  return (
    <div className="flex items-top pt-20 -mt-2 md:-mt-0 z-50 md:pt-3  justify-between px-2 sm:px-3 lg:px-4 account-header p-3 bg-white pb-3  border-slate-300">
      {/* Left side content */}
      <div className="min-w-0 flex-1  md:mt-0 ml-2 md:ml-0">
        <h2 className="text-lg pt-1 pl-1.5 font-semibold  text-slate-900 sm:truncate sm:text-xl tracking-normal">
              {title || 'Insert title here...'}
        </h2>

        {badgeText && (
          <a
            href={badgeLink || '#'}
            className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
          >
              {/* ... badgeText rendering logic ... */}
              {badgeText}
          </a>
        )}
        {showWalletsAvatar && <WalletsAvatars />} {/* Conditional rendering of WalletsAvatars */}

      </div>
          
      {/* Right side - Profile Component */}
      <div className="flex justify-end flex-row w-1/2 gap-2">
        
        <div className="hidden md:flex gap-2">
          <WalletsTotal totalBalance = {totalBalance} totalBalanceUSD = {totalBalanceUSD}/>
          <WalletDropdown wallets={wallets}/>
        </div>
        <ProfileComponent />
      </div>
    </div>
  );
}
