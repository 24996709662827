import React, { useState, useEffect, useRef } from 'react';
import SubsciptionVideoPaywallModal from './SubsciptionVideoPaywallModal';
import { fetchDecryptedContent, checkMembershipStatus, requestWebLnPayment, createZapInvoice, checkInvoicePayment, checkInvoicePaymentForExtension, fetchFlashById, fetchWalletUrlById, saveTransactionToDB } from '../api';
import Lottie from 'react-lottie';

import confettiAnimation from '../../../assets/confetti_animation.json';


const SubscriptionVideoPaywall = ({ config }) => {
    const [decryptedConfig, setDecryptedConfig] = useState(null);
    const [hasPaidWithExtension, setHasPaidWithExtension] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [showPaywallModal, setShowPaywallModal] = useState(false);
    const [invoice, setInvoice] = useState('');
    const [flashDetails, setFlashDetails] = useState({});
    const [walletDetails, setWalletDetails] = useState({});

    const hasPaidWithExtensionRef = useRef(hasPaidWithExtension);
    const videoRef = useRef(null);
    const intervalRef = useRef(null);
    const accumulatedWatchTimeRef = useRef(0);
    const flashDetailsRef = useRef({});
    const walletDetailsRef = useRef({});
    const isPaidRef = useRef(isPaid);

    // State to control the confetti animation
    const [showConfettiAnimation, setShowConfettiAnimation] = useState(false);

    // Lottie options for the confetti animation
    const defaultOptions = {
        loop: false,
        autoplay: true,  // Changed to true so it plays as soon as rendered
        animationData: confettiAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };




    useEffect(() => {
        hasPaidWithExtensionRef.current = hasPaidWithExtension;
    }, [hasPaidWithExtension]);

    useEffect(() => {
        flashDetailsRef.current = flashDetails;
    }, [flashDetails]);
    
    useEffect(() => {
        walletDetailsRef.current = walletDetails;
    }, [walletDetails]);

    useEffect(() => {
        isPaidRef.current = isPaid;
    }, [isPaid]);
    
    
    

    // Decrypt config and set initial state
    useEffect(() => {
        const decryptConfig = async () => {
            const decryptedConfig = await fetchDecryptedContent(config);
            setDecryptedConfig(decryptedConfig);
        };
        decryptConfig();
    }, [config]);


    // Fetch flash and wallet details
    useEffect(() => {
        if (decryptedConfig && decryptedConfig.flashId) {
            const fetchData = async () => {
                try {
                    const flashData = await fetchFlashById(decryptedConfig.flashId);
                    setFlashDetails(flashData);
                    const walletData = await fetchWalletUrlById(flashData.wallet_id);
                    setWalletDetails(walletData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchData();
        }
    }, [decryptedConfig]);




    // Initialize video and membership status
useEffect(() => {
    const initialize = async () => {
        if (decryptedConfig && decryptedConfig.videoId) {
            const video = document.getElementById(decryptedConfig.videoId);
            videoRef.current = video;

            const hasValidMembership = await checkMembershipStatus(decryptedConfig.subscriptionPlanFlashId);
            setIsPaid(hasValidMembership);

            if (!hasValidMembership) {
                video.addEventListener('play', handleVideoPlay);
                video.addEventListener('pause', handleVideoPause);
                video.controls = true; // Disable controls for non-members
            } else {
                // Do not show paywall modal for members
                setShowPaywallModal(false);
                video.removeEventListener('play', handleVideoPlay);
                video.removeEventListener('pause', handleVideoPause);
                video.controls = true; // Always enable controls for members
            }
        }
    };

    initialize();
}, [decryptedConfig]);





    // Function to start the video play interval
    const startVideoPlayInterval = (additionalTime = 0) => {
        clearInterval(intervalRef.current); // Clear existing interval if any

        accumulatedWatchTimeRef.current += additionalTime;

        intervalRef.current = setInterval(() => {
            accumulatedWatchTimeRef.current += 1;

            if (accumulatedWatchTimeRef.current >= decryptedConfig.videoPlayingInterval) {
                clearInterval(intervalRef.current);

                if (hasPaidWithExtensionRef.current) {
                    handleWebLnPayment(); // Call handleWebLnPayment if paid with extension
                } else {
                    setShowPaywallModal(true);
                    videoRef.current.pause();
                    videoRef.current.controls = false;
                }
            }
        }, 1000);
    };

const handleWebLnPayment = async () => {
    const currentFlashDetails = flashDetailsRef.current;
    const currentWalletDetails = walletDetailsRef.current;

    if (currentFlashDetails.user_public_key && currentWalletDetails.nwc_url) {
        try {
            // Create the invoice
            const createdInvoice = await createZapInvoice(currentFlashDetails.user_public_key, currentWalletDetails.nwc_url, decryptedConfig.price, decryptedConfig.memo);
            setInvoice(createdInvoice);

            try {
                // Request payment via WebLN
                requestWebLnPayment(createdInvoice);
                const paymentStartTime = Date.now();

                // Initialize variables for the loop
                let isPaymentValid = false;
                const maxChecks = 3;

                for (let i = 0; i < maxChecks; i++) {
                    isPaymentValid = await checkInvoicePaymentForExtension(currentFlashDetails.user_public_key, currentWalletDetails.nwc_url, createdInvoice);

                    if (isPaymentValid) {
                        break; // Exit loop if payment is valid
                    }

                    // Wait 1 second before next check
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }

                if (isPaymentValid) {
                    // Calculate elapsed time during payment process
                    const paymentElapsedTime = Math.ceil((Date.now() - paymentStartTime) / 1000);

                    // If payment is successful, handle it accordingly
                    // saveTransactionToDB(createdInvoice, 10, 1, decryptedConfig.flashId, currentFlashDetails.user_public_key);
                    setIsPaid(true); // Mark as paid
                    startVideoPlayInterval(paymentElapsedTime); // Adjust interval start with elapsed time
                } else {
                    updateHasPaidWithExtension(false);
                    throw new Error('Payment not valid');
                }
            } catch (paymentError) {
                // Handle immediate payment rejection
                console.error('Payment request failed:', paymentError);
                setShowPaywallModal(true);
                videoRef.current.pause();
                videoRef.current.controls = false;
            }
        } catch (error) {
            // Handle other errors in the payment process
            console.error('Error in handleWebLnPayment:', error);
            setShowPaywallModal(true);
            videoRef.current.pause();
            videoRef.current.controls = false;
        }
    } else {
        console.error('Missing flashDetails or walletDetails data');
        setShowPaywallModal(true);
        videoRef.current.pause();
        videoRef.current.controls = false;
    }
};


    const handleVideoPlay = () => {

        if (!isPaid) {
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(() => {
                accumulatedWatchTimeRef.current += 1;

                if (accumulatedWatchTimeRef.current >= decryptedConfig.videoPlayingInterval) {
                    clearInterval(intervalRef.current);

                    if (hasPaidWithExtensionRef.current) {
                        handleWebLnPayment();
                    } else {
                        setShowPaywallModal(true);
                        videoRef.current.pause();
                        videoRef.current.controls = false;
                    }
                }
            }, 1000);
        }
    };

    

    const handleVideoPause = () => {
        clearInterval(intervalRef.current);
    };

    // Function to handle successful payment
    const handlePaymentSuccess = () => {
        setShowConfettiAnimation(true); // Start showing the confetti

        setTimeout(() => {
            setShowConfettiAnimation(false); // Stop showing the confetti after 3 seconds
            setIsPaid(true); // Set the payment status
            setShowPaywallModal(false); // Hide the paywall modal
        }, 3000);

        setShowPaywallModal(false);
        setIsPaid(true);
        accumulatedWatchTimeRef.current = 0;

        if (videoRef.current) {
            videoRef.current.play();
            videoRef.current.controls = true; // Re-enable controls after payment
        }
    };

    // Add a new function to handle state update
    const updateHasPaidWithExtension = (value) => {
        setHasPaidWithExtension(value);
    };



    return (
        <div>
            {decryptedConfig && showPaywallModal && (
                <SubsciptionVideoPaywallModal 
                    flashId={decryptedConfig ? decryptedConfig.flashId : ''}
                    decryptedConfig={decryptedConfig}
                    onPaymentSuccess={handlePaymentSuccess}
                    updateHasPaidWithExtension={updateHasPaidWithExtension}
                />
            )}

            {/* Confetti Animation Overlay */}
            {showConfettiAnimation && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <Lottie options={defaultOptions} height={800} width={800} isStopped={!showConfettiAnimation} />
                </div>
            )}
        </div>
    );
};

export default SubscriptionVideoPaywall;
