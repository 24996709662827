import React, { useState } from 'react';
import { ArrowLongLeftIcon, EyeOffIcon, ClipboardIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { FaRegEye } from 'react-icons/fa6'; 


function MyKeys() {
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [showNsec, setShowNsec] = useState(false);

  const userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  const userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  const userNsec = localStorage.getItem('userNsec') || "";
  const userNpub = localStorage.getItem('userNpub') || "";

  const handleCopyToClipboard = (label) => {
    let valueToCopy = '';
    if (label === 'Private Key') {
      valueToCopy = userHexPrivateKey;
    } else if (label === 'Public Key') {
      valueToCopy = userHexPublicKey;
    } else if (label === 'NSec') {
      valueToCopy = userNsec;
    } else if (label === 'NPub') {
      valueToCopy = userNpub;
    }
    navigator.clipboard.writeText(valueToCopy);
  };

  const togglePrivateKeyVisibility = () => setShowPrivateKey(!showPrivateKey);
  const toggleNsecVisibility = () => setShowNsec(!showNsec);

  return (
    <>
      <div className="mt-0 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {[
            { label: 'Private Key', value: showPrivateKey ? userHexPrivateKey : '***************************', visible: showPrivateKey, toggleVisibility: togglePrivateKeyVisibility },
            { label: 'Public Key', value: userHexPublicKey },
            { label: 'NSec', value: showNsec ? userNsec : '***************************', visible: showNsec, toggleVisibility: toggleNsecVisibility },
            { label: 'NPub', value: userNpub },
          ].map((item, index) => (
            <div key={index} className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-indigo-900 px-8">{item.label}</dt>
              <dd className="mt-1 flex text-sm leading-6 text-indigo-800 sm:col-span-2 sm:mt-0">
                <span className="flex-grow truncate pr-4">{item.value}</span>
                <span className="flex-shrink-0 flex items-center space-x-4 pr-12">
                  {item.toggleVisibility && (
                    <button type="button" onClick={item.toggleVisibility} className="rounded-md text-indigo-600 hover:text-indigo-500">
                      {item.visible ? <FaRegEye className="h-5 w-5" /> : <FaRegEye className="h-5 w-5" />}
                    </button>
                  )}
                  <button type="button" onClick={() => handleCopyToClipboard(item.label)} className="rounded-md text-indigo-600 hover:text-indigo-500">
                    <ClipboardIcon className="h-5 w-5" />
                  </button>
                </span>
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="px-6 py-6 flex items-center bg-white-100 border-t border-gray-200">
        <div className="w-1/10">
          <InformationCircleIcon className="h-7 w-7 text-rose-500" />
        </div>
        <div className="w-9/10 pl-4">
          <span className="text-sm text-rose-400">Remember, your Private Key is sensitive information. Do not share it with anyone.</span>
        </div>
      </div>
    </>
  );
}

export default MyKeys;
