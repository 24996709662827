import React, { useState, Fragment } from 'react';
import { ArrowLongLeftIcon, PlusIcon, CheckIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { cardConfigs } from '../components/FlashCards/CardsList'; // Import the configuration
import { BsPlay } from 'react-icons/bs';



import { Dialog, Transition } from '@headlessui/react';
import Form1 from '../components/FlashForms/forms/0-ExampleForm';
import ConnectAPIForm from '../components/FlashForms/forms/ConnectAPIForm';

import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import CreateEgg from '../components/CreateEgg';
import GetPublicEggs from '../components/GetPublicEggs';
import Example from '../components/cards'; // make sure the path is correct
import PopExplain from '../components/PopExplain'; // Import the new component


import { CardsPage } from '../components/FlashCards/CardsTemplate';




function Eggs() {
  const [activeSection, setActiveSection] = useState('view'); // Default to 'view'

  const [showPopExplain, setShowPopExplain] = useState(true); // State to control visibility

  const handleUploadCancel = (newStatus) => {
    setActiveSection(newStatus)
  }

  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const openModal = (actionId) => {
    setSelectedAction(actionId);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  const getFormComponent = () => {
    switch (selectedAction) {
      case 'createFlashAPI':
        return <Form1 close={closeModal} />;
      case 'connectAPI':
        return <ConnectAPIForm close={closeModal} />;
      // Add other cases as needed...
      default:
        return null;
    }
  };

  return (

    <div className="min-h-screen">
      <SidebarLayout>
        <Header
          title="New Gateway"
          subtitle="Share a link, button or implement a Bitcoin payment paywall. "
          showWalletsAvatar={false}
          badgeText=""// Set this to true or false as needed

        // ... other header props ...
        />

        <div className="container mx-auto">

          


        </div>

        <div className='p-6'>

          <div className="md:w-full flex flex-row mb-4 bg-white rounded-lg ring-indigo-100 shadow-sm shadow-gray-200">
            
            <div className="w-full md:w-2/3">
  <div className="flex flex-col  p-6 px-8 pt-7">
    <h3 className="text-3xl mb-1 font-semibold text-slate-800">Create a Bitcoin Gateway</h3>
    <p className="mb-2 text-slate-600 opacity-80 -mb-2">The fastest way to accept a payment in Bitcoin.</p>
    <ul className="list-none pl-0 mt-3 text-slate-900">
      <li className="flex items-center mb-2">
        <CheckCircleIcon className="h-5 w-5 text-slate-800 mr-3" />
       <span className='font-semibold mr-1'>Widget : </span>  place a tip widget with one script.
      </li>
      <li className="flex items-center mb-2">
        <CheckCircleIcon className="h-5 w-5 text-slate-800 mr-3" />
          <span className='font-semibold mr-1'>Payment link : </span> to receive instant payments from a link.
      </li>
      <li className="flex items-center mb-0">
        <CheckCircleIcon className="h-5 w-5 text-slate-800  mr-3" />
         <span className='font-semibold mr-1'>Paywall : </span> to monetise your online content.
      </li>
    </ul>
   
  </div>
</div>
            
            <div className="hidden md:block  md:w-1/3">
            <img src='/images/pay-1.jpg' className='cover mx-auto w-full rounded-r-lg h-full object-cover'></img>
            </div>
          </div>
          
          <CardsPage
            actions={cardConfigs.flash1Actions}
            title="Receive payments in 10 secs"
          />

          <CardsPage
            actions={cardConfigs.flash3Actions}
            title="Monetize your platform"
          />
        </div>

      </SidebarLayout>
    </div>
  );
}

export default Eggs;
