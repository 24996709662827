import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import { Switch } from '@headlessui/react';
import {
  Bars3Icon,
  XMarkIcon,
  HomeIcon,
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
  BanknotesIcon,
  ArrowTrendingUpIcon,
  PlusCircleIcon,
  CalculatorIcon,
  ChartBarIcon,
  UserGroupIcon,
  CubeIcon,
  UsersIcon,
  UserPlusIcon,
  UserCircleIcon,
  ChartPieIcon,
  UserIcon,
  QueueListIcon,
  Squares2X2Icon,
  BuildingStorefrontIcon
} from '@heroicons/react/24/solid';
import { BsEgg, BsFillLightningFill } from 'react-icons/bs';
import { TbSquareRoundedCheckFilled } from 'react-icons/tb';
import { AiOutlineUser } from 'react-icons/ai';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    const later = () => {
      clearTimeout(timeout);
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function SidebarToggle({ interfaceMode, setInterfaceMode, setEnabled }) {
  const [enabled, setEnabledState] = useState(localStorage.getItem('interfaceMode') === 'Personal');

  useEffect(() => {
    let savedInterfaceMode = localStorage.getItem('interfaceMode');
    if (!savedInterfaceMode) {
      savedInterfaceMode = 'Pro';
      localStorage.setItem('interfaceMode', 'Pro');
    }
    setInterfaceMode(savedInterfaceMode);
    setEnabledState(savedInterfaceMode === 'Personal');
  }, [setInterfaceMode]);

  const updateState = useCallback(
    debounce((newState) => {
      localStorage.setItem('sidebarToggle', newState.toString());
      setEnabled(newState);
    }, 250),
    [setEnabled]
  );

  const handleChange = () => {
    const newState = !enabled;
    setEnabledState(newState);
    updateState(newState);

    const newInterfaceMode = newState ? 'Personal' : 'Pro';
    setInterfaceMode(newInterfaceMode);
    localStorage.setItem('interfaceMode', newInterfaceMode);
  };

  return (
    <Switch.Group as="div" className="flex w-full items-center" id="toggle-button">
      <Switch.Label as="span" className="mr-3 text-sm font-medium text-slate-500">
        Pro
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={handleChange}
        className={classNames(
          enabled ? 'bg-slate-500' : 'bg-slate-700',
          'relative inline-flex h-6 w-11 items-center flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm font-medium text-slate-500">
        Personal
      </Switch.Label>
    </Switch.Group>
  );
}

export default function SidebarLayout({ children, interfaceMode = 'Pro', setInterfaceMode = () => {}, onModeChange }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState(localStorage.getItem('sidebarToggle') === 'true' || false);

  const userHexPublickey = localStorage.getItem('userHexPublicKey');


  useEffect(() => {
    let savedInterfaceMode = localStorage.getItem('interfaceMode');
    if (!savedInterfaceMode) {
      savedInterfaceMode = 'Pro';
      localStorage.setItem('interfaceMode', 'Pro');
    }
    setInterfaceMode(savedInterfaceMode);
  }, [setInterfaceMode]);

  useEffect(() => {
    const introShown = localStorage.getItem(`flash_intro_shown_${interfaceMode}`);
    if (!introShown) {
      // Ensure elements are fully rendered before starting the tour
      setTimeout(() => {
        startIntro(interfaceMode);
      }, 1000);
    }
  }, [interfaceMode]);

  const startIntro = (mode) => {
    const intro = IntroJs();
  
    const proIntroShown = localStorage.getItem(`flash_intro_shown_Pro`) === 'true';
    const personalIntroShown = localStorage.getItem(`flash_intro_shown_Personal`) === 'true';
  
    const personalSteps = [
      {
        element: '#main-content',
        intro: 'This is your Dashboard. It will show your recent transactions and Quick Actions to get started.'
      },
      {
        element: '#toggle-button',
        intro: 'Click to toggle between Pro and Personal Features.'
      },
      {
        element: '#submenu-submenu1',
        intro: 'Quickly access the Dashboard and screens to view your transactions and manage your Subscriptions.'
      }
    ];
    if (!proIntroShown) {
      personalSteps.push({
        element: '#submenu-submenu4',
        intro: 'Manage your account details and manage your wallets.'
      });
    }
  
    const proSteps = [
      {
        element: '#main-content',
        intro: 'This is your Dashboard. It will show your recent transactions and Quick Actions to get started.'
      },
      {
        element: '#toggle-button',
        intro: 'Click to toggle between Pro and Personal Features.'
      },
      {
        element: '#submenu-submenu1',
        intro: 'Quickly access the Dashboard and screens to create your first Flash or Subscription.'
      },
      {
        element: '#submenu-submenu2',
        intro: 'View your existing Flashes, Subscriptions and their analytics.'
      },
      {
        element: '#submenu-submenu3',
        intro: 'Create and manage your Products. View Sales analytics here too.'
      },
      {
        element: '#new-flash',
        intro: 'Create a new Flash to get started.',
        position: 'bottom'
      }
    ];
    if (!personalIntroShown) {
      proSteps.push({
        element: '#submenu-submenu4',
        intro: 'Manage your account details and manage your wallets.'
      });
    }
  
    const steps = mode === 'Personal' ? personalSteps : proSteps;
  
    intro.setOptions({ steps, skipLabel: 'Skip' });
  
    intro.onafterchange((targetElement) => {
      const tooltip = document.querySelector('.introjs-tooltip');
      const currentStep = intro._currentStep;
  
      // Fix the first step tooltip positioning
      if (currentStep === 0) {
        tooltip.style.position = 'fixed';
        tooltip.style.top = '50%';
        tooltip.style.left = '50%';
        tooltip.style.transform = 'translate(-50%, -50%)';
      } else {
        tooltip.style.position = '';
        tooltip.style.top = '';
        tooltip.style.left = '';
        tooltip.style.transform = '';
      }
    });
  
    setTimeout(() => {
      intro.start();
    }, 500);
  
    localStorage.setItem(`flash_intro_shown_${mode}`, 'true');
  };

  const resetIntro = () => {
    localStorage.removeItem(`flash_intro_shown_Pro`);
    localStorage.removeItem(`flash_intro_shown_Personal`);
    console.log('Intro tour reset');
  };

  const hasMenuItems = (submenu) => {
    return menuItems.some(item => item.submenu === submenu && item.category.includes(toggleState ? 'Pay' : 'Receive'));
  };

  const handleLogout = () => {
    localStorage.clear ();
    window.location.href = '/login';
  };


  const menuItems = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, submenu: 'Submenu1', category: ['Pay', 'Receive'] },
    { name: 'New Gateway', href: '/new-flash', icon: PlusCircleIcon, submenu: 'Submenu2', category: ['Receive'] },
    { name: 'New Egg', href: '/new-egg', icon: BsEgg, submenu: 'Submenu1', category: [] },
    { name: 'My Expenses', href: '/expenses', icon: TbSquareRoundedCheckFilled, submenu: 'Submenu1', category: ['Pay'] },
    { name: 'My Gateways', href: '/my-services', icon: Squares2X2Icon, submenu: 'Submenu2', category: ['Receive'] },
    { name: 'My Subscriptions', href: '/my-subscriptions', icon: BsFillLightningFill, submenu: 'Submenu1', category: ['Pay'] },
    { name: 'All Transactions', href: '/transactions', icon: QueueListIcon, submenu: 'Submenu2', category: ['Receive'] },
    { name: 'Reports', href: '/income', icon: ChartPieIcon, submenu: 'Submenu2', category: ['Receive'] },
    { name: 'New Plan', href: '/new-subscriptions', icon: PlusCircleIcon, submenu: 'Submenu5', category: ['Receive'] },
    { name: 'My Subscriptions', href: '/subscriptions', icon: UsersIcon, submenu: 'Submenu5', category: ['Receive'] },
    { name: 'My Account', href: '/my-account', icon: UserCircleIcon, submenu: 'Submenu1', category: ['Pay', 'Receive'] },
    { name: 'My Store', href: '/product-gallery-user', icon: BuildingStorefrontIcon, submenu: 'Submenu3', external: true, category: ['Receive'] },
    { name: 'My Products', href: '/product-list-page', icon: Squares2X2Icon, submenu: 'Submenu3', external: true, category: ['Receive'] },
    { name: 'My PoS', href: '/point-of-sale', icon: CalculatorIcon, submenu: 'Submenu3', category: ['Receive'] },
    { name: 'All Customers', href: '/customers', icon: QueueListIcon, submenu: 'Submenu3', external: true, category: ['Receive'] },
    { name: 'Sales Report', href: '/sales', icon: ChartPieIcon, submenu: 'Submenu3', external: true, category: ['Receive'] },
  ];

  const submenuLabels = {
    Submenu1: '',
    Submenu2: 'Gateways',
    Submenu5: 'Subscriptions',
    Submenu3: 'Products',
    Submenu4: 'My account',

  };

  const renderMenuItems = (submenu) => {
    return menuItems
      .filter(item => item.submenu === submenu && item.category.includes(toggleState ? 'Pay' : 'Receive'))
      .map(item => (
        <li key={item.name} id={`submenu-${submenu.toLowerCase()}-${item.name.replace(/\s+/g, '-').toLowerCase()}`}>
          <Link to={item.href} className={classNames(
            location.pathname === item.href ? 'bg-slate-100 text-slate-900 transition' : 'text-slate-800 hover:bg-slate-100',
            'group flex gap-x-2 hover:transition transition rounded-md text-sm leading-6 font-medium w-full justify-start items-center px-2 py-0.5'
          )}>
            <item.icon className="h-4 w-4 shrink-0 text-slate-700" aria-hidden="true" />
            <span className="pl-2">{item.name}</span>
          </Link>
        </li>
      ));
  };

  const mainContentClass = sidebarOpen ? 'main-content-hidden' : '';

  return (
    <div>
      <style>
        {`
          .introjs-skipbutton, .introjs-nextbutton, .introjs-prevbutton {
            font-size: 0.75rem;
            padding: 4px 8px;
          }
        `}
      </style>
      {/* The line below is for testing purposes. The button allows to reset localStorage */}
      {/* <button onClick={resetIntro} style={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1000, padding: '10px 20px', backgroundColor: 'red', color: 'white', borderRadius: '5px', border: 'none', cursor: 'pointer' }}>Reset Tour</button> */}
      <div className="fixed flex top-0 left-0 z-[110] p-4 w-full bg-white md:bg-transparent">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-label="Toggle Sidebar"
          className="text-slate-900 hover:text-gray-600 bg-white rounded-md p-1 lg:hidden"
        >
          {sidebarOpen ? (
            <XMarkIcon className="h-7 w-7" aria-hidden="true" />
          ) : (
            <Bars3Icon className="h-7 w-7" aria-hidden="true" />
          )}
        </button>
        <img src='/nimages/logo-org.png' className='block cursor-pointer md:hidden w-32 ml-2 h-auto' alt='Flash logo' onClick={() => navigate('/dashboard')} />
      </div>
      
      {sidebarOpen && (
        <div 
          className="fixed inset-0 z-40 bg-black opacity-25 lg:hidden"
          onClick={() => setSidebarOpen(false)}
        />
      )}
  
      <div className={`${sidebarOpen ? "flex" : "hidden"} fixed inset-0 z-50 flex-col bg-white shadow-md lg:flex lg:w-72 overflow-y-auto`}>
        <div className="flex grow flex-col gap-y-1 overflow-y-auto px-4">
          <div className="flex h-16 shrink-0 items-center">
            <img className="w-32 h-auto" src="/nimages/logo-org.png" alt="Flash logo" />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-5">
              {Object.entries(submenuLabels).map(([submenu, label]) => {
                if (hasMenuItems(submenu)) {
                  return (
                    <li key={submenu} id={`submenu-${submenu.toLowerCase()}`}>
                      <div className="text-xs font-regular leading-6 transition text-slate-500 uppercase">{label}</div>
                      <ul role="list" className="-mx-2 mt-1 space-y-1">
                        {renderMenuItems(submenu)}
                      </ul>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
            <div className=" mt-auto">
              <SidebarToggle interfaceMode={interfaceMode} setInterfaceMode={setInterfaceMode} enabled={toggleState} setEnabled={setToggleState} />
              <button 
                onClick={handleLogout} 
                className="flex items-center gap-x-4 mt-3 py-3 text-sm font-semibold leading-6 w-full text-rose-700 hover:text-gray-800 "
              >
                <ArrowLeftCircleIcon className="h-6 w-4 shrink-0" aria-hidden="true" />
                <span aria-hidden="true">Logout</span>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <main className={`py-10 lg:pl-72 page-block bg-slate-50 ${mainContentClass}`}>
        <div className="page-content" id="main-content">
          {children}
        </div>
      </main>
    </div>
  );
}
