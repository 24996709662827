import React, { useState } from 'react';

export function ChangePassword() {
    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        console.log(newPassword)
        console.log(confirmNewPassword)
        // Check if new passwords match
        if (newPassword !== confirmNewPassword) {
        setErrorMessage('New passwords do not match.');
        return;
        }

        // API call to change password
        try {
        const userEmail = localStorage.getItem('userEmail'); // Assuming user email is stored in session storage
        const response = await fetch('https://api.paywithflash.com/api/change_password', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({
            email: userEmail,
            currentPassword: currentPassword,
            newPassword: newPassword,
            })
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || 'Error changing password');
        }
        alert('Password changed successfully'); // Replace with a more appropriate user feedback method
        } catch (error) {
        setErrorMessage(error.message);
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
            <div className='md:col-span-1 pt-1'>
                <h2 className="text-xl font-semibold leading-7 text-indigo-900">Change Password</h2>
                <p className="text-sm leading-6 text-indigo-900 opacity-70">Update your account password.</p>
            </div>
            <div className="md:col-span-3 overflow-hidden rounded-lg bg-white shadow mr-12 p-8">
                {/* Change Password form content */}
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="current-password" className="block text-sm font-medium text-gray-700">Current Password</label>
                        <input type="password" id="current-password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                        <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">New Password</label>
                        <input type="password" id="new-password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                    </div>
                    <div>
                        <label htmlFor="confirm-new-password" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                        <input type="password" id="confirm-new-password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
                    </div>
                    <div>
                        <button type="submit" className="inline-flex justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Change Password</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
