import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon, CurrencyDollarIcon, PlusIcon } from '@heroicons/react/24/outline';

function GetAllPublicVectorstores({ onActivateCreate }) {
    const [eventList, setEventList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [vectorstoreType, setVectorstoreType] = useState('All'); // Added for Free/Premium dropdown
    const [filteredEventList, setFilteredEventList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    let navigate = useNavigate();

    useEffect(() => {
        fetch(`https://api.paywithflash.com/api/get_all_public_vectorstores`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setEventList(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching the data', error);
            });
    }, []);

useEffect(() => {
    // Filter by name, ID, and Free/Premium dropdown
    const filtered = eventList.filter(vectorstore => 
        (vectorstore.vectorstoreName.toLowerCase().includes(searchTerm.toLowerCase()) ||
         vectorstore.vectorstoreID.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (vectorstoreType === 'All' || (vectorstoreType === 'Free' && vectorstore.vectorstore_price === 0) || (vectorstoreType === 'Premium' && vectorstore.vectorstore_price > 0))
    );
    setFilteredEventList(filtered);
}, [eventList, searchTerm, vectorstoreType]);


    const handleViewVectorstore = (vectorstoreID) => {
        navigate(`/vectorstore-detail-view?vectorstoreID=${vectorstoreID}&origin=global`);
    };

    return (
<div className="mx-auto w-full">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <header className="flex items-center justify-between border-b border-gray-100 px-4 py-4">
                    <div className="flex items-center gap-2">
                        <input 
                            type="text" 
                            placeholder="Search by name or ID..." 
                            value={searchTerm} 
                            onChange={e => setSearchTerm(e.target.value)} 
                            className="p-2 border rounded text-sm focus-within:ring-purple-800 egg-filter"
                        />
                        <select 
                            value={vectorstoreType}
                            onChange={e => setVectorstoreType(e.target.value)}
                            className="p-2 border rounded text-sm focus-within:ring-purple-800 egg-filter"
                        >
                            <option value="All">All</option>
                            <option value="Free">Free</option>
                            <option value="Premium">Premium</option>
                        </select>
                    </div>

                    
                    {/* Modified this button to use onActivateCreate prop */}
                    <button
                        onClick={onActivateCreate}
                        className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Create a new vectorstore
                    </button>
                </header>

                <ul role="list" className="divide-y divide-gray-100">
                    {isLoading ? (
                        Array.from({ length: 2 }).map((_, index) => (
                            <li key={index} className="px-4 py-6">
                                {/* Your loading skeletons here */}
                            </li>
                        ))
                    ) : filteredEventList.length > 0 ? (
                        filteredEventList.map((vectorstore) => (
                            <li 
                                key={vectorstore.vectorstoreID}
                                className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-4 cursor-pointer hover:bg-gray-100"
                                onClick={() => handleViewVectorstore(vectorstore.vectorstoreID)}
                            >
                                <img
                                    src={"https://nostreggs.io/wp-content/uploads/2023/09/Design-sans-titre-58.png"}
                                    alt={vectorstore.vectorstoreName}
                                    className="flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 egg-image"
                                />
                                <div className="min-w-0 flex-auto">
                                    <h2 className="text-sm font-medium leading-6 text-gray-900 egg-name">{vectorstore.vectorstoreName}</h2>
                                    <p className="mt-1 text-xs leading-5 text-gray-400 truncate-multiline egg-details">{vectorstore.vectorstore_description}</p>
                                    <div className="flex items-center gap-x-2.5 text-xs leading-5 text-gray-400 egg-footer">
                                        <CurrencyDollarIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
                                        <p className="whitespace-nowrap egg-price">{vectorstore.vectorstore_price} sats</p>
                                        <UserIcon className="h-4 w-4 text-gray-600 ml-3" aria-hidden="true" />
                                        <p className="egg-owner">{vectorstore.createdBy && vectorstore.createdBy.length > 12 ? `${vectorstore.createdBy.substring(0, 12)}...` : vectorstore.createdBy}</p>
                                    </div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className="px-4 py-6 text-center text-gray-500">No vectorstore found.</li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default GetAllPublicVectorstores;
