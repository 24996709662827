import { useState } from 'react';
import HeadingSection from '../components/HeadingSection';



// Replace these with your actual sidebar navigation items and icons
const sidebarItems = [
  { name: 'Introduction', href: '#introduction', icon: '📚' },
  { name: 'Getting Started', href: '#getting-started', icon: '🚀' },
  { name: 'APIs', href: '#apis', icon: '💻' },
];

// Replace these with the actual section navigation items for the current page
const sectionItems = [
  { name: 'Sub-section 1', href: '#sub-section-1' },
  { name: 'Sub-section 2', href: '#sub-section-2' },
];

export default function DocumentationPage() {
  const [activeItem, setActiveItem] = useState(sidebarItems[0].name);

  // Sample data for HeadingSection
  const headerData = {
    title: 'Documentation Page',
    subtitle: 'Learn how to use our product.',
    links: [
      { name: 'Introduction', href: '#introduction' },
      { name: 'Getting Started', href: '#getting-started' },
      { name: 'APIs', href: '#apis' },
    ],
  };

  return (
    
    <div className="min-h-screen bg-gray-900 text-gray-200">

      {/* Header */}
      <HeadingSection 
        title={headerData.title} 
        subtitle={headerData.subtitle} 
        links={headerData.links} 
      />

      <div className="max-w-10xl mx-auto flex">

        
{/* Left Sidebar */}
<nav className="w-64 flex-shrink-0 mr-6 bg-gray-800 text-gray-200 p-2 h-screen">
  <ul>
    {sidebarItems.map((item) => (
      <li key={item.name} onClick={() => setActiveItem(item.name)} 
          className={`mb-2 sidebar-btn ${activeItem === item.name ? 'active' : ''}`}>
        <a href={item.href} className="sidebar-link flex items-center space-x-2">
          <span>{item.icon}</span> <span>{item.name}</span>
        </a>
      </li>
    ))}
  </ul>
</nav>

        {/* Main Content */}
        <main className="flex-grow p-4">
          <h2 id="introduction" className="text-4xl font-bold">Introduction</h2>
          <p className="mt-4 text-lg">
            This is an introduction to our documentation. Replace this text with your actual content.
          </p>
          
          <h2 id="getting-started" className="text-4xl font-bold mt-10">Getting Started</h2>
          <p className="mt-4 text-lg">
            Here's how you get started with our product. Replace this text with your actual content.
          </p>

          <h3 id="sub-section-1" className="text-3xl font-bold mt-10">Sub-section 1</h3>
          <p className="mt-4 text-lg">
            This is the first sub-section of the Getting Started section.
          </p>

          <h3 id="sub-section-2" className="text-3xl font-bold mt-10">Sub-section 2</h3>
          <p className="mt-4 text-lg">
            This is the second sub-section of the Getting Started section.
          </p>

          <h2 id="apis" className="text-4xl font-bold mt-10">APIs</h2>
          <p className="mt-4 text-lg">
            Here's information about our APIs. Replace this text with your actual content.
          </p>
        </main>

        {/* Right Sidebar */}
        <nav className="w-64 flex-shrink-0 ml-6">
          <h2 className="text-2xl font-bold mb-4">On this page</h2>
          <ul>
            {sectionItems.map((item) => (
              <li key={item.name} className="mb-2">
                <a href={item.href} className="hover:text-orange-500">{item.name}</a>
              </li>
            ))}
          </ul>
        </nav>

      </div>
    </div>
  );
}
