import React, { useState, useEffect } from 'react';
import { nip04 } from 'nostr-tools';
import { SketchPicker } from 'react-color';
import { TextInput, NumberInput, FormHeader, PaymentInfo, CancelButton, ConfirmButton, SimpleDropdown, WalletDropdown, CurrencyDropdown, LifetimeDurationDropdown, IconDropdown, NumberInputWithConverter, SubscriptionPlanList } from '../comp/InputsLib.js';
import * as Utils from '../utils.js';
import {
  SummaryTitle, SummaryPrice, SummaryWallet, SummaryDuration, SummaryUser, SummarySnippet, SummaryCreationDate, SummaryCopyButton, SummaryFooter, SummarySubscriptionPlanView
} from '../comp/SummaryFields.js';

const SubscriptionPlanForm = ({ close }) => {
  // Get env var
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY
  const ourNostrWalletConnectUrl = process.env.REACT_APP_NOSTR_WALLET_CONNECT_URL

  // Get the values from session storage
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  let userAbout = localStorage.getItem('userAbout') || "";

  const [title, setTitle] = useState('');
  const [duration, setDuration] = useState('lifetime');
  const [expiryDate, setExpiryDate] = useState();
  const [vimeo_ott_api_key, setVimeo_ott_api_key] = useState('');
  const [vimeo_ott_product_id, setVimeo_ott_product_id] = useState('');
  const [flash_image_url, setFlash_image_url] = useState('');

  // Add state for wallets
  const [wallets, setWallets] = useState([]);
  const [wallet, setWallet] = useState('Alby');
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const [selectedWalletLnAddress, setSelectedWalletLnAddress] = useState('');
  const [selectedWalletNwc, setSelectedWalletNwc] = useState('');
  // States for Subscription Plans
  const [plans, setPlans] = useState([]);
  const [snippet, setSnippet] = useState('');

  const handlePlansChange = (updatedPlans) => {
    setPlans(updatedPlans);
  };



  // Effect hook to fetch wallets when the component mounts
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userHexPublicKey)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        const results = [];
  
        for (const item of data) {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
            decrypted_nwc_url = await nip04.decrypt(userHexPrivateKey, publicKey, item.nwc_url);
          } else {
            decrypted_nwc_url = ""
          }
          
          results.push({
            id: item.id,
            user_publicKey: item.user_public_key,
            nwc_url: decrypted_nwc_url,
            wallet_name: item.wallet_name,
            user_wallet_name: item.user_wallet_name,
            ln_address: item.ln_address
          });
        }
        setWallets(results);
  
        // Set the first wallet as the default wallet and its ID
        if (results.length > 0) {
          setWallet(results[0].user_wallet_name);
          setSelectedWalletId(results[0].id); // Set the ID of the first NWC
          setSelectedWalletNwc(results[0].nwc_url);
        }
      } catch (error) {
        console.error('Error fetching wallets:', error);
      }
    };
  
    fetchWallets();
  }, [userHexPrivateKey, userHexPublicKey, publicKey]); // Add dependencies if needed



  // Effect hook to set the expiryDate of the Flash when the user changes the duration
  useEffect(() => {
    if (duration === 'lifetime') {
      // Option 1: Set a far future date for 'lifetime'
      const farFuture = new Date();
      farFuture.setFullYear(farFuture.getFullYear() + 100); // 100 years in the future
      setExpiryDate(farFuture);
  
      // Option 2: Handle 'lifetime' differently
      // setExpiryDate(null); // If your application logic can handle null as 'lifetime'
    } else {
      // Calculate the expiry date based on the selected duration
      const durationInDays = parseInt(duration.split(' ')[0]); // This will parse the numeric part from the duration string
      const newExpiryDate = new Date();
      newExpiryDate.setDate(newExpiryDate.getDate() + durationInDays); // Add the duration to the current date
      setExpiryDate(newExpiryDate); // Set the new date as the expiry date
    }
  }, [duration]); // This effect runs whenever the duration state changes
  


  const handleWalletChange = (e) => {
    const selectedWalletId = e.target.value;
    const selectedWallet = wallets.find(wallet => wallet.id.toString() === selectedWalletId);
  
    if (selectedWallet) {
      setWallet(selectedWallet.user_wallet_name); // Sets the wallet name
      setSelectedWalletId(selectedWallet.id); // Sets the wallet ID
      setSelectedWalletLnAddress(selectedWallet.ln_address); // Sets the LN address
      setSelectedWalletNwc(selectedWallet.nwc_url);
    }
  };

  
  const saveMembershipToDb = (flashId) => {
    const savePromises = plans.map(plan => {
      return fetch('https://api.paywithflash.com/vimeo_ott/api/save_vimeo_ott_membership', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              'name': plan.name,  
              'description': plan.description,
              'vimeo_ott_flash_id': flashId,
              'price': plan.price,
              'currency': plan.currency,
              'renewal_intervals': plan.duration,
              'creation_date': new Date().toISOString(),
              'owner_public_key': userHexPublicKey
          })
      })
      .then(response => {
          // Check if the response is ok (status in the range 200-299)
          if (!response.ok) {
              // Throw an error with response status
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
      })
      .then(data => {
          // Log success message
          console.log('Membership created successfully:', data);
      })
      .catch(error => {
          // Log any errors, including HTTP errors
          console.error('Error creating membership:', error);
      });
    });

    return Promise.all(savePromises);
  };



  const handleConfirm = async () => {
    // Logic for what happens when 'Confirm' is clicked
    event.preventDefault();
    try {
        // Prepare the data to be sent to the server for creating a new flash
        const formData = new FormData();
        formData.append('flash_name', title);
        formData.append('wallet_id', selectedWalletId);
        formData.append('user_public_key', userHexPublicKey);
        formData.append('flash_script', ''); // Initially empty
        formData.append('flash_creation_date', new Date().toISOString());
        formData.append('flash_expiry_date', expiryDate.toISOString()); // Directly using toISOString
        formData.append('flash_type', 'Vimeo OTT Subscription Plan');
        formData.append('vimeo_ott_api_key', vimeo_ott_api_key);
        formData.append('vimeo_ott_product_id', vimeo_ott_product_id);
        formData.append('flash_image_url', flash_image_url);
    
        // Make an API call to save the flash to the database
        let response = await fetch('https://api.paywithflash.com/vimeo_ott/api/save_vimeo_ott_flash_to_db', {
          method: 'POST',
          body: formData,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        const saveResult = await response.json();
        console.log('Flash saved response:', saveResult); // Check the API response
        const flashId = saveResult.flash_id;
        console.log('Flash saved:', saveResult);

        // Save the memberships to the db
        await saveMembershipToDb(flashId);
        

        const generatedSnippet = `https://app.paywithflash.com/external-login/vimeo_ott?flashId=${flashId}`;
        setSnippet(generatedSnippet)
    
        // Prepare data to update the flash with the generated snippet
        const updateData = new FormData();
        updateData.append('flash_id', flashId);
        updateData.append('flash_name', title);
        updateData.append('wallet_id', selectedWalletId);
        updateData.append('user_public_key', userHexPublicKey);
        updateData.append('flash_script', generatedSnippet);
        updateData.append('flash_creation_date', new Date().toISOString());
        updateData.append('flash_expiry_date', expiryDate.toISOString()); // Again using toISOString
        updateData.append('flash_type', 'Vimeo OTT Subscription Plann');
        updateData.append('vimeo_ott_api_key', vimeo_ott_api_key);
        updateData.append('vimeo_ott_product_id', vimeo_ott_product_id);
        updateData.append('flash_image_url', flash_image_url);
    
        // Make an API call to update the flash in the database
        response = await fetch('https://api.paywithflash.com/vimeo_ott/api/update_vimeo_ott_flash_in_db', {
          method: 'POST',
          body: updateData,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok during update');
        }
    
        const updateResult = await response.json();
        console.log('Flash updated with snippet:', updateResult);
    
  
    } catch (error) {
      console.error("Error during flash creation, update, and snippet generation:", error);
    }
  };
  
  const handleCopy = async (snippetText) => {
    // Logic for what happens when 'Copy' is clicked
    try {
      await navigator.clipboard.writeText(snippetText);
    } catch (error) {
      console.error('Failed to copy snippet:', error);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleVimeoOTTAPIKeyChange = (e) => {
    setVimeo_ott_api_key(e.target.value);
  };

  const handleVimeoOTTProductIdChange = (e) => {
    setVimeo_ott_product_id(e.target.value);
  };

  const handleFlashImageUrlChange = (e) => {
    setFlash_image_url(e.target.value);
  };

return (
  <div className="flex flex-col modal-base">
    <div className="flex-1 p-14 overflow-auto h-screen">
      <FormHeader
        title="Create a Vimeo OTT Subscription Plan"
        subtitle="Add a subscription plan to your Vimeo OTT app and receive payments in Bitcoin through Lightning. Pause or delete anytime."
        infoBadge={{
          text: "Learn more about Subscription plans",
          color: "bg-gray-100 text-gray-600"
          }}
        newEggBadge={{
          text: "New Sub",
          color: "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-600/20 mb-3"
          }}
      />

      {/* Form Inputs */}
      <div className="inputform">
        <TextInput label="Title" placeholder="Enter the title" value={title} onChange={handleTitleChange} />
      </div>
      <div className="inputform">
        <TextInput label="Vimeo API Key" placeholder="Enter your Vimeo OTT API key" value={vimeo_ott_api_key} onChange={handleVimeoOTTAPIKeyChange} />
      </div>
      <div className="inputform">
        <TextInput label="Vimeo Product ID" placeholder="Enter the Vimeo OTT product ID" value={vimeo_ott_product_id} onChange={handleVimeoOTTProductIdChange} />
      </div>
      <div className="inputform">
        <TextInput label="Branding Image Url" placeholder="Enter your brand image url" value={flash_image_url} onChange={handleFlashImageUrlChange} />
      </div>
      <div className="inputform">
        <WalletDropdown label="Wallet" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
      </div>
      <div className="inputform">
        <SubscriptionPlanList label="Subscripion Plans" id="plans" name="plans" plans={plans} onPlansChange={handlePlansChange} />
      </div>
      <div className="inputform">
        <ConfirmButton onClick={handleConfirm}> Create subscription plan </ConfirmButton>
        <CancelButton onClose={close} />
      </div>
      <div className="inputform">
        <PaymentInfo text="Payments will go directly to your connected wallet." />
      </div>

      {/* Summary Column Content Now Here */}
      <div className="p-0">
        <div className="">
          {/* <SummaryTitle title={title || 'Flash Title'} />
          <SummaryCreationDate creationDate={formattedDate} />
          <SummaryWallet wallet={wallet} />
          <SummaryDuration duration={duration} />
          <SummaryUser user={user} /> */}
          {/* <SummarySubscriptionPlanView plans={plans} /> */}
          <SummarySnippet snippet={snippet} />
          <SummaryCopyButton text="Copy Url" onClick={() => handleCopy(snippet)} />
          <SummaryFooter />
        </div>
      </div>
    </div>
  </div>
);
};

export default SubscriptionPlanForm;
