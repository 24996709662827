import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaStar, FaUsers, FaHandsHelping } from 'react-icons/fa'; // Import icons

const BitcoinFilmFestTable = ({ sheetData, selectedTeam }) => {
    const navigate = useNavigate();

    // Filter sheetData based on the selected team if any
    const filteredData = selectedTeam ?
        sheetData.filter(item => item.team === selectedTeam) :
        sheetData;

    const sortedData = (Array.isArray(filteredData) ? filteredData : []).sort((a, b) => b.total_points - a.total_points).slice(1);


    // Utility function to get color and icon for each ticket type
    const getTicketTypeDetails = (ticketType) => {
        switch (ticketType) {
            case 'Pleb':
                return { color: 'bg-blue-500', icon: <FaUser /> };
            case 'SuperPleb':
                return { color: 'bg-green-500', icon: <FaStar /> };
            case 'Team':
                return { color: 'bg-red-500', icon: <FaUsers /> };
            case 'Volunteer':
                return { color: 'bg-yellow-500', icon: <FaHandsHelping /> };
            default:
                return { color: 'bg-gray-500', icon: <FaUser /> }; // Default case
        }
    };


    // Function to return the position or medal based on index
    const getPositionOrMedal = (index) => {
        switch (index) {
            case 0:
                return '🥇'; // First place
            case 1:
                return '🥈'; // Second place
            case 2:
                return '🥉'; // Third place
            default:
                return `#${index + 1}`; // Other positions
        }
    };

    return (
        <div className="mt-6 rounded-lg overflow-hidden shadow-sm" style={{ height: '800px', overflowY: 'auto' }}>
            <div className="overflow-x-auto">
                <h2 className='text-5xl text-blue-300 ml-6 font-bold'>Player points</h2>
                <table className="table w-full">
                    <thead className='bg-gray-800 text-gray-200' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <tr><th>Image</th><th>Position</th><th>Name</th><th>Team</th><th>Points</th><th>Action</th></tr>
                    </thead>
                    <tbody>
                        {sortedData.map((item, index) => {
                            const { color, icon } = getTicketTypeDetails(item.ticketType);
                            return (
                                <tr key={index}>
                                    <td><img src="https://pbs.twimg.com/profile_images/1725239923903451136/JLLqXuqG_400x400.jpg" alt="Entry" className="w-10 h-10 rounded-full" /></td>
                                    <td className='text-xl'>{getPositionOrMedal(index)}</td>
                                    <td>{item.firstName}</td>
                                    <td>{item.team}</td>
                                    <td>{item.total_points}</td>
                                    <td><button className="btn btn-ghost btn-xs" onClick={() => navigate(`/bitcoin-filmfest/${item.email}`, { state: { player: item } })}>Details</button></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
    
};

export default BitcoinFilmFestTable;
