import React, { useEffect, useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { CalendarDaysIcon, CreditCardIcon, UserCircleIcon } from '@heroicons/react/20/solid'
import { BiWallet } from 'react-icons/bi';


const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}






export default function Summary({ wallet, summaryError, invoiceDetails, onConfirm, onBack }) {
    const [formattedDate, setFormattedDate] = useState('');
    const [loading, setLoading] = useState(false);

    // Function to handle plan selection
    const handleConfirm = () => {
      setLoading(true);
      onConfirm();
    };


    // Effect hook to get the current date in right format for display
    function formatDate(date) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
            case 1:  return "st";
            case 2:  return "nd";
            case 3:  return "rd";
            default: return "th";
            }
        }

        return `${month} ${day}${suffix(day)} ${year}`;
    }

    useEffect(() => {
        const currentDate = new Date();
        setFormattedDate(formatDate(currentDate));
    }, []);

    return (
      <>
        {
          loading && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-white">
            </div>
          )
        }
        <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
          <div className="text-center text-md text-gray-600 mb-6 px-10">
            Summary.
          </div>
        </div>
        <div className="mx-auto w-full max-w-xl sm:max-w-2xl px-4">
          <div className='px-6'>
            <h2 className="sr-only">Summary</h2>
            <div className="rounded-lg bg-slate-100" >
              <dl className="flex flex-wrap mb-4">
                <div className="flex-auto pl-6 pt-6">
                  <dt className="text-md font-bold leading-6 text-slate-900">Amount</dt>
                  <dd className="mt-1 text-base font-medium leading-6 text-slate-900">
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoiceDetails.usdValue)}
                    {' '}(<i className="fak fa-bold-italic"></i>{invoiceDetails.satoshi.toLocaleString()})
                  </dd>
                </div>
                <div className="flex-grow self-end px-6 pt-4">
                  <dt className="sr-only">Status</dt>
                  <dd className="inline-flex items-center text-sm rounded-md bg- px-2 py-1 font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    Pay Invoice
                  </dd>
                </div>
                <div className="mt-6 flex w-full flex-none gap-x-4 px-6">
                  <dt className="flex-none">
                    <span className="sr-only font-semibold text-slate-700">Date</span>
                    <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd className="text-md leading-6 text-slate-600">
                    <span className='font-semibold'> Date: </span>
                    <time dateTime="2023-01-31">{formattedDate}</time>
                  </dd>
                </div>
                <div className="mt-1 flex w-full flex-none gap-x-4 px-6 mb-6">
                  <dt className="flex-none ">
                    <span className="sr-only">Status</span>
                    <BiWallet className="h-6 w-5 text-gray-400" aria-hidden="true" />
                  </dt>
                  <span className='font-semibold text-md text-slate-600'> Paid with:</span>
                  <dd className="text-md text-slate-600 -ml-3"> {wallet.user_wallet_name}</dd>
                </div>
              </dl>
            </div>
          </div>
          {summaryError && (
            <p className='mt-4 text-sm text-red-500'>Error processing payment. Choose another wallet or try again later.</p>
          )}
          <div className="mt-4 px-6 py-6 flex flex-col sm:flex-row justify-center gap-4">
  
            <button
              className="flex items-center w-full text-md justify-center rounded-lg bg-purple-700 px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
              onClick={handleConfirm}
            >
              Confirm & Pay
            </button>
  
            <button
              className="flex items-center w-full text-md justify-center rounded-lg bg-white  px-3 py-3 text-sm font-semibold leading-6 text-purple-600 hover:text-purple-800 focus:outline-none focus:ring-0 focus:ring-purple-800  custom-button2"
              onClick={onBack}
            >
              Back to Wallet Selection
            </button>
          </div>
        </div>
      </>
    )
}
