// SummaryFields.js
import React, { useState, useEffect } from 'react';
import { BiWallet, BiTimeFive, BiUser, BiCopy, BiCheck } from 'react-icons/bi';
import {
  ClipboardIcon,
  EyeIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';

import SummarySubscriptionPlans from './SummarySubscriptionPlans'


export const SummarySubscriptionPlanView = ({ plans }) => (
  <SummarySubscriptionPlans plans={plans} />
);

export const SummaryTitle = ({ title }) => (
  <div className="flex justify-between items-center">
    <dt className="text-xl font-semibold leading-6 text-gray-900 mb-1">{title || 'Flash Title'}</dt>
    <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
      <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20">
        Active
      </span>
    </dd>
  </div>
);


export const formatPrice = (price, currency) => {
  // Format price with commas
  const formattedPrice = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return currency === 'Satoshis' ? `${formattedPrice} sats` : `$${formattedPrice}`;
};

export const SummaryPrice = ({ price, currency, convertedValue }) => (
  <div>
    <div className="text-base font-semibold leading-6 text-gray-900">
      {formatPrice(price, currency)}
    </div>
    <p className="text-sm text-gray-400 font-regular mt-1">
      {convertedValue}
    </p>
  </div>
);

export const SummaryConversion = ({ convertedValue }) => (
  <p className="text-sm text-gray-400 font-regular mt-1">
    {convertedValue}
  </p>
);

export const SummaryWallet = ({ wallet }) => (
  <div className="mt-6 flex gap-x-4">
    <BiWallet className="h-6 w-5 text-gray-400" aria-hidden="true" />
    <dd className="text-sm leading-6 text-gray-500">{wallet}</dd>
  </div>
);

export const SummaryDuration = ({ duration }) => (
  <div className="mt-3 flex gap-x-4">
    <BiTimeFive className="h-6 w-5 text-gray-400" aria-hidden="true" />
    <dd className="text-sm leading-6 text-gray-500">{duration}</dd>
  </div>
);

export const SummaryUser = ({ user }) => (
  <div className="mt-3 flex gap-x-4">
    <BiUser className="h-6 w-5 text-gray-400" aria-hidden="true" />
    <dd className="text-sm leading-6 text-gray-500">{user}</dd>
  </div>
);

export const SummaryKey = ({ keyForWebhook, label}) => {

  const [visible, setVisible] = useState (false);

  const handleVisibleChange = () => {
    setVisible (!visible);
  }

  return (
    <div className="mt-2">
    <label className="block text-sm font-medium text-indigo-300 mb-2 mr-2">{label}</label>
    <div className='flex'>
      <input
        type="text"
        rows={1}
        name="key"
        id="key"
        disabled={true}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mr-2"
        value={keyForWebhook ? (visible ? keyForWebhook : '********************************************************************************') : ''} // Use value instead of defaultValue to bind the state
      >
    </input>
    <EyeIcon className="h-7 w-7 text-gray-500 eye-icon m-auto" onClick = {handleVisibleChange}/>
    </div>
    </div>
  )
};

export const SummarySnippet = ({ snippet, label }) => (
  <div className="mt-2">
    <label className="block text-sm font-medium text-indigo-300 mb-2 mr-2">{label}</label>
    <textarea
      disabled={true}
      rows={4} // Increased rows for better readability
      name="snippet"
      id="snippet"
      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      value={snippet} // Use value instead of defaultValue to bind the state
    />
  </div>
);

export const SummaryCreationDate = ({ creationDate }) => (
  <p className="max-w-4xl text-xs text-gray-400 mt-0 mb-4">{creationDate}</p>
);

export const SummaryCopyButton = ({ text, onClick }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = () => {
    setIsCopied(true);
    onClick(); // Call the passed onClick function
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <div className="mt-6">
      <button 
        onClick={handleClick} 
        className={`rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm flex items-center ${
          isCopied ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-600 hover:bg-blue-700'
        }`}
      >
        {isCopied ? 'Copied!' : text} 
        {isCopied ? <BiCheck className="ml-2" aria-hidden="true" /> : <BiCopy className="ml-2" aria-hidden="true" />}
      </button>
    </div>
  );
};

export const SummaryFooter = () => (
  <div className="text-xs text-gray-400 text-medium mt-3 mb-8">
    <p className="credit-text">Powered by </p>
    <img className="h-18 w-auto credit-logo" src="/flash-logo-dark.png" alt="Your Company"/>
  </div>
);