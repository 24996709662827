import React, { useEffect, useState, useRef } from 'react';
import QRCode from 'qrcode.react';

// import PayWithFlashButton from '../../payWithFlash/payWithFlashButton';
import LnInvoiceHashDisplayAndCopy from '../paymentComponents/LnInvoiceHashDisplayAndCopy';




// Function used to create the Zap Invoice
async function createZapInvoiceWithNWC(userHexPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userPublicKey: userHexPublicKey,
            encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
            amount: amount,
            memo: memo
        }),
    });
    const data = await response.json();

    if (!response.ok) {
        throw new Error(data.message || 'Failed to create zap invoice');
    }
    // const data = "lnbc10u1pj6j4gzpp5tzam0ynwcfv3prxmlj59lg40ssw3qfdyceeq0kzsmdg7vrg9kejqdpv2pshjmt9de6zqen0wgsy67fq2pshjmt9de6zqnrfde4scqzzsxqyz5vqsp5nmgtcgn3lnw2amw7rugnmtrdu9mfcw7kend7n3z5mk8rnh2symps9qyyssqvg8604jgpkc8eyknyekr5l8gyu8glclq09xdk8fcjep2jlj5cgrr53uc5lsmlsepe50hgvtgpdjd8v7w3xwdc7qwv02yq2rph9v4ulqqt69u8p"
    return data;
}


// Function used to send the Zap Invoice to the user and request payment via webln
async function requestWebLnPayment(invoice) {
    if (typeof window.webln === "undefined") {
        throw new Error("No WebLN available. Get a bitcoin lightning wallet. We recommend www.getalby.com");
    }

    try {
        await window.webln.enable();
        const result = await window.webln.sendPayment(invoice);
        return result;
    } catch (error) {
        console.error("Failed to pay invoice:", error);
    }
}



// Function to check if the invoice was paid
async function checkInvoicePayment(userHexPublicKey, encryptedUserNostrWalletConnectUrl, invoice) {
    const startTime = Date.now();
    let isPaid = false;

    while (Date.now() - startTime < 300000) { // Loop for max 5 minutes
        try {
            const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/check_invoice_payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userPublicKey: userHexPublicKey,
                    encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
                    invoice: invoice
                }),
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to check payment');
            }

            if (data.is_paid) {
                isPaid = true;
                break;
            }

            // If not paid, wait 1 second before trying again
            await new Promise(resolve => setTimeout(resolve, 1000));

        } catch (error) {
            console.error("Error checking invoice payment:", error);
            // We wait 1 second before trying again
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }

    return isPaid;
}


// Function to save the transaction to table transactions of the db
async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
    const formData = new FormData();
    formData.append('from_public_key', from_public_key);
    formData.append('to_public_key', to_public_key);
    formData.append('transaction_date', transactionDate);
    formData.append('amount_in_sats', amountInSats);
    formData.append('value_in_usd', valueInUsd);
    formData.append('flash_id', flashId);
    formData.append('flash_name', flashName);
    formData.append('flash_type', flashType); // Assuming this is also a new requirement
    formData.append('to_wallet_id', toWalletId);
    formData.append('to_user_wallet_name', toUserWalletName);
    formData.append('from_wallet_id', fromWalletId);
    formData.append('from_user_wallet_name', fromUserWalletName);
    formData.append('lnurl', lnurl);

    try {
        const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to save transaction to database');
        }

        const successData = await response.json();
        console.log('Transaction successfully saved to database:', successData);

        return successData;
    } catch (error) {
        console.error('Error saving transaction to database:', error);
        throw error;
    }
}






// Handles payment via extension
const handlePaymentClick = async (invoice) => {
    if (!invoice) {
        console.error('No invoice to pay');
        return;
    }
    try {
        const result = await requestWebLnPayment(invoice);
        if (result) {
            // Payment status is already being checked in the background
            // Optionally do something with result
        }
    } catch (error) {
        console.error('Error in handling payment:', error);
    }
};





// handlePaymentClick function handles web extension payment and sets hasPaidWithExtension to true
const handlePayWithFlashSuccess = async () => {
    try {
        // Get current date
        const currentDate = new Date().toISOString();

        await saveTransactionToDB(payerUserPublicKey, flashDetails.user_public_key, currentDate, priceInSats, priceInDollars, flashId, flashDetails.name, "Payment Link", walletData.id, walletData.user_wallet_name, payerSelectedWallet.id, payerSelectedWallet.user_wallet_name, invoice)

        // Calculate the fee and price minus fee
        const feePercentage = 1; // 1%

        // Assuming priceInSats is already defined and is the total price in Satoshis
        let satsFee = priceInSats * (feePercentage / 100); // Calculate the fee
        satsFee = Math.round(satsFee); // Rounding to the nearest whole number

        // Pay fee to flash
        const response = await payFeeToFlash(nwcUrl, satsFee, `Fee for flash id: ${flashId}`);
        const fee_invoice = response.invoice;
        const isPaid = response.is_paid;

        // Update UI state at the end
        setIsPaid(true);
    } catch (error) {
        console.error('Error in Pay With Flash', error);
    }
};



const PointOfSalePayment = ({ amount, totalInUSD, encryptedUserNostrWalletConnectUrl, handlePaymentSuccess, handleBack }) => {

    const userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    
    const [currentPrice, setCurrentPrice] = useState();
    const [walletData, setWalletData] = useState({});
    const [nwcUrl, setNwcUrl] = useState('');
    const [priceInDollars, setPriceInDollars] = useState('');
    const [priceInSats, setPriceInSats] = useState('');
    const [bitcoinPrice, setBitcoinPrice] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const [invoice, setInvoice] = useState('');

    const [loading, setLoading] = useState(false);

    const [payerUserPublicKey, setPayerUserPublicKey] = useState('') || "";
    const [payerSelectedWallet, setPayerSelectedWallet] = useState({});


    // useEffect to create an invoice
    useEffect(() => {
        const memo = `Flash Payment`;

        setLoading(true);
        createZapInvoiceWithNWC(userHexPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo)
            .then(data => {
                setInvoice(data.invoice);
                setLoading(false);
            })
            .catch(error => {
                console.error('Failed to create invoice:', error);
                setLoading(false);
                // Handle error (e.g., show an error message to the user)
            });
    }, [amount]);

    // useEffect to check the invoice payment status
    useEffect(() => {
        if (invoice) {
            checkPaymentStatus(encryptedUserNostrWalletConnectUrl, invoice).then((paid) => {
                if (paid) {
                    handlePaymentSuccess();
                }
            });
        }
    }, [invoice]); // React to changes in `invoice`



    // Checks the payment status of the invoice
    const checkPaymentStatus = async (encryptedUserNostrWalletConnectUrl, invoice) => {
        const paymentStatus = await checkInvoicePayment(userHexPublicKey, encryptedUserNostrWalletConnectUrl, invoice);
        if (paymentStatus) {
            // Payment success
            handlePaymentSuccess(); // Call the callback function passed as a prop
            setIsPaid(true);
        }
    };



    return (
        <>
            {loading ? (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12"></div>
                </div>
            ) : (
                <div className="flex justify-center items-center">
                    <div className="w-full max-w-md p-8 space-y-4 shadow-lg rounded-2xl bg-white">
                        {!isPaid ? (
                            <>
                                <h3 className="text-center text-lg font-semibold text-gray-700">Make Payment</h3>
                                {/* QR Code Centered */}
                                <div className="flex justify-center">
                                    <QRCode
                                        value={invoice}
                                        size={180}
                                        bgColor={"#ffffff"}
                                        fgColor={"#000000"}
                                        level={"L"}
                                        includeMargin={false}
                                        renderAs={"svg"}
                                        imageSettings={{
                                            src: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png",
                                            excavate: true,
                                            height: 40,
                                            width: 40,
                                        }}
                                    />
                                </div>

                                {/* Payment Information */}
                                <div className="text-center">
                                    <div className="flex items-center justify-center mb-2">
                                        <p className="text-red-400 text-xl font-bold">
                                            ${parseFloat(totalInUSD).toLocaleString()}
                                            <span className="text-gray-400 text-xs font-bold ml-1">
                                                ({parseInt(amount).toLocaleString()} satoshis)
                                            </span>
                                        </p>
                                    </div>
                                    <LnInvoiceHashDisplayAndCopy value={invoice} />
                                    <div className="mt-6 mb-0 text-sm text-center">
                                          <span className="text-gray-700 mr-1"> To re-generate the QR Code </span>
                                          <a href="#" onClick = { handleBack } className="text-blue-600 hover:text-blue-800">→ Back</a>
                                        </div>
                                </div>



                            </>
                        ) : (
                            <div className="text-center">
                                <p className="font-semibold text-green-500">Payment successful! Thank you.</p>
                            </div>
                        )}


                        <div className="flex justify-center space-x-4 mt-4">
                            <button
                                className="text-lg bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                onClick={() => handlePaymentClick(invoice)}
                            >
                                Pay with Extension
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );


};

export default PointOfSalePayment;