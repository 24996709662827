import React, { useState, useEffect } from 'react';
import { getPublicKey, generatePrivateKey } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19, SimplePool, matchFilter, Kind } from 'nostr-tools';

const { npubEncode, nsecEncode, decode } = nip19;

const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

const convertNostrToHex = (nostr) => {
  const { type, data } = decode(nostr);
  
  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid nostr. Nostr must be "npub" or "nsec".');
  }
};

const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();
  const filters = [{ pubkey: publicKey, kinds: [0] }];

  try {
    const events = await pool.list(relayUrls, filters);
    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);

    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");

    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      const userName = metadata.name || 'Unknown User';
      const userPictureUrl = metadata.picture || 'default_picture_url'; // Replace with a default picture URL
      const userAbout = metadata.about || 'No description available';

      return { userName, userPictureUrl, userAbout };
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', // Replace with a default picture URL
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};



function LoginWithNostr(props) {

  // Access props using props.propertyName
  const { appName, imageUrl, onLoginSuccess, onClose} = props;
  const [privateKey, setPrivateKey] = useState('');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // New state for handling error messages


  const handleLogin = async () => {
    setErrorMessage('');
  
    if (!privateKey.trim()) {
      setErrorMessage('Please enter a Nostr private key.');
      return;
    }
  
    setLoading(true);

    let hexPrivateKey;
  
    try {
      // Logic to process privateKey...
      if (privateKey.startsWith('nsec')) {
        hexPrivateKey = convertNostrToHex(privateKey);
      } else {
        hexPrivateKey = privateKey;
      }

      const userPublicKey = getPublicKey(hexPrivateKey);
      const userPrivateKey = hexPrivateKey;

      // Fetch the metadata
      const { userName, userPictureUrl, userAbout } = await fetchUserMetadata(userPublicKey, ["wss://relay.paywithflash.com", "wss://relay.damus.io"]);
      // Call the callback function with the login data
      onLoginSuccess({ userPrivateKey, userPublicKey, userName, userPictureUrl, userAbout });
    } catch (error) {
      setErrorMessage("This Nostr private key doesn't exist or an error occurred.");
      return false; // Indicate failure
    } finally {
      setLoading(false);
    }
  
  };
  

  const togglePrivateKeyVisibility = (e) => {
    e.preventDefault();
    setShowPrivateKey(!showPrivateKey);
  };


  return (
    <>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
      <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-full h-24 mb-4 object-cover rounded-t-sm rounded-b-none" // Tailwind CSS for width and margin bottom
            src={imageUrl}
            alt="Image"
          />
          {/* Overlay label positioned absolutely within the parent div */}
          <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
            {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
          </div>
        </div>

        <div className="flex shrink-0 items-center justify-center">
          <img
            className="w-20 h-20 mb-3 object-cover rounded-full border-4 border-white -mt-12 z-20" // Tailwind CSS for width, margin bottom, and z-index
            src={imageUrl}
            alt="Image"
          />
        </div>


        {/* <div className=" flex text-center justify-center items-center">
        <div className="text-center w-fit bg-orange-100  rounded-md px-2 py-1 text-xs font-medium mb-3 text-orange-600">Subscribe in Sats ₿</div>
        </div> */}
        <h2 className="text-center text-2xl font-bold mb-0 text-black">
          {appName}
        </h2>
        <div className="text-center text-md text-gray-600 mb-6 px-10">
          Login with Nostr keys to continue.
        </div>
        <form className="space-y-3 px-10  align-middle justify-center">
          <div>
            <div className="mt-2 relative">
              <input
                id="privateKey"
                name="privateKey"
                type={showPrivateKey ? 'text' : 'password'}
                required
                value={privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
                className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-md  font-medium text-gray-600  custom-placeholder"
                placeholder='Nostr Private Key (nsec)'
              />
              <button
                onClick={togglePrivateKeyVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300 eye-icon"
              >
                <FaEye className="h-5 w-5" />
              </button>
            </div>
            {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
          </div>
          <div>
            <div className="flex w-full  mt-3 text-slate-400 text-center justify-center items-center">

              <button
                type="button"
                onClick={handleLogin}
                className="flex items-center w-full text-md justify-center rounded-lg bg-purple-700 px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
              >
                Log in
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
  
}

export default LoginWithNostr;
