import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLongLeftIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';

import SidebarLayout from '../components/SidebarLayout';
import DisplayVectorstoreDetail from '../components/VectorstoreDetailPageComponents/DisplayVectorstoreDetail'
import PrivacyStatusComponent from '../components/VectorstoreDetailPageComponents/PrivacyStatusComponent'
import PaidVectorstoreComponent from '../components/VectorstoreDetailPageComponents/PaidVectorstoreComponent'
import SaveChangesComponent from '../components/VectorstoreDetailPageComponents/SaveChangesComponent';
import AlertVectorstoreUpdated from '../components/VectorstoreDetailPageComponents/AlertVectorstoreUpdated';
import DisplayVectorstoreData from '../components/VectorstoreDetailPageComponents/DisplayVectorstoreData';
import ModalUploadMore from '../components/VectorstoreDetailPageComponents/ModalUploadMore';
import Modal from '../components/Modal';



function VectorstoreDetailView(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const vectorstoreID = queryParams.get('vectorstoreID');
    const origin = queryParams.get('origin'); // We use the origin to know where to redirect to

    let userNpub = localStorage.getItem('userNpub') || "";

    let navigate = useNavigate(); // Declare the navigate function
  
    const [vectorstore, setVectorstore] = useState({
        vectorstore_ID: '',
        vectorstore_name: '',
        vectorstore_description: '',
        last_modified_date: null,
        privacy_status: '',
        created_by: '',
        content: [],
        vectorstore_price: '',
        vectorstore_owner_ln_address: '',
        relays: [],
    });

    // Vectorstore Details component states
    const [vectorstore_ID, setVectorstore_ID] = useState(vectorstore.vectorstore_ID);
    const [vectorstore_name, setVectorstore_name] = useState(vectorstore.vectorstore_name);
    const [vectorstore_description, setVectorstore_description] = useState(vectorstore.vectorstore_description);
    const [last_modified_date, setLast_modified_date] = useState(vectorstore.last_modified_date);
    const [created_by, setCreated_by] = useState(vectorstore.created_by);
    // RelayComponent states
    const [relays, setRelays] = useState([]);
    // Privacy Status component states
    const [privacy_status, setPrivacy_status] = useState(vectorstore.privacy_status);
    // Paid vectorstore component state
    const [is_paid_vectorstore, setIs_paid_vectorstore] = useState(false)
    const [vectorstore_price, setVectorstore_price] = useState(vectorstore.vectorstore_price)
    const [vectorstore_owner_ln_address, setVectorstore_owner_ln_address] = useState(vectorstore.vectorstore_owner_ln_address)
    // Vectorstore Data component states
    const [content, setContent] = useState(vectorstore.content);
    // Other component states
    const [isLoading, setIsLoading] = useState(true);
    const [is_owner, setIs_owner] = useState(false);
    const [showAlertVectorstoreUpdated, setShowAlertVectorstoreUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeletingModal, setShowDeletingModal] = useState(false);






    
    // Set data change handlers
    // Vectorstore Details
    const handle_vectorstore_name_change = (newStatus) => {
        setVectorstore_name(newStatus);
    };
    const handle_vectorstore_description_change = (newStatus) => {
        setVectorstore_description(newStatus);
    };
    // Privacy Status
    const handle_privacy_change = (newStatus) => {
        setPrivacy_status(newStatus);
    };
    // Paid component
    const handle_is_paid_change = (newStatus) => {
        setIs_paid_vectorstore(newStatus);
    };
    const handle_vectorstore_price_change = (newStatus) => {
        setVectorstore_price(newStatus);
    };
    const handle_vectorstore_owner_ln_address_change = (newStatus) => {
        setVectorstore_owner_ln_address(newStatus);
    };
    // Other
    const closeAlertVectorstoreUpdated = () => {
        setShowAlertVectorstoreUpdated(false);
    };
    const handleBackButton = () => {
        if (origin == "user") {
            navigate(`/myvectornests`);
        } else {
            navigate(`/vectornests`);
        }
    };
    const openUploadModal = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleModalSave = () => {
        setShowModal(false);
        setShowAlertVectorstoreUpdated(true);
    }

    



    // useEffects to initialize values
    // Fetch the vectorstore details using the vectorstoreID. This is just a placeholder. Update accordingly.
    useEffect(() => {
        fetch(`https://api.paywithflash.com/api/get_vectorstore_data?vectorstore_ID=${vectorstoreID}`)
            .then(response => response.json())
            .then(data => {
                setVectorstore(data);
                setIsLoading(false);
            });
    }, [vectorstoreID]);

    // Identify whether current user is creator of the vectorstore
    useEffect(() => {
        if (vectorstore.created_by === userNpub) {
            setIs_owner(true);
        }
    }, [vectorstore, userNpub]);

    // Set initial value for states when vectorstore value gets set
    useEffect(() => {
        // Vectorstore Details component
        setVectorstore_ID(vectorstore.vectorstore_ID);
        setVectorstore_name(vectorstore.vectorstore_name);
        setVectorstore_description(vectorstore.vectorstore_description);
        setLast_modified_date(vectorstore.last_modified_date);
        setCreated_by(vectorstore.created_by);
        // Relays Component
        setRelays(vectorstore.relays)
        // Privacy Status component
        setPrivacy_status(vectorstore.privacy_status);
        // Paid vectorstore component
        if (vectorstore.vectorstore_price > 0) {
            setIs_paid_vectorstore(true);
        }
        setVectorstore_price(vectorstore.vectorstore_price);
        setVectorstore_owner_ln_address(vectorstore.vectorstore_owner_ln_address);
        // Vectorstore data component
        setContent(vectorstore.content);
    }, [vectorstore]);


    // Save changes to db
    const on_save = () => {
    
        if (is_paid_vectorstore) {
            if (vectorstore_price <= 0 || !vectorstore_owner_ln_address) {
                alert("Please enter a valid price and lightning address to save.");
                return; // Exit the function without saving
            }
        }
    
        let updated_price = vectorstore_price;
        let updated_ln_address = vectorstore_owner_ln_address;
    
        if (!is_paid_vectorstore) {
            updated_price = 0;
            updated_ln_address = "";
        }
    
        const dataToUpdate = {
            vectorstore_ID: vectorstore_ID,
            vectorstore_name: vectorstore_name,
            vectorstore_description: vectorstore_description,
            created_at: last_modified_date,
            relays: relays,
            privacy_status: privacy_status,
            vectorstore_price: updated_price,
            vectorstore_owner_ln_address: updated_ln_address,
        };
    
        fetch('https://api.paywithflash.com/api/update_vectorstore_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToUpdate)
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setShowAlertVectorstoreUpdated(true);
            } else if (data.error) {
                console.error(data.error);
            }
        })
        .catch(error => {
            console.error('There was an error updating the data:', error);
        });
    }


    // Delete from db
    const on_delete_vectorstore = () => {
        // Set the component to the deleting state
        setIsDeleting(true);
        setShowDeletingModal(true);

        // Ensure you have the vectorstore_ID to proceed
        if (!vectorstore_ID) {
            console.error("No vectorstore_ID provided");
            return;
        }
        
        // Create the data object with just the ID for deletion
        const dataToDelete = {
            vectorstore_ID: vectorstore_ID
        };
        
        // Make the API call to delete the vectorstore
        fetch('https://api.paywithflash.com/api/delete_vectorstore_from_db', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToDelete)
        })
        .then(response => response.json())
        // When deletion is successful, display the alert and navigate after 2 seconds
        .then(data => {
            if (data.message) {
                
                setTimeout(() => {
                    setShowDeletingModal(false);  // Close the modal after 2 seconds
                    navigate('/vectoreggs/');
                }, 2000);
            } else if (data.error) {
                console.error(data.error);
                setIsDeleting(false);  // Reset deleting state in case of an error
                setShowDeletingModal(false); // Close the modal
            }
        })
    }


    return (
        <>
            <SidebarLayout title="Nest Details" subtitle="Check out what's inside this nest">
                {/* {showAlertVectorstoreUpdated && (
                    <div className="fixed top-2.5 right-2.5 z-50">
                        <AlertVectorstoreUpdated onClose={() => setShowAlertVectorstoreUpdated(false)} vectorstoreID={vectorstoreID} />
                    </div>
                )} */}
    
                <div className="flex justify-between mx-auto w-full overflow-hidden"> {/* Flex container */}
                    
                    {/* Left Column Wrapper (includes buttons and content) */}
                    <div className="w-2/3 mr-4">
                        <div className="flex justify-between items-center mb-2 w-full"> {/* New flex container for buttons */}
                            {/* Back Button */}
                            <button
                                type="button"
                                onClick={() => handleBackButton(vectorstore_ID)}
                                className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Back
                            </button>
    
                            {/* Upload More Button */}
                            {is_owner && (
                                <button
                                    type="button"
                                    onClick={openUploadModal}
                                    className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    <DocumentArrowUpIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Upload more
                                </button>
                            )}
                        </div>
    
                        {/* Left Column Content */}
                        <div className="bg-white shadow sm:rounded-lg"> 
                            <div className="relative">
                                {showAlertVectorstoreUpdated && (
                                    <div className="absolute mt-4 top-0 right-0 z-50">
                                        <AlertVectorstoreUpdated onClose={() => setShowAlertVectorstoreUpdated(false)} vectorstoreID={vectorstoreID} />
                                    </div>
                                )}
                                
                                <DisplayVectorstoreDetail 
                                    vectorstore_ID={vectorstore_ID}
                                    vectorstore_name={vectorstore_name} 
                                    vectorstore_description={vectorstore_description} 
                                    last_modified_date={last_modified_date} 
                                    created_by={created_by} 
                                    is_owner={is_owner}
                                    on_vectorstore_name_change={handle_vectorstore_name_change}
                                    on_vectorstore_description_change={handle_vectorstore_description_change}
                                    relays={relays} // To pass on to Relays child component
                                    setRelays={setRelays} // To pass on to Relays child component
                                />
                            </div>
                            <PrivacyStatusComponent 
                                privacy_status={privacy_status} 
                                is_owner={is_owner}
                                on_privacy_change={handle_privacy_change}
                            />
                            <PaidVectorstoreComponent 
                                vectorstore_price={vectorstore_price}
                                vectorstore_owner_ln_address={vectorstore_owner_ln_address}
                                is_paid_vectorstore={is_paid_vectorstore}
                                is_owner={is_owner}
                                on_is_paid_change={handle_is_paid_change}
                                on_vectorstore_price_change={handle_vectorstore_price_change}
                                on_vectorstore_owner_ln_address_change={handle_vectorstore_owner_ln_address_change}
                            />
                            {is_owner && (
                                <div className="flex flex-grow justify-center items-center">
                                    <SaveChangesComponent 
                                        is_owner={is_owner}
                                        on_save={on_save}
                                        handleBackButton={handleBackButton}
                                        on_delete_vectorstore={on_delete_vectorstore}
                                        vectorstore_ID={vectorstore_ID}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    
                    {/* Right Column */}
                    <div className="w-1/3 rounded-md">
                        <DisplayVectorstoreData 
                            content={content}
                        />
                    </div>
                </div>
            </SidebarLayout>
            
            {/* Upload Modal */}
            <Modal isOpen={showModal} onClose={handleCloseModal} className="w-1/2 mx-auto">
                {showModal && (
                    <ModalUploadMore
                        onClose={handleCloseModal}
                        onSave={handleModalSave}
                        vectorstore_ID={vectorstore_ID}
                        vectorstore_name={vectorstore_name}
                        vectorstore_description={vectorstore_description}
                        created_by={created_by}
                        privacy_status={privacy_status}
                        vectorstore_owner_ln_address={vectorstore_owner_ln_address}
                        vectorstore_price={vectorstore_price}
                    />
                )}
            </Modal>
            {/* Delete Modal */}
            <Modal isOpen={showDeletingModal} onClose={() => {}} className="w-1/2 mx-auto">
                <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    <p className="text-white">Deleting...</p>
                </div>

            </Modal>
        </>
    );
    
    
}

export default VectorstoreDetailView;
