import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLongLeftIcon, DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';

import SidebarLayout from '../components/SidebarLayout';
import DisplayEggDetail from '../components/EggDetailPageComponents/DisplayEggDetail'
import PrivacyStatusComponent from '../components/EggDetailPageComponents/PrivacyStatusComponent'
import PaidEggComponent from '../components/EggDetailPageComponents/PaidEggComponent'
import SaveChangesComponent from '../components/EggDetailPageComponents/SaveChangesComponent';
import AlertEggUpdated from '../components/EggDetailPageComponents/AlertEggUpdated';
import DisplayEggUsage from '../components/EggDetailPageComponents/DisplayEggUsage';
import Modal from '../components/Modal';



function EggDetailView(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [egg_name, setEgg_name] = useState(queryParams.get('egg_name'));
    const origin = queryParams.get('origin'); // We use the origin to know where to redirect to

    let userNpub = localStorage.getItem('userNpub') || "";

    let navigate = useNavigate(); // Declare the navigate function
  
    const [egg, setEgg] = useState({
        egg_name: '',
        egg_description: '',
        egg_headers: '',
        egg_url: '',
        // last_modified_date: null,
        privacy_status: '',
        created_by: '',
        content: [],
        egg_price: '',
        egg_owner_ln_address: '',
        relays: [],
    });

    // Egg Details component states
    const [egg_description, setEgg_description] = useState(egg.egg_description);
    const [egg_headers, setEgg_headers] = useState(egg.egg_headers);
    const [egg_url, setEgg_url] = useState(egg.url);
    const [egg_payload, setEgg_payload] = useState(egg.egg_payload)
    const [egg_example_usage, setEgg_example_usage] = useState(egg.egg_example_usage)
    const [last_modified_date, setLast_modified_date] = useState(egg.last_modified_date);
    const [created_by, setCreated_by] = useState(egg.created_by);
    // Privacy Status component states
    const [privacy_status, setPrivacy_status] = useState(egg.privacy_status);
    // Paid egg component state
    const [is_paid_egg, setIs_paid_egg] = useState(false)
    const [egg_price, setEgg_price] = useState(egg.egg_price)
    const [egg_owner_ln_address, setEgg_owner_ln_address] = useState(egg.egg_owner_ln_address)
    // Egg Data component states
    const [content, setContent] = useState(egg.content);
    // Other component states
    const [isLoading, setIsLoading] = useState(true);
    const [is_owner, setIs_owner] = useState(false);
    const [showAlertEggUpdated, setShowAlertEggUpdated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeletingModal, setShowDeletingModal] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [egg_name_error, setEgg_name_error] = useState(null);







    
    // Set data change handlers
    // Egg Details
    const handle_egg_name_change = (newStatus) => {
        const newValue = newStatus;
        setEgg_name(newValue);

        // Check for spaces
        if (newValue.includes(' ')) {
            setEgg_name_error('Name should not contain spaces.');
            return;  // Exit early
        }

    // Debounced check in the DB
    checkNameInDB(newValue);
    };
    const handle_egg_description_change = (newStatus) => {
        setEgg_description(newStatus);
    };
    const handle_egg_headers_change = (newStatus) => {
        setEgg_headers(newStatus);
    };
    const handle_egg_url_change = (newStatus) => {
        setEgg_url(newStatus);
    };
    const handle_egg_payload_change = (newStatus) => {
        setEgg_payload(newStatus);
    };
    const handle_egg_example_usage_change = (newStatus) => {
        setEgg_example_usage(newStatus);
    };
    // Privacy Status
    const handle_privacy_change = (newStatus) => {
        setPrivacy_status(newStatus);
    };
    // Paid component
    const handle_is_paid_change = (newStatus) => {
        setIs_paid_egg(newStatus);
    };
    const handle_egg_price_change = (newStatus) => {
        setEgg_price(newStatus);
    };
    const handle_egg_owner_ln_address_change = (newStatus) => {
        setEgg_owner_ln_address(newStatus);
    };
    // Other
    const closeAlertEggUpdated = () => {
        setShowAlertEggUpdated(false);
    };
    const handleBackButton = () => {
        if (origin == "user") {
            navigate(`/mynostreggs`);
        } else {
            navigate(`/nostreggs`);
        }
    };
    const openUploadModal = () => {
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleModalSave = () => {
        setShowModal(false);
        setShowAlertEggUpdated(true);
    }

    



    // useEffects to initialize values
    // Fetch the egg details using the eggID. This is just a placeholder. Update accordingly.
    useEffect(() => {
        fetch(`https://api.paywithflash.com/api/get_egg?egg_name=${egg_name}`)
            .then(response => response.json())
            .then(data => {
                setEgg(data);
                setIsLoading(false);
            });
    }, []);

    // Identify whether current user is creator of the egg
    useEffect(() => {
        if (egg.created_by === userNpub) {
            setIs_owner(true);
        }
    }, [egg, userNpub]);

    // Set initial value for states when egg value gets set
    useEffect(() => {
        // Egg Details component
        setEgg_description(egg.description);
        setEgg_headers(egg.headers);
        setEgg_url(egg.url);
        setEgg_payload(egg.payload);
        setEgg_example_usage(egg.example_usage);
        // setLast_modified_date(egg.last_modified_date);
        setCreated_by(egg.created_by);
        // Privacy Status component
        setPrivacy_status(egg.privacy_status);
        // Paid egg component
        if (egg.price > 0) {
            setIs_paid_egg(true);
        }
        setEgg_price(egg.price);
        setEgg_owner_ln_address(egg.owner_ln_address);
    }, [egg]);


    // This function validates the form is valid before saving changes
    const isFormValid = () => {
        // Check for name and name errors
        if (!egg_name || egg_name_error) {
          return false;
        }
      
        // Check other mandatory fields
        if (!egg_url || !egg_description || !egg_payload || !egg_example_usage) {
          return false;
        }
      
        // If paid toggle is ON, then lightning address and price should not be empty
        if (is_paid_egg && (egg_price <= 0 || !egg_owner_ln_address)) {
          return false;
        }
      
        return true;
    };


    // This function is to delay calling the API in checkNameinDb
    function debounce(func, delay) {
        let timer;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
      }
    
      // This function checks to see an egg with the same name doesn't exist already in the db
      const checkNameInDB = debounce(async (name) => {
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_egg?egg_name=${name}`);
            const data = await response.json();
            
            if (response.status === 404) {
                setEgg_name_error(null);
            } else if (data && data.name) {
                setEgg_name_error('Name already exists in the database.');
            }
        } catch (error) {
            console.error('Error checking egg name:', error);
            setEgg_name_error('Error checking name availability.');
        }
      }, 500);  // 500ms delay

      

    // Save changes to db
    const on_save = () => {
    
        // Clear previous feedback messages
        setFeedbackMessage('');

        if (!isFormValid()) {
            setFeedbackMessage('Please check the form for errors or missing fields.');
            return;  // Exit the function early
        }
    
        let updated_price = egg_price;
        let updated_ln_address = egg_owner_ln_address;
    
        if (!is_paid_egg) {
            updated_price = 0;
            updated_ln_address = "";
        }
    
        const dataToUpdate = {
            name: egg_name,
            description: egg_description,
            headers: egg_headers,
            url: egg_url,
            payload: egg_payload,
            example_usage: egg_example_usage,
            // created_at: last_modified_date,
            privacy_status: privacy_status,
            price: updated_price,
            owner_ln_address: updated_ln_address,
        };
    
        fetch('https://api.paywithflash.com/api/update_egg_data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToUpdate)
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                setShowAlertEggUpdated(true);
            } else if (data.error) {
                console.error(data.error);
            }
        })
        .catch(error => {
            console.error('There was an error updating the data:', error);
        });
    }


    // Delete from db
    const on_delete_egg = () => {
        // Set the component to the deleting state
        setIsDeleting(true);
        setShowDeletingModal(true);

        // Make the API call to delete the egg
        fetch('https://api.paywithflash.com/api/delete_egg_from_db', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToDelete)
        })
        .then(response => response.json())
        // When deletion is successful, display the alert and navigate after 2 seconds
        .then(data => {
            if (data.message) {
                
                setTimeout(() => {
                    setShowDeletingModal(false);  // Close the modal after 2 seconds
                    navigate('/vectoreggs/');
                }, 2000);
            } else if (data.error) {
                console.error(data.error);
                setIsDeleting(false);  // Reset deleting state in case of an error
                setShowDeletingModal(false); // Close the modal
            }
        })
    }


    return (
        <>
            <SidebarLayout title="Egg Details" subtitle="Check out what's inside this egg">
                {/* {showAlertEggUpdated && (
                    <div className="fixed top-2.5 right-2.5 z-50">
                        <AlertEggUpdated onClose={() => setShowAlertEggUpdated(false)} eggID={eggID} />
                    </div>
                )} */}

                                        <div className="flex justify-between items-center mb-2 w-full"> {/* New flex container for buttons */}
                            {/* Back Button */}
                            <button
                                type="button"
                                onClick={() => handleBackButton(egg_name)}
                                className="inline-flex items-center gap-x-2 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Back
                            </button>
                        </div>
    


                <div className="flex justify-between mx-auto w-full overflow-hidden"> {/* Flex container */}
                    
                    {/* Left Column Wrapper (includes buttons and content) */}
                    <div className="w-1/2 mr-4 mr-12">

                        <div className="mb-8 mt-6">
                            <h2 className="text-2xl font-semibold pb-1">{egg_name}</h2>
                            
                            <label htmlFor="email" className="block mt-6 text-sm font-medium leading-6 text-gray-900">
                            Description
                            </label>
                            <p className="text-sm text-gray-700">{egg_description}</p>

                            <label htmlFor="email" className="block mt-6 text-sm font-medium leading-6 text-gray-900">
                            URL
                            </label>
                            <p className="text-sm text-gray-700">{egg_url}</p>

                            <label htmlFor="email" className="block mt-6 text-sm font-medium leading-6 text-gray-900">
                            Price
                            </label>
                            <p className="text-sm text-gray-700">{egg_price} sats</p>

                            </div>

                        
    
                        {/* Left Column Content */}
                        <div className="bg-white shadow sm:rounded-lg"> 
                            <div className="relative">
                                {showAlertEggUpdated && (
                                    <div className="absolute mt-4 top-0 right-0 z-50">
                                        <AlertEggUpdated onClose={() => setShowAlertEggUpdated(false)} egg_name={egg_name} />
                                    </div>
                                )}
                                
                                {/* <DisplayEggDetail 
                                    egg_name={egg_name}
                                    egg_name_error={egg_name_error}
                                    egg_description={egg_description}
                                    egg_headers={egg_headers}
                                    egg_url={egg_url}
                                    last_modified_date={last_modified_date} 
                                    created_by={created_by} 
                                    is_owner={is_owner}
                                    on_egg_name_change={handle_egg_name_change}
                                    on_egg_description_change={handle_egg_description_change}
                                    on_egg_url_change={handle_egg_url_change}
                                    on_egg_headers_change={handle_egg_headers_change}
                                    on_egg_payload_change={handle_egg_payload_change}
                                    on_egg_example_usage_change={handle_egg_example_usage_change}
                                /> */}
                            </div>
                            {/* <PrivacyStatusComponent 
                                privacy_status={privacy_status} 
                                is_owner={is_owner}
                                on_privacy_change={handle_privacy_change}
                            />
                            <PaidEggComponent 
                                egg_price={egg_price}
                                egg_owner_ln_address={egg_owner_ln_address}
                                is_paid_egg={is_paid_egg}
                                is_owner={is_owner}
                                on_is_paid_change={handle_is_paid_change}
                                on_egg_price_change={handle_egg_price_change}
                                on_egg_owner_ln_address_change={handle_egg_owner_ln_address_change}
                            /> */}
                            {/* {is_owner && (
                                <div className="flex flex-grow justify-center items-center">
                                    <SaveChangesComponent 
                                        is_owner={is_owner}
                                        feedbackMessage={feedbackMessage}
                                        on_feedback_message_change={setFeedbackMessage}
                                        on_save={on_save}
                                        handleBackButton={handleBackButton}
                                        on_delete_egg={on_delete_egg}
                                    />
                                </div>
                            )} */}
                        </div>
                    </div>
                    
                    {/* Right Column */}
                    <div className="w-1/2 rounded-md mt-6">
                        <DisplayEggUsage
                            egg_payload={egg_payload}
                            egg_example_usage={egg_example_usage}
                            is_owner={is_owner}
                            on_egg_payload_change={handle_egg_payload_change}
                            on_egg_example_usage_change={handle_egg_example_usage_change}
                        />
                    </div>
                </div>
            </SidebarLayout>
            
            {/* Delete Modal */}
            <Modal isOpen={showDeletingModal} onClose={() => {}} className="w-1/2 mx-auto">
                <div className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    <p className="text-white">Deleting...</p>
                </div>

            </Modal>
        </>
    );
    
    
}

export default EggDetailView;
