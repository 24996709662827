import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

function aggregateDataByFlashType(data) {
  const aggregatedData = data.reduce((acc, curr) => {
    const { flash_type } = curr;
    if (!acc[flash_type]) {
      acc[flash_type] = 0;
    }
    acc[flash_type] += 1;
    return acc;
  }, {});

  return Object.entries(aggregatedData);
}

export default function HistoryOfTransactions({ data }) {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: 'donut',
      width: 380,
      toolbar: {
        show: true, // Explicitly show the toolbar
        tools: {
          download: true // Enable the download tool
        }
      }
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270
      }
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'gradient'
    },
    legend: {
      position: 'bottom'
    },
    title: {
      text: 'Transactions per Flash Type'
    },
    labels, // Add labels to options
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  });

  useEffect(() => {
    const aggregatedData = aggregateDataByFlashType(data);
    setSeries(aggregatedData.map(item => item[1]));
    setLabels(aggregatedData.map(item => item[0]));

    // You can also update the labels in the options directly
    setOptions(prevOptions => ({
      ...prevOptions,
      labels: aggregatedData.map(item => item[0])
    }));
  }, [data]);

  return (
    <div>
      <div id="chart" className='mt-10'>
        <ReactApexChart options={options} series={series} type="donut" height={350} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}
