import React, { useEffect, useState } from 'react';
import { nip04, getPublicKey } from 'nostr-tools';
import { webln } from "@getalby/sdk";
import { ArrowLongRightIcon, PlusIcon, UserCircleIcon, WalletIcon, PlusCircleIcon, LifebuoyIcon, BookOpenIcon  } from '@heroicons/react/24/outline'



function WalletList() {

    let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
    let userNpub = localStorage.getItem('userNpub') || "";
    let userName = localStorage.getItem('userName') || "";
    let userAbout = localStorage.getItem('userAbout') || "";
    let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

    // States for data received upon page load
    const [flashPublicKey, setFlashPublicKey] = useState(null);

    // States for user wallet
    const [wallets, setWallets] = useState([]);
    const [totalBalance, setTotalBalance] = useState(0);
    const [walletCount, setWalletCount] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                // First, fetch the public key
                const retrievedPublicKey = await getFlashPublicKey();
                if (retrievedPublicKey) {
                    setFlashPublicKey(retrievedPublicKey);
                    // Once the public key is successfully fetched and set, fetch the user wallets
                    const userWallets = await getUserWallets(userHexPrivateKey, retrievedPublicKey); // Use retrievedPublicKey directly here
                    setWallets(userWallets);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        if (userHexPrivateKey) { // Ensure you have the privateKey before attempting to fetch
            fetchData();
        }
    }, [userHexPrivateKey]); // This effect depends on userHexPrivateKey, ensure it's defined


    async function getFlashPublicKey() {
        try {
            // Make a GET request to the API endpoint
            const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/getFlashPublicKey', {
                method: 'GET',
                headers: {
                    // You can add headers if needed, such as authentication headers
                },
            });

            // Check if the response is successful (status code 200)
            if (response.ok) {
                // Parse the JSON response to get the public key
                const data = await response.json();
                return data.publicKey;
            } else {
                // Handle the error if the response status is not 200
                throw new Error(`API call failed with status: ${response.status}`);
            }
        } catch (error) {
            console.error('Error in getFlashPublicKey:', error);
            throw error;
        }
    }


    // Function that gets all user wallets
    async function getUserWallets(userPrivateKey, flashPublicKey) {
        const userPublicKey = getPublicKey(userPrivateKey);
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const walletPromises = data.map(async item => {
                let decrypted_nwc_url;
                if (item.nwc_url !== "") {
                    decrypted_nwc_url = await nip04.decrypt(userPrivateKey, flashPublicKey, item.nwc_url);
                } else {
                    decrypted_nwc_url = "";
                }

                // Fetch balance and logo URL concurrently
                const balancePromise = getWalletBalance(decrypted_nwc_url); // Abstracted balance fetching to a function
                const logoUrlPromise = getWalletLogoUrlFromNWCString(decrypted_nwc_url); // Fetch logo URL

                try {
                    const [balanceResponse, walletLogoUrl] = await Promise.all([balancePromise, logoUrlPromise]);

                    return {
                        id: item.id,
                        user_publicKey: item.user_public_key,
                        wallet_name: item.wallet_name,
                        user_wallet_name: item.user_wallet_name,
                        nwc_url: item.nwc_url,
                        balance: balanceResponse.balance, // Adding balance to the result
                        wallet_logo_url: walletLogoUrl // Adding wallet logo URL to the result
                    };
                } catch (error) {
                    console.error('Error processing wallet:', error);
                    return {
                        ...item,
                        nwc_url: item.nwc_url,
                        balance: 'Error',
                        wallet_logo_url: 'default-logo-url.jpg' // Replace with your default logo URL
                    };
                }
            });

            const processedWallets = await Promise.all(walletPromises);

            const totalInMilliSatoshis = processedWallets.reduce((acc, wallet) => acc + parseFloat(wallet.balance), 0);
            const totalInSatoshis = totalInMilliSatoshis / 1000;
            // Correctly convert satoshis to Bitcoin
            const totalInBitcoin = totalInSatoshis / 100000000;
            setTotalBalance(totalInSatoshis);


            // Update wallet count (you might want only wallets with a balance > 0, adjust accordingly)
            setWalletCount(processedWallets.length);

            return processedWallets;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    }

    // Helper function to fetch wallet balance
    async function getWalletBalance(nwcUrl) {
        const nwc = new webln.NostrWebLNProvider({
            nostrWalletConnectUrl: nwcUrl,
        });
        await nwc.enable();
        const balanceResponse = await nwc.getBalance();
        nwc.close();
        return balanceResponse;
    }


    const getWalletLogoUrlFromNWCString = async (nwcString) => {
        const walletsAvailable = [
            {
                name: "alby",
                logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/AlbyWalletLogo.png",
            },
            {
                name: "mutiny",
                logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/MutinyWalletLogo.jpeg",
            },
        ];

        const lowerCaseNWCString = nwcString.toLowerCase();

        for (let i = 0; i < walletsAvailable.length; i++) {
            if (lowerCaseNWCString.includes(walletsAvailable[i].name)) {
                return walletsAvailable[i].logoURL;
            }
        }

        // Return "Custom" or a default image if no match is found
        return "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png"; // Replace with your default wallet logo URL
    };

    const mockLinks = [
//   { id: 1, icon: UserCircleIcon, label: 'Manage your account', url: '/my-account'},
  { id: 2, icon: WalletIcon, label: 'Connect a wallet', url: '/my-account?tab=Wallets'},
  { id: 3, icon: PlusCircleIcon, label: 'Create a payment link', url: '/new-flash'},
  { id: 4, icon: BookOpenIcon, label: 'Read our guides', url: 'https://scribehow.com/page/Flash_documentation__h8N1ZIHCR9-9aeAjpz1ydA'},
  { id: 4, icon: LifebuoyIcon, label: 'Request help', url: 'https://paywithflash.com/contact'},
];


return (
  <div className="md:mx-0">
    <h2 className="text-md font-semibold text-slate-900 mb-4">Quick Actions</h2>
    {mockLinks.map(link => (
      <div key={link.id} className="flex items-center mb-2 ">
        <a href={`${link.url}`} className="text-sm font-regular flex items-center mt-0.5 hover:text-indigo-700">
          <link.icon className="h-4 w-4 mr-3 text-slate-500 " />
          {link.label}
        </a>
      </div>
    ))}
  </div>
);
       

}

export default WalletList;
