import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { ShoppingCartIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const encodeCart = (cart) => {
    const jsonString = JSON.stringify(cart);
    return encodeURIComponent(btoa(unescape(encodeURIComponent(jsonString))));
};

const Cart = ({ cart, user, removeFromCart, clearCart, updateCart }) => {
    const navigate = useNavigate();
    const [isCartOpen, setIsCartOpen] = useState(false);

    const handleCheckout = () => {
        const encodedCart = encodeCart(cart);
        const userPublicKey = user.replace(/"/g, ''); // Remove any quotation marks
        navigate(`/product-gallery-checkout?user=${userPublicKey}`, { state: { cart, user } });
    };

    const toggleCart = () => {
        setIsCartOpen(!isCartOpen);
    };

    const handleQuantityChange = (index, quantity) => {
        const updatedCart = cart.map((item, i) =>
            i === index ? { ...item, quantity: Math.max(1, Number(quantity)) } : item
        );
        updateCart(updatedCart);
    };

    const calculateSubtotal = () => {
        return cart.reduce((acc, item) => {
            const priceInDollars = item.PriceInDollars || 0;
            const quantity = item.quantity || 1;
            return acc + priceInDollars * quantity;
        }, 0).toFixed(2);
    };

    return (
        <div className="flex items-center">
            {!isCartOpen && (
                <button
                    className="relative flex items-center p-2 text-gray-700 hover:text-gray-900 ml-4"
                    onClick={toggleCart}
                >
                    <ShoppingCartIcon className="h-6 w-6" />
                    {cart.length > 0 && (
                        <span className="absolute top-0 right-0 inline-flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white text-xs font-bold">
                            {cart.length}
                        </span>
                    )}
                </button>
            )}

            <Dialog open={isCartOpen} onClose={toggleCart} className="relative z-10">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium text-gray-900">Shopping cart</Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                                    onClick={toggleCart}
                                                >
                                                    <span className="absolute -inset-0.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="mt-8">
                                            <div className="flow-root">
                                                <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                    {cart.map((product, index) => (
                                                        <li key={index} className="flex py-6">
                                                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                                <img
                                                                    src={product.ImageURLs[0]}
                                                                    alt={product.Name}
                                                                    className="h-full w-full object-cover object-center"
                                                                />
                                                            </div>

                                                            <div className="ml-4 flex flex-1 flex-col">
                                                                <div>
                                                                    <div className="flex justify-between text-base font-medium text-gray-900">
                                                                        <h3>
                                                                            <a href={product.href}>{product.Name}</a>
                                                                        </h3>
                                                                        <div className="ml-4">
                                                                            <p>{product.PriceInDollars ? `$${(product.PriceInDollars * (product.quantity || 1)).toFixed(2)} USD` : 'Price not available'}</p>
                                                                            <p className="text-sm text-gray-500">{product.PriceInSatoshis ? `${(product.PriceInSatoshis * (product.quantity || 1)).toLocaleString()} sats` : 'Satoshis not available'}</p>
                                                                        </div>
                                                                    </div>
                                                                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                                                    <p className="mt-1 text-sm text-gray-500">{product.size}</p>
                                                                </div>
                                                                <div className="flex flex-1 items-end justify-between text-sm">
                                                                    <div className="flex items-center space-x-2">
                                                                        <label className="text-gray-500">Qty</label>
                                                                        <input
                                                                            type="number"
                                                                            value={product.quantity}
                                                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                                            min="1"
                                                                            className="w-16 px-2 py-1 border rounded-md text-sm"
                                                                        />
                                                                    </div>

                                                                    <div className="flex">
                                                                        <button
                                                                            type="button"
                                                                            className="font-medium text-red-600 hover:text-red-400"
                                                                            onClick={() => removeFromCart(index)}
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                            <p>Subtotal</p>
                                            <p>${calculateSubtotal()}</p>
                                        </div>
                                        <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                                        <div className="mt-6">
                                            <button
                                                type="button"
                                                className={`flex items-center justify-center rounded-md border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm ${
                                                    cart.length === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-800 hover:bg-gray-700'
                                                }`}
                                                onClick={handleCheckout}
                                                disabled={cart.length === 0}
                                            >
                                                Checkout
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Cart;
