import React, { useState, useRef } from 'react';
import { Switch } from '@headlessui/react'
import { nip04, getEventHash, getSignature, SimplePool } from 'nostr-tools';
import axios from 'axios'; // for calling API
import { v4 as uuidv4 } from 'uuid';
import { relayConnections, createZapInvoice } from './NostrEggs'
import AlertVectorstoreCreated from './AlertVectorstoreCreated';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { NostrWebLNProvider, webln } from "@getalby/sdk";
import { LightningAddress } from "@getalby/lightning-tools";



function CreateEgg({ on_upload_cancel }) {
  const [files, setFiles] = useState([]);
  const [vectorstoreName, setVectorstoreName] = useState('');
  const [vectorstoreDescription, setVectorstoreDescription] = useState('');
  let [vectorstoreID, setVectorstoreID] = useState('');
  const [fileMetadata, setFileMetadata] = useState('');
  const [privacyStatus, setPrivacyStatus] = useState('Public'); // Default to 'Public'
  const [paidVectorstore, setPaidVectorstore] = useState(false)
  const [vectorstoreOwnerLnAddress, setVectorstoreOwnerLnAddress] = useState('')
  const [vectorstorePrice, setVectorstorePrice] = useState(0)

  // States for the relay section
  const [relays, setRelays] = useState(["wss://relay.paywithflash.com"]);
  const [relayText, setRelayText] = useState('');
  const [relayError, setRelayError] = useState(null);
  const relayInputRef = useRef(null);
  const [relayTooltipVisible, setRelayTooltipVisible] = useState(false);

  const [tooltipVisible1, setTooltipVisible1] = useState(false);
  const [tooltipVisible2, setTooltipVisible2] = useState(false);
  const [tooltipVisible3, setTooltipVisible3] = useState(false);
  const [lnAddressError, setLnAddressError] = useState('');
  const [PriceError, setPriceError] = useState("Price can't be zero or empty");

  const nostrWalletConnectUrl = process.env.REACT_APP_NWC_URL

  const [mode, setMode] = useState('Create'); // 'Create' or 'Update'
  
  const [showAlertVectorstoreCreated, setShowAlertVectorstoreCreated] = useState(false);

  // Get the values from session storage
  let userNpub = localStorage.getItem('userNpub') || "";

  const closeAlertVectorstoreCreated = () => {
    setShowAlertVectorstoreCreated(false);
  };

  const onFilesChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const onVectorstoreNameChange = (e) => {
    setVectorstoreName(e.target.value);
  };

  const onFileMetadataChange = (e) => {
    setFileMetadata(e.target.value);
  };

  const handlePrivacyToggle = (status) => {
    setPrivacyStatus(status);
  };

  const onVectorstoreOwnerLnAddressChange = async (e) => {
    const lnAddress = e.target.value;
    setVectorstoreOwnerLnAddress(lnAddress);

    try {
        await checkVectorstoreOwnerLnAddress(lnAddress);
        setLnAddressError(''); // clear any existing error message
    } catch (err) {
        setLnAddressError('Not a proper nip-05 lightning address. Check the hint for more info.');
    }
  };

  const onVectorstorePriceChange = (e) => {
    const inputValue = e.target.value;
    setVectorstorePrice(inputValue);

    if (inputValue > 0) {
      setPriceError(''); // clear any existing error message
    } else {
      setPriceError("Price can't be zero or empty");
    }
};


  const payInvoice = async (invoice) => {
    if (typeof window.webln === "undefined") {
      return alert("No WebLN available. Get a bitcoin lightning wallet. We recommed www.getalby.com");
    }
  
    try {
      await window.webln.enable();
      const result = await window.webln.sendPayment(invoice);
      // const { preimage } = result; // Extract the 'preimage' property from 'result'
  
      return result;
    } catch (error) {
      alert("An error occurred during the payment.");
    }
  };



  // Relay section functions
  const handleRelayInputChange = (e) => {
    const value = e.target.value;
    if (value.endsWith(' ')) {
        const regex = /^wss:\/\/([\w-]+\.)?[\w-]+\.\w+$/;
        if (regex.test(value.trim())) {
            setRelays(prev => [...prev, value.trim()]);
            setRelayText('');
            setRelayError(null); // Clear the error message if input is correct
        } else {
            setRelayError('Please enter a valid relay starting with "wss://" and ending with a domain.');
            setRelayText(value.trim()); // Retain the value for the user to correct it
        }
    } else {
        setRelayText(value);
    }
  };


  const removeRelay = (index) => {
    setRelays((prev) => {
      const newRelays = [...prev];
      newRelays.splice(index, 1);
      if (newRelays.length === 0) {
        setRelayError('If left empty, the NostrEggs relay will be used by default');
      }
      return newRelays;
    });
  };

  const focusRelayInput = () => {
    relayInputRef.current.focus();
  };



  

  

  const onFilesUpload = async () => {
    
    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    const nostrWalletConnectUrl = process.env.REACT_APP_NWC_URL

    if (mode === 'Create' && (!files || !vectorstoreName || !fileMetadata)) {
      alert('For creation, File, Vectorstore Name and File Metadata are mandatory!');
      return;
    }
  
    if (mode === 'Update' && (!files || !vectorstoreID || !fileMetadata)) {
      alert('For update, File, Vectorstore ID, and File Metadata are mandatory!');
      return;
    }

    if (paidVectorstore && vectorstorePrice === 0 && !vectorstoreOwnerLnAddress) {
      alert('If you are creating a paid Vectorstore, the Price and Lightning Address have to be input');
      return;
    }
    
    if (!vectorstoreID) {
      vectorstoreID = uuidv4();
    }
    setVectorstoreID(vectorstoreID);
    
    
    
    
    const requestCost = files.length * 2000000; // 2k sats per file
    const normalizedRelays = ['wss://relay.paywithflash.com']
    // Create the invoice
    const zapInvoice = await createZapInvoice(publicKey, requestCost, "NostrEgg invoice. VectorstoreID: " + vectorstoreID, normalizedRelays);
    // Make the user pay for his request
    const paid = await payInvoice(zapInvoice)
    if (paid) {
      setShowAlertVectorstoreCreated(true);
    // If the user paid, then run the following code
      for (const file of files) {
        try {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('vectorstore_name', vectorstoreName);
          formData.append('vectorstore_description', vectorstoreDescription);
          formData.append('vectorstore_ID', vectorstoreID);
          formData.append('file_metadata', fileMetadata);
          formData.append('relays', relays);
          formData.append('created_by', userNpub);
          formData.append('privacy_status', privacyStatus);
          formData.append('vectorstore_owner_ln_address', vectorstoreOwnerLnAddress);
          formData.append('vectorstore_price', vectorstorePrice);

          const response = await axios.post('https://api.paywithflash.com/api/vectorizeFile', formData);

        } catch (error) {
            console.error('API call failed:', error);
        }
      }
      // Somehow return the vectorstore ID to the user
    }
  };


  async function checkVectorstoreOwnerLnAddress(vectorstore_owner_ln_address) {
    try {
    const NWC = new webln.NostrWebLNProvider({
      nostrWalletConnectUrl: nostrWalletConnectUrl,
    });
  
    const ln = new LightningAddress(vectorstore_owner_ln_address, {
      webln: NWC,
    });
  
    await ln.fetch();
  
    if (!ln.nostrPubkey || ln.nostrPubkey === "") { // check for both undefined and empty string
      throw new Error('Invalid LN Address');
    }
  } catch (error) {
    throw new Error('Invalid LN Address');
  }
}



  return (
    <div className="relative">
      {showAlertVectorstoreCreated && (
        <div className="fixed top-2.5 right-2.5 z-50">
          <AlertVectorstoreCreated onClose={closeAlertVectorstoreCreated} vectorstoreID={vectorstoreID} />
        </div>
      )}
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <form>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {mode === 'Create' && (
                    <div className="sm:col-span-4">
                      <label htmlFor="vectorstoreName" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 sm:max-w-md">
                          <input
                            type="text"
                            name="vectorstoreName"
                            id="vectorstoreName"
                            autoComplete="vectorstoreName"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="Name"
                            value={vectorstoreName}
                            onChange={onVectorstoreNameChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {mode === 'Create' && (
                    <div className="col-span-full">
                      <label htmlFor="vectorstoreDescription" className="block text-sm font-medium leading-6 text-gray-900">
                        Description
                      </label>
                      <div className="mt-2">
                        <textarea
                          type="text"
                          name="vectorstoreDescription"
                          id="vectorstoreDescription"
                          autoComplete="vectorstoreDescription"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
                          placeholder="Describe your vector database..."
                          value={vectorstoreDescription}
                          onChange={e => setVectorstoreDescription(e.target.value)}
                          rows={3}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-span-full">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                      File
                    </label>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        {/* <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" /> */}
                        <div className="mt-4 flex text-sm leading-6 text-purple-950">
                          <label
                            htmlFor="files"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-sky-950 focus-within:outline-none focus-within:ring-2 focus-within:ring-purple-600 focus-within:ring-offset-2 hover:text-purple-500"
                          >
                            <span>Upload a file</span>
                            <input 
                              type="file"
                              multiple
                              name="files"
                              id="files"
                              // className="sr-only"
                              onChange={onFilesChange}
                          />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PDF & JSON only</p>
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="fileMetadata" className="block text-sm font-medium leading-6 text-gray-900">
                      File Metadata
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 sm:max-w-md">
                        <input
                          type="text"
                          name="fileMetadata"
                          id="fileMetadata"
                          autoComplete="fileMetadata"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Metadata"
                          value={fileMetadata} 
                          onChange={onFileMetadataChange}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="col-span-full">
                    <div className="flex items-center"> {/* <-- Use flex here */}
                        <label htmlFor="fileMetadata" className="block text-sm font-medium leading-6 text-gray-900">
                            Relays
                        </label>
                        <span className="ml-2 cursor-pointer relative z-50" onMouseEnter={() => setRelayTooltipVisible(true)} onMouseLeave={() => setRelayTooltipVisible(false)}>
                            <InformationCircleIcon className="h-6 w-6" /> {/* <-- Adjusted margin-top */}
                            {relayTooltipVisible && (
                                <div className="absolute -top-2 left-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-64">
                                IMPORTANT!
                                <br />
                                <br />
                                Relays are where your data will be stored for you to retrieve at any time (other than with NostrEggs integrations)
                                <br />
                                <br />
                                Make sure the relays you input can:
                                <br />
                                <span className="ml-4">1. Accept multiple events per second</span>
                                <br />
                                <span className="ml-4">2. Don't have event size restrictions</span>
                                <br />
                                <br />
                                If you're not sure, just leave the default NostrEggs relay!
                            </div>
                            
                            )}
                        </span>
                    </div>
                    <div 
                        onClick={focusRelayInput} 
                        className="mt-2 border rounded flex items-center flex-wrap focus-within:outline-none focus-within:ring-transparentfocus-within:ring-4 focus-within:border-purple-800"
                    >
                        {relays.map((relay, index) => (
                            <div key={index} className="m-1 flex items-center bg-purple-800 hover:bg-purple-900 text-white text-sm rounded px-2 py-1">
                                <span className="mr-2">{relay}</span>
                                <button className="text-red-500" onClick={() => removeRelay(index)}>x</button>
                            </div>
                        ))}
                        <input
                            ref={relayInputRef}
                            value={relayText}
                            onChange={handleRelayInputChange}
                            placeholder={relays.length === 0 ? 'Type and press space...' : ''}
                            className="ring-0 border-transparent focus:ring-0 focus:border-transparent flex-1 bg-transparent text-sm"
                        />
                    </div>
                    {relayError && <p className="text-red-500 text-sm mt-1">{relayError}</p>}
                </div>


                  {mode === 'Create' && (
                    <div className="col-span-full">
                      {/* Private Button  */}
                      <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Private' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Private' ? 'text-white' : 'text-gray-600'} shadow-sm hover:purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 w-1/2`}
                        onClick={() => handlePrivacyToggle('Private')}
                      >
                        &#128274;
                        Private
                      </button>

                      {/* Public Button */}
                      <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Public' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Public' ? 'text-white' : 'text-gray-600'} shadow-sm hover:purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 w-1/2`}
                        onClick={() => handlePrivacyToggle('Public')}
                      >
                        &#127758;
                        Public
                      </button>
                    </div>
                  )}

                  <div className="sm:col-span-4 flex items-center">
                    <label htmlFor="paidStatus" className="block text-sm font-medium leading-6 text-gray-900 mr-4 inline-flex items-center">
                        Paid
                    </label>
                      <Switch
                          checked={paidVectorstore}
                          onChange={setPaidVectorstore}
                          className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${paidVectorstore ? 'bg-purple-800 focus:ring-purple-800' : 'bg-gray-200'}`}
                      >
                          {/* <span className="sr-only">Use setting</span> */}
                          <span
                              aria-hidden="true"
                              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${paidVectorstore ? 'translate-x-5' : 'translate-x-0'}`}
                          />
                      </Switch>
                      <span className="ml-2 cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible1(true)} onMouseLeave={() => setTooltipVisible1(false)}>
                          <InformationCircleIcon className="h-6 w-6" /> {/* Here's the icon */}
                          {tooltipVisible1 && (
                              <div className="absolute -top-2 left-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56"> {/* Adjusted width here */}
                                  Earn money for your data! Earn sats each time your vectorstore is used.
                              </div>
                          )}
                      </span>
                  </div>

                  {paidVectorstore && (
                    <div className="ml-4 col-span-full">
                      <div className="flex items-center">
                        <label htmlFor="vectorstorePrice" className="text-sm font-medium leading-6 text-gray-900 mr-4">
                        Vectorstore Price (in sats)
                        </label>

                        <div className="flex-grow flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 mr-4">
                          <input
                          type="number"
                          name="vectorstorePrice"
                          id="vectorstorePrice"
                          autoComplete="vectorstorePrice"
                          className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0"
                          placeholder="Vectorstore Price"
                          value={vectorstorePrice}
                          onChange={onVectorstorePriceChange}
                          />
                        </div>

                        <span className="cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible2(true)} onMouseLeave={() => setTooltipVisible2(false)}>
                          <InformationCircleIcon className="h-6 w-6" />
                          {tooltipVisible2 && (
                            <div className="absolute -top-2 left-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56">
                              This is what you will earn each time your vectorstore is used.
                            </div>
                          )}
                        </span>
                      </div>

                      {PriceError && (
                        <div className="text-red-500 text-sm">
                          {PriceError}
                        </div>
                      )}

                    </div>
                  )}

                  {paidVectorstore && (
                    <div className="ml-4 col-span-full">
                      <div className="flex items-center">
                        <label htmlFor="vectorstoreOwnerLnAddress" className="text-sm font-medium leading-6 text-gray-900 mr-4">
                        Lightning Address
                        </label>

                        <div className="flex items-center flex-grow rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 mr-4">
                          <input
                          type="text"
                          name="vectorstoreOwnerLnAddress"
                          id="vectorstoreOwnerLnAddress"
                          autoComplete="vectorstoreOwnerLnAddress"
                          className="w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0"
                          placeholder="example@getalby.com"
                          value={vectorstoreOwnerLnAddress}
                          onChange={onVectorstoreOwnerLnAddressChange}
                          />
                        </div>

                        <span className="cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible3(true)} onMouseLeave={() => setTooltipVisible3(false)}>
                          <InformationCircleIcon className="h-6 w-6" />
                          {tooltipVisible3 && (
                          <div className="absolute -top-2 right-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56">
                          Don't have an address? Not a problem, get an Alby wallet and get an address! 
                          <br />
                          <br />
                          It needs to be NIP-05 verified. Just follow <a href="https://guides.getalby.com/alby-guides/alby-lightning-account/features/use-your-lightning-address-as-identifier-on-nostr"><strong>this Alby guide</strong></a>
                          </div>
                          )}
                        </span>
                      </div>

                      {lnAddressError && (
                        <div className="text-red-500 text-sm">
                          {lnAddressError}
                        </div>
                      )}

                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={() => on_upload_cancel('view')}
              >
                Cancel
              </button>
              <button
                type="button"
                className="rounded-md bg-purple-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onFilesUpload}
              >
                Save
              </button>
            </div>
          </form>
      </div>
    </div>
  )
}

export default CreateEgg;
