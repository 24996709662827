import React, { useState, useEffect } from 'react';
import EmailCodeVerificationGeyser from './EmailCodeVerificationGeyser';
import NpubCodeVerificationGeyser from './NpubCodeVerificationGeyser';
import WalletConnectButton from './WalletConnectButton';
import WalletConnectButtonSuccess from './WalletConnectButtonSuccess';
import Memberships from '../SubscriptionPage/Memberships';
import { nip19 } from 'nostr-tools';

const { decode } = nip19;

async function getWalletNameFromNWCString(nwcString) {
  const walletsAvailable = ["Alby", "Mutiny"];
  const lowerCaseNWCString = nwcString.toLowerCase();

  for (const walletName of walletsAvailable) {
    if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
      return walletName;
    }
  }

  return "Custom";
};

const ProductOrderFormGeyser = ({
  onOrderSubmitted,
  walletNwcUrl,
  setWalletNwcUrl,
  memberships,
  onSelectedMembershipChange,
  eMail,
  nPub,
  loginMethod,
  appName,
  isVerified,
  handleCodeConfirmedWrapper,
  errorMessage,
  setErrorMessage
}) => {
  const [customerName, setCustomerName] = useState('');
  const [customerSurname, setCustomerSurname] = useState('');
  const [contactMethod, setContactMethod] = useState(nPub ? 'npub' : (eMail ? 'email' : ''));
  const [email, setEmail] = useState(eMail || '');
  const [npub, setNpub] = useState(nPub || '');
  const [errorMessageNostrDM, setErrorMessageNostrDM] = useState('');
  const [errorMessageEmail, setErrorMessageEmail] = useState('');

  const [nostrVerificationCodeSent, setNostrVerificationCodeSent] = useState(false);
  const [emailVerificationCodeSent, setEmailVerificationCodeSent] = useState(false);
  const [npubVerified, setNpubVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    if (isVerified === true) {
      setNpubVerified(true);
      setEmailVerified(true);
    }
  }, [isVerified]);

  const handleSelectEmail = () => {
    setContactMethod('email');
    setNostrVerificationCodeSent(false);
  };

  const handleNpubSubmit = () => {
    setNostrVerificationCodeSent(true);
    setErrorMessageNostrDM(null);
  };

  const handleEmailSubmit = () => {
    setEmailVerificationCodeSent(true);
    setErrorMessageEmail(null);
  };

  const handleWalletConnected = async (appName, nwcString) => {
    try {
      const providerName = await getWalletNameFromNWCString(nwcString);
      setWalletNwcUrl(nwcString);
    } catch (error) {
      console.error('Error saving new wallet:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let formValues = {};
    if (contactMethod === 'email' && email) {
      formValues.email = email;
    } else if (contactMethod === 'npub' && npub && npubVerified) {
      formValues.npub = npub;
    }

    formValues.walletNwcUrl = walletNwcUrl;

    if (!formValues.email && !formValues.npub) {
      setErrorMessage("Please provide either an email address or a Nostr public key (npub).");
      return;
    }

    onOrderSubmitted(formValues);
  };

  return (
    <form className="w-full max-w-md mx-auto py-8 px-4 grid grid-cols-2 gap-4" onSubmit={handleSubmit}>
      <div className="col-span-2">
        <h2 className="text-lg font-semibold text-gray-900">Select Your Plan</h2>
      </div>
      <div className="col-span-2">
        {Array.isArray(memberships) && (
          <Memberships memberships={memberships} onSelectedMembershipChange={onSelectedMembershipChange} />
        )}
      </div>
      {!isVerified && (
        <div className="col-span-2">
          <h2 className="text-lg font-semibold text-gray-900">Contact Information</h2>
        </div>
      )}
      <div className="col-span-2 flex items-center">
        {!isVerified && loginMethod === 'email & nostr' && (
          <>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text mr-2">Email</span>
                <input
                  type="radio"
                  name="contactMethod"
                  value="email"
                  checked={contactMethod === 'email'}
                  onChange={() => setContactMethod('email')}
                  className="mr-2"
                />
              </label>
            </div>
            <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text mr-2">Nostr npub</span>
                <input
                  type="radio"
                  name="contactMethod"
                  value="npub"
                  checked={contactMethod === 'npub'}
                  onChange={() => setContactMethod('npub')}
                  className="mr-2"
                />
              </label>
            </div>
          </>
        )}
      </div>
      {!isVerified && contactMethod === 'email' && (
        <div className="col-span-2 relative">
          <input
            className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email*"
            required={contactMethod === 'email'}
          />
          {!emailVerified && (
            <button
              type="button"
              onClick={() => handleEmailSubmit(email)}
              className="absolute inset-y-0 right-0 px-4 text-white bg-gray-800 rounded-r-md hover:bg-gray-700"
            >
              Verify email
            </button>
          )}
          {emailVerified && (
            <button
              type="button"
              disabled={true}
              onClick={() => handleEmailSubmit(email)}
              className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
            >
              Verified
            </button>
          )}
        </div>
      )}
      {!isVerified && emailVerificationCodeSent && (
        <div className="col-span-2">
          <EmailCodeVerificationGeyser
            email={email}
            emailVerified={emailVerified}
            setEmailVerified={setEmailVerified}
            errorMessageEmail={errorMessageEmail}
            setErrorMessageEmail={setErrorMessageEmail}
            onCodeConfirmed={() => handleCodeConfirmedWrapper('email', email)}
          />
        </div>
      )}
      {!isVerified && contactMethod === 'npub' && (
        <div className="col-span-2 relative">
          <input
            className="w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 pl-3 pr-20"
            type="text"
            value={npub}
            onChange={(e) => setNpub(e.target.value)}
            placeholder="Nostr npub*"
            required={contactMethod === 'npub'}
          />
          {!npubVerified && (
            <button
              type="button"
              onClick={() => handleNpubSubmit(npub)}
              className="absolute inset-y-0 right-0 px-4 text-white bg-gray-800 rounded-r-md hover:bg-gray-700"
            >
              Verify npub
            </button>
          )}
          {npubVerified && (
            <button
              type="button"
              disabled={true}
              onClick={() => handleNpubSubmit(npub)}
              className="absolute inset-y-0 right-0 px-4 text-white bg-green-600 rounded-r-md"
            >
              Verified
            </button>
          )}
        </div>
      )}
      {(!emailVerified || !npubVerified) && nostrVerificationCodeSent && (
        <div className="col-span-2">
          <NpubCodeVerificationGeyser
            npub={npub}
            npubVerified={npubVerified}
            setNpubVerified={setNpubVerified}
            errorMessageNostrDM={errorMessageNostrDM}
            setErrorMessageNostrDM={setErrorMessageNostrDM}
            onCodeConfirmed={() => handleCodeConfirmedWrapper('npub', npub)}
          />
        </div>
      )}
      {(emailVerified || npubVerified) && !walletNwcUrl && (
        <>
          <div className="col-span-2 mt-2">
            <h2 className="text-lg font-semibold text-gray-900">Payment</h2>
          </div>
          <WalletConnectButton onWalletConnected={handleWalletConnected} appName={appName} />
        </>
      )}
      {walletNwcUrl && (
        <>
          <div className="col-span-2 flex justify-between">
            <WalletConnectButtonSuccess />
          </div>
          <div className="col-span-2 flex justify-between">
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Purchase Subscription
            </button>
          </div>
          {errorMessage && (
            <div className="col-span-2 text-red-500 text-sm mb-2">
              {errorMessage}
            </div>
          )}
        </>
      )}
      {errorMessage && !walletNwcUrl && (
        <div className="col-span-2 text-red-500 text-center mb-2">
          {errorMessage}
        </div>
      )}
    </form>
  );
};

export default ProductOrderFormGeyser;
