import React, { useEffect, useState, useRef } from 'react';
import { nip04, getPublicKey } from 'nostr-tools';
import { webln } from "@getalby/sdk";
import { Invoice } from "alby-tools";

import Lottie from 'react-lottie';

import confettiAnimation from '../../assets/confetti_animation.json';
import { XCircleIcon } from '@heroicons/react/24/outline';

import SelectWallet from './SelectWallet';
import Summary from './Summary';



// Helper function to fetch wallet balance
async function getWalletBalance(nwcUrl) {
  const nwc = new webln.NostrWebLNProvider({
      nostrWalletConnectUrl: nwcUrl,
  });
  await nwc.enable();
  const balanceResponse = await nwc.getBalance();
  nwc.close();
  return balanceResponse;
}


const getWalletLogoUrlFromNWCString = async (nwcString) => {
  const walletsAvailable = [
      {
          name: "alby",
          logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/AlbyWalletLogo.png",
      },
      {
          name: "mutiny",
          logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/MutinyWalletLogo.jpeg",
      },
  ];

  const lowerCaseNWCString = nwcString.toLowerCase();

  for (let i = 0; i < walletsAvailable.length; i++) {
      if (lowerCaseNWCString.includes(walletsAvailable[i].name)) {
          return walletsAvailable[i].logoURL;
      }
  }

  // Return "Custom" or a default image if no match is found
  return "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png"; // Replace with your default wallet logo URL
};



// Function that gets all user wallets
async function getUserWallets(userPrivateKey, publicKey) {
  const userPublicKey = getPublicKey(userPrivateKey);
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const walletPromises = data.map(async item => {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
              decrypted_nwc_url = await nip04.decrypt(userPrivateKey, publicKey, item.nwc_url);
          } else {
              decrypted_nwc_url = "";
          }

          // Fetch balance and logo URL concurrently
          const balancePromise = getWalletBalance(decrypted_nwc_url); // Abstracted balance fetching to a function
          const logoUrlPromise = getWalletLogoUrlFromNWCString(decrypted_nwc_url); // Fetch logo URL

          try {
              const [balanceResponse, walletLogoUrl] = await Promise.all([balancePromise, logoUrlPromise]);

              return {
                  id: item.id,
                  user_publicKey: item.user_public_key,
                  wallet_name: item.wallet_name,
                  user_wallet_name: item.user_wallet_name,
                  nwc_url: item.nwc_url,
                  balance: balanceResponse.balance, // Adding balance to the result
                  wallet_logo_url: walletLogoUrl // Adding wallet logo URL to the result
              };
          } catch (error) {
              console.error('Error processing wallet:', error);
              return {
                  ...item,
                  nwc_url: item.nwc_url,
                  balance: 'Error', 
                  wallet_logo_url: 'default-logo-url.jpg' // Replace with your default logo URL
              };
          }
      });

      return await Promise.all(walletPromises);
  } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
  }
}




// Function used to pay Zap Invoices
async function payZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, invoice) {

  try {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_zap_invoice', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              userPublicKey,
              encryptedUserNostrWalletConnectUrl,
              invoice,
          }),
      });

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.is_paid; // This will be the JSON object { is_paid: true/false }
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error making payment:', error);
      throw error;
  }
}









  // Function to save the transaction to table transactions of the db
  async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
    const appendIfNotNull = (formData, key, value) => {
      if (value !== null) {
        formData.append(key, value);
      } else {
        formData.append(key, '');
      }
    };

    const formData = new FormData();
    appendIfNotNull(formData, 'from_public_key', from_public_key);
    appendIfNotNull(formData, 'to_public_key', to_public_key);
    appendIfNotNull(formData, 'transaction_date', transactionDate);
    appendIfNotNull(formData, 'amount_in_sats', amountInSats);
    appendIfNotNull(formData, 'value_in_usd', valueInUsd);
    appendIfNotNull(formData, 'flash_id', flashId);
    appendIfNotNull(formData, 'flash_name', flashName);
    appendIfNotNull(formData, 'flash_type', flashType); // Assuming this is also a new requirement
    appendIfNotNull(formData, 'to_wallet_id', toWalletId);
    appendIfNotNull(formData, 'to_user_wallet_name', toUserWalletName);
    appendIfNotNull(formData, 'from_wallet_id', fromWalletId);
    appendIfNotNull(formData, 'from_user_wallet_name', fromUserWalletName);
    appendIfNotNull(formData, 'lnurl', lnurl);

    try {
      const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save transaction to database');
      }

      const successData = await response.json();
      console.log('Transaction successfully saved to database:', successData);
      return successData;
    } catch (error) {
      console.error('Error saving transaction to database:', error);
      throw error;
    }
  }

  



  async function getFlashPublicKey() {
    try {
      // Make a GET request to the API endpoint
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/getFlashPublicKey', {
        method: 'GET',
        headers: {
          // You can add headers if needed, such as authentication headers
        },
      });

      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Parse the JSON response to get the public key
        const data = await response.json();
        return data.publicKey;
      } else {
        // Handle the error if the response status is not 200
        throw new Error(`API call failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error in getFlashPublicKey:', error);
      throw error;
    }
  }


  // Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
          method: 'POST', // if the API requires POST method
          headers: {
              'Content-Type': 'application/json',
          },
          // If the API requires a body payload, uncomment and edit the following line
          // body: JSON.stringify({ key: 'value' }), 
      });

      if (!response.ok) {
          // If the response is not OK, throw an error with the response status
          throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();

      // Assuming the API returns an object with a property that contains the Bitcoin price
      return data
  } catch (error) {
      console.error('API call failed:', error);
      throw error; // Rethrow the error to be handled by the caller
  }
}


  // Function to go through the plans and make sure we have two columns: price in sats & price in dollars
  async function convertPrices(currency, value) {
    try {
        const bitcoinPrice = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars

        let convertedPrice;

        if (currency === "usd") {
            const priceInDollars = value / 100000000; // Convert satoshis to bitcoin
            convertedPrice = (priceInDollars * bitcoinPrice).toFixed(2);
        } else if (currency === "satoshis") {
            convertedPrice = value;
        }
        return convertedPrice;

    } catch (error) {
        console.error('Conversion failed:', error);
        return null; // Return null in case of error
    }
  }




  const decodeInvoice = async (invoice) => {
    try {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/decode-invoice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ invoice }),
      });
      if (!response.ok) throw new Error('Failed to decode invoice');
      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
    }
  };








function PayFromQRCode({ invoice, toggleContentVisibility }) {

  // States for data received upon page load
  const [publicKey, setPublicKey] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState({});


  // States for user wallet
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(false);

  // States for managing app states
  const [selectWallet, setSelectWallet] = useState(true);
  const [summary, setSummary] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [loading, setLoading] = useState(false);

  // State to control the confetti animation
  const [showConfettiAnimation, setShowConfettiAnimation] = useState(false);

  // Lottie options for the confetti animation
  const defaultOptions = {
    loop: false,
    autoplay: false, // Controlled via state
    animationData: confettiAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };



  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";


  useEffect(() => {
    async function fetchData() {
      try {
        // First, fetch the public key
        const retrievedPublicKey = await getFlashPublicKey();
        if (retrievedPublicKey) {
          setPublicKey(retrievedPublicKey);
          // Once the public key is successfully fetched and set, fetch the user wallets
          const userWallets = await getUserWallets(userHexPrivateKey, retrievedPublicKey); // Use retrievedPublicKey directly here
          setWallets(userWallets);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    if (userHexPrivateKey) { // Ensure you have the privateKey before attempting to fetch
      fetchData();
    }
  }, [userHexPrivateKey]); // This effect depends on userHexPrivateKey, ensure it's defined



  // Hook to get the details
  useEffect(() => {
    async function decodeAndSetInvoiceDetails() {
      if (invoice) {

        const invoiceObj = await decodeInvoice(invoice);

        try {
          let usdAmount = 0; // Default to 0
          // Assuming you have a function convertPrices to convert satoshis to USD
          if (invoiceObj.satoshis) {
            usdAmount = await convertPrices('usd', invoiceObj.satoshis);
          }

          // Extract the description, create and expiry dates from the tags array
          const descriptionTag = invoiceObj.tags.find(tag => tag.tagName === 'description');
          const description = descriptionTag ? descriptionTag.data : 'No description';

          // Set all invoice details
          setInvoiceDetails({
            paymentRequest: invoiceObj.paymentRequest,
            satoshi: invoiceObj.satoshis || 'Variable',
            description: description,
            createdDate: new Date(invoiceObj.timestamp * 1000).toISOString(),
            expiryDate: invoiceObj.timeExpireDateString || 'No expiry',
            usdValue: usdAmount,
          });
        } catch (error) {
          console.error('Error converting satoshi to USD:', error);
        }

      }
    }

    decodeAndSetInvoiceDetails();
  }, [invoice]);
  



  const handleSelectWallet = async (wallet) => {
    // Set the selected plan to the state
    setSelectedWallet(wallet);

    // Hide the Selet Wallet interface
    setSelectWallet(false);
    // Show the Summary interface
    setSummary(true);

  };

  
  const handleBackFromSummary = () => {
    // Assuming you want to go back to wallet selection
    setSummary(false);
    setSelectWallet(true);
  };
  



  const handleConfirm = async () => {
    // Set the error to false
    setSummaryError(false);

    setLoading(true);

    try {
    
      // Pay invoice with user wallet nwc
      const is_paid = await payZapInvoice(userHexPublicKey, selectedWallet.nwc_url, invoice)

      // If successful, save data to db
      if (is_paid) {
        setLoading(false);

        // Trigger the confetti animation
        setShowConfettiAnimation(true);
        // Reset the animation state after its duration (e.g., 3000ms for a 3-second animation)
        setTimeout(() => {
          setShowConfettiAnimation(false);
        }, 3000);

        // toggleContentVisibility();
        // Save transaction to db
        const transactionDate = new Date().toISOString();
        await saveTransactionToDB(userHexPublicKey, "", transactionDate, invoiceDetails.satoshi, invoiceDetails.usdValue, "", "", "QR Code Payment", "", "", selectedWallet.id, selectedWallet.user_wallet_name, invoice)

        // Refresh the page to close the component
        window.location.reload();        


      } else {
        // Else, show summary page again with an error
        setLoading(false);

        // Show the Summary interface
        setSummary(true);
        setSummaryError(true);
      }

    } catch (error) {
      // Else, show summary page again with an error
      setLoading(false);

      // Show the Summary interface
      setSummary(true);
      setSummaryError(true);
    }
  };





  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-start pt-4">
      <div className="relative w-full h-full overflow-auto bg-gradient-to-br from-purple-900 to-black">
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        )}
  
        {/* If the user is selecting a wallet */}
        {selectWallet && (
          <SelectWallet 
            wallets={wallets} 
            setWallets={setWallets} 
            userPublicKey={userHexPublicKey} 
            userPrivateKey={userHexPrivateKey} 
            publicKey={publicKey} 
            getUserWallets={getUserWallets} 
            onSelectWallet={handleSelectWallet} 
            className="mx-4 mb-4"
          />
        )}
  
        {/* Show the summary interface */}
        {summary && (
          <Summary 
            wallet={selectedWallet} 
            summaryError={summaryError} 
            invoiceDetails={invoiceDetails} 
            onConfirm={handleConfirm} 
            onBack={handleBackFromSummary} 
          />
        )}
  
        {/* Confetti Animation Overlay */}
        {showConfettiAnimation && (
          <div className="fixed inset-0 z-60 flex items-center justify-center">
            <Lottie options={defaultOptions} height={800} width={800} isStopped={!showConfettiAnimation} />
          </div>
        )}
      </div>
    </div>
  );
  

  
}

export default PayFromQRCode;

 