import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generatePrivateKey, getPublicKey } from 'nostr-tools';
import { nip19 } from 'nostr-tools';

const { npubEncode, nsecEncode } = nip19;

function AutoGenerateKeys() {
    const [loading, setLoading] = useState(true); // Initialize to true to show the loader immediately
    const navigate = useNavigate();

    const convertHexToNostr = (hex, type) => {
        if (type === 'npub') {
            return npubEncode(hex);
        } else if (type === 'nsec') {
            return nsecEncode(hex);
        } else {
            throw new Error('Invalid type. Type must be "npub" or "nsec".');
        }
    };

    useEffect(() => {
        const handleCreatePrivateKey = () => {
            const newPrivateKey = generatePrivateKey();
            const publicKey = getPublicKey(newPrivateKey);
            const nsec = convertHexToNostr(newPrivateKey, 'nsec');
            const npub = convertHexToNostr(newPrivateKey, 'npub');
      
            // Store keys in session storage
            localStorage.setItem('userHexPrivateKey', newPrivateKey);
            localStorage.setItem('userHexPublicKey', publicKey);
            localStorage.setItem('userNsec', nsec);
            localStorage.setItem('userNpub', npub);
      
            // Redirect to the dashboard page
            navigate('/dashboard');
            setLoading(false);
        };
        setTimeout(handleCreatePrivateKey, 1000); // Simulating a delay like in your original function
    }, [navigate]);

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-gray-900 text-white h-screen">
            {loading && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                </div>
            )}
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex shrink-0 items-center justify-center logo-wrap">
                    <img
                        className="h-48 home-logo"
                        src="./FLASH (9).png"
                        alt="NostrEggs logo"
                    />
                </div>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
                    Generating your private key...
                </h2>
            </div>
        </div>
    );
}

export default AutoGenerateKeys;
