import { UPDATE_WALLET } from './types.js';
import store from '../store.js';
import { nip04, getPublicKey} from 'nostr-tools';
import { webln } from "@getalby/sdk";

// ------------------------- Helper Functions --------------------------------
// Helper function to fetch wallet balance
async function getWalletBalance(nwcUrl) {
    const nwc = new webln.NostrWebLNProvider({
        nostrWalletConnectUrl: nwcUrl,
    });
    await nwc.enable();
    const balanceResponse = await nwc.getBalance();
    nwc.close();
    return balanceResponse;
}

const getWalletLogoUrlFromNWCString = async (nwcString) => {
    const walletsAvailable = [
        {
            name: "alby",
            logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/AlbyWalletLogo.png",
        },
        {
            name: "mutiny",
            logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/MutinyWalletLogo.jpeg",
        },
    ];

    const lowerCaseNWCString = nwcString.toLowerCase();

    for (let i = 0; i < walletsAvailable.length; i++) {
        if (lowerCaseNWCString.includes(walletsAvailable[i].name)) {
            return walletsAvailable[i].logoURL;
        }
    }

    // Return "Custom" or a default image if no match is found
    return "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png"; // Replace with your default wallet logo URL
};

// ------------------------- Actions --------------------------------

export const getAndUpdateWalletList = async (userPrivateKey, flashPublicKey) => {
    try {
        const userPublicKey = getPublicKey(userPrivateKey);
        try {
            const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const walletPromises = data.map(async item => {
                let decrypted_nwc_url;
                if (item.nwc_url !== "") {
                    decrypted_nwc_url = await nip04.decrypt(userPrivateKey, flashPublicKey, item.nwc_url);
                } else {
                    decrypted_nwc_url = "";
                }


                // Fetch balance and logo URL concurrently
                const balancePromise = getWalletBalance(decrypted_nwc_url); // Abstracted balance fetching to a function
                const logoUrlPromise = getWalletLogoUrlFromNWCString(decrypted_nwc_url); // Fetch logo URL

                try {
                    const [balanceResponse, walletLogoUrl] = await Promise.all([balancePromise, logoUrlPromise]);

                    return {
                        id: item.id,
                        user_publicKey: item.user_public_key,
                        wallet_name: item.wallet_name,
                        user_wallet_name: item.user_wallet_name,
                        nwc_url: item.nwc_url,
                        balance: balanceResponse.balance, // Adding balance to the result
                        wallet_logo_url: walletLogoUrl // Adding wallet logo URL to the result
                    };
                } catch (error) {
                    console.error('Error processing wallet:', error);
                    return {
                        ...item,
                        nwc_url: item.nwc_url,
                        balance: 'Error',
                        wallet_logo_url: 'default-logo-url.jpg' // Replace with your default logo URL
                    };
                }
            });

            const processedWallets = await Promise.all(walletPromises);

            store.dispatch ({type : UPDATE_WALLET, payload : processedWallets})
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            throw error;
        }
    } catch (err) {
        console.log (err);
    }
}