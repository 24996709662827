import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import {UserIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline'; // Make sure to import the necessary icons


function GetAllPublicEggs({ onSetActiveSection }) {
  const [eggList, setEggList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [eggType, setEggType] = useState('All'); // For Free/Premium dropdown
  const [filteredEggList, setFilteredEggList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let navigate = useNavigate(); 

  useEffect(() => {
    fetch(`https://api.paywithflash.com/api/get_all_eggs`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setEggList(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching the data', error);
      });
  }, []);

  useEffect(() => {
    const filtered = eggList.filter(egg =>
      egg.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (eggType === 'All' || (eggType === 'Free' && egg.price === 0) || (eggType === 'Premium' && egg.price > 0)) // Filtering based on Free/Premium
    );
    setFilteredEggList(filtered);
  }, [eggList, searchTerm, eggType]);

  const handleViewEgg = (egg_name) => {
    navigate(`/egg-detail-view?egg_name=${egg_name}&origin=global`); 
  };

  return (
    <div className="mx-auto w-full">
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <header className="flex items-center justify-between border-b border-gray-100 px-4 py-4">
          <div className="flex items-center gap-2">
            {/* Search filter */}
            <input 
              type="text" 
              placeholder="Search by name or ID..." 
              value={searchTerm} 
              onChange={e => setSearchTerm(e.target.value)} 
              className="p-2 border rounded text-sm focus-within:ring-purple-800  egg-filter"
            />
            {/* Dropdown filter for Free/Premium */}
            <select 
              value={eggType}
              onChange={e => setEggType(e.target.value)}
              className="p-2 border rounded text-sm focus-within:ring-purple-800  egg-filter"
            >
              <option value="All">All</option>
              <option value="Free">Free</option>
              <option value="Premium">Premium</option>
            </select>
          </div>

          <button
            onClick={onSetActiveSection}
            className="inline-flex items-center gap-x-2 rounded-md bg-white border border-gray-300 px-3.5 py-2.5 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Create a new egg
          </button>
        </header>

        <ul role="list" className="divide-y divide-gray-100">
          {isLoading ? (
            Array.from({ length: 2 }).map((_, index) => (
              <li key={index} className="px-4 py-6">
                {/* Your loading skeletons here */}
              </li>
            ))
          ) : filteredEggList.length > 0 ? (
          filteredEggList.map((egg) => (
  <li 
    key={egg.name} 
    className="relative flex items-center space-x-4 px-4 py-4 sm:px-6 lg:px-4 cursor-pointer hover:bg-gray-100"
    onClick={() => handleViewEgg(egg.name)}
  >
    <img
      src={"https://nostreggs.io/wp-content/uploads/2023/09/cropped-NostrEggs-Logos.png"}
      alt={egg.name}
                className="flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10 egg-image"
    />
    <div className="min-w-0 flex-auto">
      <h2 className="text-sm font-medium leading-6 text-gray-900 egg-name">{egg.name}</h2>
      <p className="mt-1 text-xs leading-5 text-gray-400 truncate-multiline egg-details">{egg.description}</p>
      <div className="flex items-center gap-x-2.5 text-xs leading-5 text-gray-400 egg-footer">
  {/* Bitcoin icon next to the price */}
  <CurrencyDollarIcon className="h-4 w-4 text-gray-600" aria-hidden="true" />
  <p className="whitespace-nowrap egg-price">{egg.price} sats</p>

  {/* User/Profile icon next to the truncated owner */}
  <UserIcon className="h-4 w-4 text-gray-600 ml-3" aria-hidden="true" />
  <p className="egg-owner">{egg.created_by && egg.created_by.length > 12 ? `${egg.created_by.substring(0, 12)}...` : egg.created_by}</p>
</div>
    </div>
  </li>
))
          ) : (
            <li className="px-4 py-6 text-center text-gray-500">No eggs found.</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default GetAllPublicEggs;
