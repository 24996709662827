// StatsComponent.js
import React from 'react';

import AreaChartTxHistory from './AreaChartTxHistory';
import PieChartTxPerFlashType from './PieChartTxPerFlashType';
import AreaChartTxAverageValue from './AreaChartTxAverageValue';



const ChartsComponent = ({ filteredData }) => {

  return (
    <div>
      <dl className="mb-6 grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <AreaChartTxHistory data={filteredData} />
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <PieChartTxPerFlashType data={filteredData} />
        </div>
        {/* <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <AreaChartTxAverageValue data={filteredData} />
        </div> */}
      </dl>
    </div>
  );
};

export default ChartsComponent;
