import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const encodeCart = (cart) => {
    const jsonString = JSON.stringify(cart);
    return encodeURIComponent(btoa(unescape(encodeURIComponent(jsonString))));
};

const SuccessScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        cart = [],
        user = {},
        orderDetails = {},
        subtotal = 0,
        subtotalInSats = 0,
        totalTaxes = 0,
        totalTaxesInSats = 0,
        total = 0,
        totalInSats = 0,
        totalInZloty = 0
    } = location.state || {};


    if (!cart || cart.length === 0) {
        return <div>No items in the cart.</div>;
    }

    const handleBackToStore = () => {
        const encodedCart = encodeCart(cart);
        const userPublicKey = user.replace(/"/g, ''); // Remove any quotation marks
        navigate(`/product-gallery?user=${userPublicKey}`, { state: { cart, user } });
    };

    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-3xl w-full">
                <div className="max-w-xl mx-auto">
                    <h1 className="text-base font-medium text-indigo-600">Thank you!</h1>
                    <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">It's on the way!</p>
                    <p className="text-base text-gray-500">Your order has been processed successfully.</p>
                    <button
                        className="mt-2 text-sm bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={handleBackToStore}
                    >
                        Back to Store
                    </button>
                </div>

                <div className="mt-10 border-t border-gray-200 max-w-xl mx-auto">
                    <h2 className="sr-only">Your order</h2>

                    {cart.map((product, index) => (
                        <div key={index} className="flex space-x-6 border-b border-gray-200 py-10">
                            <img
                                src={product.ImageURLs[0]}
                                alt={product.Name}
                                className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                            />
                            <div className="flex-auto flex-col">
                                <div>
                                    <h4 className="font-medium text-gray-900">{product.Name}</h4>
                                    <p className="mt-2 text-sm text-gray-600">
                                        {product.Description.length > 100 ? `${product.Description.substring(0, 100)}...` : product.Description}
                                    </p>
                                </div>
                                <div className="mt-6 flex flex-1 items-end">
                                    <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                                        <div className="flex">
                                            <dt className="font-medium text-gray-900">Quantity</dt>
                                            <dd className="ml-2 text-gray-700">{product.quantity}</dd>
                                        </div>
                                        <div className="flex pl-4 sm:pl-6">
                                            <dt className="font-medium text-gray-900">Price</dt>
                                            <dd className="ml-2 text-gray-700">${(product.PriceInDollars * product.quantity)?.toFixed(2) || 'N/A'}</dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="sm:ml-40 sm:pl-6">
                        <h3 className="sr-only">Summary</h3>

                        <dl className='mt-2 mb-2'>
                            <dt className="text-sm font-medium">Amount paid</dt>
                            <dd className="mt-1 text-3xl font-bold tracking-tight text-gray-900">${total.toFixed(2)}</dd>
                            <dd className="text-xs font-medium text-gray-700">{totalInSats.toLocaleString()} sats</dd>
                        </dl>

                        <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium">
                            <div className="flex items-center justify-between">
                                <dt>Subtotal</dt>
                                <div>
                                    <dd>${subtotal.toFixed(2)}</dd>
                                    <dd className="text-sm text-gray-500">{subtotalInSats.toLocaleString()} sats</dd>
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <dt>Taxes</dt>
                                <div>
                                    <dd>${totalTaxes.toFixed(2)}</dd>
                                    <dd className="text-sm text-gray-500">{totalTaxesInSats.toLocaleString()} sats</dd>
                                </div>
                            </div>

                            <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                                <dt className="text-base">Total</dt>
                                <div>
                                    <dd className="text-base">${total.toFixed(2)}</dd>
                                    <dd className="text-sm text-gray-500">{totalInSats.toLocaleString()} sats</dd>
                                </div>
                            </div>
                        </dl>

                    </div>
                </div>
                <div className="mb-4 mt-12 text-xs text-center text-slate-500 py-2">Powered by <a href="https://paywithflash.com" className="font-bold text-slate-500">Flash</a></div>

            </div>
        </div>
    );
};

export default SuccessScreen;
