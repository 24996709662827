// Inputs.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';

import SubscriptionPlans from './SubscriptionPlans'
import { BiLogoBitcoin, BiDollarCircle, BiWallet, BiSolidLockAlt, BiSolidInfoCircle } from 'react-icons/bi';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { TbCurrencyZloty } from "react-icons/tb";

// BASIC FORM COMPONENTS

export const TextInput = ({ label, placeholder, value, onChange, maxLength }) => (
  <div>
    <label className="block text-sm font-medium text-slate-700 mb-2">{label}</label>
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      className="block w-full input  input-bordered "
    />
  </div>
);

export const ImageUpload = ({ label, onChange }) => (
  <div className="form-control w-full max-w-xs">
    <label className="label">
      <span className="label-text text-sm font-medium text-indigo-300">{label}</span>
    </label>
    <input
      type="file"
      accept="image/*"
      onChange={onChange}
      className="input input-bordered input-primary w-full file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
    />
  </div>
);

export const CheckBox = ({ label, isChecked, onChange }) => (
  <div className="flex">
    <input
      type = "checkbox"
      checked={isChecked}
      onChange = {onChange}
      className = "block rounded-md border-indigo-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mr-2"
    />
    <label className = "block text-sm font-medium text-indigo-300 mb-2">{label}</label>
  </div>
);

export const SubscriptionPlanList = ({ label, id, name, plans, onPlansChange }) => (
  <SubscriptionPlans label={label} id={id} name={name} plans={plans} onPlansChange={onPlansChange} />
);

export const ReferralLinkDisplayAndCopy = ({ value }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the icon after 2 seconds
    };

    return (
        <div className="relative flex items-center rounded-md m-2">
            <input
                type="text"
                value={value}
                readOnly
                className="block w-full  mr-2 border-slate-300 px-3 rounded-lg bg-gray-50  text-gray-700 shadow-sm focus:outline-none sm:text-sm sm:leading-6"
            />
            <button 
                onClick={copyToClipboard}
                type="button"
                className="bg-white border border-slate-200 text-gray-600 hover:text-gray-800 p-2.5 rounded-lg focus:outline-none"
                style={{ height: '100%' }} // Ensure the button stretches to the full height of the container
            >
                {copied ? <CheckIcon className="h-5 w-5" aria-hidden="true" /> : <ClipboardIcon className="h-5 w-5" aria-hidden="true" />}
            </button>
        </div>
    );
};

export const SimpleDropdown = ({ label, id, name, options, value, onChange }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-indigo-300 mb-2">
      {label}
    </label>
    <select
      id={id}
      name={name}
      autoComplete="off"
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      value={value}
      onChange={onChange}
    >
      {options.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </select>
  </div>
);

export const IconDropdown = ({ label, id, name, options, value, onChange, iconRenderer }) => (
  <div>
    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1 flex rounded-md shadow-sm">
      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
        {iconRenderer && iconRenderer()}
      </span>
      <select
        id={id}
        name={name}
        autoComplete="off"
        className="block flex-1 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={value}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

// FLASH FORM COMPONENTS

export const NumberInputWithConverter = ({ label, name, id, placeholder, value, onChange, convertedValue }) => (
  <div className="md:flex-1">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-300 mb-2">
      {label}
    </label>
    <div className="mt-1 relative">
      <input
        type="number"
        name={name}
        id={id}
        autoComplete="off"
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <p className="text-sm text-gray-400 absolute bottom-0 right-0 mb-2 mr-3">
        {convertedValue}
      </p>
    </div>
  </div>
);



export const NumberInput = ({ label, name, id, placeholder, value, onChange }) => (
  <div className="md:flex-1">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-300 mb-2">
      {label}
    </label>
    <div className="mt-1 relative">
      <input
        type="number"
        name={name}
        id={id}
        autoComplete="off"
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

export const LifetimeDurationDropdown = ({ label, id, name, value, onChange }) => {
  const durationOptions = [
    { value: '1 day', label: '1 day' },
    { value: '3 days', label: '3 days' },
    { value: 'lifetime', label: 'Lifetime' },
    // Add other duration options if necessary
  ];

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id={id}
        name={name}
        autoComplete="off"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={value}
        onChange={onChange}
      >
        {durationOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

export const LoginMethodDropdown = ({ label, id, name, value, onChange }) => {
  const loginMethodOptions = [
    { value : 'email', label : 'Email'},
    { value : 'nostr', label : 'Nostr'},
    { value : 'email & nostr', label : 'Email & Nostr'},
  ];

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-indigo-300 mb-2">
        {label}
      </label>
      <select
        id={id}
        name={name}
        autoComplete="off"
        className="mt-1 p-2 block w-full rounded-md border-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={value}
        onChange={onChange}
      >
        {loginMethodOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
}


export const SubscriptionSelectionDropdown = ({ label, id, name, value, userPublicKey, onChange }) => {
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      try {
        const url = new URL('https://api.paywithflash.com/api/get_user_flashes_by_type');
        const params = { user_public_key: userPublicKey, flash_type: 'Subscription Plan' };
        url.search = new URLSearchParams(params).toString();
    
        const response = await fetch(url);
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        const options = data.flashes.map(flash => ({
          value: flash.id,
          label: flash.name,
          flash_id: flash.id
        }));

        if (options.length > 0) {
          setSubscriptionOptions(options);
          onChange({ target: { value: options[0].value } }); // Set default
        }
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        // Handle error or set default state
      }
    };

    fetchSubscriptionPlans();
  }, [userPublicKey]);

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id={id}
        name={name}
        autoComplete="off"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={value}
        onChange={onChange}
      >
        {subscriptionOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};




export const CurrencyDropdown = ({ label, id, name, value, onChange }) => {
  const currencyOptions = [
    { value: 'Satoshis', label: 'Satoshis', icon: <BiLogoBitcoin className="h-5 w-5" aria-hidden="true" /> },
    { value: 'Dollar', label: 'Dollars', icon: <BiDollarCircle className="h-5 w-5" aria-hidden="true" /> },
    { value: 'Zloty', label: 'Zloty', icon: <TbCurrencyZloty className="h-5 w-5" aria-hidden="true" /> },
  ];

  const renderCurrencyIcon = () => {
    const selectedOption = currencyOptions.find(option => option.value === value);
    return selectedOption ? selectedOption.icon : null;
  };

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-slate-700 mb-2">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center p-2 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
          {renderCurrencyIcon()}
        </span>
        <select
          id={id}
          name={name}
          autoComplete="off"
          className="block flex-1 border border-r-0 border-gray-300 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={value}
          onChange={onChange}
        >
          {currencyOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const WalletDropdown = ({ label, id, name, value, wallets, onChange }) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-indigo-300 mb-2">
        {label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center p-2 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
        <BiWallet className="h-5 w-5" aria-hidden="true" />
        </span>
        <select
          id={id}
          name={name}
          autoComplete="off"
          className="block flex-1 border border-r-0 border-gray-300 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={value}
          onChange={onChange}
        >
          {wallets.map(wallet => (
            <option key={wallet.id} value={wallet.id} className='border border-r-0 border-gray-300'>{wallet.user_wallet_name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};


// FLASH FORM BUTTONS

export const CancelButton = ({ onClose }) => (
  <button
    type="button"
    onClick={onClose}
    className="rounded-md bg-gray-200 px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-300 ml-2"
  >
    Cancel
  </button>
);

export const ConfirmButton = ({ onClick, children }) => (
  <button
    type="button"
    onClick={onClick}
    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700"
  >
    {children}
  </button>
);

export const PaymentInfo = ({ text }) => (
  <div className="max-w-4xl text-sm text-gray-300 mb-6">
    <BiSolidLockAlt className="icon-lock inline" aria-hidden="true" />
    <span className="ml-2">{text}</span>
  </div>
);

// FLASH FORM HEADERS


export const FormHeader = ({ title, subtitle, infoBadge, newEggBadge }) => (
  <div>
    {/* New Egg Badge */}
    {newEggBadge && (
      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${newEggBadge.color}`}>
        {newEggBadge.text}
      </span>
    )}

    {/* Title */}
    <h3 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
      {title}
    </h3>

    {/* Subtitle */}
    <p className="mt-3 max-w-4xl text-sm text-gray-500 mb-3">
      {subtitle}
    </p>

    {/* Info Badge */}
    {infoBadge && (
      <span
        className={`inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium ${infoBadge.color}`}
        onClick={infoBadge.onClick} // Add the onClick handler here
        style={{ cursor: 'pointer' }} // Add cursor style to indicate it's clickable
      >
        <BiSolidInfoCircle className="h-3 w-3" aria-hidden="true" />
        {infoBadge.text}
      </span>
    )}
  </div>
);



export const Modal = ({ children, onClose }) => {
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOutsideClick} // Close the modal when clicking outside
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-6xl relative">
        <button className="absolute top-2 right-2 text-gray-600" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
};