import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { EyeIcon as FaEye } from '@heroicons/react/24/outline';

function ResetPassword() {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  


    const handleResetPassword = async () => {
        if (newPassword !== confirmPassword) {
          setMessage('Passwords do not match'); // Display error message
          return;
        }
    
        try {
          const response = await fetch('https://api.paywithflash.com/api/reset-password', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token, // The token extracted from the URL
              new_password: newPassword,
            }),
          });
    
          if (response.ok) {
            setMessage(<span>Your password has been reset successfully. <a href="/login" style={{color: 'inherit', textDecoration: 'underline'}}>Login now!</a></span>); // Display success message with link
          } else {
            const data = await response.json();
            setMessage('Password reset failed: ' + data.error); // Display error message
          }
        } catch (error) {
          setMessage('Error resetting password: ' + error); // Display error message
        }
      };


      const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="flex items-center flex-col min-h-screen justify-center px-6 py-12 text-white" style={{ backgroundImage: 'radial-gradient(circle, rgb(37 13 79), rgb(20 6 50))' }}>
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex shrink-0 items-center justify-center logo-wrap">
                    <Link to="/login">
                        <img
                            className="w-64 home-logo"
                            src="/FLASH (9).png"
                            alt="Flash logo"
                        />
                    </Link>
                </div>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight">
                    Reset Your Password
                </h2>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form className="space-y-6">
                    <div>
                        <label htmlFor="newPassword" className="block text-sm font-medium leading-6">
                            New Password
                        </label>
                        <div className="mt-2 relative">
                            <input
                                id="newPassword"
                                name="newPassword"
                                type={showNewPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
                            />
                            <button 
                                onClick={toggleNewPasswordVisibility} 
                                type="button"
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300 eye-icon"
                            >
                                <FaEye />
                            </button>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6">
                            Confirm Password
                        </label>
                        <div className="mt-2 relative">
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
                            />
                            <button 
                                onClick={toggleConfirmPasswordVisibility} 
                                type="button"
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300 eye-icon"
                            >
                                <FaEye />
                            </button>
                        </div>
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={handleResetPassword}
                            className="flex w-full justify-center rounded-md bg-purple-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                            Reset Password
                        </button>
                    </div>
                </form>
            </div>
            {message && <p className="text-center text-sm
    text-purple-500">{message}</p>} {/* Render the message as JSX */}
    </div>
    );

}

export default ResetPassword;