import React, { useState, useEffect, useRef } from 'react';
import { parseISO, differenceInMinutes, format } from 'date-fns';
import { Link as ScrollLink } from 'react-scroll';


import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';






const BitcoinFilmFestAgendaPage = () => {

  const [currentTime, setCurrentTime] = useState(new Date());
  const currentTimeRef = useRef(null); // Ref to hold the current time slot element

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);  // Update every minute
    return () => clearInterval(timer);
  }, []);


  const navigate = useNavigate(); // Initialize useNavigate


  const getBaseHour = (dateString) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 4 ? 17 : 9;  // Thursday is day index 4
  };

  const preprocessEvents = (events) => {
    return events.map(event => {
      const startTime = parseISO(`${event.date}T${event.startTime}`);
      const endTime = parseISO(`${event.date}T${event.endTime}`);
      const isPast = endTime < currentTime;  // Check against current time

      const baseHour = getBaseHour(event.date);

      const startMinutes = startTime.getHours() * 60 + startTime.getMinutes();
      const endMinutes = endTime.getHours() * 60 + endTime.getMinutes();

      const startRow = Math.floor((startMinutes - baseHour * 60) / 15) + 1;
      const endRow = Math.floor((endMinutes - baseHour * 60) / 15) + 1;

      return {
        ...event,
        isPast,
        gridColumnStart: event.track,
        gridRowStart: startRow,
        gridRowEnd: endRow
      };
    });
  };


  // Converts HH:mm time into minutes since start of day
  const getMinutesFromStartOfDay = (datetime) => {
    const midnight = new Date(datetime);
    midnight.setHours(0, 0, 0, 0);
    return differenceInMinutes(datetime, midnight);
  };


  const generateTimeSlots = (events, baseHour) => {
    let minHour = 24;
    let maxHour = 0;
    events.forEach(event => {
      const startTime = parseISO(`${event.date}T${event.startTime}`);
      const endTime = parseISO(`${event.date}T${event.endTime}`);
      minHour = Math.min(minHour, startTime.getHours());
      maxHour = Math.max(maxHour, endTime.getHours());
    });

    const timeSlots = [];
    for (let hour = minHour; hour <= maxHour; hour++) {
      timeSlots.push(`${hour}:00`);
      timeSlots.push(`${hour}:30`);
    }
    return timeSlots;
  };


  const getCurrentTimeRow = (minHour) => {
    const nowMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();
    const startOfDayMinutes = minHour * 60;
    return Math.floor((nowMinutes - startOfDayMinutes) / 15) + 1;  // Assuming each row represents 15 minutes
  };







  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };


  const timelineEvents = [
    {
      "date": "Thursday 18/04/2024",
      "events": [
        {
          "date": "2024-04-18",
          "startTime": "17:00",
          "endTime": "20:30",
          "title": "Freedom Fights 2024",
          "description": "A small conference about free market in Warsaw School of Economics 💬📈",
          "type": "satellite event",
          "registration_link": "https://freemarket-rs.com/event/2024-04-18-warsaw",
          "location_name": "Warsaw School of Economics",
          "location_link": "https://maps.app.goo.gl/nHEAm5kUHD4JKe5RA",
          "icon": "🗣️",
          "track": 5,
          "color": "bg-orange-200"
        },
        {
          "date": "2024-04-18",
          "startTime": "18:00",
          "endTime": "21:30",
          "title": "Konferencja 4. Halving Bitcoina",
          "description": "Polish networking event on the halving, with panels, talks and artist performances 🎤👥",
          "type": "satellite event",
          "registration_link": "https://lu.ma/7bqix28k",
          "location_name": "Browary Warszawskie",
          "location_link": "https://maps.app.goo.gl/UzWV71HxjoNKdvAG7",
          "icon": "🤝",
          "track": 4,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-18",
          "startTime": "18:00",
          "endTime": "22:00",
          "title": "Halving Games",
          "description": "Bowling, darts and billiard tournaments with Pizza & beers 🍕🍺",
          "type": "main_event",
          "registration_link": null,
          "location_name": "7 club",
          "location_link": "https://maps.app.goo.gl/wvC9a8Ukuhhyi4CcA",
          "icon": "🎳",
          "track": 1,
          "color": "bg-purple-200",
        },
        {
          "date": "2024-04-18",
          "startTime": "18:00",
          "endTime": "20:00",
          "title": "Albeers",
          "description": "Meet Alby’s team and friends, or maybe you don’t like bowling. Next door! 🚪🤗",
          "type": null,
          "registration_link": null,
          "location_name": "7 club",
          "location_link": "https://maps.app.goo.gl/wvC9a8Ukuhhyi4CcA",
          "icon": "🍻",
          "track": 3,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-18",
          "startTime": "19:00",
          "endTime": "23:30",
          "title": "Bitcoin Slam & Jam",
          "description": "Jam and slam session in the most bitcoin-friendly bar in Poland 🎶🍻",
          "type": null,
          "registration_link": null,
          "performances": [
            "Roger9000",
            "Mad Munky",
            "...you"
          ],
          "location_name": "BarBazaar",
          "location_link": "https://maps.app.goo.gl/gVrm68S4RP6Uus4D7",
          "icon": "🎸",
          "track": 2,
          "color": "bg-purple-200",
        }
      ]
    },
    {
      "date": "Friday 19/04/2024",
      "events": [
        {
          "date": "2024-04-19",
          "startTime": "09:00",
          "endTime": "11:30",
          "title": "Amondo morning",
          "description": "Cinema meetup for kids & youth. Filmmakers’ workshops for anyone interested 🧒👦",
          "registration_link": "https://docs.google.com/forms/d/1FPsE6cUvpEnwTyi8Jef0nGIQoaLyfkdbGJ7bv54WPds",
          "location_name": "Amondo",
          "location_link": "https://maps.app.goo.gl/NsmsSVpvu1S7pquGA",
          "icon": "🎥",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "10:00",
          "endTime": "12:00",
          "title": "Shooting range",
          "description": "Supervised training on a shooting range 🎯👀",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSew2cE38apkzTvj_nvcuI3jVRQ0X2WsrfBG4ANfZ4U5RWBRlQ/viewform?usp=sf_link",
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🔫",
          "track": 2,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "12:00",
          "endTime": "18:00",
          "title": "Community stage",
          "description": "Parallel stage with workshops, panels, presentations, screenings by European Plebs… including you 🌍🗣️",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSe4Chgj-HY_H6d5fz8Ol8C6DmbGKiumopAMgZQKqqj_U7HQCw/viewform?usp=sf_link",
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎤",
          "track": 4,
          "color": "bg-indigo-300",
        },
        {
          "date": "2024-04-19",
          "startTime": "12:00",
          "endTime": "14:30",
          "title": "🎞️ MENGER. NOTES ON THE MARGIN",
          "description": "PREMIERE! Biography of Poland-born Carl Menger, great-grandfather of Bitcoin + panel discussion 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎬",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "12:00",
          "endTime": "14:30",
          "title": "Bicycle Day",
          "description": "Mind-expanding session with meditation, on the 81st anniversary of The Bicycle Day 🧘‍♂️✨",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSeQ6XC4qh6B5apfI8YGjcLKHqAgBjNLq7QIbY5oPF-KkWqWow/viewform?usp=sf_link",
          "location_name": "BarBazaar",
          "location_link": "https://maps.app.goo.gl/gVrm68S4RP6Uus4D7",
          "icon": "🚲",
          "track": 2,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "12:00",
          "endTime": "14:30",
          "title": "Polish lunch",
          "description": "Taste legacy Poland at local restaurant 🥘🇵🇱",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLScjxXuNtTg34l-xjMx0KHQPWeT9OHV2tlAhYfg6siEJNCV9RQ/viewform?usp=sf_link",
          "location_name": "Prodiż",
          "location_link": "https://www.google.com/maps/place/Prodi%C5%BC+Warszawski/@52.225222,21.0094039,17z/data=!3m1!4b1!4m6!3m5!1s0x471eccede4556c9b:0x5c7b909361b84865!8m2!3d52.2252187!4d21.0119788!16s%2Fg%2F11c1x2_shb?entry=ttu",
          "icon": "🍲",
          "track": 3,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "15:30",
          "endTime": "18:00",
          "title": "🎞️ SEARCHING FOR SATOSHI",
          "description": "Mass-audience ready documentary investigating into the identity of Satoshi Nakamoto + What Bitcoin Did Films presentation & panel on movie production 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎬",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "15:30",
          "endTime": "18:00",
          "title": "Vistula river journey",
          "description": "Let local bitcoiners take you on a boat 🌊🏞️",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSfOX_yO3dJznwlonbwWOEmBkb0gjhqVY8MEBncZtHZS7iUx6Q/viewform?usp=sf_link",
          "location_name": "Rejsowisko",
          "location_link": "https://maps.app.goo.gl/o3NHKWa7yXRc9Qit7",
          "icon": "🛥️",
          "track": 2,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "15:30",
          "endTime": "18:00",
          "title": "Sats4Flats",
          "description": "Real estate meetup, to exchange knowledge and opportunities 📊🔑",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSdiiUYznVRTU0bbkpRQorBhBbM-FiSdGiHkyvJ-79LyAvp85Q/viewform",
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🏠",
          "track": 3,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "18:30",
          "endTime": "21:00",
          "title": "🎞️ MY TRUST IN YOU IS BROKEN",
          "description": "PREMIERE! The story of  rebelion, the story of decentralization,the story of BTCPay Server + open stage to present your upcoming film or the idea for one  – apply! 🎥👥 And join us to see the pitches!",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎬",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "21:00",
          "endTime": "23:30",
          "title": "Cinematic cocktails",
          "description": "DIRTY COIN Cinema Cocktail party for all. Drinking and waiting for the last film with frens and a DJ 🍹🎶",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🍸",
          "track": 1,
          "color": "bg-purple-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "22:00",
          "endTime": "23:00",
          "title": "A bit of salsa",
          "description": "Step outside and learn some steps, sway to the beats and dance 🎵👣",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "💃",
          "track": 2,
          "color": "bg-purple-200",
        },
        {
          "date": "2024-04-19",
          "startTime": "23:45",
          "endTime": "23:50",
          "title": "🎞️ GODS OF THEIR OWN RELIGION",
          "description": "Midnight screening of surrealistic orwellian midnight movie 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎬",
          "track": 1,
          "color": "bg-blue-200",
        },
      ]
    },
    {
      "date": "Saturday 4/20/2024",
      "events": [
        {
          "date": "2024-04-20",
          "startTime": "09:00",
          "endTime": "11:30",
          "title": "Amondo morning",
          "description": "Junior Cinema and Filmmakers’ workshops 🧒👦",
          "registration_link": "https://docs.google.com/forms/d/1FPsE6cUvpEnwTyi8Jef0nGIQoaLyfkdbGJ7bv54WPds",
          "location_name": "Amondo",
          "location_link": "https://docs.google.com/forms/d/1FPsE6cUvpEnwTyi8Jef0nGIQoaLyfkdbGJ7bv54WPds",
          "icon": "🎥",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "10:00",
          "endTime": "11:45",
          "title": "Entrepreneurs breakfast",
          "description": "Morning networking for businessmen, regardless the industry, organised by Polish Business Congress ☕👔",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLScxR-V1BgUL_cSYp-l_FXM92NlJ3x-wi3MAcTvrtoh3zmBcig/viewform?usp=sf_link",
          "location_name": "Aioli",
          "location_link": "https://maps.app.goo.gl/p96mzLwXWS9zYC8s9",
          "icon": "🍳",
          "track": 2,
          "color": "bg-orange-200",
        },
        // {
        //   "date": "2024-04-20",
        //   "startTime": "12:00",
        //   "endTime": "14:15",
        //   "title": "Community stage",
        //   "description": "Naeem Mahmood, Vlad Costea - Parallel stage with workshops, panels, presentations, screenings by European Plebs. 🌍🗣️",
        //   "registration_link": null,
        //   "location_name": "Kinoteka - Room 8",
        //   "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
        //   "icon": "🎤",
        //   "track": 4,
        //   "color": "bg-indigo-300",
        // },
        // {
        //   "date": "2024-04-20",
        //   "startTime": "14:45",
        //   "endTime": "16:05",
        //   "title": "Community stage",
        //   "description": "Lawrence Rothbardan, Bitrefill, Stories of La Bitcoineta, Alby: Value4Value - Parallel stage with workshops, panels, presentations, screenings by European Plebs. 🌍🗣️",
        //   "registration_link": null,
        //   "location_name": "Kinoteka - Room 8",
        //   "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
        //   "icon": "🎤",
        //   "track": 4,
        //   "color": "bg-indigo-300",
        // },
        // {
        //   "date": "2024-04-20",
        //   "startTime": "16:30",
        //   "endTime": "17:50",
        //   "title": "Community stage",
        //   "description": "Wojtek: Cryptosteel, Yoav, Panel European Bitcoiners - Parallel stage with workshops, panels, presentations, screenings by European Plebs. 🌍🗣️",
        //   "registration_link": null,
        //   "location_name": "Kinoteka - Room 8",
        //   "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
        //   "icon": "🎤",
        //   "track": 4,
        //   "color": "bg-indigo-300",
        // },
        {
          "date": "2024-04-20",
          "startTime": "14:00",
          "endTime": "18:00",
          "title": "Community stage",
          "description": "Naeem Mahmood, Vlad Costea, Lawrence Rothbardan, Bitrefill, Stories of La Bitcoineta, Alby: Value4Value, Wojtek: Cryptosteel, Yoav, Panel European Bitcoiners - Parallel stage with workshops, panels, presentations, screenings by European Plebs. 🌍🗣️",
          "registration_link": null,
          "location_name": "Kinoteka - Room 8",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎤",
          "track": 4,
          "color": "bg-indigo-300",
        },
        {
          "date": "2024-04-20",
          "startTime": "12:30",
          "endTime": "14:30",
          "title": "🎞️ shortfest 🎞️ BITCOINERS",
          "description": "HODL, LEKKER FEELING, PERU BY JOE NAKAMOTO, discussion panel, BITCOINERS 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka - Room 3",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎥",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "12:00",
          "endTime": "14:30",
          "title": "Retro bus tour",
          "description": "Guided tour in a legendary bus to the shadiest districts of Warsaw  🌆👀",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSdWV5xAqgNOdKYMT_dhGE9k-wUQvCPmixyzUTTvPM0g4Qmxbw/viewform?usp=sf_link",
          "location_name": "Meeting: Kinoteka Stairs",
          "location_link": "https://maps.app.goo.gl/WCKwbMV6CmtStjSy6",
          "icon": "🚌",
          "track": 2,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "11:45",
          "endTime": "14:30",
          "title": "Chocolate workshops",
          "description": "Bitcoin and Cacao balance the human condition. Pour yourself into creative chocoprocess 🌱👩‍🍳",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSdWV5xAqgNOdKYMT_dhGE9k-wUQvCPmixyzUTTvPM0g4Qmxbw/viewform?usp=sf_link",
          "location_name": "Amondo",
          "location_link": "https://www.google.pl/maps/place/Amondo/@52.2286431,21.013496,17z/data=!3m1!4b1!4m6!3m5!1s0x471eccf17e946e65:0x887a27ee56074756!8m2!3d52.2286398!4d21.0160709!16s%2Fg%2F11c551z3pd?entry=ttu",
          "icon": "🍫",
          "track": 3,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "15:30",
          "endTime": "18:00",
          "title": "🎞️ DEATH ATHLETIC",
          "description": "A chronicle of the techno-political battle for online code sharing freedom. 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka - Room 3",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎥",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "15:30",
          "endTime": "18:00",
          "title": "Laser tag",
          "description": "Digital paintball for bitcoiners of any age 🎯👾",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSc16Loh4c9aP-pLbyEkHCfJnAmxEQW3sRbKLl-tprX30wGcxg/viewform?usp=sf_link",
          "location_name": "Meeting: Kinoteka Stairs",
          "person_responsible": "tg: @patrykfelcman",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🔫",
          "track": 2,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "15:30",
          "endTime": "18:00",
          "title": "Palace terrace",
          "description": "Guided visit to the top of our venue, the Palace of Culture and Science 🌇🔭",
          "registration_link": "https://docs.google.com/forms/d/e/1FAIpQLSdaI9AbKflCJKD5GAIpXvIKG0OgjihlOhjX6Y76PMAVgdbr0w/viewform?usp=sf_link",
          "location_name": "Meeting: Kinoteka Stairs",
          "person_responsible": "tg: @BTCyourmind",
          "location_link": "https://maps.app.goo.gl/ErPZY2pJpENhnkE68",
          "icon": "🏰",
          "track": 3,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "16:20",
          "endTime": "17:00",
          "title": "Tea time",
          "description": "Relax and unwind with a soothing cup of tea 🌿🕰️",
          "registration_link": null,
          "location_name": "Samo Centrum",
          "location_link": "https://maps.app.goo.gl/WCKwbMV6CmtStjSy6",
          "icon": "🍵",
          "track": 5,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "18:30",
          "endTime": "21:00",
          "title": "🎞️ DIRTY COIN",
          "description": "PREMIERE! Ultimate demistification of the ultimate controversy 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka - Room 4",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎥",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-20",
          "startTime": "22:30",
          "endTime": "23:50",
          "title": "European Halving Party",
          "description": "Let’s celebrate! DJ Rootzoll, Damian Widmo, Sasha S, lightshows 🎊💃",
          "registration_link": null,
          "location_name": "Cafe Kulturalna",
          "location_link": "https://maps.app.goo.gl/ErPZY2pJpENhnkE68",
          "icon": "🎉",
          "track": 1,
          "color": "bg-purple-200",
        },
      ]
    },
    {
      "date": "Sunday 21/04/2024",
      "events": [
        {
          "date": "2024-04-21",
          "startTime": "10:00",
          "endTime": "11:30",
          "title": "Yoga & self-sovereignty",
          "description": "Begin your day in the Bitcoin spirit with yoga and a self-sovereignty session 🕉️✨",
          "registration_link": "https://docs.google.com/forms/d/1Peab99KSavE-sHxIMYyaCVWGcKC2SU1muo8rwuWHBQw/edit",
          "location_name": "Saski Garden",
          "location_link": "https://www.google.com/maps/place/52%C2%B014'27.2%22N+21%C2%B000'26.0%22E/@52.2408808,21.0065663,19z/data=!3m1!4b1!4m4!3m3!8m2!3d52.24088!4d21.00721?entry=ttu",
          "icon": "🧘",
          "track": 1,
          "color": "bg-orange-200",
        },
        {
          "date": "2024-04-21",
          "startTime": "11:00",
          "endTime": "13:00",
          "title": "🎞️ Cartoonfest",
          "description": "PREMIERE! ADVENTURES OF JONATHAN GULLIBLE, WHO IS BITCOIN, MAXISCLUBSHOW, BITCOIN & FRIENDS (lost episodes), kid-friendly, kid-moderated 🎥👥",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎥",
          "track": 2,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-21",
          "startTime": "12:00",
          "endTime": "14:30",
          "title": "Chill Sunday",
          "description": "Brunch & networking in festival’s lounge, Kulturalna Cafe ☕🫖",
          "registration_link": null,
          "location_name": "Cafe Kulturalna",
          "location_link": "https://maps.app.goo.gl/ErPZY2pJpENhnkE68",
          "icon": "🍽️",
          "track": 3,
          "color": "bg-purple-200",
        },
        {
          "date": "2024-04-21",
          "startTime": "14:30",
          "endTime": "16:30",
          "title": "🎞️ AFTER IT’S SAID",
          "description": "PREMIERE! experimental exploration of internet and bitcoin as languages. Golden Rabit Awards, Halving Games Finals, closing ceremony 🏆📽️",
          "registration_link": null,
          "location_name": "Kinoteka",
          "location_link": "https://maps.app.goo.gl/1UVJFZ3HzdXyavCJ9",
          "icon": "🎥",
          "track": 1,
          "color": "bg-blue-200",
        },
        {
          "date": "2024-04-21",
          "startTime": "17:00",
          "endTime": "20:00",
          "title": "After After Party",
          "description": "grab some drinks with award-winning filmmakers, enjoy the new era of Bitcoin & warm goodbyes",
          "registration_link": null,
          "location_name": "Amondo",
          "location_link": "https://docs.google.com/forms/d/1FPsE6cUvpEnwTyi8Jef0nGIQoaLyfkdbGJ7bv54WPds",
          "icon": "🎉",
          "track": 1,
          "color": "bg-purple-200",
        },
      ]
    }
  ];


  // useEffect(() => {
  //   // Scroll into view when the currentTimeRef is updated
  //   if (currentTimeRef.current) {
  //     currentTimeRef.current.scrollIntoView({
  //       behavior: 'smooth',
  //       block: 'center'
  //     });
  //   }
  // }, [currentTimeRef, currentTime]);


  const timelineEventsWithGridInfo = timelineEvents.map(day => ({
    ...day,
    events: preprocessEvents(day.events)
  }));


  const minHour = Math.min(...timelineEvents.map(day => getBaseHour(day.date)));  // Adjust this logic based on your exact needs
  const currentTimeRow = getCurrentTimeRow(minHour);


  return (
    <div className="min-h-screen bg-gray-900">
      <Helmet>
        <title>Bitcoin FilmFest - Agenda</title>
        <meta name="description" content="This is the Agenda of our event." />
        <meta property="og:title" content="Bitcoin FilmFest - Agenda" />
        <meta property="og:description" content="This is the Agenda of our event." />
      </Helmet>
      <div className="w-4/5 mx-auto px-4 sm:px-6 lg:px-8">
        <img className='mb-6 w-full max-w-md mx-auto' src='https://bitcoinfilmfest.com/wp-content/uploads/2023/09/European-Halving-party-cropped.png' alt="European Halving Party"></img>
        <h2 className="text-3xl sm:text-4xl font-semibold text-gray-300 mb-2 text-center">Agenda</h2>
        <div className='flex justify-center text-gray-200'>
          <ul className="text-lg w-full items-center menu menu-horizontal bg-gray-800 rounded-box mb-6 justify-center">
            <li>
              <a onClick={() => handleNavigation('/bitcoin-filmfest')}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
                <p className=''>Dashboard</p>
              </a>
            </li>
            <li className='ml-4'>
              <a onClick={() => handleNavigation('/bitcoin-filmfest-agenda')}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-10 w-10">
                  <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
                </svg>

                <p className=''>Agenda</p>
              </a>
            </li>
            <li className='ml-4'>
              <a href="https://bitcoinfilmfest.notion.site/BFF24-x-European-Halving-Party-All-you-need-to-know-2fa8605078514f78a87041fd5542ac98" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className=''>Event Info</p>
              </a>
            </li>
            <li className='ml-4'>
              <a href="https://t.me/+u3gdL52sGq4wMzA0" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>
                <p className=''>Event Telegram</p>
              </a>
            </li>
            <li className='ml-4'>
              <a href="https://europeanhalvingparty.com" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                </svg>

                <p className=''>BFF Website</p>
              </a>
            </li>
          </ul>
        </div>

        {/* Scrollable agenda grid */}
        <div className="bg-gray-800 ring-1 ring-gray-700 shadow overflow-hidden sm:rounded-lg mb-6">
          <div className="overflow-x-auto">
            {timelineEventsWithGridInfo.map((day, index) => {
              const timeSlots = generateTimeSlots(day.events);
              return (
                <div key={index} className="mb-8">
                  <h3 className="text-xl font-semibold text-white mb-4 ml-4 mt-4">{day.date}</h3>
                  <div className="grid grid-cols-[80px_1fr_1fr_1fr_1fr] gap-4 min-w-max">
                    <div className="col-span-1">
                      {timeSlots.map((time, i) => (
                        <div key={i} className="text-white p-2 border-b ml-4 border-gray-700 h-20"
                            ref={i === currentTimeRow ? currentTimeRef : null}>  {/* Assign ref to the current time slot */}
                          {time}
                        </div>
                      ))}
                    </div>
                    <div className="col-span-4 grid grid-cols-4 gap-y-10 gap-x-2">
                      {day.events.map((event, idx) => (
                        <div
                          key={idx}
                          className={`${event.isPast ? 'bg-gray-300' : event.color} p-2 rounded-lg`}
                          style={{
                            gridColumnStart: event.gridColumnStart,
                            gridRowStart: event.gridRowStart,
                            gridRowEnd: event.gridRowEnd,
                            maxWidth: '200px'
                          }}
                        >
                          <div>{event.startTime} - {event.endTime}</div>
                          <div className="font-bold">{event.title}</div>
                          <div>{event.description}</div>
                          {event.person_responsible && (
                            <>
                            <div className='mt-2 font-bold'>Contact Person:</div>
                            <div>{event.person_responsible}</div>
                            </>
                          )}
                          {event.location_link && (
                            <button
                              onClick={() => window.open(event.location_link, "_blank")}
                              className="mt-2 w-full text-sm bg-blue-400 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                            >
                              📌 {event.location_name}
                            </button>
                          )}
                          {event.registration_link && (
                            <button
                              onClick={() => window.open(event.registration_link, "_blank")}
                              className="mt-1  w-full text-sm bg-green-400 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
                            >
                              Register
                            </button>
                          )}
                        </div>
                      ))}

                    </div>

                  </div>
                </div>
              );
            })}
          </div>
        </div>

      </div>

      {/* Footer area with the Flash branding */}
      <div className="text-center mt-10">
        <h2 className="text-xs font-semibold text-gray-300">Powered by <a href='https://paywithflash.com' className="underline">Flash</a></h2>
        <img className='mx-auto mt-1' src='https://flash-www-uc9va.ondigitalocean.app/images/logo-25.png' alt="Flash Logo" style={{ height: '24px', width: '80px' }}></img>
        <p className="text-xs font-semibold text-gray-300">The Bitcoin payment gateway</p>
      </div>
    </div>
  );
};

export default BitcoinFilmFestAgendaPage;
