import React, { useEffect, useState } from 'react';

const Memberships = ({ memberships, onSelectedMembershipChange }) => {
    // State to track the selected membership ID
    const [selectedMembershipId, setSelectedMembershipId] = useState(null);

    // Sort memberships by PriceInSatoshis
    const sortedMemberships = memberships.sort((a, b) => a.PriceInSatoshis - b.PriceInSatoshis);

    // Set the initially selected membership and inform the parent component
    useEffect(() => {
        if (sortedMemberships.length > 0 && selectedMembershipId === null) {
            const firstMembership = sortedMemberships[0];
            setSelectedMembershipId(firstMembership.id);
            onSelectedMembershipChange(firstMembership);
        }
    }, [sortedMemberships, onSelectedMembershipChange, selectedMembershipId]);

    // Handle membership selection
    const handleSelection = (membership) => {
        setSelectedMembershipId(membership.id);
        onSelectedMembershipChange(membership);
    };
    
    return (
        <div className="flex flex-col items-center justify-center space-y-4 p-4 gap-2">
            {memberships.map((membership, index) => (
                <label key={membership.id} className="cursor-pointer block w-full max-w-md mx-auto">
                    <input
                        type="radio"
                        className="peer sr-only"
                        name="pricing"
                        checked={selectedMembershipId === membership.id}
                        onChange={() => handleSelection(membership)}
                    />
                    <div className="rounded-md border px-5 py-3 text-gray-500 ring-2 ring-transparent transition-all shadow peer-checked:text-gray-800 peer-checked:ring-gray-800 peer-checked:ring-offset-2">
                        <div className="flex flex-col gap-1">
                            <div className="flex items-center justify-between">
                                <p className="text-sm font-semibold uppercase text-gray-500">{membership.name}</p>
                                <svg className="" width="24" height="24" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z" />
                                </svg>
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500">{membership.description}</p>
                            <div className="flex items-end justify-between">
                                <p><span className="text-lg font-bold">${membership.PriceInDollars}</span> / {membership.renewal_intervals.slice(0, -2)}</p>
                                <p className="text-sm font-bold" style={{ fontVariant: 'small-caps' }}>{membership.PriceInSatoshis} sats / {membership.renewal_intervals.slice(0, -2)}</p>
                            </div>
                        </div>
                    </div>
                </label>
            ))}
        </div>
    );
};

export default Memberships;
