import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CustomersList = ({ customers, isLoading, error, onCustomerSelect }) => {

    // Helper function to determine the className based on product status
    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ');
    };

    if (isLoading) return <div className="text-center mt-5">Loading...</div>;
    if (error) return <div className="text-red-500 text-center mt-5">Error: {error}</div>;

    return (
        <div className="mt-6 rounded-lg overflow-hidden ring-1 ring-indigo-200 shadow-sm">
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-white">
                        <tr>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Customer name</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Email</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Nostr Public Key</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Orders</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Amount Spent (USD)</th>
                            <th scope="col" className="whitespace-nowrap px-3 py-3 bg-gray-100 text-left text-xs font-semibold text-gray-700">Amount Spent (sats)</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-indigo-100 bg-white">
                        {customers.map((customer, index) => (
                            <tr key={index} className="hover:bg-indigo-50 cursor-pointer" onClick={() => onCustomerSelect(customer)}>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{customer.FirstName} {customer.LastName}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{customer.Email}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">
                                    <div className="overflow-x-auto max-w-xs">
                                        <span className="inline-block whitespace-nowrap">{customer.UserPublicKey}</span>
                                    </div>
                                </td>

                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">{customer.TotalOrders}</td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(customer.TotalCostUSD)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-2 text-sm text-indigo-900">
                                    {new Intl.NumberFormat('en-US').format(customer.TotalCostSats)}
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CustomersList;
