import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { Link as ScrollLink } from 'react-scroll';

import { Helmet } from 'react-helmet';


import { FaUser, FaStar, FaUsers, FaHandsHelping } from 'react-icons/fa';


import BitcoinFilmFestDetailCard from '../components/BitcoinFilmFest/BitcoinFilmFestDetailCard.js'
import BitcoinFilmFestUserTable from '../components/BitcoinFilmFest/BitcoinFilmFestUserTable.js'
import BitcoinFilmFestGameInfoUser from '../components/BitcoinFilmFest/BitcoinFilmFestGameInfoUser.js'




const DetailPage = () => {
  const navigate = useNavigate(); // Use the useNavigate hook
  const [sheetData, setSheetData] = useState([]);
  const { player } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/bitcoin_filmfest_data');
      const data = await response.json();
      setSheetData(data);
    };

    fetchData();
  }, [player]);

  // Function to handle navigation
  const handleNavigation = (path) => {
    navigate(path);
  };


  const getTicketTypeDetails = (ticketType) => {
    switch (ticketType) {
      case 'Pleb': return { color: 'bg-blue-500', icon: <FaUser /> };
      case 'SuperPleb': return { color: 'bg-green-500', icon: <FaStar /> };
      case 'Team': return { color: 'bg-red-500', icon: <FaUsers /> };
      case 'Volunteer': return { color: 'bg-yellow-500', icon: <FaHandsHelping /> };
      default: return { color: 'bg-gray-500', icon: <FaUser /> };
    }
  };


  if (player) {
    const { color, icon } = getTicketTypeDetails(player.ticketType);
  }

  if (!player) {
    return <div>No player data available.</div>;
  }

  return (
    <div className="min-h-screen py-10 bg-gray-900">
      <Helmet>
        <title>Bitcoin FilmFest - User Page</title>
        <meta name="description" content="This is your personal page for our event." />
        <meta property="og:title" content="Bitcoin FilmFest - User Page" />
        <meta property="og:description" content="This is your personal page for our event." />
      </Helmet>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-semibold text-gray-300 mb-6 text-center">Player Details</h2>

        <div className='flex justify-center text-gray-200'>
          <ul className="text-lg w-full items-center menu menu-horizontal bg-gray-800 rounded-box mb-6 justify-center">
            <li>
              <a onClick={() => handleNavigation('/bitcoin-filmfest')}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
                <p className=''>Dashboard</p>
              </a>
            </li>
            <li className='ml-4'>
              <a onClick={() => handleNavigation('/bitcoin-filmfest-agenda')}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-10 w-10">
                  <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
                </svg>

                <p className=''>Agenda</p>
              </a>
            </li>
            <li className='ml-4'>
              <a href="https://bitcoinfilmfest.notion.site/BFF24-x-European-Halving-Party-All-you-need-to-know-2fa8605078514f78a87041fd5542ac98" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <p className=''>Event Info</p>
              </a>
            </li>
            <li className='ml-4'>
              <ScrollLink to="rules" smooth={true} duration={500} className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                </svg>

                <p className=''>Rules</p>
              </ScrollLink>
            </li>
            <li className='ml-4'>
              <ScrollLink to="points" smooth={true} duration={500} className="cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                </svg>

                <p className=''>Points</p>
              </ScrollLink>
            </li>
            <li className='ml-4'>
              <a href="https://t.me/+u3gdL52sGq4wMzA0" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>
                <p className=''>Event Telegram</p>
              </a>
            </li>
            <li className='ml-4'>
              <a href="https://europeanhalvingparty.com" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-10 w-10">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                </svg>

                <p className=''>BFF Website</p>
              </a>
            </li>
          </ul>
        </div>

        {/* <div className='flex justify-center'>
          <div className="menu menu-horizontal text-gray-300 rounded-box mb-6">
            <div className={` max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col p-3 rounded-lg shadow-lg ${color} hover:shadow-xl transition-shadow duration-300 ease-in-out`}>
              <div className="stat-title text-sm text-gray-200">Ticket</div>
              <div className="flex items-center text-white text-sm px-3 py-2 rounded">
                {icon}
                <span className="ml-2 font-medium">{player.ticketType}</span>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-gray-800 ring-1 ring-gray-700 shadow overflow-hidden sm:rounded-lg mb-6">
          <BitcoinFilmFestDetailCard sheetData={sheetData} player={player} />
        </div>
        <div className="bg-gray-800 ring-1 ring-gray-700 shadow overflow-hidden sm:rounded-lg mb-6">
          <BitcoinFilmFestGameInfoUser sheetData={sheetData} player={player} />
        </div>
        <div className="bg-gray-800 ring-1 ring-gray-700 shadow overflow-hidden sm:rounded-lg mb-6">
          <BitcoinFilmFestUserTable sheetData={sheetData} player={player} />
        </div>
      </div>

      {/* Footer area with the Flash branding */}
      <div className="text-center mt-10">
        <h2 className="text-xs font-semibold text-gray-300">Powered by <a href='https://paywithflash.com' className="underline">Flash</a></h2>
        <img className='mx-auto mt-1' src='https://flash-www-uc9va.ondigitalocean.app/images/logo-25.png' alt="Flash Logo" style={{ height: '24px', width: '80px' }}></img>
        <p className="text-xs font-semibold text-gray-300">The Bitcoin payment gateway</p>
      </div>
    </div>
  );
};

export default DetailPage;
