import React, { useState, Fragment } from 'react';
import { ArrowLongLeftIcon, PlusIcon } from '@heroicons/react/24/outline';
import { cardConfigs } from '../components/FlashCards/CardsList'; // Import the configuration
import { BsPlay } from 'react-icons/bs';



import { Dialog, Transition } from '@headlessui/react';
import Form1 from '../components/FlashForms/forms/0-ExampleForm';
import ConnectAPIForm from '../components/FlashForms/forms/ConnectAPIForm';

import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import CreateEgg from '../components/CreateEgg';
import GetPublicEggs from '../components/GetPublicEggs';
import Example from '../components/cards'; // make sure the path is correct
import PopExplain from '../components/PopExplain'; // Import the new component


import { CardsPage } from '../components/FlashCards/CardsTemplate';
import { CardsIntegrationsPage } from '../components/FlashCards/IntegrationsTemplate';




function Subscriptions() {
  const [activeSection, setActiveSection] = useState('view'); // Default to 'view'

  const [showPopExplain, setShowPopExplain] = useState(true); // State to control visibility

  const handleUploadCancel = (newStatus) => {
    setActiveSection(newStatus)
  }

  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const openModal = (actionId) => {
    setSelectedAction(actionId);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  const getFormComponent = () => {
    switch (selectedAction) {
      case 'createFlashAPI':
        return <Form1 close={closeModal} />;
      case 'connectAPI':
        return <ConnectAPIForm close={closeModal} />;
      // Add other cases as needed...
      default:
        return null;
    }
  };

  return (

 <div className="min-h-screen">
      <SidebarLayout>
        <Header 
          title="Create a Subscription plan" 
          subtitle="The first way to implement recurring payments in Bitcoin."
          showWalletsAvatar={false}
          badgeText=""// Set this to true or false as needed

          // ... other header props ...
        />




  
            <div className="container mx-auto">
    

        </div>
 
      <div className='p-8'>
        <CardsPage
          actions={cardConfigs.flash4Actions}
          title="Handle subscriptions on your platform"
          />
        {/* <CardsPage
          actions={cardConfigs.flash5Actions}
          title="Add paywalls for members"
        /> */}
      </div>

      <hr />
      <div className='p-8'>
        <CardsIntegrationsPage
          actions={cardConfigs.flash6Actions}
          title="Integrations"
        />
      </div>
        
      </SidebarLayout>
    </div>
  );
}

export default Subscriptions;
