import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const BitcoinFilmFestTeamPointsBarChart = ({ sheetData, onTeamSelect }) => {
    const [series, setSeries] = useState([{ name: "Points", data: [] }]);
    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    const teamNameWithMedal = config.w.config.xaxis.categories[config.dataPointIndex];
                    // Use regex to remove medals and trim any leading/trailing whitespace
                    const teamName = teamNameWithMedal.replace(/🥇|🥈|🥉/g, '').trim();
                    onTeamSelect(teamName);
                },
            },
            height: 550,
            toolbar: {
                show: false
            },
            offsetX: 50,
            offsetY: 0,
            parentHeightOffset: 0,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true, // Different colors for each bar
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '16px',
                colors: ['#fff'], // Data labels color
            },
        },
        xaxis: {
            categories: [],
            labels: {
                show: false,
                style: {
                    colors: ['#fff'], // X-axis labels color
                    fontSize: '14px', // Adjust as needed
                    cssClass: 'apexcharts-yaxis-label',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: '#fff', // Ensure this is a string for uniform color application
                    fontSize: '13px', // Adjust the font size as necessary
                },
                // offsetX: 20,
            },
        },
        grid: {
            show: false,
            padding: {
                left: 40, // Adjust this value to ensure labels fit without being cut off
                right: 0,
            },
        },
        tooltip: {
            enabled: false,
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'],
        legend: {
            show: false, // Remove the legend
        },
    });

    useEffect(() => {
        const pointsByTeam = sheetData.reduce((acc, { team, total_points }) => {
            const parsedPoints = parseInt(total_points, 10);
            // Only accumulate points if they are a valid number and greater than 0
            if (!isNaN(parsedPoints) && parsedPoints > 0) {
                acc[team] = (acc[team] || 0) + parsedPoints;
            }
            return acc;
        }, {});
    
        // Convert to an array, sort by points in descending order, and then filter out teams with 0 points
        const sortedPointsByTeam = Object.entries(pointsByTeam)
          .sort((a, b) => b[1] - a[1]) // Sort teams by points in descending order
          .filter(([team, total_points]) => total_points > 0); // Exclude teams with no points, though this filter is technically redundant with the check in reduce
    
        // Generate categories, adding rankings and ensuring only valid data is included
        const categoriesWithRanking = sortedPointsByTeam.map(([team], index) => {
            let medal = '';
            if (index === 0) medal = ' 🥇';
            else if (index === 1) medal = ' 🥈';
            else if (index === 2) medal = ' 🥉';
        
            return `${team}${medal}`;
        });
        
    
        const dataSeries = sortedPointsByTeam.map(([team, total_points]) => total_points);
    
        setSeries([{ name: "Points", data: dataSeries }]);
        setOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { ...prevOptions.xaxis, categories: categoriesWithRanking },
        }));
    }, [sheetData]);
    
    
    

    return (
        <div className="relative">
            <h2 className='mt-6 text-5xl text-blue-300 ml-6 font-bold'>Team points</h2>
            <ReactApexChart options={options} series={series} type="bar" height={550} />
            <div className="text-center mt-4 mb-2">
                <p className="text-sm text-gray-300">Click on a team to filter users in table below</p>
                <button
                    onClick={() => onTeamSelect(null)}
                    className="mt-1 py-1 px-2 text-white border font-bold rounded">
                    Reset Filter
                </button>
            </div>
        </div>
    );
};

export default BitcoinFilmFestTeamPointsBarChart;
