import { nip04 } from "nostr-tools";

export const generateWidgetSnippet = async ( userPublicKey, userPrivateKey, flashId ) => {

  const ourPublicKey = "f981da5e5ca0a37af365877988b49f6011ac1d16f1defdb968e9ffc83316fe8c";

  // nip04 encrypt the content
  const jsonObjectString = JSON.stringify({ 
    flashId,
  });

  try {
    const encryptedProperties = await nip04.encrypt(userPrivateKey, ourPublicKey, jsonObjectString);
    
    const encodedData = window.btoa(JSON.stringify({
      userPublicKey,
      encryptedProperties
    }));

    const snippet = `<script src="https://app.paywithflash.com/external/paymentWidget.bundle.js" data-id="widgetScript" data-config="${encodedData}"></script>`;


    return snippet;
  } catch (error) {
    console.error("An error occurred during encryption:", error);
    return { error: "An error occurred during encryption" };
  }
};

export default generateWidgetSnippet;
