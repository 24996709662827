import React, { useState } from 'react';

import { BiLogoBitcoin, BiDollarCircle } from 'react-icons/bi';

const SubscriptionPlanRenewalDropdown = ({ label, id, name, value, plans, onChange }) => {
  const durationOptions = [
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Yearly', label: 'Yearly' },
    // Add other duration options if necessary
  ];

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id={id}
        name={name}
        autoComplete="off"
        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={value}
        onChange={onChange}
      >
        {durationOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};

const CurrencyDropdown = ({ label, id, name, value, onChange }) => {
  const currencyOptions = [
    { value: 'Satoshis', label: 'Satoshis', icon: <BiLogoBitcoin className="h-5 w-5" aria-hidden="true" /> },
    { value: 'Dollars', label: 'Dollars', icon: <BiDollarCircle className="h-5 w-5" aria-hidden="true" /> },
  ];

  const renderCurrencyIcon = () => {
    const selectedOption = currencyOptions.find(option => option.value === value);
    return selectedOption ? selectedOption.icon : null;
  };

  return (
    <div>
      <div className="flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-sm text-gray-500">
          {renderCurrencyIcon()}
        </span>
        <select
          id={id}
          name={name}
          autoComplete="off"
          className="block flex-1 rounded-none rounded-r-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          value={value}
          onChange={onChange}
        >
          {currencyOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SubscriptionPlans({ plans = [], onPlansChange }) {
  // const [plans, setPlans] = useState([]);
  const [newPlan, setNewPlan] = useState({ id: null, name: '', description: '', price: '', currency: 'Satoshis', renewal_intervals: 'Weekly', isEditable: true });
  const [showAddPlan, setShowAddPlan] = useState(false);

  const handleDelete = (planId) => {
    const updatedPlans = plans.filter(plan => plan.id !== planId);

    const deletePlan = async (planId) => {

      const formData = new FormData ();
      formData.append ('id', planId);

      const response = await fetch('https://api.paywithflash.com/api/delete_membership', {
        method : 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error ('Network response was not ok during update');
      }

      const result = response.json ();

      console.log ('Delete result:', result);
    }

    deletePlan (planId);
    
    onPlansChange(updatedPlans); // Update parent component 
  };

  const handleChange = (e, field) => {
    setNewPlan({ ...newPlan, [field]: e.target.value });
  };

  const handleDurationChange = (e) => {
    setNewPlan({ ...newPlan, renewal_intervals: e.target.value });
  };

  const handleCurrencyChange = (e) => {
    setNewPlan({ ...newPlan, currency: e.target.value });
  };

  const handleAddMore = () => {
    setShowAddPlan(true); // Show the input fields for adding a new plan
  };

  const handleSave = () => {
    const newPlanWithId = { ...newPlan, id: Date.now(), isEditable: false };
    const updatedPlans = [...plans, newPlanWithId];
    onPlansChange(updatedPlans); // Update parent component
    setNewPlan({ id: null, name: '', description: '', price: '', currency: 'Satoshis', renewal_intervals: 'Weekly', isEditable: true });
    setShowAddPlan(false); // Hide the input fields after saving
  };

  return (
    <>
    <div className="mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Plan
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Description
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Price
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Currency
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Renewal Period
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan, planIdx) => (
            <tr key={plan.id}>
              <td
                className={classNames(
                  'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                )}
              >
                <div className="font-medium text-gray-500">
                  {plan.name}
                </div>
              </td>
              <td
                className={classNames(
                  'relative py-4 pl-4 pr-3 text-sm sm:pl-6'
                )}
              >
                <div className="font-medium text-gray-500">
                  {plan.description}
                </div>
              </td>
              <td
                className={classNames(
                  'px-3 py-3.5 text-sm text-gray-500'
                )}
              >
                {plan.price}
              </td>
              <td
                className={classNames(
                  'px-3 py-3.5 text-sm text-gray-500'
                )}
              >
                {plan.currency}
              </td>
              <td
                className={classNames(
                  'px-3 py-3.5 text-sm text-gray-500'
                )}
              >
                {plan.renewal_intervals}
              </td>
              <td
                className={classNames(
                  'relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'
                )}
              >
                {!plan.isEditable && (
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => handleDelete(plan.id)}
                  >
                    Delete<span className="sr-only">, {plan.id}</span>
                  </button>
                )}
              </td>
            </tr>
          ))}
          {showAddPlan && (
            <tr>
              <td className="p-2">
                <input
                  type="text"
                  value={newPlan.name}
                  onChange={(e) => handleChange(e, 'name')}
                  className="w-full rounded-md border-gray-300 text-sm"
                  placeholder="Plan Name"
                />
              </td>
              <td className="p-2">
                <input
                  type="text"
                  value={newPlan.description}
                  onChange={(e) => handleChange(e, 'description')}
                  className="w-full rounded-md border-gray-300 text-sm"
                  placeholder="Description"
                />
              </td>
              <td className="p-2">
                <input
                  type="number"
                  value={newPlan.price}
                  onChange={(e) => handleChange(e, 'price')}
                  className="w-full rounded-md border-gray-300 text-sm"
                  placeholder="Price"
                />
              </td>
              <td className="p-2">
                <CurrencyDropdown
                  id="currency" 
                  name="currency" 
                  value={newPlan.currency} 
                  onChange={handleCurrencyChange}
                />
              </td>
              <td className="p-2">
                <SubscriptionPlanRenewalDropdown 
                  id="renewal_intervals" 
                  name="renewal_intervals" 
                  value={newPlan.renewal_intervals} 
                  onChange={handleDurationChange}
                />
              </td>
              <td className="p-2 text-right">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-blue-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
                  onClick={handleSave}
                >
                  Save
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    {!showAddPlan && ( // Only show the "Add More" button if the add plan row is not showing
      <div className="mt-4">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
          onClick={handleAddMore}
        >
          Add New Plan
        </button>
      </div>
    )}
    </>
  );
}
