import React, { useEffect, useState } from 'react';

function WalletsTotal({totalBalance, totalBalanceUSD}) {

    return (
        <div className="btn flex btn-primary hover:bg-slate-100 animate-none hover:border-slate-200 bg-white text-slate-800 py-1.5 border-slate-200 px-3  min-h-0 h-fit">
            <div className='flex'>
                <p className="text-sm font-medium  text-slate-800 ">{new Intl.NumberFormat().format(totalBalance)} sats</p>
                <p className='text-sm  ml-2 font-normal text-slate-500'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalBalanceUSD)}</p>

            </div>
        </div>
    );
       
}

export default WalletsTotal;
