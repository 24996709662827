import { nip04 } from "nostr-tools";

export const generatePaywallSnippet = async (
  price, 
  userPublicKey, 
  userPrivateKey, 
  memo, 
  nostrWalletConnectUrl,
  currency, 
  expiryDate, 
  flashId,
  lnAddress
  ) => {
    
  const ourPublicKey = "f981da5e5ca0a37af365877988b49f6011ac1d16f1defdb968e9ffc83316fe8c"

  // nip04 encrypt the content
  const jsonObjectString = JSON.stringify({ 
    price: price, 
    memo: memo, 
    currency: currency, 
    expiryDate: expiryDate, 
    flashId: flashId,
    lnAddress: lnAddress
   });

  try {
    const encryptedUserPrivatekey = await nip04.encrypt(userPrivateKey, ourPublicKey, userPrivateKey);
    const encryptedProperties = await nip04.encrypt(userPrivateKey, ourPublicKey, jsonObjectString);

    let encryptedUserNostrWalletConnectUrl;
    if (nostrWalletConnectUrl !== "") {
      encryptedUserNostrWalletConnectUrl = await nip04.encrypt(userPrivateKey, ourPublicKey, nostrWalletConnectUrl);
    } else {
      encryptedUserNostrWalletConnectUrl = "";
    }
    
    const encodedData = window.btoa(JSON.stringify({
      userPublicKey: userPublicKey,
      encryptedUserPrivatekey: encryptedUserPrivatekey,
      encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
      encryptedProperties: encryptedProperties
    }));

    const snippet = `<script src="https://app.paywithflash.com/external/paywall.bundle.js" data-id="paywallScript" data-config="${encodedData}"></script>`;

    return snippet;
  } catch (error) {
    console.error("An error occurred during encryption:", error);
    // Handle the error as needed
  }
};

export default generatePaywallSnippet;
