import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { nip04, nip19, SimplePool, finishEvent, getPublicKey, generatePrivateKey } from 'nostr-tools';

import { v4 as uuidv4 } from 'uuid';


const { decode } = nip19;

import ProductOrderFormManage from '../components/SubscriptionPage/ProductOrderFormManage';
import ManageSubscriptionUserSubscribedScreen from '../components/SubscriptionPage/ManageSubscriptionUserSubscribedScreen';


// Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
      method: 'POST', // if the API requires POST method
      headers: {
        'Content-Type': 'application/json',
      },
      // If the API requires a body payload, uncomment and edit the following line
      // body: JSON.stringify({ key: 'value' }), 
    });

    if (!response.ok) {
      // If the response is not OK, throw an error with the response status
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();

    // Assuming the API returns an object with a property that contains the Bitcoin price
    return data
  } catch (error) {
    console.error('API call failed:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

const convertPrices = async (currency, value) => {
  const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars

  if (currency === "Dollars") {
    const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
    return { priceInDollars: value, priceInSatoshis };
  } else if (currency === "Satoshis") {
    const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
    return { priceInDollars, priceInSatoshis: value };
  }
};

async function convertNostrToHex(npub) {
  const { type, data } = decode(npub);

  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid Nostr key. Expected an "npub" key.');
  }
};

// Function that calls API to get flash details and return the flash name
const getFlashById = async (flashId) => {
  const API_URL = 'https://api.paywithflash.com/api/get_flash_from_id';

  try {
    const response = await fetch(`${API_URL}?flash_id=${encodeURIComponent(flashId)}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    // Calculate and include both price representations
    const prices = await convertPrices(data.currency, Number(data.price));
    data.PriceInDollars = prices.priceInDollars;
    data.PriceInSatoshis = prices.priceInSatoshis;

    if (data.error) {
      console.error('Error fetching flash data:', data.error);
      return null; // or handle the error as needed
    }

    return data; // Return the flash
  } catch (error) {
    console.error('Error in getFlashNameById:', error);
    return null; // or handle the error as needed
  }
};

// Function that calls API to get membershipsfor the flash id
async function getMembershipsByFlashId(flashId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/api/get_memberships_by_flash_id?flash_id=${encodeURIComponent(flashId)}`);

    if (!response.ok) {
      // If the response status code is not in the 200-299 range, throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    let data = await response.json();

    // Assuming each membership in the data array needs price conversion
    const promises = data.memberships.map(async (membership) => {
      // Convert prices for each membership
      const prices = await convertPrices(membership.currency, Number(membership.price));
      // Return a new object with both price representations included
      return {
        ...membership,
        PriceInDollars: prices.priceInDollars,
        PriceInSatoshis: prices.priceInSatoshis
      };
    });

    // Resolve all promises to get the updated memberships
    const updatedMemberships = await Promise.all(promises);

    return updatedMemberships;
  } catch (error) {
    console.error('Error fetching memberships:', error);
    throw error;
  }
}

async function cancelSubscription() {

}

// Function that calls the API to check if the user has a membership for the flash_id (look in table user_memberships where user_public_key = userPublicKey and flash_id = flashId)
async function getUserMembershipsForFlashId(external_uuid, userPublicKey, flashId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/api/get_user_memberships_for_flash_id?external_uuid=${encodeURIComponent(external_uuid)}&user_public_key=${encodeURIComponent(userPublicKey)}&flash_id=${encodeURIComponent(flashId)}`);

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    // You might want to return the data here if you need to use it where the function is called
    return data.user_memberships;
  } catch (error) {
    // Log the error to the console
    console.error('Error fetching user memberships:', error);
    // Optionally, return or handle the error in another way
    throw error;
  }
}

async function getMembershipById(membership_id) {
  try {
    const response = await fetch(`https://api.paywithflash.com/api/get_membership_by_id?id=${encodeURIComponent(membership_id)}`);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }
    const data = await response.json ();
    const prices = await convertPrices(data.membership.currency, Number(data.membership.price));
    // Return a new object with both price representations included
    return {
      ...data.membership,
      PriceInDollars: prices.priceInDollars,
      PriceInSatoshis: prices.priceInSatoshis
    };
  } catch (error) {
    console.error ('Error fetching membership', error);
    throw error;
  }
}

async function cancelUserMembership(user_membership_id) {
  const formData = new FormData ();
  formData.append ('id', user_membership_id);

  const response = await fetch('https://api.paywithflash.com/api/cancel_user_membership', {
    method : 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error ('Network response was not ok during update');
  }

  const result = response.json ();

  return result;
}

// Function to be able to add new wallets
async function saveWalletToDB(userWalletName, walletNwcUrl, userPublicKey) {
  // Call function to get the wallet name based on the NWCUrl
  const selectedWalletName = await getWalletNameFromNWCString(walletNwcUrl);
  // Encrypt the nwcUrl
  let encrypted_nwc_url;
  if (typeof walletNwcUrl !== 'undefined' && walletNwcUrl !== null && walletNwcUrl !== "") {
    encrypted_nwc_url = await fetchEncryptedContent(userPublicKey, walletNwcUrl);
  } else {
    encrypted_nwc_url = "";
  }

  const formData = new FormData();
  formData.append('user_public_key', userPublicKey);
  formData.append('wallet_name', selectedWalletName);
  formData.append('ln_address', "");
  formData.append('user_wallet_name', userWalletName);
  formData.append('nwc_url', encrypted_nwc_url);

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_wallet_to_db', {
      method: 'POST',
      body: formData
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.wallet_id;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
}

async function getWalletNameFromNWCString(nwcString) {
  const walletsAvailable = ["Alby", "Mutiny"];
  // Convert nwcString to lowercase for case-insensitive comparison
  const lowerCaseNWCString = nwcString.toLowerCase();

  // Iterating over each wallet name to see if it's present in the nwcString
  for (const walletName of walletsAvailable) {
    // Also convert walletName to lowercase
    if (lowerCaseNWCString.includes(walletName.toLowerCase())) {
      return walletName;
    }
  }

  // Return "Custom" if no match is found
  return "Custom";
};

async function fetchEncryptedContent(userPublicKey, propertiesToDecrypt) {
  try {
    const response = await fetch(`https://nostr-eggs-server-skphk.ondigitalocean.app/api/encrypt_content`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        propertiesToDecrypt: propertiesToDecrypt,
      }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data.properties;
  } catch (error) {
    console.error('Error in fetchEncryptedContent:', error);
    throw error;
  }
};

async function createNewUser(email, flashPublicKey) {
  try {
    // Check if email exists in the database
    const emailExists = await checkUserEmailAddressInDb(email);
    if (emailExists) {
      const userPublicKey = emailExists;
      // Return the userPublicKey of that email
      return userPublicKey;
    }

    // Create new keys for the user
    const keysResult = await createPrivateKey();
    const userPrivateKey = keysResult.newPrivateKey;
    const userPublicKey = keysResult.publicKey;

    // Encrypt the user private key
    const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPublicKey, userPrivateKey);

    const password = uuidv4()

    // Sign up the user
    const signupResult = await signupUser(email, password, encryptedUserPrivateKey, userPublicKey);

    return userPublicKey;
  } catch (error) {
    console.error('Signup error:', error);
  }
}

async function createNewUserWithPublicKey (userPublicKey) {
  try {
    const password = uuidv4();
    const signUpResults = await signupUser ('', password, '', userPublicKey);
    return signUpResults;
  } catch (error) {
    console.log ('Creating the new user with userPublicKey:', error);
  }
}

async function signupUser(email, password, encryptedUserPrivateKey, publicKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      email: email,
      password: password,
      privateKey: encryptedUserPrivateKey,
      publicKey: publicKey,
      verificationLinkRequired: false,
    })
  };

  return fetch('https://api.paywithflash.com/api/signup', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('There was an error during the signup:', error);
    });
}

async function checkUserEmailAddressInDb(emailAddress) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({ email: emailAddress })
  };

  return fetch('https://api.paywithflash.com/api/check_email', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data.public_key;
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
}

async function checkUserPublicKeyInDb(userPublicKey) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams ({ userPublicKey: userPublicKey }),
  }

  return fetch ('https://api.paywithflash.com/api/check_user_public_key', requestOptions)
    .then (response => {
      if (!response.ok) {
        throw new Error ('Network response was not ok');
      }
      return response.json ();
    })
    .then (data => {
      return data.userPublicKeyExists;
    })
    .catch (error => {
      console.error ('There was a problem with the fetch options:', error);
    });
}

// Function to create new keys and set session storage
async function createPrivateKey() {
  const newPrivateKey = generatePrivateKey();
  const publicKey = getPublicKey(newPrivateKey);

  return { newPrivateKey, publicKey }; // Return the user hex keys
};

// Function to encode the user private key using nip04 before saving it to db
async function encryptUserPrivateKey(flashPublicKey, userPrivateKey) {
  const encryptedUserPrivateKey = await nip04.encrypt(userPrivateKey, flashPublicKey, userPrivateKey);

  return encryptedUserPrivateKey;
}

// Function to get the referral data from the db using user public key
async function fetchReferralDataWithPublicKey(userPublicKey) {
  const formData = new FormData ();
  formData.append('userPublicKey', userPublicKey);
  try {

    const response = await fetch('https://api.paywithflash.com/api/get_referral_info_with_public_key', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
    throw error; // Rethrowing the error for handling it further up in the call stack
  }
}

// Function that get wallet linked to flash
async function getWalletFromId(walletId) {
  try {
    const response = await fetch(`https://api.paywithflash.com/api/get_wallet_from_id?wallet_id=${encodeURIComponent(walletId)}`);

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    const walletDetails = await response.json();
    // You might want to return the wallet details here if you need to use them where the function is called
    return walletDetails;
  } catch (error) {
    // Log the error to the console
    console.error('Error fetching wallet details:', error);
    // Optionally, return or handle the error in another way
    throw error;
  }
}

// The function used to create the Zap Invoice
async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      console.error("Response Error: ", await response.text());
      throw new Error(data.message || 'Failed to create invoice');
    }
    return data.invoice;

  } catch (error) {
    console.error("Error in createZapInvoice: ", error);
    // Rethrow the error for the caller to handle
    throw error;
  }
}

// Function used to pay Zap Invoices
async function payZapInvoiceWithNwc(nostrWalletConnectUrl, invoice) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_zap_invoice_with_nwc', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nostrWalletConnectUrl,
        invoice,
      }),
    });

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      console.log("Successfully paid invoice")
    }

    const result = await response.json();
    return result.is_paid; // This will be the JSON object { is_paid: true/false }
  } catch (error) {
    // Log the error to the console or handle it in another way
    console.error('Error making payment:', error);
    throw error;
  }
}

// Function used to update the membership chosen by the user in table user_memberships
async function updateUserMembership(user_membership_id, userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {

  try {
    const response = await fetch('https://api.paywithflash.com/api/update_user_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        user_membership_id: user_membership_id,
        user_public_key: userPublicKey,
        flash_id: flashId,
        external_uuid: externalUuid,
        wallet_id: walletId,
        membership_id: membershipId,
        is_paid: isPaid,
        membership_start_date: membershipStartDate,
        next_payment_date: nextPaymentDate,
        status: status
      })
    });

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result; // Handle the JSON response as needed
  } catch (error) {
    // Log the error to the console or handle it in another way
    console.error('Error saving user membership:', error);
    throw error;
  }
}

// Function used to save the membership chosen by the user in table user_memberships
async function saveUserMembership(userPublicKey, flashId, externalUuid, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_user_membership', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        user_public_key: userPublicKey,
        flash_id: flashId,
        external_uuid: externalUuid,
        wallet_id: walletId,
        membership_id: membershipId,
        is_paid: isPaid,
        membership_start_date: membershipStartDate,
        next_payment_date: nextPaymentDate,
        status: status
      })
    });

    if (!response.ok) {
      // If the response status code is not in the 200-299 range,
      // throw an error with the status text
      throw new Error(`HTTP error! status: ${response.status}`);
    } else {
      console.log("Succesfully saved user membership")
    }

    const result = await response.json();
    return result; // Handle the JSON response as needed
  } catch (error) {
    // Log the error to the console or handle it in another way
    console.error('Error saving user membership:', error);
    throw error;
  }
}

// Function to save the transaction to table transactions of the db
async function saveTransactionToDB(from_public_key, to_public_key, transactionDate, amountInSats, valueInUsd, flashId, flashName, flashType, toWalletId, toUserWalletName, fromWalletId, fromUserWalletName, lnurl) {
  const formData = new FormData();
  formData.append('from_public_key', from_public_key);
  formData.append('to_public_key', to_public_key);
  formData.append('transaction_date', transactionDate);
  formData.append('amount_in_sats', amountInSats);
  formData.append('value_in_usd', valueInUsd);
  formData.append('flash_id', flashId);
  formData.append('flash_name', flashName);
  formData.append('flash_type', flashType); // Assuming this is also a new requirement
  formData.append('to_wallet_id', toWalletId);
  formData.append('to_user_wallet_name', toUserWalletName);
  formData.append('from_wallet_id', fromWalletId);
  formData.append('from_user_wallet_name', fromUserWalletName);
  formData.append('lnurl', lnurl);

  try {
    const response = await fetch('https://api.paywithflash.com/api/save_new_transaction_to_db', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to save transaction to database');
    }

    const successData = await response.json();
    console.log('Transaction successfully saved to database:', successData);
    return successData;
  } catch (error) {
    console.error('Error saving transaction to database:', error);
    throw error;
  }
}

async function sendPayInfoViaWebhook(flashId, userPublicKey, plan, userName, userAbout, userPictureUrl, eventType, signUpDate, failedPaymentDate, transactionId, transactionDate) {

  const payload = {
    eventType: eventType,
    flashId: flashId,
    userPublicKey: userPublicKey,
    plan: plan,
    userName: userName,
    userAbout: userAbout,
    userPictureUrl: userPictureUrl,
    signUpDate: signUpDate,
    failedPaymentDate: failedPaymentDate,
    transactionId: transactionId,
    transactionDate: transactionDate
  };

  try {
    const response = await fetch('https://api.paywithflash.com/api/send_payinfo_via_webhook', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status : ${response.status}`);
    }
  } catch (err) {
    console.log("Error sending pay info to Geyser", err);
    throw err;
  }
}

// The function used to for the user to pay the fee owed to referred him
async function payFeeToReferral(referralUserPublicKey, encryptedReferralUserNostrWalletConnectUrl, userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo, priceInSats, priceInDollars, flashId) {

    // Get transaction date
    const transactionDate = new Date().toISOString();

    // Get the price of bitcoin
    const bitcoinPrice = await getBitcoinPrice(); // If getBitcoinPrice is async, otherwise remove await

    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_referral', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        referralUserPublicKey: referralUserPublicKey,
        encryptedReferralUserNostrWalletConnectUrl: encryptedReferralUserNostrWalletConnectUrl,
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }

    return data;
}

async function payFeeToFlash(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo, priceInSats, priceInDollars, flashId, user_public_key) {
  try {
    // Get transaction date
    const transactionDate = new Date().toISOString();

    // Get the price of bitcoin
    const bitcoinPrice = await getBitcoinPrice(); // If getBitcoinPrice is async, otherwise remove await

    // Call your server-side API to decrypt the config with nostr nip04
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_fee_to_flash', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userPublicKey: userPublicKey,
        encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
        amount: amount,
        memo: memo,
        amount_in_sats: priceInSats,
        value_in_usd: priceInDollars,
        usd_btc_exchange_rate: bitcoinPrice,
        flash_id: flashId,
        transaction_date: transactionDate,
        user_public_key: user_public_key
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Failed to decrypt config');
    }
    console.log('Fee transaction successfully saved to database:', data);
    return data;
  } catch (error) {
    console.error('Error in payFeeToFlash:', error);
    throw error; // Re-throw the error if you want the calling function to handle it
  }
}




const ManageSubscriptionPage = () => {

  const navigate = useNavigate();
  // Get env var
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // params
  const [encodedParams, setEncodedParams] = useState('');
  const [subscriptionPageUrl, setSubscriptionPageUrl] = useState('');

  // States for the flash
  const [appName, setAppName] = useState('');
  const [priceInDollars, setPriceInDollars] = useState();
  const [priceInSats, setPriceInSats] = useState();
  const [flashDetails, setFlashDetails] = useState('');
  const [flashUrl, setFlashUrl] = useState('') || "";
  const [flashId, setFlashId] = useState('');
  const [email, setEmail] = useState ('');
  const [npub, setNpub] = useState ();
  const [isVerified, setIsVerified] = useState();
  const [externalUuid, setExternalUuid] = useState ('');
  const [memberships, setMemberships] = useState([]);
  const [userMembershipId, setUserMembershipId] = useState();
  const [loginMethod, setLoginMethod] = useState ('');

  // States for subscription plans
  const [selectedMembership, setSelectedMembership] = useState(null);


  // States for payments
  const [userAlreadySubscribed, setUserAlreadySubscribed] = useState(false);
  const [flashWalletDetails, setFlashWalletDetails] = useState(null);
  const [payerPublicKey, setPayerPublicKey] = useState('');

  // Transactions By subscription and user
  const [transactions, setTransactions] = useState([]); 

  useEffect(() => {
    // Extracts URL parameters, useful for both redirects and initial iframe load
    const queryParams = new URLSearchParams(window.location.search);
    const flashId = queryParams.get('flashId');
    const encodedParams = queryParams.get('params');
    setFlashId(flashId);

    if (encodedParams) {
      try {
        // Parse the data  from encoded params
        const decodedString = atob(encodedParams);
        const params = JSON.parse(decodedString);
        const { email, npub, is_verified, external_uuid } = params;
        setEmail (email);
        setNpub (npub);
        setIsVerified (is_verified);
        setExternalUuid (external_uuid);

      } catch (error) {
        console.error ('Encoding the queryParams: ', error);
      }
    }
    
  }, []);
  
  useEffect (() => {
    if (flashId) {
      setIsLoading(true); // Set loading true at the start of the effect
  
      const fetchFlashDetails = async () => {
        try {
          const flash = await getFlashById(flashId);
          if (flash) {
            setFlashDetails(flash);
            setPriceInDollars(flash.PriceInDollars);
            setPriceInSats(flash.PriceInSatoshis);
            setAppName(flash.name || ''); // Handle potentially undefined properties
            setFlashUrl(flash.url || '');
            setLoginMethod(flash.flash_login_method || '');
          } else {
            // Handle case where no flash details are returned
            setFlashDetails({});
            setPriceInDollars(0);
            setPriceInSats(0);
            setAppName('');
            setFlashUrl('');
            setLoginMethod('');
          }
        } catch (error) {
          console.error('Failed to load flash details:', error);
          setFlashDetails({});
        }
  
        try {
          const memberships = await getMembershipsByFlashId(flashId);
          setMemberships(memberships || []);
        } catch (error) {
          console.error('Failed to load memberships:', error);
          setMemberships([]);
        }
  
        setIsLoading(false); // Set loading false after all fetches have been attempted
      };
  
      fetchFlashDetails();

    }

  }, [flashId]);

  useEffect (() => {
    if (isVerified) {
      setIsLoading(true);

      // Calculate the Subscription Page Url
      const jsonData = {
        email: email,
        npub: npub,
        is_verified: false,
        external_uuid: externalUuid,
      };
      const jsonString = JSON.stringify(jsonData);
      const encodedData = btoa(jsonString);
      setEncodedParams(encodedData);
      setSubscriptionPageUrl(`https://paywithflash.com/subscription-page?flashId=${flashId}&params=${encodedData}`);

      if (externalUuid) {
        getUserMembershipsForFlashId(externalUuid, '', flashId)
          .then (async(data) => {
            if (data.status) {
              if (data.userMembership.length > 0) {
                setUserMembershipId(data.userMembership[0].id);
                const membership_id = data.userMembership[0].membership_id;
                const user_public_key = data.userMembership[0].user_public_key;
                const next_payment_date = data.userMembership[0].next_payment_date;
                const membership = await getMembershipById (membership_id);
                setSelectedMembership ({...membership, ...{'next_payment_date': next_payment_date}});
                setPayerPublicKey (user_public_key);
                setUserAlreadySubscribed (true);
              }
              else {
                setUserAlreadySubscribed(false);
                console.log("No memberships found for this user and flash_id.");
                // Handle case where no memberships are found (perhaps guide user to subscription options)
                setError("No memberships found for this user about this subscription");
              }
            }
            setIsLoading(false);
          })
      }
      else if (email) {
        checkUserEmailAddressInDb (email)
          .then (async(data) => {
            if (data) {
              const user_public_key = data;
              setPayerPublicKey (user_public_key);
              const user_memberships = await getUserMembershipsForFlashId('', user_public_key, flashId);
              console.log(user_memberships);
  
              // Ensure there is at least one membership in the array
              if (user_memberships.length > 0) {
                  const firstMembership = user_memberships[0]; // Take the first membership
                  console.log(firstMembership);
  
                  if (firstMembership.is_paid === 'true') {
                      console.log("Duplicate - User already subscribed and doesn't need to pay");
                      setUserMembershipId(firstMembership.id);
                      const membership_id = firstMembership.membership_id;
                      const next_payment_date = firstMembership.next_payment_date;
                      const membership = await getMembershipById (membership_id);
                      setSelectedMembership ({...membership, ...{'next_payment_date': next_payment_date}});
                      setUserAlreadySubscribed(true);
                  }
              } else {
                  setUserAlreadySubscribed(false);
                  console.log("No memberships found for this user and flash_id.");
                  // Handle case where no memberships are found (perhaps guide user to subscription options)
                  setError("No memberships found for this user about this subscription");
              }
            }
            else {
              setUserAlreadySubscribed (false);
            }
            setIsLoading(false);
          })
      }
      else if (npub) {
          const userPublickey = convertNostrToHex(npub);
          setPayerPublicKey (userPublickey);
          getUserMembershipsForFlashId('', userPublickey, flashId).
              then (async(user_memberships) => {
                  console.log(user_memberships);
          
                  // Ensure there is at least one membership in the array
                  if (user_memberships.length > 0) {
                      const firstMembership = user_memberships[0]; // Take the first membership
                      console.log(firstMembership);
          
                      if (firstMembership.is_paid === 'true') {
                          console.log("Duplicate - User already subscribed and doesn't need to pay");
                          setUserMembershipId(firstMembership.id);
                          const membership_id = firstMembership.membership_id;
                          const next_payment_date = firstMembership.next_payment_date;
                          const membership = await getMembershipById (membership_id);
                          setSelectedMembership ({...membership, ...{'next_payment_date': next_payment_date}});
                          setUserAlreadySubscribed(true);
                      }
                  } else {
                      setUserAlreadySubscribed (false);
                      console.log("No memberships found for this user and flash_id.");
                      // Handle case where no memberships are found (perhaps guide user to subscription options)
                      setError("No memberships found for this user about this subscription");
                  }
                  setIsLoading(false);
              })
      }
    }
  }, [email, npub, isVerified, externalUuid]);
  
  return (
    <>
      {isLoading && (
        <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style={{ zIndex: '100' }}>
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-col justify-center items-center mt-10 lg:p-32">
          <div className="w-full bg-white rounded-xl overflow-hidden">

            { !isVerified && (
              <div className="text-md font-bold text-center mb-6"> Enter your contact information to access and update your subscription details. </div>
            )}
            <div className="grid grid-cols-2 gap-2 lg:divide-x-2">
              <div className="lg:col-span-1 sm:col-span-2 col-span-2 p-8 flex flex-col justify-center items-center">
                <div className="px-8 pt-8 w-full flex items-start">
                  <div className="uppercase tracking-wide font-semibold text-gray-500">{flashDetails.name} </div>
                </div>
                <div className="flex justify-center items-center md:flex-shrink-0 p-8 pb-3 w-3/5">
                  <img
                    className="object-contain rounded-xl"
                    src={flashDetails.flash_image_url}
                    alt="First Image"
                  />
                </div>
                <div className="text-center text-xs text-gray-500 mt-8">
                  Powered by <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <b>Flash</b>
                  </a>
                </div>              </div>
              <div className="lg:col-span-1 sm:col-span-2 col-span-2 flex flex-col justify-center items-center mx-2">
                { userAlreadySubscribed && (
                  <>
                    <ManageSubscriptionUserSubscribedScreen payerPublicKey={payerPublicKey} selectedMembership={selectedMembership} flashId={flashId} encodedParams={encodedParams} />
                    <button
                      type="button"
                      onClick={async () => {
                        await cancelUserMembership(userMembershipId);
                        navigate(`/subscription-page?flashId=${flashId}&params=${encodedParams}`);
                      }}
                      className="w-full sm:w-2/3 rounded-md bg-gray-800 py-2 mx-4 my-2 mb-10 text-sm font-semibold text-white shadow-sm hover:bg-gray-900"
                    >
                      Cancel Subscription
                    </button>
                  </>
                )}
                { !userAlreadySubscribed && (
                    <>
                        {/* <div className="text-center text-xs text-gray-500 mt-8"> User didn't subscribed yet! Click the following link to subscribe 
                        </div> */}
                      <ProductOrderFormManage
                        email={email}
                        setEmail={setEmail}
                        npub={npub}
                        setNpub={setNpub}
                        isVerified={isVerified}
                        setIsVerified={setIsVerified}
                        loginMethod={loginMethod}
                      />
                      { error && (
                        <div className="max-w-4xl mx-auto p-8 bg-white rounded-lg shadow">
                          <div className="text-center text-2xl text-gray-800 text-bold mt-8"> You are not subscribed! </div>
                          <div className="text-center text-xl text-gray-800 text-bold mt-2">
                            You can subscribe to {flashDetails.name}
                            <a href={subscriptionPageUrl} style={{ fontWeight: 'bold' }}> here </a>
                          </div>
                        </div>
                      )}
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageSubscriptionPage;
