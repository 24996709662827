import React, { useEffect, useState, useRef } from 'react';
import { nip04, getPublicKey } from 'nostr-tools';
import { webln } from "@getalby/sdk";
import Lottie from 'react-lottie';

import confettiAnimation from '../../assets/confetti_animation.json';
import { XCircleIcon } from '@heroicons/react/24/outline';

import FlashLogin from './Login';
import SelectWallet from './SelectWallet';
import Summary from './Summary';



// Helper function to fetch wallet balance
async function getWalletBalance(nwcUrl) {
  const nwc = new webln.NostrWebLNProvider({
      nostrWalletConnectUrl: nwcUrl,
  });
  await nwc.enable();
  const balanceResponse = await nwc.getBalance();
  nwc.close();
  return balanceResponse;
}


const getWalletLogoUrlFromNWCString = async (nwcString) => {
  const walletsAvailable = [
      {
          name: "alby",
          logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/AlbyWalletLogo.png",
      },
      {
          name: "mutiny",
          logoURL: "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/MutinyWalletLogo.jpeg",
      },
  ];

  const lowerCaseNWCString = nwcString.toLowerCase();

  for (let i = 0; i < walletsAvailable.length; i++) {
      if (lowerCaseNWCString.includes(walletsAvailable[i].name)) {
          console.log(walletsAvailable[i].logoURL)
          return walletsAvailable[i].logoURL;
      }
  }

  // Return "Custom" or a default image if no match is found
  return "https://flash-images.fra1.cdn.digitaloceanspaces.com/flash_images/Flash_favicon.png"; // Replace with your default wallet logo URL
};



// Function that gets all user wallets
async function getUserWallets(userPrivateKey, publicKey) {
  const userPublicKey = getPublicKey(userPrivateKey);
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userPublicKey)}`);
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const walletPromises = data.map(async item => {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
              decrypted_nwc_url = await nip04.decrypt(userPrivateKey, publicKey, item.nwc_url);
          } else {
              decrypted_nwc_url = "";
          }

          // Fetch balance and logo URL concurrently
          const balancePromise = getWalletBalance(decrypted_nwc_url); // Abstracted balance fetching to a function
          const logoUrlPromise = getWalletLogoUrlFromNWCString(decrypted_nwc_url); // Fetch logo URL

          try {
              const [balanceResponse, walletLogoUrl] = await Promise.all([balancePromise, logoUrlPromise]);

              return {
                  id: item.id,
                  user_publicKey: item.user_public_key,
                  wallet_name: item.wallet_name,
                  user_wallet_name: item.user_wallet_name,
                  nwc_url: item.nwc_url,
                  balance: balanceResponse.balance, // Adding balance to the result
                  wallet_logo_url: walletLogoUrl // Adding wallet logo URL to the result
              };
          } catch (error) {
              console.error('Error processing wallet:', error);
              return {
                  ...item,
                  nwc_url: item.nwc_url,
                  balance: 'Error', 
                  wallet_logo_url: 'default-logo-url.jpg' // Replace with your default logo URL
              };
          }
      });

      return await Promise.all(walletPromises);
  } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
  }
}


// Function that get wallet linked to flash
async function getWalletFromId(walletId) {
  try {
      const response = await fetch(`https://api.paywithflash.com/api/get_wallet_from_id?wallet_id=${encodeURIComponent(walletId)}`);

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
      }

      const walletDetails = await response.json();
      // You might want to return the wallet details here if you need to use them where the function is called
      return walletDetails;
  } catch (error) {
      // Log the error to the console
      console.error('Error fetching wallet details:', error);
      // Optionally, return or handle the error in another way
      throw error;
  }
}



// The function used to create the Zap Invoice
async function createZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, amount, memo) {
  try {
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/create_zap_invoice', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          userPublicKey: userPublicKey,
          encryptedUserNostrWalletConnectUrl: encryptedUserNostrWalletConnectUrl,
          amount: amount,
          memo: memo
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      console.error("Response Error: ", await response.text());
      throw new Error(data.message || 'Failed to create invoice');
    }
    return data.invoice;

  } catch (error) {
    console.error("Error in createZapInvoice: ", error);
    // Rethrow the error for the caller to handle
    throw error;
  }
}



// Function used to pay Zap Invoices
async function payZapInvoice(userPublicKey, encryptedUserNostrWalletConnectUrl, invoice) {

  try {
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/pay_zap_invoice', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              userPublicKey,
              encryptedUserNostrWalletConnectUrl,
              invoice,
          }),
      });

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result.is_paid; // This will be the JSON object { is_paid: true/false }
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error making payment:', error);
      throw error;
  }
}



// Function used to save the membership chosen by the user in table user_memberships
async function saveUserMembership(userPublicKey, flashId, walletId, membershipId, isPaid, membershipStartDate, nextPaymentDate, status) {

  try {
      const response = await fetch('https://api.paywithflash.com/api/save_user_membership', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
              user_public_key: userPublicKey,
              flash_id: flashId,
              wallet_id: walletId,
              membership_id: membershipId,
              is_paid: isPaid,
              membership_start_date: membershipStartDate,
              next_payment_date: nextPaymentDate,
              status: status
          })
      });

      if (!response.ok) {
          // If the response status code is not in the 200-299 range,
          // throw an error with the status text
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      return result; // Handle the JSON response as needed
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error saving user membership:', error);
      throw error;
  }
}






// Function to save the transaction to table transactions of the db
async function saveTransactionToDB(lnurl, amountInSats, valueInUsd, flashId, transactionDate, userPublicKey) {

  const formData = new FormData();
  formData.append('lnurl', lnurl);
  formData.append('amount_in_sats', amountInSats);
  formData.append('value_in_usd', valueInUsd);
  formData.append('flash_id', flashId);
  formData.append('transaction_date', transactionDate);
  formData.append('user_public_key', userPublicKey);

  try {
      const response = await fetch('https://api.paywithflash.com/api/save_transaction_to_db', {
          method: 'POST',
          body: formData,
      });

      if (!response.ok) {
          // Parse JSON response to get error details
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to save transaction to database');
      }

      // Parse JSON response to get success data
      const successData = await response.json();
      console.log('Transaction successfully saved to database:', successData);
  } catch (error) {
      // Log the error to the console or handle it in another way
      console.error('Error saving transaction to database:', error);
      throw error;
  }
}



// Function to get the price of Bitcoin
async function getBitcoinPrice() {
    try {
        const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
            method: 'POST', // if the API requires POST method
            headers: {
                'Content-Type': 'application/json',
            },
            // If the API requires a body payload, uncomment and edit the following line
            // body: JSON.stringify({ key: 'value' }), 
        });
  
        if (!response.ok) {
            // If the response is not OK, throw an error with the response status
            throw new Error(`API call failed with status: ${response.status}`);
        }
  
        const data = await response.json();
  
        // Assuming the API returns an object with a property that contains the Bitcoin price
        return data
    } catch (error) {
        console.error('API call failed:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
  }
  
  // Function to go through the plans and make sure we have two columns: price in sats & price in dollars
  async function convertPrices(flashDetails) {
    try {
        const bitcoinPrice = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
        const oneBitcoinInSats = 100000000; // 100 million satoshis in one bitcoin
  
        let priceInDollars, priceInSats;
  
        if (flashDetails.currency.toLowerCase() === "dollars") {
            priceInDollars = parseFloat(flashDetails.price);
            priceInSats = Math.round((priceInDollars / bitcoinPrice) * oneBitcoinInSats);
        } else if (flashDetails.currency.toLowerCase() === "satoshis") {
            priceInSats = parseFloat(flashDetails.price);
            priceInDollars = ((priceInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
        }
        return {
            ...flashDetails,
            priceInDollars,
            priceInSats
        };
  
    } catch (error) {
        console.error('Conversion failed:', error);
        return flashDetails; // Return original flashDetails in case of error
    }
  }



  async function getFlashPublicKey() {
    try {
      // Make a GET request to the API endpoint
      const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/getFlashPublicKey', {
        method: 'GET',
        headers: {
          // You can add headers if needed, such as authentication headers
        },
      });
  
      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Parse the JSON response to get the public key
        const data = await response.json();
        return data.publicKey;
      } else {
        // Handle the error if the response status is not 200
        throw new Error(`API call failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error in getFlashPublicKey:', error);
      throw error;
    }
  }







function payWithFlashModal({ flashDetails, onPaymentSuccess, closeModal, selectedWallet, setSelectedWallet, userPublicKey, setUserPublicKey }) {

  // States for data received upon page load
  const [publicKey, setPublicKey] = useState(null);
  const [appName, setAppName] = useState('');
  const [flashId, setFlashId] = useState(40);
  const [flashUrl, setFlashUrl] = useState('') || "";
  const [sourceUrl, setSourceUrl] = useState('') || "";
  const [flashWalletDetails, setFlashWalletDetails] = useState('');
  const [updatedFlashDetails, setUpdatedFlashDetails] = useState('');
  

  // States for user data
  const [userData, setUserData] = useState('') || "";
  const [userPrivateKey, setUserPrivateKey] = useState('') || "";
  const [userName, setUserName] = useState('') || "";
  const [userPictureUrl, setUserPictureUrl] = useState('') || "";
  const [userAbout, setUserAbout] = useState('') || "";



  // States for user wallet
  const [wallets, setWallets] = useState([]);

  // States for managing app states
  const [userLogin, setUserLogin] = useState(true);
  const [selectWallet, setSelectWallet] = useState(false);
  const [summary, setSummary] = useState(false);
  const [summaryError, setSummaryError] = useState(false);
  const [loading, setLoading] = useState(false);

  const modalRef = useRef();


  // Hook to get the flash public key
  useEffect(() => {
    async function fetchPublicKey() {
      try {
        const retrievedPublicKey = await getFlashPublicKey();
        setPublicKey(retrievedPublicKey);
      } catch (error) {
        console.error('Error fetching flash public key:', error);
      }
    }

    fetchPublicKey();
  }, []);

  

  // Get the flash wallet details
  useEffect(() => {
    const fetchWalletDetails = async () => {
      if (flashDetails) {
        const flashWalletId = flashDetails.wallet_id;

        // Get the details of the wallet from the db and set to state variable
        try {
          const flashWalletDetails = await getWalletFromId(flashWalletId);
          setFlashWalletDetails(flashWalletDetails);
        } catch (error) {
          console.error('Error fetching wallet details:', error);
          // Handle the error as needed
        }
      }
    };

    fetchWalletDetails();
  }, [flashDetails]);


  // useEffect to get the priceInSats and priceInDollars
  useEffect(() => {
    async function updateFlashDetails() {
      const newFlashDetails = await convertPrices(flashDetails);
      setUpdatedFlashDetails(newFlashDetails);
    }

    if (flashDetails !== null) {
      updateFlashDetails();
    }
  }, [flashDetails]);




  const handleSelectWallet = async (wallet) => {
    // Set the selected plan to the state
    setSelectedWallet(wallet);

    // Hide the Selet Wallet interface
    setSelectWallet(false);
    // Show the Summary interface
    setSummary(true);

  };

  
  const handleBackFromSummary = () => {
    // Assuming you want to go back to wallet selection
    setSummary(false);
    setSelectWallet(true);
  };
  



  const handleConfirm = async () => {
    // Hide the Summary interface
    setSummary(false);
    // Set the error to false
    setSummaryError(false);

    setLoading(true);

    try {

      // Create invoice with flash wallet nwc
      const invoice = await createZapInvoice(
        flashWalletDetails.publicKey,
        flashWalletDetails.nwc_url,
        updatedFlashDetails.priceInSats,
        `Payment for ${updatedFlashDetails.name} plan with Flash`
      );
    
      // Pay invoice with user wallet nwc
      const is_paid = await payZapInvoice(userPublicKey, selectedWallet.nwc_url, invoice)

      // If successful, save data to db
      if (is_paid) {
        onPaymentSuccess()
        closeModal();
        setLoading(false);


      } else {
        // Else, show summary page again with an error
        setLoading(false);

        // Show the Summary interface
        setSummary(true);
        setSummaryError(true);
      }

    } catch (error) {
      // Else, show summary page again with an error
      setLoading(false);

      // Show the Summary interface
      setSummary(true);
      setSummaryError(true);
    }

  };



  const handleLoginSuccess = async (loginData) => {
    // Do something with loginData
    setUserData(loginData);

    // Get all login values
    const userPrivateKey = loginData.userPrivateKey;
    const userPublicKey = loginData.userPublicKey;
    const userName = loginData.userName;
    const userPictureUrl = loginData.userPictureUrl;
    const userAbout = loginData.userAbout;

    // Set all state variables
    setUserPrivateKey(userPrivateKey);
    setUserPublicKey(userPublicKey);
    setUserName(userName);
    setUserPictureUrl(userPictureUrl);
    setUserAbout(userAbout);

    setUserLogin(false);

    // Get the user Wallets and set to state variable
    const userWallets = await getUserWallets(userPrivateKey, publicKey)
    setWallets(userWallets);

    setSelectWallet(true);

  };



  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div ref={modalRef} className="relative rounded-2xl shadow-2xl w-full sm:w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/3 2xl:w-1/4 max-w-3xl flex flex-col p-4" style={{ backgroundImage: 'radial-gradient(circle, rgb(37 13 79), rgb(20 6 50))', margin: 'auto', width: '100%' }}>
      {/* Close Button */}
      <button 
            onClick={closeModal} 
            className="text-right text-gray-400 bg-transparent hover:text-gray-700 font-semibold p-2 rounded" // Adjust margin/padding if needed
        >
            <XCircleIcon className="h-8 w-8"/>
        </button>
        
      <div className="flex flex-col justify-center bg-gray-900 text-white rounded-2xl shadow-2xl flex-grow">
        
  
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        )}
        {userLogin && (
          <FlashLogin appName={appName} onLoginSuccess={handleLoginSuccess} />
        )}
  
        {/* If the user already selected a plan */}
        {selectWallet && (
          <SelectWallet wallets={wallets} userData={userData} setWallets={setWallets} userPrivateKey={userPrivateKey} publicKey={publicKey} getUserWallets={getUserWallets} onSelectWallet={handleSelectWallet} className="mx-4 mb-4"/>
        )}
  
        {/* Show the summary interface */}
        {summary && (
          <Summary wallet={selectedWallet} flashDetails={updatedFlashDetails} userData={userData} summaryError={summaryError} onConfirm={handleConfirm} onBack={handleBackFromSummary} />
        )}
      </div>
    </div>
  </div>
  
);

  
}

export default payWithFlashModal;
