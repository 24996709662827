import React, { useEffect, useState } from 'react';
import ProductCurrentOrder from './ProductCurrentOrder';
import cn from 'classnames';

const CurrentOrder = ({ selectedProducts, productOrderingCount, clearOrder, increaseOrderCount, decreaseOrderCount }) => {
    return (
        <>
            <div className="flex flex-row items-center justify-between px-5 mt-5">
                <div className="font-bold text-xl">Current Order</div>
                <div className="font-semibold">
                    <span className="px-4 py-2 rounded-md bg-red-100 text-red-500 hover:cursor-pointer hover:bg-red-500 hover:text-white" onClick={clearOrder}>Clear All</span>
                </div>
            </div>
            <div className="px-5 py-4 mt-5 overflow-y-auto h-64">
                {selectedProducts.map((product, index) => {
                    return (
                        <div key={index} className="flex flex-row justify-between items-center mb-4">
                            <div className="flex flex-row items-center w-2/5">
                                <img src={product.ImageURLs[0]} className="w-10 h-10 object-cover rounded-md" alt="" />
                                <span className="ml-4 font-semibold text-sm">{product.Name}</span>
                            </div>
                            <div className="w-32 flex justify-between">
                                <span className="px-3 py-1 rounded-md bg-gray-300 hover:cursor-pointer hover:bg-gray-700" onClick={() => decreaseOrderCount(index)}>-</span>
                                <span className="font-semibold mx-4">{productOrderingCount[index]}</span>
                                <span
                                    className={cn(
                                        {
                                            // Add hover effects only if inventory is not reached or not tracked
                                            'hover:bg-gray-700': productOrderingCount[index] != (product.inventory ? product.inventory.StockCount : Infinity),
                                            'hover:cursor-pointer': productOrderingCount[index] != (product.inventory ? product.inventory.StockCount : Infinity)
                                        },
                                        "px-3 py-1 rounded-md bg-gray-300"
                                    )}
                                    onClick={() => {
                                        // Allow increasing order count if inventory is not tracked or stock is not reached
                                        if (!product.inventory || productOrderingCount[index] != product.inventory.StockCount) {
                                            increaseOrderCount(index);
                                        }
                                    }}
                                >
                                    +
                                </span>
                            </div>
                            <div className="font-semibold text-lg w-16 text-center">
                                {product.Currency == 'usd' ? '$' : ''}{product.Price * productOrderingCount[index]} {product.Currency != 'usd' ? 'sats' : ''}
                            </div>
                        </div>
                    )
                })}
                {selectedProducts.length == 0 && (
                    <div className="text-xl text-center font-semibold"> No Order </div>
                )}
            </div>
        </>
    )
}

export default CurrentOrder;