import React, { useState, useRef } from 'react';
import { Switch } from '@headlessui/react'
import axios from 'axios'; // for calling API
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { NostrWebLNProvider, webln } from "@getalby/sdk";
import { LightningAddress } from "@getalby/lightning-tools";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import AlertEggCreated from './AlertEggCreated';

import { PlusIcon, LockClosedIcon, GlobeAltIcon, UserIcon, ArrowLongLeftIcon } from '@heroicons/react/24/outline';




function CreateEgg({ on_upload_cancel }) {
  const [eggName, setEggName] = useState('');
  const [eggNameError, setEggNameError] = useState(null);

  const [eggUrl, setEggUrl] = useState('');
  const [eggDescription, setEggDescription] = useState('');
  const [eggHeaders, setEggHeaders] = useState(
`{
  "Example Header": "xxxxxxxxxxx",
}

`);
  const [eggPayload, setEggPayload] = useState(
`{
  "Your": "payload goes here",
}

`);
  const [eggExampleUsage, setEggExampleUsage] = useState(
`// Create your json
const data = {
  egg: "your_function_name",
  data: {
    "your": "payload goes here",
  },
  paid_by: "app",
};
// Convert to string
const jsonData = JSON.stringify(data);
// Call NostrEggs.getEgg()
const myNostrEgg = await NostrEggs.getEgg(jsonData)
`);
  const [finalMarkdown, setFinalMarkdown] = useState("");
  const [privacyStatus, setPrivacyStatus] = useState('Public'); // Default to 'Public'
  const [paidEgg, setPaidEgg] = useState(false)
  const [eggOwnerLnAddress, setEggOwnerLnAddress] = useState('')
  const [eggPrice, setEggPrice] = useState(0)

  const [tooltipVisible1, setTooltipVisible1] = useState(false);
  const [tooltipVisible2, setTooltipVisible2] = useState(false);
  const [tooltipVisible3, setTooltipVisible3] = useState(false);
  const [lnAddressError, setLnAddressError] = useState('');
  const [PriceError, setPriceError] = useState("Price can't be zero or empty");

  const [feedbackMessage, setFeedbackMessage] = useState('');


  const nostrWalletConnectUrl = process.env.REACT_APP_NWC_URL
  
  const [showAlertEggCreated, setShowAlertEggCreated] = useState(false);

  // Get the values from session storage
  let userNpub = localStorage.getItem('userNpub') || "";

  const closeAlertEggCreated = () => {
    setShowAlertEggCreated(false);
  };

  const onEggNameChange = (e) => {
    const newValue = e.target.value;
    setEggName(newValue);

    // Check for spaces
    if (newValue.includes(' ')) {
        setEggNameError('Name should not contain spaces.');
        return;  // Exit early
    }

    // Debounced check in the DB
    checkNameInDB(newValue);
};


  const onEggUrlChange = (e) => {
    setEggUrl(e.target.value);
  };

  const handlePrivacyToggle = (status) => {
    setPrivacyStatus(status);
  };

  const onEggOwnerLnAddressChange = async (e) => {
    const lnAddress = e.target.value;
    setEggOwnerLnAddress(lnAddress);

    try {
        await checkEggOwnerLnAddress(lnAddress);
        setLnAddressError(''); // clear any existing error message
    } catch (err) {
        setLnAddressError('Not a proper nip-05 lightning address. Check the hint for more info.');
    }
  };

  const onEggPriceChange = (e) => {
    const inputValue = e.target.value;
    setEggPrice(inputValue);

    if (inputValue > 0) {
      setPriceError(''); // clear any existing error message
    } else {
      setPriceError("Price can't be zero or empty");
    }
};



  // Create references
  const headersSyntaxRef = useRef(null);
  const headersTextAreaRef = useRef(null);

  const payloadSyntaxRef = useRef(null);
  const payloadTextAreaRef = useRef(null);

  const exampleSyntaxRef = useRef(null);
  const exampleTextAreaRef = useRef(null);


  // Synchronize scrolling
  const syncScrolling = (syntax, textArea) => {
    if (syntax.current && textArea.current) {
        syntax.current.scrollLeft = textArea.current.scrollLeft;
    }
  };

  // This function is to delay calling the API in checkNameinDb
  function debounce(func, delay) {
    let timer;
    return function(...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
  }

  // This function checks to see an egg with the same name doesn't exist already in the db
  const checkNameInDB = debounce(async (name) => {
    try {
        const response = await fetch(`https://api.paywithflash.com/api/get_egg?egg_name=${name}`);
        const data = await response.json();
        
        if (response.status === 404) {
            setEggNameError(null);
        } else if (data && data.name) {
            setEggNameError('Name already exists in the database.');
        }
    } catch (error) {
        console.error('Error checking egg name:', error);
        setEggNameError('Error checking name availability.');
    }
  }, 500);  // 500ms delay


  // This function is to validate the form being enabling the Save button
  const isFormValid = () => {
    // Check for name and name errors
    if (!eggName || eggNameError) {
      return false;
    }
  
    // Check other mandatory fields
    if (!eggUrl || !eggDescription || !eggPayload || !eggExampleUsage) {
      return false;
    }
  
    // If paid toggle is ON, then lightning address and price should not be empty
    if (paidEgg && (eggPrice <= 0 || !eggOwnerLnAddress)) {
      return false;
    }
  
    return true;
  };
  
  
  

  

  const onEggUpload = async () => {
    
    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    const nostrWalletConnectUrl = process.env.REACT_APP_NWC_URL

    // Clear previous feedback messages
    setFeedbackMessage('');

    if (!isFormValid()) {
      setFeedbackMessage('Please check the form for errors or missing fields.');
      return;  // Exit the function early
    }

    // if (paidEgg && eggPrice === 0 && !eggOwnerLnAddress) {
    //   alert('If you are creating a paid Vectorstore, the Price and Lightning Address have to be input');
    //   return;
    // }

    // If one of our fields has an error, we shouldn't load to db
    
  
    setShowAlertEggCreated(true);
    // If the user paid, then run the following code
    try {
      const formData = new FormData();
      formData.append('egg_name', eggName);
      formData.append('egg_url', eggUrl);
      formData.append('egg_description', eggDescription);
      formData.append('egg_headers', eggHeaders);
      formData.append('egg_payload', eggPayload);
      formData.append('egg_example_usage', eggExampleUsage)
      formData.append('created_by', userNpub);
      formData.append('privacy_status', privacyStatus);
      formData.append('egg_owner_ln_address', eggOwnerLnAddress);
      formData.append('egg_price', eggPrice);

      const response = await axios.post('https://api.paywithflash.com/api/save_egg_to_db', formData);

    } catch (error) {
        console.error('API call failed:', error);
    }
  };


  // We need a function that will make sure the name is unique in the db. Should call the db each time with the same name


  async function checkEggOwnerLnAddress(vectorstore_owner_ln_address) {
    try {
    const NWC = new webln.NostrWebLNProvider({
      nostrWalletConnectUrl: nostrWalletConnectUrl,
    });
  
    const ln = new LightningAddress(vectorstore_owner_ln_address, {
      webln: NWC,
    });
  
    await ln.fetch();
  
    if (!ln.nostrPubkey || ln.nostrPubkey === "") { // check for both undefined and empty string
      throw new Error('Invalid LN Address');
    }
  } catch (error) {
    throw new Error('Invalid LN Address');
  }
}



  return (
    <div className="relative">
      {showAlertEggCreated && (
        <div className="fixed top-2.5 right-2.5 z-50">
          <AlertEggCreated onClose={closeAlertEggCreated} />
        </div>
      )}
      <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
          <form>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                <div className="sm:col-span-4">
                    <label htmlFor="eggName" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                    </label>
                    <label htmlFor="eggName" className="mt-2 block text-xs font-medium leading-6 text-gray-500">
                        Be explicit, this is what users will use when querying your api.
                    </label>
                    <div className="mt-1">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 sm:max-w-md">
                            <input
                                type="text"
                                name="eggName"
                                id="eggName"
                                autoComplete="eggName"
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="Name"
                                value={eggName}
                                onChange={onEggNameChange}
                            />
                        </div>
                        {eggNameError && <div className="mt-1 text-sm text-red-500">{eggNameError}</div>}
                    </div>
                </div>

                    <div className="col-span-full">
                      <label htmlFor="eggUrl" className="block text-sm font-medium leading-6 text-gray-900">
                        Egg url
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 sm:max-w-md">
                          <input
                            type="text"
                            name="eggUrl"
                            id="eggUrl"
                            autoComplete="eggUrl"
                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="API url"
                            value={eggUrl}
                            onChange={onEggUrlChange}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="col-span-full">
                      <label htmlFor="eggDescription" className="block text-sm font-medium leading-6 text-gray-900">
                          Description
                      </label>
                      <label htmlFor="eggName" className="mt-2 block text-xs font-medium leading-6 text-gray-500">
                        Be explicit, let users know <strong>exactly</strong> what they can get from your api. The more they know, the better.
                    </label>
                      <div className="mt-1">
                          <textarea
                              type="text"
                              name="eggDescription"
                              id="eggDescription"
                              autoComplete="eggDescription"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-800 sm:text-sm sm:leading-6"
                              placeholder="Describe your egg..."
                              value={eggDescription}
                              onChange={e => {
                                  setEggDescription(e.target.value);
                              }}
                              rows={3}
                          />
                      </div>
                  </div>


                  <div className="col-span-full">
                      <label htmlFor="eggHeaders" className="block text-sm font-medium leading-6 text-gray-900">
                          Headers
                      </label>
                      <label htmlFor="eggName" className="mt-2 block text-xs font-medium leading-6 text-gray-500">
                        Headers will be encrypted using your nostr public key and won't be shared with anyone.
                    </label>
                    <label htmlFor="eggName" className="block text-xs font-medium leading-6 text-gray-500">
                        No headers needed? Just delete the contents of the box below.
                    </label>
                      <div className="" style={{ 
                              position: 'relative', 
                              overflowX: 'auto', 
                              overflowY: 'hidden', 
                              resize: 'none', 
                              minHeight: '100px', 
                              borderRadius: '10px', 
                              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', 
                          }}>
                          
                          <div 
                              ref={headersSyntaxRef}
                              style={{ overflow: 'hidden' }}
                          >
                              <SyntaxHighlighter
                                  language="javascript"
                                  style={atomOneDark}
                                  customStyle={{
                                    padding: '15px',
                                    minHeight: '108px',  // Minimum height of 6 lines
                                    width: '100%',
                                    minWidth: 'max-content',
                                    borderRadius: '10px',
                                    resize: 'none',
                                    fontSize: '12px',
                                    lineHeight: '1.5',
                                    whiteSpace: 'pre',
                                  }}
                              >
                                  {eggHeaders}
                              </SyntaxHighlighter>
                          </div>
                          
                          <textarea
                              type="text"
                              ref={headersTextAreaRef}
                              name="eggHeaders"
                              id="eggHeaders"
                              autoComplete="eggHeaders"
                              spellCheck="false"
                              onScroll={() => syncScrolling(headersSyntaxRef, headersTextAreaRef)}
                              style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  background: 'transparent',
                                  color: 'transparent',
                                  caretColor: '#f8f8f2',
                                  fontFamily: '"Source Code Pro", monospace',
                                  fontSize: '12px',
                                  lineHeight: '1.5',
                                  padding: '15px',
                                  border: 'none',
                                  outline: 'none',
                                  overflowY: 'hidden',
                                  resize: 'none',
                                  borderRadius: '10px',
                                  whiteSpace: 'pre'
                              }}
                              placeholder="Your JSON headers..."
                              value={eggHeaders}
                              onChange={e => {
                                  setEggHeaders(e.target.value);
                              }}
                              rows={3}
                          />
                      </div>
                  </div>

                  <div className="col-span-full">
                      <label htmlFor="eggPayload" className="block text-sm font-medium leading-6 text-gray-900">
                          Payload
                      </label>
                      <div className="mt-2" style={{ 
                              position: 'relative', 
                              overflowX: 'auto', 
                              overflowY: 'hidden', 
                              resize: 'none', 
                              minHeight: '100px', 
                              borderRadius: '10px', 
                              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', 
                          }}>
                          
                          <div 
                              ref={payloadSyntaxRef}
                              style={{ overflow: 'hidden' }}
                          >
                              <SyntaxHighlighter
                                  language="javascript"
                                  style={atomOneDark}
                                  customStyle={{
                                      padding: '15px',
                                      minHeight: '108px',  // Minimum height of 6 lines
                                      height: '100%',
                                      minWidth: 'max-content',
                                      borderRadius: '10px',
                                      resize: 'none',
                                      fontSize: '12px',
                                      lineHeight: '1.5',
                                      whiteSpace: 'pre',
                                  }}
                              >
                                  {eggPayload}
                              </SyntaxHighlighter>
                          </div>
                          
                          <textarea
                              type="text"
                              ref={payloadTextAreaRef}
                              name="eggPayload"
                              id="eggPayload"
                              autoComplete="eggPayload"
                              spellCheck="false"
                              onScroll={() => syncScrolling(payloadSyntaxRef, payloadTextAreaRef)}
                              style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  background: 'transparent',
                                  color: 'transparent',
                                  caretColor: '#f8f8f2',
                                  fontFamily: '"Source Code Pro", monospace',
                                  fontSize: '12px',
                                  lineHeight: '1.5',
                                  padding: '15px',
                                  border: 'none',
                                  outline: 'none',
                                  overflowY: 'hidden',
                                  resize: 'none',
                                  borderRadius: '10px',
                                  whiteSpace: 'pre'
                              }}
                              placeholder="Your JSON payload..."
                              value={eggPayload}
                              onChange={e => {
                                  setEggPayload(e.target.value);
                              }}
                              rows={3}
                          />
                      </div>
                  </div>




                  <div className="col-span-full">
                      <label htmlFor="eggExampleUsage" className="block text-sm font-medium leading-6 text-gray-900">
                          Example Usage
                      </label>
                      <div className="mt-2" style={{ 
                              position: 'relative', 
                              overflowX: 'auto', 
                              overflowY: 'hidden', 
                              resize: 'none', 
                              minHeight: '100px', 
                              borderRadius: '10px', 
                              boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)', 
                          }}>
                          
                          <div 
                              ref={exampleSyntaxRef}
                              style={{ overflow: 'hidden' }}
                          >
                              <SyntaxHighlighter
                                  language="javascript"
                                  style={atomOneDark}
                                  customStyle={{
                                      padding: '15px',
                                      minHeight: '108px',  // Minimum height of 6 lines
                                      height: '100%',
                                      minWidth: 'max-content',
                                      borderRadius: '10px',
                                      resize: 'none',
                                      fontSize: '12px',
                                      lineHeight: '1.5',
                                      whiteSpace: 'pre',
                                  }}
                              >
                                  {eggExampleUsage}
                              </SyntaxHighlighter>
                          </div>
                          
                          <textarea
                              type="text"
                              ref={exampleTextAreaRef}
                              name="eggExampleUsage"
                              id="eggExampleUsage"
                              autoComplete="eggExampleUsage"
                              spellCheck="false"
                              onScroll={() => syncScrolling(exampleSyntaxRef, exampleTextAreaRef)}
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'transparent',
                                color: 'transparent',
                                caretColor: '#f8f8f2',
                                fontFamily: '"Source Code Pro", monospace',
                                fontSize: '12px',
                                lineHeight: '1.5',
                                padding: '15px',
                                border: 'none',
                                outline: 'none',
                                overflowY: 'hidden',
                                resize: 'none',
                                borderRadius: '10px',
                                whiteSpace: 'pre'
                              }}
                              placeholder="Your JSON payload..."
                              value={eggExampleUsage}
                              onChange={e => {
                                  setEggExampleUsage(e.target.value);
                              }}
                              rows={3}
                          />
                      </div>
                  </div>



                    





                    <div className="col-span-full">
                      {/* Private Button  */}
                      <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Private' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Private' ? 'text-white' : 'text-gray-600'} shadow-sm hover:purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 w-1/2`}
                        onClick={() => handlePrivacyToggle('Private')}
                      >
                        &#128274;
                        Private
                      </button>

                      {/* Public Button */}
                      <button
                        type="button"
                        className={`inline-flex items-center gap-x-1.5 rounded-md ${privacyStatus === 'Public' ? 'bg-purple-800' : 'bg-gray-200'} px-2.5 py-1.5 text-sm font-semibold ${privacyStatus === 'Public' ? 'text-white' : 'text-gray-600'} shadow-sm hover:purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 w-1/2`}
                        onClick={() => handlePrivacyToggle('Public')}
                      >
                        &#127758;
                        Public
                      </button>
                    </div>


                  <div className="sm:col-span-4 flex items-center">
                    <label htmlFor="paidStatus" className="block text-sm font-medium leading-6 text-gray-900 mr-4 inline-flex items-center">
                        Paid
                    </label>
                      <Switch
                          checked={paidEgg}
                          onChange={setPaidEgg}
                          className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${paidEgg ? 'bg-purple-800 focus:ring-purple-800' : 'bg-gray-200'}`}
                      >
                          {/* <span className="sr-only">Use setting</span> */}
                          <span
                              aria-hidden="true"
                              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${paidEgg ? 'translate-x-5' : 'translate-x-0'}`}
                          />
                      </Switch>
                      <span className="ml-2 cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible1(true)} onMouseLeave={() => setTooltipVisible1(false)}>
                          <InformationCircleIcon className="h-6 w-6" /> {/* Here's the icon */}
                          {tooltipVisible1 && (
                              <div className="absolute -top-2 left-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56"> {/* Adjusted width here */}
                                  Earn money for your APIs! Get paid each time your API is used.
                              </div>
                          )}
                      </span>
                  </div>

                  {paidEgg && (
                    <div className="ml-4 col-span-full">
                      <div className="flex items-center">
                        <label htmlFor="eggPrice" className="text-sm font-medium leading-6 text-gray-900 mr-4">
                        Egg Price (in sats)
                        </label>

                        <div className="flex-grow flex items-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 mr-4">
                          <input
                          type="number"
                          name="eggPrice"
                          id="eggPrice"
                          autoComplete="eggPrice"
                          className="flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0"
                          placeholder="Vectorstore Price"
                          value={eggPrice}
                          onChange={onEggPriceChange}
                          />
                        </div>

                        <span className="cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible2(true)} onMouseLeave={() => setTooltipVisible2(false)}>
                          <InformationCircleIcon className="h-6 w-6" />
                          {tooltipVisible2 && (
                            <div className="absolute -top-2 right-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-56">
                              This is what you will earn each time your API is used.
                            </div>
                          )}
                        </span>
                      </div>

                      {PriceError && (
                        <div className="text-red-500 text-sm">
                          {PriceError}
                        </div>
                      )}

                    </div>
                  )}

                  {paidEgg && (
                    <div className="ml-4 col-span-full">
                      <div className="flex items-center">
                        <label htmlFor="eggOwnerLnAddress" className="text-sm font-medium leading-6 text-gray-900 mr-4">
                        Lightning Address
                        </label>

                        <div className="flex items-center flex-grow rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-800 mr-4">
                          <input
                          type="text"
                          name="eggOwnerLnAddress"
                          id="eggOwnerLnAddress"
                          autoComplete="eggOwnerLnAddress"
                          className="w-full flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 m-0"
                          placeholder="example@getalby.com"
                          value={eggOwnerLnAddress}
                          onChange={onEggOwnerLnAddressChange}
                          />
                        </div>

                        <span className="cursor-pointer relative z-10" onMouseEnter={() => setTooltipVisible3(true)} onMouseLeave={() => setTooltipVisible3(false)}>
                          <InformationCircleIcon className="h-6 w-6" />
                          {tooltipVisible3 && (
                          <div className="absolute -top-2 right-6 ml-2 bg-purple-900 text-white p-2 rounded shadow text-xs w-64">
                          The lightning address is where we will send you the funds.
                          <br />
                          <br />
                          Don't have an address? Not a problem, get an Alby wallet and get an address! 
                          <br />
                          <br />
                          It needs to be NIP-05 verified. Just follow <a href="https://guides.getalby.com/alby-guides/alby-lightning-account/features/use-your-lightning-address-as-identifier-on-nostr"><strong>this Alby guide</strong></a>
                          </div>
                          )}
                        </span>
                      </div>

                      {lnAddressError && (
                        <div className="text-red-500 text-sm">
                          {lnAddressError}
                        </div>
                      )}

                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={() => on_upload_cancel('view')}
              >
                Cancel
              </button>
              <button
                // disabled={!isFormValid()} 
                type="button"
                className="rounded-md bg-purple-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onEggUpload}
              >
                Save
              </button>
              <span className="cursor-pointer relative z-10" onMouseLeave={() => setFeedbackMessage('')}>
                  {feedbackMessage && (
                  <div className="absolute -top-12 right-10 ml-2 border-2 border-red-700 bg-red-100 text-gray-800 p-2 rounded shadow text-xs w-64">
                    Please fill in the form properly before submitting.
                  </div>
                  )}
              </span>
            </div>
          </form>
      </div>
    </div>
  )
}

export default CreateEgg;
