import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import PayFromQRCode from './PayFromQRCode'; // Ensure this is imported

function PayFromQRCodeReader({ toggleContentVisibility, showQRReader, setShowQRReader }) {
  const [scanResult, setScanResult] = useState('');
  const [showPaymentComponent, setShowPaymentComponent] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalUrl, setModalUrl] = useState('');

  const handleScan = data => {
    if (data) {
      const lowerCaseData = data.toLowerCase();
      if (lowerCaseData.startsWith('ln')) {
        setScanResult(lowerCaseData);
        setShowPaymentComponent(true);
        setShowQRReader(false);
      } else if (isValidHttpUrl(lowerCaseData)) {
        setModalUrl(lowerCaseData);
        setShowModal(true);
        setShowQRReader(false); // Hide QR code reader when showing the modal
      }
    }
  };

  const handleError = err => {
    console.error(err);
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleConfirm = () => {
    window.open(modalUrl, '_blank');
    setShowModal(false);
    setShowQRReader(false); // Show QR Reader again
    toggleContentVisibility()
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowQRReader(false); // Show QR Reader again
    toggleContentVisibility(); // Call toggleContentVisibility on cancel
  };

  // Adjusted SimpleModal component for TailwindCSS styling
  const SimpleModal = ({ isVisible, onConfirm, onCancel, url }) => isVisible ? (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-semibold leading-6 text-gray-900">Open URL</h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>This QR code contains a URL. Do you want to open it?</p>
              <p className="break-all mt-2"><a href={url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">{url}</a></p>
            </div>
            <div className="mt-5 flex justify-end gap-3">
              <button
                onClick={onCancel}
                className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Open
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  return (
    <div>
      {showQRReader && (
        <div className='flex flex-col items-center'>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
            facingMode="environment"
          />
          <button 
            onClick={toggleContentVisibility} 
            className="mt-4 px-4 py-2 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            style={{ position: 'relative', zIndex: 2 }} // Ensure button is clickable and visible
          >
            Cancel
          </button>
        </div>
      )}
      {showPaymentComponent && <PayFromQRCode invoice={scanResult} toggleContentVisibility={toggleContentVisibility} />}
      <SimpleModal 
        isVisible={showModal} 
        onConfirm={handleConfirm} 
        onCancel={handleCancel} 
        url={modalUrl} 
      />
    </div>
  );
}

export default PayFromQRCodeReader;
