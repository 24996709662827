import React, { useState, useEffect } from 'react';

const formatPriceWithDiscount = (price, discount) => {
    const numericPrice = Number(price);
    const finalPrice = discount ? numericPrice * (1 - Number(discount) / 100) : numericPrice;
    return finalPrice;
};

const formatDollarPrice = (price) => {
    // Ensure the price is formatted with two decimal places
    return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};

const formatSatoshiPrice = (price) => {
    // Satoshi prices are integers but you might want to include commas for readability
    return price.toLocaleString('en-US') + ' sats';
};

const formatZlotyPrice = (price) => {
    // Satoshi prices are integers but you might want to include commas for readability
    return price.toLocaleString('en-US') + ' PLN';
};


const ProductDetails = ({ product, isLoading }) => {
    const [category, setCategory] = useState('');

    useEffect(() => {
        const fetchCategory = async () => {
            if (product && product.CategoryID) {
                try {
                    const response = await fetch(`https://api.paywithflash.com/api/get_category_by_id/${product.CategoryID}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch category');
                    }
                    const data = await response.json();
                    setCategory(data.CategoryName);
                } catch (error) {
                    console.error('Error fetching category:', error);
                    // Handle the error according to your needs, e.g., setCategory to "Unknown Category" or leave it blank
                }
            }
        };

        fetchCategory();
    }, [product]);


    if (isLoading) return <div>Loading...</div>;

const hasDiscount = product.Discount && Number(product.Discount) > 0;
const originalPriceInDollars = formatDollarPrice(Number(product.PriceInDollars));
const originalPriceInSatoshis = formatSatoshiPrice(Number(product.PriceInSatoshis));
const originalPriceInZloty = formatZlotyPrice(Number(product.PriceInZloty));
const discountedPriceInDollars = hasDiscount ? formatDollarPrice(formatPriceWithDiscount(product.PriceInDollars, product.Discount)) : null;
const discountedPriceInSatoshis = hasDiscount ? formatSatoshiPrice(formatPriceWithDiscount(product.PriceInSatoshis, product.Discount)) : null;
const discountedPriceInZloty = hasDiscount ? formatZlotyPrice(formatPriceWithDiscount(product.PriceInSatoshis, product.Discount)) : null;


    return (
        <>
    <div className="uppercase tracking-wide flex w-full justify-start text-3xl text-gray-800 font-bold">{product.Name} </div>
    <div className="mt-1 flex flex-col items-baseline w-full justify-start">
        {hasDiscount ? (
            <>
                <p className="text-slate-900 font-medium line-through text-3xl font-bold">US{originalPriceInDollars}</p>
                <p className="text-slate-900 text-2xl font-bold ml-1">US{discountedPriceInDollars}</p>
                <p className="text-gray-600 text-lg font-bold ml-1">{discountedPriceInSatoshis} / {discountedPriceInZloty}</p>
            </>
        ) : (
            <>
                <p className="text-slate-900 font-medium text-3xl font-bold">US{originalPriceInDollars}</p>
                <p className="text-gray-600 font-medium text-lg font-bold">{originalPriceInSatoshis}</p>
            </>
        )}
        {product.TaxRate > 0 && (
            <p className="text-slate-400 text-xs font-medium text-base font-bold"> (excl. taxes {product.TaxRate}%)</p>
        )}
    </div>
    {/* More product details go here */}
</>

    );
};

export default ProductDetails;
