import React, { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { cardConfigs } from './CardsList'; // Adjust this path to where your cardConfig file is located

// Helper function for classNames
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Info Icon SVG
const InfoIcon = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-5 h-5 text-gray-600 cursor-pointer hover:text-gray-400 absolute top-0 right-0"
    onClick={onClick}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
  </svg>
);

// Card Component
const Card = ({ action, onCardClick, onIconClick, gifUrl, gifText }) => {
  const [isHovering, setIsHovering] = useState(false);
  const iconRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={classNames(
        'flex flex-col bg-white shadow p-5 focus-within:ring-2 h-40 justify-between focus-within:ring-inset focus-within:ring-indigo-500 rounded-lg',
        'cursor-pointer hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1'
      )}
      onClick={() => onCardClick(action.id)}
    >
      <div className="flex justify-between items-center">
        <span
          className={classNames(
            action.iconBackground,
            action.iconForeground,
            'inline-flex rounded-xl p-3 ring-4 ring-white mt-1'
          )}
        >
          <action.icon className="h-5 w-5" aria-hidden="true" />
        </span>
        <div
          className="absolute top-5 right-4"
          ref={iconRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={(e) => {
            e.stopPropagation();
            onIconClick(action.id);
          }}
        >
          <InfoIcon />
        </div>
      </div>
      <div className="mt-5">
        <h4 className="text-md font-semibold text-slate-900 ">{action.title}</h4>
      </div>
    </div>
  );
};

// Cards Component
export const Cards = ({ actions, title, gridCols }) => {
  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);

const gridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 4fr))', // Adjust the min value as per your card's minimum width
  gap: '1rem',
  '@media (min-width: 600px)': { // Adjust the breakpoint as needed
    gridTemplateColumns: 'repeat( minmax(250px, 4fr))',
  },
};

  const openModal = (actionId) => {
    const action = actions.find((a) => a.id === actionId);
    if (action) {
      setSelectedAction(action);
      setOpen(true);
    }
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedAction(null);
  };

  const openFormModal = (actionId) => {
    const action = actions.find((a) => a.id === actionId);
    if (action) {
      setSelectedAction(action);
      setShowFormModal(true);
    }
  };

  const closeFormModal = () => {
    setShowFormModal(false);
    setSelectedAction(null);
  };

  return (
    <Fragment>
      <div className="mb-4">
        {/* <h4 className="text-sm font-medium mb-4 text-slate-900 opacity-100">{title}</h4> */}
        <div className=' grid grid-cols-1 md:grid-cols-4 gap-4 '>
          {actions.map((action) => (
            <Card
              key={action.id}
              action={action}
              onCardClick={openModal}
              onIconClick={openFormModal}
              gifUrl={action.gifUrl}
              gifText={action.gifText}
            />
          ))}
        </div>
      </div>

      {/* Modal Logic */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-500" onClose={closeModal}>
          <div className="fixed inset-0 bg-slate-500 bg-opacity-50 transition-opacity z-50" />
          <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 z-50">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-0 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-0 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 modal-base modal-pop">
                  {selectedAction?.formComponent ? React.createElement(selectedAction.formComponent, { close: closeModal }) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Form Modal Logic */}
<Transition.Root show={showFormModal} as={Fragment}>
  <Dialog as="div" className="relative z-500" onClose={closeFormModal}>
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50" />
    <div className="fixed inset-0 overflow-y-auto z-50">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0 z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="relative z-50 transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl max-h-[80vh]">
            <button
              className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
              onClick={closeFormModal}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
            {selectedAction ? (
              <>
                <p className="text-gray-700 mb-4 font-bold">{selectedAction.gifText}</p>
                <div className="flex justify-center items-center">
                  <div className="max-w-full max-h-full">
                    <img src={selectedAction.gifUrl} alt="Info GIF" className="rounded-lg max-h-[calc(80vh-4rem)] max-w-full w-auto h-auto" />
                  </div>
                </div>
              </>
            ) : null}
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition.Root>



    </Fragment>
  );
};

// The page where you use the Cards component
export function CardsPage({ actions, title, gridCols }) {
  return (
    <div>
      <Cards
        actions={actions}
        title={title}
        gridCols={gridCols}
      />
    </div>
  );
}
