import { nip04, getPublicKey, getEventHash, getSignature, relayInit, SimplePool } from 'nostr-tools';
import axios from 'axios'; // for calling API
import {
    getProfileMetadata,
    extractProfileMetadataContent,
    getZapEndpoint,
    fetchInvoice,
    listenForZapReceipt,
  } from '../utils/nostr/ZapFunctions';
  import { Invoice } from "alby-tools";
import GetVectorstores from './GetVectorstores';

const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

export let relayConnections;

export const connectToRelays = async (relayUrls = []) => {
    const defaultRelayUrl = "wss://relay.paywithflash.com";
    // const defaultRelayUrl = "wss://nostr-pub.wellorder.net";
    
    // Add the default relay URL to the user-provided URLs and remove duplicates
    const uniqueUrls = Array.from(new Set([...relayUrls, defaultRelayUrl]));
  
    const pool = new SimplePool();
    relayConnections = await Promise.all(uniqueUrls.map(async (url) => {
      const relay = relayInit(url);
      await relay.connect();
      
      relay.on('connect', () => {
        console.log(`Connected to ${relay.url}`);
        // const sub = pool.sub([relay.url], [{ kinds: [4] }]);
        // const current_time = Math.floor(Date.now() / 1000);
        // receiveEggRequest(sub, current_time);
      });
      
      relay.on('error', () => {
        console.log(`Failed to connect to ${relay.url}`);
      });
  
      return relay;
    }));
    
    // Store relayConnections in session storage
    localStorage.setItem('relayConnections', JSON.stringify(relayConnections));
    // Return the relayConnections
    return relayConnections;
  };
  



export const receiveEggRequest = async (sub, current_time) => {
    let parsedData = null;
    sub.on('event', async (event) => {
        try {
            // Check if the event is a direct message and intended for you
            if (
                event.kind === 4 &&
                event.tags.some(([tag, value]) => tag === 'p' && value === publicKey) &&
                event.created_at > current_time &&
                event.tags.some(([tag]) => tag === 'EggID') &&
                !event.tags.some(([tag]) => tag === 'requestCost')
            ) {
                // Get the Egg id
                const EggID = event.tags.find(([tag]) => tag === 'EggID')[1];
                
                // Try to decrypt the message
                const plaintext = await nip04.decrypt(privateKey, event.pubkey, event.content);
                console.log('Received direct message:', plaintext);

                parsedData = JSON.parse(plaintext);

                // Get the name of the function requested
                const functionName = parsedData.function;
                // Get the value of paid_by to pass to sendZapInvoiceDirectMessage()
                const paid_by = parsedData.paid_by;

                // declare result and requestCost here
                let result;
                let requestCost;
                let FullApiResponse;
                
                // Check if the data has a vectorstoreID
                if (parsedData.data.vectorstoreID) {
                    // Get the vectostore_id from the json
                    const vectorstoreID = parsedData.data.vectorstoreID;
                    // Call our react function  getVectorstore(vectorstoreID, EggID)
                    const result = await getVectorstore(vectorstoreID); // Remove 'const'

                    const vectorstore = result[0];
                    const pageContents = result[1];
                    console.log('vectorstore: ', vectorstore);
                    console.log('pageContents: ', pageContents);

                    // Update the data element of the received json
                    // Create a copy of the 'data' object
                    const newData = { ...parsedData.data };
                    // Add a new element to the copy
                    newData.vectorstore = vectorstore;
                    newData.pageContents = pageContents;
                    // Update the variable with the modified copy
                    parsedData = { ...parsedData, data: newData }; // <-- Set variable

                    console.log('parsedData: ', parsedData)

                    const jsonData = JSON.stringify(parsedData);

                    FullApiResponse = await callAPI(jsonData);
                    result = FullApiResponse.result; // Remove 'const'
                    requestCost = FullApiResponse.requestCost; // Remove 'const'
                    console.log('result: ', result);
                } else {
                    // Call the Flask App API
                    FullApiResponse = await callAPI(plaintext);
                    result = FullApiResponse.result; // Remove 'const'
                    requestCost = FullApiResponse.requestCost; // Remove 'const'
                }

                const normalizedRelays = ['wss://relay.paywithflash.com']
                if (result) {
                    
                    console.log("YO");
                    // const requestCost = 10000;
                    const zapInvoice = await createZapInvoice(publicKey, requestCost, "NostrEgg invoice. EggID: " + EggID, normalizedRelays);

                    sendZapInvoiceDirectMessage(privateKey, event.pubkey, zapInvoice, EggID, requestCost, paid_by);

                    const pool = new SimplePool();
                    relayConnections = await Promise.all(normalizedRelays.map(async (url) => {
                        const relay = relayInit(url);
                        await relay.connect();
                        return relay;
                    }));

                    relayConnections.forEach(async (relay) => {
                        relay.on('connect', async () => {
                          const sub = pool.sub([relay.url], [{ kinds: [4] }]);
                          const current_time = Math.floor(Date.now() / 1000);
                          const zap_received = await listenForZapReceipt( sub, EggID );
                          if (zap_received) {
                            sendEggResponse(privateKey, event.pubkey, result, EggID);
                          }
                        });
                        relay.on('error', () => {
                          console.log(`Failed to connect to ${relay.url}`);
                        });
                      });
                }
            }
        } catch (error) {
            console.error('Error handling event:', error.message, error);
        }
    });
};



export const getVectorstore = async (vectorstoreID) => {
    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    const nostrEggsVectorstorePublicKey = process.env.REACT_APP_NOSTR_EGGS_VECTORSTORE_PUBLIC_KEY;
    const nostrEggsVectorstorePrivateKey = process.env.REACT_APP_NOSTR_EGGS_VECTORSTORE_PRIVATE_KEY;

    const pool = new SimplePool();
    // Create an empty Set to store unique vectorstoreIDs
    let vectorstore = [];
    let pageContents = [];

    if (relayConnections) {
        const relays = relayConnections.map(connection => connection.url);

        let events = await pool.list(relays, [{
            kinds: [4],
            ['#p']: [nostrEggsVectorstorePublicKey],
            // ['#vectorstoreID']: [vectorstoreID],
        }]);
        console.log('events: ', events)

        await Promise.all(events.filter((event) => {
            // Get the vectorstoreID from the tags array
            const vectorstoreTag = event.tags.find(tag => tag[0] === 'vectorstoreID');
            const eventVectorstoreID = vectorstoreTag ? vectorstoreTag[1] : null;
            // Only process events that match the provided vectorstoreID
            return eventVectorstoreID === vectorstoreID;
        }).map((event) => {
            // Rest of the map function as before...
            return nip04.decrypt(nostrEggsVectorstorePrivateKey, event.pubkey, event.content)
            .then(result => {
                const json_result = JSON.parse(result)
                console.log(json_result)
                // Parse the vectors string into an array and spread it into vectorstore
                vectorstore.push(json_result.vectors)
                // Push the pageContent into pageContents
                pageContents.push(json_result.pageContents);
            })
            .catch(err => console.error(`Error in event processing: ${err}`));
        }));
    }
    console.log(vectorstore);
    console.log(pageContents)
    return [vectorstore, pageContents];
};





export const sendZapInvoiceDirectMessage = async (privateKey, recipientPublicKey, invoice, EggID, cost_of_request, paid_by) => {
    try {
        const senderPublicKey = getPublicKey(privateKey);
        const cipherinvoice = await nip04.encrypt(privateKey, recipientPublicKey, invoice);
        const requestCost = cost_of_request.toString();

        let event = {
            kind: 4,
            pubkey: senderPublicKey,
            tags: [['p', recipientPublicKey],['invoice', 'invoice'], ['EggID', EggID], ['requestCost', requestCost], ['paid_by', paid_by]],
            content: cipherinvoice,
            created_at: Math.floor(Date.now() / 1000),
        };

        event.id = getEventHash(event);
        event.sig = getSignature(event, privateKey);
        
        relayConnections.forEach((relay) => {
            relay.on('connect', () => {
                let pub = relay.publish(event);
                pub.on('ok', () => {
                    console.log(`${relay.url} has accepted our zap invoice send`);
                });
                return true
            });
            relay.on('error', () => {
                console.log(`Failed to publish zap invoice send to ${relay.url}`);
            });
        });
    } catch (error) {
        console.error('Error sending direct message:', error.message, error);
    }
};




export const sendEggResponse = async (privateKey, recipientPublicKey, message, EggID) => {
    try {
        const senderPublicKey = getPublicKey(privateKey);
        const jsonDataString = JSON.stringify(message);
        const ciphertext = await nip04.encrypt(privateKey, recipientPublicKey, jsonDataString);

        let event = {
            kind: 4,
            pubkey: senderPublicKey,
            tags: [['p', recipientPublicKey], ['u', publicKey], ['EggID', EggID]],
            content: ciphertext,
            created_at: Math.floor(Date.now() / 1000),
        };

        event.id = getEventHash(event);
        event.sig = getSignature(event, privateKey);

        relayConnections.forEach((relay) => {
            relay.on('connect', () => {
                let pub = relay.publish(event);
                pub.on('ok', () => {
                    console.log(`${relay.url} has accepted our egg response`);
                });
            });
            relay.on('error', () => {
                console.log(`Failed to publish egg response to ${relay.url}`);
            });
        });
    } catch (error) {
        console.error('Error sending direct message:', error.message, error);
    }
};




export const createZapInvoice = async (pubKey, amount, comment, normalizedRelays) => {
    const authorId = pubKey;

    const profileMetadata = await getProfileMetadata(authorId);
    const metadataContent = extractProfileMetadataContent(profileMetadata);
    const zapEndpoint = await getZapEndpoint(profileMetadata);

    const invoice = await fetchInvoice({
        zapEndpoint: zapEndpoint,
        amount,
        comment,
        authorId,
        noteId: undefined,
        normalizedRelays,
    });
    
    return invoice;
};



export const receiveZapConfirmation = (sub, EggID) => {
    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
  
    return new Promise((resolve, reject) => {
      const handler = async (event) => {
        try {
          if (
            event.kind === 4 &&
            event.tags.some(([tag, value]) => tag === 'p' && value === publicKey) &&
            event.tags.some(([tag, value]) => tag === 'EggID' && value === EggID) &&
            event.tags.some(([tag]) => tag === 'preimage')
          ) {
            const plaintext = await nip04.decrypt(privateKey, event.pubkey, event.content);
            const jsonPlaintext = JSON.parse(plaintext); // Parse the decrypted plaintext as JSON
            resolve(jsonPlaintext);
            
            // Once we got the response, remove this event listener
            sub.off('event', handler);
            return true
          }
        } catch (error) {
          console.error('Error handling event:', error.message, error);
          reject(error);
        }
      };
      // Register the event handler
      sub.on('event', handler);
    });
  };

  






export async function callAPI(content) {
    const requestOptions = {
        method: 'POST',
        body: content,
    };
    try {
        const response = await axios.post('http://127.0.0.1:5000/api/get_eggs', requestOptions); // replace with your API endpoint
        if (response.data) {
            console.log(response.data)
            return response.data;
        }
    } catch (error) {
        console.error('API call failed:', error);
    }
};




export const checkInvoicesSent = async (userPublicAddress) => {
    let totalRequestCostUser = 0;
    let totalRequestCostPublicKey = 0;
    const current_time = Math.floor(Date.now() / 1000);

    const pool = new SimplePool();

    // Create a promise for each relay connection
    const relayPromises = relayConnections.map((relay) =>
        new Promise((resolve, reject) => {
            relay.on("connect", () => {
                const sub = pool.sub([relay.url], [{ kinds: [4] }]);

                sub.on("event", async (event) => {
                    try {
                        // First set of conditions for userPublicAddress
                        if (
                            event.kind === 4 &&
                            event.pubkey === userPublicAddress &&
                            event.created_at < current_time &&
                            event.tags.some(([tag, value]) => tag === "p" && value === publicKey) &&
                            event.tags.some(([tag]) => tag === "EggID") &&
                            event.tags.some(([tag]) => tag === "requestCost")
                        ) {
                            const requestCostTag = event.tags.find(([tag]) => tag === "requestCost");
                            const requestCost = Number(requestCostTag[1]);

                            totalRequestCostUser += requestCost;
                        }

                        // Second set of conditions for publicKey
                        if (
                            event.kind === 4 &&
                            event.pubkey === publicKey &&
                            event.created_at < current_time &&
                            event.tags.some(([tag, value]) => tag === "p" && value === userPublicAddress) &&
                            event.tags.some(([tag]) => tag === "EggID") &&
                            event.tags.some(([tag]) => tag === "requestCost")
                        ) {
                            const requestCostTag = event.tags.find(([tag]) => tag === "requestCost");
                            const requestCost = Number(requestCostTag[1]);

                            totalRequestCostPublicKey += requestCost;
                        }
                    } catch (error) {
                        console.error("Error handling event:", error.message, error);
                    }
                });

                // Wait a bit for events to propagate, then resolve the promise
                setTimeout(() => resolve(), 5000);
            });

            relay.on("error", (error) => {
            console.log(`Failed to connect to ${relay.url}`);
            reject(error);
            });
        })
    );

    // Wait for all relay connections to process
    await Promise.all(relayPromises);

    // return the difference
    return totalRequestCostUser - totalRequestCostPublicKey;
};

  

export const receiveDirectMessage = async (sub, current_time) => {
    const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
    const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;
    return new Promise((resolve, reject) => {
        sub.on('event', async (event) => {
            try {
                // Check if the event is a direct message and intended for you
                if (
                    event.kind === 4 &&
                    event.tags.some(([tag, value]) => tag === 'p' && value === publicKey) &&
                    !event.tags.some(([tag]) => tag === 'EggID') &&
                    event.created_at > current_time
                ) {
                    // Try to decrypt the message
                    const plaintext = await nip04.decrypt(privateKey, event.pubkey, event.content);
                    // Convert the JSON string back to a JavaScript object
                    // const jsonObject = JSON.parse(plaintext);
                    console.log('Received direct message:', plaintext);
                    resolve(plaintext);
                }
            } catch (error) {
                console.error('Error handling event:', error.message, error);
                reject(error);
            }
        });
    });
};
