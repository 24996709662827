import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPublicKey, generatePrivateKey, nip04 } from 'nostr-tools';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19 } from 'nostr-tools';

const { npubEncode, nsecEncode } = nip19;

const convertHexToNostr = async (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

const createPrivateKey = async () => {
  const newPrivateKey = await generatePrivateKey();
  const publicKey = await getPublicKey(newPrivateKey);
  const nsec = await convertHexToNostr(newPrivateKey, 'nsec');
  const npub = await convertHexToNostr(publicKey, 'npub');

  localStorage.setItem('userHexPrivateKey', newPrivateKey);
  localStorage.setItem('userHexPublicKey', publicKey);
  localStorage.setItem('userNsec', nsec);
  localStorage.setItem('userNpub', npub);

  return { newPrivateKey, publicKey };
};

const encryptUserPrivateKey = async (flashPrivateKey, userPublicKey, userPrivateKey) => {
  const encryptedUserPrivateKey = await nip04.encrypt(flashPrivateKey, userPublicKey, userPrivateKey);
  return encryptedUserPrivateKey;
};

const checkUserEmailAddressInDb = async (emailAddress) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({ email: emailAddress })
  };

  return fetch('https://api.paywithflash.com/api/check_email', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => data)
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    });
};

const signupUser = async (email, password, encryptedUserPrivateKey, publicKey, referralPublicKey) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      email,
      password,
      privateKey: encryptedUserPrivateKey,
      publicKey,
      referralPublicKey: referralPublicKey == null ? '': referralPublicKey,
    })
  };

  return fetch('https://api.paywithflash.com/api/signup', requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data.message);
      return data;
    })
    .catch(error => {
      console.error('There was an error during the signup:', error);
    });
};

function SignUpWithEmail({ referralPublicKey }) {
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);


  const navigate = useNavigate();

  const doPasswordsMatch = () => {
    return password === repeatPassword;
  };

  const verifyKeyPair = async (privateKey, publicKey) => {
    const generatedPublicKey = await getPublicKey(privateKey);
    return generatedPublicKey === publicKey;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
  
    if (!acceptedTerms) {
      setErrorMessage('You must accept the terms and conditions to proceed.');
      setLoading(false);
      return;
    }
  
    if (!doPasswordsMatch()) {
      setErrorMessage('Passwords do not match.');
      setLoading(false);
      return;
    }
  
    try {
      const { user_id, public_key, email_exists, is_verified } = await checkUserEmailAddressInDb(email);
      if (email_exists) {
        if (is_verified) {
          setErrorMessage('An account with this email address already exists, try logging in instead');
          setLoading(false);
          return;
        }
        else {
          navigate ('/please-verify', {state: {userId: user_id, email: email}});
        }
      }
  
      const { newPrivateKey, publicKey } = await createPrivateKey();
  
      // Verify key pair validity
      if (!(await verifyKeyPair(newPrivateKey, publicKey))) {
        throw new Error('Generated key pair is invalid');
      }
  
      const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPrivateKey, publicKey, newPrivateKey);
  
      const signupResult = await signupUser(email, password, encryptedUserPrivateKey, publicKey, referralPublicKey);
      if (signupResult && signupResult.user_id) {
        localStorage.setItem('userEmail', email);
        navigate ('/please-verify', {state : {userId: signupResult.user_id, email: email }});
      } else {
        setErrorMessage('Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Signup error:', error);
      setErrorMessage('An error occurred during signup. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex items-center">
          <h2 className="text-left text-2xl font-bold leading-9 tracking-tight text-slate-900">
            Create an account
          </h2>
        </div>
        <p className="text-sm font-light text-slate-900">
          Start accepting Bitcoin payments.
        </p>
        <div className="mt-2">
          <form className="space-y-4" onSubmit={handleSignup}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-900">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder='satoshi@nakamoto.com'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-slate-900">
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  name="password"
                  placeholder='•••••••••••••'
                  type={showPassword ? 'text' : 'password'}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
                />
                <button
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-slate-900 hover:text-slate-700 eye-icon"
                >
                  <FaEye />
                </button>
              </div>
            </div>
            <div>
              <label htmlFor="repeatPassword" className="block text-sm font-medium leading-6 text-slate-900">
                Repeat Password
              </label>
              <div className="mt-1 relative">
                <input
                  id="repeatPassword"
                  name="repeatPassword"
                  placeholder='•••••••••••••'
                  type={showRepeatPassword ? 'text' : 'password'}
                  required
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  className="mt-1 block w-full rounded-md bg-white/5 py-1.5 text-slate-900 border border-slate-200 ring-1 ring-inset ring-white/10 sm:text-sm sm:leading-6"
                />
                <button
                  onClick={toggleRepeatPasswordVisibility}
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-slate-900 hover:text-slate-700 eye-icon"
                >
                  <FaEye />
                </button>
              </div>
              {errorMessage && <div className="text-red-500 mt-2 text-sm">{errorMessage}</div>}
            </div>
            <div className="flex items-center">
              <input
                id="terms_and_conditions"
                name="terms_and_conditions"
                type="checkbox"
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="terms_and_conditions" className="ml-2 block text-sm text-slate-900">
                I accept the <a href="/terms" className="text-indigo-800">Terms and Conditions</a>
              </label>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-slate-900 px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-slate-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Create my account
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignUpWithEmail;
