import React, { useState, useEffect } from 'react';
import { getPublicKey, generatePrivateKey, nip04 } from 'nostr-tools';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { EyeIcon as FaEye } from '@heroicons/react/24/outline';
import { nip19 } from 'nostr-tools';
import { SimplePool } from 'nostr-tools';

const { npubEncode, nsecEncode } = nip19;

// Function to convert the hex keys to their nsec and npub equivalents
const convertHexToNostr = (hex, type) => {
  if (type === 'npub') {
    return npubEncode(hex);
  } else if (type === 'nsec') {
    return nsecEncode(hex);
  } else {
    throw new Error('Invalid type. Type must be "npub" or "nsec".');
  }
};

// Function to create new keys and set session storage
const createPrivateKey = async () => {
  const newPrivateKey = generatePrivateKey();
  const publicKey = getPublicKey(newPrivateKey);
  const nsec = convertHexToNostr(newPrivateKey, 'nsec');
  const npub = convertHexToNostr(newPrivateKey, 'npub');

  // Store keys in session storage
  localStorage.setItem('userHexPrivateKey', newPrivateKey);
  localStorage.setItem('userHexPublicKey', publicKey);
  localStorage.setItem('userNsec', nsec);
  localStorage.setItem('userNpub', npub);

  return { newPrivateKey, publicKey }; // Return the user hex keys
};

// Function to encode the user private key using nip04 before saving it to db
const encryptUserPrivateKey = async (flashPublicKey, userPrivateKey) => {
  const encryptedUserPrivateKey = await nip04.encrypt(userPrivateKey, flashPublicKey, userPrivateKey);

  return encryptedUserPrivateKey;
}


const checkUserEmailAddressInDb = async (emailAddress) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ email: emailAddress })
  };

  return fetch('https://api.paywithflash.com/api/check_email', requestOptions)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          return data.email_exists;
      })
      .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
      });
}



const signupUser = async (email, password, encryptedUserPrivateKey, publicKey) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({ 
          email: email,
          password: password,
          privateKey: encryptedUserPrivateKey,
          publicKey: publicKey 
      })
  };

  return fetch('https://api.paywithflash.com/api/signup', requestOptions)
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          return data;
      })
      .catch(error => {
          console.error('There was an error during the signup:', error);
      });
}

const fetchUserMetadata = async (publicKey, relayUrls) => {
  const pool = new SimplePool();

  const filters = [{
    pubkey: publicKey,
    kinds: [0]
  }];

  try {
    const events = await pool.list(relayUrls, filters);

    const userMatchingEvents = events.filter(event => event.pubkey === publicKey);
    if (userMatchingEvents.length > 0) {
      // Process the array of matching events
    } else {
      console.log('No events found with the matching public key.');
    }

    // Sort the events by the 'created_at' timestamp in descending order
    const sortedEvents = userMatchingEvents.sort((a, b) => b.created_at - a.created_at);

    // Find the first event with non-empty content
    const metadataEvent = sortedEvents.find(event => event.content && event.content !== "{}");
    if (metadataEvent) {
      const metadata = JSON.parse(metadataEvent.content);
      // Store user metadata in variables
      const userName = metadata.name;
      const userPictureUrl = metadata.picture;
      const userAbout = metadata.about;

      return { userName, userPictureUrl, userAbout };
    } else {
      console.log('No metadata found for the given public key.');
      return { 
        userName: 'Unknown User', 
        userPictureUrl: 'default_picture_url', // Replace with a default picture URL
        userAbout: 'No description available' 
      };
    }
  } catch (error) {
    console.error('An error occurred while fetching user metadata:', error);
    throw error;
  }
};


const getFlashPublicKey = async () => {
  try {
    // Make a GET request to the API endpoint
    const response = await fetch('https://nostr-eggs-server-skphk.ondigitalocean.app/api/getFlashPublicKey', {
      method: 'GET',
      headers: {
        // You can add headers if needed, such as authentication headers
      },
    });

    // Check if the response is successful (status code 200)
    if (response.ok) {
      // Parse the JSON response to get the public key
      const data = await response.json();
      return data.publicKey;
    } else {
      // Handle the error if the response status is not 200
      throw new Error(`API call failed with status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in getFlashPublicKey:', error);
    throw error;
  }
}






function SignUpWithEmail(props) {

  const { appName, imageUrl, onLoginSuccess, onClose } = props;

  const [flashPublicKey, setFlashPublicKey] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  // Hook to get the flash public key
  useEffect(() => {
    async function fetchPublicKey() {
      try {
        const retrievedPublicKey = await getFlashPublicKey();
        setFlashPublicKey(retrievedPublicKey);
      } catch (error) {
        console.error('Error fetching flash public key:', error);
      }
    }

    fetchPublicKey();
  }, []);
  

  // const navigate = useNavigate();

  const doPasswordsMatch = () => {
    return password === repeatPassword;
  };
  


  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
  
    if (!acceptedTerms) {
      setErrorMessage('You must accept the terms and conditions to proceed.');
      setLoading(false);
      return;
    }
  
    if (!doPasswordsMatch()) {
      setErrorMessage('Passwords do not match.');
      setLoading(false);
      return;
    }

    setTimeout(async () => {
      try {
        // Check if email exists in the database
        const emailExists = await checkUserEmailAddressInDb(email);
        if (emailExists) {
          setErrorMessage('An account with this email address already exists, try logging in instead');
          setLoading(false);
          return;
        }
  
        // Create new keys for the user
        const keysResult = await createPrivateKey();
        const userPrivateKey = keysResult.newPrivateKey;
        const userPublicKey = keysResult.publicKey;


  
        // Encrypt the user private key
        const encryptedUserPrivateKey = await encryptUserPrivateKey(flashPublicKey, userPrivateKey);
  
        // Sign up the user
        const signupResult = await signupUser(email, password, encryptedUserPrivateKey, userPublicKey);
        if (signupResult && signupResult.user_id) {
          // Store values in session storage and redirect
          localStorage.setItem('userEmail', email);

          const { userName, userPictureUrl, userAbout } = await fetchUserMetadata(userPublicKey, ["wss://relay.paywithflash.com", "wss://relay.damus.io"]);
        
          // Call the callback function with the login data
          onLoginSuccess ({ email, password, userPrivateKey, userPublicKey, userName, userPictureUrl, userAbout });

        } else {
          // Handle the case where signup did not return a user ID
          setErrorMessage('Signup failed. Please try again.');
        }
      } catch (error) {
        console.error('Signup error:', error);
        setErrorMessage('An error occurred during signup. Please try again.');
      } finally {
        setLoading(false);
      }
    }, 1000); // 1-second delay to ensure the loading animation is shown
  }  

    

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  return (
    <>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
      <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
                <div className="flex shrink-0 items-center justify-center bg-black">
                    <img
                        className="w-full h-24 mb-4 object-contain rounded-t-sm rounded-b-none" // Changed to object-contain for scaling down
                        src={imageUrl}
                        alt="Image"
                    />
                    {/* Overlay label positioned absolutely within the parent div */}
                    <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
                        {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
                    </div>
                </div>

                <div className="flex items-center justify-center mb-4 -mt-6"> {/* This ensures vertical space and center alignment */}
                    <div className="relative rounded-full border-4 border-white overflow-hidden" style={{ width: '80px', height: '80px', backgroundColor: 'black' }}>
                        <img
                        src={imageUrl}
                        alt="Image"
                        className="p-2 w-full h-full object-contain" // Ensures the image fits without distortion
                        />
                    </div>
                </div>


        {/* <div className=" flex text-center justify-center items-center">
        <div className="text-center w-fit bg-orange-100  rounded-md px-2 py-1 text-xs font-medium mb-3 text-orange-600">Subscribe in Sats ₿</div>
        </div> */}
        <h2 className="text-center text-2xl font-bold mb-0 text-black">
          {appName}
        </h2>
        <div className="text-center text-md text-gray-600 mb-6 px-10">
          Create an account or login to subscribe.
        </div>
        <form className="space-y-3 px-10  align-middle justify-center" onSubmit={handleLogin}>
          <div>
            <input
              id="email"
              name="email"
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-md  font-medium text-gray-600  custom-placeholder"
              placeholder='Email'
            />
          </div>
          <div className="relative">
            <input
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-md  font-medium text-gray-600  custom-placeholder"
              placeholder='Password'
            />
            <button
              onClick={togglePasswordVisibility}
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300"
            >
              {/* Assume FaEye/FaEyeSlash icon based on showPassword state */}
            </button>
          </div>
          <div>
            <input
              id="repeatPassword"
              name="repeatPassword"
              type={showRepeatPassword ? 'text' : 'password'}
              required
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              className="px-3 py-2  block w-full rounded-md  border-gray-200 border-2 text-md  font-medium text-gray-600  custom-placeholder"
              placeholder='Repeat Password'
            />
            <button
              onClick={toggleRepeatPasswordVisibility}
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm font-medium text-purple-500 hover:text-purple-300"
            >
              {/* FaEye/FaEyeSlash icon here */}
            </button>
          </div>
          {errorMessage && <div className="text-red-500 mt-1 text-sm">{errorMessage}</div>}
          <div className="flex items-center">
            <input
              id="terms_and_conditions"
              name="terms_and_conditions"
              type="checkbox"
              checked={acceptedTerms}
              onChange={(e) => setAcceptedTerms(e.target.checked)}
              className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
            />
            <label htmlFor="terms_and_conditions" className="ml-2 block text-md font-medium text-gray-500 py-2">
              I accept the <a href="/terms" className="text-purple-500 underline">Terms and Conditions</a>
            </label>
          </div>
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-purple-800 px-3 py-3 text-md font-semibold leading-6 text-white  hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:ring-offset-2 custom-button2"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </>
  );
  
}

export default SignUpWithEmail;
