// StatsComponent.js
import React from 'react';

import AreaChartTxHistoryNew from './AreaChartTxHistoryNew';
// import PieChartTxPerFlashType from './PieChartTxPerFlashType';
// import AreaChartTxAverageValue from './AreaChartTxAverageValue';



const ChartsComponentNew = ({ filteredData }) => {

  return (
    <div>
      <dl className="w-full">
        <div className="overflow-hidden  ">
            <AreaChartTxHistoryNew data={filteredData} />
        </div>
        {/* <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <PieChartTxPerFlashType data={filteredData} />
        </div>
        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 ring-1 ring-indigo-100">
            <AreaChartTxAverageValue data={filteredData} />
        </div> */}
      </dl>
    </div>
  );
};

export default ChartsComponentNew;
