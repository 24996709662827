import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import SubscriptionsList from '../components/FlashServices/SubscriptionsList';
import ProjectStatsComponent from '../components/FlashServices/ProjectStatsComponent';
import services from '../components/FlashServices/servicesData'; // Adjust the path as needed



function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Subscriptions() {
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  const [selectedTab, setSelectedTab] = useState('My Subscriptions');
  const location = useLocation();
  const [flashes, setFlashes] = useState([])

  useEffect(() => {
    fetchUserFlashes(userHexPublicKey)
      .then(data => {
        if (data && data.flashes) {
          setFlashes(data.flashes);
        }
      });
  }, [userHexPublicKey]); // The effect depends on userHexPublicKey

  async function fetchUserFlashes(userPublicKey) {
    try {
        const formData = new FormData();
        formData.append('user_public_key', userPublicKey);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({ user_public_key: userPublicKey })
        };

        const results = await Promise.allSettled([
            fetch('https://api.paywithflash.com/api/get_user_flashes', { method: 'POST', body: formData }),
            fetch('https://api.paywithflash.com/vimeo_ott/api/get_user_vimeo_ott_flashes', requestOptions),
            fetch('https://api.paywithflash.com/geyser/api/get_user_geyser_flashes', requestOptions)
        ]);

        const combinedFlashes = [];

        for (const result of results) {
            if (result.status === 'fulfilled' && result.value.ok) {
                const data = await result.value.json();
                // Filter out flashes with a flash_type of "Subscription Plan" from the first API's results
                const flashes = result.value.url.includes('get_user_flashes') ? 
                                 data.flashes.filter(flash => flash.flash_type === "Subscription Plan") : 
                                 data.flashes;
                combinedFlashes.push(...flashes);
            }
        }

        combinedFlashes.sort((a, b) => new Date(a.creation_date) - new Date(b.creation_date));
        console.log(combinedFlashes)
        return {
            flashes: combinedFlashes,
        };
    } catch (error) {
        console.error('Error fetching user flashes:', error);
        return { error: error.message };
    }
}


  return (
    <div>
      <SidebarLayout>
        <Header
          title="My Subscription Plans"
          subtitle="Distribute directly your APIs and datasets. For a better web."
          showWalletsAvatar={false} // Set this to true or false as needed
          // ... other header props ...
        />

        <div className="w-full">
          <nav className="flex space-x-8 border-b border-t pt-3 pb-0 bg-white pl-9">
            {['My Subscriptions'].map((tab) => (
              <a
                key={tab}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(tab);
                }}
                className={classNames(
                  tab === selectedTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-indigo-300 hover:text-indigo-400 hover:border-indigo-400',
                  'inline-flex items-center border-b-2 px-0 pt-1 pb-3 text-sm font-medium'
                )}
              >
                {tab}
              </a>
            ))}
          </nav>

          <div className="mt-6 pl-9">
            {selectedTab === 'My Subscriptions' && (
              <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
                <div className='md:col-span-1 pt-1'>
                  <h2 className="text-xl font-semibold leading-7 text-indigo-900">Flash Subscriptions</h2>
                  <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage your flash subscriptions.</p>
                </div>
                <div className="md:col-span-3">
                  <div className='p-2 pr-12'>
                    <ProjectStatsComponent flashes={flashes} />
                    <SubscriptionsList flashes={flashes} />
                  </div>
                </div>
              </div>
            )}

            {selectedTab === 'My Eggs' && (
              <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
                <div className='md:col-span-1 pt-1'>
                  <h2 className="text-xl font-semibold leading-7 text-indigo-900">My Eggs</h2>
                  <p className="text-sm leading-6 text-indigo-900 opacity-70">This section is under development.</p>
                </div>
                {/* Placeholder for My Eggs content */}
                <div className="md:col-span-3">
                  {/* Content for My Eggs will go here */}
                </div>
              </div>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
}

export default Subscriptions;
