import React, { useState } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

// Function to fetch the user metadata

function SuccessScreenHasSubscription(props) {

  // Access props using props.propertyName
  const { appName, imageUrl, onClose } = props;
  const [flashLogo, setFlashLogo] = useState("/flash-credit-1.png"); // Initial logo


    const handleClick = async (e) => {
        window.open("https://app.paywithflash.com", "_blank");
    }


    return (
        <>
          <div className="flex-1 flex flex-col justify-center  text-black bg-white ">
            <div className="flex-1 flex flex-col justify-center text-black md:w-full bg-white px-0 rounded-sm relative"> {/* Add relative positioning here */}
              <div className="flex shrink-0 items-center justify-center">
                <img
                  className="w-full h-24 mb-4 object-cover rounded-t-sm rounded-b-none" // Tailwind CSS for width and margin bottom
                  src={imageUrl}
                  alt="Image"
                />
                {/* Overlay label positioned absolutely within the parent div */}
                <div className="absolute top-0 w-full flex justify-center"> {/* Position the label on top */}
                  {/* <div className="bg-white rounded-md px-2 py-1 text-xs font-medium text-orange-600 mt-2">Subscribe in Sats ₿</div> */}
                </div>
              </div>
    
              <div className="flex shrink-0 items-center justify-center">
                <img
                  className="w-20 h-20 mb-3 object-cover rounded-full border-4 border-white -mt-12 z-20" // Tailwind CSS for width, margin bottom, and z-index
                  src={imageUrl}
                  alt="Image"
                />
              </div>
              <h2 className="text-center text-2xl font-bold mb-0 text-black">
                {appName}
              </h2>
              <div className="text-center text-md text-gray-600 mb-6 px-10">
                You are already a subscriber!
              </div>
    
              <div className="space-y-6 px-7">
                <button
                  type="submit"
                  onClick={handleClick}
                  className="flex items-center w-full text-md justify-center rounded-lg bg-purple-700  px-3 py-3 text-sm font-semibold leading-6 text-white hover:bg-purple-800 focus:outline-none focus:ring-0 focus:ring-purple-800  custom-button2"
                >
                  Manage my subscription
                </button>
              </div>
              <p className="fixed w-full bottom-0 mt-4 pb-3 text-sm text-slate-400 font-medium text-center">
    
                <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" className="mx-auto">
                  <img
                    src={flashLogo}
                    alt="Flash"
                    className="inline h-5 ml-1 align-middle" // Adjust size as needed
                    onMouseEnter={() => setFlashLogo("/flash-credit-2.png")} // Change logo on hover
                    onMouseLeave={() => setFlashLogo("/flash-credit-1.png")} // Revert logo on mouse leave
                  />
                </a>
              </p>                  </div>
          </div>
        </>
      );
    
}

export default SuccessScreenHasSubscription;
