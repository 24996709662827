import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import ServicesList from '../components/FlashServices/ServicesList';
import ProjectStatsComponent from '../components/FlashServices/ProjectStatsComponent';
import services from '../components/FlashServices/servicesData'; // Adjust the path as needed



function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function MyServices() {
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userAbout = localStorage.getItem('userAbout') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";

  const [selectedTab, setSelectedTab] = useState('My Flashes');
  const location = useLocation();
  const [flashes, setFlashes] = useState([])

  useEffect(() => {
    fetchUserFlashes(userHexPublicKey)
      .then(data => {
        if (data && data.flashes) {
          setFlashes(data.flashes);
        }
      });
  }, [userHexPublicKey]); // The effect depends on userHexPublicKey

  async function fetchUserFlashes(userPublicKey) {
    try {
        const formData = new FormData();
        formData.append('user_public_key', userPublicKey);

        const response = await fetch('https://api.paywithflash.com/api/get_user_flashes', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const filteredFlashes = data.flashes.filter(flash => flash.flash_type !== "Subscription Plan");

        filteredFlashes.sort((a, b) => new Date(a.creation_date) - new Date(b.creation_date));

        return {
            flashes: filteredFlashes,
        };
    } catch (error) {
        console.error('Error fetching user flashes:', error);
        return { error: error.message };
    }
  }





  return (
    <div>
      <SidebarLayout>
        <Header
          title="My Gateways"
          subtitle="The list of all created Flashes and Plans."
          showWalletsAvatar={false} // Set this to true or false as needed
          // ... other header props ...
        />

        <div className="w-full">
          {/* <nav className="flex space-x-8 border-b border-t pt-3 pb-0 bg-white pl-9">
            {['My Flashes'].map((tab) => (
              <a
                key={tab}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedTab(tab);
                }}
                className={classNames(
                  tab === selectedTab ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-indigo-300 hover:text-indigo-400 hover:border-indigo-400',
                  'inline-flex items-center border-b-2 px-0 pt-1 pb-3 text-sm font-medium'
                )}
              >
                {tab}
              </a>
            ))}
          </nav> */}

          <div className="m-4">
            {selectedTab === 'My Flashes' && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-10">
                {/* <div className='md:col-span-1 pt-1'>
                  <h2 className="text-xl font-semibold leading-7 text-indigo-900">Flash Services</h2>
                  <p className="text-sm leading-6 text-indigo-900 opacity-70">Manage your flash services.</p>
                </div> */}
                <div className="md:col-span-2">
                  <div className='p-2 pr-0'>
                    <div className="flex flex-col  mb-4">
    <h3 className="text-3xl mb-1 font-semibold text-slate-800">My Bitcoin Gateways</h3>
    <p className="mb-2 text-slate-600 opacity-80 -mb-2">The list of created gateways.</p>
   
    
  </div>
                    <ProjectStatsComponent flashes={flashes} />
                    <ServicesList flashes={flashes} />
                  </div>
                </div>
              </div>
            )}

            {selectedTab === 'My Eggs' && (
              <div className="grid grid-cols-1 md:grid-cols-4 gap-x-8 gap-y-10">
                <div className='md:col-span-1 pt-1'>
                  <h2 className="text-xl font-semibold leading-7 text-indigo-900">My Eggs</h2>
                  <p className="text-sm leading-6 text-indigo-900 opacity-70">This section is under development.</p>
                </div>
                {/* Placeholder for My Eggs content */}
                <div className="md:col-span-2">
                  {/* Content for My Eggs will go here */}
                </div>
              </div>
            )}
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
}

export default MyServices;
