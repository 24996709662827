import React, { useState, useEffect } from 'react';
import { nip04 } from 'nostr-tools';
import axios from 'axios';

import { TextInput, ImageUpload, FormHeader, Modal, PaymentInfo, CancelButton, ConfirmButton, SimpleDropdown, WalletDropdown, CurrencyDropdown, LifetimeDurationDropdown, IconDropdown, NumberInputWithConverter } from '../comp/InputsLib.js';
import * as Utils from '../utils.js';
import {
  SummaryTitle, SummaryPrice, SummaryWallet, SummaryDuration, SummaryUser, SummarySnippet, SummaryCreationDate, SummaryCopyButton, SummaryFooter
} from '../comp/SummaryFields.js';

//1400x520

import generateWidgetSnippet from '../generateSnippets/generateWidgetSnippet'

const WidgetForm = ({ close }) => {
  // Get env var
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY
  const ourNostrWalletConnectUrl = process.env.REACT_APP_NOSTR_WALLET_CONNECT_URL

  // Get the values from session storage
  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";
  let userNsec = localStorage.getItem('userNsec') || "";
  let userNpub = localStorage.getItem('userNpub') || "";
  let userName = localStorage.getItem('userName') || "";
  let userPictureUrl = localStorage.getItem('userPictureUrl') || "";
  let userAbout = localStorage.getItem('userAbout') || "";

  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('Satoshis');
  const [duration, setDuration] = useState('lifetime');
  const [expiryDate, setExpiryDate] = useState();
  const [wallet, setWallet] = useState('Alby');
  const user = userName;
  const [widgetText1, setWidgetText1] = useState('');
  const [widgetText2, setWidgetText2] = useState('');
  const [flashImage, setFlashImage] = useState('');
  const [convertedValue, setConvertedValue] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  // Add state for wallets
  const [wallets, setWallets] = useState([]);
  const [selectedWalletId, setSelectedWalletId] = useState('');
  const [selectedWalletLnAddress, setSelectedWalletLnAddress] = useState('');
  const [selectedWalletNwc, setSelectedWalletNwc] = useState('');
  const [flashMemo, setFlashMemo] = useState('');
  const [snippet, setSnippet] = useState('');

  const [isModalOpen, setModalOpen] = useState(false);


  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);


  // Effect hook to get the current date in right format for display
  function formatDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    }

    return `${month} ${day}${suffix(day)} ${year}`;
  }
  useEffect(() => {
    const currentDate = new Date();
    setFormattedDate(formatDate(currentDate));
  }, []);

  // Effect hook to directly set the exchange rate of sats to usd
  useEffect(() => {
    let isMounted = true;

    const fetchConvertedValue = async () => {
      const value = await Utils.convertCurrency(price, currency);
      if (isMounted) {
        setConvertedValue(value);
      }
    };

    fetchConvertedValue();

    return () => {
      isMounted = false;
    };
  },);

  // Effect hook to set the expiryDate of the Flash when the user changes the duration
  useEffect(() => {
    if (duration === 'lifetime') {
      // Option 1: Set a far future date for 'lifetime'
      const farFuture = new Date();
      farFuture.setFullYear(farFuture.getFullYear() + 100); // 100 years in the future
      setExpiryDate(farFuture);

      // Option 2: Handle 'lifetime' differently
      // setExpiryDate(null); // If your application logic can handle null as 'lifetime'
    } else {
      // Calculate the expiry date based on the selected duration
      const durationInDays = parseInt(duration.split(' ')[0]); // This will parse the numeric part from the duration string
      const newExpiryDate = new Date();
      newExpiryDate.setDate(newExpiryDate.getDate() + durationInDays); // Add the duration to the current date
      setExpiryDate(newExpiryDate); // Set the new date as the expiry date
    }
  }, [duration]); // This effect runs whenever the duration state changes


  // Effect hook to fetch wallets when the component mounts
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await fetch(`https://api.paywithflash.com/api/get_user_wallets?user_public_key=${encodeURIComponent(userHexPublicKey)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const results = [];

        for (const item of data) {
          let decrypted_nwc_url;
          if (item.nwc_url !== "") {
            decrypted_nwc_url = await nip04.decrypt(userHexPrivateKey, publicKey, item.nwc_url);
          } else {
            decrypted_nwc_url = ""
          }

          results.push({
            id: item.id,
            user_publicKey: item.user_public_key,
            nwc_url: decrypted_nwc_url,
            wallet_name: item.wallet_name,
            user_wallet_name: item.user_wallet_name,
            ln_address: item.ln_address
          });
        }
        setWallets(results);

        // Set the first wallet as the default wallet and its ID
        if (results.length > 0) {
          setWallet(results[0].user_wallet_name);
          setSelectedWalletId(results[0].id); // Set the ID of the first NWC
          setSelectedWalletNwc(results[0].nwc_url);
        }
      } catch (error) {
        console.error('Error fetching wallets:', error);
      }
    };

    fetchWallets();
  }, [userHexPrivateKey, userHexPublicKey, publicKey]); // Add dependencies if needed


  const handleWalletChange = (e) => {
    const selectedWalletId = e.target.value;
    const selectedWallet = wallets.find(wallet => wallet.id.toString() === selectedWalletId);

    if (selectedWallet) {
      setWallet(selectedWallet.user_wallet_name); // Sets the wallet name
      setSelectedWalletId(selectedWallet.id); // Sets the wallet ID
      setSelectedWalletLnAddress(selectedWallet.ln_address); // Sets the LN address
      setSelectedWalletNwc(selectedWallet.nwc_url);
    }
  };

  const handleConfirm = async () => {
    // Logic for what happens when 'Confirm' is clicked
    event.preventDefault();
    try {
      // Prepare the data to be sent to the server for creating a new flash
      console.log(title)
      console.log(selectedWalletId)
      console.log(userHexPublicKey)
      const formData = new FormData();
      formData.append('flash_name', title);
      formData.append('wallet_id', selectedWalletId);
      formData.append('user_public_key', userHexPublicKey);
      formData.append('flash_script', ''); // Initially empty
      formData.append('flash_creation_date', new Date().toISOString());
      formData.append('flash_expiry_date', expiryDate.toISOString()); // Directly using toISOString
      formData.append('flash_price', 0.00);
      formData.append('flash_currency', 'does not apply');
      formData.append('flash_type', 'Widget');
      formData.append('flash_image_url', flashImage);
      formData.append('optional_text_1', widgetText1);
      formData.append('optional_text_2', widgetText2);

      // Make an API call to save the flash to the database
      let response = await fetch('https://api.paywithflash.com/api/save_flash_to_db', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const saveResult = await response.json();
      const flashId = saveResult.flash_id;
      console.log('Flash saved:', saveResult);

      const snippet = await generateWidgetSnippet(userHexPublicKey, userHexPrivateKey, flashId);
      setSnippet(snippet);
      console.log(snippet)

      // Prepare data to update the flash with the generated snippet
      const updateData = new FormData();
      updateData.append('flash_id', flashId);
      updateData.append('flash_name', title);
      updateData.append('wallet_id', selectedWalletId);
      updateData.append('user_public_key', userHexPublicKey);
      updateData.append('flash_script', snippet);
      updateData.append('flash_creation_date', new Date().toISOString());
      updateData.append('flash_expiry_date', expiryDate.toISOString()); // Again using toISOString
      updateData.append('flash_price', 0.00);
      updateData.append('flash_currency', 'does not apply');
      updateData.append('flash_type', 'Widget');
      updateData.append('flash_image_url', flashImage);
      updateData.append('optional_text_1', widgetText1);
      updateData.append('optional_text_2', widgetText2);

      // Make an API call to update the flash in the database
      response = await fetch('https://api.paywithflash.com/api/update_flash_in_db', {
        method: 'POST',
        body: updateData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok during update');
      }

      const updateResult = await response.json();
      console.log('Flash updated with snippet:', updateResult);

    } catch (error) {
      console.error("Error during flash creation, update, and snippet generation:", error);
    }
  };

  const handleCopy = async (snippetText) => {
    // Logic for what happens when 'Copy' is clicked
    try {
      await navigator.clipboard.writeText(snippetText);
    } catch (error) {
      console.error('Failed to copy snippet:', error);
    }
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleWidgetText1Change = (e) => {
    setWidgetText1(e.target.value);
  };

  const handleWidgetText2Change = (e) => {
    setWidgetText2(e.target.value);
  };

  const handleImageChange = async (e) => {
    // Check if a file was selected
    if (e.target.files.length > 0) {
        const file = e.target.files[0]; // Access the selected file directly
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post(`https://api.paywithflash.com/api/upload_flash_picture`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.data.success) {
                // Assuming response.data.url contains the URL of the uploaded image
                const imageUrl = response.data.url;
                setFlashImage(imageUrl)
                console.log('Image uploaded successfully:', imageUrl);
                // Perform any action with the imageUrl, like updating state to render the image in the UI
            } else {
                // Handle the failure case
                console.error('Image upload failed:', response.data.error);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }
};


  return (
    <div className="flex flex-col md:flex-row md:space-x-4 modal-base h-screen">
      <div className="flex-1 p-14 overflow-auto">

        <FormHeader
          title="Create a Payment Widget"
          subtitle="Add a simple payment widget to receive payments and tips from your website visitors, with a simple snippet. Add it on any page, pause or delete anytime."
          infoBadge={{
            text: "Learn more about Payment Widgets",
            color: "bg-gray-100 text-gray-600",
            onClick: openModal // Add onClick handler to open the modal
          }}
          newEggBadge={{
            text: "New Flash",
            color: "bg-purple-50 text-purple-700 ring-1 ring-inset ring-purple-600/20 mb-3"
          }}
        />

        <div className="inputform">
          <TextInput label="Title" placeholder="Support us in Bitcoin!" value={title} onChange={handleTitleChange} maxLength={30} />
        </div>

        <div className="inputform">
          <TextInput label="Widget text 1" placeholder="Click here to support us" value={widgetText1} onChange={handleWidgetText1Change} maxLength={20} />
        </div>

        <div className="inputform">
          <TextInput label="Widget text 2" placeholder="By supporting us, you help us continue and improve the value we bring!" value={widgetText2} onChange={handleWidgetText2Change} maxLength={90} />
        </div>

        <div className="inputform">
          <ImageUpload label="Upload Image (recommended resolution: 1400x520)" onChange={handleImageChange} />
        </div>
        {/*         
        <div className="inputform">
          <NumberInputWithConverter label="Price" name="price" id="price" placeholder="Enter the price" value={price} onChange={(e) => setPrice(e.target.value)} convertedValue={convertedValue} />
        </div>

        <div className="inputform">
          <CurrencyDropdown label="Currency" id="currency" name="currency" value={currency} onChange={(e) => setCurrency(e.target.value)} />
        </div> */}

        <div className="inputform">
          <WalletDropdown label="Wallet" id="wallet" name="wallet" wallets={wallets} value={selectedWalletId} onChange={handleWalletChange} />
        </div>
        {/* 
        <div className="inputform">
          <LifetimeDurationDropdown label="Flash Duration" id="flash-duration" name="flash-duration" value={duration} onChange={(e) => setDuration(e.target.value)} />
        </div> */}

        <div className="inputform">
          <ConfirmButton onClick={handleConfirm}> Create payment widget </ConfirmButton>
          <CancelButton onClose={close} />
        </div>

        <div className="inputform">
          <PaymentInfo text="Payments will go directly to your connected wallet, through Flash." />
        </div>

      </div>
      <div className="flex-1 bg-gray-100">
        {/* You can add content here if needed */}

        {/* Summary Column */}
        <div className="flex-1 bg-gray-100 p-14">
          <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5 p-8">
            <SummaryTitle title={title || 'Flash Title'} />
            <SummaryCreationDate creationDate={formattedDate} />
            <SummaryPrice price={price} currency={currency} convertedValue={convertedValue} />
            <SummaryWallet wallet={wallet} />
            <SummaryDuration duration={duration} />
            <SummaryUser user={user} />
            <SummarySnippet snippet={snippet} />
            <SummaryCopyButton text="Copy Snippet" onClick={() => handleCopy(snippet)} />
            <SummaryFooter />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <iframe 
            src="https://scribehow.com/embed/Create_Payment_Widget__QdCzMv-7TrKPfXXn55VBTQ?removeLogo=true" 
            width="100%" 
            height="640" 
            allowFullScreen 
            frameBorder="0"
          ></iframe>
        </Modal>
      )}
    </div>
  );
};

export default WidgetForm;
