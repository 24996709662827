import React from 'react';

const BitcoinFilmFestStats = ({ sheetData }) => {
    const validData = (Array.isArray(sheetData) ? sheetData : []).filter(item => !isNaN(parseInt(item.total_points, 10)));
    const sortedData = validData.sort((a, b) => parseInt(b.total_points, 10) - parseInt(a.total_points, 10));

    if (!sortedData.length) {
        return <div>No data available.</div>;
    }

    const numberOfPlayers = sortedData.length;
    const teams = [...new Set(sortedData.map(item => item.team))];
    const numberOfTeams = teams.length;
    
    const highestScoringPlayer = sortedData[0]; // First item after sort for highest score

    const pointsByTeam = sortedData.reduce((acc, { team, total_points }) => {
        acc[team] = acc[team] ? acc[team] + parseInt(total_points, 10) : parseInt(total_points, 10);
        return acc;
    }, {});

    const highestScoringTeam = Object.entries(pointsByTeam).reduce((max, current) => max[1] > current[1] ? max : current, ["", 0]);

    return (
        <div className="flex flex-col items-center justify-center bg-gray-800">
            <div className="w-full max-w-4xl p-4 md:p-8 bg-gray-800 shadow rounded-lg">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
                    <div className="stat">
                        <div className="stat-title text-gray-200">Number of Players</div>
                        <div className="stat-value text-2xl text-blue-300">{numberOfPlayers}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-title text-gray-200">Number of Teams</div>
                        <div className="stat-value text-2xl text-blue-300">{numberOfTeams}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-title text-gray-200">Top Scoring Player</div>
                        <div className="stat-value text-2xl text-blue-300">{highestScoringPlayer.firstName}</div>
                        <div className="stat-desc text-gray-500">Points: {highestScoringPlayer.total_points}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-title text-gray-200">Top Scoring Team</div>
                        <div className="stat-value text-2xl text-blue-300">{highestScoringTeam[0]}</div>
                        <div className="stat-desc text-gray-500">Points: {highestScoringTeam[1]}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BitcoinFilmFestStats;
