import React, { useState, useEffect } from 'react';
import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import SalesReportingWithFilters from '../components/SalesReporting/SalesReportingWithFilters';
import SaleDetails from '../components/SalesReporting/SaleDetails';

const SalesReporting = () => {
  const privateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const publicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  let userHexPrivateKey = localStorage.getItem('userHexPrivateKey') || "";
  let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";

  const [activeSection, setActiveSection] = useState('view');
  const [salesData, setSalesData] = useState([]);
  const [expandData, setExpandData] = useState([]);
  const [bitcoinPrice, setBitcoinPrice] = useState();
  const [exchangeRate, setExchangeRate] = useState();

  const [selectedSale, setSelectedSale] = useState(null);
  const [showSaleDetails, setShowSaleDetails] = useState(false);

  async function fetchTransactionsWithDetails(userPublicKey) {
    try {
      const response = await fetch(`https://api.paywithflash.com/api/list_sales_transactions_for_user?user_public_key=${encodeURIComponent(userPublicKey)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }

  const getBitcoinPrice = async () => {
    try {
      const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  }

  const getExchangeRateFromDollars = async (to_currency) => {
    try {
      const formData = new FormData();
      formData.append('to_currency', to_currency);

      const response = await fetch('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`API call failed with status:${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('API call failed:', error);
      throw error;
    }
  }

  const convertPrice = async (price, currency) => {
    const oneBitcoinInSats = 100000000;
    let priceInSats = 0, priceInDollars = 0, priceInZloty = 0;

    try {
      if (!bitcoinPrice) {
        throw new Error('Failed to fetch Bitcoin price');
      }

      if (!exchangeRate) {
        return new Error('Failed to fetch exchange rate');
      }

      if (currency === "Dollar" || currency === 'usd') {
        const amountInUSD = parseFloat(price);
        priceInSats = Math.round((amountInUSD / bitcoinPrice) * oneBitcoinInSats);
        priceInDollars = amountInUSD;
        priceInZloty = priceInDollars * exchangeRate;
      } else if (currency === "satoshis" || currency === 'Satoshis') {
        const amountInSats = parseFloat(price);
        priceInSats = amountInSats;
        priceInDollars = ((amountInSats / oneBitcoinInSats) * bitcoinPrice).toFixed(2);
        priceInZloty = priceInDollars * exchangeRate;
      } else if (currency === 'Zloty') {
        const amount = parseFloat(price);
        priceInZloty = amount;
        priceInDollars = priceInZloty / exchangeRate;
        priceInSats = Math.round((priceInDollars / bitcoinPrice) * oneBitcoinInSats);
      } else {
        throw new Error('Unknown currency type: ' + currency);
      }
      return { priceInDollars, priceInSats, priceInZloty };
    } catch (error) {
      console.error('Error in conversion:', error);
      throw error;
    }
  }

  useEffect(() => {
    fetchTransactionsWithDetails(userHexPublicKey)
      .then(data => {
        if (data) {
          setSalesData(data);
        }
      });
  }, [userHexPublicKey]);

  useEffect(() => {
    const setRate = async () => {
      const bitcoinPrice = await getBitcoinPrice();
      const exchangeRate = await getExchangeRateFromDollars('PLN');

      setBitcoinPrice(bitcoinPrice);
      setExchangeRate(exchangeRate);
    }
    setRate();
  }, [])

  useEffect(() => {
    if (salesData && bitcoinPrice && exchangeRate) {
      const getExpandData = async (salesData) => {
        const expandData = [];
        for (const sale of salesData) {
          for (const product of sale.Products) {
            const { priceInDollars, priceInSats, priceInZloty } = await convertPrice(product.ProductPrice, product.ProductCurrency);
            expandData.push({
              saleId: sale.SaleId,
              firstName: sale.Customer.FirstName,
              lastName: sale.Customer.LastName,
              productId: product.ProductID,
              productName: product.ProductName,
              productDescription: product.ProductDescription,
              productPriceInDollars: priceInDollars,
              productPriceInSats: priceInSats,
              productPriceInZloty: priceInZloty,
              categoryId: product.CategoryID,
              categoryName: product.CategoryName,
              quantity: product.Quantity,
              taxRate: product.ProductTaxRate,
              currency: product.ProductCurrency,
              isDigital: product.ProductIsDigital,
              totalCostDollar: sale.TotalCostDollar,
              totalCostSats: sale.TotalCostSats,
              totalCostZloty: sale.TotalCostZloty,
              saleOrigin: sale.SaleOrigin,
              saleDate: sale.SaleDate,
              customerEmail: sale.Customer.Email,
              customerAddress1: sale.Customer.Address1,
              customerAddress2: sale.Customer.Address2,
              customerPhoneNumber: sale.Customer.PhoneNumber,
              customerPostalCode: sale.Customer.PostalCode,
              customerCity: sale.Customer.City,
              customerCountry: sale.Customer.Country,
            })
          }
        }
        setExpandData(expandData);
      }
      getExpandData(salesData);
    };
  }, [salesData, bitcoinPrice, exchangeRate])

  const handleSaleSelect = (sale) => {
    setSelectedSale(sale);
    console.log(sale)
    setShowSaleDetails(true);
  };

  const handleCloseModal = () => {
    setSelectedSale(null);
    setShowSaleDetails(false);
  };

  return (
    <div className="min-h-screen">
      <SidebarLayout>
        <Header
          title="Sale Reporting"
          subtitle="View all sales reporting from your product"
          showWalletsAvatar={false} // Set this to true or false as needed
        />
        <div className='p-8'>
          {!showSaleDetails && (
            <SalesReportingWithFilters
              initialData={salesData}
              expandData={expandData}
              onSaleSelect={handleSaleSelect}
            />
          )}
          {showSaleDetails && (
            <SaleDetails
              sale={selectedSale}
              onClose={handleCloseModal}
            />
          )}
        </div>
      </SidebarLayout>
    </div>
  );
}

export default SalesReporting;
