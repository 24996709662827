import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { nip04, nip19, SimplePool, finishEvent } from 'nostr-tools';

const { decode } = nip19;

import ProductDetails from '../components/Products/Products/ProductDetails';
import ProductImages from '../components/Products/Products/ProductImages';
import ProductOrderForm from '../components/Products/Products/ProductOrderForm';
import ProductOrderFormDisplay from '../components/Products/Products/ProductOrderFormDisplay';
import ProductPayment from '../components/Products/Products/ProductPayment';
import PaymentSuccessScreen from '../components/Products/Products/PaymentSuccessScreen';

// Function to get the price of Bitcoin
async function getBitcoinPrice() {
  try {
    const response = await fetch('https://api.paywithflash.com/api/get_bitcoin_price', {
      method: 'POST', // if the API requires POST method
      headers: {
        'Content-Type': 'application/json',
      },
      // If the API requires a body payload, uncomment and edit the following line
      // body: JSON.stringify({ key: 'value' }), 
    });

    if (!response.ok) {
      // If the response is not OK, throw an error with the response status
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const data = await response.json();

    // Assuming the API returns an object with a property that contains the Bitcoin price
    return data
  } catch (error) {
    console.error('API call failed:', error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

async function getExchangeRateFromDollars (to_currency) {
  try {
    const formData = new FormData ();
    formData.append ('to_currency', to_currency);

    const response = await fetch ('https://api.paywithflash.com/api/get_exchanging_rate_from_dollars', {
      method : 'POST',
      body : formData,
    });

    if (!response.ok) {
      throw new Error (`API call failed with status:${response.status}`);
    }

    const data = await response.json ();
    return data;
  } catch (error) {
    console.error ('API call failed:', error);
    throw error;
  }
}


const convertPrices = async (currency, value) => {
  const bitcoinPriceUSD = await getBitcoinPrice(); // Fetch the current price of Bitcoin in dollars
  const exchangeRate = await getExchangeRateFromDollars ('PLN');

  if (currency === "usd") {
    const priceInSatoshis = Math.round(value / bitcoinPriceUSD * 100000000);
    const priceInZloty = parseFloat (value) * exchangeRate;
    return { priceInDollars: value, priceInSatoshis, priceInZloty };
  } else if (currency === "satoshis") {
    const priceInDollars = (value / 100000000 * bitcoinPriceUSD).toFixed(2);
    const priceInZloty = priceInDollars * exchangeRate;
    return { priceInDollars, priceInSatoshis: value, priceInZloty };
  } else if (currency === 'zloty') {
    const priceInDollars = parseFloat (value) / exchangeRate;
    const priceInSatoshis = Math.round((priceInDollars / bitcoinPriceUSD) * 100000000);
    return { priceInDollars, priceInSatoshis, priceInZloty: value}
  }
};



async function sendPhysicalProductPurchaseConfirmationEmail(product, recipientEmail) {
  const apiUrl = 'https://api.paywithflash.com/api/send_purchase_confirmation_for_physical_product';

  // Prepare the data to be sent in the request
  const requestData = {
    product: product,
    recipient_email: recipientEmail
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      // If the response is not OK, throw an error
      const errorData = await response.json();
      throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
    }

    // If the request was successful, you can handle the response here
    const responseData = await response.json();
    // Perform any actions needed upon successful email sending, e.g., showing a notification to the user
  } catch (error) {
    // Handle any errors that occurred during the request
    console.error('Error sending purchase confirmation:', error.message);
    // Optionally, inform the user about the error, e.g., with an error message in the UI
  }
}


async function sendDigitalProductPurchaseConfirmationEmail(product, recipientEmail, downloadLink) {
  const apiUrl = 'https://api.paywithflash.com/api/send_purchase_confirmation_for_digital_product';

  const requestData = {
    product: product,
    recipient_email: recipientEmail,
    download_link: downloadLink  // Add this line to include the download link in the request data
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Failed to send purchase confirmation: ${errorData.error || response.status}`);
    }

    const responseData = await response.json();
    // console.log('Email sent successfully:', responseData);
  } catch (error) {
    console.error('Error sending purchase confirmation email:', error.message);
    throw error;
  }
}



const convertNostrToHex = async (npub) => {
  const { type, data } = decode(npub);

  if (type === 'npub' || type === 'nsec') {
    return data;
  } else {
    throw new Error('Invalid Nostr key. Expected an "npub" key.');
  }
};



async function sendPhysicalProductPurchaseConfirmationNostrDM(product, recipientNpub) {

  const relayUrls = ["wss://relay.paywithflash.com", "wss://relay.damus.io", "wss://relay.primal.net", "wss://relay.snort.social", "wss://relay.nostr.band"];
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY

  const recipientHexPublicKey = await convertNostrToHex(recipientNpub);

  const message = `
  Congratulations on your purchase!

  ${product.Name} purchased for $${product.PriceInDollars} (${product.PriceInSatoshis} satoshis).
  Follow this link to view the product: https://app.paywithflash.com/product-page/${product.ProductID}

  Please note: We are the payment gateway and do not handle shipping or fulfillment. Should you have any inquiries regarding your order, please reach out to the vendor directly.
  `;

  // Initialize the pool
  const pool = new SimplePool();

  try {
    const senderPrivateKey = flashPrivateKey;
    const senderPublicKey = flashPublicKey;
    const ciphertext = await nip04.encrypt(senderPrivateKey, recipientHexPublicKey, message);

    let event = {
      kind: 4,
      pubkey: senderPublicKey,
      tags: [['p', recipientHexPublicKey]],
      content: ciphertext,
      created_at: Math.floor(Date.now() / 1000),
      id: '', // The ID will be calculated in finishEvent
      sig: '', // The signature will be added in finishEvent
    };

    // Sign the event
    const signedEvent = finishEvent(event, senderPrivateKey);

    // Publish the event to the list of relays
    try {
      const publishResults = await Promise.allSettled(pool.publish(relayUrls, signedEvent));

      publishResults.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          // console.log(`DM successfully published to relay at ${relayUrls[index]}.`);
        } else {
          console.error(`An error occurred while publishing to relay at ${relayUrls[index]}:`, result.reason);
        }
      });
    } catch (error) {
      console.error('Unexpected error in publishing process:', error);
    }
  } catch (error) {
    console.error('Error sending direct message:', error.message, error);
  }
}



async function createAndUploadZip(product) {
  try {
    const response = await axios.post('https://api.paywithflash.com/api/create_zip_endpoint', {
      product_id: product.ProductID,
      file_urls: product.DigitalProductURLs // Assuming you have URLs ready in the product object
    });
    return response.data.url; // Assuming the API returns an object with a url property
  } catch (error) {
    console.error('Failed to create zip file:', error);
    throw new Error('Failed to create download link.');
  }
}

async function sendDigitalProductPurchaseConfirmationNostrDM(product, recipientNpub, downloadLink) {
  const relayUrls = [
    "wss://relay.paywithflash.com",
    "wss://relay.damus.io",
    "wss://relay.primal.net",
    "wss://relay.snort.social",
    "wss://relay.nostr.band"
  ];
  const flashPrivateKey = process.env.REACT_APP_NOSTR_PRIVATE_KEY;
  const flashPublicKey = process.env.REACT_APP_NOSTR_PUBLIC_KEY;

  const recipientHexPublicKey = await convertNostrToHex(recipientNpub);

  const message = `
    Congratulations on your purchase!
    ${product.Name} purchased for $${product.PriceInDollars} (${product.PriceInSatoshis} satoshis).
    Follow this link to view the product: https://app.paywithflash.com/product-page/${product.ProductID}
    Follow this link to download your digital products: ${downloadLink}
    Please note: We are the payment gateway and do not handle shipping or fulfillment. Should you have any inquiries regarding your order, please reach out to the vendor directly.
  `;

  const ciphertext = await nip04.encrypt(flashPrivateKey, recipientHexPublicKey, message);

  let event = {
    kind: 4,
    pubkey: flashPublicKey,
    tags: [['p', recipientHexPublicKey]],
    content: ciphertext,
    created_at: Math.floor(Date.now() / 1000)
  };

  event = finishEvent(event, flashPrivateKey);

  const pool = new SimplePool();
  const publishResults = await Promise.allSettled(pool.publish(relayUrls, event));

  publishResults.forEach((result, index) => {
    if (result.status === 'fulfilled') {
      // console.log(`DM successfully published to relay at ${relayUrls[index]}.`);
    } else {
      console.error(`An error occurred while publishing to relay at ${relayUrls[index]}:`, result.reason);
    }
  });
}




async function updateInventoryAfterPurchase(productId, quantity) {
  const url = 'https://api.paywithflash.com/api/update_inventory_after_purchase'; // Change to your actual API URL
  const data = {
    product_id: productId,
    quantity: quantity
  };

  return axios.post(url, data)
    .then(response => {
      // console.log('Inventory update response:', response.data);
      return response.data; // Return the response data for further processing
    })
    .catch(error => {
      console.error('Error updating inventory:', error.response ? error.response.data : error.message);
      throw error; // Re-throw the error for further handling by the caller
    });
}


const ProductPage = () => {

  const userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";

  if (userHexPublicKey == "") {
    return <div> Please use the same browser! </div>
  }
  
  const { productId } = useParams();
  const [customerId, setCustomerId] = useState(null);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [orderDetails, setOrderDetails] = useState({});
  const [isModifying, setIsModifying] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [priceInSats, setPriceInSats] = useState();
  const [priceInDollars, setPriceInDollars] = useState();
  const [priceInZloty, setPriceInZloty] = useState();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const response = await fetch(`https://api.paywithflash.com/api/get_product_by_id?product_id=${productId}`);
        const data = await response.json();

        // Then get the inventory
        try {
          const inventoryResponse = await axios.get(`https://api.paywithflash.com/api/get_inventory_for_product/${data.ProductID}`);
          data.inventory = inventoryResponse.data;
        } catch (inventoryError) {
          console.error('Failed to fetch inventory:', inventoryError);
          data.inventory = { StockCount: 0 }; // Default value if inventory fetch fails
        }

        // Calculate and include both price representations
        const prices = await convertPrices(data.Currency, Number(data.Price));
        data.PriceInDollars = prices.priceInDollars;
        data.PriceInSatoshis = prices.priceInSatoshis;
        data.PriceInZloty = prices.priceInZloty;

        if (data.Discount) {
          setPriceInDollars(parseFloat(data.PriceInDollars * (1 - Number(data.Discount) / 100)));
          setPriceInZloty(parseFloat(data.PriceInZloty * (1 - Number(data.Discount) / 100)));
          setPriceInSats(parseInt(data.PriceInSatoshis * (1 - Number(data.Discount) / 100)));
        } else {
          setPriceInDollars(data.PriceInDollars);
          setPriceInZloty(data.PriceInDollars);
          setPriceInSats(data.PriceInSatoshis);
        }
        if (data) setProduct(data);
      } catch (error) {
        console.error('Failed to fetch product', error);
        setError('Failed to fetch product');
      } finally {
        setIsLoading(false);
      }
    };
    getProduct();
  }, [productId]);

  useEffect(() => {
    if (paymentSuccess) {
      const saveCustomerToDB = async () => {
        const jsonData = {
          user_public_key: orderDetails.hasOwnProperty('npub') ? orderDetails.npub : '',
          email: orderDetails.email,
          firstName: orderDetails.customerName,
          lastName: orderDetails.customerSurname,
          address1: orderDetails.hasOwnProperty('addressLine1') ? orderDetails.addressLine1 : '',
          address2: orderDetails.hasOwnProperty('addressLine2') ? orderDetails.addressLine2 : '',
          postalCode: orderDetails.hasOwnProperty('postalCode') ? orderDetails.postalCode : '',
          city: orderDetails.hasOwnProperty('city') ? orderDetails.city : '',
          country: orderDetails.hasOwnProperty('country') ? orderDetails.country : '',
          phoneNumber: orderDetails.hasOwnProperty('phoneNumber') ? orderDetails.phoneNumber : ''
        };

        try {
          const response = await axios.post('https://api.paywithflash.com/api/create_customer', jsonData);
          setCustomerId(response.data.CustomerID);
        } catch (error) {
          console.error('Error saving the customer info to DB');
          throw error;
        }
      };

      saveCustomerToDB();
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (customerId) {
      const saveSaleInfoToDB = async () => {
        const formData = new FormData ();
        formData.append('user_public_key', userHexPublicKey);
        formData.append ('customer_id', customerId);
        formData.append ('product_list[]', productId);
        formData.append ('product_quantity[]', quantity);
        formData.append ('sale_date', new Date().toISOString());
        formData.append ('sale_origin', 'online');
        formData.append ('price_in_dollars', priceInDollars);
        formData.append ('price_in_sats', priceInSats);
        formData.append ('price_in_zloty', priceInZloty);

        try {
          await axios.post('https://api.paywithflash.com/api/save_sale_info_to_db', formData);
        } catch (error) {
          console.log('Error saving the sale information');
          throw error;
        }
      };

      saveSaleInfoToDB();
    }
  }, [customerId]);

  const handleOrderSubmit = (formValues) => {
    setIsFormSubmitted(true);
    setOrderDetails(formValues);
  };

  const handleModify = () => {
    setIsFormSubmitted(false);
    setOrderDetails({});
  };

  const handlePaymentSuccess = async () => {
    setPaymentSuccess(true);
    // Update the product inventory if inventory is > 0
    if (product.inventory && product.inventory.StockCount > 0) {
      updateInventoryAfterPurchase(productId, quantity);
    }
    // Send the email and nostr DM
    if (product.is_digital_product) {
      try {
        const downloadUrl = await createAndUploadZip(product);
        setDownloadLink(downloadUrl);

        await sendDigitalProductPurchaseConfirmationEmail(product, orderDetails.email, downloadUrl);
        if (orderDetails.npub) {
          await sendDigitalProductPurchaseConfirmationNostrDM(product, orderDetails.npub, downloadUrl);
        }
      } catch (error) {
        console.error('Error handling digital product confirmation:', error);
      }
    } else {
      await sendPhysicalProductPurchaseConfirmationEmail(product, orderDetails.email);
      if (orderDetails.npub) {
        await sendPhysicalProductPurchaseConfirmationNostrDM(product, orderDetails.npub);
      }
    }
  };

  if (error) return <div>Error: {error}</div>;
  if (paymentSuccess) {
    return <PaymentSuccessScreen product={product} />;
  }

  console.log(product)

  return (
    <>
      {isLoading && (
        <div className="loading-container absolute inset-0 flex items-center justify-center bg-black bg-opacity-60" style={{ zIndex: '100' }}>
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-purple-300 h-12 w-12 mb-4"></div>
        </div>
      )}
      {!isLoading && (
        <div className="flex justify-center items-center bg-blue-100 p-10 lg:p-32 bg-white">
          <div className="w-full rounded-xl">
            <div className="grid grid-cols-2 gap-2 lg:divide-x-2">
              <div className="lg:col-span-1 md:col-span-2 sm:col-span-2 col-span-2 p-8 flex flex-col justify-center items-center">
                <ProductDetails product={product} />
                <div className="w-full h-64"> {/* Set a fixed height for the image container */}
                  <ProductImages product={product} />
                </div>
                <div className="flex items-center justify-center space-x-2 mt-5">
                  <label htmlFor="product-quantity" className="text-sm font-medium text-gray-700">Quantity</label>
                  <input
                    type="number"
                    id="product-quantity"
                    name="quantity"
                    min="1"
                    max={product.inventory ? product.inventory.StockCount : 9999}
                    value={quantity}
                    onChange={(e) => setQuantity(product.inventory ? Math.max(1, Math.min(product.inventory.StockCount, Number(e.target.value))) : Math.max(1, Number(e.target.value)))}
                    className="text-xs p-2 border border-gray-300 rounded-md shadow-sm w-16"
                  />
                </div>
                <div className="text-xs text-gray-500 mt-8">{ product.Description }</div>

                <div className="text-center text-xs text-gray-500 mt-8">
                  Powered by <a href="https://paywithflash.com" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                    <b>Flash</b>
                  </a>
                </div>
              </div>
              <div className="lg:col-span-1 sm:col-span-2 col-span-2 flex flex-col justify-center">
                {!isFormSubmitted && 
                  <div className='px-6'>
                      <ProductOrderForm
                        onOrderSubmitted={handleOrderSubmit}
                        isDigitalProduct={product.is_digital_product}
                      />
                  </div>
                } 
                {isFormSubmitted && (
                  <>
                    <ProductPayment product={product} quantity={quantity} handlePaymentSuccess={handlePaymentSuccess} />
                    <ProductOrderFormDisplay
                      onModify={handleModify}
                      details={orderDetails}
                      isDigitalProduct={product.is_digital_product}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="mb-10 flex justify-center">
              <div className="flex flex-col w-full mx-8">
                <div className='mb-4 ml-10 flex space-x-4'> {/* Centered and added space */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductPage;
