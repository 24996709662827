import React, { useState, useEffect } from 'react';
import axios from 'axios';

import SidebarLayout from '../components/SidebarLayout';
import Header from '../components/FlashStatic/Header';
import CustomersList from '../components/Products/Customers/CustomersList';
import CustomerDetails from '../components/Products/Customers/CustomerDetails';

const CustomersPage = () => {
    let userHexPublicKey = localStorage.getItem('userHexPublicKey') || "";

    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [showCustomerDetails, setShowCustomerDetails] = useState(false);

    useEffect(() => {
        const fetchCustomersAndDetails = async () => {
            try {
                setIsLoading(true);
                const { data: customersData } = await axios.get(`https://api.paywithflash.com/api/list_customers_for_user`, {
                    params: { user_public_key: userHexPublicKey }
                });

                const aggregatedCustomers = aggregateCustomerData(customersData);
                setCustomers(aggregatedCustomers);
            } catch (error) {
                console.error('Failed to fetch customers and their details', error);
                setError('Failed to fetch customers and details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchCustomersAndDetails();
    }, [userHexPublicKey]);

    const aggregateCustomerData = (data) => {
        const customerMap = new Map();
        data.forEach((customer) => {
            const key = customer.Email;
            if (customerMap.has(key)) {
                const existingCustomer = customerMap.get(key);
                existingCustomer.TotalOrders += parseInt(customer.TotalOrders, 10);
                existingCustomer.TotalCostUSD += parseFloat(customer.TotalCostUSD);
                existingCustomer.TotalCostSats += parseFloat(customer.TotalCostSats);
            } else {
                customerMap.set(key, { 
                    ...customer,
                    TotalOrders: parseInt(customer.TotalOrders, 10),
                    TotalCostUSD: parseFloat(customer.TotalCostUSD),
                    TotalCostSats: parseFloat(customer.TotalCostSats)
                });
            }
        });
        return Array.from(customerMap.values());
    };
    

    const handleCustomerSelect = (customer) => {
        setSelectedCustomer(customer);
        setShowCustomerDetails(true);
    };

    const handleCloseModal = () => {
        setSelectedCustomer(null);
        setShowCustomerDetails(false);
    };

    return (
        <div className="min-h-screen">
            <SidebarLayout>
                <Header
                    title="My Customers"
                    subtitle="See the customers that bought your products."
                    showWalletsAvatar={false}
                    badgeText=""
                />
                <div className="container mx-auto">
                </div>
                <div className="container mx-auto px-4">
                    <>
                        {!showCustomerDetails && (
                            <div>
                                <CustomersList customers={customers} isLoading={isLoading} error={error} onCustomerSelect={handleCustomerSelect} />
                            </div>
                        )}
                        {showCustomerDetails && (
                            <div>
                                <CustomerDetails
                                    customer={selectedCustomer}
                                    onClose={handleCloseModal}
                                />
                            </div>
                        )}
                    </>
                </div>
            </SidebarLayout>
        </div>
    );
};

export default CustomersPage;
